import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Input,
	Spinner,
	MDBIcon,
	toast,
	MDBModalBody,
	MDBModalHeader,
	MDBModal,
	MDBModalFooter,
	MDBListGroup,
	MDBListGroupItem,
	ToastContainer,
	MDBCol,
	MDBRow,
	MDBBtn,
	MDBCardHeader,
	MDBInput,
} from "mdbreact";
import AccountService from "../Security/AccountService/accountService";
import PropTypes from "prop-types";
import UserService from "../Security/UserService/userService";
import Select from "react-select";
import AddAccountModal from "./accountAdd";
import ReactTooltip from "react-tooltip";

export default class accountList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
				},
				{
					// label: <span data-tip={'Sort by Account Name'} onClick={() => this.sortTable('account')} style={{cursor: 'pointer'}}>Account</span>,
					label: "Account",
					field: "account",
				},
				{
					label: "Sales Rep",
					field: "owner",
				},
				{
					label: "Type",
					field: "type",
				},
				{
					label: "City",
					field: "city",
				},
				{
					label: "State",
					field: "state",
				},
				{
					label: "Zip",
					field: "zip",
				},
				{
					label: "Phone",
					field: "phone",
				},
				{
					label: "Is Master",
					field: "isMaster",
				},
				{
					label: "Master Acc. Name",
					field: "masterAccountName",
				},
				{
					label: "Edit",
					field: "button",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: true,
			allLocs: [],
			allSalesReps: [],
			locationsSelect: [
				{
					label: "Loading...",
					value: "loading",
				},
			],
			salesRepsSelect: [
				{
					label: "Loading...",
					value: "loading",
				},
			],
			locationsSelected: [],
			salesRepsSelected: "",
			search: "",
			foundAccounts: [],
			accountModalOpen: false,
			selectedAccounts: [],
			selectedAccountsFull: [],
			accountMoveModal: false,
			accountMergeModal: false,
			accountMasterModal: false,
			accountMoveRep: null,
			allSelected: false,
			primaryAccount: {},
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allLocations: PropTypes.array,
		salesReps: PropTypes.array,
	};

	componentDidMount() {
		this.getLocationsOptions();
		this.getSalesRepsOptions();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { salesReps, currentUser, allLocations } = this.context;
		let st = this.state;
		if (
			st.allLocs.length !== allLocations.length &&
			st.currUser !== currentUser
		) {
			this.getLocationsOptions();
		}
		if (st.allSalesReps.length !== salesReps.length) {
			this.getSalesRepsOptions();
		}
	}

	clientCSV(stateData, filename) {
		let result,
			ctr,
			keys = [],
			headers = [
				"id",
				"name",
				"group",
				"owner",
				"account Type",
				"address",
				"city",
				"state",
				"zip",
				"phone",
				"fax",
				"email",
				"marketingCampaign",
			],
			columnDelimiter = ",",
			lineDelimiter = "\n";

		if (stateData.length === 0) {
			return null;
		}

		let h = stateData[0];
		for (let prop in h) {
			if (prop !== "notes" && prop !== "addrLine2" && prop !== "addrLine3") {
				keys.push(prop);
			}
			if(prop === "name"){
				keys.push("group");
			}
		}

		let replace = ["name", "group"];

		result = "";
		result += headers.join(columnDelimiter);
		result += lineDelimiter;

		stateData.forEach(function (item) {
			ctr = 0;
			item.group = "no group";
			keys.forEach(function (key) {
				let v = "";
				if (ctr > 0) result += columnDelimiter;
				
				switch (key) {
					case "group":
						v = "no group";
						break;
					case "addrLine1":
						v = (item.addrLine1 ? item.addrLine1.replace(/,/g, " ") : "") +
							(item.addrLine2 ? " " + item.addrLine2.replace(/,/g, " ") : "") +
							(item.addrLine3 ? " " + item.addrLine3.replace(/,/g, " ") : "");
						break;
					case "notes":
						break;
					default:
						v = item[key] || "no " + key;
						break;
				}
				
				if (replace.indexOf(key) > -1 && v != null) {
					result += v.replace(/,/g, " ");
				} else {
					result += v;
				}

				ctr++;
			});
			result += lineDelimiter;
		});

		this.downloadBlob(result, filename);
	}

	//this actually prompts the download
	downloadBlob(csvData, filename) {
		let blob = new Blob([csvData], {
			type: "application/csv;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a"),
				csvUrl = URL.createObjectURL(blob);

			link.href = csvUrl;
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	getSalesRepsOptions(e) {
		const { salesReps, currentUser } = this.context;

		let options = [];
		let locs = e && e.length > 0 ? e.map(x => x.value) : [];

		if (currentUser.role !== "SALES") {
			salesReps.map((rep) => {
				if (!e || e.length === 0 || rep.userprofileLocations.filter(x => locs.includes(x.locationsId)).length > 0) {
					return options.push({
						label: rep.firstname + " " + rep.lastname,
						value: rep.id,
					});
				}
				return null;
			});
		}
		else {
			options.push({
				label: currentUser.firstname + " " + currentUser.lastname,
				value: currentUser.id,
			});
        }
		this.setState({
			salesRepsSelect: options,
			allSalesReps: salesReps,
		});
	}

	getLocationsOptions() {
		const { currentUser, allLocations } = this.context;
		let select = [],
			locations = [],
			data = this.state.data,
			cu = currentUser,
			canMove = this.canMove(cu.role);
		if (cu.role === "SALES") {
			locations = cu.userprofileLocations.map(x => x.locations);
		} else {
			locations = allLocations;
		}
		if (canMove) {
			data.columns[0].label = (
				<span
					data-tip={"Select All Accounts"}
					onClick={this.selectAllAccounts.bind(this)}
					style={{ cursor: "pointer" }}
				>
					ID
				</span>
			);
			this.setState({
				data: data,
			});
		}
		locations.map((location) => {
			// Populates the locations dropdown depending on which locations the user is in...JK
			if (location.type === 0) {
				return select.push({
					label: location.name,
					value: location.id,
				});
			}
			return null;
		});
		this.setState({
			locationsSelect: select,
			allLocs: allLocations,
		});
	}

	handleLocationChange = (e) => {
		this.setState({
			locationsSelected: e,
		});
		this.getSalesRepsOptions(e);
	};

	handleSaleRepChange = (e) => {
		this.setState({
			salesRepsSelected: e,
		});
	};

	renderFilter() {
		const { currentUser } = this.context;
		let st = this.state;

		let disabled = true;

		if (currentUser.role.indexOf("ADMIN") > -1) {
			disabled = false;
		}

		const styles = {
			menu: (provided, state) => ({
				...provided,
				color: "black",
			}),
		};

		return (
			<MDBCardHeader
				style={{
					paddingTop: 12,
					paddingBottom: 8,
					marginBottom: 10,
					color: "white",
					fontSize: 18,
					backgroundColor: "#5881C1",
				}}
			>
				<MDBRow>
					<MDBCol md={"3"}>
						<Select
							placeholder="Select Location"
							closeMenuOnSelect={false}
							isMulti
							styles={styles}
							options={st.locationsSelect}
							onChange={this.handleLocationChange.bind(this)}
							value={st.locationsSelected}
						/>
					</MDBCol>
					<MDBCol md={"3"}>
						<Select
							placeholder="Select Sales Rep"
							isClearable
							styles={styles}
							options={st.salesRepsSelect}
							onChange={this.handleSaleRepChange.bind(this)}
							value={st.salesRepsSelected}
						/>
					</MDBCol>
					<MDBCol md={"3"}>
						<form
							onSubmit={(e) => {
								this.filterClicked(e);
							}}
						>
							<MDBInput
								label="Search..."
								outline
								style={{ backgroundColor: "white" }}
								containerClass={"smallMargin"}
								value={st.search}
								onChange={(e) => {
									this.setState({
										search: e.target.value,
									});
								}}
							/>
						</form>
					</MDBCol>
					<MDBCol md={"3"}>
						<Button
							outline
							size="sm"
							rounded
							className="px-2 toolbarButton"
							color={"info"}
							data-tip={"Search Accounts"}
							data-place={"bottom"}
							onClick={(e) => this.filterClicked(e)}
						>
							<MDBIcon icon="search" style={{ fontSize: "2em" }} />
						</Button>
						<Button
							outline
							size="sm"
							rounded
							className="px-2 toolbarButton"
							color={"success"}
							data-tip={"Add New Account"}
							data-place={"bottom"}
							onClick={() => this.toggleModalPopup()}
						>
							<MDBIcon icon="plus" style={{ fontSize: "2em" }} />
						</Button>
						<Button
							outline
							size="sm"
							rounded
							className="px-2 toolbarButton"
							color={"secondary"}
							data-tip={"Download CSV"}
							data-place={"bottom"}
							onClick={this.clientCSV.bind(
								this,
								this.state.foundAccounts,
								"Accounts.csv"
							)}
						>
							<MDBIcon icon="download" style={{ fontSize: "2em" }} />
						</Button>
						&nbsp;
						<Button
							outline
							size="sm"
							rounded
							className="px-2 toolbarButton"
							color={"warning"}
							disabled={
								!this.canMove(currentUser.role) ||
								st.selectedAccounts.length === 0
							}
							data-tip={"Move Selected Accounts"}
							data-place={"bottom"}
							onClick={this.toggleAccountMoveModal}
						>
							<MDBIcon icon="people-carry" style={{ fontSize: "2em" }} />
						</Button>
						<Button
							outline
							size="sm"
							rounded
							className="px-2 toolbarButton"
							color={"red"}
							disabled={disabled || st.selectedAccounts.length < 2}
							data-tip={"Merge Accounts"}
							data-place={"bottom"}
							onClick={this.toggleAccountMergeModal}
						>
							<MDBIcon icon="compress" style={{ fontSize: "2em" }} />
						</Button>
						<Button
							outline
							size="sm"
							rounded
							className="px-2 toolbarButton"
							color={"grey"}
							disabled={disabled || st.selectedAccounts.length < 2}
							data-tip={"Set Master Account"}
							data-place={"bottom"}
							onClick={this.toggleAccountMasterModal}
						>
							<MDBIcon icon="level-up-alt" style={{ fontSize: "2em" }} />
						</Button>
					</MDBCol>

					<ReactTooltip />
				</MDBRow>
			</MDBCardHeader>
		);
	}

	filterClicked = (e) => {
		e.preventDefault();
		if(!this.state.search && !this.state.salesRepsSelected){
			return toast.warn("Please select a rep or enter text to search by");
		}

		this.setState({
			isLoaded: false,
			selectedAccounts: [],
		});
		this.retrieveAccounts();
	};

	canMove(role) {
		switch (role) {
			case "ADMIN":
			case "SYSTEM_ADMIN":
			case "EXECUTIVE_MANAGEMENT":
			case "SALES_MANAGER":
				return true;
			default:
				return false;
		}
	}

	retrieveAccounts() {
		let st = this.state,
			reps = [],
			filters = {};

		// Get users in the sales rep dropdown and add to the filter
		if (st.salesRepsSelected) {
			reps.push(st.salesRepsSelected.value);
		}
		//add the user selected if any
		filters.ids = reps.toString();
		if (st.search) {
			filters.q = st.search;
		}

		AccountService.getAllAccounts(filters)
			.then((res) => {
				this.setState({ foundAccounts: res });

				this.buildRows(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	buildRows(accounts) {
		let ary = [],
			dt = this.state.data;

		accounts.forEach((account, index) => {
			ary.push({
				id: this.renderSelect(account, index),
				account: account.name,
				owner: account.owner || "No Sales-Rep",
				type: account.accountType || "No Type",
				city: account.city ? account.city : "No City",
				state: account.state ? account.state : "No State",
				zip: account.zip ? account.zip : "No Zip Code",
				phone: account.phoneNumber || "No Phone",
				isMaster: account.isMaster ? "Yes" : "No",
				masterAccountName: account.masterAccountName,
				button: this.renderRouteButton(account, index),
			});
		});

		dt.rows = ary;

		this.setState({
			data: dt,
			isLoaded: true,
		});
	}

	addNewAccount(account) {
		toast.success("Account successfully created.");
		this.props.history.push("/account/" + account.id + "/detail");
	}

	toggleModalPopup() {
		this.setState({ accountModalOpen: !this.state.accountModalOpen });
	}

	renderSelect(account, index) {
		let sa = this.state.selectedAccounts;
		return (
			<Input
				key={"checkbox" + account.id}
				id={"checkbox" + index}
				checked={sa.find((acc) => {
					return acc.id === account.id;
				})}
				label={account.id}
				type="checkbox"
				onClick={() => this.handleSelectChange(account, index)}
			/>
		);
	}

	handleSelectChange = (account, index) => {
		let st = this.state,
			acc = st.selectedAccounts,
			full = st.selectedAccountsFull,
			exists = acc.findIndex((acc) => {
				return acc.id === account.id;
			});

		if (exists === -1) {
			acc.push({
				id: account.id,
				name: account.name,
			});
			full.push(account);
		} else {
			acc.splice(exists, 1);
			full.splice(exists, 1);
		}
		this.setState({
			selectedAccounts: acc,
			selectedAccountsFull: full,
		});
	};

	renderRouteButton(account, index) {
		return (
			<MDBIcon
				icon="edit"
				key={index}
				aria-hidden="true"
				onClick={() => {
					this.props.history.push({
						pathname: "/account/" + account.id + "/detail",
						state: { account: account },
					});
				}}
			/>
		);
	}

	selectAllAccounts() {
		let allSelected = !this.state.allSelected,
			accounts = this.state.foundAccounts,
			sAcc = [],
			sAccFull = [],
			t = this;

		if (allSelected) {
			//check
			if (accounts) {
				accounts.forEach((r, idx) => {
					sAccFull.push(r);
					sAcc.push({ id: r.id, name: r.account });
				});
			}
		}

		this.setState({
			allSelected: allSelected,
			selectedAccounts: sAcc,
			selectedAccountsFull: sAccFull,
		});

		setTimeout(() => {
			t.buildRows(t.state.foundAccounts);
		}, 25);
	}

	deselectAllAccounts = () => {
		let a = document.getElementsByClassName("form-control form-check-input");

		for (let ele of a) {
			//uncheck
			if (ele.checked) {
				ele.click();
			}
		}
	};

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<div>
					<MDBTable maxHeight={"65vh"} responsive scrollY striped small>
						<MDBTableHead columns={this.state.data.columns} />
						<MDBTableBody rows={this.state.data.rows} />
					</MDBTable>
					<ReactTooltip />
				</div>
			);
		} else {
			return <div></div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	toggleAccountMoveModal = () => {
		this.setState({
			accountMoveModal: !this.state.accountMoveModal,
		});
	};

	toggleAccountMergeModal = () => {
		this.setState({
			accountMergeModal: !this.state.accountMergeModal,
			primaryAccount: {},
		});
	};
	toggleAccountMasterModal = () => {
		this.setState({
			accountMasterModal: !this.state.accountMasterModal,
			primaryAccount: {},
		});
	};

	setPrimaryAccount(acc) {
		this.setState({
			primaryAccount: acc,
		});
	}

	renderAccountMoveModal() {
		let st = this.state,
			styles = {
				backgroundColor: "red",
				width: "35px",
				height: "35px",
				borderRadius: "50px",
				textAlign: "center",
				color: "white",
				fontSize: ".8rem",
				paddingTop: "5px",
				cursor: "pointer",
			};
		return (
			<MDBModal
				isOpen={st.accountMoveModal}
				toggle={() => {
					return;
				}}
			>
				<MDBModalHeader>Move Accounts</MDBModalHeader>
				<MDBModalBody>
					<Select
						options={st.salesRepsSelect}
						value={st.accountMoveRep || ""}
						onChange={(evt) => {
							this.setState({
								accountMoveRep: evt,
							});
						}}
					/>
					<MDBListGroup
						style={{ marginTop: "5%", overflowY: "scroll", maxHeight: "500px" }}
					>
						{st.accountMoveModal ? this.renderAccountMoveList() : <div />}
					</MDBListGroup>
				</MDBModalBody>
				<MDBModalFooter>
					<span
						style={styles}
						onClick={this.toggleAccountMoveModal}
						data-tip="Close"
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</span>
					<span
						style={{ ...styles, backgroundColor: "green" }}
						data-tip={st.accountMoveRep ? "Save" : ""}
						onClick={
							st.accountMoveRep
								? this.moveAccounts
								: () => {
										return;
								  }
						}
					>
						<MDBIcon icon="save" style={{ fontSize: "2em" }} />
					</span>
				</MDBModalFooter>
				<ReactTooltip />
			</MDBModal>
		);
	}

	moveAccounts = () => {
		let st = this.state,
			selectedAccounts = st.selectedAccounts,
			u = { name: st.accountMoveRep.label, id: st.accountMoveRep.value },
			accounts = [];
		selectedAccounts.forEach((a) => {
			accounts.push(a.id);
		});

		return UserService.moveAccounts(accounts, u)
			.then((res) => {
				toast.success("Accounts moved successfully");
				this.setState({
					selectedAccounts: [],
					accountMoveRep: null,
					accountMoveModal: false,
				});
				this.retrieveAccounts();
			})
			.catch((err) => {
				toast.error("Accounts move error");
			});
	};

	mergeAccounts = () => {
		const { currentUser } = this.context;

		let st = this.state,
			t = this,
			selectedAccounts = st.selectedAccountsFull,
			pa = st.primaryAccount,
			filtered = [];

		selectedAccounts.forEach((a) => {
			if (a.id !== pa.id) {
				filtered.push(a.id);
			}
		});

		let p = {
			keepAccountId: pa.id,
			deleteAccountIds: filtered.toString(),
			userId: currentUser.id,
		};

		AccountService.mergeAccounts(p)
			.then(() => {
				toast.success("Merge Successful");

				t.setState({
					primaryAccount: {},
					selectedAccounts: [],
					selectedAccountsFull: [],
					accountMergeModal: false,
				});

				this.retrieveAccounts();
				this.deselectAllAccounts();
			})
			.catch((e) => {
				toast.error("Merge Failed");
			});
	};
	setMasterAccount = () => {
		let st = this.state,
			t = this,
			selectedAccounts = st.selectedAccountsFull,
			pa = st.primaryAccount,
			error=false,
			filtered = [];

		selectedAccounts.forEach((a) => {
			if (a.id !== pa.id) {
				if(a.isMaster){
					error = "Account " + a.id + " : " + a.name
					+ " is already a master account, it cannot be assigned to another master account."
				}
				filtered.push(a.id);
			}
		});

		let p = {
			masterId: pa.id,
			childIds: filtered.toString(),
		};
		if(error){
			toast.error(error);
			return;
		}

		AccountService.setMaster(p)
			.then(() => {
				toast.success("Successfully Set Master Account");

				t.setState({
					primaryAccount: {},
					selectedAccounts: [],
					selectedAccountsFull: [],
					accountMasterModal: false,
				});

				this.retrieveAccounts();
				this.deselectAllAccounts();
			})
			.catch((e) => {
				toast.error("Failed To Set Master Account");
			});
	};

	renderAccountMoveList() {
		let st = this.state,
			accounts = st.selectedAccounts;
		return accounts.map((a, index) => {
			return (
				<MDBListGroupItem
					color={index % 2 === 0 ? "light" : "grey lighten-1"}
					key={"a.account" + index}
				>
					{a.name}
				</MDBListGroupItem>
			);
		});
	}

	renderAccountMergeList() {
		let st = this.state,
			accounts = st.selectedAccountsFull;
		return accounts.map((a, index) => {
			return (
				<MDBListGroupItem
					className={"listGroupHover"}
					onClick={() => this.setPrimaryAccount(a)}
					key={"a.account" + index}
				>
					{"ID: " + a.id + " Name: " + a.name}
				</MDBListGroupItem>
			);
		});
	}

	renderMergeAccountModal() {
		let st = this.state,
			master = this.state.accountMasterModal,
			pa = st.primaryAccount,
			add = pa.address || {};
			
		return (
			<MDBModal
				className={"salesPilotModal"}
				size="lg"
				isOpen={st.accountMergeModal || master}
				toggle={() => {
					master ? this.toggleAccountMasterModal() : this.toggleAccountMergeModal()
				}}
			>
				<MDBModalHeader >{master ? "Set Master Account" : "Merge Accounts"}</MDBModalHeader>
				<MDBModalBody>
					<span style={{ fontWeight: "bold", textDecoration: "underline" }}>
						{master ? "Master Account" : "Primary Account" }
					</span>
					<MDBRow style={{ padding: 8 }}>
						<MDBCol size="2">ID: {st.primaryAccount.id}</MDBCol>
						<MDBCol size="10">{st.primaryAccount.account}</MDBCol>
						<MDBCol size="4">{add.city}</MDBCol>
						<MDBCol size="4">{add.state}</MDBCol>
						<MDBCol size="4">{add.zip}</MDBCol>
					</MDBRow>

					<MDBListGroup style={{ overflowY: "scroll", maxHeight: "300px" }}>
						{st.accountMergeModal || master ? this.renderAccountMergeList() : <div />}
					</MDBListGroup>
				</MDBModalBody>
				<MDBModalFooter>
					<MDBBtn
						outline
						rounded
						size="md"
						color="red"
						className="px-2 toolbarButton"
						data-tip={"Cancel"}
						onClick={() => {master ? this.toggleAccountMasterModal() :this.toggleAccountMergeModal()}}
					>
						<MDBIcon icon="times" />
					</MDBBtn>

					<MDBBtn
						outline
						rounded
						size="md"
						color="success"
						className="px-2 toolbarButton"
						disabled={!st.primaryAccount.id}
						data-tip={master ? "Set Master Account" : "Merge Accounts"}
						onClick={() => {master ? this.setMasterAccount() :this.mergeAccounts()}}
					>
						<MDBIcon icon="check" />
					</MDBBtn>
				</MDBModalFooter>
				<ReactTooltip />
			</MDBModal>
		);
	}

	render() {
		return (
			<div style={{ overflowY: "hidden", overflowX: "hidden", minHeight: 500 }}>
				{this.renderFilter()}
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={5000}
				/>
				<Card style={{ margin: "1%" }}>
					<CardBody>
						{this.state.isLoaded
							? this.renderTable()
							: this.renderLoadingSpinner()}
					</CardBody>
				</Card>
				{this.renderAccountMoveModal()}
				{this.renderMergeAccountModal()}
				<AddAccountModal
					isOpen={this.state.accountModalOpen}
					accountFunction={this.addNewAccount.bind(this)}
					cancelFunction={this.toggleModalPopup.bind(this)}
				/>
			</div>
		);
	}
}
