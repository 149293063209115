import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBIcon,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast, MDBContainer, MDBRow,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import AccountTypesService from "./accountTypesService";

export default class accountTypes extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
				},
				{
					label: "Marketing",
					field: "isMarketing",
					sort: "asc",
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
				},
				{
					label: "Delete",
					field: "delete",
					sort: "asc",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			confirmationModal: false,
			addNewModal: false,
			name: "",
			currIndex: -1,
			errorMessage: "",
			currAccount: {},
			newAccount: {
				name: "",
				isMarketing: false,
			}
		};
	}

    static contextTypes = {
        currentUser: PropTypes.object,
    };

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveAccountType();
	}

	retrieveAccountType() {
		let t = this;
		return AccountTypesService.getAllAccountTypes()
			.then((res) => {
				let arr = [],
					d = this.state.data;
				res.forEach((account, index) => {
					arr.push({
						id: account.id,
						name: account.name,
						isMarketing: account.isMarketing === true ? "Yes" : "No",
						edit: t.renderEditButton(account, index),
						remove: t.renderRemoveButton(account, index),
					});
				});

				d.rows = arr;

				t.setState({
					data: d,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderEditButton(account, index) {
		return (
				<MDBIcon icon="edit"
						 onClick={() => this.handleEditClick(account, index)}
						 style={{color: "#7ac5ff"}}/>
		);
	}

	renderRemoveButton(id) {
		return (
				<MDBIcon icon="trash"
						 onClick={() => this.handleRemoveClick(id)}
						 style={{color: "#db0404"}}
				/>
		);
	}

	handleConfirmationDelete(id) {
		AccountTypesService.deleteAccount(id)
			.then((res) => {
				this.retrieveAccountType();
				toast.success("Account Type Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Account Type");
			});
		this.handleConfirmationClose();
	}

	handleRemoveClick(account, index) {
		let i = {
			id: account.id,
			name: account.name,
			order: account.order,
		};
		this.setState({
			confirmationModal: !this.state.confirmationModal,
			currAccount: i,
			currIndex: index,
		});
	}

	handleEditClick(account, index) {
		let a = {
			id: account.id,
			name: account.name,
			isMarketing: account.isMarketing || false,
		};
		this.setState({
			currAccount: a,
			editModal: !this.state.editModal,
			currIndex: index,
		});
	}

	addNewToggle = () => {
		this.setState({
			addNewModal: !this.state.addNewModal,
			errorMessage: "",

		})
	}

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};
	confirmationToggle=()=>{
		this.setState({
			confirmationModal: !this.state.confirmationModal,
			errorMessage: "",
		})
	}

	renderConfirmationModal() {
		return (
			<Modal isOpen={this.state.confirmationModal} toggle={this.confirmationToggle}>
				<ModalHeader
					style={{ backgroundColor: "#F93154", color: "white" }}
					toggle={this.confirmationToggle}
				>
					Confirm Delete
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderConfirmationModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Confirm Delete"}
						onClick={() => this.handleConfirmationDelete(this.state.currAccount.id)}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleConfirmationClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderConfirmationModalBody() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<MDBContainer>
					<MDBRow>
						Are you sure you want to delete {this.state.currAccount.name}?
					</MDBRow>
				</MDBContainer>
			</div>
		);
	}

	renderAddNewModal() {
		return (
			<Modal isOpen={this.state.addNewModal} toggle={this.addNewToggle}>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.addNewToggle}
				>
					Add New Account Type
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderAddNewModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Add New Account"}
						onClick={this.handleAddNewSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleAddNewClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderAddNewModalBody() {
		if (this.state.addNewModal) {
			return (
				<div>
					<p style={{ color: "red" }}>{this.state.errorMessage}</p>
					<Input
						label="Is Marketing?"
						checked={this.state.newAccount.isMarketing}
						type="checkbox"
						onChange={(e) => {
							let a = this.state.newAccount;
							a.isMarketing = e.target.checked;
							this.setState({
								newAccount: a,
							});
						}}
						filled
						id="chkIsMarketing"
					/>
					<Input
						label={"Account Type Name"}
						onChange={this.nameChange.bind(this)}
						value={this.state.newAccount.name}
					/>
				</div>
			);
		}
	}

	renderEditModal() {
		return (
			<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.editToggle}
				>
					Edit Account Type
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					<div>
						<p style={{ color: "red" }}>{this.state.errorMessage}</p>
						<Input
							label="Is Marketing?"
							checked={this.state.currAccount.isMarketing}
							type="checkbox"
							onChange={(e) => {
								let a = this.state.currAccount;
								a.isMarketing = e.target.checked;
								this.setState({
									currAccount: a,
								});
							}}
							filled
							id="chkIsMarketing"
						/>
						<Input
							outline
							label="Name"
							value={this.state.currAccount.name}
							onChange={this.handleEditChange.bind(this)}
						/>
					</div>
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Update Account Type"}
						onClick={this.handleEditSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleEditClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	handleConfirmationClose= () => {
		let i = {
			id: -1,
			name: "",
		};
		this.setState({
			currAccount: i,
			currIndex: -1,
			errorMessage: "",
			confirmationModal: !this.state.confirmationModal,
		});
	}

	handleEditSave = () => {
		if (this.validateEdit()) {
			let d = this.state.data,
				i = this.state.currIndex,
				t = this;
			return AccountTypesService.updateAccountType(this.state.currAccount)
				.then((account) => {
					d.rows[i].name = account.name;
					d.rows[i].isMarketing = account.isMarketing === true ? "Yes" : "No";
					d.rows[i].edit = t.renderEditButton(account, i);
					this.setState({
						data: d,
						editModal: !this.state.editModal,
					});
					toast.success("Account Type Edited!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	handleAddNewSave = () => {
		if (this.validate()) {
			let obj = this.state.newAccount;
			return AccountTypesService.createAccountType(obj)
				.then((res) => {
					this.setState({
						errorMessage: "",
						newAccount: {
							isMarketing: false,
							name: "",
						}
					});
					this.handleAddNewClose();
					this.retrieveAccountType();
					toast.success(obj.name + " Account Type has been Added!");

				})
				.catch((err) => {
					toast.warn("An error occurred adding a new Account Type");
				});
		}
	};

	validate() {
		let n = this.state.newAccount.name;
		if (n === "") {
			this.setError("Please enter an Account Name");
			return false;
		}
		return true;
	}

	nameChange(e){
		let a = this.state.newAccount;
		a.name = e.target.value;
		this.setState({
			newAccount: a,
		});
	};


	validateEdit() {
		let a = this.state.currAccount;
		if (a.name === "") {
			this.setError("Please add an Account Name");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleAddNewClose = () => {
		this.setState({
			newAccount:{
				name: "",
				isMarketing: false,
			},
			addNewModal: !this.state.addNewModal,
			errorMessage: "",
		})
	}

	handleEditClose = () => {
		this.setState({
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
			currAccount: {},
		});
	};

	handleEditChange(e) {
		let a = this.state.currAccount;
		a.name = e.target.value;
		this.setState({
			currAccount: a,
		});
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable
					small
					striped
					style={{ textAlign: "center", fontSize: "16px" }}
				>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	addNewClick = () => {
		this.setState({
			addNewModal: !this.state.addNewModal,
		})
	};

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30}}
					>
						Account Types
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Account Type"}
							onClick={this.addNewClick}
						>
							<MDBIcon icon="address-card" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				{this.renderEditModal()}
				{this.renderConfirmationModal()}
				{this.renderAddNewModal()}
				<ReactTooltip />
			</Container>
		);
	}
}
