import React from "react";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import MUIMenuItem from "@mui/material/MenuItem";
import MUIAlert from "@mui/material/Alert";

export default class CardAdminOrderActivityTypeGeneral extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        return (
            <MUIGrid container spacing={2}>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        value={this.props.orderActivityType ? this.props.orderActivityType.name : ""}
                        autoFocus={true}
                        size="small"
                        disabled={true}
                        label="Name"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 30,
                        }}
                        InputLabelProps={{ shrink: true }}
                        required={true}
                        error={this.props.validationIssues.name ? true : false}
                        helperText={this.props.validationIssues.name ? this.props.validationIssues.name : ""}
                    />
                </MUIGrid>
                <MUIGrid item xs={12} sm={6} md={4}>
                    <MUITextField
                        select={true}
                        value={this.props.orderActivityType ? this.props.orderActivityType.activityType : ""}
                        size="small"
                        label="Activity Type"
                        variant="standard"
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => this.props.onChange({ activityType: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.activityType ? true : false}
                        helperText={this.props.validationIssues.activityType ? this.props.validationIssues.activityType : ""}
                    >
                        <MUIMenuItem value="Order">Order</MUIMenuItem>
                        <MUIMenuItem value="Ticket">Ticket</MUIMenuItem>
                    </MUITextField>
                </MUIGrid>
                <MUIGrid item xs={12}>
                    <MUIAlert severity="warning">
                        Name cannot be changed as this field exists in the database as a legacy text field instead of a pointer.
                    </MUIAlert>
                </MUIGrid>
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}