import React from "react";
import Chip from "../Chip";
import MUIGreyColor from "@mui/material/colors/grey";
import MUIAvatar from "@mui/material/Avatar";
import Team from "../../Utility/Crud/Team";
import PropTypes from "prop-types";
import * as MUIColors from "@mui/material/colors";

export default class ChipTeam extends Chip {
    static contextTypes = {
        colorsIndexed: PropTypes.object,
        departmentsIndexed: PropTypes.object,
    };

    /**
     * Renders a MUI Avatar.
     */
    renderAvatar() {
        const department = this.context.departmentsIndexed[this.props.team.departmentId];

        const colorObject = this.props.team ? this.context.colorsIndexed[department.colorId] : null;
        const color = (this.props.team ? MUIColors[colorObject.hue][colorObject.shade] : MUIGreyColor[500]);
        const avatarColor = this.props.isTransparent ? "transparent" : color;
        const iconColor = this.props.isTransparent ? color : "#fff";

        return (
            <MUIAvatar sx={{ bgcolor: avatarColor }}>
                {Team.getIcon({ fontSize: "small", sx: { color: iconColor } })}
            </MUIAvatar>
        );
    }

    /**
     * @return {string} The team name.
     */
    getLabel() {
        return this.props.team ? Team.getDisplayName(this.props.team) : "None";
    }
}
