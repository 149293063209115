import React from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    CardHeader,
    Col,
    Row,
    Modal,
    ModalBody,
    ModalFooter,
    Fa,
    MDBIcon,
    MDBInput, MDBRow, MDBCol, 
} from "mdbreact";
import ManualDocumentCreation from "./manualDocumentBuilderService";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

export default class HomeAssessmentCompare extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: "",
            success: "",
            idTextbox: "",
            modal: false,
            docs: [],
            options: [],
            optionSelectedA: null,
            optionSelectedB: null,
            bothSelected: false
        };
    }

    renderMessage() {
        let t = this.state;

        if (t.error.length > 0) {
            return <h5 style={{color: "#D50000", textAlign: "left"}}>{t.error}</h5>;
        } else if (t.success.length > 0) {
            return (
                <h5 style={{color: "#00C851", textAlign: "left"}}>{t.success}</h5>
            );
        } else {
            return <div></div>;
        }
    }

    clearMessage() {
        setTimeout(() => {
            this.setState({error: "", success: ""});
        }, 5000);
    }

    getPaperWorkById = () => {
        return ManualDocumentCreation.getDeliveryDocs({
            orderId: this.state.idTextbox,
        })
            .then((res) => {
                let o = [];
                res.map((doc) => {
                    if (doc.documnetType && doc.documnetType.indexOf("Home") > -1) {
                        return o.push({
                            label:
                                doc.documnetType +
                                " - " +
                                new Date(doc.uploadedOn).toLocaleString(),
                            value: doc.docInfo,
                        });
                    }
                    return doc;
                });
                this.setState({
                    docs: res,
                    options: o,
                    error: "",
                });
                this.toggleModal();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    renderModal() {
        return (
            <Modal
                centered
                size="med"
                isOpen={this.state.modal}
                toggle={this.toggleModal}
            >
                <ModalBody style={{backgroundColor: "#FFFFFF"}}>
                    <CardHeader
                        style={{borderRadius: "8px"}}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>Select Document</h3>
                    </CardHeader>

                    <Row>
                        <Col size={6}>
                            <Select
                                placeholder={"Home Assessment A"}
                                options={this.state.options}
                                onChange={(e) => this.handleSelectChange(e)}
                            ></Select>
                        </Col>
                        <Col size={6}>
                            <Select
                                placeholder={"Home Assessment B"}
                                options={this.state.options}
                                onChange={(e) => this.handleSelectChange(e, "secondary")}
                            ></Select>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter color={"indigo"}>
                    <Button
                        size="sm"
                        color={"success"}
                        disabled={!this.state.optionSelectedA || !this.state.optionSelectedB}
                        data-tip={"Confirm"}
                        onClick={
                            () => {
                                this.toggleModal();
                                this.setState({bothSelected: true})
                            }
                        }
                    >
                        <MDBIcon icon="check" style={{fontSize: "2em"}}/>
                    </Button>
                    <Button
                        color={"red"}
                        size="sm"
                        data-tip={"Cancel"}
                        onClick={this.toggleModal}
                    >
                        <Fa size={"2x"} icon={"times"}>
                            {" "}
                        </Fa>
                    </Button>
                    <ReactTooltip/>
                </ModalFooter>
            </Modal>
        );
    }

    handleSaveClick = () => {
        let v = this.state.optionSelected.value;

        try {
            let o = JSON.parse(v);

            for (let prop in o) {
                if (o[prop] && o[prop].millis != null) {
                    o[prop] = o[prop].millis;
                }
            }
            document.getElementById("docText").value = JSON.stringify(o);
        } catch (e) {
            document.getElementById("docText").value = JSON.stringify(v);
        }

        this.toggleModal();
    };

    handleSelectChange(e, secondary) {
        if (secondary) {
            this.setState({
                optionSelectedB: e,
            });
            return;
        }

        this.setState({
            optionSelectedA: e,
        });
    };

    renderDifferences() {
        if (!this.state.bothSelected) {
            return;
        }


        let d1 = this.state.optionSelectedA,
            d2 = this.state.optionSelectedB;
            
        let d1Parse = JSON.parse(d1.value);
        let d2Parse = JSON.parse(d2.value);


        let rows = [];

        function createRows() {

            rows.push(
                <MDBRow>
                    <MDBCol size={4}>
                        Field Names
                    </MDBCol>
                    <MDBCol size={4} style={{fontSize: 14}}>
                        {d1.label}
                    </MDBCol>
                    <MDBCol size={4} style={{fontSize: 14}}>
                        {d2.label}
                    </MDBCol>
                </MDBRow>
            )

            for (let a in d1Parse) {
                let bColor = "#DDD";


                if (a.indexOf("Signature") > -1) {
                    continue;
                }

                if (d1Parse[a] !== d2Parse[a]) {
                    bColor = "rgba(255, 0, 0, 0.4)";
                }

                if(a.indexOf("igned") > -1){
                    d1Parse[a] = d1Parse[a] ? new Date(d1Parse[a].millis).toLocaleString() : "";
                    d2Parse[a] = d2Parse[a] ? new Date(d2Parse[a].millis).toLocaleString() : "";
                }

                rows.push(
                    <MDBRow style={{backgroundColor: bColor, padding: 3}}>
                        <MDBCol size={4}>
                            {a}
                        </MDBCol>

                        <MDBCol size={4}>
                            {d1Parse[a] != null ? d1Parse[a].toString() : ""}
                        </MDBCol>
                        <MDBCol size={4}>
                            {d2Parse[a] != null ? d2Parse[a].toString() : ""}
                        </MDBCol>
                    </MDBRow>
                )
            }

        }

        createRows();

        return rows.map(v => {
            return v
        })
    }



    render() {
        return (
            <Container className="mt-5">
                <Card>
                    <CardHeader style={{textAlign: "center"}}>
                        Home Assessment Comparison
                    </CardHeader>
                    <CardBody>
                        {this.renderMessage()}
                        <Row>
                            <Col md={3}>
                                <MDBInput
                                    containerClass={"smallMargin"}
                                    outline
                                    label={"Order Id"}
                                    valueDefault={this.state.idTextbox}
                                    onChange={(e) => this.setState({idTextbox: e.target.value})}
                                />

                            </Col>
                            <Col md={3}>
                                <Button size={"sm"} color="primary" onClick={() => this.getPaperWorkById()}
                                >Find Assessments</Button>
                            </Col>
                        </Row>
                        {this.renderDifferences()}
                    </CardBody>
                </Card>
                {this.renderModal()}
            </Container>
        )
    }
}
