import React from "react";
import PropTypes from "prop-types";
import {
	Container,
	ToastContainer,
	Row,
	Fa,
	Col,
	CardBody,
	toast,
	DataTable,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	MDBIcon,
	Button,
	Spinner,
	Modal,
	ModalFooter,
	ModalBody,
	CardHeader,
	Input,
	MDBBtn,
	MDBCol,
	MDBRow,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBModal,
	MDBModalBody,
	MDBContainer,
	DatePicker,
	InputFile,
	MDBInput,
    MDBModalHeader,
	MDBModalFooter,
	MDBSelect,
	Card,
} from "mdbreact";
import NoteService from "../Security/NoteService/noteService";
import ActivityService from "../Security/ActivityService/activityService";
import ContactService from "../Security/ContactService/contactService";
//import UserService from "../Security/UserService/userService";
import classnames from "classnames";
import "./account.css";
import AccountService from "../Security/AccountService/accountService";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import GlobalFunctions from "../Filters/GlobalFunctions";
import InlineTable from "../../Components/Custom/InlineTable";
import noteService from "../Security/NoteService/noteService";

export default class accountInfo extends React.Component {
	constructor(props) {
		super(props);

		let account = {},
			id = props.match.params.id;

		const notes = {
			columns: [
				{
					label: "Type",
					field: "type",
					sort: "asc",
					width: 100,
				},
				{
					label: "Created By",
					field: "createdBy",
					sort: "asc",
					width: 100,
				},
				{
					label: "Created",
					field: "createdOn",
					sort: "asc",
					width: 100,
				},
				{
					label: "Complete",
					field: "completed",
					sort: "asc",
					width: 100,
				},
				{
					label: "Text",
					field: "text",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "button",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		const activities = {
			columns: [
				{
					label: "Type",
					field: "type",
					sort: "asc",
					width: 100,
				},
				{
					label: "Date",
					field: "expenseDate",
					sort: "asc",
					width: 100,
				},
				{
					label: "Marketing",
					field: "quality",
					sort: "asc",
					width: 100,
				},
				{
					label: "Spent",
					field: "expenses",
					sort: "asc",
					width: 100,
				},
				{
					label: "At",
					field: "at",
					sort: "asc",
					width: 100,
				},
				{
					label: "For",
					field: "for",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		const orders = {
			columns: [
				{
					label: "Order Id",
					field: "id",
					sort: "asc",
					width: 100,
				},
				{
					label: "Status",
					field: "status",
					sort: "asc",
					width: 100,
				},
				{
					label: "Reason",
					field: "reason",
					sort: "asc",
					width: 100,
				},
				{
					label: "Name",
					field: "patientName",
					sort: "asc",
					width: 100,
				},
				{
					label: "Date",
					field: "when",
					sort: "asc",
					width: 100,
				},
				{
					label: "Payor Source",
					field: "insuranceType",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		const contacts = {
			columns: [
				{
					label: "Name",
					field: "name",
					sort: "asc",
					width: 100,
				},
				{
					label: "Type",
					field: "type",
					sort: "asc",
					width: 100,
				},
				{
					label: "Title",
					field: "role",
					sort: "asc",
					width: 100,
				},
				{
					label: "Phone",
					field: "phone",
					sort: "asc",
					width: 100,
				},
				{
					label: "Email",
					field: "mail",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		const columnMap = [
			{ columnName: "Id", fieldName: "id", canEdit: false, type: "text", required: false, hidden: true },
			{ columnName: "Product Type", fieldName: "productTypeId", canEdit: true, type: "dropdown", required: true, options: [] },
			{ columnName: "Product Sub Type", fieldName: "productSubTypeId", canEdit: true, type: "dropdown", required: true, options: [], filter: "productTypeId", unique: true },
			{ columnName: "Rate", fieldName: "rate", canEdit: true, type: "float", required: true, },
			{ columnName: "Deleted", fieldName: "deleted", canEdit: true, type: "bool", required: false, hidden: true, defaultValue: false },
		];

		this.state = {
			account: account,
			accountId: id,
			eAccount: JSON.parse(JSON.stringify(account)),
			notes: notes,
			nLoaded: false,
			activities: activities,
			aLoaded: false,
			orders: orders,
			oLoaded: false,
			contacts: contacts,
			cLoaded: false,
			taskCompleted: false,
			noteModalOpen: false,
			contactModalOpen: false,
			noteText: "",
			activeItem: "5",
			typeRadio: "Note",
			nContact: {
				account: {},
				contactType: {},
				email: "",
				firstname: "",
				lastname: "",
				phone: "",
				role: "",
			},
			nActivity: {
				account: account,
				accountId: account.id,
				expenseDate: new Date(),
				quality: false,
				contacts: [],
				type: "Appointment",
			},
			nCall: {
				account: account,
				quality: false,
				contacts: [],
				type: "Call",
				endedAt: new Date(),
				callType: null,
				callReason: null,
			},
			callTypes: [
				{ label: "Face-To-Face", value: "FTF" },
				{ label: "LCMP Evaluation", value: "LCMP Evaluation" },
				{ label: "Drop Ins", value: "Drop Ins" },
			],
			callReasons: [
				{ label: "Following up with new information from a previous meeting", value: "Following up with new information from a previous meeting" },
				{ label: "Following up regarding new information about changes in LCDs", value: "Following up regarding new information about changes in LCDs" },
				{ label: "Paperwork Education/Streamlining Processes", value: "Paperwork Education/Streamlining Processes" },
				{ label: "Equipment Changes", value: "Equipment Changes" },
				{ label: "Policy Changes", value: "Policy Changes" },
				{ label: "Other industry and/or inter-company changes that our accounts need to be aware of", value: "Other industry and/or inter-company changes that our accounts need to be aware of" },
				{ label: "Other", value: "Other" },
			],
			modalNote: {},
			editNoteOpen: false,
			callModalOpen: false,
			appointmentModalOpen: false,
			dueDate: null,
			contactList: [],
			callNoteText: "",
			allNotes:[],
			contactTypes: [],
			allContactTypes: [],
			marketingCampaigns: [],
			accountTypes: [],
			cardTypes: [],
			expenseTypes: [],
			allCardTypes: [],
			allExpenseTypes: [],

			pricesheets: [],
			pricesheetsLoaded: false,
			filteredProductSubTypes: [],
			pricesheetColumnMap: columnMap,
			productTypes: [],
			productSubTypes: [],
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		contactTypes: PropTypes.array,
		marketingCampaigns: PropTypes.array,
		accountTypes: PropTypes.array,
		cardTypes: PropTypes.array,
		expenseTypes: PropTypes.array,
		companyName: PropTypes.string,
		productTypes: PropTypes.array,
		productSubTypes: PropTypes.array,
	};

	componentDidMount(){
		let id = this.state.accountId;
		this.getMasterAccounts();
		this.getCardList();
		this.getExpenseTypeList();

		this.getAccountTypeList();
		this.getMarketingCampaigns();
		this.getContactTypesList();

		this.fetchAccount(id);
		this.retrieveActivities(id);
		this.getPricesheets(id);
	}

	componentDidUpdate() {
		const { cardTypes, expenseTypes, contactTypes } = this.context;
		if (cardTypes.length !== this.state.allCardTypes.length) {
			this.getCardList()
		}
		if (expenseTypes.length !== this.state.allExpenseTypes.length) {
			this.getExpenseTypeList()
		}
		if (contactTypes.length !== this.state.allContactTypes.length) {
			this.getContactTypesList()
		}
    }

	toggle = (tab) => {
		if (this.state.activeItem !== tab) {
			this.setState({
				activeItem: tab,
			});
		}
	};

	//DATA
	fetchAccount(id) {
		let t = this;

		let nAct = this.state.nActivity,
			nCall = this.state.nCall;

		return AccountService.getAccount(id)
			.then((data) => {
				console.log(data)
				let accRef = { id: data.id, name: data.name };

				nAct.account = accRef;
				nCall.account = accRef;

				let cAry = [],
					cdt = this.state.contacts,
					cList = [];
				data.contact.forEach(c => {
					cAry.push({
						name: c.lastname + "," + c.firstname,
						type: c.contactType ? c.contactType.name : "No Type",
						role: c.role || "No Role",
						phone: c.phone || "No Phone",
						mail: c.email || "No Email",
						edit: this.routeContact(c),
					});
					cList.push({
						label: c.firstname + ' ' + c.lastname,
						value: { id: c.id, firstname: c.firstname, lastname: c.lastname },
					})
				})
				cdt.rows = cAry;

				//let aAry = [],
				//	adt = this.state.activities,
				//	dt = this.state.orders,
				//	oAry = [];

				//data.orders.forEach((o,index) => {
				//	oAry.push({
				//		id: t.routeOrder(o, index),
				//		status: o.status || "No Status",
				//		reason: o.reason || "No Reason",
				//		patientName: o.patientName || "",
				//		when: GlobalFunctions.formatDateTimeTimeZone(o.date),
				//		insuranceType: o.payor || "No Insurance",
    //                })
    //            })

				//data.activities.forEach((a, index) => {
				//	aAry.push({
				//		type: a.type,
				//		expenseDate: a.date,
				//		quality: a.quality ? "Yes" : "No",
				//		expenses: a.expenses ? t.formatCurrency(a.expenses) : "No Expenses",
				//		at: a.vendor || "No Vendor",
				//		for: a.description || "No Description",
				//		edit: t.renderRouteButton(a, index),
    //                })
				//})

				//adt.rows = aAry;
				//dt.rows = oAry;

				t.setState({
					account: data,
					nActivity: nAct,
					nCall: nCall,
					//allNotes: data.note.reverse(),
					eAccount: JSON.parse(JSON.stringify(data)),
					nLoaded: true,
					contacts: cdt,
					contactList: cList,
					cLoaded: true,
					//activities: adt,
					//aLoaded: true,
					//orders: dt,
					//oLoaded: true,
				}, this.getAccountNotes(id));
			});
	}

	sortNotesByDate(notes){
		let sorted = notes.sort((a,b) => {
			let da = new Date(a.createdAt).getTime(),
				db = new Date(b.createdAt).getTime();

			return da > db ? -1: 1
		});
		return sorted
	}

	getAccountNotes(id) {
		noteService.getNotesByAccount(id)
			.then(res => {
				console.log(res)

				this.setState({
					allNotes: this.sortNotesByDate(res),
				});
			})
			.catch(err => {
				console.log(err)
            })
    }

	getPricesheets(id) {
		const { companyName } = this.context;

		if (companyName !== "rehabmedical") {
			AccountService.GetAccountPricesheets(id)
				.then(res => {
					this.setState({
						pricesheets: res || [],
						pricesheetsLoaded: true,
					}, () => this.updateColumnMap());
				})
				.catch(ex => {
					console.log(ex);
					this.setState({
						pricesheetsLoaded: true,
					})
                })
        }
	}

	updateColumnMap() {
		const { productTypes, productSubTypes } = this.context;
		if (productTypes && productTypes.length > 0) {
			var ptOpts = [],
				map = this.state.pricesheetColumnMap;
			productTypes.map(p => {
				return ptOpts.push({
					label: p.name,
					//text: p.name,
					value: p.id,
					realValue: p,
                })
			})
			map[1].options = ptOpts;
			this.setState({
				pricesheetColumnMap: map,
            })
		}

		if (productSubTypes && productSubTypes.length > 0) {
			var pstOpts = [],
				smap = this.state.pricesheetColumnMap;
			productSubTypes.map(p => {
				return pstOpts.push({
					label: p.name,
					//text: p.name,
					value: p.id,
					realValue: p,
				})
			})
			smap[2].options = pstOpts;
			this.setState({
				pricesheetColumnMap: smap,
			})
		}
    }

	getMasterAccounts() {
		AccountService.getMasterAccounts()
			.then(res => {
				var arr = [];
				if (res && res.length > 0) {
					res.map(a => {
							return arr.push({
								// checked:true,
								text: a.name,
								value: a.id,
							})
                    })
				}
				else {
					arr.push({
						disabled: true,
						text: '---No Master Accounts Found---',
						value: null,
                    })
                }
				this.setState({
					masterAccounts: arr,
                })
            })
	}


	getCardList() {
		const { cardTypes } = this.context;

		let a = [];
		a.push({
			label: "Select Card Type...",
			value: "",
		});

		cardTypes.map((card) => {
			return a.push({
				label: card.name,
				value: card,
			});
		});

		this.setState({
			cardTypes: a,
			allCardTypes: cardTypes,
        })
	}

	getExpenseTypeList() {
		const { expenseTypes } = this.context;
		let act = this.state.nActivity,
			a = [];

		a.push({
			label: "Select Expense Type...",
			value: "",
		});

		expenseTypes.map((exp) => {
			if (act.cardType != null) {
				if (exp.cardTypes) {
					let i = exp.cardTypes.findIndex((x) => x.id === act.cardType.id);
					if (i > -1) {
						return a.push({
							label: exp.name,
							value: exp,
						});
					}
				}
			} else {
				return a.push({
					label: exp.name,
					value: exp,
				});
			}

			return null;
		});
		this.setState({
			expenseTypes: a,
			allExpenseTypes: expenseTypes,
        })
	}

	getContactTypesList() {
		const { contactTypes } = this.context;
		let a = [];
		a.push({
			label: "Select Category...",
			value: "",
		});
		contactTypes.map((type) => {
			return a.push({
				label: type.name,
				value: type,
			});
		});
		this.setState({
			contactTypes: a,
			allContactTypes: contactTypes,
        })
	}

	handleCardTypeSelected = (e) => {
		let act = this.state.nActivity;

		act.cardType = { name: e.value.name, id: e.value.id };
		act.cardTypeId = e.value.id;

		this.setState({
			nActivity: act,
		});
	};

	handleExpenseTypeSelected = (e) => {
		let act = this.state.nActivity;

		act.expenseType = { name: e.value.name, id: e.value.id };
		act.expenseTypeId = e.value.id;

		this.setState({
			nActivity: act,
			selectedExpense: e.value,
		});
	};

	validateActivity(activity) {
		let shouldContinue = true;

		if (!activity.account) {
			toast.warn("Please pick an account");
			shouldContinue = false;
		}

		if (!activity.type) {
			toast.warn("Please select an activity type");
			shouldContinue = false;
		}

		if (activity.type === "Appointment") {
			if (!activity.expenseDesc) {
				toast.warn("Please provide a description");
				shouldContinue = false;
			}

			if (!activity.expenseDate) {
				toast.warn("Please set the date");
				shouldContinue = false;
			}

			if (activity.expenses < 0) {
				toast.warn("Amount spent should be no less than 0");
				shouldContinue = false;
			}

			if (!activity.vendor) {
				toast.warn("Please identify the vendor");
				shouldContinue = false;
			}

			if (!activity.peopleAttending) {
				toast.warn("Please identify how many people were attending");
				shouldContinue = false;
			}

			if (!activity.educationalTopics) {
				toast.warn("Please identify the topics discussed");
				shouldContinue = false;
			}

			if (activity.expenses > 0) {
				if (!activity.expenseType) {
					toast.warn("Please select an expense type");
					shouldContinue = false;
				}

				if (!activity.cardType) {
					toast.warn("Please select a card type");
					shouldContinue = false;
				}

				if (
					activity.expenseType &&
					this.state.selectedExpense.receiptRequired === true
				) {
					if (!activity.expenseReceipt) {
						toast.warn("Please upload a receipt");
						shouldContinue = false;
					}
				}
			}
		}
		else if(activity.type === "Call"){
			if(activity.quality === true && (!activity.contacts || activity.contacts.length === 0)){
				toast.warn("Please select a contact");
				shouldContinue = false;
			}

			if(activity.quality === false && !activity.callType){
				toast.warn("Please select a call type");
				shouldContinue = false;
			}

			if(activity.quality === true && activity.callReason === null){
				toast.warn("Please select a call reason");
				shouldContinue = false;
			}

			let nText = document.getElementById("newNoteText");
			if(activity.quality === true && activity.callReason === "Other" && nText != null && nText.value === ""){
				toast.warn("Please enter the reason note");
				shouldContinue = false;
			}
		}

		return shouldContinue;
	}

	createActivity(type) {
		this.setState({ saveLoading: true });
		let act = this.state.nActivity.type === "Appointment" ? this.state.nActivity : this.state.nCall,
			na = {
				type: this.state.nActivity.type,
				accountId: parseInt(this.state.accountId),
				ownerId: this.state.account.ownerId,
				endedAt: new Date().toISOString(),
				startedAt: new Date().toISOString(),
				quality: this.state.nCall.quality || false,
				priority: 0,
				isExpense: this.state.typeRadio === "Appointment",
			};

		if (type === "Appointment") {
			let shouldCreate = this.validateActivity(act);

			if (!shouldCreate) {
				this.setState({ saveLoading: false });
				return;
			}

			//If we have a receipt, format it before save...BC
			if (act.expenseReceipt) {
				let rt = act.expenseReceipt;
				//get the index of the comma which comes after the part that declares the image type
				let idx = rt.indexOf(","),
					newStr = rt.slice(idx + 1);

				act.expenseReceipt = newStr;
			}

			if (act.expenses) {
				act.expenses = parseFloat(act.expenses);
			}

			delete act.contacts;
			delete act.endedAt;
			delete act.quality;
			delete act.type;
			delete act.account;
			na.appointmentActivity = act;
		} else {
			let act = this.state.nCall;

			let shouldCreate = this.validateActivity(act);

			if (!shouldCreate) {
				this.setState({ saveLoading: false });
				return;
			}
			na.activityContact = act.contacts && act.contacts.length > 0 ? act.contacts.map(x => { return { accountId: this.state.accountId, contactId: x.id, contact: x }; }) : [];
			delete act.contacts;
			delete act.endedAt;
			delete act.quality;
			delete act.type;
			delete act.account;
			na.callActivity = act;
		}

		return ActivityService.createActivity(na)
			.then((res) => {
				let oActivity = this.state.nActivity;

				let nActivity = {
					account: oActivity.account,
					accountId: oActivity.account.id,
					expenseDate: new Date(),
					quality: false,
					contacts: [],
					type: "Appointment",
				};

				let nCall = {
					quality: false,
					contacts: [],
					type: "Call",
					endedAt: new Date(),
					callReason: null,
					callType: null,
				};

				this.setState({
					nActivity: nActivity,
					aLoaded: false,
					appointmentModalOpen: false,
					nCall: nCall,
					callModalOpen: false,
				});

				//let nText = document.getElementById("newNoteText");
				if(na.type === "Call" && this.state.callNoteText){
					this.createActivityNote(res);
				}

				this.retrieveActivities(this.state.accountId);
			})
			.catch((err) => {
				toast.error("Oh! There was an error creating the Activity.");
			});
	}

	createActivityNote(activity) {
		const { currentUser } = this.context;
		let noteRadio = this.state.noteRadio,
			accountDTO = activity.account,
			txt = this.state.callNoteText,
			cb = { id: currentUser.id, name: currentUser.username };

		let note = {
			text: txt,
			account: accountDTO,
			accountId: accountDTO.id,
			completed: true,
			task: false,
			createdAt: GlobalFunctions.getUTCMoment(),
			activityId: activity.id,
			createdBy: cb,
			createdById: cb.id,
		};

		if (noteRadio === "Task") {
			note.completed = false;
			note.task = true;
			note.assignedTo = this.state.account.owner;
			note.assignedToId = this.state.account.owner.id;
		}

		return NoteService.createActivityNote(activity.id, note)
			.then((res) => {
			})
			.catch((err) => {
				console.log("Oh! There was an error creating the Task.");
			});
	}

	encodeImageFileAsURL(element) {
		let file = element[0],
			reader = new FileReader(),
			t = this;

		//let reader2 = new FileReader();
		//reader2.readAsArrayBuffer(file);
		//reader2.onloadend = function (ev) {
		//	if(ev.target.readyState === FileReader.DONE){
		//		var ab = ev.target.result,
		//			ar = new Uint8Array(ab);

		//		// t.state.nActivity.receipt = reader.result;
		//		t.state.nActivity.receiptArray = ar;
		//		t.setState({ nActivity: t.state.nActivity });
		//	}
		//}

		reader.onloadend = function () {
			t.state.nActivity.expenseReceipt = reader.result;
			t.setState({ nActivity: t.state.nActivity });
		};
		reader.readAsDataURL(file);
		// let file = element[0],
		// 	reader = new FileReader(),
		// 	t = this;

		// reader.onloadend = function () {
		// 	t.state.nActivity.receipt = reader.result;
		// 	t.setState({ nActivity: t.state.nActivity });
		// };
		// reader.readAsDataURL(file);
	}

	getMarketingCampaigns() {
		const { marketingCampaigns } = this.context;

		let a = [];
		marketingCampaigns.map((type) => {
			return a.push({
				label: type.name,
				value: type.id,
				mcVal: type,
			});
		});

		this.setState({marketingCampaigns: a});
		return a;
	}

	updateNoteModal(note) {
		let nText = document.getElementById("noteTextArea").value;

		let upDate = "Updated: " + GlobalFunctions.formatDate(new Date());
		note.text = nText + " -- " + upDate;

		this.updateNote(note, null, true);
	}

	updateNote(note, complete) {
		const { currentUser } = this.context;

		let notes = this.state.allNotes,
			noteToReplace = notes.findIndex((n) => {
				return n.id === note.id;
			});

		if (noteToReplace > -1) {
			notes.splice(noteToReplace, 1, note);
		}

		if (complete != null) {
			note.completed = complete;

			note.text +=
				" --> Completed by " +
				currentUser.username +
				" on " +
				GlobalFunctions.formatDate(new Date());
		}

		return NoteService.updateActivityNote(note)
			.then((res) => {
				this.setState({
					allNotes: notes,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	sortNotes(allNotes, task) {
		let t = [],
			n = [];

		if(allNotes){
			allNotes.forEach((note) => {
				if (note.completed === false) {
					t.push(note);
				} else {
					n.push(note);
				}
			});
		}

		if (task) {
			return t;
		}
		return n;
	}

	toggleEditModalPopup(note) {
		const { currentUser } = this.context;

		let currentState = this.state.editNoteOpen,
			ncopy = {};

		if (note) {
			ncopy = JSON.parse(JSON.stringify(note));

			if (currentUser) {
				if (
					currentUser.role === "ADMIN" ||
					currentUser.role === "SYSTEM_ADMIN"
				) {
					//we are fine, continue
				} else if (currentUser.username !== note.createdBy.username) {
					//they do not own this note.  They shouldn't be able to edit it.
					return;
				}
			} else {
				return;
			}
		}

		this.setState({
			editNoteOpen: !currentState,
			modalNote: ncopy,
		});
	}

	toggleNoteModalPopup() {
		let currentState = this.state.noteModalOpen;
		this.setState({ noteModalOpen: !currentState });
	}

	toggleContactModalPopup() {
		let currentState = this.state.contactModalOpen;
		this.setState({ contactModalOpen: !currentState });
	}

	//noteChange = (property, event) => {
	//	this.setState({ [property]: event.target.value });
	//};

	contactChange = (property, event) => {
		let contact = this.state.nContact;
		contact[property] = event.target.value;
		this.setState({ nContact: contact });
	};

	handleContactTypeSelected = (e) => {
		let con = this.state.nContact;

		con.contactType = { name: e.value.name, id: e.value.id };
		con.contactTypeId = e.value.id;

		this.setState({
			nContact: con,
		});
	};

	//completedChange = (event) => {
	//	this.setState({ completed: event.target.checked });
	//};

	addNewNote() {
		//get the current user context that can be passed into the note DTO...BC
		//another comment...BC
		const { currentUser } = this.context;
		let cb = { id: currentUser.id, name: currentUser.username },
			currentNotes = this.state.allNotes,
			acc = this.state.account;

		let nText = document.getElementById("newActivityNoteText").value;

		let note = {
			type: "AccountNote",
			text: nText,
			createdAt: GlobalFunctions.getUTCMoment(),
			accountId: acc.id,
			// account: { id: acc.id, name: acc.name },
			task: !this.state.taskCompleted,
			createdBy: cb,
			createdById: cb.id,
			completed: this.state.taskCompleted,
			dueDate: this.state.dueDate ? this.state.dueDate.toISOString() : new Date().toISOString(),
		};

		if (!this.state.completed) {
			note.assignedTo = this.state.account.owner;
			note.assignedToId = this.state.account.owner.id;
		}

		return NoteService.createAccountNote(note)
			.then((res) => {
				this.toggleNoteModalPopup();
				currentNotes.unshift(note);
				this.setState({ allNotes: currentNotes });
			})
			.catch((err) => {
				//Maybe send an error to the user?...BC
			});
	}

	addNewContact() {
		let obj = this.state.nContact,
			acc = this.state.eAccount;

		obj.account = { name: acc.name, id: acc.id };
		obj.accountId = acc.id;

		this.setState({ cLoaded: false });

		//change to add contact
		return ContactService.createContact(obj)
			.then((res) => {
				toast.success("Contact Created");

				let dt = this.state.contacts;

				dt.rows.push({
					name: res.lastname + "," + res.firstname,
					type: res.contactType ? res.contactType.name : "No Type",
					role: res.role || "No Role",
					phone: res.phone || "No Phone",
					mail: res.email || "No Email",
					edit: this.routeContact(res),
				});

					let n = {
						account: {},
						contactType: {},
						email: "",
						firstname: "",
						lastname: "",
						phone: "",
						role: "",
					}

					this.setState({
						cLoaded: true,
						contacts: dt,
						nContact: n,
					});

				this.createNoteTask(acc, res.id);
			})
			.catch((err) => {
				console.log(err)
				toast.error("There was an error.  Make sure all fields are filled out");
				this.setState({ cLoaded: true });
			});
	}

	createNoteTask(account, contactID) {
		const { currentUser } = this.context;

		let typeRadio = this.state.typeRadio,
			accountDTO = { id: account.id, name: account.name },
			cb = { id: currentUser.id, name: currentUser.username };

		let nText = document.getElementById("newNoteText").value;

		//we don't have any text, no note to create
		if (nText === "") {
			this.toggleContactModalPopup();
		} else {
			let note = {
				text: nText,
				account: accountDTO,
				accountId: accountDTO.id,
				completed: true,
				task: false,
				createdAt: GlobalFunctions.getUTCMoment(),
				createdBy: cb,
				createdById: cb.id,
			};

			if (typeRadio === "Task") {
				note.completed = false;
				note.task = true;
				note.assignedTo = account.owner;
				note.assignedToId = account.owner.id;
			}

			return NoteService.createContactNote(contactID, note)
				.then((res) => {
					toast.success("Contact task successfully created.");
					this.toggleContactModalPopup();
				})
				.catch((err) => {
					console.log(err)
					toast.warn("Oh! There was an error creating the contact task.");
					this.toggleContactModalPopup();
				});
		}
	}

	updateAccount() {
		let acc = this.state.eAccount;
		return AccountService.updateAccount(acc)
			.then((data) => {
				this.setState({
					account: data,
					eAccount: JSON.parse(JSON.stringify(data)),
				});
				toast.success("Saved Successfully!");
			})
			.catch((err) => {
				toast.warn("An error occurred while saving.");
			});
	}

	handleChange = (property, event) => {
		//update the property that the user has edited in the order...BC
		const nAccount = this.state.eAccount;
		nAccount[property] = event.target.value;
		this.setState({ eAccount: nAccount });
	};

	handleSelectChange = (property, e) => {
		let n = this.state.eAccount;

		if (property === "marketingCampaign") {
			n.marketingCampaign = e.mcVal;
		} else if (property === "accountTypeId") {
			n.accountTypeId = e.value.id;
		}
		this.setState({ eAccount: n });
	};

	retrieveActivities(id) {
		let t = this;

		return ActivityService.getActivitiesByAccount(id)
			.then((data) => {
				let aAry = [],
					oAry = [],
					dt = this.state.activities,
					ot = this.state.orders,
					activities = data;
				let stats = ["New", "In Process", "Ready to Deliver", "Setup", "Cancelled"];
				activities.forEach((activity, index) => {
					if (activity.type === "Call" || activity.type === "Appointment") {
						aAry.push({
							type: activity.type,
							expenseDate: activity.appointmentActivity
								? activity.appointmentActivity.expenseDate ?
									GlobalFunctions.customFormatDateMoment(activity.appointmentActivity.expenseDate,"YYYY-MM-DD") :
									GlobalFunctions.customFormatDateMoment(activity.endedAt,"YYYY-MM-DD")
								: GlobalFunctions.customFormatDateMoment(activity.endedAt,"YYYY-MM-DD"),
							quality: activity.quality ? "Yes" : "No",
							expenses: activity.appointmentActivity
								? t.formatCurrency(activity.appointmentActivity.expenses)
								: "No Expenses",
							at: activity.appointmentActivity ? activity.appointmentActivity.vendor : "No Vendor",
							for: activity.appointmentActivity ? activity.appointmentActivity.expenseDesc : "No Description",
							edit: t.renderRouteButton(activity, index),
						});
					}
					else if (activity.type === "Order" && activity.orderActivity) {
						oAry.push({
							id: t.routeOrder(activity.orderActivity, index),
							status: activity.orderActivity ? stats[activity.orderActivity.status] : "No Status",
							reason: activity.orderActivity && activity.orderActivity.orderStatusReason ? activity.orderActivity.orderStatusReason.description : "No Reason",
							patientName: activity.orderActivity ? activity.orderActivity.patientName : "",
							when: GlobalFunctions.formatDateTime(activity.startedAt),
							insuranceType: activity.orderActivity && activity.orderActivity.insuranceType ? activity.orderActivity.insuranceType.name : "No Insurance",
						});
                    }
				});

				aAry.sort((a, b) => {
					if (a.expenseDate > b.expenseDate) {
						return -1;
					} else {
						return 1;
					}
				});
				dt.rows = aAry;
				ot.rows = oAry;
				t.setState({
					activities: dt,
					orders: ot,
					aLoaded: true,
					oLoaded: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	renderRouteButton(activity, index) {
		return (
			<MDBIcon
				icon="edit"
				aria-hidden="true"
				color="warning"
				onClick={() => {
					this.props.history.push({
						pathname: "/activity/" + activity.id,
						state: {
							//activity: activity,
							prevPage: this.state.account.id + "",
						},
					});
				}}
			/>
		);
	}

	renderActivityTable() {
		if (this.state.aLoaded === true) {
			return (
				<DataTable striped info={false} small order={['date' ]} data={this.state.activities}>
					{" "}
				</DataTable>
			);
		} else {
			return <Spinner size="small" />;
		}
	}


	routeOrder(order) {
		if (order && order.id) {
			return (
				<a
					style={{ color: "#00afd7" }}
					href={"/order/" + order.id}
					onClick={() => {
						this.setState({ order: order });
					}}
				>
					{order.id}
				</a>
			);
		}
		else {
			return null;
        }
	}

	renderOrderTable() {
		if (this.state.oLoaded === true) {
			return (
				<DataTable striped info={false} small data={this.state.orders}>
					{" "}
				</DataTable>
			);
		} else {
			return <div></div>;
		}
	}

	renderReceipt() {
		if (!this.state.nActivity.expenseReceipt) {
			return <div></div>;
		} else {
			return (
				<img
					style={{ width: "200px", height: "300px" }}
					src={this.state.nActivity.expenseReceipt}
					alt="Invalid format"
				/>
			);
		}
	}

	renderInformationSection() {
		const { companyName } = this.context;
		let account = this.state.eAccount,
			masAcc = this.state.masterAccounts,
			selAccIndex = account.masterAccountId && masAcc ? masAcc.findIndex(x => x.value === account.masterAccountId) : -1,
			selAcc = null;

		if(selAccIndex !== -1 ){
			selAcc = masAcc[selAccIndex];
			selAcc.checked=true;
		}
		return (
			<div>
				<Row>
					<Col className={"outlineBoxWithLabel"}>
						<label className="patientInfoLabel">Account Contact Info</label>
						<Input
							value={account.name}
							outline
							onChange={this.handleChange.bind(this, "name")}
							label="Account Name"
							icon="user-circle"
						/>
						<Input
							value={account.phoneNumber}
							outline
							onChange={this.handleChange.bind(this, "phoneNumber")}
							label="Phone"
							icon="phone"
						/>
						<Input
							value={account.faxNumber}
							outline
							onChange={this.handleChange.bind(this, "faxNumber")}
							label="Fax"
							icon="fax"
						/>
						<Input
							value={account.email}
							outline
							onChange={this.handleChange.bind(this, "email")}
							label="E-mail"
							icon="envelope"
						/>
					</Col>
					<Col className={"outlineBoxWithLabel"}>
						<label className="patientInfoLabel">Account Location Info</label>
						<Input
							value={account.addrLine1}
							outline
							onChange={this.handleChange.bind(this, "addrLine1")}
							label="Address"
							icon="address-book"
						/>
						<Input
							value={account.city}
							outline
							onChange={this.handleChange.bind(this, "city")}
							label="City"
							icon="city"
						/>
						<Input
							value={account.state}
							outline
							onChange={this.handleChange.bind(this, "state")}
							label="State"
							icon="map-marked-alt"
						/>
						<Input
							value={account.zip}
							outline
							onChange={this.handleChange.bind(this, "zip")}
							label="ZipCode"
							icon="map-pin"
						/>
					</Col>
				</Row>

				<Row>
					<Col className={"outlineBoxWithLabel"}>
						<label className="patientInfoLabel">Account Type</label>
						<div style={{marginBottom: "1.4rem"}}>
						<Select
							placeholder={
								account.marketingCampaign
									? account.marketingCampaign.name
									: "Select Marketing Campaign..."
							}
							options={this.state.marketingCampaigns}
							onChange={this.handleSelectChange.bind(this, "marketingCampaign")}
						/>
						</div>
						<div>
						<Select
							placeholder={
								account.accountType
									? account.accountType.name
									: "Select Account Type..."
							}
							options={this.state.accountTypes}
							onChange={this.handleSelectChange.bind(this, "accountTypeId")}
						/>
						</div>
					</Col>
					{companyName !== "rehabmedical" ?
						<Col className={"outlineBoxWithLabel"}>
							<label className="patientInfoLabel">Account Type</label>

							<Row style={{marginBottom: "0.4rem"}}>
								<Col>
									<MDBBtn
										disabled={account.isMaster}
										style={{display: "block", margin: "auto"}}
										onClick={() => {
											account.isMaster = true;
											account.masterAccountId = null;
											this.setState({
												eAccount: account,
											});
										}}
										// data-tip={"Set As A Master Account"}
										data-place={"bottom"}
										size="md"
										active={true}
										color="primary"
									>
										Set As A Master Account
									</MDBBtn>
									{account.isMaster ?
										<div className={"accountIconDiv"}>
											<MDBIcon icon={"check"} color={"primary"}/>
											This Is A Master Account
										</div> : ""}
								</Col>
								<Col>
									<MDBBtn
										disabled={!account.isMaster}
										style={{display: "block", margin: "auto"}}
										onClick={() => {
											account.isMaster = false;
											this.setState({
												eAccount: account,
											});
										}}
										data-place={"bottom"}
										size="md"
										color="danger"
									>
										Set As A Normal Account
									</MDBBtn>
									{account.isMaster ? "" :
										<div className={"accountIconDiv"}>
											<MDBIcon icon={"check"} color={"primary"}/>
											This Is A Normal Account
										</div>}
								</Col>
							</Row>
							{!account.isMaster ?
								<MDBSelect

									label={selAcc ? "Master Account" : "Select Master Account"}
									className={"smallMargin searchField"}
									outline={true}
									search={true}
									selected={selAcc ? selAcc.text : "Select Master Account"}
									options={this.state.masterAccounts}
									getValue={(e) => {
										if (e && e.length > 0)
											account.masterAccountId = e[0];
										else
											account.masterAccountId = null;

										this.setState({
											eAccount: account,
										});
									}}
								/>
								: ''}


							<Input type="number"
								   value={account.rentalFee}
								   placeholder="Rental Fee"
								   label="Rental Fee"
								   outline
								   onChange={(e) => {
									   account.rentalFee = parseFloat(e.target.value);
									   this.setState({
										   eAccount: account,
									   });
								   }}
							/>

						</Col>
						: <Col className={"outlineBoxWithLabelPlaceHolder"}>
						</Col>}
				</Row>
			</div>
		);
	}

	getAccountTypeList() {
		const { accountTypes } = this.context;

		let a = [];
		accountTypes.map((type) => {
			return a.push({
				label: type.name,
				value: type,
			});
		});
		this.setState({accountTypes: a});
		return a;
	}

	//retrieveContacts(id) {
	//	let t = this;

	//	return UserService.getContactsByAccount(id)
	//		.then((users) => {
	//			let cAry = [],
	//				cList = [],
	//				dt = this.state.contacts,
	//				contacts = users;

	//			contacts.forEach((contact) => {
	//				cAry.push({
	//					name: contact.lastname + "," + contact.firstname,
	//					type: contact.contactType ? contact.contactType.name : "No Type",
	//					role: contact.role || "No Role",
	//					phone: contact.phone || "No Phone",
	//					mail: contact.email || "No Email",
	//					edit: this.routeContact(contact),
	//				});
	//				cList.push({
	//					label: contact.firstname + ' ' + contact.lastname,
	//					value: { id: contact.id, firstname: contact.firstname, lastname: contact.lastname },
	//				})
	//			});

	//			dt.rows = cAry;

	//			t.setState({
	//				contacts: dt,
	//				contactList: cList,
	//				cLoaded: true,
	//			});
	//		})
	//		.catch((err) => {
	//			//handle error...BC
	//		});
	//}

	routeContact(contact) {
		return (
			<Fa
				icon={"edit"}
				style={{ color: "#00afd7" }}
				onClick={() => {
					this.props.history.push({
						pathname: "/contact/" + contact.id,
						state: { contact: contact },
					});
				}}
			/>
		);
	}

	renderContactTable() {
		if (this.state.cLoaded === true) {
			return (
				<DataTable striped info={false} small data={this.state.contacts}>
					{" "}
				</DataTable>
			);
		} else {
			return <div></div>;
		}
	}

	formatCurrency(expense) {
		return new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
		}).format(expense);
	}

	formatDateStringToISO(dateString) {
		if (dateString.includes("Z")) {
			return dateString;
		} else {
			return dateString.concat("Z")
		}
	}

	formatDateLong(date) {
		let dateString = this.formatDateStringToISO(date);
		let ndate = new Date(dateString);

		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "numeric",
			day: "2-digit",
			hour: "numeric",
			minute: "numeric"
		}).format(ndate);
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	generateNote(note) {
		return (
			<MDBCard style={{ backgroundColor: "#FFF7D1" }}>
				<MDBCardHeader style={{ backgroundColor: "#FFF2AB" }}>
					<MDBRow>
						<MDBCol size="5">
							{note.createdAt ? this.formatDateLong(note.createdAt): ""}
						</MDBCol>

						<MDBCol size="5">
							{note.createdBy ? note.createdBy.firstname + " " + note.createdBy.lastname : ""}
						</MDBCol>
						<MDBCol onClick={() => this.toggleEditModalPopup(note)} size="2">
							<MDBIcon
								style={{ color: "#857E59", padding: 4, float: "right" }}
								data-tip={"Edit"}
								icon="ellipsis-v"
							/>
						</MDBCol>
					</MDBRow>
				</MDBCardHeader>

				<MDBCardBody>{note.text}</MDBCardBody>
			</MDBCard>
		);
	}

	renderNotesSection() {
		let notes = this.sortNotes(this.state.allNotes);

		return (
			<div>
				{notes.map((n, idx) => {
					return (
						<div key={idx} style={{ paddingBottom: 6 }}>
							{this.generateNote(n)}
						</div>
					);
				})}
			</div>
		);
	}

	generateTask(note) {
		function renderAssignedTo() {
			if (note.assignedTo) {
				return (
					<span style={{ fontSize: 14 }}>
						{note.createBy ? note.createdby.username : " "}
						<MDBIcon
							icon="long-arrow-alt-right"
							data-tip={"Assigned To"}
						/>{" "}
						{note.assignedTo.username}
					</span>
				);
			}
		}

		return (
			<MDBCard style={{ backgroundColor: "#FFE4F1" }}>
				<MDBCardHeader style={{ backgroundColor: "#FFCCE5" }}>
					<MDBRow>
						<MDBCol size="5" style={{ fontSize: 14 }}>
							{note.createdAt ? this.formatDateLong(note.createdAt) : ""}
						</MDBCol>

						<MDBCol size="5" style={{ fontSize: 14 }}>
							{note.createdBy ? note.createdBy.firstname + " " + note.createdBy.lastname : ""}
							{renderAssignedTo()}
						</MDBCol>

						<MDBCol siz="2">
							<MDBIcon
								onClick={() => this.toggleEditModalPopup(note)}
								style={{
									color: "#7B626E",
									float: "right",
									padding: "4px 4px 4px 8px",
								}}
								data-tip={"Edit"}
								icon="ellipsis-v"
							/>
							<MDBIcon
								style={{
									color: "#7B626E",
									float: "right",
									padding: "4px 8px 4px 4px",
								}}
								onClick={() => this.updateNote(note, true)}
								data-tip={"Complete"}
								icon="check"
							/>
						</MDBCol>
					</MDBRow>
				</MDBCardHeader>

				<MDBCardBody>{note.text}</MDBCardBody>
				<ReactTooltip />
			</MDBCard>
		);
	}

	renderTasksSection() {
		let tasks = this.sortNotes(this.state.allNotes, true);

		return (
			<div>
				{tasks.map((n, idx) => {
					return (
						<div key={idx} style={{ paddingBottom: 6 }}>
							{this.generateTask(n)}
						</div>
					);
				})}
			</div>
		);
	}

	renderNotesList() {
		return (
			<div>
				<MDBRow>
					<MDBCol className={"pvNotesGroupOuterContainer"} size="6">
						<div className={"pvNotesGroupInnerContainer"}>
							{this.renderNotesSection()}
						</div>
					</MDBCol>

					<MDBCol className={"pvNotesGroupOuterContainer"} size="6">
						<div className={"pvNotesGroupInnerContainer"}>
							{this.renderTasksSection()}
						</div>
					</MDBCol>
				</MDBRow>
			</div>
		);
	}

	renderAddContactModal() {
		return (
			<div>
				<Container>
					<Row>
						<Modal
							centered
							className="form-elegant"
							isOpen={this.state.contactModalOpen}
							toggle={() => this.toggleContactModalPopup()}
						>
							<ModalBody
								style={{ backgroundColor: "#FFFFFF" }}
								className="mx-3"
							>
								<CardHeader
									color={"indigo"}
									className="form-header text-center"
								>
									Add New Contact
								</CardHeader>

								<CardBody>
									<Row style={{ display: "flex", justifyContent: "center" }}>
										<Col size="4">
											<Input
												label="First Name"
												value={this.state.nContact.firstname}
												onChange={this.contactChange.bind(this, "firstname")}
											/>
										</Col>

										<Col size="4">
											<Input
												label="Last Name"
												value={this.state.nContact.lastname}
												onChange={this.contactChange.bind(this, "lastname")}
											/>
										</Col>

										<Col size="4">
											<Input
												label="Phone"
												value={this.state.nContact.phone}
												onChange={this.contactChange.bind(this, "phone")}
											/>
										</Col>
									</Row>

									<Row style={{ display: "flex", justifyContent: "center" }}>
										<Col size="5">
											<Input
												label="Email"
												value={this.state.nContact.email}
												onChange={this.contactChange.bind(this, "email")}
											/>
										</Col>

										<Col size="5">
											<Input
												label="Title"
												value={this.state.nContact.role}
												onChange={this.contactChange.bind(this, "role")}
											/>
										</Col>
									</Row>

									<Row style={{ display: "flex", justifyContent: "center" }}>
										<Col size="8">
											<Select
												placeholder={"Select Contact Type..."}
												options={this.state.contactTypes}
												onChange={this.handleContactTypeSelected.bind(this)}
											/>
										</Col>
									</Row>

									<Col size="12">
										<hr />
									</Col>

									<Row>
										<Col size="5">
											<Input
												onClick={() => this.setState({ typeRadio: "Note" })}
												checked={this.state.typeRadio === "Note"}
												label="Note"
												type="radio"
												id="noteRadio"
											/>
										</Col>
										<Col size="5">
											<Input
												onClick={() => this.setState({ typeRadio: "Task" })}
												checked={this.state.typeRadio === "Task"}
												label="Task"
												type="radio"
												id="taskRadio"
											/>
										</Col>
									</Row>

									<Row>
										<Col md="12">
											<textarea
												placeholder={"Enter Text"}
												id={"newNoteText"}
												style={{ width: "100%", height: "160px" }}
											></textarea>
										</Col>
									</Row>
								</CardBody>
								<Button onClick={this.addNewContact.bind(this)}>
									{" "}
									Add Contact{" "}
								</Button>
							</ModalBody>
						</Modal>
					</Row>
				</Container>
			</div>
		);
	}

	renderNoteModal() {
		return (
			<MDBContainer>
				<MDBRow>
					<Modal
						centered
						isOpen={this.state.noteModalOpen}
						toggle={() => this.setState({ noteModalOpen: false })}
					>
						<ModalBody style={{ backgroundColor: "#FFFFFF" }} className="mx-3">
							<CardHeader color={"indigo"} className="form-header text-center">
								Add New Note
							</CardHeader>
							<form className=" mx-3 grey-text">
								<textarea
									placeholder={"Add note text here."}
									autoFocus
									id={"newActivityNoteText"}
									style={{ width: "100%", height: "200px" }}
								></textarea>

								<Row>
									<Col size="12">
										<div className={"datePickerOptions"}>
											<DatePicker
												format="MM-DD-YYYY"
												hint={"01-01-1900"}
												keyboard
												getValue={(evt) => {
													this.setState({ dueDate: evt });
												}}
												mask={[
													/\d/,
													/\d/,
													"-",
													/\d/,
													/\d/,
													"-",
													/\d/,
													/\d/,
													/\d/,
													/\d/,
												]}
												label="Due Date"
											/>
										</div>
									</Col>
								</Row>

								<Row>
									<Col size="6">
										<Input
											type="radio"
											gap
											label={"Note"}
											checked={this.state.taskCompleted}
											onClick={() => this.setState({ taskCompleted: true })}
											id={"noteRadio"}
										/>
									</Col>

									<Col size="6">
										<Input
											type="radio"
											gap
											label={"Task"}
											checked={!this.state.taskCompleted}
											onClick={() => this.setState({ taskCompleted: false })}
											id={"taskRadio"}
										/>
									</Col>
								</Row>
								<br />

								<ModalFooter>
									<span
										className={"ptBtn saveBtn"}
										data-tip={"Add Note"}
										onClick={this.addNewNote.bind(this)}
									>
										<Fa size="2x" icon={"check"}>
											{" "}
										</Fa>
									</span>
									<span
										className={"ptBtn cancelBtn"}
										data-tip={"Cancel"}
										onClick={() => this.setState({ noteModalOpen: false })}
									>
										<Fa size="2x" icon={"times"}>
											{" "}
										</Fa>
									</span>
								</ModalFooter>
								<ReactTooltip />
							</form>
						</ModalBody>
					</Modal>
				</MDBRow>
			</MDBContainer>
		);
	}

	renderAddCallModal() {
		return (
			<MDBModal
				size="lg"
				centered
				isOpen={this.state.callModalOpen}
				toggle={() => this.setState({ callModalOpen: false })}
			>
				<MDBModalHeader style={{ color: 'white', backgroundColor: '#5881c1' }}>
					Add New Call
				</MDBModalHeader>
				<MDBModalBody>
					<div>
						<MDBRow>
							<MDBCol md="6">
									<Input
										onChange={(evt) => {
											let nCall = this.state.nCall;
											nCall.quality = evt.target.checked;
											nCall.callType = null;
											nCall.callReason = null;
											this.setState({ nCall: nCall });
										}}
										filled
										label="Marketing"
										type="checkbox"
										id="checkbox6"
								/>
							</MDBCol>
								<MDBCol md="6">
									<DatePicker
										format="MM-DD-YYYY"
										hint={"01-01-1900"}
										keyboard
										getValue={(evt) => {
											let act = this.state.nCall;
											act.when = evt;
											this.setState({ nCall: act });
										}}
										mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
										label="Call Date"
										className="openDatePicker"
									/>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol md="12">
								<Select
									placeholder={"Select Contact..."}
									options={this.state.contactList}
									onChange={e => {
										let call = this.state.nCall,
											contact = { id: e.value.id, firstname: e.value.firstname, lastname: e.value.lastname, role: e.value.role };

										call.contacts.push(contact);

										this.setState({
											nCall: call,
										});
									}}
								/>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol md="12">
								<br />
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol md="12">
								{this.state.nCall.quality === false ?
									<Select
										placeholder={"Select Call Type..."}
										options={this.state.callTypes}
										onChange={(e) => {
											let call = this.state.nCall;

											call.callType = e.value;

											this.setState({
												nCall: call,
											});
										}}
									/>
									:
									<Select
										placeholder={"Select Call Reason..."}
										options={this.state.callReasons}
										onChange={(e) => {
											let call = this.state.nCall;

											call.callReason = e.value;

											this.setState({
												nCall: call,
												isOtherReason: e.value === "Other" ? "flex" : "none"
											});
										}}
									/>
								}
							</MDBCol>
							<MDBCol md="12">
								<br />
							</MDBCol>
						</MDBRow>
					</div>
				</MDBModalBody>
				<MDBModalFooter>
					<MDBRow>
						<MDBCol md="4">
							<div style={{ paddingTop: 25 }}>
								<MDBBtn
									size="sm"
									color={"success"}
									onClick={() => this.createActivity("Call")}
								>
									Save
								</MDBBtn>
							</div>
						</MDBCol>
					</MDBRow>
				</MDBModalFooter>
				{/*<MDBContainer>*/}
				{/*	<CardHeader*/}
				{/*		style={{ marginBottom: 2 }}*/}
				{/*		color={"indigo"}*/}
				{/*		className="form-header text-center"*/}
				{/*	>*/}
				{/*		Add New Call*/}
				{/*	</CardHeader>*/}
				{/*	<MDBRow>*/}
				{/*		<MDBCol size="6" className={"smallMargin uiDateOutline"}>*/}
				{/*			<DatePicker*/}
				{/*				style={{ paddingLeft: 0 }}*/}
				{/*				format="MM-DD-YYYY"*/}
				{/*				variant={"outlined"}*/}
				{/*				hint={"01-01-1900"}*/}
				{/*				size="small"*/}
				{/*				getValue={(evt) => {*/}
				{/*					let act = this.state.nCall;*/}
				{/*					act.endedAt = evt;*/}
				{/*					this.setState({ nCall: act, });*/}
				{/*				}}*/}
				{/*				mask={[*/}
				{/*					/\d/,*/}
				{/*					/\d/,*/}
				{/*					"-",*/}
				{/*					/\d/,*/}
				{/*					/\d/,*/}
				{/*					"-",*/}
				{/*					/\d/,*/}
				{/*					/\d/,*/}
				{/*					/\d/,*/}
				{/*					/\d/,*/}
				{/*				]}*/}
				{/*				label="Call Date"*/}
				{/*			/>*/}
				{/*		</MDBCol>*/}

				{/*		<MDBCol size="6">*/}
				{/*			<div style={{ paddingTop: 34 }}>*/}
				{/*				<Input*/}
				{/*					onChange={(evt) => {*/}
				{/*						let nCall = this.state.nCall;*/}
				{/*						nCall.quality = evt.target.checked;*/}
				{/*						nCall.callType = null;*/}
				{/*						nCall.callReason = null;*/}
				{/*						this.setState({ nCall: nCall });*/}
				{/*					}}*/}
				{/*					filled*/}
				{/*					label="Marketing"*/}
				{/*					type="checkbox"*/}
				{/*					id="checkbox6"*/}
				{/*				/>*/}
				{/*			</div>*/}
				{/*		</MDBCol>*/}

				{/*		<MDBCol size="12">*/}
				{/*			{ this.state.nCall.quality === true ?*/}
				{/*				<Select*/}
				{/*					placeholder={"Select Contact..."}*/}
				{/*					options={this.state.contactList}*/}
				{/*					onChange={(e) => {*/}
				{/*						let nCall = this.state.nCall;*/}
				{/*						let c = [];*/}
				{/*						c.push(e.value);*/}
				{/*						nCall.contacts = c;*/}
				{/*						this.setState({ nCall: nCall });*/}
				{/*					}}*/}
				{/*				/>*/}
				{/*				: '' }*/}
				{/*		</MDBCol>*/}

				{/*		<MDBCol size="12"><br/></MDBCol>*/}

				{/*		<MDBCol size="12">*/}
				{/*			{ this.state.nCall.quality === false ?*/}
				{/*				<Select*/}
				{/*					placeholder={"Select Call Type..."}*/}
				{/*					options={this.state.callTypes}*/}
				{/*					onChange={(e) => {*/}
				{/*						let nCall = this.state.nCall;*/}
				{/*						nCall.callType = e.value;*/}
				{/*						this.setState({ nCall: nCall });*/}
				{/*					}}*/}
				{/*				/>*/}
				{/*				:*/}
				{/*				<Select*/}
				{/*					placeholder={"Select Call Reason..."}*/}
				{/*					options={this.state.callReasons}*/}
				{/*					onChange={(e) => {*/}
				{/*						let nCall = this.state.nCall;*/}
				{/*						nCall.callReason = e.value;*/}
				{/*						this.setState({ nCall: nCall });*/}
				{/*					}}*/}
				{/*				/>*/}
				{/*			}*/}
				{/*		</MDBCol>*/}

				{/*		<MDBCol size="12"><br/></MDBCol>*/}

				{/*		<MDBCol size="12">*/}
				{/*			{ this.state.nCall.callReason === "Other" ?*/}
				{/*				<textarea*/}
				{/*					id={"newNoteText"}*/}
				{/*					style={{width:"100%"}}*/}
				{/*					placeholder={"Add a note..."}*/}
				{/*					onChange={(e) => {*/}
				{/*						let txt = e.target.value;*/}
				{/*						this.setState({*/}
				{/*							callNoteText: txt*/}
				{/*						})*/}
				{/*					}}*/}
				{/*				></textarea>*/}
				{/*				: ''*/}
				{/*			}*/}
				{/*		</MDBCol>*/}

				{/*		<MDBCol size="9"></MDBCol>*/}
				{/*		<MDBCol size="3">*/}
				{/*			<div style={{ paddingTop: 25 }}>*/}
				{/*				<MDBBtn*/}
				{/*					size="sm"*/}
				{/*					color={"success"}*/}
				{/*					onClick={() => this.createActivity("Call")}*/}
				{/*				>*/}
				{/*					Save*/}
				{/*				</MDBBtn>*/}
				{/*			</div>*/}
				{/*		</MDBCol>*/}
				{/*	</MDBRow>*/}
				{/*</MDBContainer>*/}
			</MDBModal>
		);
	}

	renderAddAppointmentModal() {
		return (
			<MDBModal
				centered
				isOpen={this.state.appointmentModalOpen}
				toggle={() => this.setState({ appointmentModalOpen: false })}
			>
				<MDBContainer>
					<CardHeader
						style={{ marginBottom: 2 }}
						color={"indigo"}
						className="form-header text-center"
					>
						Add New Appointment
					</CardHeader>

					<MDBRow>
						<MDBCol size="6" className={"smallMargin uiDateOutline"}>
							<DatePicker
								style={{ paddingLeft: 0 }}
								format="MM-DD-YYYY"
								variant={"outlined"}
								size="small"
								hint={"01-01-1900"}
								getValue={(evt) => {
									let act = this.state.nActivity;
									act.expenseDate = evt;
									this.setState({ nActivity: act });
								}}
								mask={[
									/\d/,
									/\d/,
									"-",
									/\d/,
									/\d/,
									"-",
									/\d/,
									/\d/,
									/\d/,
									/\d/,
								]}
								label="Appointment Date"
							/>
						</MDBCol>
						<MDBCol size="6">
							<MDBInput
								label="Amount Spent"
								outline
								style={{ paddingTop: 10 }}
								value={this.state.nActivity.expenses}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.expenses = evt.target.value;
									this.setState({ nActivity: act });
								}}
							/>
						</MDBCol>
					</MDBRow>

					<MDBRow>
						<Col size="6">
							<Select
								placeholder={"Select Card Type"}
								options={this.state.cardTypes}
								onChange={this.handleCardTypeSelected.bind(this)}
							/>
						</Col>

						<Col size="6">
							<Select
								placeholder={"Select Expense Type"}
								options={this.state.expenseTypes}
								onChange={this.handleExpenseTypeSelected.bind(this)}
							/>
						</Col>
					</MDBRow>

					<MDBRow>
						<Col size="12">
							<Input
								label="Appointment Description"
								outline
								containerClass={"medMargin"}
								value={this.state.nActivity.expenseDesc}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.expenseDesc = evt.target.value;
									this.setState({ nActivity: act });
								}}
							/>
						</Col>
					</MDBRow>

					<MDBRow>
						<Col size="6">
							<Input
								label="Vendor"
								outline
								containerClass={"medMargin"}
								value={this.state.nActivity.vendor}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.vendor = evt.target.value;
									this.setState({ nActivity: act });
								}}
							/>
						</Col>

						<Col size="6">
							<Input
								type="number"
								label="People Attending (amount)"
								outline
								containerClass={"medMargin"}
								value={this.state.nActivity.peopleAttending}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.peopleAttending = parseInt(evt.target.value);
									this.setState({ nActivity: act });
								}}
							/>
						</Col>
					</MDBRow>

					<MDBRow>
						<Col size="6">
							<Input
								label="Topics Discussed"
								outline
								containerClass={"medMargin"}
								value={this.state.nActivity.educationalTopics}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.educationalTopics = evt.target.value;
									this.setState({ nActivity: act });
								}}
							/>
						</Col>

						<Col size="6">
							<Input
								label="Physicians Attending (names)"
								outline
								containerClass={"medMargin"}
								value={this.state.nActivity.physiciansAttending}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.physiciansAttending = evt.target.value;
									this.setState({ nActivity: act });
								}}
							/>
						</Col>
					</MDBRow>

					<InputFile
						type="file"
						textFieldTitle={"Upload receipt (.jpg or .png only)"}
						className="form-control-file"
						name="file"
						getValue={this.encodeImageFileAsURL.bind(this)}
					/>
					<MDBRow style={{ justifyContent: "space-evenly" }}>
						{this.renderReceipt()}
					</MDBRow>

					<MDBBtn
						size="sm"
						color={"success"}
						onClick={() => this.createActivity("Appointment")}
						style={{ float: "right" }}
					>
						Save
					</MDBBtn>
				</MDBContainer>
			</MDBModal>
		);
	}

	renderEditNoteModal() {
		let note = this.state.modalNote,
			isTask = note.completed === false;

		let bgClass = "orderNotesModal",
			bgColor = "#FFF7D1";

		if (isTask) {
			bgClass = "orderTasksModal";
			bgColor = "#FFE4F1";
		}
		return (
			<MDBModal
				className={bgClass}
				centered
				isOpen={this.state.editNoteOpen}
				toggle={() => this.toggleEditModalPopup()}
			>
				<MDBModalBody>
					<MDBRow>
						<MDBCol size="12">
							<textarea
								id={"noteTextArea"}
								style={{
									width: "100%",
									height: "200px",
									backgroundColor: bgColor,
								}}
							>
								{note.text}
							</textarea>
						</MDBCol>

						<MDBCol size="12">
							<span>Updated: {GlobalFunctions.formatDate(new Date())}</span>

							<MDBIcon
								style={{ color: "#7B626E", float: "right", padding: 4 }}
								data-tip={"Update"}
								icon="check"
								onClick={() => {
									this.updateNoteModal(note);
									this.toggleEditModalPopup();
								}}
							/>
						</MDBCol>
					</MDBRow>
				</MDBModalBody>
				<ReactTooltip />
			</MDBModal>
		);
	}

	renderPricesheetSection() {
		if (!this.state.pricesheetsLoaded) {
			return this.renderLoadingSpinner()
        }
		return (
			<Card style={{ backgroundColor: "rgb(88,129,193)" }}>
				<InlineTable
					tableHeader="Account Pricesheets"
					paging={true}
					columnFieldMap={this.state.pricesheetColumnMap}
					allowRowSearch={false}
					batchSave={true}
					inlineEdit={true}
					rows={this.state.pricesheets}
					addItemMethod={this.createNewPricesheet.bind(this)}
					editRowsMethod={this.editPricesheetRows.bind(this)}
					modalHeader="Add Pricesheet"
				/>
			</Card>)
	}

	createNewPricesheet(sheet) {
		const { currentUser } = this.context;
		sheet.updatedById = currentUser.id;
		sheet.updatedOn = new Date().toISOString();
		sheet.accountId = parseInt(this.state.accountId);

		AccountService.CreateAccountPricesheet(sheet)
			.then(res => {
				this.setState({
					pricesheetsLoaded: false,
				}, () => this.getPricesheets(this.state.accountId))
			})
			.catch(ex => {
				console.log(ex)
            })
	}

	editPricesheetRows(sheets) {
		const { currentUser } = this.context;

		sheets.forEach(sheet => {
			sheet.updatedById = currentUser.id;
			sheet.updatedOn = new Date().toISOString();
		});

		AccountService.UpdatePricesheets(sheets)
			.then(res => {
				this.setState({
					pricesheetsLoaded: false,
				}, () => this.getPricesheets(this.state.accountId))
			})
			.catch(ex => {
				console.log(ex)
			})
	}

	render() {
		const { companyName } = this.context;
		if (this.state.nLoaded === false) {
			return this.renderLoadingSpinner();
		}

		let account = this.state.eAccount;

		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>

				<CardHeader
					style={{
						paddingTop: 12,
						paddingBottom: 8,
						marginBottom: 10,
						color: "white",
						fontSize: 18,
						backgroundColor: "#5881c1",
					}}
				>
					<Row>
						<Col size="2">
							<MDBBtn
								className="px-2 toolbarButton"
								data-tip="Return to Order Listing"
								data-place={"right"}
								outline
								rounded
								onClick={() => this.props.history.push("/accounts")}
								size="sm"
								color={"warning"}
							>
								<MDBIcon icon="long-arrow-alt-left" />
							</MDBBtn>
							<ReactTooltip />
						</Col>
						<Col size="5">
							<span style={{ fontSize: 24 }}>{account.name}</span>
						</Col>
						<Col size="3">
							<span style={{ fontSize: 24 }}>
								{account.owner ? account.owner.firstname + " " + account.owner.lastname : "No Owner"}
							</span>
						</Col>

						<Col size="2">
							<MDBBtn
								className="px-2 toolbarButton"
								outline
								rounded
								size="sm"
								color={"secondary"}
								data-tip={"Add Note / Task"}
								data-place={"bottom"}
								onClick={() => {
									this.toggleNoteModalPopup();
								}}
							>
								<MDBIcon icon="sticky-note" />
							</MDBBtn>

							<MDBBtn
								className="px-2 toolbarButton"
								outline
								rounded
								size="sm"
								color={"amber"}
								data-tip={"Add Call"}
								data-place={"bottom"}
								onClick={() => {
									let a = this.state.nActivity;
									a.type = "Call";
									this.setState({
										callModalOpen: true,
										nActivity: a,
									});
								}}
							>
								<MDBIcon icon="phone" />
							</MDBBtn>

							<MDBBtn
								className="px-2 toolbarButton"
								outline
								rounded
								size="sm"
								color={"yellow"}
								data-tip={"Add Appointment"}
								data-place={"bottom"}
								onClick={() => {
									let a = this.state.nActivity;
									a.type = "Appointment";
									this.setState({
										appointmentModalOpen: true,
										nActivity: a,
									});
								}}
							>
								<MDBIcon icon="handshake" />
							</MDBBtn>

							<MDBBtn
								className="px-2 toolbarButton"
								outline
								rounded
								size="sm"
								color={"orange"}
								data-tip={"Add Contact"}
								data-place={"bottom"}
								onClick={() => {
									this.toggleContactModalPopup();
								}}
							>
								<MDBIcon icon="user" />
							</MDBBtn>

							<MDBBtn
								onClick={() => this.updateAccount()}
								data-tip={"Save Account"}
								data-place={"bottom"}
								outline
								rounded
								size="sm"
								color="success"
								className="px-2 toolbarButton"
							>
								<MDBIcon icon="save" />
							</MDBBtn>
						</Col>
					</Row>
				</CardHeader>
				{this.renderNoteModal()}
				{this.renderAddCallModal()}
				{this.renderAddAppointmentModal()}
				<Container className="mt-4">
					<Row>
						<Col md="12">
							<Nav tabs className="nav-justified navFull" color={"blue"}>
								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({
											active: this.state.activeItem === "1",
										})}
										onClick={() => {
											this.toggle("1");
										}}
										role="tab"
									>
										Notes & Tasks
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({
											active: this.state.activeItem === "2",
										})}
										onClick={() => {
											this.toggle("2");
										}}
										role="tab"
									>
										Activities
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({
											active: this.state.activeItem === "3",
										})}
										onClick={() => {
											this.toggle("3");
										}}
										role="tab"
									>
										Orders
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({
											active: this.state.activeItem === "4",
										})}
										onClick={() => {
											this.toggle("4");
										}}
										role="tab"
									>
										Contacts
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({
											active: this.state.activeItem === "5",
										})}
										onClick={() => {
											this.toggle("5");
										}}
										role="tab"
									>
										Details
									</NavLink>
								</NavItem>

								{companyName !== "rehabmedical" ?
									<NavItem>
										<NavLink
											link
											to="#"
											className={classnames({
												active: this.state.activeItem === "6",
											})}
											onClick={() => {
												this.toggle("6");
											}}
											role="tab"
										>
											Pricesheets
										</NavLink>
									</NavItem>
									: ""}
							</Nav>

							<TabContent className="card" activeItem={this.state.activeItem}>
								<TabPane tabId="1" role="tabpanel">
									{this.renderNotesList()}
								</TabPane>
								<TabPane tabId="2" role="tabpanel">
									{this.renderActivityTable()}
								</TabPane>
								<TabPane tabId="3" role="tabpanel">
									{this.renderOrderTable()}
								</TabPane>
								<TabPane tabId="4" role="tabpanel">
									{this.renderContactTable()}
								</TabPane>
								<TabPane tabId="5" role="tabpanel">
									{this.renderInformationSection()}
								</TabPane>
								{companyName !== "rehabmedical" ?
									<TabPane tabId="6" role="tabpanel">
										{this.renderPricesheetSection()}
									</TabPane>
									: ""}
							</TabContent>
						</Col>
					</Row>
					{this.renderAddContactModal()}
					{this.renderEditNoteModal()}
				</Container>
				<ReactTooltip />
			</div>
		);
	}
}