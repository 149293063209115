import TermCreateEdit from "../AdminTermCreateEdit";

export default class AdminTermCreateEditInsuranceSubTypeGroup extends TermCreateEdit {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return 'insurance_sub_type_group';
    }
}
