import Admin from "../Admin";
import React from "react";
import CardAdminBaseUnitList from "../../../Component/Card/Admin/BaseUnit/CardAdminBaseUnitList";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIAddIcon from "@mui/icons-material/Add";

export default class AdminBaseUnitList extends Admin {

    /**
     * Render the content.
     */
    renderContent() {
        return (
            <CardAdminBaseUnitList />
        );
    }

    renderHeader() {
        const handleClickCreate = () => {
            this.props.history.push({
                pathname: "/admin/baseUnit"
            });
        };

        const buttons = [
            (<MUIButton
                component="label"
                variant="contained"
                startIcon={<MUIAddIcon />}
                onClick={handleClickCreate}>Create New</MUIButton>),
        ];

        return (
            <PageHeader title="Base Units" buttons={buttons} />
        );
    }
}
