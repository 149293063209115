import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import Department from "../../Utility/Crud/Department";
import PropTypes from "prop-types";
import * as MUIColors from "@mui/material/colors";

export default class TileDepartment extends Tile {
    static contextTypes = {
        colorsIndexed: PropTypes.object,
    };

    /**
      * @returns {string} The Department name.
      */
    getTitle() {
        return this.props.department ? Department.getDisplayName(this.props.department) : "None";
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.department ? Department.getDisplayName(this.props.department) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        const color = this.props.department ? this.context.colorsIndexed[this.props.department.colorId] : null;
        return this.props.department ? MUIColors[color.hue][color.shade] : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return Department.getIcon();
    }
}
