import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import orderService from "../../../Seating/Security/OrderService/orderService";
import { enqueueSnackbar as notistackEnqueueSnackbar } from "notistack";
import { closeSnackbar as notistackCloseSnackbar } from "notistack"
import Card from "../../Card";
import DataGrid from "../../DataGrid";
import Progress from "../../Progress";
import Setting from "../../../Utility/Setting";
import User from "../../../Utility/Crud/User";
import OrderStatusReason from "../../../Utility/Crud/OrderStatusReason";
import OrderActivityType from "../../../Utility/Crud/OrderActivityType";
import InsuranceType from "../../../Utility/Crud/InsuranceType";
import InsuranceSubType from "../../../Utility/Crud/InsuranceSubType";
import Location from "../../../Utility/Crud/Location";
import Region from "../../../Utility/Crud/Region";
import ProductType from "../../../Utility/Crud/ProductType";
import ProductSubType from "../../../Utility/Crud/ProductSubType";
import OrderStatus from "../../../Utility/Crud/OrderStatus";
import IssueCategory from "../../../Utility/Crud/IssueCategory";
import IssueReason from "../../../Utility/Crud/IssueReason";
import Term from "../../../Utility/Crud/Term";
import DialogHelpInfo from "../../Dialog/DialogHelpInfo";

import MUICard from "@mui/material/Card";
import MUIRefreshIcon from '@mui/icons-material/Refresh';
import MUIFilterAltIcon from '@mui/icons-material/FilterAlt';
import MUIVisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MUIFileDownloadIcon from '@mui/icons-material/FileDownload';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIMenuItem from "@mui/material/MenuItem";
import MUICloudSyncIcon from "@mui/icons-material/CloudSync";
import MUIHelpIcon from '@mui/icons-material/Help';

import Exporter from "../../../Utility/Exporter";

export default class CardQueueDataGrid extends Card {
    static contextTypes = {
        usersIndexed: PropTypes.object,
        orderStatusesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
        insuranceSubTypesIndexed: PropTypes.object,
        locationsIndexed: PropTypes.object,
        orderActivityTypesIndexed: PropTypes.object,
        insuranceTypesIndexed: PropTypes.object,
        regionsIndexed: PropTypes.object,
        productTypesIndexed: PropTypes.object,
        productSubTypesIndexed: PropTypes.object,
        issueCategoriesIndexed: PropTypes.object,
        issueReasonsIndexed: PropTypes.object,
        termsIndexed: PropTypes.object,
        currentUser: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state.viewOutOfBoundsOnly = this.props.viewOutOfBoundsOnly || false;
        this.state.formattedRows = [];
    }

    /**
     * Handle removes or adds to the rows prop.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.loadedAt !== this.props.loadedAt) {
            this.setState({
                formattedRows: this.getFormattedRows(this.state.viewOutOfBoundsOnly),
            });
        }
    }

    /**
     * Pull settings and save them to state.
     */
    componentDidMount() {
        const savedSorting = Setting.get(`queue.${this.props.queue.id}.sorting`);
        const savedGrouping = Setting.get(`queue.${this.props.queue.id}.grouping`);

        if (savedSorting !== undefined) {
            // Use the saved sorting.
            this.state.sorting = savedSorting.filter(sorting => sorting.columnName in this.columns);
            if (this.state.sorting.length !== savedSorting) {
                Setting.set(`queue.${this.props.queue.id}.sorting`, this.state.sorting);
            }
        } else {
            // Convert the queue sorting object to one that the grid understands.
            this.state.sorting = this.props.queue.view.sorts ? this.props.queue.view.sorts.map((sort) => ({
                columnName: sort.key,
                direction: sort.direction,
            })) : [];
        }

        if (savedGrouping !== undefined) {
            // Use the saved grouping. Filter out columns that no longer exist
            // and if there are any save that as the new setting.
            this.state.grouping = savedGrouping.filter(grouping => grouping.columnName in this.columns);
            if (this.state.grouping.length !== savedGrouping) {
                Setting.set(`queue.${this.props.queue.id}.grouping`, this.state.grouping);
            }
        } else {
            // Convert the queue grouping object to one that the grid understands.
            this.state.grouping = this.props.queue.view.groups ? this.props.queue.view.groups.map((group) => ({
                columnName: group,
            })) : [];
        }

         // Ensure default grouping columns are included in the sorting list with ascending order
        const finalSorting = this.updateSortingFromGrouping(this.state.grouping, this.state.sorting, this.props.queue.view.sorts || []);

        this.applyGroupingToSorting(this.state.grouping, finalSorting)

        const savedExpandedGroups = Setting.get(`queue.${this.props.queue.id}.expandedGroups`);
        if (savedExpandedGroups !== undefined) {
            // Use the saved expandedGroups. This does NOT break if keys exist
            // that do not exist in the data.
            this.state.expandedGroups = savedExpandedGroups;
        } else {
            this.state.expandedGroups = [];
        }
    }

    /**
     * @returns A MUI Card component with a header and content. Overrides the
     * default card properties for a custom 100% height look.
     */
    render() {
        return (
            <>
                <MUICard sx={{ position: "relative", height: "100%", background: this.getBackground() }}>
                    <DialogHelpInfo open={this.state.openDialogInfo} header={"Help"} onClose={() => this.setState({openDialogInfo: false})}/>
                    {this.renderContent()}
                    {this.renderInternal()}
                </MUICard>
            </>
        );
    }

    /**
     * Override this in subclasses and put any special render code you need in
     * this function.
     */
    renderInternal() {
        return null;
    }

    /**
    * Renders the Queue Data Grid with custom action menu items.
    */
    renderContent() {
        if (this.props.loadedAt) {
            const columns = [];
            const columnWidths = [];

            this.props.queue.view.columns.forEach((column) => {
                if (this.columns[column]) {
                    columns.push({
                        title: this.columns[column].name,
                        name: column,
                        type: this.columns[column].type
                    });

                    columnWidths.push({
                        columnName: column,
                        width: this.columns[column].width,
                    });
                } else {
                    console.warn(`Column "${column}" is not defined.`);
                }
            });

            const rowActions = [];
            if (this.props.queue.view.rowActions && this.props.queue.view.rowActions.length > 0) {
                columns.unshift({
                    title: " ",
                    name: "rowActions",
                    type: "rowActions"
                });
                columnWidths.unshift({
                    columnName: "rowActions",
                    width: 70,
                });

                this.props.queue.view.rowActions.forEach((rowAction) => {
                    switch (rowAction) {
                        case "syncToBrightree":
                            rowActions.push(
                                {
                                    rowAction: rowAction,
                                    component: (
                                        <MUIMenuItem>
                                            <MUIListItemIcon>
                                                <MUICloudSyncIcon fontSize="small" />
                                            </MUIListItemIcon>
                                            Sync to Brightree
                                        </MUIMenuItem>
                                    ),
                                    isDisabled: (row) => {
                                        return row["order_activity.bt_so_id"] !== null
                                    }
                                }
                            );
                            break;
                        default:
                            console.warn("Undefined row action: " + rowAction);
                            break;
                    }
                });
            }

            return (
            <DataGrid
                rows={this.state.formattedRows}
                columns={columns}
                actions={this.getActions()}
                rowActions={rowActions}
                onClick={this.handleClickRow.bind(this)}
                onClickRowAction={this.handleClickRowAction.bind(this)}
                sorting={this.state.sorting}
                onSortingChange={this.handleSortingChange.bind(this)}
                grouping={this.state.grouping}
                onGroupingChange={this.handleGroupingChange.bind(this)}
                expandedGroups={this.state.expandedGroups}
                onExpandedGroupsChange={this.handleExpandedGroupsChange.bind(this)}
                defaultColumnWidths={columnWidths}
            />);
        }

        return (<Progress message="Loading Queue..." />);
    }

    /**
     * @returns {array} Actions for the current queue.
     */
    getActions() {
        let actions = []

        actions.push(
            <MUIMenuItem value={"Reload Queue"} onClick={this.props.onReload} >
                <MUIListItemIcon>
                    <MUIRefreshIcon fontSize="small" />
                </MUIListItemIcon>
                Reload Queue
            </MUIMenuItem>
        );

        actions.push(
            <MUIMenuItem disabled={!this.hasSavedView()} value={"Reset View"} onClick={this.clearSavedView.bind(this)} >
                <MUIListItemIcon>
                    <MUIVisibilityOffIcon fontSize="small" />
                </MUIListItemIcon>
                Reset View
            </MUIMenuItem>
        );

        actions.push(
            <MUIMenuItem value={`View ${this.state.viewOutOfBoundsOnly ? "All" : "OOB Only"}`} onClick={() => { this.setState({ viewOutOfBoundsOnly: !this.state.viewOutOfBoundsOnly, formattedRows: this.getFormattedRows(!this.state.viewOutOfBoundsOnly) }) }} >
                <MUIListItemIcon>
                    <MUIFilterAltIcon fontSize="small" />
                </MUIListItemIcon>
                {`View ${this.state.viewOutOfBoundsOnly ? "All" : "OOB Only"}`}
            </MUIMenuItem>
        );

        actions = actions.concat(this.getActionsInternal());

        actions.push(
            <MUIMenuItem disabled={!this.props.rows || this.props.rows.length === 0} value={"Export CSV"} onClick={this.exportCSV.bind(this)} >
                <MUIListItemIcon>
                    <MUIFileDownloadIcon fontSize="small" />
                </MUIListItemIcon>
                Export CSV
            </MUIMenuItem>
        );

        actions.push(
            <MUIMenuItem disabled={!this.props.rows || this.props.rows.length === 0} value={"Help"} onClick={() => this.setState({openDialogInfo: true})} >
                <MUIListItemIcon>
                    <MUIHelpIcon fontSize="small" />
                </MUIListItemIcon>
                Help
            </MUIMenuItem>
        );

        return actions;
    }

    /**
     * Override this in subclasses and put any special actions you need in this
     * function.
     */
    getActionsInternal() {
        return [];
    }

    /**
     * Takes an array of raw rows with all available columns and returns an
     * array of rows with only the relevant columns and also formats IDs into
     * strings, etc as the DataGrid component has limited/no knowledge of these
     * objects for performance.
     */
    getFormattedRows(viewOutOfBoundsOnly) {
        const formattedRows = [];

        // Find all of the sourced columns.
        const sourcedColumns = Object.entries(this.columns)
            .filter(([_, value]) => value.source !== undefined)
            .map(([key, value]) => ({
                source: value.source,
                destination: key
            }));

        // Iterate over all of the rows
        this.props.rows.forEach((row) => {
            // Conditionally ignore out of bounds.
            if (viewOutOfBoundsOnly === true && row.out_of_bounds !== true) {
                return;
            }

            // The resultant row object.
            const formattedRow = {};

            // Iterate over all of the columns in the row and format the data.
            Object.keys(row).forEach((key) => {
                if (!this.columns[key]) {
                    throw new Error(`Column not defined: ${key}`);
                }

                formattedRow[key] = this.getFormattedColumn(
                    row,
                    key,
                    this.columns[key].type
                );
            });

            // Add in any sourced columns
            sourcedColumns.forEach((column) => {
                formattedRow[column.destination] = this.getFormattedColumn(
                    row,
                    column.source,
                    this.columns[column.destination].type
                );
            });

            formattedRows.push(formattedRow);
        });

        return formattedRows;
    }

    /**
     * @param {object} row The full row.
     * @param {string} key They key for the column to format.
     *
     * @returns A formatted column.
     */
    getFormattedColumn(row, key, type) {
        switch (type) {
            case "user":
                return row[key] === null ? null : User.getDisplayName(this.context.usersIndexed[row[key]]);
            case "orderStatusReason":
                return row[key] === null ? null : OrderStatusReason.getDisplayName(this.context.orderStatusReasonsIndexed[row[key]]);
            case "orderActivityType":
                return row[key] === null ? null : OrderActivityType.getDisplayName(this.context.orderActivityTypesIndexed[row[key]]);
            case "insuranceType":
                return row[key] === null ? null : InsuranceType.getDisplayName(this.context.insuranceTypesIndexed[row[key]]);
            case "insuranceSubType":
                return row[key] === null ? null : InsuranceSubType.getDisplayName(this.context.insuranceSubTypesIndexed[row[key]]);
            case "productType":
                return row[key] === null ? null : ProductType.getDisplayName(this.context.productTypesIndexed[row[key]]);
            case "productSubType":
                return row[key] === null ? null : ProductSubType.getDisplayName(this.context.productSubTypesIndexed[row[key]]);
            case "productSubTypes":
                if (row[key] === null) {
                    return null;
                } else {
                    const productSubTypes = JSON.parse(row[key]);
                    return "[" + productSubTypes.length + "] " + productSubTypes
                        .map(productSubType => ProductSubType.getDisplayName(this.context.productSubTypesIndexed[productSubType]))
                        .join(" • ");
                }
            case "location":
                return row[key] === null ? null : Location.getDisplayName(this.context.locationsIndexed[row[key]]);
            case "region":
                return row[key] === null ? null : Region.getDisplayName(this.context.regionsIndexed[row[key]]);
            case "orderStatus":
                return row[key] === null ? null : OrderStatus.getDisplayName(this.context.orderStatusesIndexed[row[key]]);
            case "date":
                return row[key] === null ? null : moment.utc(row[key]).format("YYYY-MM-DD");
            case "dateMonthYear":
                return row[key] === null ? null : moment.utc(row[key]).format("YYYY-MM");
            case "issueCategory":
                return row[key] === null ? null : IssueCategory.getDisplayName(this.context.issueCategoriesIndexed[row[key]]);
            case "issueReason":
                return row[key] === null ? null : IssueReason.getDisplayName(this.context.issueReasonsIndexed[row[key]]);
            case "patientIssueIssueStatus":
                return row[key] === null ? null : row[key] === 1 ? 'Closed' : 'Open';
            case "term":
                return row[key] === null ? null : Term.getDisplayName(this.context.termsIndexed[row[key]]);
            case "boolean":
                return row[key] === null ? null : row[key] === true ? "Yes" : "No";
            case "phone":
                return row[key] === null ? null : row[key].replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3').padStart(14, row[key]);
            default:
                return row[key];
        }
    }

    /**
     * Saves the sorting and updates the state so the grid rerenders.
     *
     * @param {Array} sorting
     */
    handleSortingChange(sorting) {
        this.setState({
            sorting: sorting
        });
        Setting.set(`queue.${this.props.queue.id}.sorting`, sorting);
    }

    /**
     * Saves the grouping and updates the state so the grid rerenders.
     *
     * @param {Array} grouping
     */
    handleGroupingChange(grouping) {
        // Remove nonDefaultSorting values from updatedSorting
        const finalSorting =  this.updateSortingFromGrouping(grouping, this.state.sorting, this.props.queue.view.sorts || []);

        this.applyGroupingToSorting(grouping, finalSorting)
    }

    /**
     * Saves the expanded groups and updates the state so the grid rerenders.
     *
     * @param {Array} expandedGroups
     */
    handleExpandedGroupsChange(expandedGroups) {
        this.setState({
            expandedGroups: expandedGroups
        });
        Setting.set(`queue.${this.props.queue.id}.expandedGroups`, expandedGroups);
    }

     /**
    * Updates sorting to include all grouping columns in ascending order
    * and ensures that sorting reflects the current grouping and default settings.
    *
    * @param {Array} grouping - The updated grouping list.
    * @param {Array} currentSorting - The current sorting list.
    * @param {Array} defaultSorting - The default sorting list.
    * @returns {Array} - The updated sorting list.
    */
    updateSortingFromGrouping(grouping, currentSorting, defaultSorting) {
        // Extract column names from the new grouping
        const newGroupingColumns = new Set(grouping.map(g => g.columnName));

        // Create a set of column names currently in the sorting list
        const currentSortingColumns = new Set(currentSorting.map(s => s.columnName));

        // Add new grouping columns to the sorting list
        const updatedSorting = [...currentSorting];
        newGroupingColumns.forEach(columnName => {
            if (!currentSortingColumns.has(columnName)) {
                updatedSorting.push({
                    columnName: columnName,
                    direction: 'asc'  // Default sorting direction
                });
            }
        });

        // Remove columns from the sorting list that are no longer in grouping
        const filteredSorting = updatedSorting.filter(sort => !newGroupingColumns.has(sort.columnName));

        // Extract column names from defaultSorting
        const defaultSortingColumns = new Set(defaultSorting.map(sort => sort['key']));

        // Create a new array of values in filteredSorting that are not in defaultSorting
        const nonDefaultSorting = filteredSorting.filter(sort => !defaultSortingColumns.has(sort.columnName));

        // Remove nonDefaultSorting values from updatedSorting
        return updatedSorting.filter(sort => !nonDefaultSorting.some(nonDefault => nonDefault.columnName === sort.columnName));
    }

    applyGroupingToSorting(grouping, sorting) {
        // Update state
        this.setState({
            grouping: grouping,
            sorting: sorting
        });

        // Save new settings
        Setting.set(`queue.${this.props.queue.id}.grouping`, grouping);
        Setting.set(`queue.${this.props.queue.id}.sorting`, sorting);
    }

    /**
     * Handle clicking on a grid row. Probably want to override this in the
     * child class.
     */
    handleClickRow(row) {
        console.log(row);
    }

    /**
     * @param {string} rowAction The action that was performed.
     * @param {object} row The row the action was performed for.
     */
    handleClickRowAction(rowAction, row) {
        switch (rowAction) {
            case "syncToBrightree":
                this.handleSyncToBrightree(row);
                break;
            default:
                console.warn(`Row action not defined: ${rowAction}`);
                break;
        }
    }

    /**
     * @param {object} row The row to sync.
     */
    handleSyncToBrightree(row) {
        const syncingSnackbar = notistackEnqueueSnackbar('Syncing to Brightree...', { variant: "info", persist: true });
        orderService.syncOrderWithBT(row["order_activity.id"])
            .then(() => {
                notistackCloseSnackbar(syncingSnackbar)
                notistackEnqueueSnackbar('Sync complete!', { variant: "success" });
                this.props.onReload();
            })
            .catch(() => {
                notistackCloseSnackbar(syncingSnackbar)
                notistackEnqueueSnackbar('Sync failed!', { variant: "error" });
            });
    }

    /**
     * Clear saved view.
     */
    clearSavedView() {
        Setting.clear(`queue.${this.props.queue.id}.sorting`);
        Setting.clear(`queue.${this.props.queue.id}.grouping`);
        Setting.clear(`queue.${this.props.queue.id}.expandedGroups`);

        // Get default sorts and groups from the queue view
        const defaultSorts = this.props.queue.view.sorts || [];
        const defaultGroups = this.props.queue.view.groups || [];

        // Create default sorting array from default sorts
        const defaultSorting = defaultSorts.map(sort => ({
            columnName: sort.key,
            direction: sort.direction,
        }));

        // Create default grouping array
        const defaultGrouping = defaultGroups.map(group => ({
            columnName: group,
        }));

        // Ensure all grouping columns are in the sorting list with 'asc' direction
        const sortingColumns = new Set(defaultSorting.map(sort => sort.columnName));

        const updatedSorting = [
            ...defaultSorting,
            ...defaultGrouping.filter(group => !sortingColumns.has(group.columnName)).map(group => ({
                columnName: group.columnName,
                direction: 'asc',  // Default sorting direction
            }))
        ];

        this.applyGroupingToSorting(defaultGrouping, updatedSorting)

        // Update state
        this.setState({
            expandedGroups: []
        });
    }

    /**
     * @return {boolean} Whether or not there is currently a saved view.
     */
    hasSavedView() {
        return (
            Setting.get(`queue.${this.props.queue.id}.sorting`) !== undefined ||
            Setting.get(`queue.${this.props.queue.id}.grouping`) !== undefined ||
            Setting.get(`queue.${this.props.queue.id}.expandedGroups`) !== undefined
        );
    }

    exportCSV() {
        const csvRows = [];

        // Header row
        const columns = [];
        this.props.queue.view.columns.forEach((column) => {
            columns.push(this.columns[column].name);
        });
        csvRows.push(columns);

        // Table rows
        const rows = this.state.formattedRows;
        rows.forEach((row) => {
            const csvRow = [];
            this.props.queue.view.columns.forEach((column) => {
                csvRow.push(row[column]);
            });
            csvRows.push(csvRow);
        });

        const filename = `${this.props.queue.name} - ${moment(this.props.loadedAt).format("YYYY-MM-DD h mm a")}.csv`

        Exporter.exportCSV(csvRows, filename);
    }
}