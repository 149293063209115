import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import MUIGrid from "@mui/material/Grid";
import TileUser from "../../../Tile/TileUser";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@material-ui/icons/Delete";
import DialogSearchUser from "../../../Dialog/Search/DialogSearchUser";

export default class CardAdminDepartmentManagement extends Card {
    constructor(props) {
        super(props);

        this.state.executiveDialogOpen = false;
        this.state.directorDialogOpen = false;
    }

    static contextTypes = {
        usersIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        let department = this.props.department;
        let executiveUser = null;
        let directorUser = null;

        if (department && department.executiveId !== null) {
            executiveUser = this.context.usersIndexed[department.executiveId]
        }

        if (department && department.directorId !== null) {
            directorUser = this.context.usersIndexed[department.directorId]
        }

        // Executive
        const handleEditExecutive = () => {
            this.setState({ executiveDialogOpen: true });
        }
        const handleClearExecutive = () => {
            this.props.onChange({ executiveId: null });
        }
        const executiveIconButtons = [
            (<MUIIconButton onClick={handleEditExecutive}><MUIEditIcon /></MUIIconButton>)
        ];

        if (executiveUser) {
            executiveIconButtons.push(
                (<MUIIconButton onClick={handleClearExecutive}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // Director
        const handleEditDirector = () => {
            this.setState({ directorDialogOpen: true });
        }
        const handleClearDirector = () => {
            this.props.onChange({ directorId: null });
        }
        const directorIconButtons = [
            (<MUIIconButton onClick={handleEditDirector}><MUIEditIcon /></MUIIconButton>)
        ];

        if (directorUser) {
            directorIconButtons.push(
                (<MUIIconButton onClick={handleClearDirector}><MUIDeleteIcon /></MUIIconButton>)
            )
        }

        // Render
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileUser
                            user={executiveUser}
                            iconButtons={executiveIconButtons}
                            label="Executive"
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileUser
                            user={directorUser}
                            iconButtons={directorIconButtons}
                            label="Director"
                        />
                    </MUIGrid>
                </MUIGrid>
                <DialogSearchUser
                    open={this.state.executiveDialogOpen}
                    onClose={() => this.setState({ executiveDialogOpen: false })}
                    onSelect={(user) => {
                        this.props.onChange({ executiveId: user.id });
                    }}
                />
                <DialogSearchUser
                    open={this.state.directorDialogOpen}
                    onClose={() => this.setState({ directorDialogOpen: false })}
                    onSelect={(user) => {
                        this.props.onChange({ directorId: user.id });
                    }}
                />
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Management"
    }
}