import Crud from "../Crud";
import MUICyanColor from "@mui/material/colors/cyan";

/**
 * Static helper functions related to BtProducts.
 */
export default class BtProduct extends Crud {

    /**
     * @param {object} btProduct The btProduct object.
     * @returns The display name of the btProduct.
     */
    static getDisplayName(btProduct) {
        return btProduct.name;
    }

    /**
     * Determines if a btProduct matches a search query.
     *
     * @param {object} btProduct The btProduct to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the btProduct matches the query.
     */
    static match(btProduct, query) {
        return (
            (btProduct.name !== null && btProduct.name.toLowerCase().includes(query)) ||
            btProduct.id === +query
        );
    }

    /**
     * @param {array} btProducts The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(btProducts) {
        return btProducts.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUICyanColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Accessible";
    }
}