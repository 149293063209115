import React from "react";
import PropTypes from "prop-types";
import {
	toast,
	MDBIcon,
	MDBRow,
	MDBCol,
	CardHeader,
	CardBody,
	Button, Input, Col,
} from "mdbreact";
import PurchaseQueueService from "../Security/PurchaseQueueService/purchaseQueueService";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export default class poDeliveryUpdate extends React.Component {
	constructor(props) {
		super(props);

		this.onClose = props.onClose;

		this.state = {
			poNumber:"",
			expectedShipDate: null,
			confirmationNumber: "",
			trackingNumber: "",
			searchResults:[],
			badSearch: false,
			activeOrder: null,
			selectedResult: [],
			vendorList: [],
			selectedVendor: {},
			selectedVendorName: "Select Vendor"
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		productSubTypes: PropTypes.array,
		productTypes: PropTypes.array,
		internalLocations: PropTypes.array,
		allVendors: PropTypes.array,
	};

	componentDidMount() {
		this.getVendorList();
	}

	componentDidUpdate() {
		const { allVendors } = this.context;
		if (allVendors.length !== this.state.vendorList.length) {
			this.getVendorList();
		}
	}

	getVendorList() {
		const { allVendors } = this.context;
			let v = [];
		if (allVendors && allVendors.length > 0) {
			allVendors.map(x => {
				return v.push({ label: x.name, value: x.id })
			})
		}
		this.setState({
			vendorList: v,
		})
    }

	checkResults(){
		if (this.state.searchResults.length === 0){
			this.setState({
				badSearch: true
			})
		}else{
			this.setState({
				badSearch: false
			})
		}
	}

	handleSearch = () =>{
		let id = this.state.poNumber;
		PurchaseQueueService.findPurchaseTrackingById(id)
			.then((res) => {

                this.setState({
                    searchResults: res
                })
                this.checkResults();

            })
            .catch((err) => {
                this.checkResults();
            });
	}

	handleSave = () => {

		let st = this.state;

		let date = new Date(st.expectedShipDate),
			dt = date.setHours(12),
			order = {
				poNumber: st.poNumber,
				expectedShipDate: st.expectedShipDate ? new Date(dt).toLocaleDateString() : null,
				confirmationNumber: st.confirmationNumber,
				trackingNumber: st.trackingNumber,
				orderId: st.activeOrder,
				vendorId: st.selectedVendor.value,
			};

			PurchaseQueueService.updatePurchaseTracking(order)
				.then((res) => {
					this.onClose();
					toast.success("PO Tracking Updated");
            })
            .catch((err) => {
                toast.error(
                    "There was an error submitting the request.  Please make sure all fields are filled out."
                );
            });

	};

	validateSave(equip, loc) {
		let arr = equip.filter((r) => {
			return !r.quantity;
		});
		if (!loc) {
			toast.warn("Please select a location");
			return false;
		}
		if (equip.length === 0) {
			toast.warn("Must add a product");
			return false;
		}
		if (arr.length > 0) {
			toast.warn("Please enter a quntity for all products");
			return false;
		}
		return true;
	}

	handleEditChange(property, e) {
		this.setState({
			[property]: e.target.value,
		});
	}

	handleDatePickerChange = (property, value) => {
		this.setState({ [property]: new Date(value._d) });
	};

	getOptions(){
		let select = [];
		this.state.searchResults.map((options) => {
			let ven = {};
			if (options.vendorId) {
				ven = this.state.vendorList.find(x => x.value === options.vendorId);
			}
            return select.push({
                label: options.orderId + "  |  " + options.name,
                value: options.id,
                key: options.orderId,
                confirmNumber: options.confirmationNumber,
                trackingNumber: options.trackingNumber,
				expectedShipDate: options.expectedShipDate,
				vendor: ven,
            });

		});

		return select
	}

	handleSelectChange = (e) => {
		this.setState({
			activeOrder: e.key,
			selectedResult: e,
			confirmationNumber: e.confirmNumber,
			trackingNumber: e.trackingNumber,
			expectedShipDate: e.expectedShipDate,
			selectedVendor: e.vendor,
			selectedVendorName: e.vendor && e.vendor.label ? e.vendor.label : "Select Vendor",
		})
	};

	handleVendorChange = (e) => {
		this.setState({
			selectedVendor: e || {},
			selectedVendorName: e.label || "SelectVendor",
		})
	};

	renderCardOrError() {
		if(this.state.badSearch) return(
			<CardBody style={{padding: "2%" }}>

				<MDBRow>
					<div style={{paddingLeft: "20px"}}>
						No Results Found
					</div>
				</MDBRow>

			</CardBody>
		)
		if(this.state.searchResults.length > 0){

		return(
			<CardBody style={{padding: "2%" }}>

				<MDBRow>
					<Col size="6" >
						<Select
							placeholder="Confirm Order"
							options = {this.getOptions()}
							onChange={this.handleSelectChange.bind(this)}
						/>
					</Col>

					<Col size="6" className={"inputDateWrapper uiDateOutline"}>
						<DatePicker
							disabled={this.state.activeOrder === null}
							size="small"
							inputVariant={"outlined"}
							format="MM-DD-YYYY"
							keyboard
							onChange={this.handleDatePickerChange.bind(this, "expectedShipDate")}
							value={this.state.expectedShipDate}
							mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/,]}
							label="Expected Ship Date"
						/>
					</Col>
				</MDBRow>
				<MDBRow >
					<MDBCol size="6">
						<Input
							disabled={this.state.activeOrder === null}
							outline
							label="Confirmation Number"
							value={this.state.confirmationNumber}
							onChange={this.handleEditChange.bind(this, "confirmationNumber")}
						/>
					</MDBCol>


					<MDBCol size="6">
						<Input
							disabled={this.state.activeOrder === null}
							outline
							label="Tracking Number"
							value={this.state.trackingNumber}
							onChange={this.handleEditChange.bind(this, "trackingNumber")}
						/>
					</MDBCol>
				</MDBRow>

				<MDBRow>
					<Col size="12" >
						<Select
							placeholder={this.state.selectedVendorName}
							value={this.state.selectedVendor && this.state.selectedVendor.value ? this.state.selectedVendor.value : null}
							options={this.state.vendorList}
							onChange={this.handleVendorChange.bind(this)}
						/>
						<br />
					</Col>
				</MDBRow>

				<MDBRow style={{ justifyContent: "right" }}>
					<MDBCol size={"3"}>
						<Button
							disabled={this.state.activeOrder === null}

							color={"green"}
							data-tip={"Save"}
							size={"sm"}
							onClick={() => this.handleSave()}
						>
							<MDBIcon icon="check" style={{ fontSize: "2em" }} />
						</Button>
					</MDBCol>
				</MDBRow>
				<ReactTooltip />
			</CardBody>
		)}

	}

	render() {
		return (
			<MuiPickersUtilsProvider utils={MomentUtils}>

				<CardHeader
					style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30 }}
				>
					PO Delivery Update
				</CardHeader>

				<CardBody style={{padding: "2%" }}>
				<MDBRow>
					<MDBCol size="6">
						<Input
							outline
							label="PO Number"
							onChange={this.handleEditChange.bind(this, "poNumber")}
						/>
					</MDBCol>
					<MDBCol size={"6"} >
						<Button

							style={{marginTop:"25px"}}
							color={"green"}
							data-tip={"Search"}
							size={"sm"}
							onClick={this.handleSearch}

						>
							<MDBIcon style={{ fontSize: "2em" }} />
							Search
						</Button>
					</MDBCol>
				</MDBRow>
				</CardBody>

				{this.renderCardOrError()}
				{/*<CardBody style={{padding: "2%" }}>*/}

				{/*	<MDBRow>*/}
				{/*		<MDBCol size="6">*/}
				{/*			<Input*/}
				{/*				outline*/}
				{/*				label="PO Number"*/}
				{/*				//value={this.state.currInsurance.name}*/}
				{/*				onChange={this.handleEditChange.bind(this, "poNumber")}*/}
				{/*			/>*/}
				{/*		</MDBCol>*/}
				{/*		<Col size="6" style= {{paddingTop: "23px"}}className={"inputDateWrapper uiDateOutline"}>*/}
				{/*			<DatePicker*/}
				{/*				size="small"*/}
				{/*				inputVariant={"outlined"}*/}
				{/*				format="MM-DD-YYYY"*/}
				{/*				keyboard*/}
				{/*				onChange={this.handleDatePickerChange.bind(this, "expectedShipDate")}*/}
				{/*				value={this.state.expectedShipDate}*/}
				{/*				//getValue={this.handleDatePickerChange.bind(this, "expectedShipDate")}*/}
				{/*				mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/,]}*/}
				{/*				label="Expected Ship Date"*/}
				{/*			/>*/}
				{/*		</Col>*/}
				{/*	</MDBRow>*/}
				{/*	<MDBRow >*/}
				{/*		<MDBCol size="6">*/}
				{/*			<Input*/}
				{/*				outline*/}
				{/*				label="Confirmation Number"*/}
				{/*				//value={this.state.currInsurance.name}*/}
				{/*				onChange={this.handleEditChange.bind(this, "confirmationNumber")}*/}
				{/*			/>*/}
				{/*		</MDBCol>*/}


				{/*		<MDBCol size="6">*/}
				{/*			<Input*/}
				{/*				outline*/}
				{/*				label="Tracking Number"*/}
				{/*				//value={this.state.currInsurance.name}*/}
				{/*				onChange={this.handleEditChange.bind(this, "trackingNumber")}*/}
				{/*			/>*/}
				{/*		</MDBCol>*/}
				{/*	</MDBRow>*/}
				{/*	<MDBRow style={{ justifyContent: "right" }}>*/}
				{/*		<MDBCol size={"3"}>*/}
				{/*			<Button*/}

				{/*				color={"green"}*/}
				{/*				data-tip={"Save"}*/}
				{/*				size={"sm"}*/}
				{/*				onClick={this.handleSave}*/}
				{/*			>*/}
				{/*				<MDBIcon icon="check" style={{ fontSize: "2em" }} />*/}
				{/*			</Button>*/}
				{/*		</MDBCol>*/}
				{/*	</MDBRow>*/}
				{/*	<ReactTooltip />*/}
				{/*</CardBody>*/}



				{/*<Card style={{ margin: "0", padding: "2%" }}*/}
				{/*	  className={'test'}>*/}
				{/*	<MDBTable striped small>*/}
				{/*		<MDBTableHead columns={columns} />*/}
				{/*		<MDBTableBody rows={this.renderRows()} />*/}
				{/*	</MDBTable>*/}
				{/*</Card>*/}

			</MuiPickersUtilsProvider>

		);
	}
}
