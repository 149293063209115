import React from "react";
import Card from "../../../Card";
import ColorPicker from "../../../ColorPicker";

export default class CardAdminDepartmentStyle extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        return (
            <ColorPicker
                label="Color"
                onChange={ this.props.onChange }
                department={this.props.department}
                required={true}
                error={ this.props.validationIssues.colorId ? true : false }
            />
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Style";
    }
}