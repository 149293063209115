import React from "react";
import Moment from "moment";
import PropTypes from "prop-types";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	DataTable,
	Spinner,
	Row,
	MDBIcon,
	ToastContainer,
	MDBDatePicker,
	toast,
	} from "mdbreact";
import ActivityService from "../Security/ActivityService/activityService";
import ActivitiesModal from "./activitiesModal";
import Select from "react-select";
import QuickFilter from "../Filters/quickFilter";
import ReactTooltip from "react-tooltip";

export default class activityList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "Type",
					field: "type",
					sort: "asc",
					width: 150,
				},
				{
					label: "Date",
					field: "date",
					sort: "asc",
					width: 200,
				},
				{
					label: "Account",
					field: "account",
					sort: "asc",
					width: 150,
				},
				{
					label: "Sales Rep",
					field: "owner",
					sort: "asc",
					width: 100,
				},
				{
					label: "Marketing",
					field: "quality",
					sort: "asc",
					width: 50,
				},
				{
					label: "Spent",
					field: "expenses",
					sort: "asc",
					width: 100,
				},
				{
					label: "Vendor",
					field: "vendor",
					sort: "asc",
					width: 100,
				},
				{
					label: "Description",
					field: "description",
					sort: "asc",
					width: 100,
				},
				{
					label: "Notes",
					field: "notes",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "button",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			allActivities: [],
			isLoaded: false,
			accCallModalOpen: false,
			accApptModalOpen: false,
			account: {},
			startDate: new Date(),
			endDate: new Date(),
			allLocs: [],
			allSalesReps: [],
			locationsSelect: [
				{
					label: "Loading...",
					value: "loading",
				},
			],
			salesRepsSelect: [
				{
					label: "Loading...",
					value: "loading",
				},
			],
			locationsSelected: [],
			salesRepsSelected: "",
			search: "",
			showDatePicker: true,
			quickFilter: [],
			quickFilterSelected: null,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allLocations: PropTypes.array,
		salesReps: PropTypes.array,
	};

	componentDidMount() {
		this.getLocationsOptions();
		this.getLocalStorage();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { salesReps, currentUser, allLocations } = this.context;
		let st = this.state;
		if (
			st.allLocs.length !== allLocations.length &&
			st.currUser !== currentUser
		) {
			this.getLocationsOptions();
		}
		if (st.allSalesReps.length !== salesReps.length) {
			this.getSalesRepsOptions();
		}
	}

	updateLocalStorage() {
		let filters = {},
			st = this.state;

		filters.startDate = st.startDate;
		filters.endDate = st.endDate;
		filters.reps = st.salesRepsSelected;
		filters.locations = st.locationsSelected;

		window.localStorage.setItem("activityFilters", JSON.stringify(filters));
	}

	getLocalStorage() {
		let f = window.localStorage.getItem("activityFilters");

		if (f != null) {
			f = JSON.parse(f);

			this.setState({
				startDate: f.startDate ? new Date(f.startDate) : new Date(),
				endDate: f.endDate ? new Date(f.endDate) : new Date(),
				salesRepsSelected: f.reps,
				locationsSelected: f.locations,
				isLoaded: false,
			});
		}

		setTimeout(() => {
			this.getActivities();
		}, 500);
	}

	getSalesRepsOptions(e) {
		const { currentUser, salesReps } = this.context;
		let options = [],
			reps = salesReps || [];

		if (currentUser.role !== "SALES") {
			reps.map((rep) => {
				return options.push({
					label: rep.username,
					value: rep.id,
				});
			});
		}
		else {
			options.push({
				label: currentUser.firstname + " " + currentUser.lastname,
				value: currentUser.id,
            })
        }
		this.setState({
			salesRepsSelect: options,
			allSalesReps: salesReps,
		});
	}

	getLocationsOptions() {
		const { currentUser, allLocations } = this.context;
		
		let cu = currentUser;
		let select = [],
			locations = [];
		if (cu.role === "SALES") {
			locations = cu.userprofileLocations.map(x => x.locations);
		} else {
			locations = allLocations;
		}
		locations.map((location) => {
			// Populates the locations dropdown depending on which locations the user is in...JK
			if (location.type === 0) {
				return select.push({
					label: location.name,
					value: location.id,
				});
			}
			return null;
		});
		this.setState({
			locationsSelect: select,
			allLocs: allLocations,
		});
	}

	handleLocationChange = (e) => {
		this.setState({
			locationsSelected: e,
		});
		this.getSalesRepsOptions(e);
	};

	handleSaleRepChange = (e) => {
		this.setState({
			salesRepsSelected: e,
		});
	};

	handleQuickFilter = (e) => {
		let a = QuickFilter.getDates(e.value),
			startDate = "",
			endDate = "";

		if (a.length > 0) {
			startDate = a[0];
			endDate = a[1];
		}

		this.setState({
			quickFilter: a,
			quickFilterSelected: e,
			startDate: startDate,
			endDate: endDate,
		});
	};

	handleDatePickerChange = (property, value) => {
		this.setState({
			[property]: value,
		});
	};

	renderFilter() {
		let st = this.state;
		return (
			<Card style={{ marginBottom: "1%", backgroundColor: "#5881C1" }}>
				<CardBody>
					<Row>
						<Col md={"2"} className={"filterSearchBoxCol"}>
							<Select
								placeholder="Select Location"
								closeMenuOnSelect={false}
								isMulti
								options={st.locationsSelect}
								onChange={this.handleLocationChange.bind(this)}
								value={st.locationsSelected}
							/>
						</Col>
						<Col md={"2"} className={"filterSearchBoxCol"}>
							<Select
								placeholder="Select Sales Rep"
								isClearable
								options={st.salesRepsSelect}
								onChange={this.handleSaleRepChange.bind(this)}
								value={st.salesRepsSelected}
							/>
						</Col>
						<Col md={"2"} className={"filterSearchBoxCol"}>
							<Select
								placeholder="Quick Filter"
								options={QuickFilter.getOptions()}
								onChange={this.handleQuickFilter.bind(this)}
								value={this.state.quickFilterSelected}
							/>
						</Col>

						<Col md={"2"} className={"filterSearchBoxCol"}>
							<div className={"expenseDateOptions"}>
								<MDBDatePicker
									className={"openDatePicker"}
									label={"Start Date"}
									showTodayButton
									autoOk
									allowKeyboardControl
									keyboard={true}
									okLabel={false}
									value={this.state.startDate}
									getValue={this.handleDatePickerChange.bind(this, "startDate")}
								/>
							</div>
						</Col>
						<Col md={"2"} className={"filterSearchBoxCol"}>
							<div className={"expenseDateOptions"}>
								<MDBDatePicker
									className={"openDatePicker"}
									label={"End Date"}
									showTodayButton
									autoOk
									allowKeyboardControl
									keyboard={true}
									okLabel={false}
									value={this.state.endDate}
									getValue={this.handleDatePickerChange.bind(this, "endDate")}
								/>
							</div>
						</Col>
						<Col className={"filterSearchBoxCol"}>
							<Button
								floating
								size="sm"
								color={"indigo"}
								data-tip={"Search Activities"}
								onClick={() => this.filterClicked()}
							>
								<MDBIcon icon="search" style={{ fontSize: "2em" }} />
							</Button>

							<Button
								floating
								size="sm"
								color={"secondary"}
								data-tip={"Add New Call"}
								onClick={() => this.toggleActivityModalPopup("Call")}
							>
								<MDBIcon icon="phone" style={{ fontSize: "2em" }} />
							</Button>
							<Button
								floating
								size="sm"
								color={"secondary"}
								data-tip={"Add New Appointment"}
								onClick={() => this.toggleActivityModalPopup("Appointment")}
							>
								<MDBIcon icon="calendar-check" style={{ fontSize: "2em" }} />
							</Button>
						</Col>
							
						<ActivitiesModal
							isOpen={this.state.accCallModalOpen}
							type={"Call"}
							toggleFunction={this.toggleActivityModalPopup.bind(this, "Call")}
							activityFunction={this.addNewActivity.bind(this)}
							cancelFunction={this.toggleActivityModalPopup.bind(this, "Call")}
						/>
							
						<ActivitiesModal
							isOpen={this.state.accApptModalOpen}
							type={"Appointment"}
							toggleFunction={this.toggleActivityModalPopup.bind(this, "Appointment")}
							activityFunction={this.addNewActivity.bind(this)}
							cancelFunction={this.toggleActivityModalPopup.bind(this, "Appointment")}
						/>
						<ReactTooltip />
					</Row>

				</CardBody>
			</Card>
		);
	}

	filterClicked = () => {
		this.setState({
			isLoaded: false,
		});
		this.updateLocalStorage();
		this.getActivities();
	};

	formatCurrency(expense) {
		return new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
		}).format(expense);
	}

	clientCSV(stateData, filename) {
		let result,
			ctr,
			keys = [],
			headers = [],
			columnDelimiter = ",",
			lineDelimiter = "\n",
			data;

		data = stateData.rows || null;
		if (data == null || !data.length) {
			return null;
		}

		stateData.columns.forEach((col) => {
			if (col.field === "button") {
				//we dont want this column...BC
				return;
			}

			keys.push(col.field);
			headers.push(col.label);
		});

		let replace = ["description", "account"],
			children = ["account"];

		result = "";
		result += headers.join(columnDelimiter);
		result += lineDelimiter;

		data.forEach(function (item) {
			ctr = 0;
			keys.forEach(function (key) {
				if (ctr > 0) result += columnDelimiter;

				if (children.indexOf(key) > -1 && item[key] != null) {
					//a child in this case is the object, but we just want the text...BC
					result += item[key].props.children.replace(/,/g, " ");
				} else if (replace.indexOf(key) > -1 && item[key] != null) {
					result += item[key].replace(/,/g, " ");
				} else {
					result += item[key];
				}

				ctr++;
			});
			result += lineDelimiter;
		});

		this.downloadBlob(result, filename);
	}

	//this actually prompts the download
	downloadBlob(csvData, filename) {
		let blob = new Blob([csvData], {
			type: "application/csv;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a"),
				csvUrl = URL.createObjectURL(blob);

			link.href = csvUrl;
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	getActivities() {
		let st = this.state,
			filters = {},
			sd = new Date(st.startDate),
			ed = new Date(st.endDate);

		// Get user in the sales rep dropdown and add to the filter
		if (st.salesRepsSelected) {
			filters.user = st.salesRepsSelected.value;
		}

		if (st.startDate) {
			//Need to apply time as 0 for the start date...BC
			filters.startDate = Moment(sd).format("YYYY-MM-DD") + "T00:00:00.000Z";
		} else {
			toast.warn("Please Choose a start date");
			return;
		}

		if (st.endDate) {
			//Need to apply time as 23:59 for the end date...BC
			filters.endDate = Moment(ed).format("YYYY-MM-DD") + "T23:59:59.000Z";
		} else {
			toast.warn("Please Choose an end date");
			return;
		}

		this.setState({ isLoaded: false });

		return ActivityService.getActivityListing(filters)
			.then((res) => {
				// console.log(res)
				this.setState({ allActivities: res });
				this.formatRows();
			})
			.catch((err) => {
				console.log(err);
			});
	}

	formatRows() {
		let activities = this.state.allActivities,
			dt = this.state.data,
			ary = [];

		activities.forEach((activity, index) => {
			if (activity.type !== "Order") {
				ary.push({
					type: activity.type,
					date: activity.moddate,
					account: this.renderAccount(activity.accountname, activity.accountid),
					owner: activity.salesrep || "No Sales-Rep",
					quality: activity.quality ? "Yes" : "No",
					expenses: activity.spent
						? this.formatCurrency(activity.spent)
						: "No Expense",
					vendor: activity.vendor || "No Vendor",
					description: activity.description || "No Description",
					notes: activity.dt || "No Notes",
					button: this.renderRouteButton(activity, index),
				});
			}
		});

		dt.rows = ary;

		this.setState({
			data: dt,
			isLoaded: true,
		});
	}

	renderAccount(name, id) {
		if (name == null) {
			return <div>No Account</div>;
		}

		let account = {
			name: name,
			id: id,
		};

		return (
			<a
				style={{ color: "#00afd7" }}
				href={"/account/" + id + "/detail"}
				onClick={() => {
					this.setState({ account: account });
				}}
			>
				{name}
			</a>
		);
	}

	addNewActivity(activity) {
		toast.success("Activity successfully created.");

		let d = this.state.data,
			r = d.rows,
			l = r.length,
			t = this,
			acc = activity.account;

		r.push({
			type: activity.type,
			date: activity.appointmentActivity
				? Moment(activity.appointmentActivity.expenseDate).format("MM/DD/YYYY")
				: Moment(activity.endedAt).format("MM/DD/YYYY"),
			account: this.renderAccount(acc.name, acc.id),
			owner: activity.owner ? activity.owner.name : "No Sales-Rep",
			quality: activity.quality ? "Yes" : "No",
			expenses: activity.appointmentActivity
				? this.formatCurrency(activity.appointmentActivity.expenses)
				: "No Expense",
			vendor: activity.appointmentActivity ?
				activity.appointmentActivity.vendor :
				"No Vendor",
			description: activity.appointmentActivity ?
				activity.appointmentActivity.expenseDesc :
				"No description",
			notes: activity.notes || "No Notes",
			button: this.renderRouteButton(activity, l),
		});

		this.setState({
			data: d,
			isLoaded: false,
		});

		setTimeout(() => {
			t.setState({
				isLoaded: true,
			});
		}, 500);
	}

	toggleActivityModalPopup(type) {
		this.setState({
			accCallModalOpen: type === "Call" ? !this.state.accCallModalOpen : this.state.accCallModalOpen,
			accApptModalOpen: type === "Appointment" ? !this.state.accApptModalOpen : this.state.accApptModalOpen,
		});
	}

	renderRouteButton(activity, index) {
		return (
			<MDBIcon
				icon="edit"
				key={index}
				aria-hidden="true"
				color="warning"
				onClick={() => {
					this.props.history.push({
						pathname: "/activity/" + activity.id,
						state: { activity: activity },
					});
				}}
			></MDBIcon>
		);
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<DataTable striped info={false} small data={this.state.data}>
					{" "}
				</DataTable>
			);
		} else {
			return this.renderLoadingSpinner();
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		return (
			<div style={{ marginLeft: "5%", marginRight: "5%" }}>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={5000}
				/>
				{this.renderFilter()}
				<Card style={{ marginBottom: ".5%" }}>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				<div className={"fixed-bottom downloadCsvDiv"}>
					<Button
						floating
						size="sm"
						className={"downloadCsvButton"}
						color={"primary"}
						data-tip={"Download CSV"}
						onClick={this.clientCSV.bind(
							this,
							this.state.data,
							"Activities.csv"
						)}
					>
						<MDBIcon icon="download" style={{ fontSize: "2em" }} />
					</Button>
					<ReactTooltip />
				</div>
			</div>
		);
	}
}
