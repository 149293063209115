import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Input,
	Spinner,
	toast,
	ToastContainer,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	MDBIcon,
	CardHeader,
} from "mdbreact";
import {
	Grid,
	Table,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import { PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import Select from "react-select";
import PropTypes from "prop-types";
import "./marketing.css";
import MarketingService from "../Security/MarketingService/marketingService";
import ReactTooltip from "react-tooltip";

const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

export default class CampaignList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					title: "ID",
					name: "id",
				},
				{
					title: "Name",
					name: "name",
				},
				{
					title: "Category",
					name: "type",
				},
				{
					title: "Description",
					name: "description",
				},
				{
					title: "Active",
					name: "isActive",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			results: [],
			categories: [],
			isLoaded: false,
			editModal: false,
			newModal: false,
			currCampaign: {},
			errorMessage: "",
			selectedCategory: {},
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	componentDidMount() {
		this.retrieveCampaigns();
		this.retrieveCategories();
	}

	//componentDidUpdate() {
	//	if (!this.state.data.rows.length) {
	//		this.retrieveCampaigns();
	//	}
	//	if (!this.state.categories.length) {
	//		this.retrieveCategories();
	//	}
	//}

	buildResults(res) {
		let arr = [],
			dt = this.state.data;
		console.log(res)
		res.forEach((item) => {
			arr.push({
				id: item.id,
				name: item.name,
				description: item.description,
				type: item.marketingCampaignCategory.name,
				isActive: item.isActive ? "Yes" : "No",
			});
		});
		
		dt.rows = arr;

		this.setState({ data: dt, isLoaded: true });
	}

	retrieveCampaigns() {
		MarketingService.findAllCampaigns()
			.then((camp) => {
				this.setState({ results: camp });
				this.buildResults(camp);
			})
			.catch(err => {
				console.log(err);
			});
	}

	retrieveCategories() {
		MarketingService.findAllCategories()
		.then((cat) => {
			let arr = [];
			console.log(cat)
			cat.forEach((cc) => {
				arr.push({
					value: cc.name,
					label: cc.name,
					catVal: cc,
				});
			});
			this.setState({ categories: arr });
		});
	}

	handleEditClick(row) {
		let campaign = JSON.parse(JSON.stringify(row));
		let cats = this.state.categories;
		let currCat = cats.find((cat) => campaign.type === cat.value);
		this.setState({
			currCampaign: campaign,
			editModal: !this.state.editModal,
			selectedCategory: currCat,
		});
	}

	addNewClick = () => {
		let camp = {
			id: null,
			name: null,
			description: null,
			isActive: true,
			type: null,
		};
		this.setState({
			currCampaign: camp,
			newModal: !this.state.newModal,
		});
	};

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};

	newToggle = () => {
		this.setState({
			newModal: !this.state.newModal,
			errorMessage: "",
		});
	};

	renderEditModal() {
		if (this.state.editModal) {
			let exp = this.state.currCampaign;
			return (
				<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
					<ModalHeader
						style={{ backgroundColor: "#90A4AE", color: "white" }}
						toggle={this.editToggle}
					>
						Edit Campaign
					</ModalHeader>
					<ModalBody style={{ backgroundColor: "white" }}>
						<div>
							<p style={{ color: "red" }}>{this.state.errorMessage}</p>
							<Input
								label={"Campaign Name"}
								onChange={this.handleChange.bind(this, "name")}
								value={exp.name}
							/>
							<div className="form-check my-4">
								<input
									className="form-check-input"
									checked={this.state.currCampaign.isActive === "Yes"}
									type="checkbox"
									id="defaultCheck12"
									onChange={this.checkBoxChange.bind(this)}
								/>
								<label htmlFor="defaultCheck12" className="grey-text">
									Is Active?
								</label>
							</div>
							<Input
								label={"Description"}
								onChange={this.handleChange.bind(this, "description")}
								value={exp.description}
							/>
							<Select
								closeMenuOnSelect={true}
								options={this.state.categories}
								onChange={this.handleSelectChange.bind(this)}
								value={this.state.selectedCategory}
							/>
						</div>
					</ModalBody>
					<ModalFooter style={{ backgroundColor: "white" }}>
						<Button
							style={{ float: "left" }}
							floating
							size="sm"
							color={"success"}
							data-tip={"Update Marketing Campaign"}
							onClick={this.handleEditSave}
						>
							<MDBIcon icon="check" style={{ fontSize: "2em" }} />
						</Button>
						<Button
							style={{ float: "left" }}
							floating
							size="sm"
							color={"red"}
							data-tip={"Cancel"}
							onClick={this.handleEditClose}
						>
							<MDBIcon icon="times" style={{ fontSize: "2em" }} />
						</Button>
					</ModalFooter>
					<ReactTooltip />
				</Modal>
			);
		}
		return <div></div>;
	}

	renderNewModal() {
		if (this.state.newModal) {
			let exp = this.state.currCampaign;
			return (
				<Modal isOpen={this.state.newModal} toggle={this.newToggle}>
					<ModalHeader
						style={{ backgroundColor: "#90A4AE", color: "white" }}
						toggle={this.newToggle}
					>
						Create Campaign
					</ModalHeader>
					<ModalBody style={{ backgroundColor: "white" }}>
						<div>
							<p style={{ color: "red" }}>{this.state.errorMessage}</p>
							<Input
								label={"Campaign Name"}
								onChange={this.handleChange.bind(this, "name")}
								value={exp.name || undefined}
							/>
							<Input
								label={"Description"}
								onChange={this.handleChange.bind(this, "description")}
								value={exp.description || undefined}
							/>
							<Select
								closeMenuOnSelect={true}
								options={this.state.categories}
								onChange={this.handleSelectChange.bind(this)}
							/>
						</div>
					</ModalBody>

					<ModalFooter style={{ backgroundColor: "white" }}>
						<Button
							style={{ float: "left" }}
							floating
							size="sm"
							color={"success"}
							data-tip={"Save New Marketing Campaign"}
							onClick={this.handleNewSave}
						>
							<MDBIcon icon="check" style={{ fontSize: "2em" }} />
						</Button>
						<Button
							style={{ float: "left" }}
							floating
							size="sm"
							color={"red"}
							data-tip={"Cancel"}
							onClick={this.handleNewClose}
						>
							<MDBIcon icon="times" style={{ fontSize: "2em" }} />
						</Button>
					</ModalFooter>
					<ReactTooltip />
				</Modal>
			);
		}
		return <div></div>;
	}

	checkBoxChange = (e) => {
		let camp = this.state.currCampaign;
		camp.isActive = camp.isActive === "Yes" ? "No" : "Yes";
		this.setState({
			currCampaign: camp,
		});
	};

	handleEditSave = () => {
		const { currentUser } = this.context;
		let obj = this.state.currCampaign,
			id = this.state.categories.find((cat) => obj.type === cat.label).catVal;
			
		if (this.state.currCampaign.name) {
			let res = {
				id: obj.id,
				name: obj.name,
				description: obj.description,
				isActive: obj.isActive === "Yes",
				marketingCampaignCategory: id,
				campaignTypeId: id.id,
				UpdatedById: currentUser.id,
				updateDate: new Date().toISOString(),
			};

			return MarketingService.updateCampaign(res)
				.then((r) => {
					if(r){
						this.setState({
							editModal: !this.state.editModal,
							errorMessage: "",
						});
						this.retrieveCampaigns();
					}
				})
				.catch((err) => toast.warn("An error occurred while saving."));
		}
	};

	handleNewSave = () => {
		const { currentUser } = this.context;
		let obj = this.state.currCampaign,
			id = this.state.selectedCategory.catVal;
			
		if (this.state.currCampaign.name) {
			let res = {
				name: obj.name,
				description: obj.description,
				isActive: true,
				marketingCampaignCategory: id,
				campaignTypeId: id.id,
				UpdatedById: currentUser.id,
				updateDate: new Date().toISOString(),
			};

			return MarketingService.createCampaign(res)
				.then((r) => {
					if(r){
						this.setState({
							newModal: !this.state.newModal,
							errorMessage: "",
						});
						this.retrieveCampaigns();
					}
				})
				.catch((err) => toast.warn("An error occurred while saving."));
		}
	};

	handleSelectChange = (e) => {
		let c = this.state.currCampaign;
		c.type = e.value;
		this.setState({
			currCampaign: c,
			selectedCategory: e,
		});
	};

	handleEditClose = () => {
		this.setState({
			errorMessage: "",
			editModal: !this.state.editModal,
		});
	};

	handleNewClose = () => {
		this.setState({
			errorMessage: "",
			newModal: !this.state.newModal,
		});
	};

	handleChange = (property, e) => {
		let exp = this.state.currCampaign;
		exp[property] = e.target.value;
		this.setState({
			currCampaign: exp,
		});
	};

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderTable() {
		const TableRow = ({ row, ...restProps }) => (
			<Table.Row
				{...restProps}
				onClick={() => this.handleEditClick(row)}
				style={{ cursor: "pointer" }}
			/>
		);

		if (this.state.isLoaded === true) {
			return (
				<Grid
					style={{ maxWidth: "1800px !important" }}
					rows={this.state.data.rows}
					rootComponent={Root}
					columns={this.state.data.columns}
				>
					<PagingState defaultCurrentPage={0} pageSize={25} />
					<IntegratedPaging />
					<VirtualTable rowComponent={TableRow} />
					<TableHeaderRow />
					<PagingPanel />
				</Grid>
			);
		} else {
			return <div />;
		}
	}

	render() {
		return (
			<div
				className={"mt-5"}
				style={{ marginLeft: "5%", marginRight: "5%" }}
			>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={5000}
				/>
				<Card>
					<CardHeader
						style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30}}
					>
						Marketing Campaigns
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Marketing Campaign"}
							onClick={this.addNewClick}
						>
							<MDBIcon icon="file-invoice-dollar" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>

					<CardBody>
						{this.state.isLoaded
							? this.renderTable()
							: this.renderLoadingSpinner()}
					</CardBody>
				</Card>
				<ReactTooltip />
				{this.renderEditModal()}
				{this.renderNewModal()}
			</div>
		);
	}
}
