import React from "react";
import PropTypes from "prop-types";
import {
	Card,
	CardBody,
	Container,
	ToastContainer,
	CardHeader,
	Input,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Chip,
	toast,
	StickyContainer,
	Sticky,
	Row,
	Col,
	FormInline,
	PerfectScrollbar,
	InputFile,
	DatePicker,
} from "mdbreact";
import "./activities.css";
import ActivityService from "../Security/ActivityService/activityService";
import GlobalFunctions from "../Filters/GlobalFunctions";

export default class activityAdd extends React.Component {
	constructor(props) {
		super(props);

		let account = props.location.state.account;

		this.state = {
			nActivity: {
				type: "",
				account: { id: account.id, name: account.name },
				owner: null,
				contacts: [],
				receipt: null,
				quality: false,
				cardType: null,
				expenseType: null,
				vendor: null,
				expenseDescription: null,
				peopleAttending: 0,
				educationalTopics: null,
				physiciansAttending: null,
				isExpense: false,
				when: null,
				expenseDate: null,
				notes: null,
				lastNoteDate: null,
				approvedBy: null,
				perDiem: null,
			},
			contacts: [],
			cardTypes: [],
			expenseTypes: [],
			types: [
				{ label: "Call", value: "Call" },
				{ label: "In-Service", value: "Appointment" },
			],
			initialNote: {
				type: "ActivityNote",
				account: { id: account.id, name: account.name },
				task: false,
				completed: false,
				text: "",
			},
			initialTask: {
				type: "ActivityNote",
				account: { id: account.id, name: account.name },
				task: true,
				completed: false,
				text: "",
			},
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		cardTypes: PropTypes.array,
		expenseTypes: PropTypes.array,
	};

	componentWillMount() {
		this.retrieveCreditCardTypes();
		this.retrieveExpenseTypes();
		this.retrieveContacts();
	}

	renderErrorMessage() {
		let res = this.validateForm(),
			color = "green darken-2",
			text = "Create Activity",
			onClick = this.addActivity.bind(this);

		//if the form is not valid, change the style...BC
		if (res !== true) {
			text = res;
			color = "pink darken-4";
			onClick = () => {
				return;
			};
		}

		//return the chip...BC
		return (
			<Chip
				className="activityHeader"
				size="lg"
				bgColor={color}
				text="white"
				onClick={onClick}
			>
				{text}
			</Chip>
		);
	}

	validateForm() {
		let n = this.state.nActivity;

		if (n.type === "") {
			return "Select a Activity Type.";
		} else if (n.expenses > 0) {
			if (n.cardType === null) {
				return "Select a Card.";
			} else if (n.expenseType === null) {
				return "Select an Expense.";
			} else if (n.vendor === null) {
				return "Enter Vendor.";
			} else if (n.expenseDescription === null) {
				return "Please enter a description.";
			} else if (n.peopleAttending === 0) {
				return "Please enter people who attended.";
			} else if (n.educationalTopics === null) {
				return "Please enter topics discussed.";
			}
			return true;
		}
		return true;
	}

	addActivity() {
		//set the owner
		const { currentUser } = this.context;
		let cb = { id: currentUser.id, name: currentUser.username },
			obj = this.state.nActivity;
			obj.owner = cb;

		//set when and expenseDate based on Type
		if (obj.type === "Appointment") {
			obj.startedAt = new Date();
		}
		if (obj.type === "Call") {
			obj.expenseDate = null;
		}

		return ActivityService.createActivity(obj)
			.then((res) => {
				toast.success("Activity successfully created.");

				//reset the user object to blank...BC
				let nActivity = {
					type: "",
					account: { id: obj.account.id, name: obj.account.name },
					owner: null,
					contacts: [],
					receipt: null,
					quality: false,
					cardType: null,
					expenseType: null,
					vendor: null,
					expenseDescription: null,
					physiciansAttending: null,
					peopleAttending: 0,
					educationalTopics: null,
					isExpense: false,
					when: null,
					expenseDate: null,
					notes: null,
					lastNoteDate: null,
					approvedBy: null,
					perDiem: null,
				};

				this.setState({ nActivity: nActivity });
			})
			.catch((err) => {
				toast.warn("Oh! There was an error creating the Activity.");
			});
	}

	retrieveCreditCardTypes() {
		const { cardTypes } = this.context;
		this.setState({ cardTypes: cardTypes });
		//ActivityService.getCreditCardTypes()
		//	.then((types) => {
		//		this.setState({ cardTypes: types });
		//	})
		//	.catch((err) => {
		//		console.log(err);
		//	});
	}

	retrieveExpenseTypes() {
		const { expenseTypes } = this.context;
		this.setState({ expenseTypes: expenseTypes });
		//ActivityService.getExpenseTypes()
		//	.then((types) => {
		//		this.setState({ expenseTypes: types });
		//	})
		//	.catch((err) => {
		//		console.log(err);
		//	});
	}

	retrieveContacts() {
		ActivityService.getAllContacts()
			.then((data) => {
				this.setState({ contacts: data });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	renderTopCard() {
		return (
			<Card style={{ backgroundColor: "transparent", boxShadow: "none" }}>
				<CardHeader
					className="text-center"
					style={{
						backgroundColor: "#324191",
						color: "white",
						marginTop: "0",
						marginBottom: "2rem",
					}}
				>
					<h2>Activity Editor {this.renderErrorMessage()}</h2>
				</CardHeader>
			</Card>
		);
	}

	renderAccountCard() {
		let activity = this.state.nActivity;
		return (
			<Card>
				<CardBody>
					<CardHeader
						style={{ backgroundColor: "#324191", color: "white" }}
						className="form-header text-center"
					>
						<h3>Account & Type Details</h3>
					</CardHeader>
					<Input
						value={activity.account.name}
						label="Account Name"
						style={{ color: "darkgrey" }}
						size="sm"
						icon="user"
						readOnly
					/>
					<Row>
						<Col md="6" className="md-4">
							<Dropdown>
								<DropdownToggle caret color="primary">
									{activity.type || "Select Type"}
								</DropdownToggle>
								<DropdownMenu basic>
									<DropdownItem onClick={this.changeType.bind(this, null)}>
										--Choose Type--
									</DropdownItem>
									{this.state.types.map((type, index) => {
										return (
											<DropdownItem
												key={index}
												onClick={this.changeType.bind(this, type)}
											>
												{type.label}
											</DropdownItem>
										);
									})}
								</DropdownMenu>
							</Dropdown>
						</Col>
						<Col md="6" className="md-4">
							<Dropdown>
								<DropdownToggle caret color="primary">
									{activity.contacts.firstname
										? activity.contacts.firstname +
										  " " +
										  activity.contacts.lastname
										: "Select Contact"}
								</DropdownToggle>
								<DropdownMenu basic>
									{this.state.contacts
										? this.state.contacts.map((contact, index) => {
												return (
													<DropdownItem
														key={index}
														onClick={this.changeContacts.bind(this, contact)}
													>
														{contact.firstname + " " + contact.lastname}
													</DropdownItem>
												);
										  })
										: "no contacts"}
								</DropdownMenu>
							</Dropdown>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}

	changeContacts(contact) {
		let oActivity = this.state.nActivity;
		oActivity.contacts = [];
		this.setState({ nActivity: oActivity });
	}

	changeType(type) {
		let oActivity = this.state.nActivity;
		if (type != null) {
			oActivity.type = type.value;
			this.setState({ nActivity: oActivity });
		} else {
			oActivity.type = "";
			this.setState({ nActivity: oActivity });
		}
	}

	handleChange = (property, event) => {
		//update the property that the user has edited in the order...BC
		const nActivity = this.state.nActivity;
		nActivity[property] = event.target.value;
		this.setState({ eActivity: nActivity });
	};

	handleDatePickerChange = (property, value) => {
		//update the date property on the object that the user has edited in the order...BC
		const nActivity = this.state.nActivity;
		nActivity[property] = value;
		this.setState({ eActivity: nActivity });
	};

	renderInformationCard() {
		let activity = this.state.nActivity;
		if (activity.type === "Appointment") {
			return (
				<Card>
					<CardBody>
						<CardHeader
							style={{ backgroundColor: "#324191", color: "white" }}
							className="form-header text-center"
						>
							<h3>Enter Details</h3>
						</CardHeader>
						<DatePicker
							label="Expense Date"
							getValue={this.handleDatePickerChange.bind(this, "expenseDate")}
						/>
						<Row>
							<Col md="6" className="md-4">
								<Dropdown>
									<DropdownToggle caret color="primary">
										{activity.cardType ? activity.cardType.name : "Select Card"}
									</DropdownToggle>
									<DropdownMenu basic>
										{this.state.cardTypes.map((card, index) => {
											return (
												<DropdownItem
													key={index}
													onClick={this.changeCardType.bind(this, card)}
												>
													{card.name}{" "}
												</DropdownItem>
											);
										})}
									</DropdownMenu>
								</Dropdown>
							</Col>
							<Col md="6" className="md-4">
								<Dropdown>
									<DropdownToggle caret color="primary">
										{activity.expenseType
											? activity.expenseType.name
											: "Select Expense"}
									</DropdownToggle>
									<DropdownMenu basic>
										{this.state.expenseTypes.map((expense, index) => {
											return (
												<DropdownItem
													key={index}
													onClick={this.changeExpenseType.bind(this, expense)}
												>
													{expense.name}{" "}
												</DropdownItem>
											);
										})}
									</DropdownMenu>
								</Dropdown>
							</Col>
						</Row>
						<Input
							value={activity.expenses || ""}
							label="Amount Spent"
							onChange={this.handleChange.bind(this, "expenses")}
							size="sm"
							icon="usd"
						/>
						<Input
							value={activity.vendor || ""}
							label="Vendor"
							onChange={this.handleChange.bind(this, "vendor")}
							size="sm"
							icon="user"
						/>
						<Input
							value={activity.expenseDescription || ""}
							label="Description"
							onChange={this.handleChange.bind(this, "expenseDescription")}
							size="sm"
							icon="commenting-o"
						/>
						<Input
							value={activity.peopleAttending || ""}
							label="Number of People Attending"
							onChange={this.handleChange.bind(this, "peopleAttending")}
							size="sm"
							icon="users"
						/>
						<Input
							value={activity.educationalTopics || ""}
							label="Educational Topics Discussed"
							onChange={this.handleChange.bind(this, "educationalTopics")}
							size="sm"
							icon="question"
						/>
						<Input
							value={activity.physiciansAttending || ""}
							label="Physician Attending"
							onChange={this.handleChange.bind(this, "physiciansAttending")}
							size="sm"
							icon="users"
						/>
					</CardBody>
				</Card>
			);
		} else if (activity.type === "Call") {
			return (
				<Card>
					<CardBody>
						<CardHeader
							style={{ backgroundColor: "#324191", color: "white" }}
							className="form-header text-center"
						>
							<h3>Enter Details</h3>
						</CardHeader>
						<DatePicker
							label="Date"
							getValue={this.handleDatePickerChange.bind(this, "when")}
							size="sm"
							icon="user"
						/>
						<Input
							label="Marketing"
							onChange={this.qualityChange.bind(this)}
							type="checkbox"
							id="checkbox1"
						/>
					</CardBody>
				</Card>
			);
		} else if (activity.type === "") {
			return (
				<Card>
					<CardBody>
						<CardHeader
							style={{ backgroundColor: "#324191", color: "white" }}
							className="form-header text-center"
						>
							<h3>Enter Details</h3>
						</CardHeader>
						<DatePicker
							label="Date"
							getValue={this.handleDatePickerChange.bind(this, "when")}
							size="sm"
							icon="user"
						/>
					</CardBody>
				</Card>
			);
		}
	}

	changeCardType(cardType) {
		let oActivity = this.state.nActivity;
		oActivity.cardType = { id: cardType.id, name: cardType.name };
		this.setState({ nActivity: oActivity });
	}

	changeExpenseType(expenseType) {
		let oActivity = this.state.nActivity;
		oActivity.expenseType = { id: expenseType.id, name: expenseType.name };
		this.setState({ nActivity: oActivity });
	}

	qualityChange = (event) => {
		let oActivity = this.state.nActivity;
		oActivity.quality = event.target.checked;
		this.setState({ nActivity: oActivity });
	};

	renderNotesTask() {
		return (
			<Card>
				<CardBody>
					<CardHeader
						style={{ backgroundColor: "#324191", color: "white" }}
						className="form-header text-center"
					>
						<h3>Notes & Tasks</h3>
					</CardHeader>
					<div style={{ maxHeight: "400px", overflowY: "scroll" }}>
						{this.initialNoteTask()}
					</div>
				</CardBody>
			</Card>
		);
	}

	initialNoteTask() {
		return (
			<div>
				<CardHeader style={{ backgroundColor: "#324191", color: "white" }}>
					<FormInline className={"individualNote"}>
						<label>Initial Note</label>
						<span>{GlobalFunctions.formatDate(new Date())}</span>
					</FormInline>
				</CardHeader>
				<Input
					type="textarea"
					label="Enter note.."
					rows="2"
					cols="50"
					onChange={this.setInitialNote.bind(this)}
				/>

				<CardHeader style={{ backgroundColor: "#324191", color: "white" }}>
					<FormInline className={"individualNote"}>
						<label>Initial Task</label>
						<span>{GlobalFunctions.formatDate(new Date())}</span>
					</FormInline>
				</CardHeader>
				<Input
					type="textarea"
					label="Enter task.."
					rows="2"
					cols="50"
					onChange={this.setInitialTask.bind(this)}
				/>
			</div>
		);
	}

	setInitialNote = (event) => {
		const { currentUser } = this.context;
		let note = this.state.initialNote,
			cb = { id: currentUser.id, name: currentUser.username };

		note.text = event.target.value;
		note.createdAt = new Date().toISOString();
		note.createdBy = cb;
		note.createdById = cb.id;
		this.setState({ initialNote: note });
	};

	setInitialTask = (event) => {
		const { currentUser } = this.context;
		let task = this.state.initialTask,
			cb = { id: currentUser.id, name: currentUser.username };

		task.createdAt = new Date().toISOString();
		task.createdBy = cb;
		task.createdById = cb.id;
		task.text = event.target.value;
		this.setState({ initialTask: task });
	};

	renderReceipt() {
		let activity = this.state.nActivity;
		if (activity.receipt) {
			return (
				<Card>
					<CardBody>
						<CardHeader
							style={{ backgroundColor: "#324191", color: "white" }}
							className="form-header text-center"
						>
							<h3>Preview Receipt</h3>
						</CardHeader>
						<img style={{ height: 250, width: 500 }} alt="Nothing" />
					</CardBody>
				</Card>
			);
		}
	}

	renderReceiptCard() {
		let activity = this.state.nActivity;
		if (activity.type === "Appointment") {
			return (
				<Container style={{ maxHeight: "300px" }}>
					<PerfectScrollbar className="scrollbar-primary">
						<CardHeader
							style={{ backgroundColor: "#324191", color: "white" }}
							className="text-center"
						>
							<h2>Upload Receipt</h2>
							<InputFile name="file" />
						</CardHeader>
						<CardBody></CardBody>
					</PerfectScrollbar>
				</Container>
			);
		}
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>

				<Container className="mt-5">
					<StickyContainer>
						<Sticky>
							{({ style }) => {
								return (
									<div style={{ ...style, zIndex: 1000, marginTop: "4rem" }}>
										<Row>
											<Col md="12" className="mb-12">
												{this.renderTopCard()}
											</Col>
										</Row>
									</div>
								);
							}}
						</Sticky>
					</StickyContainer>
					<div>
						<br />
						<Row>
							<Col md="6" className="mb-4">
								{this.renderAccountCard()}
								<br />
								<br />
								{this.renderNotesTask()}
							</Col>
							<Col md="6" className="mb-4">
								{this.renderInformationCard()}
								<br />
								<br />
								{this.renderReceipt()}
							</Col>
						</Row>
						<Row>
							<Col md="12" className="mb-4">
								{this.renderReceiptCard()}
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		);
	}
}