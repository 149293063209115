import Crud from "../Crud";
import MUILightGreenColor from "@mui/material/colors/lightGreen";

/**
 * Static helper functions related to Service Reasons.
 */
export default class ServiceReason extends Crud {
    /**
     * @param {object} serviceReason The serviceReason object.
     * @returns The display name of the serviceReason.
     */
    static getDisplayName(serviceReason) {
        return serviceReason.name;
    }

    /**
     * Determines if an serviceReason matches a search query.
     *
     * @param {object} serviceReason The serviceReason to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the serviceReason matches the query.
     */
    static match(serviceReason, query) {
        return (
            (serviceReason.name.toLowerCase().includes(query)) ||
            (serviceReason.description.toLowerCase().includes(query))
        );
    }

    /**
     * @param {array} serviceReasons The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(serviceReasons) {
        return serviceReasons.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUILightGreenColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "SubdirectoryArrowRight";
    }
}