import Crud from "../Crud";

/**
 * Static helper functions related to Departments.
 */
export default class Department extends Crud {

    /**
     * @param {object} department The department object.
     * @returns The display name of the department.
     */
    static getDisplayName(department) {
        return department.name;
    }

    /**
     * Determines if a department matches a search query.
     *
     * @param {object} department The department to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the department matches the query.
     */
    static match(department, query) {
        return (
            (department.name !== null && department.name.toLowerCase().includes(query)) ||
            department.id === +query
        );
    }

    /**
     * @param {array} departments The list to sort.
     * @returns {array} The sorted list by ordinal then alphabetically by name.
     */
    static sort(departments) {
        return departments.sort((a, b) => {
            if (a.ordinal !== null && b.ordinal !== null) {
                return a.ordinal - b.ordinal
             } else {
                return ( a.ordinal === null ) - ( b.ordinal === null ) || (a.name).localeCompare(b.name);
             }
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        throw new Error("Use department.colorId");
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "BusinessCenter";
    }
}