import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import MUIGrid from "@mui/material/Grid";
import TileUser from "../../../Tile/TileUser";
import MUIEditIcon from "@mui/icons-material/Edit";
import MUIIconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@material-ui/icons/Delete";
import DialogSearchUser from "../../../Dialog/Search/DialogSearchUser";

export default class CardAdminRegionManagement extends Card {
    constructor(props) {
        super(props);

        this.state.salesDirectorDialogOpen = false;
        this.state.operationsDirectorDialogOpen = false;
    }

    static contextTypes = {
        usersIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        let region = this.props.region;
        let salesDirectorUser = null;
        let operationsDirectorUser = null;

        if (region && region.salesDirectorId !== null) {
            salesDirectorUser = this.context.usersIndexed[region.salesDirectorId]
        }

        if (region && region.operationsDirectorId !== null) {
            operationsDirectorUser = this.context.usersIndexed[region.operationsDirectorId]
        }

        // Sales Director
        const handleEditExecutive = () => {
            this.setState({ salesDirectorDialogOpen: true });
        }
        const handleClearExecutive = () => {
            this.props.onChange({ salesDirectorId: null });
        }
        const salesDirectorIconButtons = [
            (<MUIIconButton onClick={handleEditExecutive}><MUIEditIcon /></MUIIconButton>)
        ];

        if (salesDirectorUser) {
            salesDirectorIconButtons.push(
                (<MUIIconButton onClick={handleClearExecutive}><MUIDeleteIcon /></MUIIconButton>)
            );
        }

        // Operations Director
        const handleEditDirector = () => {
            this.setState({ operationsDirectorDialogOpen: true });
        }
        const handleClearDirector = () => {
            this.props.onChange({ operationsDirectorId: null });
        }
        const operationsDirectorIconButtons = [
            (<MUIIconButton onClick={handleEditDirector}><MUIEditIcon /></MUIIconButton>)
        ];

        if (operationsDirectorUser) {
            operationsDirectorIconButtons.push(
                (<MUIIconButton onClick={handleClearDirector}><MUIDeleteIcon /></MUIIconButton>)
            )
        }

        // Render
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileUser
                            user={salesDirectorUser}
                            iconButtons={salesDirectorIconButtons}
                            label="Sales Director"
                        />
                    </MUIGrid>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <TileUser
                            user={operationsDirectorUser}
                            iconButtons={operationsDirectorIconButtons}
                            label="Operations Director"
                        />
                    </MUIGrid>
                </MUIGrid>
                <DialogSearchUser
                    open={this.state.salesDirectorDialogOpen}
                    onClose={() => this.setState({ salesDirectorDialogOpen: false })}
                    onSelect={(user) => {
                        this.props.onChange({ salesDirectorId: user.id });
                    }}
                />
                <DialogSearchUser
                    open={this.state.operationsDirectorDialogOpen}
                    onClose={() => this.setState({ operationsDirectorDialogOpen: false })}
                    onSelect={(user) => {
                        this.props.onChange({ operationsDirectorId: user.id });
                    }}
                />
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Management"
    }
}