import Crud from "../Crud";
import MUIGreyColor from "@mui/material/colors/cyan";

/**
 * Static helper functions related to Terms.
 */
export default class Term extends Crud {

    /**
     * @param {object} term The term object.
     * @returns The display name of the term.
     */
    static getDisplayName(term) {
        return term.name;
    }

    /**
     * Determines if a term matches a search query.
     *
     * @param {object} term The term to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the term matches the query.
     */
    static match(term, query) {
        return (
            (term.name !== null && term.name.toLowerCase().includes(query)) ||
            term.id === +query
        );
    }

    /**
     * @param {array} terms The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(terms) {
        return terms.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIGreyColor;
    }

    /**
     * @returns {number} The base color shade of this object.
     */
    static getBaseColorShade() {
        return 900;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "MenuBook";
    }

    /**
     * @returns A list of all supported term types. This is not stored in the
     * database on purpose as any new term types will need custom code anyways.
     *
     * This is structured as if the term type (gender, queue_type, etc) is the
     * primary key and points at an object with all of the properties.
     */
    static getTermTypes() {
        return {
            queue_type: {
                name: "Queue Type"
            },
            insurance_sub_type_group: {
                name: "Insurance Group"
            }
        };
    }
}