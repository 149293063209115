import React from "react";
import PropTypes from "prop-types";
import {
	Button,
	Col,
	Container,
	Input,
	InputFile,
	Modal,
	ModalFooter,
	ModalBody,
	Row,
	toast,
	DatePicker,
	MDBIcon, MDBModalHeader,
} from "mdbreact";
import "./activities.css";
import Select from "react-select";
import ActivityService from "../Security/ActivityService/activityService";
import NoteService from "../Security/NoteService/noteService";
import UserService from "../Security/UserService/userService";
import GlobalFunctions from "../Filters/GlobalFunctions";

import ReactTooltip from "react-tooltip";

export default class activitiesModal extends React.Component {
    constructor(props) {
        super(props);

		let account = props.account || null;
		this.activityFunction = props.activityFunction;
		this.cancelFunction = props.cancelFunction;
		this.toggleFunction = props.toggleFunction;
		
		this.state = {
			plainExpense: null,
			isOpen: props.isOpen,
			typeRadio: props.type,
			selectedExpense: {},
			myAccounts: [],
			nActivity: {
				account: account,
				expenseDate: new Date(),
				quality: false,
				contacts: [],
				type: "Appointment",
			},
			nCall: {
				account: account,
				quality: false,
				contacts: [],
				type: "Call",
				endedAt: new Date(),
				callReason: null,
				callType: null,
			},
			callTypes: [
				{ label: "Face-To-Face", value: "FTF" },
				{ label: "LCMP Evaluation", value: "LCMP Evaluation" },
				{ label: "Drop Ins", value: "Drop Ins" },
			],
			callReasons: [
				{ label: "Following up with new information from a previous meeting", value: "Following up with new information from a previous meeting" },
				{ label: "Following up regarding new information about changes in LCDs", value: "Following up regarding new information about changes in LCDs" },
				{ label: "Paperwork Education/Streamlining Processes", value: "Paperwork Education/Streamlining Processes" },
				{ label: "Equipment Changes", value: "Equipment Changes" },
				{ label: "Policy Changes", value: "Policy Changes" },
				{ label: "Other industry and/or inter-company changes that our accounts need to be aware of", value: "Other industry and/or inter-company changes that our accounts need to be aware of" },
				{ label: "Other", value: "Other" },
			],
			noteRadio: "Note",
			saveLoading: false,
			myContacts: [],
			isOtherReason: props.type !== "Call" ? "flex" : "none",
			expenseTypes: props.expenseTypes || [],
			filteredExpenseTypes: [],
			cardTypes: props.cardTypes || [],
			hideNotes: props.hideNotes || false,
			validateList: props.validateList || null,
		};
	}

    static contextTypes = {
        currentUser: PropTypes.object,
        myAccounts: PropTypes.array,
        cardTypes: PropTypes.array,
        expenseTypes: PropTypes.array,
    };

	componentDidMount(){
		this.getCardList();
		this.getExpenseTypeList();
		this.getAccountList();
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isOpen != null) {
			this.setState({
				isOpen: nextProps.isOpen,
			});
		}
		if (nextProps.plainExpense){
			this.setState({
				plainExpense: true,
			})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		//const { myAccounts, cardTypes, expenseTypes } = this.context;
		const { myAccounts } = this.context;
		let st = this.state;

		if (st.myAccounts.length !== myAccounts.length) {
			this.getAccountList();
		}
   //     if (!st.cardTypes || st.cardTypes.length !== cardTypes.length) {
			//this.getCardList();
   //     }
   //     if (!st.expenseTypes || st.expenseTypes.length !== expenseTypes.length) {
			//this.getExpenseTypeList();
   //     }
	}

    getCardList() {
        const {cardTypes} = this.context;

        let a = [];
        a.push({
            label: "Select Card Type...",
            value: "",
        });
		if (cardTypes && cardTypes.length > 0) {
			cardTypes.map((card) => {
				return a.push({
					label: card.name,
					value: card,
				});
			});

			this.setState({
				cardTypes: a,
			})
		}
    }

    getExpenseTypeList() {
        const {expenseTypes} = this.context;

        let act = this.state.nActivity;

        let a = [];
        a.push({
            label: "Select Expense Type...",
            value: "",
        });
		if (expenseTypes && expenseTypes.length > 0) {
			expenseTypes.map((exp) => {
				if (act.cardType != null) {
					if (exp.cardTypes) {
						let i = exp.cardTypes.findIndex((x) => x.id === act.cardType.id);
						if (i > -1) {
							return a.push({
								label: exp.name,
								value: exp,
							});
						}
					}
				} else {
					return a.push({
						label: exp.name,
						value: exp,
					});
				}

				return null;
			});

			this.setState({
				expenseTypes: a,
				filteredExpenseTypes: a,
			})
		}
    }

	getAccountList() {
		const { myAccounts } = this.context;

		if (myAccounts && myAccounts.length > 0) {
			let a = [];
			myAccounts.map((acc) => {
				return a.push({
					label: acc.name,
					value: acc,
				});
			});

			this.setState({
				myAccounts: a,
			});
		}
	}

    getContactList(id) {
        return UserService.getContactsByAccount(id)
            .then((users) => {
                let cAry = [],
                    contacts = users;

                if (!contacts || contacts.length === 0) {
                    toast.warn("There are no contacts registered for this Account. Please create a contact first.");
                }
                contacts.forEach((contact) => {
                    cAry.push({
                        label: contact.firstname + " " + contact.lastname,
                        value: contact,
                    });
                });

                this.setState({
                    myContacts: cAry,
                });
            })
			.catch((err) => {
				console.log(err)
            });
    }

    handleAccountSelected = (e) => {
        let act = this.state.nActivity,
            call = this.state.nCall,
            account = {name: e.value.name, id: e.value.id};

		act.account = account;
		act.accountId = account.id;
		call.account = account;
		call.accountId = account.id;

        this.getContactList(e.value.id);

        this.setState({
            nActivity: act,
            nCall: call,
        });
    };

    handleContactSelected = (e) => {
        let call = this.state.nCall,
            contact = {id: e.value.id, firstname: e.value.firstname, lastname: e.value.lastname, role: e.value.role};

        call.contacts.push(contact);

        this.setState({
            nCall: call,
        });
    };

    handleCallTypeSelected = (e) => {
        let call = this.state.nCall;

        call.callType = e.value;

        this.setState({
            nCall: call,
        });
    };

    handleCallReasonSelected = (e) => {
        let call = this.state.nCall;

        call.callReason = e.value;

        this.setState({
            nCall: call,
            isOtherReason: e.value === "Other" ? "flex" : "none"
        });
    };

	handleCardTypeSelected = (e) => {
		let act = this.state.nActivity,
			eTypes = this.state.expenseTypes;

		act.cardType = { name: e.value.name, id: e.value.id };
		act.cardTypeId = e.value.id;

		var filterTypes = eTypes.filter(x => x.value !== "" && x.value.expenseTypeCreditCards.map(y => {
			return y.creditCardsId;
		}).includes(e.value.id));
		
		this.setState({
			nActivity: act,
			filteredExpenseTypes: filterTypes,
		});
	};

    handleExpenseTypeSelected = (e) => {
        let act = this.state.nActivity;

		act.expenseType = { name: e.value.name, id: e.value.id };
		act.expenseTypeId = e.value.id;

        this.setState({
            nActivity: act,
            selectedExpense: e.value,
        });
    };

	base64Resize(sourceBase64, scale , setImage) {
		const _scale = scale;
		var img = document.createElement('img');
		img.setAttribute("src", sourceBase64);

		img.onload = () => {
			let canvas = document.createElement('canvas');
			canvas.width = img.width * _scale;
			canvas.height = img.height * _scale;
			let ctx = canvas.getContext("2d");
			let iw = img.width;
			let ih = img.height;
			let scl = Math.min((1000 / iw), (1000 / ih));
			let iwScaled = iw * scl;
			let ihScaled = ih * scl;
			canvas.width = iwScaled;
			canvas.height = ihScaled;
			ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
			const newBase64 = canvas.toDataURL("image/jpeg", scl);

			setImage(newBase64);
		}
	}

    encodeImageFileAsURL(element) {
        let file = element[0],
            reader = new FileReader(),
            t = this;
		function callback(im){
			t.state.nActivity.receipt = im;
			t.setState({nActivity: t.state.nActivity});
		}

        reader.onloadend = function () {
            t.base64Resize(reader.result, .5, callback);
        };
        reader.readAsDataURL(file);
    }

	validateActivity(activity) {
		let shouldContinue = true;
		let plain = this.state.plainExpense,
			vList = this.state.validateList;

		if (!activity.account && !plain && (!vList || vList.includes("account"))) {
			toast.warn("Please pick an account");
			shouldContinue = false;
		}

        if (!activity.type) {
            toast.warn("Please select an activity type");
            shouldContinue = false;
        }

        if (activity.type === "Appointment") {
			if (!activity.expenseDesc && (!vList || vList.includes("description"))) {
                toast.warn("Please provide a description");
                shouldContinue = false;
            }

			if (!activity.expenseDate && (!vList || vList.includes("date"))) {
                toast.warn("Please set the date");
                shouldContinue = false;
            }

			if (!activity.expenses || activity.expenses < 0) {
				toast.warn("Amount spent should be no less than 0");
				shouldContinue = false;
			}

			if (!activity.vendor && (!vList || vList.includes("vendor"))) {
                toast.warn("Please identify the vendor");
                shouldContinue = false;
            }

			if (!activity.peopleAttending && !plain && (!vList || vList.includes("peopleAttending"))) {
				toast.warn("Please identify how many people were attending");
				shouldContinue = false;
			}
			if (activity.peopleAttending && !parseInt(activity.peopleAttending) && !plain && (!vList || vList.includes("peopleAttending"))){
				toast.warn("Please enter a number for people attending");
				shouldContinue = false;
			}

			if (!activity.educationalTopics && !plain && (!vList || vList.includes("topics"))) {
				toast.warn("Please identify the topics discussed");
				shouldContinue = false;
			}

            //if (activity.expenses > 0) {
				if (!activity.expenseType && (!vList || vList.includes("expenseType"))) {
                    toast.warn("Please select an expense type");
                    shouldContinue = false;
                }

				if (!activity.cardType && (!vList || vList.includes("cardType"))) {
                    toast.warn("Please select a card type");
                    shouldContinue = false;
                }

                if (
                    activity.expenseType &&
                    this.state.selectedExpense.receiptRequired === true
                ) {
                    if (!activity.receipt) {
                        toast.warn("Please upload a receipt");
                        shouldContinue = false;
                    }
                }
            //}
        } else if (activity.type === "Call") {
            if (activity.quality === true && (!activity.contacts || activity.contacts.length === 0)) {
                toast.warn("Please select a contact");
                shouldContinue = false;
            }

            if (!activity.quality && !activity.callType) {
                toast.warn("Please select a call type");
                shouldContinue = false;
            }

            if (activity.quality && activity.callReason === "Other") {
                toast.warn("Please select a call reason");
                shouldContinue = false;
            }

            let nText = document.getElementById("newNoteText").value;
            if (activity.quality && activity.callReason === "Other" && nText === "") {
                toast.warn("Please enter the reason note");
                shouldContinue = false;
            }
        }

        return shouldContinue;
    }

	createActivity() {
		const { currentUser } = this.context;
        this.setState({saveLoading: true});
		let act = this.state.nActivity;
		let account = act.account,
			na = {
				type: this.state.typeRadio,
				accountId: null, //this.state.accountId ? parseInt(this.state.accountId),
				ownerId: currentUser.id,
				endedAt: new Date().toISOString(),
				startedAt: new Date().toISOString(),
				quality: this.state.nCall.quality || false,
				priority: 0,
				isExpense: this.state.typeRadio === "Appointment",
			};

        if (this.state.typeRadio === "Appointment") {
            let shouldCreate = this.validateActivity(act);

            if (!shouldCreate) {
                this.setState({saveLoading: false});
                return;
            }

            //If we have a receipt, format it before save...BC
			if(act.peopleAttending){
				act.peopleAttending = parseInt(act.peopleAttending);
			}
            if (act.receipt) {
                let rt = act.receipt;
                //get the index of the comma which comes after the part that declares the image type
                let idx = rt.indexOf(","),
                    newStr = rt.slice(idx + 1);

				act.expenseReceipt = newStr;
			}
			na.accountId = act.account ? act.account.id : null;
			delete act.contacts;
			delete act.cardType;
			delete act.expenseType;
			delete act.accountId;
			delete act.endedAt;
			delete act.quality;
			delete act.type;
			delete act.account;

            if (act.expenses) {
                act.expenses = parseFloat(act.expenses);
            }
			na.appointmentActivity = act;
			na.type = "Appointment";
            //act.endedAt = new Date();
        } else {
            act = this.state.nCall;

            let shouldCreate = this.validateActivity(act);

            if (!shouldCreate) {
                this.setState({saveLoading: false});
                return;
			}
			na.accountId = act.account ? act.account.id : null;
			na.activityContact = act.contacts && act.contacts.length > 0 ? act.contacts.map(x => { return { accountId: this.state.accountId, contactId: x.id, contact: x }; }) : [];
			delete act.contacts;
			delete act.endedAt;
			delete act.quality;
			delete act.type;
			delete act.account;
			na.callActivity = act;
			na.type = "Call";
        }

        return ActivityService.createActivity(na)
            .then((res) => {
				res.account = account;
                let nActivity = {
                    expenseDate: new Date(),
                    quality: false,
                    contacts: [],
                    type: "Appointment",
                };

					let nCall = {
						quality: false,
						contacts: [],
						type: "Call",
						endedAt: new Date(),
						callReason: null,
						callType: null,
					};

                this.setState({
                    nActivity: nActivity,
                    nCall: nCall,
                });

				if (na.type === "Call" && this.state.callNoteText) {
					this.createNoteTask(res);
				}

                //this is the callback that will add the newly created activity to the list...BC
                this.activityFunction(res);
                this.setState({
                    nActivity: nActivity,
                    saveLoading: false,
                });
				this.toggleFunction(this.state.typeRadio);
            })
			.catch((err) => {
				let nActivity = {
					expenseDate: new Date(),
					quality: false,
					contacts: [],
					type: "Appointment",
				};
				console.log(err);
				toast.warn("Oh! There was an error creating the Activity.");
				this.setState({
					saveLoading: false,
					nActivity: nActivity
				});
				this.toggleFunction(this.state.typeRadio);
			});
    }

    createNoteTask(activity) {
        const {currentUser} = this.context;

        let noteRadio = this.state.noteRadio,
            accountDTO = activity.account,
            cb = {id: currentUser.id, name: currentUser.username};

        let nText = document.getElementById("newNoteText").value;

		//we don't have any text, no note to create
		if (nText === "") {
			this.cancelFunction();
		} else {
			let note = {
				text: nText,
				account: accountDTO,
				completed: true,
				task: false,
				createdAt: GlobalFunctions.getUTCMoment(),
				createdBy: cb,
				createdById: cb.id,
			};

            if (noteRadio === "Task") {
                note.completed = false;
                note.task = true;
            }

            return NoteService.createActivityNote(activity.id, note)
                .then((res) => {
                    toast.success("Task successfully created.");
                    this.cancelFunction();
                })
                .catch((err) => {
                    toast.warn("Oh! There was an error creating the Task.");
                });
        }
    }

	determineSection() {
        if (this.state.typeRadio === "Call" || this.props.type === "Call") {
            return this.renderCallSection();
		} else if (this.state.typeRadio === "Appointment" || this.props.type === "Appointment") {
            return this.renderAppointmentSection();
        }
    }

	determineNoteTaskSection() {
		if (this.state.hideNotes === true) {
			return <div />;
		}
		else {
			return (
				<div>
					<Row>
						<Col size="5">
							<Input
								onClick={() => this.setState({ noteRadio: "Note" })}
								checked={this.state.noteRadio === "Note"}
								label="Note"
								type="radio"
								id="noteRadio"
							/>
						</Col>
						<Col size="5">
							<Input
								onClick={() => this.setState({ noteRadio: "Task" })}
								checked={this.state.noteRadio === "Task"}
								label="Task"
								type="radio"
								id="taskRadio"
							/>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<textarea
								placeholder={"Enter Text"}
								id={"newNoteText"}
								style={{ width: "100%", height: "160px" }}
							></textarea>
						</Col>
					</Row>
				</div>
			);
		}
	}

	renderCallSection() {
		return (
			<div>
				<Row>
					<Col md="12">
						<Select
							isDisabled={!this.state.myContacts || this.state.myContacts.length === 0}
							placeholder={"Select Contact..."}
							options={this.state.myContacts || []}
							onChange={this.handleContactSelected.bind(this)}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="8">
						{/*<DatePicker*/}
						{/*	format="MM-DD-YYYY"*/}
						{/*	hint={"01-01-1900"}*/}
						{/*	keyboard*/}
						{/*	getValue={(evt) => {*/}
						{/*		let act = this.state.nCall;*/}
						{/*		act.endedAt = evt;*/}
						{/*		this.setState({ nCall: act });*/}
						{/*	}}*/}
						{/*	mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}*/}
						{/*	label="Call Date"*/}
						{/*	className="openDatePicker"*/}
						{/*/>*/}
					</Col>
					<Col size="12">
						<br />
					</Col>
				</Row>
				<Row>
					<Col md="12">
						{ this.state.nCall.quality === false ?
							<Select
								placeholder={"Select Call Type..."}
								options={this.state.callTypes}
								onChange={this.handleCallTypeSelected.bind(this)}
							/>
							:
							<Select
								placeholder={"Select Call Reason..."}
								options={this.state.callReasons}
								onChange={this.handleCallReasonSelected.bind(this)}
							/>
						}
					</Col>
					<Col size="12">
						<br />
					</Col>
				</Row>
			</div>
		);
	}

    renderReceipt() {
        if (!this.state.nActivity.receipt) {
            return <div></div>;
        } else {
            return (
                <img
                    style={{width: "200px", height: "300px"}}
                    src={this.state.nActivity.receipt}
                    alt="Invalid format"
                />
            );
        }
    }

	renderAppointmentSection() {
		return (
			<div>
				<Row>
					<Col>
						<div className={"datePickerOptions"}>
							<DatePicker
								format="MM-DD-YYYY"
								hint={"01-01-1900"}
								keyboard
								getValue={(evt) => {
									let act = this.state.nActivity;
									act.expenseDate = new Date(evt);
									this.setState({ nActivity: act });
								}}
								mask={[/\d/,/\d/,"-",/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/]}
								label={this.state.plainExpense ? "Expense Date" : "Appointment Date"}
							/>
						</div>
					</Col>
					<Col className={"inputIcon"}>
						<Input
							type="number"
							icon="dollar-sign"
							label="Amount Spent"
							value={this.state.nActivity.expenses}
							style={{textAlign: "left"}}
							onChange={(evt) => {
								let act = this.state.nActivity;
								act.expenses = evt.target.value ? parseFloat(evt.target.value.replaceAll("$", "")) : null;
								this.setState({ nActivity: act });
							}}

						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<Select
							placeholder={"Select Card Type"}
							options={this.state.cardTypes}
							onChange={this.handleCardTypeSelected.bind(this)}
						/>
					</Col>

					<Col>
						<Select
							placeholder={"Select Expense Type"}
							options={this.state.filteredExpenseTypes || []}
							onChange={this.handleExpenseTypeSelected.bind(this)}
						/>
					</Col>
				</Row>

				<Row>
					<Col size="12">
						<Input
							label={this.state.plainExpense ? "Expense Description" : "Appointment Description"}
							value={this.state.nActivity.expenseDesc}
							maxLength="255"
							onChange={(evt) => {
								let act = this.state.nActivity;
								act.expenseDesc = evt.target.value;
								this.setState({ nActivity: act });
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<Input
							label="Vendor"
							value={this.state.nActivity.vendor}
							onChange={(evt) => {
								let act = this.state.nActivity;
								act.vendor = evt.target.value;
								this.setState({ nActivity: act });
							}}
						/>
					</Col>

					{this.state.plainExpense ? "" :
						<Col>
							<Input
								label="People Attending (amount)"
								value={this.state.nActivity.peopleAttending}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.peopleAttending = evt.target.value;
									this.setState({nActivity: act});
								}}
							/>
						</Col>
					}
				</Row>

				{this.state.plainExpense ? "" :
					<Row>
						<Col>
							<Input
								label="Topics Discussed"
								value={this.state.nActivity.educationalTopics}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.educationalTopics = evt.target.value;
									this.setState({nActivity: act});
								}}
							/>
						</Col>
						<Col>
							<Input
								label="Physicians Attending (names)"
								value={this.state.nActivity.physiciansAttending}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.physiciansAttending = evt.target.value;
									this.setState({nActivity: act});
								}}
							/>
						</Col>
					</Row>
				}

                <InputFile
                    type="file"
					maxHeight={50}
                    textFieldTitle={"Upload receipt (.jpg or .png only)"}
                    className="form-control-file"
                    name="file"
                    getValue={this.encodeImageFileAsURL.bind(this)}
                />
                <Row style={{justifyContent: "space-evenly"}}>
                    {this.renderReceipt()}
                </Row>
            </div>
        );
    }

	render() {
		let plain = this.state.plainExpense;

		return (
			<Container>
				<Row>
					<Modal
						position={"top"}
						className="salesPilotModal"
						size="lg"
						isOpen={this.props.isOpen}
						toggle={() => {
							return;
						}}
					>

						{plain ? <MDBModalHeader color={"indigo"}>New Expense </MDBModalHeader> :
							<MDBModalHeader color={"indigo"}>New {this.state.typeRadio} Activity</MDBModalHeader>
						}
						<ModalBody>
							{this.state.typeRadio === "Call" ?
								<Col md="12"
										style={{paddingTop: "20px"}}>
									<Input
										onChange={(evt) => {
											let nCall = this.state.nCall;
											nCall.quality = evt.target.checked;
											if(!nCall.quality){
												nCall.callReason = null;
											}
											else{
												nCall.callType = null;
											}
											this.setState({ 
												nCall: nCall 
											});
										}}
										filled
										label="Marketing"
										type="checkbox"
										id="checkbox6"
									/>
								</Col>
							: ""}

							{plain ? "" :
							<Select
								placeholder={"Select Account...(Required)"}
								options={this.state.myAccounts}
								onChange={this.handleAccountSelected.bind(this)}
							/>}

							{this.determineSection()}
                            {this.determineNoteTaskSection()}
                        </ModalBody>
                        <ModalFooter>
                            {this.state.saveLoading ? (
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                <Button
                                    floating
                                    size="sm"
                                    color={"success"}
                                    data-tip={"Create New Activity"}
                                    onClick={this.createActivity.bind(this)}
                                >
                                    <MDBIcon icon="check" style={{fontSize: "2em"}}/>
                                </Button>
                            )}

                            <Button
                                floating
                                size="sm"
                                color={"red"}
                                data-tip={"Cancel"}
                                onClick={this.cancelFunction.bind(this)}
                            >
                                <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                            </Button>

                            <ReactTooltip/>
                        </ModalFooter>
                    </Modal>
                </Row>
            </Container>
        );
    }
}
