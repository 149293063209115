import React from "react";
import MUIChip from "@mui/material/Chip";

export default class Chip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Renders a MUI Avatar with an avatar and text.
     */
    render() {
        const label = this.getLabel();

        return (
            <MUIChip
                title={label}
                avatar={this.renderAvatar()}
                label={label}
                onClick={this.props.onClick}
                onDelete={this.props.onDelete}
                sx={this.props.isTransparent ? {backgroundColor: "transparent"} : {}}
            />
        );
    }

    /**
     * Renders a MUI Avatar. Defaults to null; subclasses should override.
     */
    renderAvatar() {
        return this.props.avatar;
    }

    /**
     * @returns {string} The label of the chip. Defaults to null; subclasses
     * should override.
     */
    getLabel() {
        return this.props.label;
    }
}