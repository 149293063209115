import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import Region from "../../../../Utility/Crud/Region";

export default withRouter(class CardAdminRegionList extends Card {
    static contextTypes = {
        regionsIndexed: PropTypes.object
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "name", name: "Name", type: "string" },
                    { key: "salesDirectorId", name: "Sales Director", type: "user" },
                    { key: "operationsDirectorId", name: "Operations Director", type: "user" },
                ]}
                rows={Region.sort(Object.values(this.context.regionsIndexed))}
                onClick={(region) => {
                    this.props.history.push({
                        pathname: "/admin/region/" + region.id
                    });
                }}
            />
        );
    }
});