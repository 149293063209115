import React from "react";
import DialogSearch from "../DialogSearch"
import TileDepartment from "../../Tile/TileDepartment";
import PropTypes from "prop-types";
import Department from "../../../Utility/Crud/Department";

export default class DialogSearchDepartment extends DialogSearch {

    static contextTypes = {
        departmentsIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered departments.
     */
    getSearchResults(query) {
        return Department.search(Object.values(this.context.departmentsIndexed), query, true);
    }

    /**
     * @param {object} department The department object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileDepartment component for this department.
     */
    renderTile(department, onClick) {
        const handleClick = () => {
            onClick(department);
            this.close();
        }

        return (
            <TileDepartment
                key={ department.id }
                department={ department }
                onClick={ handleClick }
            />
        );
    }
}
