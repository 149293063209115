import React from "react";
import DialogSearch from "../DialogSearch"
import TileUser from "../../Tile/TileUser";
import PropTypes from "prop-types";
import User from "../../../Utility/Crud/User";

export default class DialogSearchUser extends DialogSearch {

    static contextTypes = {
        usersIndexed: PropTypes.object,
    };

    /**
     * Searches for users by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered users.
     */
    getSearchResults(query) {
        return User.search(Object.values(this.context.usersIndexed), query, true);
    }

    /**
     * @param {object} user The user object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileUser component for this user.
     */
    renderTile(user, onClick) {
        const handleClick = () => {
            onClick(user);
            this.close();
        }

        return (
            <TileUser
                key={user.id}
                user={user}
                onClick={handleClick}
            />
        );
    }
}
