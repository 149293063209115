import React from "react";
import PropTypes from "prop-types";
import MUIBox from "@mui/material/Box"
import Layer from "../../Layer";
import CardQueueFiltersSidebar from "../../Component/Card/Queue/CardQueueFiltersSidebar";
import CardQueueDataGrid from "../../Component/Card/Queue/CardQueueDataGrid";
import Setting from "../../Utility/Setting";
import QueueService from "../../Seating/Security/QueueService/queueService";
import CardQueueDataGridOrderActivity from "../../Component/Card/Queue/CardQueueDataGrid/CardQueueDataGridOrderActivity";
import CardQueueDataGridPatientIssue from "../../Component/Card/Queue/CardQueueDataGrid/CardQueueDataGridPatientIssue";
import CardQueueDataGridPendingPreauth from "../../Component/Card/Queue/CardQueueDataGrid/CardQueueDataGridPendingPreauth";

export default class Queue extends Layer {
    static contextTypes = {
        queuesIndexed: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state.sidebarCollapsed = Setting.get("queue.sidebarCollapsed") === true ? true : false;
    }

    /**
     * Set the data in the state.
     */
    afterComponentDidMount() {
        const queueId = this.props.match.params.id ? +this.props.match.params.id : null;
        if (queueId !== null) {
            this.setState({
                queue: this.context.queuesIndexed[queueId]
            });
            this.getQueueRows(queueId);
            this.getQueueStatistics(queueId)
        }

        document.addEventListener(
            "visibilitychange",
            this.handleVisibilityChange.bind(this)
        );
    }

    /**
     * Remove the visibilitychange event handler.
     */
    componentWillUnmount() {
        document.removeEventListener(
            "visibilitychange",
            this.handleVisibilityChange.bind(this)
        );
    }

    /**
     * API call for getting queue data by id.
     */
    async getQueueRows(id) {
        this.setState({ loadedAt: null })
        return QueueService.getQueueRows({ id: id })
            .then((rows) => {
                this.setState({
                    rows: rows,
                    queueDirty: false,
                    loadedAt: new Date(),
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /**
     * API call for getting queue data history by id, start date, and end date.
     */
       async getQueueStatistics(id) {
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);

        let filters = {
            startDate: startDate.toLocaleDateString(),
        }

           return QueueService.getQueueStatistics(id, filters)
            .then((stats) => {
                this.setState({
                    queueStatistics: stats,
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /**
     * Reload the queue when the tab becomes visible again if it's dirty.
     */
    handleVisibilityChange() {
        if (
            document.hidden === false &&
            this.state.queueDirty === true
        ) {
            this.getQueueRows(this.state.queue.id);
        }
    };

    /**
     * Renders page content.
     */
    renderContent() {
        let CardQueueDataGridComponent;
        switch (this.state.queue.table) {
            case 'order_activity':
                CardQueueDataGridComponent = CardQueueDataGridOrderActivity;
                break;
            case 'patient_issue':
                CardQueueDataGridComponent = CardQueueDataGridPatientIssue;
                break;
            case 'pending_preauth':
                CardQueueDataGridComponent = CardQueueDataGridPendingPreauth;
                break;
            default:
                CardQueueDataGridComponent = CardQueueDataGrid;
                break;
        }

        return (
            <>
                <MUIBox sx={{ height: "calc(100vh - 80px)", marginLeft: 1, marginRight: 1 }}>
                    <MUIBox sx={{ display: "flex", height: "100%", alignItems: "stretch", gap: 2 }}>
                        <MUIBox sx={ { width: this.state.sidebarCollapsed ? "60px" : "260px" } }>
                            <CardQueueFiltersSidebar
                                rows={this.state.rows}
                                queue={this.state.queue}
                                loadedAt={this.state.loadedAt}
                                collapsed={this.state.sidebarCollapsed}
                                onCollapse={(collapsed) => {
                                    Setting.set("queue.sidebarCollapsed", collapsed);
                                    this.setState({
                                        sidebarCollapsed: collapsed
                                    });
                                }}
                                viewOutOfBoundsOnly={this.props.history.location.state && this.props.history.location.state.viewOutOfBoundsOnly ? this.props.history.location.state.viewOutOfBoundsOnly : null}
                                queueStatistics={this.state.queueStatistics}
                            />
                        </MUIBox>

                        {/* The goofy margin/padding here is necessary because the overflow breaks the box shadow on the card inside the box. */ }
                        <MUIBox sx={{ flexGrow: 1, overflowX: "hidden", margin: "-8px", padding: "8px" }}>
                            <CardQueueDataGridComponent
                                rows={this.state.rows}
                                queue={this.state.queue}
                                loadedAt={this.state.loadedAt}
                                onReload={() => this.getQueueRows(this.state.queue.id)}
                                onClickRow={() => this.setState({ queueDirty: true })}
                                viewOutOfBoundsOnly={this.props.history.location.state && this.props.history.location.state.viewOutOfBoundsOnly ? this.props.history.location.state.viewOutOfBoundsOnly : null}
                            />
                        </MUIBox>
                    </MUIBox>
                </MUIBox>
            </>
        );
    }
}
