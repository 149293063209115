import api from "../api";

class DepartmentService {
    async getAllDepartments() {
        return new Promise((res, reject) => {
            api
                .get("/allDepartments")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }
 
    async getDepartmentById(id) {
        return new Promise((res, reject) => {
            api
                .get("/departments/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }



    async createDepartment(obj) {
        return new Promise((res, reject) => {
            api
                .post("/departments", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateDepartment(obj) {
        return new Promise((res, reject) => {
            api
                .put("/departments", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }



    async deleteDepartment(id) {
        return new Promise((res, rej) => {
            return api.delete('/departments/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            })
        })
    }


  

}

const departmentService = new DepartmentService();

export default departmentService;
