/**
 * Handles all of the security checks in the application.
 */
export default class Security {
    static memo = {};
    static context;

    /**
     * @param {object} context The context, as set by the SecurityProvider.
     */
    static setContext(context) {
        this.context = context;
    }

    /**
     * @param {string} permission The permission to check
     * @returns {boolean} Whether or not the current user has that permission.
     */
    static hasPermission(permission) {
        const user = this.context.currentUser;
        const key = `${permission}.${user.id}`;
        if (this.memo.hasOwnProperty(key) === false) {
            switch (permission) {
                case "queue.viewHistoryChart":
                    this.memo[key] = (
                        this.hasRole(user, "ADMIN") === true ||
                        this.isExecutive(user) === true ||
                        this.isDirector(user) === true ||
                        this.isManager(user) === true
                    );
                    break;
                case "queue.viewAll":
                    /**
                     * If you are an admin, an exec, a director, or are not on a
                     * team and are note a manager, then you can view all
                     * queues.
                     */
                    this.memo[key] = (
                        this.hasRole(user, "ADMIN") === true ||
                        this.isExecutive(user) === true ||
                        this.isDirector(user) === true ||
                        // This logic can go away once all users are
                        // appropriately assigned to teams. For now it's a
                        // safeguard to allow users who are not on teams and are
                        // not managers to view everything. There is already
                        // separate logic to allow managers to view departments
                        // where they are a manager on a team in the department.
                        (
                            this.isOnATeam(user) === false &&
                            this.isManager(user) === false
                        )
                    );
                    break;
                default:
                    this.memo[key] = false;
                    break;
            }
        }

        return this.memo[key];
    }

    /**
     * @param {object} user The user to check.
     * @param {array|string} roles The role(s) to check.
     * @returns {boolean} Whether or not the user has a specific role or roles.
     *
     * Role reference:
     *
     * OFFICE_SUPPORT
     * SALES
     * TECHNICIAN
     * PATIENT_CARE_REP
     * SALES_MANAGER
     * OFFICE_MANAGER
     * EXECUTIVE_MANAGEMENT
     * FOLLOWUP
     * THIRD_PARTY_USER
     * SERVICE_TEAM
     * WAREHOUSE_TECHNICIAN
     * VERIFICATION
     * PURCHASING
     * AREA_MANAGER
     * BILLING
     * FINANCE
     * ADMIN
     * CARE_COORDINATOR
     * COLLECTIONS
     * CUSTOMER_EXPERIENCE
     * MARKETING
     * CALL_CENTER
     * DATA_EXPORT
     * CASE_WORKER
     * SALES_LIAISON
     * THERAPIST
     * SALES_LIAISON_MANAGER
     */
    static hasRole(user, roles) {
        if (typeof roles === "string") {
            roles = [roles];
        }
        return roles.includes(user.role);
    }

    /**
     * @param {object} user The user to check.
     * @returns {boolean} Whether or not the user is an executive, based on
     * their attachment as an executive on departments.
     */
    static isExecutive(user) {
        return Object.values(this.context.departmentsIndexed).some((department) => {
            return department.executiveId === user.id;
        });
    }

    /**
     * @param {object} user The user to check.
     * @returns {boolean} Whether or not the user is a director, based on their
     * attachment as a director on departments.
     */
    static isDirector(user) {
        return Object.values(this.context.departmentsIndexed).some((department) => {
            return department.directorId === user.id;
        });
    }

    /**
     * @param {object} user The user to check.
     * @returns {boolean} Whether or not the user is a manager, based on their
     * attachment as a manager on teams.
     */
    static isManager(user) {
        return Object.values(this.context.teamsIndexed).some((team) => {
            return team.managerId === user.id;
        });
    }

    /**
     * @param {object} user The user to check.
     * @returns {boolean} Whether or not the user is on a team, based on whether
     * or not at least one row exists in team_user with their user.id.
     */
    static isOnATeam(user) {
        return Object.values(this.context.teamUsersIndexed).some((teamUser) => {
            return teamUser.userId === user.id;
        });
    }
}