import React from "react";
import MUICircularProgress from "@mui/material/CircularProgress"
import MUIStack from "@mui/material/Stack";
import MUITypography from "@mui/material/Typography";
import MUIBox from "@mui/material/Box"

export default class Progress extends React.Component {
    /**
     * Renders a spinning loading circle that fills whatever container it's
     * placed in.
     */
    render() {
        return (
            <MUIStack spacing={2} height="100%" display="flex" alignItems="center" justifyContent="center">
                <MUICircularProgress
                    size={80}
                    thickness={2}
                    variant={this.props.variant ? this.props.variant : "indeterminate"}
                    value={this.props.value}
                />

                <MUIBox sx={{ "textAlign": "center" }}>
                    {this.props.message && (
                        <MUITypography display="block" variant="body1" color="textPrimary">
                            {this.props.message}
                        </MUITypography>
                    )}
                    {this.props.variant === "determinate" && (
                        <MUITypography display="block" variant="body2" color="textSecondary">
                            {Math.round(this.props.value) + "%"}
                        </MUITypography>
                    )}
                </MUIBox>
            </MUIStack>
        );
    }
}