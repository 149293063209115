import React from "react";
import {
	Col,
	Fa,
	Input,
	MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader,
	Row,
	toast,
	MDBBtn,
} from "mdbreact";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import NoteService from "../../Security/NoteService/noteService";
// import globalFunctions from "../../Filters/GlobalFunctions";

export default class CreateNoteModal extends React.Component {
	constructor(props) {
		super(props);

		this.toggleNoteModal = props.toggleNoteModal;
		this.addNoteUpdateOrderCloseModal = props.addNoteUpdateOrderCloseModal;
		this.toggleProcessing = props.toggleProcessing;
		this.refreshNotes = props.getNotes;
		this.handleRouting = props.handleRouting;

		this.state = {
			saving: false,
			eOrder: props.eOrder,
			usedLiaisonKeys: [],
			resultingLiaisonKeys: [],
			existingMeeting: {},
			allNotes: props.allNotes,
			noteModalOpen: false,
			taskCompleted: true,
			//noteAssignedTo: props.isOrderDetails ? props.noteAssignedTo || null : props.eOrder.activity.owner || null,
			noteAssignedToId: props.isOrderDetails ? props.noteAssignedToId || null : (props.eOrder && props.eOrder.activity ? props.eOrder.activity.ownerId : null),
			usersList: [],
			isOrderDetails: props.isOrderDetails,
			approvalAction: props.approvalAction,
			queueRoute: props.queueRoute,
			activityId: props.activityId || (props.eOrder ? props.eOrder.id : null),
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allUsers: PropTypes.array,
		queueRoutesIndexed: PropTypes.array,
	};

	componentDidMount(){
		this.getUserList();
	}

	componentDidUpdate() {
		const { allUsers } = this.context;
		if (allUsers.length !== this.state.usersList.length) {
			this.getUserList();
		}
	}

	validate() {
		let noteText = document.getElementById("newActivityNoteText").value;
		if (noteText === null || noteText.trim() === "") {
			toast.error("You must add note text!");
			return false;
		}
		return true;
	}


	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.noteModalOpen !== prevState.noteModalOpen) {
			return {
				noteModalOpen: nextProps.noteModalOpen,
				eOrder: nextProps.eOrder,
			};
		} else return null;
	}

	getUserList() {
		const { allUsers } = this.context;
		let a = [];

		allUsers.map(x => {
			return a.push({
				label: x.firstname + " " + x.lastname,
				value: x.id,
            })
        })
		this.setState({ usersList: a });
	}

	taskCompletedChange = (value) => {
		this.setState({ taskCompleted: value });
	};

	handleNoteUserSelected = (e) => {
		let user = { name: e.label, id: e.value };
		this.setState({
			noteAssignedTo: user,
		});
	};

	addNewNote() {
		let shouldContinue = this.validate();

		if (!shouldContinue) {
			return
		}

		if(this.state.saving){
			return
		}
		this.setState({
			saving:true
		})
		this.toggleProcessing();
		// this.toggleNoteModal();
		let queueRouteName = "";
		if (this.state.isOrderDetails || this.state.queueRoute) {
			const { queueRoutesIndexed } = this.context;
			let queueRoute = queueRoutesIndexed[this.state.queueRoute].name.replace(/\s/g, '').toLowerCase();

			if (queueRoute.includes("unabletoapprove(insurance)")) {
				queueRouteName = "unableInsurance"
			} else if (queueRoute.includes("unabletoapprove(insurance)")) {
				queueRouteName = "unableInsurance"
			} else if (queueRoute.includes("unabletoapprove(equipment)")) {
				queueRouteName = "unableEquipment"
			} else {
				return null
			}
		} 
		//get the current user context that can be passed into the note DTO...BC
		const { currentUser } = this.context;

		let cb = currentUser; //{ id: currentUser.id, username: currentUser.username };

		let note = {
			task: !this.state.taskCompleted,
			completed: this.state.taskCompleted,
			//assignedTo: this.state.noteAssignedTo,
			assignedToId: this.state.noteAssignedTo ? this.state.noteAssignedTo.id : null,
			createdBy: cb,
			createdById: cb.id,
			//account: this.state.isOrderDetails ? this.state.eOrder.account : this.state.eOrder.activity.account,
			accountId: this.state.isOrderDetails ? this.state.eOrder.accountId : (this.state.eOrder && this.state.eOrder.activity ? this.state.eOrder.activity.accountId : null),
			patientName: this.state.eOrder ? this.state.eOrder.patientName : "",
			type: "ActivityNote",
			activityId: this.state.activityId,
			dueDate: null,
			isAutoVerification: this.props.isAutoVerification,
		};

		note.text = document.getElementById("newActivityNoteText").value;

		return NoteService.createActivityNote(this.state.eOrder || this.props.eOrder ? this.state.eOrder.id || this.props.eOrder.id : null, note)
			.then((res) => {

				if(res){
					//need to set the last note date because otherwise the save overrides it...BC
					//let ord = this.state.eOrder;
					//ord.activity.lastNoteDate = new Date();
					if (this.state.approvalAction === "needsAdditionalDocs" || this.state.approvalAction === "diagnoseIssue" || this.state.approvalAction === "unableToPickUp" || queueRouteName) {
						this.handleRouting("fromNote")
					} else {
						this.setState({ saving: false })
						this.toggleNoteModal();
					}
					if(res.createdAt.indexOf("Z") > 0){
						res.createdAt = res.createdAt.split("Z")[0];
					}

					this.addNoteUpdateOrderCloseModal(res, this.state.eOrder);
					if (this.refreshNotes) {
						this.refreshNotes();
					}
					toast.success("Note Saved Successfully!");
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error("There was an error adding note.");
				//Maybe send an error to the user?...BC
			});
	}

	renderNoteModal() {
		const { queueRoutesIndexed } = this.context;
		let queueRouteName = "";
		if (this.state.queueRoute) {
			let queueRoute = queueRoutesIndexed[this.state.queueRoute].name.replace(/\s/g, '').toLowerCase();
			if (queueRoute.includes("unabletoapprove(insurance)")) {
				queueRouteName = "unableInsurance"
			} else if (queueRoute.includes("unabletoapprove(insurance)")) {
				queueRouteName = "unableInsurance"
			} else if (queueRoute.includes("unabletoapprove(equipment)")) {
				queueRouteName = "unableEquipment"
			} else {
				return null
			}
		}
		return (

					<MDBModal
						className="salesPilotModal"
						size="small"
						isOpen={this.state.noteModalOpen}
						toggle={() => this.toggleNoteModal()}

					>
						<MDBModalHeader>
							Add New Note
						</MDBModalHeader>
						<MDBModalBody style={{ backgroundColor: "#FFFFFF" }}>

							<form className=" mx-3 grey-text">
								<textarea
									placeholder={"Add note text here (required)"}
									autoFocus
									id={"newActivityNoteText"}
									style={{ width: "100%", height: "200px" }}
								>{this.props.text || ""}</textarea>
								{/* {this.state.isOrderDetails ? null : ( */}
						{false || this.state.approvalAction === "needsAdditionalDocs" || this.state.approvalAction === "diagnoseIssue" || queueRouteName ? null : (
									<Row>
										<Col size={ "6" }>
											<Input
												type="radio"
												gap
												label={ "Note" }
												checked={ this.state.taskCompleted }
												onClick={ this.taskCompletedChange.bind(this, true) }
												id={ "noteRadio" }
											/>
										</Col>

										<Col size={ "6" }>
											<Input
												type="radio"
												gap
												label={ "Task" }
												checked={ !this.state.taskCompleted }
												onClick={ this.taskCompletedChange.bind(this, false) }
												id={ "taskRadio" }
											/>
										</Col>
									</Row>
						) }
						{this.state.approvalAction === "needsAdditionalDocs" || this.state.approvalAction === "diagnoseIssue" || queueRouteName ? null : (
							<>
								<br />

								<label> Assigned to (optional) </label>
								<Select
									menuPortalTarget={ document.body }
									styles={ { menuPortal: base => ({ ...base, zIndex: 9999 }) } }
									placeholder={
										this.state.noteAssignedTo
											? this.state.noteAssignedTo.name
											: "Select User..."
									}
									options={ this.state.usersList }
									onChange={ this.handleNoteUserSelected.bind(this) }
								/>
								<br />
							</>
						) }
						<ReactTooltip />
					</form>
				</MDBModalBody>
				<MDBModalFooter>

					<MDBBtn
						data-tip={"Add Note"}
										floating
										size="sm"
										color="success"
						onClick={this.addNewNote.bind(this)}
										disabled={this.state.saving ? true : false}>
										<Fa icon={ "check" } />
										</MDBBtn>

							<span
								className={"ptBtn cancelBtn"}
								data-tip={"Cancel"}
								style={{cursor: "pointer"}}
								onClick={() => this.toggleNoteModal()}
							>
										<Fa size={"2x"} icon={"times"}>
											{" "}
										</Fa>
									</span>
						</MDBModalFooter>
					</MDBModal>
		);
	}

	render() {
		return <div>{this.renderNoteModal()}</div>;
	}
}
