import Crud from "../Crud";
import MUIIndigoColor from "@mui/material/colors/indigo";

/**
 * Static helper functions related to Vendors.
 */
export default class Vendor extends Crud {

    /**
     * @param {object} vendor The vendor object.
     * @returns The display name of the vendor.
     */
    static getDisplayName(vendor) {
        return vendor.name;
    }

    /**
     * Determines if a vendor matches a search query.
     *
     * @param {object} vendor The vendor to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the vendor matches the query.
     */
    static match(vendor, query) {
        return (
            (vendor.name !== null && vendor.name.toLowerCase().includes(query)) ||
            vendor.id === +query
        );
    }

    /**
     * @param {array} vendors The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(vendors) {
        return vendors.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {object} The color of this object.
     */
    static getBaseColor() {
        return MUIIndigoColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Store";
    }
}