import React from "react";
import Dialog from "../Dialog";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import MUIIconButton from "@mui/material/IconButton";
import MUICloseIcon from '@mui/icons-material/Close';
import MUISearchIcon from "@mui/icons-material/Search";
import MUITooltip from "@mui/material/Tooltip";
import MUIInputAdornment from "@mui/material/InputAdornment";
import MUIBox from '@mui/material/Box';
import EmptyState from "../EmptyState";
import Tile from "../Tile";

export default class DialogSearch extends Dialog {
    constructor(props) {
        super(props);

        this.state.query = "";
        this.state.searchResults = {};
    }

    /**
     * Set the search results on mount. This ensures that the full list gets
     * rendered if it's set up to return all on empty search string.
     */
    componentDidMount() {
        this.setState({
            searchResults: this.getSearchResults(this.state.query),
        });
    }

    /**
     * Clear state. Called when the dialog closes.
     */
    clearState() {
        super.clearState();

        this.setState({
            query: "",
            searchResults: this.getSearchResults(""),
        });
    }

    /**
     * Render the search box.
     */
    renderHeader() {
        const handleChange = (e) => {
            this.setState({
                query: e.target.value,
                searchResults: this.getSearchResults(e.target.value)
            });
        };

        const handleKeyDown = (e) => {
            if (
                e.key === "Enter" &&
                this.state.searchResults.length === 1 &&
                this.props.onSelect !== undefined
            ) {
                e.preventDefault();
                this.props.onSelect(this.state.searchResults[0]);
                this.close();
            };
        };

        return (
            <>
                <MUITextField
                    autoFocus={true}
                    placeholder="Type to search..."
                    fullWidth={true}
                    variant="standard"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        startAdornment: (
                          <MUIInputAdornment position="start">
                            <MUISearchIcon />
                          </MUIInputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                />
                <MUITooltip title="Close" placement="left">
                    <MUIIconButton
                        onClick={() => this.close()}
                        sx={{
                            position: "absolute",
                            right: 16,
                            top: 14
                        }}
                    >
                        <MUICloseIcon />
                    </MUIIconButton>
                </MUITooltip>
            </>
        );
    }

    /**
     * Render search result tiles in a grid.
     */
    renderContent() {
        const height = 400;

        if (this.state.searchResults.length > 0) {
            return (
                <MUIBox height={height}>
                    <MUIGrid container spacing={2} sx={{"paddingBottom": 2}}>
                        {this.state.searchResults.slice(0, this.getMaxResults()).map((searchResult, i) => {
                            return (
                                <MUIGrid key={i} item xs={12} sm={6} md={4}>
                                    {this.renderTile(searchResult, this.props.onSelect)}
                                </MUIGrid>
                            );
                        })}
                    </MUIGrid>
                </MUIBox>
            );
        }

        /**
         * Empty state. This uses a grid container so the modal height doesn't
         * change depending on whether there are results or not.
         */
        return (
            <MUIBox height={height}>
                <MUIGrid container spacing={2} sx={{"paddingBottom": 2}}>
                    <MUIGrid item xs={12} >
                        <div style={{"textAlign": "center", "paddingTop": 130}}>
                            <EmptyState
                                line1={this.state.query.trim() === "" ? "Start typing to begin your search" : "No results found"}
                                line2={this.state.query.trim() === "" ? "What have you got to lose?" : "Try searching for something a little different"}
                            />
                        </div>
                    </MUIGrid>
                </MUIGrid>
            </MUIBox>
        );
    }

    /**
     * @returns The maximum number of results that can be rendered. Set to
     * Infinity to remove the cap. Just be aware that rendering too many items
     * is not performant or generally useful.
     */
    getMaxResults() {
        return 20;
    }

    /**
     * @returns {boolean} Whether or not to show dividers on content area.
     */
    getDividers() {
        return true;
    }

    /**
     * Searches for objects. Intended to only be used in the DialogSearch class
     * directly for generic search dialogs.
     *
     * @param {string} query The search query.
     * @returns A list of filtered objects.
     */
    getSearchResults(query) {
        const queryCleaned = query
            .toLowerCase()
            .trim()
            .replace(/\s+/g, ' ');

        return this.props.data.filter((row) => {
            return (
                (row.name.toLowerCase().includes(queryCleaned)) ||
                String(row.value) === String(queryCleaned)
            );
        });
    }

    /**
     * @param {object} object The object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A Tile component for this object. Intended to only be used in
     * the DialogSearch class directly for generic search dialogs.
     */
    renderTile(object, onClick) {
        const handleClick = () => {
            onClick(object);
            this.close();
        }

        return (
            <Tile
                title={object.name}
                onClick={handleClick}
                avatarString={object.name}
            />
        );
    }
}