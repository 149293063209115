import React from "react";
import DialogSearch from "../DialogSearch"
import TileQueue from "../../Tile/TileQueue";
import PropTypes from "prop-types";
import Queue from "../../../Utility/Crud/Queue";

export default class DialogSearchQueue extends DialogSearch {

    static contextTypes = {
        queuesIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered queue.
     */
    getSearchResults(query) {
        return Queue.search(Object.values(this.context.queuesIndexed), query, true);
    }

    /**
     * @param {object} queue The queue object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileQueue component for this queue.
     */
    renderTile(queue, onClick) {
        const handleClick = () => {
            onClick(queue);
            this.close();
        }
        
        return (
            <TileQueue
                key={ queue.id }
                queue={ queue }
                onClick={ handleClick }
            />
        );
    }
}
