import React from "react";
import DialogSearch from "../DialogSearch"
import TileOrderActivityType from "../../Tile/TileOrderActivityType";
import PropTypes from "prop-types";
import OrderActivityType from "../../../Utility/Crud/OrderActivityType";

export default class DialogSearchOrderActivityType extends DialogSearch {

    static contextTypes = {
        orderActivityTypesIndexed: PropTypes.object,
    };

    /**
     * Searches for Order Activity Types by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered orderActivityTypes.
     */
    getSearchResults(query) {
        return OrderActivityType.search(
            Object.values(this.context.orderActivityTypesIndexed),
            query,
            true
        );
    }

    /**
     * @param {object} orderActivityType The orderActivityType object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileOrderActivityType component for this orderActivityType.
     */
    renderTile(orderActivityType, onClick) {
        const handleClick = () => {
            onClick(orderActivityType);
            this.close();
        }

        return (
            <TileOrderActivityType
                key={orderActivityType.id}
                orderActivityType={orderActivityType}
                onClick={handleClick}
            />
        );
    }
}
