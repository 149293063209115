import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

export default withRouter(class CardAdminTermList extends Card {
    static contextTypes = {
        termsIndexed: PropTypes.object
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        let rows;
        let columns;
        if (this.props.type) {
            rows = Object.values(Object.values(this.context.termsIndexed).filter((term) => {
                return term.type === this.props.type;
            }));
            columns = [
                { key: "id", name: "ID", type: "number" },
                { key: "name", name: "Name", type: "string" },
            ];
        } else {
            rows = Object.values(this.context.termsIndexed);
            columns = [
                { key: "id", name: "ID", type: "number" },
                { key: "type", name: "Type", type: "string" },
                { key: "name", name: "Name", type: "string" },
            ];
        }

        const path = this.props.type.replace(
            /(_\w)/g,
            (match) => match[1].toUpperCase()
        );

        return (
            <Table
                search={true}
                columns={columns}
                rows={rows}
                onClick={(term) => {
                    this.props.history.push({
                        pathname: `/admin/${path}/${term.id}`
                    });
                }}
            />
        );
    }
});