import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

export default withRouter(class CardAdminTeamList extends Card {
    static contextTypes = {
        teamsIndexed: PropTypes.object
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={ true }
                columns={ [
                    { key: "id", name: "ID", type: "number" },
                    { key: "departmentId", name: "Department", type: "department", filter: true },
                    { key: "name", name: "Name", type: "string" },
                    { key: "managerId", name: "Manager", type: "user" },
                ]}
                sort={[
                    {
                        key: "departmentId",
                        direction: "ascending",
                        type: "department",
                    },
                    {
                        key: "name",
                        direction: "ascending"
                    },
                ]}
                rows={ Object.values(this.context.teamsIndexed) }
                onClick={ (team) => {
                    this.props.history.push({
                        pathname: "/admin/team/" + team.id
                    });
                } }
            />
        );
    }
});