import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import OrderStatusReason from "../../Utility/Crud/OrderStatusReason";
import OrderStatus from "../../Utility/Crud/OrderStatus";
import PropTypes from "prop-types";
import * as MUIColors from "@mui/material/colors";

export default class TileOrderStatusReason extends Tile {
    static contextTypes = {
        colorsIndexed: PropTypes.object,
        orderStatusesIndexed: PropTypes.object,
    };

    /**
      * @returns {string} The OrderStatusReason name.
      */
    getTitle() {
        return this.props.orderStatusReason ? OrderStatusReason.getDisplayName(this.props.orderStatusReason) : "None";
    }

    /**
      * @returns {string} The OrderStatusReason display name.
      */
    getSubtitle() {
        return this.props.orderStatusReason ? OrderStatus.getDisplayName(
            this.context.orderStatusesIndexed[this.props.orderStatusReason.orderStatus]
        ) : null;
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.orderStatusReason ? OrderStatusReason.getDisplayName(this.props.orderStatusReason) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        let color;
        if (this.props.orderStatusReason) {
            const orderStatus = this.context.orderStatusesIndexed[this.props.orderStatusReason.orderStatus];
            color = this.context.colorsIndexed[orderStatus.colorId];
        }

        return this.props.orderStatusReason ? MUIColors[color.hue][color.shade] : MUIGreyColor[500];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return OrderStatusReason.getIcon();
    }
}