import React from "react";
import { Route, Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
	Grid,
	Table,
	TableHeaderRow,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar,
} from "@devexpress/dx-react-grid-bootstrap4";
import { GroupingState, IntegratedGrouping } from "@devexpress/dx-react-grid";
import {
	Button,
	Card,
	CardGroup,
	CardBody,
	CardHeader,
	Container,
	Col,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	Row,
	Spinner,
	toast,
	ToastContainer,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	MDBIcon,
	Fa,
	MDBSelect,
	MDBInput,
	MDBCol,
	MDBRow, MDBBtn,
	MDBCard,
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBSpinner
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import AccountService from "../Security/AccountService/accountService";
import ReactTooltip from "react-tooltip";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import InputMask from "react-input-mask";
import GlobalFunctions from "../Filters/GlobalFunctions";
import ActivityService from "../Security/ActivityService/activityService";

export default class patientList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					title: "Name",
					name: "name",
				},
			],
			rows: [],
		};

		let results = this.determineResults(props.location);

		this.state = {
			data: data,
			isLoaded: true,
			filtersOpen: false,
			results: results,
			patientModal: false,
			mergeModal: false,
			nPatient: {
				firstName: "",
				lastName: "",
				dateOfBirth: null,
				smsMessages: true,
				insuranceType: null,
				insuranceSubType: null,
				phone: "",
			},
			allLocs: [],
			allSalesReps: [],
			locationsSelect: [
				{
					text: "Loading...",
					value: "loading",
				},
			],
			salesRepsSelect: [
				{
					text: "Loading...",
					value: "loading",
				},
			],
			accountListSelect: [
				{
					text: "Choose Sales Rep First...",
					value: "Choose Sales Rep First...",
				},
			],
			locationsSelected: {},
			salesRepsSelected: {},
			accountSelected: {},
			showDatePicker: false,
			quickFilter: [],
			quickFilterSelected: null,
			selectedPatients: [],
			masterPatient: null,
			selectMode: false,
			fName: "",
			lName: "",
			dateOfBirth: "",
			phone: "",
			policyResults: [],
		};

		this.changeGrouping = (grouping) => this.setState({ grouping });
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		accounts: PropTypes.array,
		internalLocations: PropTypes.array,
		allLocations: PropTypes.array,
		salesReps: PropTypes.array,
		insuranceTypes: PropTypes.array,
		insuranceSubTypes: PropTypes.array,
	};

	componentDidMount(){
		this.getLocationsOptions();
		this.getSalesRepsOptions();
	}

	 componentDidUpdate(prevProps, prevState, snapshot) {
	 	const { salesReps, currentUser, allLocations } = this.context;

	 	let st = this.state;
	 	if (
	 		st.allLocs.length !== allLocations.length &&
	 		st.currUser !== currentUser
	 	) {
	 		this.getLocationsOptions();
	 	}
	 	if (st.allSalesReps.length !== salesReps.length) {
	 		this.getSalesRepsOptions();
	 	}
	 }

	determineResults(propsData) {
		let results = [];
		if (propsData.results) {
			results = propsData.results;
		}
		return results;
	}

	getSalesRepsOptions(e) {
		const { salesReps, currentUser } = this.context;
		let np = this.state.nPatient,
			options = [];

		np.account = null;

		if (e === undefined || e.length === 0) {
			if (currentUser.role !== "SALES") {
				salesReps.map((rep) => {
					if (["SALES", "SALES_MANAGER"].includes(rep.role)) {
						return options.push({
							text: rep.firstname + " " + rep.lastname,
							value: rep.id,
						});
					}
					return null;
				});
			}
			else {
				options.push({
					text: currentUser.firstname + " " + currentUser.lastname,
					value: currentUser.id,
                })
			}

			options = options.sort((a, b) => {
				return a.text > b.text ? 1 : -1
			});

			this.setState({
				salesRepsSelect: options,
				allSalesReps: salesReps,
				nPatient: np,
			});
		} else {
			if (currentUser.role !== "SALES") {
				salesReps.map((rep) => {
					console.log(rep)
					if (["SALES", "SALES_MANAGER"].includes(rep.role) && (rep.userprofileLocations && rep.userprofileLocations.length > 0 && rep.userprofileLocations.filter(x => e.includes(x.locationsId)).length > 0)) {
						return options.push({
							text: rep.firstname + " " + rep.lastname,
							value: rep.id,
						});
					}
					return null;
				});
			}
			else {
				options.push({
					text: currentUser.firstname + " " + currentUser.lastname,
					value: currentUser.id,
				})
			}

			options = options.sort((a, b) => {
				return a.text > b.text ? 1 : -1
			});

			this.setState({
				salesRepsSelect: options,
				allSalesReps: salesReps,
				nPatient: np,
			});

			//return GroupService.getAllSalesRepsByLocations(e)
			//	.then((res) => {
			//		res.forEach((t) => {
			//			if (salesList.indexOf(t.id) === -1) {
			//				options.push({
			//					text: t.username,
			//					value: t.id,
			//				});
			//			}
			//			salesList.push(t.id);
			//		});

			//		this.setState({
			//			salesRepsSelect: options,
			//			salesRepsSelected: [],
			//			nPatient: np,
			//		});
			//	})
			//	.catch((e) => {});
		}
	}

	getAccountOptions(e) {
		if (e && e.length > 0) {
			let accountList = [],
				np = this.state.nPatient;

			np.account = null;

			return AccountService.getAccountsByUser(e)
				.then((res) => {
					res.forEach((t) => {
						accountList.push({
							text: t.name || "NO NAME",
							value: {
								label: t.name,
								value: t.id,
							},
						});
					});

					this.setState({
						accountListSelect: accountList,
						accountSelected: {},
						nPatient: np,
					});
				})
				.catch((err) => {
					console.log(err)
				});
		}
	}

	getLocationsOptions() {
		const { allLocations, currentUser } = this.context;
		let cu = currentUser;
		let select = [],
			locations = [];

		if (cu.role === "SALES") {
			locations = cu.userprofileLocations.map(x => x.locations);
		} else {
			locations = allLocations;
		}

		locations.map((location) => {
			// Populates the locations dropdown depending on which locations the user is in...JK
			if (location.type === 0) {
				return select.push({
					text: location.name,
					value: location.id,
				});
			}
			return null;
		});
		this.setState({
			locationsSelect: select,
			allLocs: allLocations,
		});
	}

	handleLocationChange = (e) => {
		let np = this.state.nPatient;
		np.account = null;

		this.setState({
			locationsSelected: e,
			nPatient: np,
			accountListSelect: [],
			accountSelected: {},
		});
		this.getSalesRepsOptions(e);
	};

	handleSaleRepChange = (e) => {
		this.setState({
			salesRepsSelected: e,
		});

		this.getAccountOptions(e);
	};

	selectLocation(location) {
		let nFilters = this.state.filters;
		nFilters.locationId = location.value.id;
		this.setState({ filters: nFilters });
	}

	renderLocationsFilter() {
		return (
			<Row>
				<Col md={"6"} style={{ marginTop: 5 }}>
					<MDBSelect
						search
						outline
						label="Select Location"
						className={"smallMargin searchField"}
						options={this.state.locationsSelect}
						getValue={this.handleLocationChange.bind(this)}
						value={this.state.locationsSelected}
					/>
				</Col>
				<Col md={"6"} style={{ marginTop: 5 }}>
					<MDBSelect
						search
						outline
						className={"smallMargin searchField"}
						label="Select Sales Rep"
						options={this.state.salesRepsSelect}
						getValue={this.handleSaleRepChange.bind(this)}
						value={this.state.salesRepsSelected}
					/>
				</Col>
				<Col md="12" style={{ marginTop: 5 }}>
					<MDBSelect
						search
						outline
						className={"smallMargin searchField"}
						label="Select Account"
						options={this.state.accountListSelect}
						getValue={(e) => {
							if (e && e.length > 0) {
								this.handlePatientAccountSelected(e[0]);
							}
						}}
						value={this.state.accountSelected}
					/>
				</Col>
			</Row>
		);
	}

	renderPrimarySection() {
		let item = this.state.nPatient;
		const { insuranceTypes, insuranceSubTypes } = this.context;
		return (
			<Col >
				<Row md={6} style={{ marginTop: 5 }}>
					<Dropdown className={"idDropdown idDropdownPatient"}>
						<DropdownToggle className={"idDropdownToggleP"} nav>
							Payor Source
							<span style={{ float: "right" }}>
								{item.insuranceType ? item.insuranceType.name : ""}
								&nbsp;
								<Fa icon={"angle-down"} />
							</span>
						</DropdownToggle>
						<DropdownMenu basic>
							<DropdownItem disabled>Select Payor Source</DropdownItem>
							<DropdownItem divider />
							{insuranceTypes.map((type, index) => {
								return (
									<DropdownItem
										key={index}
										onClick={this.changeInsuranceType.bind(this, type, false)}
									>
										{type.name}
									</DropdownItem>
								);
							})}
						</DropdownMenu>
					</Dropdown>
				</Row>
				<Row md={6} style={{ marginTop: 15 }}>
					<Dropdown className={"idDropdown idDropdownPatient"}>
						<DropdownToggle className={"idDropdownToggleP"} nav>
							Insurance
							<span style={{ float: "right" }}>
								{item.insuranceSubType ? item.insuranceSubType.name : ""}
								&nbsp;
								<Fa icon={"angle-down"} />
							</span>
						</DropdownToggle>
						<DropdownMenu basic>
							<DropdownItem disabled>Select Insurance</DropdownItem>
							<DropdownItem divider />
							{insuranceSubTypes.map((type, index) => {
								let nPatient = this.state.nPatient;
								if (
									nPatient.insuranceType &&
									type.insuranceType.id === nPatient.insuranceType.id
								) {
									return (
										<DropdownItem
											key={index}
											onClick={this.changeInsuranceSubType.bind(
												this,
												type,
												false
											)}
										>
											{type.name}{" "}
										</DropdownItem>
									);
								}
								return null;
							})}
						</DropdownMenu>
					</Dropdown>
				</Row>
			</Col>
		);
	}

	togglePatientModal() {
		let currentState = this.state.patientModal;
		this.setState({ patientModal: !currentState });
	}

	changeInsuranceType(type) {
		let nPatient = this.state.nPatient;
		nPatient.insuranceType = type;
		nPatient.insuranceTypeId = type.id;
		nPatient.insuranceSubType = null;
		this.setState({ nPatient: nPatient });
	}

	changeInsuranceSubType(type) {
		let nPatient = this.state.nPatient;
		nPatient.insuranceSubType = type;
		nPatient.insuranceSubTypeId = type.id;
		this.setState({ nPatient: nPatient });
	}

	toggleMergeModal() {
		let currentState = this.state.mergeModal;
		this.setState({ mergeModal: !currentState });
	}

	clearPatientObject() {
		let ac = {};

		this.handleLocationChange();

		this.setState({
			nPatient: ac,
			accountSelected: {},
			salesRepsSelected: {},
			locationsSelected: {},
		});
	}

	handleChange = (property, event) => {
		//update the property on the object that the user has edited in the order...BC
		let nPatient = this.state.nPatient;
		nPatient[property] = event.target.value;
		this.setState({ nPatient: nPatient });
	};

	handleDatePickerChange = (value) => {
		//update the date property on the object that the user has edited in the order...BC
		let nPatient = this.state.nPatient;
		nPatient.dateOfBirth = value;
		this.setState({ nPatient: nPatient });
	};

	handlePatientAccountSelected = (e) => {
		let account = { name: e.label, id: e.value },
			selectBoxVal = { text: e.label, id: e.value },
			nP = this.state.nPatient;

		nP.account = account;
		nP.accountId = account.id;

		this.setState({
			nPatient: nP,
			accountSelected: selectBoxVal,
		});
	};

	checkPatientDetails() {
		let patient = this.state.nPatient,
			text = null;
		console.log(patient)
		if(!patient.firstName || patient.firstName.length < 1){
			text = "Input First Name";
			toast.warn("First name is required");
		}

		if(!patient.lastName || patient.lastName.length < 1){
			text = "Input Last Name";
			toast.warn("Last name is required");
		}

		if(!patient.phone){
			text = "Input Phone Number";
			toast.warn("Phone number is required");
		}

		if(!patient.dateOfBirth){
			text = "Input Date of Birth";
			toast.warn("Birth date is required");
		}

		if(!patient.account){
			text = "Select Account";
			toast.warn("Account is required");
		}

		if(!patient.insuranceType){
			text = "Select Payor Source";
			toast.warn("Payor Source is required");
		}

		if(!patient.insuranceSubType){
			text = "Select Insurance";
			toast.warn("Insurance is required");
		}


		if(text){
			this.setState({
				errorText: text
			})
			return false;
		}

		this.setState({
			errorText: ""
		})
		return true;
	}

	createPatient(history) {

		let proceed = this.checkPatientDetails();

		if(!proceed){
			return;
		}

		let patient = this.state.nPatient;

		//Set DOB to 'MM-DD-YYYY' to strip time from the value...BC
		patient.dateOfBirth = new Date(patient.dateOfBirth).toLocaleDateString();

		return OrderService.createPatient(patient)
			.then((res) => {
				this.togglePatientModal();
				history.push({
					pathname: "/patientInformation/" + res.id,
					state: { patient: res },
				});
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	getPatientsByInformation(fName, lName, dob, phone) {
		return OrderService.getPatientsByInformation(fName, lName, dob, phone)
			.then((res) => {
				this.buildListResults(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	buildListResults(res) {
		let t = this;

		let ary = [],
			dt = this.state.data;

		if (res.length === 0) {
			toast.warn("No Patients Found");
		}

		res.forEach((patient, index) => {
			ary.push({
				name: patient.firstName + " " + patient.lastName,
				patient: patient,
			});
		});

		dt.rows = ary;

		t.setState({
			data: dt,
			results: res,
			isLoaded: true,
		});
	}

	checkSearch(key) {
		let t = this.state,
			fName = t.fName.trim(),
			lName = t.lName.trim(),
			phone = t.phone.trim();
		if (key.key === "Enter") {
			this.setState({ isLoaded: false });
			// if (!t.dateOfBirth) {
			// 	this.getPatientsByInformation(fName, lName);
			// } else {
				// this.getPatientsByInformation(fName, lName, t.dateOfBirth);
				this.getPatientsByInformation(fName, lName, t.dateOfBirth, phone);
			// }
		}
	}

	addPatientToList(p) {
		let patientList = this.state.selectedPatients,
			idx = patientList.indexOf(p.id);

		if (idx > -1) {
			patientList.splice(idx, 1);
		} else {
			patientList.push(p.id);
		}

		this.setState({
			patientList: patientList,
		});
	}

	renderCreatePatientRoute() {
		return (
			<Route
				render={({ history }) => (
					<MDBBtn
						outline
						rounded
						size="sm"
						color="success"
						className="px-2 toolbarButton"
						data-tip={"Create Patient"}
						data-place={"bottom"}
						onClick={() => this.createPatient(history)}
					>
						<MDBIcon icon="check"/>
					</MDBBtn>
				)}
					// <div
					// 	className={"ptBtn saveBtn"}
					// 	disabled={this.checkPatientDetails()}
					// 	data-tip={"Add New Patient"}
					// 	onClick={() => this.createPatient(history)}
					// >
					// 	<Fa size="2x" icon={"check"}>
					// 		{" "}
					// 	</Fa>
					// </div>
				// )}
			/>
		);
	}

	renderRouteHeader(patient) {
		let pl = this.state.selectedPatients,
			bgColor = pl.indexOf(patient.id) > -1 ? "red" : "#5881C1";
		return (
			<Route
				render={({ history }) => (
					<CardHeader
						className={"cardGroupCardHeader"}
						style={{ backgroundColor: bgColor, color: "white" }}
						onClick={() => {
							if (this.state.selectMode === true) {
								this.addPatientToList(patient);
							} else {
								history.push({
									pathname: "/patientInformation/" + patient.id,
									state: { patient: patient },
									results: this.state.results,
								});
							}
						}}
					>
						<span style={{ fontSize: 14 }}>
							{patient.firstName + " " + patient.lastName}
						</span>
						<span style={{ fontSize: 14, float: "right" }}>
							{" "}
							{GlobalFunctions.formatDateMoment(patient.dateOfBirth)}
						</span>
					</CardHeader>
				)}
			/>
		);
	}

	determineSelectModeButton() {
		let sm = this.state.selectMode;
		const { currentUser } = this.context;

		//if the user is not one of those roles, they are not allowed to merge patients...BC
		if (
			currentUser.role === "ADMIN" ||
			currentUser.role === "SYSTEM_ADMIN" ||
			currentUser.role === "EXECUTIVE_MANAGEMENT"
		) {
			if (sm === true) {
				return (
					<Button
						floating
						size="sm"
						color={"success"}
						data-place={"bottom"}
						data-tip={"Complete Merge"}
						onClick={() => this.toggleMergeModal()}
					>
						<MDBIcon far icon="object-group" style={{ fontSize: "2em" }} />
					</Button>
				);
			} else {
				return (
					<Button
						floating
						size="sm"
						color={"orange"}
						data-place={"bottom"}
						data-tip={"Merge Patients"}
						onClick={() =>
							this.setState({
								selectMode: true,
							})
						}
					>
						<MDBIcon icon="object-group" style={{ fontSize: "2em" }} />
					</Button>
				);
			}
		}
	}

	masterSelected(pid) {
		this.setState({
			masterPatient: pid,
		});
	}

	renderCardGroup(theme) {
		return (
			<div className={"cardGroupListContainer"}>
				<CardGroup className={"cardGroupList"} deck>
					{this.state.results.map((item, idx) => {
						return this.renderCard(item, idx, theme);
					})}
				</CardGroup>
			</div>
		);
	}

	renderCard(item, idx) {
		let today = new Date(),
			oldDate = new Date(today.getFullYear()-18, today.getMonth(), today.getDate()),
			isPediatric = item.dateOfBirth ? oldDate <= new Date(item.dateOfBirth) : false;
		return (
			<Card key={idx} className={"cardGroupCard"}>
				{this.renderRouteHeader(item)}
				<CardBody style={{ padding: 4 }}>
					<MDBRow>
						<MDBCol size="10">
							<span style={{ fontSize: 14 }}>
								{" "}
								{(item.city || "No City") + " , " + (item.state || "No State")}
							</span>
						</MDBCol>
						{isPediatric === true ?
						<MDBCol size="1">
							<MDBIcon icon="baby-carriage" data-tip="This is a pediatric patient" style={{color: "lightGreen", textAlign: "right"}} />
						</MDBCol>
						:
						''}
						<MDBCol size="11" style={{ fontSize: 14 }}>
							<p style={{ marginBottom: 0 }}>
								{item.phone || "No Phone Number"}{" "}
							</p>
						</MDBCol>
					</MDBRow>

					<p
						style={{
							fontSize: 12,
							position: "absolute",
							bottom: 4,
							right: 4,
							marginBottom: 0,
						}}
					>
						#{item.id}{" "}
					</p>
				</CardBody>
				<ReactTooltip />
			</Card>
		);
	}

	renderPatientModal() {
		return (
			<Modal
				centered
				size="md"
				isOpen={this.state.patientModal}
				toggle={() => {
					return;
				}}
			>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<ModalBody style={{ backgroundColor: "#FFFFFF" }}>
						<CardHeader
							style={{
								color: "white",
								backgroundColor: "#5881C1",
								marginBottom: 0,
							}}
							className="form-header text-center font-weight-bold"
						>
							<h3>Create New Patient</h3>
						</CardHeader>
						<Row style={{ paddingTop: 10 }}>
							<Col md="6">
								<MDBInput
									outline
									containerClass={"smallMargin"}
									label="First Name"
									onChange={this.handleChange.bind(this, "firstName")}
								/>
							</Col>
							<Col md="6">
								<MDBInput
									outline
									containerClass={"smallMargin"}
									label="Last Name"
									onChange={this.handleChange.bind(this, "lastName")}
								/>
							</Col>

							<MDBCol size="6">
								<InputMask
									mask="(999)-999-9999"
									value={this.state.nPatient.phone}
									onChange={this.handleChange.bind(this, "phone")}
								>
									{(inputProps) => (
										<MDBInput
											outline
											containerClass={"smallMargin"}
											{...inputProps}
											label="Phone Number"
											style={{ paddingTop: 8 }}
										/>
									)}
								</InputMask>
							</MDBCol>

							<Col md="6" className={"inputDateWrapper uiDateOutline"}>
								<KeyboardDatePicker
									value={this.state.nPatient.dateOfBirth}
									label={"Date of Birth"}
									size="small"
									inputVariant={"outlined"}
									autoOk={true}
									format={"MM/DD/YYYY"}
									onChange={(date) => this.handleDatePickerChange(date)}
								/>
							</Col>
						</Row>
						<Row>
							<Col md="12">{this.renderLocationsFilter()}</Col>
						</Row>
						<Row>
							<Col md="12"> {this.renderPrimarySection()} </Col>
						</Row>
					</ModalBody>
				</MuiPickersUtilsProvider>
				<ModalFooter color={"indigo"}>
					{this.renderCreatePatientRoute()}

					<div
						className={"ptBtn cancelBtn"}
						data-tip={"Cancel"}
						onClick={() => {
							this.clearPatientObject();
							this.togglePatientModal();
						}}
					>
						<Fa size="2x" icon={"times"}>
							{" "}
						</Fa>
					</div>
					<ReactTooltip />
				</ModalFooter>
			</Modal>
		);
	}

	mergePatients() {
		if (this.state.selectedPatients.length < 2) {
			toast.warn("You do not have enough patients selected to perform a merge");
			return;
		}

		if (this.state.masterPatient == null) {
			toast.warn("You must select a patient to be the master");
			return;
		}

		let masterIdx = this.state.selectedPatients.indexOf(
			this.state.masterPatient
		);

		this.state.selectedPatients.splice(masterIdx, 1);

		//do merge stuff
		let p = {
			masterId: this.state.masterPatient.toString(),
			ids: this.state.selectedPatients.toString(),
		};

		OrderService.mergePatients(p)
			.then(() => {
				toast.success("Merge Successful");

				this.setState({
					masterPatient: null,
					selectedPatients: [],
					selectMode: false,
					isLoaded: false,
				});

				//redo the search to get updated patient list
				this.checkSearch({ key: "Enter" });
			})
			.catch((e) => {
				toast.error("There was an error merging the patients.");
			});

		this.toggleMergeModal();
	}

	renderMergeModal() {
		let ids = this.state.selectedPatients;

		return (
			<Modal
				centered
				size="md"
				isOpen={this.state.mergeModal}
				toggle={() => {
					return;
				}}
			>
				<ModalBody style={{ backgroundColor: "#FFFFFF" }}>
					<CardHeader
						style={{
							color: "white",
							backgroundColor: "#5881C1",
							marginBottom: 20,
						}}
						className="form-header text-center font-weight-bold"
					>
						<h3>Select Master Patient Record</h3>
					</CardHeader>

					<Row>
						{ids.map((p, idx) => {
							return (
								<Col key={idx} size="3">
									<Input
										label={p}
										checked={this.state.masterPatient === p}
										onChange={() => this.masterSelected(p)}
										type="radio"
										id={"checkbox" + p}
									/>
								</Col>
							);
						})}
					</Row>
				</ModalBody>
				<ModalFooter style={{ paddingTop: 0, paddingBottom: 0 }}>
					<Button
						color={"success"}
						floating
						size="sm"
						data-tip={"Merge"}
						onClick={() => {
							this.mergePatients();
						}}
					>
						<Fa size="2x" icon={"check"}>
							{" "}
						</Fa>
					</Button>

					<Button
						color={"red"}
						floating
						size="sm"
						data-tip={"Cancel"}
						onClick={() => {
							this.setState({
								masterPatient: null,
								selectedPatients: [],
								selectMode: false,
							});
							this.toggleMergeModal();
						}}
					>
						<Fa size="2x" icon={"times"}>
							{" "}
						</Fa>
					</Button>

					<ReactTooltip />
				</ModalFooter>
			</Modal>
		);
	}
	
    togglePolicyModal() {
        let isOpen = this.state.policyModalOpen;

        this.setState({
            policyModalOpen: !isOpen,
            policyResults: [],
            policyText: "",
        })
    }

	searchByPolicyNumber() {
        let number = this.state.policyText;

        this.setState({
            searchPressed: true
        })

        ActivityService.getByPolicyNumber(number).then(r => {
            this.setState({
                policyResults: r,
                searchPressed: false,
            })
        }).catch(e => {
            this.setState({
                searchPressed: false,
            })
        })
    }

	renderPolicyResults() {
        let r = this.state.policyResults;

        return (
            <div>
                {r.map((v, idx) => {
                    return (
                        <MDBCard key={idx} className={"policyNumberRow"}
                                 onClick={() => this.openOrderFromPolicy(v)}>
                            <MDBRow>
                                <MDBCol>
                                    {v.patientName}
                                </MDBCol>
                                <MDBCol>
                                    {v.status}&nbsp; {v.orderStatusReason ? v.orderStatusReason.name : ""}
                                </MDBCol>

                                <MDBCol>
                                    {v.id}
                                </MDBCol>
                            </MDBRow>

                        </MDBCard>
                    )
                })}
            </div>
        )
    }

	openOrderFromPolicy(order) {
        if (order.orderType && (order.orderType.toLowerCase() === "service" || order.orderType.toLowerCase() === "pickup")) {
            this.props.history.push({
                pathname: `/patientInformation/`+order.patientId,
                state: {
                    goto: "followUp",
                    shouldOpenOrderId: order.id,
                    followUpId: order.id,
                },
            });
            return;
        } 

        this.setState({
            targetPage: "/order/",
            targetLink: order.id,
        });

        setTimeout(() => {
            document.getElementById("orderListId").click();
        }, 5);

        return;
    }


	renderPolicyModal() {
        return (
            <MDBModal isOpen={this.state.policyModalOpen} toggle={() => this.togglePolicyModal()}>
                <MDBModalHeader toggle={() => this.togglePolicyModal()}>
                    Search By Policy Number
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol size="8">
                            <MDBInput
                                outline
                                containerClass={"smallMargin"}
                                style={{paddingTop: 8}}
                                label={"Policy Number"}
                                value={this.state.policyText}
                                onChange={(e) => {
                                    this.setState({policyText: e.target.value})
                                }}/>
                        </MDBCol>
                        <MDBCol>
                            {this.state.searchPressed ?
                                <MDBSpinner small/> :

                                <MDBBtn
                                    color={'indigo'}
                                    size={"sm"}
                                    onClick={() => this.searchByPolicyNumber()}>
                                    <MDBIcon icon={"search"}/>
                                </MDBBtn>
                            }
                        </MDBCol>

                    </MDBRow>
                    <div style={{maxHeight: 300, overflowY: 'scroll', overflowX: 'hidden'}}>
                        {this.renderPolicyResults()}
                    </div>
                </MDBModalBody>
            </MDBModal>
        )
    }

	renderTable() {
		const TableRow = ({ row, ...restProps }) => (
			<Table.Row
				{...restProps}
				// eslint-disable-next-line no-alert
				onClick={() => {
					this.props.history.push({
						pathname: "/patientInformation/" + row.patient.id,
						state: { patient: row.patient },
					});
				}}
				style={{
					cursor: "pointer",
				}}
			/>
		);

		if (this.state.isLoaded === true) {
			return (
				<Grid
					style={{ maxWidth: "1800px !important" }}
					rows={this.state.data.rows}
					columns={this.state.data.columns}
				>
					<DragDropProvider />
					<GroupingState onGroupingChange={this.changeGrouping} />
					<IntegratedGrouping />
					<Table rowComponent={TableRow} />
					<TableHeaderRow />
					<TableGroupRow />
					<Toolbar />
					<GroupingPanel />
				</Grid>
			);
		} else {
			return <div></div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderTableOrSpinner(theme) {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return this.renderCardGroup(theme);
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>

				<CardHeader style={{backgroundColor: "#5881C1"}}>
					<Row>
						<Col size="3" style={{ paddingTop: "10px" }}>
							<div>
								<input
									className="form-control my-0 py-1 listSearchInputBackground"
									type="text"
									placeholder="First Name...."
									aria-label="Search"
									onChange={(event) =>
										this.setState({ fName: event.target.value })
									}
									onKeyPress={(key) => this.checkSearch(key)}
								/>
							</div>
						</Col>

						<Col size="3" style={{ paddingTop: "10px" }}>
							<div>
								<input
									className="form-control my-0 py-1 listSearchInputBackground"
									type="text"
									placeholder="Last Name...."
									aria-label="Search"
									onChange={(event) =>
										this.setState({ lName: event.target.value })
									}
									onKeyPress={(key) => this.checkSearch(key)}
								/>
							</div>
						</Col>

						<Col size="2" style={{ paddingTop: "10px" }}>
							<div>
								<input
									className="form-control my-0 py-1 listSearchInputBackground"
									type="text"
									placeholder="Phone Number...."
									aria-label="Search"
									onChange={(event) =>
										this.setState({ phone: event.target.value })
									}
									onKeyPress={(key) => this.checkSearch(key)}
								/>
							</div>
							{/*<Button*/}
							{/*	floating*/}
							{/*	size="sm"*/}
							{/*	color={"indigo"}*/}
							{/*	data-place={"bottom"}*/}
							{/*	data-tip={"Search"}*/}
							{/*	onClick={() => this.checkSearch({ key: "Enter" })}*/}
							{/*>*/}
							{/*	<MDBIcon icon="search" style={{ fontSize: "2em" }} />*/}
							{/*</Button>*/}
						</Col>

						<Col size="2" style={{ paddingTop: "10px" }}>
							<div className="input-group">
								{/*month--day--year trying to save seperately*/}
								<input
									className="form-control"
									type="date"
									placeholder="Date of Birth...."
									aria-label="Search"
									onChange={(event) => {
										var dt = new Date(event.target.value);
										dt.setDate(dt.getDate() + 1);
										let mo = dt.getMonth() + 1,
											dy = dt.getDate(),
											yr = dt.getFullYear();
										this.setState({ dateOfBirth: yr + "-" + (mo < 10 ? "0" + mo : mo) + "-" + (dy < 10 ? "0" + dy : dy) });
									}}
									onKeyPress={(key) => this.checkSearch(key)}
								/>
							</div>
						</Col>
						<Link
							id="orderListId"
							to={{
								pathname: `${this.state.targetPage}${this.state.targetLink}`,
								state: { goto: "followup" },
							}}
							target="_blank"
							rel="opener"
							style={{ display: "none" }}
							activeclassname="active"
						/>
						<Col size="2">
							<Button
								floating
								size="sm"
								color={"indigo"}
								data-place={"bottom"}
								data-tip={"Search"}
								onClick={() => this.checkSearch({ key: "Enter" })}
							>
								<MDBIcon icon="search" style={{ fontSize: "2em" }} />
							</Button>
							<Button
								floating
								size="sm"
								color={"secondary"}
								data-place={"bottom"}
								data-tip={"Add New Patient"}
								onClick={() => this.togglePatientModal()}
							>
								<MDBIcon icon="plus" style={{ fontSize: "2em" }} />
							</Button>
							{this.determineSelectModeButton()}
							<Button
								floating
								size="sm"
								color={"amber"}
								data-place={"bottom"}
								data-tip={"Find by Policy #"}
								onClick={() => this.togglePolicyModal()}
							>
								<MDBIcon icon="search" style={{ fontSize: "2em" }} />
							</Button>
						</Col>
						{/*<Col size="1">{this.determineSelectModeButton()}</Col>*/}
					</Row>
					<ReactTooltip />
				</CardHeader>
				{this.renderPolicyModal()}
				{this.renderTableOrSpinner()}
				{this.renderPatientModal()}
				{this.renderMergeModal()}
			</div>
		);
	}
}
