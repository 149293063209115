import React from "react";
import {
	Spinner,
	MDBIcon,
	MDBCard,
	MDBRow,
	MDBBtn,
	MDBCol,
	MDBCardHeader,
	MDBTableHead,
	MDBTable,
	MDBTableBody,
	MDBNav,
	MDBNavItem,
	MDBNavLink,
	MDBTabContent,
	MDBTabPane,
} from "mdbreact";
import { Bar, Line } from "react-chartjs-2";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ColorHash from "color-hash";
import ReportsService from "../Security/ReportsService/reportsService";

const bgColors = [
	"rgba(244, 67, 54, .4)",
	"rgba(0, 188, 212, .4)",
	"rgba(255, 152, 0, .4)",
	"rgba(156, 39, 176,.4)",
	"rgba(0, 150, 136,.4)",
	"rgba(255, 235, 59,.4)",
	"rgba(3, 169, 244,.4)",
	"rgba(233, 30, 99,.4)",
	"rgba(76, 175, 80,.4)",
	"rgba(63, 81, 181,.4)",
	"rgba(255, 87, 34,.4)",
	"rgba(139, 195, 74,.4)",
	"rgba(103, 58, 183,.4)",
	"rgba(205, 220, 57,.4)",
	"rgba(33, 150, 243,.4)",
	"rgba(255, 202, 40,.4)",
	"rgba(198, 40, 40,.4)",
	"rgba(0, 131, 143,.4)",
	"rgba(239, 108, 0,.4)",
	"rgba(106, 27, 154,.4)",
	"rgba(0, 105, 92,.4)",
	"rgba(249, 168, 37,.4)",
	"rgba(2, 119, 189,.4)",
	"rgba(173, 20, 87,.4)",
	"rgba(158, 157, 36,.4)",
	"rgba(40, 53, 147,.4)",
];

const bColors = [
	"rgba(244, 67, 54, 1)",
	"rgba(0, 188, 212,1)",
	"rgba(255, 152, 0,1)",
	"rgba(156, 39, 176,1)",
	"rgba(0, 150, 136,1)",
	"rgba(255, 235, 59,1)",
	"rgba(3, 169, 244,1)",
	"rgba(233, 30, 99,1)",
	"rgba(76, 175, 80,1)",
	"rgba(63, 81, 181,1)",
	"rgba(255, 87, 34,1)",
	"rgba(139, 195, 74,1)",
	"rgba(103, 58, 183,1)",
	"rgba(205, 220, 57,1)",
	"rgba(33, 150, 243,1)",
	"rgba(255, 202, 40,1)",
	"rgba(198, 40, 40,1)",
	"rgba(0, 131, 143,1)",
	"rgba(239, 108, 0,1)",
	"rgba(106, 27, 154,1)",
	"rgba(0, 105, 92,1)",
	"rgba(249, 168, 37,1)",
	"rgba(2, 119, 189,1)",
	"rgba(173, 20, 87,1)",
	"rgba(158, 157, 36,1)",
	"rgba(40, 53, 147,1)",
];

export default class dailyOrderCounts extends React.Component {
	constructor(props) {
		super(props);

		const dataBar = {
			labels: [""],
			datasets: [
				{
					label: "",
					data: [0],
					backgroundColor: ["rgba(255, 134,159,0.4)"],
					borderWidth: 2,
					borderColor: ["rgba(255, 134, 159, 1)"],
				},
			],
		};

		const dataLine = {
			labels: [],
			datasets: [
				{
					label: "New",
					fill: true,
					lineTension: 0.3,
					backgroundColor: "rgba(225, 204,230, .3)",
					borderColor: "rgb(205, 130, 158)",
					borderCapStyle: "butt",
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: "miter",
					pointBorderColor: "rgb(205, 130,158)",
					pointBackgroundColor: "rgb(255, 255, 255)",
					pointBorderWidth: 10,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: "rgb(253,0,0)",
					pointHoverBorderColor: "rgb(232,128,128)",
					pointHoverBorderWidth: 2,
					pointRadius: 1,
					pointHitRadius: 10,
					data: [],
				},
				{
					label: "Service",
					fill: true,
					lineTension: 0.3,
					backgroundColor: "rgba(107,109,231,0.3)",
					borderColor: "rgb(35, 26, 136)",
					borderCapStyle: "butt",
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: "miter",
					pointBorderColor: "rgb(35, 26, 136)",
					pointBackgroundColor: "rgb(255, 255, 255)",
					pointBorderWidth: 10,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: "rgb(81,103,241)",
					pointHoverBorderColor: "rgb(136,209,241)",
					pointHoverBorderWidth: 2,
					pointRadius: 1,
					pointHitRadius: 10,
					data: [],
				},
				{
					label: "Exchange",
					fill: true,
					lineTension: 0.3,
					backgroundColor: "rgba(238,195,114,0.3)",
					borderColor: "rgb(200,220,75)",
					borderCapStyle: "butt",
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: "miter",
					pointBorderColor: "rgb(205, 130,1 58)",
					pointBackgroundColor: "rgb(255, 255, 255)",
					pointBorderWidth: 10,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: "rgb(186,163,6)",
					pointHoverBorderColor: "rgb(250,218,111)",
					pointHoverBorderWidth: 2,
					pointRadius: 1,
					pointHitRadius: 10,
					data: [],
				},
				{
					label: "Pickup",
					fill: true,
					lineTension: 0.3,
					backgroundColor: "rgba(184,210,187,0.3)",
					borderColor: "rgb(26,136,41)",
					borderCapStyle: "butt",
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: "miter",
					pointBorderColor: "rgb(0,175,16)",
					pointBackgroundColor: "rgb(255, 255, 255)",
					pointBorderWidth: 10,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: "rgb(119,255,0)",
					pointHoverBorderColor: "rgb(117,236,87)",
					pointHoverBorderWidth: 2,
					pointRadius: 1,
					pointHitRadius: 10,
					data: [],
				},
			],
		};

		this.state = {
			newOrdersData: dataBar,
			serviceOrdersData: dataBar,
			exchangeOrdersData: dataBar,
			pickupOrdersData: dataBar,
			dataLine: dataLine,
			allOrderTypes: [],
			ordersDayLocation: [],
			superData: {},
			startDate: new Date(),
			endDate: new Date(),
			isLoaded: true,
			barLoaded: true,
			radio: "New",
			tableColumns: ["New", "Service", "Pickup", "Exchange"],
			rowsData: [],
			activeItem: 1,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	getDailyOrderCount() {
		let st = this.state;

		let filters = {
			start: new Date(st.startDate).toLocaleDateString(),
			end: new Date(st.endDate).toLocaleDateString(),
		};

		this.setState({ isLoaded: false, barLoaded: false });

		ReportsService.getDailyOrderCounts(filters)
			.then((res) => {
				this.setState({ allOrderTypes: res });

				this.formatRows();
			})
			.catch((err) => {
				console.log(err);
			});

		ReportsService.getDailyOrderCountsLocation(filters)
			.then((res) => {
				this.setState({ ordersDayLocation: res });

				this.formatBarRows(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	formatRows() {
		let orders = this.state.allOrderTypes,
				sd = {};

		orders.forEach((o) => {
			if (!sd[o.orderCreateDate]) {
				let nObj = {
					[o.orderType]: o.numberOfOrders,
					date: [o.orderCreateDate],
				};

				sd[o.orderCreateDate] = nObj;
			} else {
				sd[o.orderCreateDate][o.orderType] = o.numberOfOrders;
			}
		});

		this.setState({
			superData: sd,
		});

		//this.displayCurrentData();
		this.displayLineChartData();
	}

	formatBarRows(data) {
		let start = new Date(this.state.startDate),
			end = new Date(this.state.endDate);

		let lbo = {},
			lbl = [],
			dary = [];

		while (start <= end) {
			lbo[start.toLocaleDateString()] = {
				newOrders: 0,
				serviceOrders: 0,
				exchangeOrders: 0,
				pickupOrders: 0,
			};
			dary.push(start.toLocaleDateString());
			start.setDate(start.getDate() + 1);
		}

		data.forEach((d) => {
			let cidx = lbl.find((v) => {
				return v.salesLocation === d.salesLocation;
			});

			if (!cidx) {
				cidx = {
					salesLocation: d.salesLocation,
					dates: JSON.parse(JSON.stringify(lbo)),
				};

				lbl.push(cidx);
			}

			let prop = "newOrders";

			switch (d.orderType) {
				case "Service":
					prop = "serviceOrders";
					break;
				case "Exchange":
					prop = "exchangeOrders";
					break;
				case "Pickup":
					prop = "pickupOrders";
					break;
				default:
					break;
			}

			cidx.dates[new Date(d.orderCreateDate).toLocaleDateString()][prop] +=
				d.numberOfOrders;
		});

		this.generateByType(lbl, dary);
	}

	generateByType(maxData, dateAry) {
		let nd = {
				labels: dateAry,
				datasets: [],
			},
			sd = {
				labels: dateAry,
				datasets: [],
			},
			ed = {
				labels: dateAry,
				datasets: [],
			},
			pd = {
				labels: dateAry,
				datasets: [],
			};

		maxData.forEach((loc, idx) => {
			let ndd = [],
				sdd = [],
				edd = [],
				pdd = [];

			for (let v in loc.dates) {
				ndd.push(loc.dates[v].newOrders);
				sdd.push(loc.dates[v].serviceOrders);
				edd.push(loc.dates[v].exchangeOrders);
				pdd.push(loc.dates[v].pickupOrders);
			}

			let bgc = new Array(ndd.length),
				bc = new Array(ndd.length);

			bgc.fill(bgColors[idx], 0);
			bc.fill(bColors[idx], 0);

			let datasetObject = {
				label: loc.salesLocation,
				backgroundColor: bgc,
				borderWidth: 2,
				borderColor: bc,
			};
			let c0 = Object.assign({ data: ndd }, datasetObject);
			let c1 = Object.assign({ data: sdd }, datasetObject);
			let c2 = Object.assign({ data: edd }, datasetObject);
			let c3 = Object.assign({ data: pdd }, datasetObject);

			nd.datasets.push(c0);
			sd.datasets.push(c1);
			ed.datasets.push(c2);
			pd.datasets.push(c3);
		});

		this.setState({
			newOrdersData: nd,
			serviceOrdersData: sd,
			exchangeOrdersData: ed,
			pickupOrdersData: pd,
			barLoaded: true,
		});
	}

	//This will convert all the data in the grid to a csv file
	clientCSV() {
		let result = "",
			secondaryResult = "",
			keys = ["Date", "New", "Service", "Pickup", "Exchange"],
			secondaryKeys = [
				"Sales Rep",
				"Sales Location",
				"Created Date",
				"Order Type",
				"Number of Orders",
			],
			columnDelimiter = ",",
			lineDelimiter = "\n",
			data = this.state.superData,
			secondaryData = this.state.ordersDayLocation;

		if (Object.keys(data).length === 0 && data.constructor === Object) {
			return null;
		}

		result += keys.join(columnDelimiter);
		result += lineDelimiter;

		secondaryResult += secondaryKeys.join(columnDelimiter);
		secondaryResult += lineDelimiter;

		for (let q in data) {
			result += q + columnDelimiter;
			result += (data[q].New || 0) + columnDelimiter;
			result += (data[q].Service || 0) + columnDelimiter;
			result += (data[q].Exchange || 0) + columnDelimiter;
			result += (data[q].Pickup || 0) + columnDelimiter;
			result += lineDelimiter;
		}

		secondaryData.forEach((x) => {
			secondaryResult += (x.salesRep || "No Rep") + columnDelimiter;
			secondaryResult += (x.salesLocation || "No Location") + columnDelimiter;
			secondaryResult += x.orderCreateDate + columnDelimiter;
			secondaryResult += x.orderType + columnDelimiter;
			secondaryResult += x.numberOfOrders + columnDelimiter;
			secondaryResult += lineDelimiter;
		});

		this.downloadBlob(result, "NewOrdersByType.csv");
		this.downloadBlob(secondaryResult, "OrdersBySalesRep.csv");
	}

	downloadBlob(csvData, filename) {
		let blob = new Blob([csvData], {
			type: "application/csv;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a"),
				csvUrl = URL.createObjectURL(blob);

			link.href = csvUrl;
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	adjustGraph(r) {
		this.setState({
			radio: r,
			isLoaded: false,
		});
		setTimeout(() => {
			this.displayCurrentData();
		}, 300);
	}

	displayCurrentData() {
		let radio = this.state.radio,
			superData = this.state.superData;

		let fd = {
				data: [],
				backgroundColor: [],
				hoverBackgroundColor: [],
			},
			fl = [];

		for (let prop in superData) {
			if (superData[prop][radio]) {
				let bgHash = new ColorHash(),
					olHash = new ColorHash({ lightness: 0.5 });

				let bgC = bgHash.hex([prop] + [radio]),
					olC = olHash.hex([prop] + [radio]);

				fd.data.push(superData[prop][radio]);
				fd.backgroundColor.push(bgC);
				fd.hoverBackgroundColor.push(olC);

				fl.push(prop);
			}
		}

		this.setState({
			isLoaded: true,
		});
	}

	displayLineChartData() {
		let sd = this.state.superData,
			nAry = {
				New: [],
				Service: [],
				Pickup: [],
				Exchange: [],
			};
		let lbl = [];

		for (let p in sd) {
			nAry.New.push(sd[p].New || 0);
			nAry.Service.push(sd[p].Service || 0);
			nAry.Pickup.push(sd[p].Pickup || 0);
			nAry.Exchange.push(sd[p].Exchange || 0);
			lbl.push(p);
		}

		let d = this.state.dataLine;

		d.labels = lbl;
		d.datasets[0].data = nAry.New;
		d.datasets[1].data = nAry.Service;
		d.datasets[2].data = nAry.Pickup;
		d.datasets[3].data = nAry.Exchange;

		this.setState({
			dataLine: d,
			isLoaded: true,
		});
	}

	renderTableRows() {
		let sd = this.state.superData,
			nAry = [];

		for (let p in sd) {
			nAry.push(sd[p]);
		}

		return nAry.map((d, idx) => {
			return (
				<tr key={idx}>
					<td>{d.date}</td>
					<td>{d.New || 0}</td>
					<td>{d.Service || 0}</td>
					<td>{d.Exchange || 0}</td>
					<td>{d.Pickup || 0}</td>
				</tr>
			);
		});
	}

	renderBarTable(barData) {
		if (this.state.barLoaded === false) {
			return (
				<MDBCard style={{ height: 600 }}>
					<div style={{ textAlign: "center", verticalAlign: "center" }}>
						<Spinner multicolor />
					</div>
				</MDBCard>
			);
		}
		return (
			<div style={{ maxHeight: 600 }}>
				<Bar height={100} data={barData} options={{ responsive: true }} />
			</div>
		);
	}

	renderTableOrSpinner() {
		if (this.state.isLoaded === false) {
			return (
				<MDBCard style={{ height: 600 }}>
					<div style={{ textAlign: "center", verticalAlign: "center" }}>
						<Spinner multicolor />
					</div>
				</MDBCard>
			);
		}
		return (
			<div>
				<MDBCard>
					<MDBNav className="nav-tabs">
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === 1}
								onClick={() => this.setState({ activeItem: 1 })}
								role="tab"
							>
								Daily Orders Combined
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === 2}
								onClick={() => this.setState({ activeItem: 2 })}
								role="tab"
							>
								Daily Orders Table
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === 3}
								onClick={() => this.setState({ activeItem: 3 })}
								role="tab"
							>
								New By Location
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === 4}
								onClick={() => this.setState({ activeItem: 4 })}
								role="tab"
							>
								Service By Location
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === 5}
								onClick={() => this.setState({ activeItem: 5 })}
								role="tab"
							>
								Exchange By Location
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === 6}
								onClick={() => this.setState({ activeItem: 6 })}
								role="tab"
							>
								Pickup By Location
							</MDBNavLink>
						</MDBNavItem>
					</MDBNav>
					<MDBTabContent activeItem={this.state.activeItem}>
						<MDBTabPane tabId={1} role="tabpanel">
							<div style={{ maxHeight: 600 }}>
								<Line
									height={100}
									data={this.state.dataLine}
									options={{ responsive: true }}
								/>
							</div>
						</MDBTabPane>
						<MDBTabPane tabId={2} role="tabpanel">
							<MDBTable>
								<MDBTableHead>
									<tr>
										<th></th>
										<th>New</th>
										<th>Service</th>
										<th>Exchange</th>
										<th>Pickup</th>
									</tr>
								</MDBTableHead>
								<MDBTableBody>{this.renderTableRows()}</MDBTableBody>
							</MDBTable>
						</MDBTabPane>
						<MDBTabPane tabId={3} role="tabpanel">
							{this.renderBarTable(this.state.newOrdersData)}
						</MDBTabPane>
						<MDBTabPane tabId={4} role="tabpanel">
							{this.renderBarTable(this.state.serviceOrdersData)}
						</MDBTabPane>
						<MDBTabPane tabId={5} role="tabpanel">
							{this.renderBarTable(this.state.exchangeOrdersData)}
						</MDBTabPane>
						<MDBTabPane tabId={6} role="tabpanel">
							{this.renderBarTable(this.state.pickupOrdersData)}
						</MDBTabPane>
					</MDBTabContent>
				</MDBCard>
			</div>
		);
	}

	//load in the table
	render() {
		return (
			<div style={{ padding: 12 }}>
				{/*<MDBContainer style={{paddingTop: 12}}>*/}
				<MDBCard style={{ marginBottom: 15 }}>
					<MDBCardHeader style={{ backgroundColor: "white" }}>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<MDBRow style={{ justifyContent: "center" }}>
								<MDBCol size="6">
									<MDBRow>
										<MDBCol
											size="4"
											className={"inputDateWrapper reportsDateOutline"}
										>
											<DatePicker
												inputVariant={"outlined"}
												size={"small"}
												format={"MM/DD/YYYY"}
												label={"Start Date"}
												value={this.state.startDate}
												onChange={(evt) => this.setState({ startDate: evt })}
											/>
										</MDBCol>
										<MDBCol
											size="4"
											className={"inputDateWrapper reportsDateOutline"}
										>
											<DatePicker
												inputVariant={"outlined"}
												size={"small"}
												format={"MM/DD/YYYY"}
												label={"End Date"}
												value={this.state.endDate}
												onChange={(evt) => this.setState({ endDate: evt })}
											/>
										</MDBCol>
										<MDBCol size="4">
											<MDBBtn
												size={"sm"}
												data-tip={"Search"}
												color={"indigo"}
												floating
												onClick={() => this.getDailyOrderCount()}
											>
												<MDBIcon icon={"search"} />
											</MDBBtn>

											<MDBBtn
												size={"sm"}
												data-tip={"Download"}
												color={"secondary"}
												floating
												onClick={() => this.clientCSV()}
											>
												<MDBIcon icon={"download"} />
											</MDBBtn>
										</MDBCol>
									</MDBRow>
								</MDBCol>
							</MDBRow>
						</MuiPickersUtilsProvider>
					</MDBCardHeader>
				</MDBCard>

				{this.renderTableOrSpinner()}
				<ReactTooltip />
			</div>
		);
	}
}
