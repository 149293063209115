import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
	Grid,
	Table,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar,
	ColumnChooser,
	TableColumnResizing,
	TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import {
	GroupingState,
	SortingState,
	IntegratedSorting,
	IntegratedGrouping,
	PagingState,
	IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
    Plugin,
    Template,
    TemplateConnector,
    TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import ReactTooltip from "react-tooltip";
import {
    Button,
    Container,
    Collapse,
    Col,
    Fa,
    Row,
    Spinner,
    ToastContainer,
    MDBIcon,
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import NoteService from "../Security/NoteService/noteService";
import UserFilters from "../Filters/UserFilters";

import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "./orderList.css";
import GlobalFunctions from "../Filters/GlobalFunctions";

const ItemCounter = () => (
    <Plugin name="ItemCounter">
        <Template
            name="tableCell"
            predicate={({tableRow}) => isGroupTableRow(tableRow)}
        >
            {(params) => (
                <TemplateConnector>
                    {({getCollapsedRows}) => {
                        const updatedParams = {
                            ...params,
                            tableRow: {
                                ...params.tableRow,
                                row: {
                                    ...params.tableRow.row,
                                    collapsedRows: getCollapsedRows(params.tableRow.row) || [],
                                },
                            },
                        };
                        return <TemplatePlaceholder params={updatedParams}/>;
                    }}
                </TemplateConnector>
            )}
        </Template>
    </Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{height: "100%"}}/>;

//function to properly compare dates when sorting in the grid...BC
const compareDate = (a, b) => {
    const priorityA = new Date(a);
    const priorityB = new Date(b);
    if (priorityA === priorityB) {
        return 0;
    }
    return priorityA < priorityB ? -1 : 1;
};

function getVal(row) {
    if (row.collapsedRows.length > 0) {
        let r = row.collapsedRows,
            t = 0;

        r.forEach((rd) => {
            t += rd.totalPoints;
        });

        return "Count: " + row.collapsedRows.length + "    Points: " + t.toFixed(1);
    } else {
        return "";
    }
}

const Content = ({row, column}) => (
    <span>
		<span>
			{column.title} : {row.value}
		</span>
		<span style={{fontWeight: "bold"}}>&nbsp; {getVal(row)}</span>
	</span>
);

export default class orderList extends React.Component {
    constructor(props) {
        super(props);

        let shouldOverride = false,
            preFilters = {};

        if (
            props.location &&
            props.location.state &&
            props.location.state.shouldOverride
        ) {
            shouldOverride = true;
            preFilters = props.location.state.filters;
        }

		const data = {
			columns: [
				{
					title: "Id",
					name: "id",
				},
				{
					title: "P",
					name: "priority",
				},
				{
					title: "R",
					name: "patientResponsibility",
				},
				{
					title: "Order Type",
					name: "orderType",
				},
				{
					title: "Status",
					name: "status",
				},
				{
					title: "Reason",
					name: "reason",
				},
				{
					title: "Sales Rep",
					name: "salesRep",
				},
				{
					title: "Patient Name",
					name: "patientName",
				},
				{
					title: "Account",
					name: "account",
				},
				{
					title: "Setup Date",
					name: "setupDate",
				},
				{
					title: "Payor Source",
					name: "payorSource",
				},
				{
					title: "Insurance",
					name: "insurance",
				},
				{
					title: "Category",
					name: "category",
				},
				{
					title: "Product",
					name: "product",
				},
				{
					title: "Total Points",
					name: "totalPoints",
				},
				{
					title: "Last Note",
					name: "lastNoteDate",
				},
				{
					title: "Total Days",
					name: "totalDays",
				},
				{
					title: "Last Status Change",
					name: "lastStatusChange",
				},
				{
					title: "Sales Location",
					name: "salesLocation",
				},
				{
					title: "Service Location",
					name: "serviceLocation",
				},
				{
					title: "ATP",
					name: "atp",
                },
                {
                    title: "Technician",
                    name: "technician",
                },
                {
					title: "Deliver By Date",
					name: "deliverByDate",
				},
				{
					title: "Expected Ship Date",
					name: "expectedShipDate",
				},
				{
					title: "Scheduled Date",
					name: "scheduledDate",
				},
				{
					title: "Marketing Campaign",
					name: "campaignName",
				},
				{
					title: "Same or Similar",
					name: "sameOrSimilar",
				},
				{
					title: "Pediatric",
					name: "pediatric",
				},
				 {
				     title:'Region',
				     name:'region',
				 },
			],
			groupSummaryItems: [{ columnName: "totalPoints", type: "sum" }],
			integratedSortingColumnExtensions: [
				{ columnName: "setupDate", compare: compareDate },
				{ columnName: "lastNoteDate", compare: compareDate },
				{ columnName: "lastStatusChange", compare: compareDate },
			],
			rows: [],
		};

		let defaultColumnWidths = [
			{ columnName: "id", width: 80 },
			{ columnName: "priority", width: 50 },
			{ columnName: "patientResponsibility", width: 50 },
			{ columnName: "orderType", width: 130 },
			{ columnName: "status", width: 100 },
			{ columnName: "reason", width: 130 },
			{ columnName: "salesRep", width: 130 },
			{ columnName: "patientName", width: 130 },
			{ columnName: "account", width: 100 },
			{ columnName: "setupDate", width: 130 },
			{ columnName: "payorSource", width: 140 },
			{ columnName: "insurance", width: 140 },
			{ columnName: "category", width: 150 },
			{ columnName: "product", width: 150 },
			{ columnName: "totalPoints", width: 130 },
			{ columnName: "lastNoteDate", width: 130 },
			{ columnName: "totalDays", width: 130 },
			{ columnName: "lastStatusChange", width: 180 },
			{ columnName: "salesLocation", width: 140 },
			{ columnName: "serviceLocation", width: 150 },
			{ columnName: "atp", width: 140 },
            { columnName: "technician", width: 140 },
			{ columnName: "deliverByDate", width: 140 },
			{ columnName: "expectedShipDate", width: 140},
			{ columnName: "scheduledDate", width: 140 },
			{ columnName: "campaignName", width: 140 },
			{ columnName: "sameOrSimilar", width: 140 },
			{ columnName: "pediatric", width: 100 },
			{ columnName: 'region', width: 100 },
		];

        let defaultHiddenColumnNames = [];

        this.state = {
            data: data,
            isLoaded: false,
            filtersOpen: true,
            modalOpen: false,
            isOpen: true,
            preFilters: preFilters,
            shouldOverride: shouldOverride,
            grouping: [],
            groupingColumns: [],
            defaultColumnWidths: defaultColumnWidths,
            defaultHiddenColumnNames: defaultHiddenColumnNames,
            tooltipContent: [],
        };

        this.changeGrouping = (grouping) => {
            let ary = [],
                gpAry = [];
            grouping.forEach((group) => {
                ary.push({columnName: group.columnName, type: "count"});
                gpAry.push(group.columnName);
            });
            this.setState({
                grouping: grouping,
                groupingColumns: gpAry,
            });
        };

        this.changeHiddenColumns = (columns) => {
            this.setState({
                defaultHiddenColumnNames: columns,
            });
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };

	componentDidMount() {
		const { currentUser } = this.context;
		if (currentUser.userpreference) {
			this.renderOriginalGrouping(currentUser.userpreference);
			this.renderOriginalVisibility(currentUser.userpreference);
		}
	}

    renderOriginalGrouping(filters) {
        if (filters.userpreferenceGroupColumns && filters.userpreferenceGroupColumns.length > 0) {
			let ary = [];

            filters.userpreferenceGroupColumns.forEach((col) => {
				ary.push({ columnName: col.groupColumns });
			});

            this.setState({
                grouping: ary,
            });
        }
    }

    renderOriginalVisibility(filters) {
        if (filters.userpreferenceDefaultHiddenColumns) {
			this.setState({
                defaultHiddenColumnNames: filters.userpreferenceDefaultHiddenColumns.map(x => { return x === null ? null : x.defaultHiddenColumns; }),
			});
		}
	}

    downloadFunction() {
        this.clientCSV(this.state.data, "Orders.csv");
    }

    //This will convert all the data in the grid to a csv file
    clientCSV(stateData, filename) {
        let result,
            ctr,
            keys = [],
            headers = [],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data;

        data = stateData.rows || null;
        if (data == null || !data.length) {
            return null;
        }

        stateData.columns.forEach((col) => {
            keys.push(col.name);
            headers.push(col.title);
        });

		let replace = [
			"insurance",
			"category",
			"product",
			"patientName",
			"account",
			"lastStatusChange",
		];

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (replace.indexOf(key) > -1 && item[key] != null) {
                    result += item[key].replace(/,/g, " ");
                } else {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //this actually prompts the download
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

	dateAge(d) {
		if (d == null) {
			return 0;
		}

        let a = new Date().getTime(),
            oneDay = 1000 * 60 * 60 * 24,
            c = a - d,
            v = Math.round(c / oneDay);

        return v;
    }

    newSearch(filters) {
        this.setState({
            isLoaded: false,
        });

        return OrderService.getAllOrdersNative(filters)
            .then((res) => {
                this.buildNewResults(res);
            })
            .catch((err) => {
                this.setState({isLoaded: true});
            });
    }

    searchById(orderId) {
        this.setState({
            isLoaded: false,
        });

        let filters = {orderId: orderId};

        return OrderService.getOrdersByIdNative(filters)
            .then((res) => {
                this.buildNewResults(res);
            })
            .catch((err) => {
                this.setState({isLoaded: true});
            });
    }

    calculatePoints(order) {
        if (order.products && order.products.length > 0) {
            let points = 0;
            order.products.forEach((p) => {
                points = points + p.points;
            });

            return Number(points.toFixed(1));
        }
        return 0;
    }

    renderPriorityIcon(order) {
        if (order.priority) {
            return <Fa icon={"arrow-circle-up"}/>;
        }
        return "";
    }

    renderResponsibilityOption(order) {
		if (order.patientResponsibility > 0) {
			return (
				<i
					className="fa fa-money"
					style={{ color: "#4ba64a", fontSize: "20px" }}
                />
			);
		}
		return "";
	}

	buildNewResults(res) {
		let ary = [],
			today = new Date(),
			oldDate = new Date(today.getFullYear()-18, today.getMonth(), today.getDate()),
			dt = this.state.data;

        res.forEach((order) => {
            try {
                ary.push({
                    id: order.id,
                    priority: this.renderPriorityIcon(order),
                    patientResponsibility: this.renderResponsibilityOption(order),
                    orderType: order.orderType,
                    status: order.status,
                    reason: order.reason,
                    salesRep: order.salesRep,
                    patientName: order.patientName,
                    account: order.accountName,
                    setupDate: order.setupDate,
                    payorSource: order.payorSource,
                    insurance: order.insurance,
                    category: order.category,
                    product: order.product,
                    totalPoints: order.totalPoints,
                    totalDays: order.totalDays,
                    lastNoteDate: order.lastNoteDate,
                    lastStatusChange: GlobalFunctions.formatDateTime(order.lastStatusChange),
                    lastUpdatedOn: order.lastUpdatedOn,
                    salesLocation: order.salesLocation,
                    serviceLocation: order.serviceLocation,
                    atp: order.atp,
                    technician: order.technician,
                    deliverByDate: order.deliverByDate,
                    expectedShipDate: order.expectedShipDate,
                    scheduledDate: order.scheduledDate,
                    campaignName: order.campaignName || "",
                    sameOrSimilar: order.sameOrSimilar,
                    pediatric: oldDate <= new Date(order.dateOfBirth) ? "Yes" : "No",
                    region: order.region || "",
                    patientId: order.patientId,
                });
            } catch (err) {
                console.log(err);
            }
		});

        dt.rows = ary;

        this.setState({
            data: dt,
            isLoaded: true,
        });
    }

    toggleCollapse() {
        this.setState((state) => ({
            isOpen: !state.isOpen,
            isLoaded: false,
        }));

        //This is necessary because otherwise the grid won't use the full width on resize
        let t = this;
        setTimeout(() => {
            t.setState({
                isLoaded: true,
            });
        }, 5);
    }

    formatToolTip(result) {
        if (result == null) {
            return;
        }
        result = result.sort((a,b) => {
            let da = new Date(a.createdAt).getTime(),
                db = new Date(b.createdAt).getTime();
            return da > db ? -1: 1
        });
        let strResult = "",
			count = result.length >= 2 ? 2 : result.length;
		if (result !== null && count > 0) {
			for (let i = 0; i < count; i++) {
				strResult +=
					"\nUser: " +
					result[i].createdBy.firstname + " " + result[i].createdBy.lastname +
					"\n" +
					"Date: " +
					GlobalFunctions.formatDate(result[i].createdAt) +
					"\n" +
					"Note: " +
					result[i].text;

                if (i < count - 1) {
                    strResult += "\n----------------------";
                }
            }
        }
        return strResult;
    }

    getTooltipContent(id) {
        NoteService.getNotes(id)
            .then((res) => {
                this.setState({tooltipContent: res});
                return res;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderTable() {
        const CellWithTooltip = ({value, style, ...restProps}) => {
            return (
                <Table.Cell
                    data-tip
                    data-event={"click focus"}
                    data-for={"notes"}
                    onMouseEnter={() => this.getTooltipContent(restProps.tableRow.row.id)}
                    style={{
                        cursor: "pointer",
                    }}
                >
                    {value}
                    <ReactTooltip
                        className={"note-tooltip"}
                        id={"notes"}
                        place={"left"}
                        delayHide={0}
                        globalEventOff={"click"}
                        effect={"solid"}
                        getContent={() => (
                            <p> {this.formatToolTip(this.state.tooltipContent)} </p>
                        )}
                    />
                </Table.Cell>
            );
        };

        const Cell = ({row, column, ...props}) => {
            if (column.name === "lastNoteDate" && row.lastNoteDate) {
                return <CellWithTooltip {...props} />;
            } else {
                return (
                    <Table.Cell
                        {...props}
                        onClick={(e) => {

                            if (row.orderType && (row.orderType.toLowerCase() === "service" || row.orderType.toLowerCase() === "pickup")) {
                                if (e.ctrlKey === true) {
                                    this.setState({
                                        targetPage: "/patientInformation/",
                                        targetLink: row.patientId + "/" + row.id,
                                    });
                                    setTimeout(() => {
                                        document.getElementById("orderListId").click();
                                    }, 5);
                                    return
                                } else {
                                    this.props.history.push({
                                        pathname: "/patientInformation/" + row.patientId,
                                        state: { shouldOpenOrderId: row.id }
                                    })
                                    return;
                                } 
                            }

                            if (e.ctrlKey === true) {
                                this.setState({
                                    targetPage: "/order/",
                                    targetLink: row.id,
                                });

								setTimeout(() => {
									document.getElementById("orderListId").click();
								}, 5);
							} else {
								this.props.history.push({
									pathname: "/order/" + row.id,
								});
							}
						}}
						style={{
							cursor: "pointer",
						}}
					/>
				);
			}
		};

		if (this.state.isLoaded === true) {
			return (
				<Grid
					style={{ maxWidth: "1800px !important" }}
					rows={this.state.data.rows}
					rootComponent={Root}
					columns={this.state.data.columns}
				>
					<SortingState
						defaultSorting={[
							{ columnName: "lastStatusChange", direction: "desc" },
						]}
					/>
					<IntegratedSorting
						columnExtensions={this.state.data.integratedSortingColumnExtensions}
					/>
					<GroupingState
						grouping={this.state.grouping}
						onGroupingChange={this.changeGrouping}
					/>
					<IntegratedGrouping />
					<PagingState defaultCurrentPage={0} pageSize={25} />
					<IntegratedPaging />
					<DragDropProvider />
					<VirtualTable
						cellComponent={Cell}
						//rowComponent={TableRow}
						//cellComponent={Cell}
						height={"auto"}
					/>
					<TableColumnResizing
						defaultColumnWidths={this.state.defaultColumnWidths}
					/>
					<TableColumnVisibility
						onHiddenColumnNamesChange={this.changeHiddenColumns}
						defaultHiddenColumnNames={this.state.defaultHiddenColumnNames}
					/>
					<TableHeaderRow showSortingControls />
					<PagingPanel />
					<TableGroupRow contentComponent={Content} showColumnsWhenGrouped />
					<ItemCounter />
					<Toolbar />
					<ColumnChooser />
					<GroupingPanel />
				</Grid>
			);
		} else {
			return <div />;
		}
	}

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

	renderFilterCollapse() {
		let { currentUser } = this.context,
			filters = [];

		if (
			currentUser &&
			currentUser.userpreference &&
			!this.state.shouldOverride
		) {
			filters = currentUser.userpreference;
		} else {
			filters = this.state.preFilters;
		}

		return (
			<Collapse
				delay={{ show: 100, hide: 0 }}
				id="accordion1"
				isOpen={this.state.isOpen}
			>
				<UserFilters
					userFilters={filters}
					groupingColumns={this.state.groupingColumns}
					defaultHiddenColumns={this.state.defaultHiddenColumnNames}
					downloadFunction={this.downloadFunction.bind(this)}
					newSearchFunction={this.newSearch.bind(this)}
					orderSearchFunction={this.searchById.bind(this)}
					shouldOverride={this.state.shouldOverride}
				/>
			</Collapse>
		);
	}

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }

        return (
            <div
                className={"purchasingGrid ordersListGrid"}
                style={{height: 0.9 * window.innerHeight, backgroundColor: "white"}}
            >
                {this.renderTable()}
            </div>
        );
    }

    render() {
        let colSize = "0",
            gridSize = "12",
            displayStyle = "none";
        if (this.state.isOpen) {
            colSize = "3";
            gridSize = "9";
            displayStyle = "block";
        }

        return (
            <div>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                    position={"top-right"}
                    style={{marginTop: "75px"}}
                />

                <div style={{position: "fixed", top: "5%", left: 0, zIndex: "50000"}}>
                    <Button
                        floating
                        size="sm"
                        color={"orange"}
                        data-tip={"Hide/Show Filters"}
                        onClick={() => this.toggleCollapse()}
                    >
                        <MDBIcon icon="filter" style={{fontSize: "2em"}}/>
                    </Button>
                    <ReactTooltip/>
                </div>

                <Link
                    id="orderListId"
                    to={`${this.state.targetPage}${this.state.targetLink}`}
                    target="_blank"
                    rel="opener"
                    style={{display: "none"}}
                    activeclassname="active"
                />

                <div
                    style={{maxWidth: "98%", paddingLeft: "1rem", paddingRight: "1rem"}}
                >
                    <Row>
                        <Col
                            size={colSize}
                            style={{display: displayStyle, paddingTop: 50}}
                        >
                            {this.renderFilterCollapse()}
                        </Col>

                        <Col size={ gridSize } style={ { marginTop: '1rem' } }>{ this.renderTableOrSpinner() }</Col>
                    </Row>
                    <div
                        style={{position: "fixed", bottom: 15, right: 15}}
                        className={"downloadCsvDiv"}
                    >
                        <Button
                            className={"downloadCsvButton"}
                            floating
                            size="sm"
                            color={"primary"}
                            data-tip={"Download CSV"}
                            onClick={this.clientCSV.bind(this, this.state.data, "Orders.csv")}
                        >
                            <MDBIcon icon="download" style={{fontSize: "2em"}}/>
                        </Button>

                        <ReactTooltip/>
                    </div>
                </div>
            </div>
        );
    }
}
