import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import ProductSubType from "../../Utility/Crud/ProductSubType";
import ProductType from "../../Utility/Crud/ProductType";
import PropTypes from "prop-types";

export default class TileProductSubType extends Tile {
    static contextTypes = {
        productSubTypesIndexed: PropTypes.object,
        productTypesIndexed: PropTypes.object,
    };

    /**
      * @returns {string} The ProductSubType name.
      */
    getTitle() {
        return this.props.productSubType ? ProductSubType.getDisplayName(this.props.productSubType) : "None";
    }

    /**
     * @returns {string} The ProductType name.
     */
    getSubtitle() {
        return this.props.productSubType ? ProductType.getDisplayName(this.context.productTypesIndexed[this.props.productSubType.productTypeId]) : "";
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.productSubType ? ProductSubType.getDisplayName(this.props.productSubType) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.productSubType ? ProductSubType.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return ProductSubType.getIcon();
    }
}
