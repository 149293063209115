import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";

export default class CardAdminQueueRouteSimilar extends Card {
    static contextTypes = {
        queueRoutesIndexed: PropTypes.object
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        /**
         * TODO: There is a small bug here where right when you create a new
         * route, it shows up briefly on this card after creation because it now
         * exists in context, but it appears different because that row has an
         * ID and this one doesn't.
         */
        return (
            <Table
                search={false}
                columns={[
                    { key: "orderActivityTypeId", name: "Order Activity Type", type: "orderActivityType", width: "180" },
                    { key: "fromOrderStatusReasonId", name: "From Order Status Reason", type: "orderStatusReason" },
                    { key: "toOrderStatusReasonId", name: "To Order Status Reason", type: "orderStatusReason" },
                    { key: "name", name: "Name", type: "string" },
                ]}
                sort={[
                    {
                        key: "toOrderStatusReasonId",
                        direction: "ascending",
                        type: "orderStatusReason",
                    },
                    {
                        key: "name",
                        direction: "ascending",
                    },
                ]}
                rows={Object.values(this.context.queueRoutesIndexed).filter((queueRoute) => {
                    return (
                        this.props.queueRoute &&
                        queueRoute.id !== this.props.queueRoute.id &&
                        queueRoute.orderActivityTypeId === this.props.queueRoute.orderActivityTypeId &&
                        queueRoute.fromOrderStatusReasonId === this.props.queueRoute.fromOrderStatusReasonId
                    );
                })}
            />
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Similar Queue Routes";
    }
}