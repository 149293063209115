import React from "react";
import Card from "../../../Card";
import PropTypes from "prop-types";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";

export default class CardAdminBtProductGeneral extends Card {
    static contextTypes = {
        btProductTypesIndexed: PropTypes.object,
        vendorsIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        return (
            <>
                <MUIGrid container spacing={2}>
                    <MUIGrid item xs={12} sm={6} md={4}>
                        <MUITextField
                            value={this.props.btProduct ? this.props.btProduct.hcpcs : ""}
                            autoFocus={true}
                            size="small"
                            label="HCPCS"
                            variant="standard"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 30,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onBlur={(e) => this.props.onChange({ hcpcs: e.target.value.trim() })}
                            onChange={(e) => this.props.onChange({ hcpcs: e.target.value })}
                            required={true}
                            error={this.props.validationIssues.hcpcs ? true : false}
                            helperText={this.props.validationIssues.hcpcs ? this.props.validationIssues.hcpcs : ""}
                        />
                    </MUIGrid>

                    <MUIGrid item xs={12} sm={6} md={4}>
                        <MUITextField
                            value={this.props.btProduct ? this.props.btProduct.itemId : ""}
                            autoFocus={true}
                            size="small"
                            label="Item ID"
                            variant="standard"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 30,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onBlur={(e) => this.props.onChange({ itemId: e.target.value.trim() })}
                            onChange={(e) => this.props.onChange({ itemId: e.target.value })}
                            required={true}
                            error={this.props.validationIssues.itemId ? true : false}
                            helperText={this.props.validationIssues.itemId ? this.props.validationIssues.itemId : ""}
                        />
                    </MUIGrid>

                    <MUIGrid item xs={12} sm={6} md={4}>
                        <MUITextField
                            value={this.props.btProduct ? this.props.btProduct.name : ""}
                            autoFocus={true}
                            size="small"
                            label="Name"
                            variant="standard"
                            fullWidth={true}
                            inputProps={{
                                maxLength: 30,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onBlur={(e) => this.props.onChange({ name: e.target.value.trim() })}
                            onChange={(e) => this.props.onChange({ name: e.target.value })}
                            required={true}
                            error={this.props.validationIssues.name ? true : false}
                            helperText={this.props.validationIssues.name ? this.props.validationIssues.name : ""}
                        />
                    </MUIGrid>
                </MUIGrid>
            </>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}