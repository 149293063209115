import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import BaseUnitType from "../../Utility/Crud/BaseUnitType";

export default class TileBaseUnitType extends Tile {
    /**
      * @returns {string} The BaseUnitType name.
      */
    getTitle() {
        return this.props.baseUnitType ? BaseUnitType.getDisplayName(this.props.baseUnitType) : "None";
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.baseUnitType ? BaseUnitType.getDisplayName(this.props.baseUnitType) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.baseUnitType ? BaseUnitType.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return BaseUnitType.getIcon();
    }
}
