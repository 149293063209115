import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import {
    Button,
    CardHeader,
    Card,
    CardBody,
    Col,
    Container,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Fa,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    Spinner,
    TabContent,
    TabPane,
    ToastContainer,
    toast,
    Timeline,
    TimelineStep,
    MDBModal,
    MDBContainer,
    MDBSelect,
    MDBCol,
    MDBRow,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBIcon,
    MDBBtn,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBModalHeader,
    MDBListGroupItem,
    MDBSpinner, MDBTabContent, MDBTabPane, MDBBtnGroup, MDBNavItem, MDBNavLink, MDBNav, MDBCollapse,
} from "mdbreact";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Select from "react-select";
import NotesTab from "./PatientViewComponents/notesTab";
import AttachmentsTab from "./PatientViewComponents/attachmentsTab";
import NoteService from "../Security/NoteService/noteService";
import moment from "moment";
import OrderService from "../Security/OrderService/orderService";
import "./patientView.css";
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableRowDetail, TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import {
    PagingState,
    IntegratedPaging,
    RowDetailState,
} from "@devexpress/dx-react-grid";
import ComplaintService from "../Security/ComplaintService/ComplaintService";
import PatientOrders from "../Security/PatientService/patientOrdersService";
import PatientNoteService from "../Security/PatientNoteService/PatientNoteService";
import ReactTooltip from "react-tooltip";
import googleService from "../Security/GoogleService/GoogleService";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ServiceOrderReasonService from "../Security/ServiceOrderReasonService/ServiceOrderReasonService";
import patientAppService from "../Security/PatientService/PatientAppService";
import patientPhoneService from "../Security/PatientService/PatientPhoneService";
import GlobalFunctions from "../Filters/GlobalFunctions";
import PageHeader from "../../Components/Custom/pageHeader";
import CreateQuoteModalFromTicket from "./PatientViewComponents/CreateQuoteModalFromTicket";
import ApprovalTab from "./PatientViewComponents/approvalTab";
import insuranceService from "../Security/InsuranceService/insuranceService";
import partsService from "../Security/ProductService/partsService";
import PverifyModal from "./PatientViewComponents/pverifyModal";
import globalFunctions from "../Filters/GlobalFunctions";
import DialogAddNote from "../../Component/Dialog/DialogAddNote";
import DialogSearchOrderStatusReason from "../../Component/Dialog/Search/DialogSearchOrderStatusReason";

//SP Component Imports
import EmptyState from "../../Component/EmptyState";

//MUI Imports
import MUIButton from '@mui/material/Button';
import MUIButtonGroup from "@mui/material/ButtonGroup"
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUIDialog from '@mui/material/Dialog';
import MUIDialogActions from '@mui/material/DialogActions';
import MUIDialogContent from '@mui/material/DialogContent';
import MUIDialogContentText from '@mui/material/DialogContentText';
import MUIDialogTitle from '@mui/material/DialogTitle';
import MUIAlert from '@mui/material/Alert'
import MUIAlertTitle from "@mui/material/AlertTitle"
import MUIChip from "@mui/material/Chip"
import MUITextField from "@mui/material/TextField"
import MUIMenuItem from "@mui/material/MenuItem"
import MUIGrid from "@mui/material/Grid";
import MUIRadio from '@mui/material/Radio';
import MUICheckbox from "@mui/material/Checkbox"
import MUIFormControl from "@mui/material/FormControl"
import MUIFormLabel from "@mui/material/FormLabel"
import MUIFormControlLabel from "@mui/material/FormControlLabel"
import MUIBox from '@mui/material/Box';
import MUIContainer from "@mui/material/Container"
import MUITypography from '@mui/material/Typography'
import MUIBlueColor from "@mui/material/colors/blue"
import MUILightBlueColor from "@mui/material/colors/lightBlue"
import MUIRedColor from "@mui/material/colors/red";
import MUIArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BtProductService from "../Security/BtProductService/btProductService";



//const apiKey = 'eDF3LRILoAxo10s3TpIsSQcv034AyeVRrBYr8P4G8iM~'; // thinkgeo
const apiKey = "AIzaSyAM9_trzKLE1Y2J7eWPSjl3i5jrtgBHe9s"; // google


export default class PatientView extends React.Component {
    constructor(props) {
        super(props);
        let shouldOpenOrderId = null;
        let shouldNot = localStorage.getItem("shouldNotOpenInfo");
        // patientId: this.state.id, shouldOpenOrderId: id
        // For refreshing and navigating with the back arrow
        let localShouldOpen = JSON.parse(localStorage.getItem("shouldOpenInfo"));
        if (props.history && props.history.action === "POP") {
            if (props.match.params.orderId && !shouldNot) {
                shouldOpenOrderId = parseInt(props.match.params.orderId);
            }
            if (localShouldOpen && props.match.params.id === localShouldOpen.patientId && localShouldOpen.shouldOpenOrderId) {
                shouldOpenOrderId = localShouldOpen.shouldOpenOrderId;
            } else {
                localStorage.removeItem("shouldOpenInfo")
            }
        } else {
            localStorage.removeItem("shouldOpenInfo")
        }
        //For being directed to a specific ticket from a different page
        if (!shouldOpenOrderId) {
            if (
                props.location &&
                props.location.state &&
                props.location.state.shouldOpenOrderId && !(
                    shouldNot &&
                    shouldNot.shouldOpenOrderId &&
                    shouldNot.shouldOpenOrderId === props.location.state.shouldOpenOrderId
                )
            ) {
                shouldOpenOrderId = props.location.state.shouldOpenOrderId;
            }


            if (
                (shouldNot && !props.location.state) ||
                (shouldNot && !props.location.state.shouldOpenOrderId) ||
                (
                    shouldNot &&
                    shouldNot.shouldOpenOrderId &&
                    shouldNot.shouldOpenOrderId !== props.location.state.shouldOpenOrderId
                )
            ) {
                localStorage.removeItem("shouldNotOpenOrderId")
            }
        }

        let geoCodeClient = {};

        if (window.tg) {
            try {
                geoCodeClient = new window.tg.GeocodingClient(apiKey);
            } catch (e) {
                console.log(e);
            }
        }

        const groupColumns = [{ columnName: "status" }];

        const tOptions = [
            {
                text:
                    "Setup Issue – patient was not setup correctly on initial delivery",
                value:
                    "Setup Issue – patient was not setup correctly on initial delivery",
            },
            {
                text: "Misdiagnosed – last part recommendation was incorrect",
                value: "Misdiagnosed – last part recommendation was incorrect",
            },
            {
                text:
                    "Manufacturer Error – vendor sent wrong parts or suggested wrong parts",
                value:
                    "Manufacturer Error – vendor sent wrong parts or suggested wrong parts",
            },
            {
                text: "Equipment Fit – ATP recommendations were not correct initially",
                value: "Equipment Fit – ATP recommendations were not correct initially",
            },
            {
                text:
                    "Equipment Abuse – patient is using equipment incorrectly or abusing equipment",
                value:
                    "Equipment Abuse – patient is using equipment incorrectly or abusing equipment",
            },
            {
                text:
                    "Quality Issue – equipment is a lemon or has repeat issues on same parts",
                value:
                    "Quality Issue – equipment is a lemon or has repeat issues on same parts",
            },
        ];


        const data = {
            columns: [
                {
                    title: " ",
                    name: "blank",
                },
                {
                    title: "Order",
                    name: "orderIdLink",
                },
                {
                    title: "Status",
                    name: "statusChip",
                },
                {
                    title: "Equipment",
                    name: "category",
                },
                {
                    title: "Last Note",
                    name: "lastNoteDate",
                },
                {
                    title: "Points",
                    name: "points",
                },
                // {
                //     title: "Serial",
                //     name: "serialNumber",
                // },
                // {
                //     title: "Setup Date",
                //     name: "setupDate",
                // },
                {
                    title: " ",
                    name: "feedback",
                },

            ],
            rows: [],
            defaultColumnWidths: [
                { columnName: "blank", width: 60 },
                { columnName: "orderIdLink", width: 70 },
                { columnName: "category", width: 150 },
                { columnName: "status", width: 150 },
                { columnName: "points", width: 60 },
                // { columnName: "serialNumber", width: 140 },
                // { columnName: "setupDate", width: 100 },
                { columnName: "lastNoteDate", width: 100 },
                { columnName: "feedback", width: 50 },

            ],
        };
        const sideCardData = {
            columns: [
                {
                    title: "Order",
                    name: "orderId",
                },
                {
                    title: "Date",
                    name: "date",
                },
                {
                    title: "Status",
                    name: "status",
                },
            ],
            rows: [],
            defaultColumnWidths: [
                { columnName: "orderId", width: 80 },
                { columnName: "date", width: 110 },
                { columnName: "status", width: 125 },
            ],
        };

        const phoneData = {
            columns: [
                {
                    title: "Patient ID",
                    name: "patientId",
                },
                {
                    title: "ID",
                    name: "id",
                },
                {
                    title: "Contact Name",
                    name: "contactName",
                },
                {
                    title: "Phone Number",
                    name: "phoneNumber",
                },
                {
                    title: "Phone Type",
                    name: "phoneType",
                },
                {
                    title: "Delete",
                    name: "delete",
                },
            ],
            defaultHiddenColumnNames: ["patientId"],
            rows: [],
        };
        //get all results to save
        let results = props.location.results;

        let id = props.match.params.id;

        this.state = {
            allServiceParts: null,
            firstLoad:true,
            id: id,
            notes: [],
            orderNotes: [],
            orderNotesLoading: false,
            orderNotesLoaded: false,
            orderApprovalsLoading: false,
            orderApprovalsLoaded: false,
            orderAttachments: [],
            orderAttachmentsLoading: false,
            orderAttachmentsLoaded: false,
            tasks: [],
            accountOwner: {},
            accountRecentSalesRep: null,
            patient: {},
            ePatient: {},
            isLoaded: false,
            patientOrders: [],
            selectedPreviousOrder: {},
            serviceOrderReasons: tOptions,
            orderModalOpen: false,
            editLocationInformationModal: false,
            editGeneralInformationModal: false,
            createComplaintModal: false,
            createPatientNoteModal: false,
            gridData: data,
            sideCardGridData: sideCardData,
            groupColumns: groupColumns,
            patientProducts: [],
            patientProductsLoaded: false,
            patientProductsLoading: false,
            productsAddedList: [],
            allOrdersNotSorted: [],
            nOrder: {
                type: "Order",
                quality: false,
                hasInfectiousDisease: false,
                contacts: [],
                priority: 0,
                startedAt: new Date(),
                lastStatusChange: new Date(),
                account: {},
                approvals: [{}],
                insuranceType: null,
                secondaryInsuranceType: null,
                products: [],
                orderType: "New",
                status: 0,
            },
            nComplaint: {
                issueCategory: {},
                issueReason: {},
            },
            complaints: [],
            orderActivityFull: {},
            complaintNotes: {},
            currentComplaint: {},
            complaintNoteModal: false,
            complaintNoteText: "",
            closeComplaintModal: false,
            cancelTicketModal: false,
            addAdditionalPartsModal: false,
            selectedOrderTypeRadio: 0,
            serviceLocation: null,
            salesLocation: null,
            patientSalesLocation: null,
            orderTypes: [
                { id: 0, name: "New" },
                { id: 1, name: "Pickup" },
                { id: 2, name: "Service" },
                { id: 3, name: "Exchange" },
                { id: 4, name: "Internal Request" },
                { id: 5, name: "Re-Supply" },
            ],
            statuses: [
                "New",
                "In Progress",
                "Ready to Deliver",
                "Setup",
                "Cancelled",
            ],

            orderStatuses: [
                { label: "New", value: 0 },
                { label: "In Process", value: 1 },
                { label: "Ready To Deliver", value: 2 },
                { label: "Set Up", value: 3 },
                { label: "Cancelled", value: 4 },
            ],

            noteText: "",
            results: results,
            geoClient: geoCodeClient,
            searchResults: [],
            currOpenNote: {},
            ptnNoteModal: false,

            appApproval: null,
            isChangingPassword: false,
            newPassword: null,

            preferredContactMethods: [
                { value: "SMS", label: "SMS" },
                { value: "Call", label: "Call" },
                { value: "Email", label: "Email" },
            ],
            phoneTypes: [
                { value: "Cell", label: "Cell" },
                { value: "Home", label: "Home" },
                { value: "EmergencyContact", label: "Emergency Contact" },
                { value: "Other", label: "Other" },
            ],
            newPhoneNumber: {
                patientId: null,
                contactName: null,
                phoneType: null,
                phoneNumber: null,
            },
            patientPhoneNumbers: [], // list of new or modified
            deletedNumbers: [],
            addPhoneModalOpen: false,
            phoneData: phoneData,

            allUsers: [],
            allLocations: [],
            allSalesReps: [],
            insuranceTypes: [],
            insuranceSubTypes: [],
            complaintCategories: [],
            complaintReasons: [],
            isPediatric: false,

            patientEditTemplate: {
                id: id,
                type: "patient",
                item: {}
            },
            disableCreate: false,
            popUpComplaints: false,
            isCompliment: false,
            options: [],
            filteredOpts: [],
            complimentedUser: null,
            csUsers: [],
            filteredCSUsers: [],
            searchQ: "",
            searchText: "",
            collapseID: "collapse0",
            createServiceTicketModalOpen: false,
            isOrderDetails: false,
            ticketDetailsModalOpen: false,
            radio: {
                pickupTicketAction: { value: "", label: "" },
                serviceTicketAction: "",
                choosePartsAction: "",
                needsDiagnosisAction: "",
                obtainDocAction: "",
                nonBillableAction: "",
                docFollowupAction: "",
                approvalAction: "",
                needsBilledAction: "",
                preAuthFollowUpAction: "",
                preAuthSubmission: "",
                followUpTask: "",
                billingAction: "",
                salesPPWIssue: "",
                setup: "",
                dispatchTechSubType: "",
                newAccessoryAfterSaleSubType: "",
                preAuthAction: "",
                readyToDeliverAction: "",
                rtdEquipmentOrderedAction: "",
                readyToSchedule: "",
                rtdScheduled: "",
                equipmentWriteOff: "",
                rtdNotAbleToDeliverAction: "",
                verification: "",
            },
            createTicketNote: null,
            approvalActive: true,
            notesActive: false,
            attachemntsActive: false,
            canEditBasicInfo: false,
            canEditInsuranceInfo: false,
            curOrderId: null,
            patientAge: null,
            expandedRowID: 2,
            items: {
                rightMenu: "1",
                ticketDetails: "1",
                contentCard: "1"
            },
            patientEquipmentTickets: [],
            createTicketOrderId: null,
            insuranceInfoValidated: false,
            basicInfoValidated: false,
            radioBtnSelected: false,
            highlightedRow: { id: "" },
            expandedRowIds: [],
            insuranceVerification: {
                loading: true,
                insuranceVerified: null,
                verificationInfo: null,
                start: "",
                end: "",
                inDateRange: false
            },
            quoteItems: {},
            shipToPatient: false,
            patientEquipmentLoaded: false,
            noteModalOpen: false,
            saveProcessStarted: false,

            shouldDisable: false,
            shouldOpenOrderId: shouldOpenOrderId,
            statusOrReasonChange: false,

            lastChartNotes: null,
            chartNotesOpen: false,
            serviceOrderProducts: [],
            orderRetrieved: false,
            servicePartsLoading:false,
            servicePartsLoaded: false,
            orderDetailProducts: [],
            idNames: [],

            attachmentsTabOpen: false,
            feedbackCollapseID: "",
            sortedFeedback: [],
            openOnFeedback: false,
            addPartCreateQuoteModal: false,

            addFeedbackFromActions: false,

            hasScrolledToTop: false,

            approvalModalOpen: false,
            approveAgreement: false,
            approveWithoutAuth: false,
            approveAuthNotRequired: false,

            orderProducts: [],
            authNumber: null,

            followupModalOpen: false,
            followUpDays: 5,

            deliveryNoteModalOpen: false,
            deliveryNoteText: null,

            searchOrderStatusReasonDialogOpen: false,

            verificationModalOpen: false,
            verificationResults: [],

            patientInfoRetrieved: false,

            serviceReason: null,
            curServiceOrderReason: {},
            showSubtypeRadioOptions: false,

            thirtyDayFit: false,
            serviceReasonLoaded: false,

            createQuoteSmall: false,

            ticketRecordsLoaded: false,
            ticketRecords: [],
            reopenCancelledTicketModal: false,

            paymentInformationModal: false,
            abnModal: false,

            timelineModalOpen: false,
            parentFlat: {},
            typeOfTicketToCreate: "",
            productsForOrderId: null,

            setDialogOpen: false,

            curOrderIsApproved: false,
            baseUnitLoaded: false,

            submitToPverifyLoading: false,
            initialRoutingInformation: null,
            selectedSubTypeFromQueueRoute: null,

        };
        this.unloadedState=JSON.parse(JSON.stringify(this.state));

        this.state.TabsOneActiveItem = "1";


        this.retrievePatient(id);
        this.retrievePatientInsurance(id);
        this.getPatientNotes(id);
        this.retrieveVerificationResults(id);
    }
    componentWillReceiveProps(nextProps) {
        let id = nextProps.match.params.id;

        if (id !== this.state.id) {


            this.unloadedState.id = id;
            this.unloadedState.shouldOpenOrderId = nextProps.location.state.shouldOpenOrderId;
            this.shouldOpenOrderId = nextProps.location.state.shouldOpenOrderId

            this.setState(this.unloadedState);
            this.retrievePatient(id);
            this.retrievePatientInsurance(id);
            this.getPatientNotes(id);
            this.retrieveVerificationResults(id);
            this.componentDidMount();
        }
        else if(nextProps.location.state && nextProps.location.state.shouldOpenOrderId){
            this.highlightOrderRow(nextProps.location.state.shouldOpenOrderId)
        }
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        companyName: PropTypes.string,
        salesReps: PropTypes.array,
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        internalLocations: PropTypes.array,
        complaintCategories: PropTypes.array,
        complaintReasons: PropTypes.array,
        productTypes: PropTypes.array,
        productSubTypes: PropTypes.array,
        productOverrides: PropTypes.array,
        stateSalesTax: PropTypes.array,
        allUsers: PropTypes.array,
        reasons: PropTypes.array,
        allVendors: PropTypes.array,
        allBaseUnits: PropTypes.array,
        queueRoutesIndexed: PropTypes.object,
        orderActivityTypesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
    };

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.getLocations();
        this.getReps();
        this.getInsuranceTypes();
        this.getInsuranceSubTypes();
        this.getComplaintCategories();
        this.getComplaintReasons();
        // this.getPatientProducts();
        this.getAllUsers();
    }

    calcMinWidth(screenWidth, colMin, percent,constantVals){

        //table gets 53% of VW controlled in patientView.css .leftCol .middleCol and .rightCol
        //Subtracting 48 to account for the dropdown caret Col
        let total = 48;
        constantVals.forEach((val) => {
            total += val.width
        });
        let width = (screenWidth*.53)-total;
        let colWidth = width * (percent/100);

        return colWidth

    }

    getWidths() {
        let width = this.state.width || window.innerWidth;

        let constantVals = [
            { columnName: "blank", width:  90 }, //90 from 60
            { columnName: "orderIdLink", width:  75 },
            { columnName: "feedback", width:  50 },
            { columnName: "points", width:  60 },
        ];
        let calculateVals = [
            { columnName: "statusChip", width: this.calcMinWidth(width, 150, 34, constantVals) }, //35
            { columnName: "category", width: this.calcMinWidth(width,150, 50,constantVals)  }, //35
            // { columnName: "serialNumber", width:  this.calcMinWidth(width,70, 15,constantVals) },
            { columnName: "lastNoteDate", width: this.calcMinWidth(width, 70, 16, constantVals) },
        ];
        let vals = constantVals.concat(calculateVals);
        return vals;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { allUsers } = this.context;
        if (
            allUsers.length !== this.state.allUsers.length
        ) {
            this.getAllUsers();
        }
    }

    getLocations() {
        const { internalLocations } = this.context;
        this.setState({
            allLocations: internalLocations
        });
    }

    getReps() {
        const { salesReps } = this.context;
        this.setState({
            allSalesReps: salesReps,
        });
    }

    getStateList() {
        const { stateSalesTax } = this.context;
        let lst = [];
        stateSalesTax.forEach(x => {
            lst.push({
                label: x.name + " - " + x.abbr,
                value: x.abbr,
            })
        })
        return lst;
    }

    getInsuranceTypes() {
        const { insuranceTypes } = this.context;
        this.setState({
            insuranceTypes: insuranceTypes,
        });
    }

    getInsuranceSubTypes() {
        const { insuranceSubTypes } = this.context;
        this.setState({
            insuranceSubTypes: insuranceSubTypes,
        });
    }

    getComplaintCategories() {
        const { complaintCategories } = this.context;
        this.setState({
            complaintCategories: complaintCategories,
        });

    }

    getComplaintReasons() {
        const { complaintReasons } = this.context;
        this.setState({
            complaintReasons: complaintReasons,
        });
    }

    getPatientLastChartNotes(patientId) {
        OrderService.getPatientLastChartNotes(patientId)
            .then(res => {
                if (!res.id) {
                    return
                }
                this.setState({
                    lastChartNotes: res,
                })
            })
            .catch(ex => {
                console.log(ex)
            })
    }

    toggle = (tabset, tab) => {
        if (this.state.TabsOneActiveItem !== tab) {
            this.setState({
                TabsOneActiveItem: tab,
            });
        }
        if (this.state.canEditBasicInfo && (this.state.TabsOneActiveItem !== "2" || this.state.TabsOneActiveItem !== "3")) {
            this.setState({
                canEditBasicInfo: false,
            })
        }
    };

    toggleOrderModal() {
        let p = this.state.patient;

        if (!p.address || !p.city || !p.state || !p.zipCode) {
            toast.error(
                "Patient must have address information set before adding an Order"
            );
        } else if (!p.insuranceType) {
            toast.error(
                "Patient must have a Payor Source set before adding an Order"
            );
        } else {
            let currentState = this.state.orderModalOpen;
            this.setState({ orderModalOpen: !currentState });
        }
    }

    toggleCreateTicketModal(order, orderId, ticketType) {
        let curState = this.state.createServiceTicketModalOpen;
        if (curState === false) {
            this.setState({
                curOrder: {},
            })
        }
        this.setState({
            parentFlat: {},
            typeOfTicketToCreate: ticketType ? ticketType : "",
            createTicketNote: null,
            createServiceTicketModalOpen: !curState,
            radio: {
                serviceTicketAction: "",
                pickupTicketAction: { label: "", value: "" }
            },
            canEditBasicInfo: false,
            canEditInsuranceInfo: false,
            createTicketOrderId: this.state.curOrderId ? null : orderId,
            basicInfoValidated: false,
            insuranceInfoValidated: false,
            radioBtnSelected: false,
            curOrderId: this.state.curOrderId ? null : orderId,
            curOrder: order,
            thirtyDayFit: false,
        })
        if (orderId) {
            this.getParentFlatDemographics(orderId)
        }
    }

    getParentFlatDemographics(orderId) {
        OrderService.getFlatOrderActivity(orderId).then((res) => {

            this.changeLocation(res.salesLocation, "salesLocation")
            this.changeLocation(res.serviceLocation, "serviceLocation")

        }).catch((err) => {

        })
    }

    toggleAddServicePartsModal(){
        this.setState({
            addPartCreateQuoteModal: !this.state.addPartCreateQuoteModal
        })
    }


    toggleTicketDetailsModal(curOrder, id) {
        let curState = this.state.ticketDetailsModalOpen;
        let items = this.state.items;
        if (this.state.openOnFeedback === false) {
               items.rightMenu = "1";
        }
        if (this.state.openOnFeedback === true) {
            items.rightMenu = "3";
        }
        this.setState()
        if (curState === false) {

            this.setState({
                highlightedRow:{ id: null },
                TabsOneActiveItem: "4",
                curOrder: {},
                curOrderId: null,
                isOrderDetails: curOrder.orderType === "New",
                statusOrReasonChange: false,
                serviceOrderProducts: [],
                orderRetrieved: false,
                servicePartsLoading:false,
                servicePartsLoaded: false,
                orderDetailProducts: [],
                canSelectChoosePartsAction: false,
                canSelectNonBillableAction: false,
                canSelectNeedsDiagnosisAction: false,
                canSelectObtainDocAction: false,
                canSelectDocFollowupAction: false,
                canSelectApprovalAction: false,
                canSelectNeedsBilledAction: false,
                canSelectPreAuthFollowUpAction: false,
                canSelectPreAuthSubmission: false,
                canSelectFollowUpTask: false,
                canSelectSalesPPWIssueAction: false,
                canSelectSetupAction: false,
                canSelectBillingAction: false,
                canSelectReadyToDeliverAction: false,
                canSelectTechNotRequired: false,
                canSelectEquipmentOrderedAction: false,
                canSelectReadyToScheduleAction: false,
                canSelectScheduledAction: false,
                canSelectWriteOffAction: false,
                canSelectNotAbleToDeliverAction: false,
                canSelectVerificationAction: false,
                feedbackCollapseID: "",
                openOnFeedback: false,
                loadingParentProducts: null,
                loadingServiceOrderReason: null,
                loadingOrderTimeline: null,
                radio: {
                    pickupTicketAction: { label: "", value: "" },
                    serviceTicketAction: "",
                    choosePartsAction: "",
                    needsDiagnosisAction: "",
                    obtainDocAction: "",
                    docFollowupAction: "",
                    approvalAction: "",
                    needsBilledAction: "",
                    preAuthFollowUpAction: "",
                    preAuthSubmission: "",
                    followUpTask: "",
                    billingAction: "",
                    salesPPWIssue: "",
                    setup: "",
                    dispatchTechSubType: "",
                    newAccessoryAfterSaleSubType: "",
                    readyToDeliverAction: "",
                    rtdEquipmentOrderedAction: "",
                    readyToSchedule: "",
                    rtdScheduled: "",
                    equipmentWriteOff: "",
                    rtdNotAbleToDeliverAction: "",
                    verification: "",
                },
                eOrder:null,
                curServiceOrderReason: {},
                currentBaseUnit: null,
                currentVendor: null,
                baseUnitSerial: null,
                baseUnitHCPCS: null,
                serviceReason: null,

                curOrderIsApproved: false,
                baseUnitLoaded: false,
                productsAddedList: [],
                productsForOrder: [],
                nOrder: {
                    type: "Order",
                    quality: false,
                    hasInfectiousDisease: false,
                    contacts: [],
                    priority: 0,
                    startedAt: new Date(),
                    lastStatusChange: new Date(),
                    account: {},
                    approvals: [{}],
                    insuranceType: null,
                    secondaryInsuranceType: null,
                    products: [],
                    orderType: "New",
                    status: 0,
                },
            })
            setTimeout(() => {
                let elem = document.getElementById("orderDetailsContainer");
                if(elem) {
                    elem.scrollIntoView();
                }else{
                    setTimeout(() => {
                        let elem2 = document.getElementById("orderDetailsContainer");
                        if(elem2) {
                            elem2.scrollIntoView();
                        }
                    }, 1);
                }
            }, 1);
        } else {
            this.setState({
                TabsOneActiveItem: "1"
            })
        }

        items.ticketDetails = "1";
        this.setState({
            ticketDetailsModalOpen: !curState,
            orderNotesLoading: false,
            orderNotesLoaded: false,
            orderNotes: [],
            orderApprovalsLoading: false,
            orderApprovalsLoaded: false,
            orderAttachmentsLoading: false,
            orderAttachmentsLoaded: false,
            orderAttachments: [],
            curOrderId: this.state.curOrderId ? null : id,
            curOrder: curOrder,
            notesActive: true,
            attachmentsActive: false,
            items: items,
            serviceReasonLoaded: false,
            ticketRecordsLoaded: false,
        })

        if (id) {
            localStorage.setItem("shouldOpenInfo", JSON.stringify({ patientId: this.state.id, shouldOpenOrderId: id }))
            localStorage.removeItem("shouldNotOpenInfo")
        } else {
            localStorage.removeItem("shouldOpenInfo")
            localStorage.setItem("shouldNotOpenInfo", true)
        }
    }

    addressTextChanged(e) {
        let val = e.target.value;
        const nPatient = this.state.ePatient,
            tplt = this.state.patientEditTemplate;
        nPatient["address"] = val;
        tplt.item["address"] = val;
        this.setState({ ePatient: nPatient, patientEditTemplate: tplt });
        let geocoderResultNode = document.getElementById("geocoderResult");
        if (val) {
            this.geoGoogle(val);
        } else {
            this.setState({ searchResults: [] });
            if (geocoderResultNode) {
                geocoderResultNode.innerHTML = "";
            }
        }
    }

    validateComplaint() {
        let comp = this.state.nComplaint;
        if (comp.issueCategory.name == null) {
            toast.warn("You must add a feedback category");
            return false;
        }

        if (comp.issueReason.name == null) {
            toast.warn("You must add a feedback reason");
            return false;
        }

        if (this.state.addFeedbackFromActions === true && comp.orderId == null) {
            toast.warn("You must select an order");
            return false;
        }

        if (this.state.isCompliment === true && comp.complimentedUserId == null) {
            toast.warn("You must add complimented employee");
            return false
        }

        if (comp.complaintDescription == null || comp.complaintDescription === "") {
            toast.warn("You must add a feedback description");
            return false;
        }

        return true;
    }

    addPatientComplaint() {
        const { currentUser } = this.context;

        let shouldContinue = this.validateComplaint();

        if (!shouldContinue) {
            return;
        }

        let p = this.state.patient,
            comp = this.state.nComplaint;
        comp.patientId = p.id;
        comp.issueLoggedBy = { firstname: currentUser.firstname, lastname: currentUser.lastname, id: currentUser.id };
        comp.issueLoggedById = currentUser.id;
        comp.issueLoggedOn = new Date().toISOString();
        comp.issueStatus = 0;
        comp.issueCategoryId = comp.issueCategory.id;
        comp.issueReasonId = comp.issueReason.id;
        if (!this.state.addFeedbackFromActions) {
            comp.orderId = this.state.curOrderId;
        }

        return ComplaintService.createPatientIssue(comp)
            .then((res) => {
                toast.success("Feedback added successfully");

                let c = this.state.complaints;
                c.push(res);

                this.setState({
                    createComplaintModal: false,
                    complaints: c,
                    nComplaint: {
                        issueCategory: {},
                        issueReason: {},
                    },
                    addFeedbackFromActions: false,
                });
            })
            .catch((e) => {
                console.log(e)
            });
    }

    calculateAutofocus = (a) => {
        if (this.state["formActivePanel" + a + "Changed"]) {
            return true;
        }
    };

    sortIssues(issues) {
        let sorted = issues.sort((a, b) => {
            let da = new Date(a.issueLoggedOn).getTime(),
                db = new Date(b.issueLoggedOn).getTime();

            return da > db ? -1 : 1
        });
        return sorted;
    }

    retrievePatient(id) {
        return OrderService.getPatientById(id)
            .then((data) => {
                data.patientIssue = this.sortIssues(data.patientIssue);

                let nOrder = this.state.nOrder;
                nOrder.account = data.account;
                let st = this.state,
                    // orders = [],
                    complaintNotes = st.complaintNotes;

                // data.orderActivity.forEach(d => {
                //     let l = d.id
                //         .toString()
                //         .concat(d.orderType ? " - " + d.orderType : " - Blank Type")
                //         .concat(d.status ? " - " + d.status : " - Blank Status");
                //     orders.push({ label: l, value: d.id });
                // });

                // this.createOrderList(data.orderActivity);

                data.patientIssue.forEach(p => {
                    complaintNotes["notes" + p.id] = p.issueNotes;
                })

                this.determineLegalComplaints(data.patientIssue);


                this.setState({
                    patientAge: moment().diff(data.dateOfBirth, 'years', false),
                    patient: data,
                    accountOwner: data.account.owner,
                    ePatient: JSON.parse(JSON.stringify(data)),
                    isLoaded: true,
                    // patientOrders: orders,
                    complaints: data.patientIssue,
                    complaintNotes: complaintNotes,
                    TabsOneActiveItem: "1",
                    canEditBasicInfo: false,
                    patientInfoRetrieved: true,
                    previousMbi: data.mbi,
                });

                this.retrievePatientEquipment(id)
                this.getPatientLastChartNotes(id)
            });
    }

    retrieveTicketRecords(o) {

        if (this.state.ticketRecordsLoaded === true) {
            return
        }
        if (!(this.state.loadingOrderTimeline && this.state.loadingOrderTimeline === o.id)) {
            this.setState({ loadingOrderTimeline: o.id })
            return OrderService.getOrderTimeline(o).then((records) => {
                let sorted = records.sort((a, b) => {
                    if (a.id > b.id) {
                        return -1;
                    } else if (a.id < b.id) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                this.setState({

                    ticketRecordsLoaded: true,
                    ticketRecords: sorted
                })
            })
                .catch((err) => {
                    console.log(err)
                });
        }
    }

    retrieveServiceOrderReasonByTicketId(id) {
        if (this.state.serviceReasonLoaded === true) {
            return
        }
        if (!(this.state.loadingServiceOrderReason && this.state.loadingServiceOrderReason === id)) {
            this.setState({ loadingServiceOrderReason: id })
            return ServiceOrderReasonService.getServiceOrderReasonByServiceId(id)
                .then((res) => {
                    this.setState({
                        serviceReasonLoaded: true,
                        curServiceOrderReason: res
                    })

                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }

    updateServiceOrderReasonRecord(prop, val) {
        let obj = this.state.curServiceOrderReason;
        obj[prop] = val

        return ServiceOrderReasonService.updateServiceOrderReason(obj)
            .then((data) => {
            })

            .catch((err) => {
            console.log(err)
            });
    }

    retrievePatientInsurance(id) {
        id = parseInt(id);

        return insuranceService.findPatientVerification(id)
            .then((data) => {
                if (data.verificationInfo) {
                    let parsed = JSON.parse(data.verificationInfo),
                        start = "",
                        end = "";
                    if (parsed.PlanCoverageSummary) {
                        if (parsed.PlanCoverageSummary.EffectiveDate) {
                            start = parsed.PlanCoverageSummary.EffectiveDate;
                        }
                        if (parsed.PlanCoverageSummary.ExpiryDate) {
                            end = parsed.PlanCoverageSummary.ExpiryDate;
                        }
                    }
                    data.start = start;
                    data.end = end;
                    let inDateRange = false;
                    if (!end) {
                        inDateRange = true;
                    } else {
                        inDateRange = (new Date(start).getTime() < new Date().getTime()) && (new Date().getTime() < new Date(end).getTime());
                    }
                    data.inDateRange = inDateRange;
                }
                this.setState({
                    insuranceVerification: data
                })
            })
            .catch((err) => {
                this.setState({
                    insuranceVerification: {}
                })
                console.log(err);
            });
    }

    sortParentsAndChildren(data) {
        let newParentOrders = [];
        let childOrders = [];
        function findParent(parentId) {
            return data.find(x => x.id === parentId);
        }
        data.forEach(order => {
            if (!order.parentOrder) {
                let orderWithChild = order;
                orderWithChild.children = [];
                newParentOrders.push(orderWithChild)
            } else if (order.parentOrder && data.filter(x => (x.id === order.parentOrder && x.orderType === "Service") || (x.id === order.parentOrder && x.orderType === "Pickup")).length > 0) {
                var temp = data.find(x => x.id === order.parentOrder);
                while ((temp && temp.orderType === "Service") || (temp && temp.orderType === "Pickup")) {
                    let temp2 = findParent(temp.parentOrder);
                    temp = temp2;
                }
                if (temp) {
                    order['parentOrder'] = temp.orderId;
                    order['currentModel'] = temp.currentModel;
                    order['serialNumber'] = temp.serialNumber;
                    childOrders.push(order);
                }
                else if (newParentOrders.length > 0) {
                    order['parentOrder'] = newParentOrders[0].orderId;
                    childOrders.push(order);
                }
                else {
                    let orderWithChild = order;
                    orderWithChild.children = [];
                    newParentOrders.push(orderWithChild)
                    // add to the new orders list?
                }
            } else {
                childOrders.push(order)
            }
        })
        childOrders.forEach(childOrder => {
            newParentOrders.forEach(parOrder => {
                if (parOrder.orderId === childOrder.parentOrder) {
                    parOrder.children.push(childOrder)
                }
            })
        })

        return newParentOrders;
    }

    sortFeedback(d, type) {
        let feedbackForId = [];
        let feedback = this.state.complaints;
        let feedbackIsClosed = false;
        let openFeedback = null;
        feedback.forEach(fb => {
            if (fb.orderId === d.id) {
                feedbackForId.push(fb)
            }
        })

        let isTicket = type==="ticket";
        if (feedbackForId && feedbackForId.length > 1) {
            feedbackForId.sort((a,b) => {
                return a.issueCategory.id < b.issueCategory.id ? -1: 1
            });
        }

        if (feedbackForId && feedbackForId.length > 0) {
            openFeedback = feedbackForId.filter((f) => f.issueClosedOn === null)

          if (!openFeedback.length > 0) {
            feedbackIsClosed = true
          }
        }


        if (feedbackForId && feedbackForId.length > 0) {
            let feedbackType = feedbackForId[0].issueCategory.id-1;
            let styles = [
                { margin: '.2rem', backgroundColor: "#FFB8B8", border: "1px solid #FF7089", color: "#75570D" },
                { margin: '.2rem', backgroundColor: "#FFEBBC", border: "1px solid #FBC02D", color: "#881A2D" },
                { margin: '.2rem', backgroundColor: "#C1F5BF", border: "1px solid #52B48F", color: "#195D17" }
            ]

            return (
                <>
                     <div
                        size={ "small" }
                        data-tip={ feedbackIsClosed ? feedbackForId[0].issueCategory.name + " (Closed)" : feedbackForId[0].issueCategory.name }
                        style={ styles[feedbackType] }
                        className="clickableChip gridFeedbackChip"
                        onClick={ (e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            this.setState({
                                openOnFeedback: true,
                            },
                                () => {
                                    this.toggleTicketDetailsModal(d, d.id);
                                    this.setState({
                                        isOrderDetails: !isTicket
                                    })
                                }
                            )
                        } }
                    />
                    <ReactTooltip />
                </>
            )
        }
    }
    setAccountRecentSalesRep(order) {
        if (!order) {
            this.setState({
                accountRecentSalesRep: this.state.accountOwner
            })
            return
        }
        let accountRecentSalesRep = null;
        OrderService.getFlatOrderActivity(order.id).then((res) => {
            accountRecentSalesRep = res.activity.owner;
            this.setState({
                accountRecentSalesRep: accountRecentSalesRep
            })
        }).catch((err) => { })

    }
    retrievePatientEquipment(id) {

        this.setState({ patientEquipmentLoaded: false })
        return OrderService.getPatientEquipment(id)
            .then((data) => {
                if (data && data.length > 0) {
                    let ordersFull = [];
                    data.forEach(d => {
                        let l = d.id
                            .toString()
                            .concat(d.orderType ? " - " + d.orderType : " - Blank Type")
                            .concat(d.status ? " - " + d.status : " - Blank Status");
                        ordersFull.push({ label: l, value: d.id });
                    });
                    this.setState({allOrdersNotSorted: ordersFull});
                    const { internalLocations } = this.context;
                    let ary = internalLocations;
                    let parentOrders = data.filter((f) => f.orderType === "New")

                    let index = ary.find((loc) => {
                        if (!parentOrders || parentOrders.length === 0) {
                            return loc.name === data[0].salesLocation
                        } else {
                            return loc.name === parentOrders[0].salesLocation
                        }
                    })
                    this.setState({
                        salesLocation: index,
                        patientSalesLocation: index,
                    })
                    let gridData = this.state.gridData,
                        o = [],
                        sideCardGridData = this.state.sideCardGridData,
                        sideCardOrders = [];

                    data.sort((a, b) => {
                        const statusA = a.status.toLowerCase();
                        const statusB = b.status.toLowerCase();
                        if (statusA === "cancelled" && statusB !== "cancelled") {
                            return 1;
                        }
                        if (statusA !== "cancelled" && statusB === "cancelled") {
                            return -1;
                        }

                        return 0;
                    });

                    let firstNewOrder = data.find((order) => {
                        return order.orderType.toLowerCase() === "new"
                    })

                    this.setAccountRecentSalesRep(firstNewOrder);

                    data.forEach(d => {
                        if (d.status.toLowerCase() !== "setup" && d.status.toLowerCase() !== "cancelled") {
                            if (d.parentOrder) {
                                let i = data.findIndex(x => x.orderId === d.parentOrder);
                                if (data[i].status.toLowerCase() === "cancelled") {
                                    return
                                }
                            }
                            sideCardOrders.push({
                                orderId: this.renderSideCardId(d),
                                id: d.orderId,
                                date: moment(d.createdAt).format("MM/DD/YYYY"),
                                reason: d.reason,
                                status: this.renderSideCardStatus(d),
                                orderType: d.orderType,
                                isApproved: d.isApproved,
                            })
                        }
                    })
                    let parentsWithKids = this.sortParentsAndChildren(data);
                    parentsWithKids.forEach(d => {
                        o.push({
                            blank: this.renderCreateTicketButton(d, d.id),
                            orderIdLink: this.renderOrderLink(d.id),
                            orderId: d.id,
                            statusChip: this.renderStatusChip(d),
                            category: this.renderProductInformation(d),
                            points: d.points,
                            serialNumber: d.serial,
                            setupDate: d.setupDate && d.setupDate !== null ? globalFunctions.format(d.setupDate, { month: "2-digit", day: "2-digit", year: "2-digit" }) : null,
                            lastNoteDate: d && d.lastNoteDate && d.lastNoteDate !== null ? moment(d.lastNoteDate).format("MM/DD/YY") : null,
                            feedback: this.sortFeedback(d, "order"),
                            tickets: d.children,
                            status: d.status,
                            reason: d.reason,
                            ticketRows: d.children ? this.createTicketRowData(d, d.children) : null,
                            orderType: d.orderType,
                            isApproved: d.isApproved,
                            orderStatusReasonId: d.orderStatusReasonId,
                            routingInformation: null
                        });

                    });
                    gridData.rows = o;
                    sideCardGridData.rows = sideCardOrders;
                    let openAndNotApproved = this.state.sideCardGridData.rows.filter((d) => d.isApproved !== true)

                    this.setState({
                        gridData: gridData,
                        sideCardGridData: sideCardGridData,
                        patientEquipmentTickets: data,
                        openAndNotApproved: openAndNotApproved,
                    })

                    if (this.state.shouldOpenOrderId) {
                        setTimeout(() => {
                            this.setState({
                                patientEquipmentLoaded: true
                            }, ()=>{
                                this.highlightOrderRow(this.state.shouldOpenOrderId, "service")
                                this.setState({ shouldOpenOrderId: null })})
                        }, 1);
                    }else{
                        setTimeout(() => {
                            this.setState({
                                patientEquipmentLoaded: true
                            })
                        }, 1);
                    }
                }
                else {
                    this.setState({
                        accountRecentSalesRep: this.state.accountOwner,
                        gridData: this.state.gridData,
                        sideCardGridData: this.state.sideCardGridData,
                        patientEquipmentTickets: [],
                        patientEquipmentLoaded: true
                    })
                }
            })

            .catch((err) => {
                console.log(err)
                toast.error("Oh no! An error occurred while retrieving patient orders.");
            });

    }

    renderProductInformation(p) {
        if (p.product && p.product !== null) {
            return (
                <div>
                    { p.category }<br />
                    { `(${ p.qty }) ${ p.product }` }
                </div>
            )
        }else{
            return (<div>
                No Equipment Added<br />

            </div>)
        }

    }

    renderOrderLink(id) {
        return (
            <Route
                render={ ({ history }) => (
                    <div className={ "orderIdText" } onClick={ () => {
                        history.push({
                            pathname: "/order/" + id,
                            results: this.state.results,
                        });
                    } }
                    >
                        { id }
                    </div>
                ) }
            />
        )
    }

    renderSideCardId(order) {
        return (
            <div ><p className="openOrdersRowLabel" style={ { marginBottom: 0 } }>{ order.orderType.toLowerCase() === "service" ?
                "Service" : "Sales" }</p><p
                    className="" style={ {
                        marginBottom: 0, color: "#5881C1",
                        fontWeight: 500
                    } }>{ order.id }</p></div>
        )
    }

    renderSideCardStatus(order) {
        if (order.status.toLowerCase() === "in process") {
            var chipColor = "#FFF5DE";
            var chipBorderColor = "#FBC02D51";
            var chipFontColor = "#75570D";
        } else if (order.status.toLowerCase() === "ready to deliver") {
            chipColor = "#E5F9E4";
            chipBorderColor = "#52B48F32";
            chipFontColor = "#195D17";
        } else if (order.status.toLowerCase() === "new") {
            chipColor = "#E3EBFA";
            chipBorderColor = "#316BCF32";
            chipFontColor = "#316BCF";
        } else {
            chipColor = "#FFF";
            chipBorderColor = "#00032";
            chipFontColor = "#000";
        }

        return (
            <MUIChip label={ order.status } variant={ "outlined" } size={ "small" }
                style={ { color: chipFontColor, backgroundColor: chipColor, border: `1px solid ${chipBorderColor}` } } />
        )
    }

    highlightOrderRow(id, openModalOfType) {
        if (this.state.TabsOneActiveItem !== "1") {
            this.toggleTicketDetailsModal()
        }
        this.setState({ TabsOneActiveItem: "1" })
        if (this.state.highlightedRow.id === id || this.state.highlightedRow.orderId === id) {
            return
        }
        let rows = this.state.gridData.rows;
        let expanded = this.state.expandedRowIds;

        let index = rows.findIndex(x => x.orderId === id);
        let order = {};
        if (index < 0) {
            rows.forEach((row, idx) => {
                let tempIndex = row.tickets.findIndex(x => x.orderId === id);
                if (tempIndex > -1) {
                    order = row.tickets[tempIndex]
                    if (!expanded.includes(idx)) {
                        expanded.push(idx)
                        this.setExpandedRowIds(expanded)
                    }
                }
            });
        } else {
            order = rows[index]
        }


        this.setState({ highlightedRow: order });
        setTimeout(() => {
            this.scrollToOrder(order);
        }, 1);


        if (openModalOfType === "service") {
            setTimeout(() => {
                this.toggleTicketDetailsModal(order, (order.id || order.orderId));

            }, 1);
        }
        setTimeout(() => {
            this.removeHighlightRow(id);

        }, 6000);

    }
    scrollToOrder(order){
        let id = order.orderId || order.id;
        if(!id){
            return
        } else {
            let elem = document.getElementById(id.toString());
            elem.scrollIntoView();
        }
    }

    removeHighlightRow(id) {
        let currHighlight = this.state.highlightedRow.id ? this.state.highlightedRow.id : this.state.highlightedRow.orderId;
        if (currHighlight === id) {
            this.setState({ highlightedRow: { id: null } })
        }
    }

    handleCreateTicketDropDown = (e) => {


        if (e && e.value && e.value.toLowerCase() === "service") {
            this.toggleCreateTicketModal(e.order, e.orderId, "Service")
        }
        else if (e && e.value && e.value.toLowerCase() === "pickup") {
            this.toggleCreateTicketModal(e.order, e.orderId, "Pickup")
        }


    }

    checkForCompletedPickup(order) {
        if (!order || !order.children || order.children.length === 0) {
            return
        }

        let completedSetupChildIndex = order.children.findIndex((child) => {
            return (child.orderType === "Pickup" && child.status === "Setup")
        })
        return (completedSetupChildIndex !== -1)
    }

    renderCreateTicketButton(order, orderId) {
        const customStyles = {
            option: (styles) => ({
                ...styles,
                cursor: 'pointer',
            }),
            control: (base) => ({
                ...base,
                boxShadow: "none"
            })
        }

        if (order.orderType.toLowerCase() === "new" && order.status.toLowerCase() !== "cancelled") {
            let disableCreate = this.checkForCompletedPickup(order);
            return (
                <div className={"test1"}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        if (disableCreate) {
                            toast.warn("Cannot Create a New Ticket on an Order That Has a Completed Pickup")
                        }
                    }
                    }
                >
                    <Select
                        styles={ customStyles }
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        className={"createTicketDropDown"}
                        isSearchable={false}
                        placeholder={"Create Ticket"}
                        value={{
                            label: "Create Ticket",
                            value: "Create Ticket",
                        }}
                        options={[{
                            label: "Service",
                            value: "service",
                            order: order,
                            orderId: orderId
                        },
                        {
                            label: "Pickup",
                            value: "pickup",
                            order: order,
                            orderId: orderId
                        }
                        ]}
                        onChange={(e) => this.handleCreateTicketDropDown(e)}
                        openMenuOnClick={!disableCreate}
                    />
                </div>
            )
        }
        if (order.orderType.toLowerCase() === "service") {
            return (
                "Service - No Parent Found"
            )
        }
    }

    getAppApproval() {
        let id = this.state.id;

        patientAppService
            .getPatientApprovals(id)
            .then((res) => {
                this.setState({ appApproval: res });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getAllUsers() {
        const { allUsers } = this.context;
        let o = [];
        let cso = [];

        allUsers.map((u) => {
            return o.push({
                name: u.firstname + " " + u.lastname,
                id: u.id,
            })
        })

        let filterCSUsers = allUsers.filter((u) => ["CARE_COORDINATOR", "CUSTOMER_EXPERIENCE", "EXECUTIVE_MANAGEMENT"].includes(u.role));

        filterCSUsers.map((u) => {
            return cso.push({
                name: u.firstname + " " + u.lastname,
                id: u.id,
            })
        })
        this.setState({
            allUsers: allUsers,
            options: o,
            filteredOpts: o,
            csUsers: cso,
            filteredCSUsers: cso
        })
    }

    getPatientPhones(id) {
        patientPhoneService.getPatientPhones(id).then((res) => {
            let rows = [],
                data = this.state.phoneData;
            res.forEach((val, idx) => {
                var row = {
                    id: val.id,
                    patientId: val.patientId,
                    contactName: val.contactName,
                    phoneType: val.phoneType,
                    phoneNumber: val.phoneNumber,
                };
                row.delete = this.renderDeletePhoneButton(row);
                rows.push(row);
            });
            data.rows = rows;
            this.setState({ phoneData: data });
        });
    }

    determineLegalComplaints(complaints) {
        let legalComp = complaints.filter(({ issueReason, issueStatus }) => {
            return issueStatus === 0 && issueReason && issueReason.name === "Legal"
        }
        );

        let nonLegalComp = complaints.filter(({ issueReason, issueStatus }) => {
            return issueStatus === 0 && issueReason && issueReason.name !== "Legal"
        }
        );

        if (nonLegalComp.length > 0 && legalComp.length === 0) {
            this.setState({
                popUpComplaintModal: true,
                popupComplaints: nonLegalComp,
            })
        } else if (legalComp.length > 0) {
            this.setState({
                popUpComplaintModal: true,
                popupComplaints: legalComp,
            })
        } else {
            return false
        }
    }

    getPatientNotes(id) {
        return PatientNoteService.getNotesByPatient(id)
            .then((res) => {
                let sorted = res.sort((a,b) => {
                    let da = new Date(a.noteAddedOn).getTime(),
                        db = new Date(b.noteAddedOn).getTime();

                    return da > db ? -1: 1
                });
                this.setState({
                    notes: sorted,
                });
            })
            .catch((e) => {
                toast.error(e);
            });
    }

    createComplaintNote() {
        const { currentUser } = this.context;

        let issueId = this.state.currentComplaint.id,
            text = this.state.complaintNoteText,
            obj = {};

        if (text === "") {
            toast.warn("You must enter note text");
            return;
        }

        obj.patientIssueId = issueId;
        obj.noteText = text;
        obj.noteAddedOn = new Date().toISOString();
        obj.noteAddedBy = currentUser.username;

        ComplaintService.createPatientIssueNote(obj)
            .then((res) => {
                toast.success("Note created successfully");
                let notes = this.state.complaintNotes;

                if(notes["notes" + issueId]) {
                    notes["notes" + issueId].push(res);
                }
                else{
                    notes["notes" + issueId] = [res];
                }
                this.setState({
                    complaintNoteModal: false,
                    complaintNotes: notes,
                    complaintNoteText: "",
                });
            })
            .catch((e) => {
                toast.error(e);
            });
    }

    createPatientNote() {
        const { currentUser } = this.context;

        let notes = this.state.notes,
            note = {
                patientId: parseInt(this.state.id),
                note: this.state.noteText,
                noteAddedBy: currentUser.username,
                noteAddedOn: globalFunctions.getUTCMoment(),
            };

        PatientNoteService.createPatientNote(note)
            .then((res) => {
                if (res.noteAddedOn.indexOf("Z") > 0) {
                    res.noteAddedOn = res.noteAddedOn.split("Z")[0];
                }

                notes.reverse();
                notes.push(res);
                notes.reverse();
                this.setState({
                    notes: notes,
                    createPatientNoteModal: false,
                    noteText: "",
                });
                toast.success("Patient Note Saved Successfully!");

            })
            .catch((err) => {
                toast.error(err.toString());
            });
    }

    closeComplaint() {
        const { currentUser } = this.context;

        let c = this.state.currentComplaint,
            idx = this.state.complaints.findIndex((v) => {
                return v.id === c.id;
            });

        c.issueClosedBy = currentUser;
        c.issueClosedById = currentUser.id;
        c.issueClosedOn = new Date().toISOString();
        c.issueStatus = 1;
        return ComplaintService.updatePatientIssue(c)
            .then((res) => {
                toast.success("Customer feedback closed successfully");

                let com = this.state.complaints;
                res.issueClosedBy = currentUser;
                com.splice(idx, 1, res);

                this.setState({
                    closeComplaintModal: false,
                    complaints: com,
                });
            })
            .catch((e) => {
                console.log(e)
                toast.error(e);
            });
    }

    cancelTicket() {
        let ticket = this.state.curOrder;
        if (!ticket) {
            this.setState({
                cancelTicketModal: false,
            })
            return
        }

        this.statusChanged(4)
        this.updateReason()

        this.saveStatusReasonChange()
        this.setState({
            cancelTicketModal: false,
        })
    }
    addAdditionalPArtsToTicket() {
        let ticket = this.state.curOrder;
        if (!ticket) {
            this.setState({
                addAdditionalPartsModal: false,
            })
            return
        }

        this.statusChanged(1)
        this.updateReason(72)

        this.saveStatusReasonChange()
        this.setState({
            addAdditionalPartsModal: false,
        })
    }

    getOrderType = (orderType) => {
        const nOrder = this.state.nOrder;
        nOrder.orderType = orderType.name;

        this.setState({
            nOrder: nOrder,
            selectedOrderTypeRadio: orderType.id,
        });
    };

    handleChange(property, event) {
        //update the property that the user has edited in the order...BC
        let nPatient = this.state.ePatient;
        if (property === "height" || property === "weight" || property === "btPId") {
            nPatient[property] = event.target.value ? parseInt(event.target.value) : null;
        }
        else if (property === "state" || property === "ciState") {
            nPatient[property] = event.value;
        }
        else {
            nPatient[property] = event.target.value;
        }
        this.setState({ ePatient: nPatient });
    }

    geocoder(val) {
        let geocodingClient = this.state.geoClient;
        let opts = {
            MaxResults: 5,
            LocationType: "Street,Address",
            Countries: "us",
            AutoComplete: true,
            VerboseResults: true,
        };
        const callback = (status, res) => {
            if (status !== 200) {
                alert(res.error);
            } else {
                let locations = res.data.locations;
                this.setState({ searchResults: locations });
            }
        };

        if (geocodingClient && geocodingClient.searchByPoint) {
            // Call the searchByPoint API to search the points by the input address.
            geocodingClient.searchByPoint(val, callback, opts);
        }
    }

    geoGoogle(val) {
        if (val) {
            googleService.searchAddress(val).then((res) => {
                this.setState({ searchResults: res.results });
            });
        }
    }

    renderSearchResult() {
        let results = this.state.searchResults;
        if (results.length > 0) {
            return (
                <div className="resultWrap">
                    <div style={ { width: "100%" } }>
                        <button
                            id="popup-closer"
                            className="ol-popup-closer"
                            onClick={ this.clearSearch.bind(this) }
                        />
                    </div>
                    <br />
                    <ul id="geocoderResult">
                        { results.map((item) => {
                            return (
                                <li key={ results.indexOf(item) }>
                                    <button
                                        style={ {
                                            border: "0",
                                            backgroundColor: "transparent",
                                            color: "#3887BE",
                                        } }
                                        onClick={ this.setAddress.bind(this, item) }
                                    >
                                        { " " }
                                        { item.address }{ " " }
                                    </button>
                                </li>
                            );
                        }) }
                    </ul>
                    <div className="loading hidden">
                        <img
                            src="https://samples.thinkgeo.com/cloud/example/image/Spinner-1s-50px.gif"
                            alt="loading"
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderGoogleSearchResult() {
        let results = this.state.searchResults;
        if (results.length > 0) {
            return (
                <div className="resultWrap">
                    <div style={ { width: "100%" } }>
                        <button
                            id="popup-closer"
                            className="ol-popup-closer"
                            onClick={ this.clearSearch.bind(this) }
                        />
                    </div>
                    <br />
                    <ul id="geocoderResult">
                        { results.map((item, index) => {
                            if (
                                index < 4 &&
                                item.formatted_address &&
                                item.formatted_address.length > 0
                            ) {
                                return (
                                    <li key={ results.indexOf(item) }>
                                        <button
                                            style={ {
                                                border: "0",
                                                backgroundColor: "transparent",
                                                color: "#3887BE",
                                            } }
                                            onClick={ this.setGoogleAddress.bind(this, item) }
                                        >
                                            { " " }
                                            { item.formatted_address }{ " " }
                                        </button>
                                    </li>
                                );
                            }
                            return null;
                        }) }
                    </ul>
                    <div className="loading hidden">
                        <img
                            src="https://samples.thinkgeo.com/cloud/example/image/Spinner-1s-50px.gif"
                            alt="loading"
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    clearSearch() {
        this.setState({ searchResults: [] });
    }

    setAddress = (location) => {
        const nPatient = this.state.ePatient;
        let addr = location.address.split(",");

        let street = addr[0],
            city = addr[1],
            county = addr[2],
            state = addr[3],
            zip = addr[4];

        nPatient.address = street ? street.trim() : "";
        nPatient.city =
            city && city.toLowerCase().indexOf("county") < 0 ? city.trim() : "";
        nPatient.state =
            state && city.toLowerCase().indexOf("county") < 0
                ? state.trim()
                : county
                    ? county.trim()
                    : "";
        nPatient.zipCode =
            zip && parseInt(zip) ? zip.trim() : parseInt(state) ? state : "";
        nPatient.latitude = location.locationPoint.x;
        nPatient.longitude = location.locationPoint.y;
        this.setState({ ePatient: nPatient, searchResults: [] });
    };

    setGoogleAddress = (location) => {
        const nPatient = this.state.ePatient;

        let addr = location.address_components;
        let numComp = "",
            streetComp = "",
            city = "",
            state = "",
            zip = "";
        addr.forEach((item) => {
            if (item.types.includes("street_number")) {
                numComp = item.long_name;
            } else if (item.types.includes("route")) {
                streetComp = item.long_name;
            } else if (
                item.types.includes("neighborhood") ||
                item.types.includes("locality")
            ) {
                city = item.long_name;
            } else if (item.types.includes("administrative_area_level_1")) {
                state = item.long_name;
            } else if (item.types.includes("postal_code")) {
                zip = item.long_name;
            }
        });

        nPatient.address = numComp + " " + streetComp;
        nPatient.city = city;
        nPatient.state = state;
        nPatient.zipCode = zip;
        nPatient.latitude = location.geometry.location.lat || null;
        nPatient.longitude = location.geometry.location.lng || null;
        this.setState({ ePatient: nPatient, searchResults: [] });
    };

    handleDatePickerChange = (property, value) => {
        //update the date property on the object that the user has edited in the order...BC
        const nPatient = this.state.ePatient;
        value = moment(value).format("MM/DD/YYYY");
        nPatient[property] = value;
        this.setState({ ePatient: nPatient });
    };

    handleCheckboxChange = (property, event) => {
        //update the property that the user has edited in the order...BC
        const nPatient = this.state.ePatient;
        nPatient[property] = event.target.checked;
        this.setState({ ePatient: nPatient });
    };

    handleComplaintCheckboxChange = (property, event) => {
        const complaint = this.state.nComplaint;
        complaint[property] = event.target.checked;
        this.setState({
            nComplaint: complaint
        })
    }

    handleNoteChange(ev) {
        this.setState({ noteText: ev.target.value });
    }

    completedChange = (event) => {
        this.setState({ completed: event.target.checked });
    };

    changeInsuranceType(type, secondary) {
        let ePatient = this.state.ePatient,
            tplt = this.state.patientEditTemplate;
        if (secondary === true) {
            ePatient.secondaryInsuranceType = type;
            ePatient.secondaryInsuranceTypeId = type.id;
            ePatient.secondaryInsuranceSubType = null;
            ePatient.secondaryInsuranceSubTypeId = null;
            tplt.item.secondaryInsuranceTypeId = type.id;
            tplt.item.secondaryInsuranceSubTypeId = null;
        } else {
            ePatient.insuranceType = type;
            ePatient.insuranceTypeId = type.id;
            ePatient.insuranceSubType = null;
            ePatient.insuranceSubTypeId = null;
            tplt.item.insuranceTypeId = type.id;
            tplt.item.insuranceSubTypeId = null;
        }
        this.setState({ ePatient: ePatient, patientEditTemplate: tplt });
    }

    changeInsuranceSubType(type, secondary) {
        if (type != null) {
            delete type.deleted;
            delete type.deletedAt;
            delete type.deletedById;
        }
        let ePatient = this.state.ePatient,
            tplt = this.state.patientEditTemplate;
        if (secondary === true) {
            ePatient.secondaryInsuranceSubType = type;
            ePatient.secondaryInsuranceSubTypeId = type.id;
            tplt.item.secondaryInsuranceSubTypeId = type.id;
        } else {
            ePatient.insuranceSubType = type;
            ePatient.insuranceSubTypeId = type.id;
            tplt.item.insuranceSubTypeId = type.id;
        }
        this.setState({ ePatient: ePatient });
    }

    changeContactRelationship(rel) {
        let ePatient = this.state.ePatient;

        ePatient.emergencyRelationship = rel;

        this.setState({ ePatient: ePatient });
    }

    changeContactMethod(rel) {
        let ePatient = this.state.ePatient;

        ePatient.preferredContactMethod = rel;

        this.setState({ ePatient: ePatient });
    }


    renderDialog() {
        let openOrders = []
        if (this.state.patientEquipmentLoaded && this.state.openAndNotApproved && this.state.openAndNotApproved !== null) {
          this.state.openAndNotApproved.forEach((o) => openOrders.push(o))
           const sortByCurOrder = open => {
                const sorter = (a, b) => {
                    if (a.id !== this.state.curOrderId && b.id === this.state.curOrderId) {
                        return 1;
                    };
                    if (a.id === this.state.curOrderId && b.id !== this.state.curOrderId) {
                        return -1;
                    }
                    return b['id'] - a['id']
                }
                open.sort(sorter)
            }
            sortByCurOrder(openOrders)
        }

        return (
            <React.Fragment>
                <MUIDialog
                    maxWidth="md"
                    open={ this.state.setDialogOpen }
                    onClose={ () => this.setState({ setDialogOpen: false }) }
                >
                    <MUIDialogTitle variant="h6" color="textPrimary">
                        { "Update Insurance?" }
                    </MUIDialogTitle>
                    <MUIDialogContent>
                        <MUIDialogContentText variant="body1" color="textPrimary">
                            <span style={{ display: "inline-block", paddingBottom: ".5rem" }}>Changes to the insurance will affect the following open tickets:</span>
                            <br/>
                            { openOrders.map((o) => <span style={ this.state.curOrderId && this.state.curOrderId === o.id ? { color: MUIBlueColor[700] } : {}}>#{ o.id } ({ o.orderType }) { o.status.props.label }{ o.reason ? ": " + o.reason : null } { this.state.curOrderId && this.state.curOrderId === o.id ? <b>(Current Ticket)</b> : "" } <br /></span>)}
                            <span style={ { display: "inline-block", paddingTop: "1rem" } }>Do you wish to continue with these changes?</span>
                        </MUIDialogContentText>
                    </MUIDialogContent>
                    <MUIDialogActions>
                        <MUIButton
                            variant="text"
                            onClick={ () => this.setState({ setDialogOpen: false }) }>Cancel</MUIButton>
                        <MUIButton variant="contained"
                            onClick={ () => {
                                this.savePatient(true)
                                this.setState({
                                    setDialogOpen: false,
                                })
                            } } autoFocus>
                            Confirm
                        </MUIButton>
                    </MUIDialogActions>
                </MUIDialog>
            </React.Fragment>
        );

    }


    savePatient(fromInsurance, lastAorDate) {
        let t = this,
            st = this.state,
            ph = st.patientPhoneNumbers;

        if (st.ePatient.mbi !== st.previousMbi && st.ePatient.mbi && st.ePatient.mbi.length !== 11) {
            toast.error("Please enter a valid MBI.");
            return;
        }

        if (lastAorDate && lastAorDate !== null && lastAorDate === true) {
            st.ePatient.lastAorDate = new Date()
        }

        const regex = /((|\()\d{3}(|\))-\d{3}-\d{4})/g;

        if (st.patient.dateOfBirth !== st.ePatient.dateOfBirth) {
            st.ePatient.dateOfBirth = moment(st.ePatient.dateOfBirth).format(
                "MM/DD/YYYY"
            );
        }

        if (st.ePatient.phone && !regex.test(st.ePatient.phone)) {
            toast.warning(
                "Invalid phone number.  Try re-entering phone number and saving again."
            );
        }

        let phs = ph.filter((x) => x.id === null);
        if (phs && phs.length > 0) {
            patientPhoneService
                .addPatientPhones(st.ePatient.id, phs)
                .then((res) => {
                    this.getPatientPhones(this.state.id);
                })
                .catch((err) => {
                    console.log(err);
                });
        }



        return OrderService.updatePatient(t.state.ePatient, fromInsurance)
            .then((data) => {
                if (this.state.TabsOneActiveItem === "2" || this.state.TabsOneActiveItem === "3") {
                    this.setState({
                        TabsOneActiveItem: "1",
                    })
                }

                this.setState({
                    patient: data,
                    ePatient: JSON.parse(JSON.stringify(data)),
                    canEditBasicInfo: false,
                    canEditInsuranceInfo: false,
                    radioBtnSelected: false,
                    radio: {
                        serviceTicketAction: "",
                        pickupTicketAction: { label: "", value: "" }
                    },
                    createTicketNote: null,
                }, () => { this.openOrder() });

                toast.success("Saved Successfully!");
            })

            .catch((err) => {
                toast.error("Oh no! An error occurred while saving.");
            });
    }

    retrieveVerificationResults(id) {
        insuranceService.getPatientVerifications(id)
            .then(res => {
                this.setState({
                    verificationResults: res && res.length > 0 ? res.reverse() : [],
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    submitChangesToPverify() {
        //uncomment ifyou want to test the routing after verification
        // var o = this.state.eOrder;
        // o.status = 1;
        // o.orderStatusReasonId = 15;
        // this.updateOrder(o);
        // this.setState({
        //     verificationModalOpen: !this.state.verificationModalOpen,
        // });

        // return
        var item = this.state.ePatient,
            ticks = this.state.eOrder;// || this.state.patientEquipmentTickets.filter(x => x.status.toLowerCase() !== "cancelled");


        if (ticks) {
            this.setState({
                submitToPverifyLoading: true,
            })
            var model = {
                orderId: ticks.id,
                insuranceSubTypeId: item.insuranceSubTypeId,
                secondaryInsuranceSubTypeId: item.secondaryInsuranceSubTypeId,
                insuranceGroup: item.insuranceGroup,
                secondaryInsuranceGroup: item.secondaryInsuranceGroup,
                insurancePolicy: item.insurancePolicy,
                secondaryInsurancePolicy: item.secondaryInsurancePolicy
            };

            insuranceService.verifyPatient(model)
                .then(res => {
                    if (res && res.length > 0) {
                        toast.success("Verification requested!");
                        this.retrievePatientInsurance(this.state.id);
                        this.retrieveVerificationResults(this.state.id);
                        this.setState({
                            verificationModalOpen: true,
                            submitToPverifyLoading: false,
                        })
                    }
                    else {
                        this.setState({
                            submitToPverifyLoading: false,
                        })
                        toast.warn("There was an issue submitting the request.");
                    }
                })
                .catch(e => {
                    this.setState({
                        submitToPverifyLoading: false,
                    })
                    console.log(e)
                    toast.error("Something went wrong with the request.");
                })
        }
        else
            toast.warn("No open tickets to verify insurance.");
    }

    toggleNewOrdersModal(order) {
        this.setState({ shouldOpenOrderId: order.id })
        this.retrievePatientEquipment(this.state.id).then((res) => {

            }
        )
    }

    addNewNote(id, note) {
        if (this.state.saving) {
            return
        }
        this.setState({
            saving: true
        })
        this.toggleProcessing();

        return NoteService.createActivityNote(id, note)
            .then((res) => {
                if (res) {
                    //need to set the last note date because otherwise the save overrides it...BC
                    //let ord = this.state.eOrder;
                    //ord.activity.lastNoteDate = new Date();

                    if (res.createdAt.indexOf("Z") > 0) {
                        res.createdAt = res.createdAt.split("Z")[0];
                    }

                    this.setState({ saving: false, createTicketNote: null, })
                    this.retrieveNotes(id)
                }
            })
            .catch((err) => {
                console.log(err);
                //Maybe send an error to the user?...BC
            });
    }

    createOrder(history, ticket, quoteItems) {
        if (this.state.disableCreate) {
            return
        }
        this.setState({
            disableCreate: true
        })
        const { currentUser, companyName } = this.context;
        let cb = currentUser;
        let st = this.state;
        let order = st.nOrder,
            patient = st.ePatient,
            // owner = st.accountOwner,
            owner = st.accountRecentSalesRep || st.accountOwner,
            productsAdded = st.productsAddedList;

        let activity = {
            type: "Order",
            accountId: patient.accountId,
            startedAt: new Date().toISOString(),
            endedAt: new Date().toISOString(),
            quality: false,
            modified: new Date().toISOString(),
            priority: 0,
            isExpense: false,
        };
        if (ticket) {
            order.orderType = ticket.orderActivityType.name;
            order.orderStatusReasonId = ticket.orderStatusReason.id;
            order.status = ticket.orderStatusReason.orderStatus;
            order.type = ticket.orderActivityType.name;
            activity.type = ticket.orderActivityType.activityType
        }

        // if (!this.state.patientProductsLoaded) {
        //     return <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }><MDBSpinner></MDBSpinner></div>
        // }
        //default sales and service location to owner of account...BC
        if (owner.userprofileLocations && owner.userprofileLocations.length > 0) {

            order.serviceLocationId = owner.userprofileLocations[0].locations.id;
            order.salesLocationId = owner.userprofileLocations[0].locations.id;
        }

        //if the type is new, this works just fine...BC


        if (order.orderType === "New" || (ticket && ticket.orderActivityType.name.toLowerCase() !== "service")) {
            activity.ownerId = owner.id;
        } else if (companyName === "corkmedical") {
            //otherwise, the owner is the current logged in user...BC
            activity.ownerId = currentUser.id;
        } else {
            activity.ownerId = 211
        }

        let er = [];
        //in this case, they must set the service location themselves from the box...BC
        if (this.state.serviceLocation != null) {
            if (companyName === "corkmedical" || !ticket || (ticket.type && ticket.type.toLowerCase() !== "service")) {
                order.serviceLocationId = this.state.serviceLocation.id;
            } else {
                order.serviceLocationId = 45
            }
        }
        else {
            er.push("You must select a service location.");
        }
        if (this.state.salesLocation != null) {
            if (companyName === "corkmedical" || !ticket || (ticket.orderActivityType && ticket.orderActivityType.name !== "service")) {
                order.salesLocationId = this.state.salesLocation.id;
            } else {
                order.salesLocationId = 45
            }
        }
        else {
            er.push("You must select a sales location.");
        }
        if (order.orderType === "Service" && (!productsAdded || productsAdded.length === 0) && !ticket) {
            er.push("At least one product is required for a Service order. Please select a product and try again.");
        }
        if (er.length > 0) {
            er.forEach(e => toast.error(e));
            this.setState({
                disableCreate: false
            });
            return;
        }

        order.insuranceTypeId = patient.insuranceTypeId;
        order.insuranceSubTypeId = patient.insuranceSubTypeId;
        order.insurancePolicy = patient.insurancePolicy;
        order.insuranceGroup = patient.insuranceGroup;

        order.secondaryInsuranceTypeId = patient.secondaryInsuranceTypeId;
        order.secondaryInsuranceSubTypeId = patient.secondaryInsuranceSubTypeId;
        order.secondaryInsurancePolicy = patient.secondaryInsurancePolicy;
        order.secondaryInsuranceGroup = patient.secondaryInsuranceGroup;

        order.lastUpdatedOn = new Date();
        order.lastUpdatedById = currentUser.id;
        order.patientId = patient.id;
        order.patientName = patient.firstName + " " + patient.lastName;
        order.lastStatusChange = new Date().toISOString();

        order.phone = patient.phone;
        order.address = patient.address;
        order.city = patient.city;
        order.state = patient.state;
        order.zipCode = patient.zipCode;
        order.dateOfBirth = patient.dateOfBirth;
        order.weight = patient.weight;
        order.gender = patient.gender;
        order.email = patient.email;
        //endregion

        if (
            (order.orderType === "Pickup" ||
                order.orderType === "Exchange" ||
                order.orderType === "Service") &&
            productsAdded.length > 0 && (!ticket.type || ticket.type.toLowerCase() !== "service")
        ) {
            order.orderActivityProducts = [];
            for (let product of productsAdded) {
                order.orderActivityProducts.push({
                    products: {
                        abnRequired: false,
                        amount: 0,
                        approved: false,
                        hcpcs: product.hcpcs || "",
                        points: 0,
                        productSubType: {
                            id: product.productSubType.id,
                            name: product.productSubType.name,
                            hcpcs: product.hcpcs || "",
                            productType: {
                                id: product.productType.id,
                                name: product.productType.name,
                            },
                        },
                        productSubTypeId: product.productSubType.id,
                        productTypeId: product.productType.id,
                        productType: {
                            id: product.productType.id,
                            name: product.productType.name,
                        },
                        qty: 1,
                        serial: product.serial || "",
                    }
                });
            }
        }

        let po = this.state.selectedPreviousOrder;
        activity.orderActivity = order;

        return OrderService.createOrder(activity)
            .then((res) => {
                if (po.previousOrderId) {
                    po.currentOrderId = res.id;
                    this.createServiceOrderReasonRecord(po);
                } else if (ticket) {
                    let o = {
                        previousOrderId: this.state.curOrder.id || this.state.curOrder.orderId ,
                        name: this.state.patient.firstName + " " + this.state.patient.lastName,
                        technician: order.technician ? order.technician.name : "No Technician",
                        salesRep: this.state.accountRecentSalesRep ? this.state.accountRecentSalesRep.name : "No Sales Rep",
                        atp: order.atp ? order.atp.name : "No ATP",
                        serviceReason: this.state.serviceReason && this.state.serviceReason !== null ? this.state.serviceReason : "",
                        currentOrderId: res.id,
                    };
                    let note = {
                        assignedToId: null,
                        createdAt: globalFunctions.getUTCMoment(),
                        createdBy: cb,
                        createdById: cb.id,
                        accountId: res.accountId,
                        patientName: order.patientName,
                        type: "ActivityNote",
                        activityId: o.currentOrderId,
                        dueDate: null,
                        completed: true,
                        task: false,
                    };

                    note.text = this.state.createTicketNote;
                    this.createServiceOrderReasonRecord(o, res);
                    this.addNewNote(o.currentOrderId, note);
                    if (quoteItems) {
                        this.createQuote(quoteItems, quoteItems.shipToPatient, res)
                    }
                }

                this.setState({
                    disableCreate: false,
                    serviceReason: null,
                })
                if (this.state.orderModalOpen) {
                    this.toggleOrderModal();
                }

                if (!quoteItems && (!ticket || (ticket.type !== "Service" && ticket.type.toLowerCase() !== "pickup"))) {
                    history.push({
                        pathname: "/order/" + res.id,
                        state: { order: res },
                    });
                }

            })
            .catch((err) => {
                console.log(err);
            });


    }

    updateOrder(order, noStatusChange, noteToAdd) {
        const { currentUser } = this.context;
        order.lastUpdatedById = currentUser.id;
        if(!noStatusChange){
            if (this.state.radio.preAuthAction === "approveWithoutAuth") {
                OrderService.createPreAuth(order.id)
                    .then((res) => {
                        this.setState({
                            radio: {
                                preAuthAction: "",
                                serviceTicketAction: "",
                                pickupTicketAction: { label: "", value: "" }
                            },
                        })
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }
        return OrderService.updateOrder(order)
            .then((res) => {
                if (noteToAdd) {
                    this.addNewNote(res.id, noteToAdd)
                }
                this.retrievePatientEquipment(this.state.id)
                this.toggleTicketDetailsModal();
                this.setState({
                    shouldOpenOrderId: order.id,
                    radio: {
                        pickupTicketAction: { label: "", value: "" },
                        serviceTicketAction: "",
                        choosePartsAction: "",
                        needsDiagnosisAction: "",
                        needsBilledAction: "",
                        obtainDocAction: "",
                        docFollowupAction: "",
                        approvalAction: "",
                        preAuthFollowUpAction: "",
                        preAuthSubmission: "",
                        followUpTask: "",
                        billingAction: "",
                        salesPPWIssue: "",
                        setup: "",
                        dispatchTechSubType: "",
                        newAccessoryAfterSaleSubType: "",
                        preAuthAction: "",
                        readyToDeliverAction: "",
                        rtdEquipmentOrderedAction: "",
                        readyToSchedule: "",
                        rtdScheduled: "",
                        rtdNotAbleToDeliverAction: "",
                        verification: "",
                    },
                    editLocationInformationModal: false,
                    editGeneralInformationModal: false,
                    thirtyDayFit: false,
                    paymentInformationModal: false,
                    isUpdating: false,
                    noteModalOpen: false,
                    selectedSubTypeFromQueueRoute: null,
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    createPatientPhone() {
        const regex = /((|\()\d{3}(|\))-\d{3}-\d{4})/g;
        let phones = this.state.phoneData,
            pt = this.state.ePatient,
            rows = [],
            allPhones = this.state.patientPhoneNumbers,
            newPhone = this.state.newPhoneNumber,
            valid = true;

        if (!newPhone.contactName) {
            toast.error("You must add a contact name.");
            valid = false;
        }

        if (!newPhone.phoneType) {
            toast.error("You must select a phone type.");
            valid = false;
        }

        if (!newPhone.phoneNumber) {
            toast.error("You must add a phone number.");
            valid = false;
        } else if (!regex.test(newPhone.phoneNumber)) {
            toast.error("You must add a valid phone number.");
            valid = false;
        } else if (valid) {
            phones.rows.forEach((val, idx) => {
                rows.push(val);
            });
            allPhones.push({
                contactName: newPhone.contactName,
                phoneNumber: newPhone.phoneNumber,
                phoneType: newPhone.phoneType,
                patientId: pt.id,
                id: null,
            });
            let nPhone = {
                contactName: newPhone.contactName,
                phoneNumber: newPhone.phoneNumber,
                phoneType: newPhone.phoneType,
                patientId: pt.id,
                id: null,
                delete: null,
            };
            nPhone.delete = this.renderDeletePhoneButton(nPhone);
            rows.push(nPhone);
            phones.rows = rows;
            var p = this.state.newPhoneNumber;
            p.phoneNumber = null;
            p.contactName = null;
            p.phoneType = null;
            this.setState({
                newPhoneNumber: p,
                phoneData: phones,
                patientPhoneNumbers: allPhones,
                addPhoneModalOpen: false,
            });
        }
    }

    createServiceOrderReasonRecord(issue, newOrder) {
        ServiceOrderReasonService.createServiceOrderReason(issue)
            .then((res) => {
                this.setState({
                    selectedPreviousOrder: {},
                });
                if(newOrder){
                    this.toggleNewOrdersModal(newOrder);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    syncPatientToBT() {
        PatientOrders.syncPatientToBT(this.state.id)
            .then(res => {
                if (res.syncPatient) {
                    toast.success("Patient has been created in BT.");
                    window.location.reload();
                }
                else {
                    toast.warn("There was an issue creating the patient.");
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    renderLoadingSpinner() {
        return (
            <Container>
                <div style={ { textAlign: "center", verticalAlign: "center" } }>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderOrdersCard() {
        if(this.state.TabsOneActiveItem !== "1"){
            return
        }

        return (
            <div className="patientViewTable">
                { this.renderTable() }
            </div>
        );
    }

    renderComplaintNotes(id) {
        let notes = this.state.complaintNotes["notes" + id];

        if (notes) {
            notes = notes.sort((a, b) => {
                return a.noteAddedOn < b.noteAddedOn ? -1 : 1;
            });

            return notes.map((n, idx) => {
                return (
                    <tr
                        key={ idx }>
                        <td >{ n.noteAddedOn ? new Date(n.noteAddedOn).toLocaleDateString() : "" }</td>

                        <td>{ n.noteAddedBy }</td>

                        <td>{ n.noteText }</td>
                    </tr>
                );
            }).reverse();
        }
    }

    canEdit(role) {
        switch (role) {
            case "ADMIN":
            case "SYSTEM_ADMIN":
            case "CUSTOMER_EXPERIENCE":
            case "CARE_COORDINATOR":
            case "EXECUTIVE_MANAGEMENT":
            case "OFFICE_MANAGER":
                return true;
            default:
                return false;
        }
    }

    getNextFollowUpDate() {
        let t = new moment();
        let y = t.weekday();
        if (y < 4) {
            t.add(2, 'days')
        } else {
            t.add(4, 'days');
        }

        return t;
    }

    handleSearch(e) {
        let st = this.state,
            search = st.searchQ,
            csUsers = st.csUsers;

        this.setState({
            searchQ: e.target.value
        })

        let filter = csUsers.filter((f) => f.name.toLowerCase().includes(search.toLowerCase()))

        if (e.target.value !== "") {
            this.setState({
                filteredCSUsers: filter,
            })
        } else {
            this.setState({
                filteredCSUsers: csUsers,
            })
        }
    }

    handleSearchChange(e) {
        let st = this.state,
            opts = st.options,
            search = st.searchText;

        this.setState({
            searchText: e.target.value
        })

        let filter = opts.filter((f) => f.name.toLowerCase().includes(search.toLowerCase()))

        if (e.target.value !== "") {
            this.setState({
                filteredOpts: filter,
            })
        } else {
            this.setState({
                filteredOpts: opts,
            })
        }
    }

    toggleFeedbackCollapse = feedbackCollapseID => () =>
        this.setState(prevState => ({
            feedbackCollapseID: prevState.feedbackCollapseID !== feedbackCollapseID ? feedbackCollapseID : ""
        }));


    renderPatientComplaintsCard() {
        const { complaintCategories, currentUser, complaintReasons } = this.context;

        let com = this.state.complaints,
            obj = {},
            op = this.state.options,
            csUsers = this.state.csUsers,
            canEdit = this.canEdit(currentUser.role);

        let complaintsOnOrder = this.state.complaints.filter((c) => c.orderId === this.state.curOrderId);

        let updateComplaint = (val, item, prop) => {
            item[prop] = val;
            if (prop === "issueCategory") {
                prop = "category"
                item.issueCategoryId = val.id;
                this.setState({ complaints: com });
            }
            if (prop === "issueReason") {
                prop = "reason"
                item.issueReasonId = val.id;
                this.setState({ complaints: com });
            }
            if (prop === "assignedToId") {
                prop = "assigned user"
                item.assignedToId = val.id;
                this.setState({
                    complaints: com,
                })
            }
            if (prop === "complimentedUserId") {
                prop = "complimented user"
                item.complimentedUserId = val.id;
                this.setState({ complaints: com })
            }

            if (prop === "nextFollowupDate") {
                prop = "follow up date"
                val.set("hour", 12);
                val.set("minute", 0);
                item.nextFollowupDate = GlobalFunctions.getUTCMoment(val);
            }

            return ComplaintService.updatePatientIssue(item)
                .then((res) => {
                    let complaints = com.slice(),
                        comIndex = complaints.findIndex((c) => c.id === item.id),
                        notes = this.state.complaintNotes;

                    complaints[comIndex] = res;
                    let info = val.name ? val.name : GlobalFunctions.formatDate(val);
                    obj.noteText = "Feedback " + prop + " changed to " + info;
                    obj.patientIssueId = res.id;
                    // obj.noteAddedOn = new Date();
                    obj.noteAddedBy = currentUser.username;

                    this.setState({
                        complaints: complaints,
                    })
                    ComplaintService.createPatientIssueNote(obj)
                        .then((res) => {
                            if (notes["notes" + item.id] === null || notes["notes" + item.id] === undefined) {
                                notes["notes" + item.id] = [];
                            }

                            notes["notes" + item.id].push(res);

                            this.setState({
                                complaints: complaints,
                                complaintNotes: notes,
                            });

                            if (prop === "issueCategory") {
                                let d = document
                                    .getElementsByClassName("issueReasonDropdown")
                                    .item(0);
                                let a = d.getElementsByTagName("a").item(0);
                                a.click();
                            }
                            toast.success("Complaint Updated");
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((e) => {
                    toast.error(e);
                });
        }
        return (
            <>
                <MDBRow>
                    <MDBBtn
                        size="large"
                        className="addNoteBtnFull"
                        onClick={ () => this.setState({ createComplaintModal: true }) }
                    >
                        Add Feedback
                    </MDBBtn>
                </MDBRow>
                { complaintsOnOrder.map((c, idx) => {
                    return (

                        <MDBCard className="patientFeedbackCard" key={ idx } style={ this.state.feedbackCollapseID === "collapse" + idx.toString() ? { border: "2px solid #316BCF", borderRadius: "5px"} : {border: "none"}}>
                            <MDBCollapse id={"collapse" + idx.toString()} isOpen={this.state.feedbackCollapseID}>
                            <CardHeader className="patientFeedbackCardHeader">
                                <Row style={ { paddingBottom: '5px' } }>
                                    <Col size="6" className="feedbackHeaderInfo">
                                        Feedback ID: { c.id }
                                    </Col>
                                        <Col size="6" style={ { textAlign: 'right' } } className="feedbackHeaderInfo">
                                       #{ c.orderId }
                                    </Col>
                                </Row>
                                <div className="feedbackFormWrapper">
                                    <Row>
                                        <Dropdown className={ "feedBackDropDown" }>
                                            <DropdownToggle
                                                disabled={ !canEdit }
                                                className={ "feedBackDropDownToggle" }
                                                nav
                                            >
                                                <span>
                                                    { " " }
                                                    { c.issueCategory ? c.issueCategory.name : "" } &nbsp;
                                                    <Fa icon={ "angle-down" } />
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu basic style={ { width: "100%" } }>
                                                <DropdownItem disabled>
                                                    Select Feedback Type
                                                </DropdownItem>
                                                <DropdownItem divider />
                                                { complaintCategories.map((type, index) => {
                                                    return (
                                                        <DropdownItem
                                                            key={ index }
                                                            onClick={ updateComplaint.bind(
                                                                this,
                                                                type,
                                                                c,
                                                                "issueCategory"
                                                            ) }
                                                        >
                                                            { type.name }
                                                        </DropdownItem>
                                                    );
                                                }) }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Row>
                                        { c.issueCategory.name === "Compliment" ?
                                            (<div>
                                                <Row>
                                                    <Dropdown
                                                        className={
                                                            "feedBackDropDown"
                                                        }
                                                    >
                                                        <DropdownToggle
                                                            disabled={ !canEdit }
                                                            className={ "feedBackDropDownToggle" }
                                                            nav
                                                        >
                                                            <span>
                                                                { " " }
                                                                { c.complimentedUserId
                                                                    ? op.filter((f) => f.id === c.complimentedUserId).map((m) => m.name)
                                                                    : "Assign Compliment" }{ " " }
                                                                &nbsp;
                                                                <Fa icon={ "angle-down" } />
                                                            </span>
                                                        </DropdownToggle>
                                                        <DropdownMenu id="userDropdownMenu" basic style={ { width: "100%" } }>
                                                            <Input className="form-control" placeholder="Search..." onChange={ this.handleSearchChange.bind(this) } value={ this.state.searchText } />
                                                            <DropdownItem disabled>
                                                                Select User
                                                            </DropdownItem>
                                                            <DropdownItem divider />
                                                            { this.state.filteredOpts.map((u, index) => {
                                                                return (
                                                                    <DropdownItem
                                                                        key={ index }
                                                                        onClick={ updateComplaint.bind(
                                                                            this,
                                                                            u,
                                                                            c,
                                                                            "complimentedUserId"
                                                                        ) }
                                                                    >
                                                                        { u.name }
                                                                    </DropdownItem>
                                                                )
                                                            }
                                                            ) }
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </Row>
                                            </div>
                                            ) :
                                            null
                                        }
                                    <Row>
                                        <Dropdown
                                            className={
                                                "feedBackDropDown"
                                            }
                                        >
                                            <DropdownToggle
                                                disabled={ !canEdit }
                                                className={ "feedBackDropDownToggle" }
                                                nav
                                            >
                                                <span>
                                                    { " " }
                                                    { c.issueReason
                                                        ? c.issueReason.name
                                                        : "Select Reason" }{ " " }
                                                    &nbsp;
                                                    <Fa icon={ "angle-down" } />
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu basic style={ { width: "100%" } }>
                                                <DropdownItem disabled>
                                                    Select Feedback Reason
                                                </DropdownItem>
                                                <DropdownItem divider />
                                                { complaintReasons
                                                    .filter((res) => {
                                                        return res.issueCategory.id === c.issueCategory.id;
                                                    })
                                                    .map((type, index) => {
                                                        return (
                                                            <DropdownItem
                                                                key={ index }
                                                                onClick={ updateComplaint.bind(
                                                                    this,
                                                                    type,
                                                                    c,
                                                                    "issueReason"
                                                                ) }
                                                            >
                                                                { type.name }
                                                            </DropdownItem>
                                                        );
                                                    }) }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Row>
                                    <Row>
                                            <Col size="6">
                                            <MuiPickersUtilsProvider utils={ MomentUtils }>
                                                <Row style={ { cursor: "pointer", marginTop: '.2rem'} }>
                                                    <DatePicker
                                                        id={ "followUpDatePicker" }
                                                        style={ { backgroundColor: "white", color: "black", border: "1px solid #CFCFCF" } }
                                                        format="MM/DD/YYYY"
                                                        size="small"
                                                        inputVariant="outlined"
                                                        label={ "Follow Up Date" }
                                                        value={ c.nextFollowupDate || null }
                                                        onChange={ (date) => {
                                                            updateComplaint(
                                                                date,
                                                                c,
                                                                "nextFollowupDate"
                                                            )
                                                            this.setState({
                                                                currentComplaint: c,
                                                                complaintNoteModal: true,
                                                            })
                                                        } }
                                                    />
                                                </Row>
                                            </MuiPickersUtilsProvider>
                                            </Col>
                                            <Col size="6">
                                        <Dropdown
                                            className={
                                                "feedBackDropDown"
                                            }
                                        >
                                            <DropdownToggle
                                                disabled={ !canEdit }
                                                className={ "feedBackDropDownToggle" }
                                                nav
                                            >
                                                <span>
                                                    { " " }
                                                    { c.assignedToId
                                                        ? csUsers.filter((f) => f.id === c.assignedToId).map((m) => m.name)
                                                        : "Assign To" }{ " " }
                                                    &nbsp;
                                                    <Fa icon={ "angle-down" } />
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu basic style={ { width: "100%" } }>
                                                <Input className="form-control" placeholder="Search..." onChange={ this.handleSearch.bind(this) } value={ this.state.searchQ } />
                                                <DropdownItem disabled>
                                                    Select User
                                                </DropdownItem>
                                                <DropdownItem divider />
                                                { this.state.filteredCSUsers

                                                    .map((u, index) => {
                                                        return (
                                                            <DropdownItem
                                                                key={ index }
                                                                onClick={ updateComplaint.bind(
                                                                    this,
                                                                    u,
                                                                    c,
                                                                    "assignedToId"
                                                                ) }
                                                            >
                                                                { u.name }
                                                            </DropdownItem>
                                                        );
                                                    }) }
                                            </DropdownMenu>
                                        </Dropdown>
                                            </Col>
                                    </Row>
                                </div>
                            </CardHeader>
                            </MDBCollapse>
                            <CardBody className="feedbackCardBody" onClick={ this.toggleFeedbackCollapse(`collapse${idx}`)}>
                                <Row className="feedbackCardRow">
                                    <Col size="6" className={"noteHeaderDateAndNameText"}>
                                        <Row size="12">
                                            <div>
                                                {new Date(c.issueLoggedOn).toLocaleDateString()}
                                            </div>
                                        </Row>
                                        <Row size="12">
                                            <div>
                                                {c.issueLoggedBy.firstname + " " + c.issueLoggedBy.lastname}
                                            </div>
                                        </Row>

                                    </Col>
                                    <Col size="6" className={ "noteHeaderDateText feedbackHeaderText"  }>
                                      {`${c.issueCategory.name} - ${c.issueReason.name}`}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col size="12" className="feedbackNoteDescriptionText">
                                        { c.complaintDescription }
                                    </Col>
                                </Row>
                                <Row>
                                    <hr></hr>
                                </Row>
                                <MDBCollapse id={ "collapse" + idx.toString() } isOpen={ this.state.feedbackCollapseID }>
                                <Row>
                                    <Col sixe="12">
                                        <table
                                            className={ "complaintNotesTable" }
                                            style={ { width: "100%" } }
                                        >
                                            <tbody>
                                                <tr>
                                                    <th style={ { width: "12%" } }>Date</th>

                                                    <th style={ { width: "12%" } }>Added By</th>

                                                    <th>Text</th>
                                                </tr>
                                                { this.renderComplaintNotes(c.id) }
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                { c.issueStatus === 1 ? (
                                        <Row className="feedbackCardClosedRow">
                                        <Col className="feedbackCardClosedCol" size="6">
                                            Closed On:{ " " }
                                            { c.issueClosedOn
                                                ? new Date(c.issueClosedOn).toLocaleDateString()
                                                : "Not Closed" }
                                        </Col>
                                            <Col className="feedbackCardClosedCol"  size="6">
                                            Closed By:{ " " }
                                            { c.issueClosedBy ? c.issueClosedBy.firstname + " " + c.issueClosedBy.lastname : "Not Closed" }
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row className="feedbackFooterButtonRow">
                                        <Col size="8" >
                                            <Button
                                                className={ "footerButtonCol" }
                                                size="md"
                                                data-tip={ "Set Follow Up Date & Note" }
                                                onClick={ (e) => {
                                                    e.stopPropagation();
                                                    e.nativeEvent.stopImmediatePropagation();
                                                    updateComplaint(this.getNextFollowUpDate(),
                                                        c,
                                                        "nextFollowupDate"
                                                    )
                                                    this.setState({
                                                        currentComplaint: c,
                                                        complaintNoteModal: true,
                                                    })
                                                }
                                                }
                                            >
                                                { " Set Follow Up Date " }
                                            </Button>
                                            </Col>
                                            <Col size="1" style={{marginRight: "1rem"}}>
                                            <Button
                                                color={ "secondary" }
                                                floating
                                                size="sm"
                                                data-tip={ "Add Feedback Note" }
                                                        onClick={ (e) => {
                                                            e.stopPropagation();
                                                            e.nativeEvent.stopImmediatePropagation();
                                                            this.setState({
                                                                currentComplaint: c,
                                                                complaintNoteModal: true,
                                                            });
                                                        } }
                                                    >
                                                        <Fa size="2x" icon={ "plus" }>
                                                            { " " }
                                                        </Fa>
                                                    </Button>
                                                </Col>
                                                <Col size="1">
                                                    <Button
                                                        color={ "red" }
                                                        floating
                                                        size="sm"
                                                        data-tip={ "Close Feedback" }
                                                        onClick={ (e) => {
                                                            e.stopPropagation();
                                                            e.nativeEvent.stopImmediatePropagation();
                                                            this.setState({
                                                                closeComplaintModal: true,
                                                                currentComplaint: c,
                                                            })
                                                        }
                                                        }
                                            >
                                                <Fa size="2x" icon={ "check-square" }>
                                                    { " " }
                                                </Fa>
                                            </Button>
                                            </Col>
                                        <ReactTooltip />
                                    </Row>
                                ) }
                                </MDBCollapse>
                            </CardBody>
                        </MDBCard>
                    );
                }) }
            </>
        );
    }

    changePassword() {
        var pWord = this.state.newPassword,
            approval = this.state.appApproval;
        if (pWord) {
            patientAppService
                .changePassword(approval.id, pWord)
                .then((res) => {
                    this.setState({
                        isChangingPassword: false,
                        newPassword: null,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            alert("You must add the new password.");
            return;
        }
    }

    clientCSV() {
        let d = this.state.complaints,
            dn = this.state.complaintNotes;

        let outsideHeaders =
            ["id", "orderId", "patientId", "issueStatus", "complaintDescription", "issueCategory", "issueReason",
                "issueLoggedBy", "issueLoggedOn", "issueClosedBy", "issueClosedOn"];

        let legibleOutsideHeaders = ["Feedback ID", "Order ID", "Patient ID", "Issue Status", "Complaint Description", "Issue Category", "Issue Reason",
            "Issue Logged By", "Issue Logged On", "Issue Closed By", "Issue Closed On"];

        let insideHeaders = ["id", "noteAddedBy", "noteAddedOn", "noteText"];

        let legibleInsideHeaders = ["Note ID", "Note Added By", "Note Added On", "Note Text"];

        let result = "",
            columnDelimiter = ",",
            lineDelimiter = "\n";


        d.forEach(function (item) {

            result += legibleOutsideHeaders.join(columnDelimiter);
            result += lineDelimiter;

            outsideHeaders.forEach((property) => {

                switch (property) {
                    case "complaintDescription":
                        let n = item[property].replace(/\n/g, " ");
                        n = n.replace(/,/g, " ");
                        result += n;
                        result += columnDelimiter;
                        break;
                    case "issueLoggedBy":
                    case "issueClosedBy":
                    case "issueCategory":
                    case "issueReason":
                        let v = item[property] ? item[property].name : " "
                        result += v;
                        result += columnDelimiter;
                        break;
                    case "issueLoggedOn":
                        let y = item[property] ? new Date(item[property]).toLocaleDateString() : ""
                        result += y;
                        result += columnDelimiter;
                        break;
                    case "issueClosedOn":
                        let x = item[property] ? new Date(item[property]).toLocaleDateString() : ""
                        result += x;
                        result += columnDelimiter;

                        //double line delimiter for ease of reading
                        result += lineDelimiter;
                        result += lineDelimiter;
                        break;
                    case "issueStatus":
                        let z = item[property] === 0 ? "Open" : "Closed";
                        result += z;
                        result += columnDelimiter;
                        break;
                    default:
                        result += item[property];
                        result += columnDelimiter;
                        break;
                }

            })
            let nProp = "notes" + item.id;
            let nAry = dn[nProp];

            if (nAry) {
                result += legibleInsideHeaders.join(columnDelimiter);
                result += lineDelimiter;

                nAry.forEach((note) => {
                    insideHeaders.forEach(iProp => {
                        switch (iProp) {
                            case "id":
                            case "noteAddedBy":
                                result += note[iProp];
                                result += columnDelimiter;
                                break;
                            case "noteAddedOn":
                                let u = note[iProp] ? new Date(note[iProp]).toLocaleDateString() : ""
                                result += u;
                                result += columnDelimiter;
                                break;
                            case "noteText":
                                let p = note[iProp].replace(/\n/g, " ");
                                p = p.replace(/,/g, " ");
                                result += p;
                                break;
                            default:
                                break;
                        }
                    })
                    //add a new line after each note
                    result += lineDelimiter;
                });
            }
            //add a new line after all the notes are done
            result += lineDelimiter;
            result += lineDelimiter;

        });

        this.downloadBlob(result, "patientFeedback.csv");
    }

    //csv downlaod data here
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    renderRightCardTabs() {
        return (
            <div>
                <MDBNav
                    className="nav-justified  pills-purple-gradient sideNavWrapper"
                >
                    <MDBNavItem>
                        <MDBNavLink
                            style={{fontSize: '.8rem'}}
                            link
                            to="#"
                            active={this.state.items.rightMenu === "1"}
                            onClick={this.togglePills("rightMenu", "1")}
                        >
                            NOTES
                        </MDBNavLink>

                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBNavLink
                            style={{fontSize: '.8rem'}}
                            link
                            to="#"
                            active={this.state.items.rightMenu === "2"}
                            onClick={this.togglePills("rightMenu", "2")}
                        >
                            ATTACHMENTS
                        </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBNavLink
                            style={{fontSize: '.8rem'}}
                            link
                            to="#"
                            active={this.state.items.rightMenu === "3"}
                            onClick={this.togglePills("rightMenu", "3")}
                        >
                            FEEDBACK
                        </MDBNavLink>
                    </MDBNavItem>

                </MDBNav>
                <MDBModalBody style={{paddingTop: '0'}}>
                    <MDBTabContent activeItem={this.state.items.rightMenu} className={"rightCardTabContent"}>
                        <MDBTabPane tabId="1">
                            {this.renderOrderDetailsNotes()}
                        </MDBTabPane>
                        <MDBTabPane tabId={"2"}>
                            {this.renderAttachments()}
                        </MDBTabPane>
                        <MDBTabPane tabId={"3"}>
                            {this.renderPatientComplaintsCard()}
                        </MDBTabPane>
                    </MDBTabContent>
                </MDBModalBody>
            </div>
        )
    }

    renderRightCard() {
        return (<div className={"patientInformationCol"}>{this.state.TabsOneActiveItem === "4" ?
            this.renderRightCardTabs() : this.renderPatientOrOrderNotesCard()}</div>)
    }

    renderPatientOrOrderNotesCard() {
        let stateNotes = this.state.notes;

        if (stateNotes.filter((s) => s.type).length > 0) {
            stateNotes.shift();
        }

        if (this.state.TabsOneActiveItem === "4") {
            if (!this.state.orderNotesLoading && !this.state.orderNotesLoaded && this.state.curOrderId) {
                this.retrieveNotes()
            }
            if (!this.state.orderNotesLoaded) {
                return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <MDBSpinner></MDBSpinner></div>
            }
            stateNotes = this.state.orderNotes;
        }

        return (
            <div>
                {stateNotes.map((note, idx) => {
                    return (
                        <div key={idx} style={{paddingBottom: 6}}>
                            <MDBCard className={"patientPageNoteCard"}>
                                <MDBCardHeader style={{backgroundColor: "#5881C1"}}
                                               className={"orderNoteHeaderPatientPage"}>
                                    <MDBRow>
                                        <MDBCol size={"6"} className={"noteHeaderDateText"}>
                                            {GlobalFunctions.formatDateTime(note.noteAddedOn ? note.noteAddedOn : "")}
                                        </MDBCol>

                                        <MDBCol size={"6"} className={"noteHeaderNameText"}>
                                            {"Added by: " + (note.noteAddedBy ? note.noteAddedBy : "")}
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardHeader>
                                <MDBCardBody className={"orderNotesCardBodyPatientPage"}>
                                    <p style={{whiteSpace: "pre-line"}}>{note.note}</p>

                                </MDBCardBody>

                            </MDBCard>
                        </div>
                    );
                })}
            </div>
        );
    }

    renderTip(note) {
        return note.length > 150
            ? note.substr(0, 150).concat("... (Click to view more)")
            : note;
    }

    patientNoteClicked = (note) => {
        this.setState({
            currOpenNote: note,
        });
        this.togglePatientNoteModal();
    };

    togglePatientNoteModal = () => {
        this.setState({
            ptnNoteModal: !this.state.ptnNoteModal,
        });
    };

    renderPtnNoteModal() {
        if(!this.state.ptnNoteModal){return}
        let n = this.state.currOpenNote;
        return (
            <Modal
                isOpen={ this.state.ptnNoteModal }
                toggle={ this.togglePatientNoteModal }
            >
                <ModalBody>
                    <div>
                        <p>
                            <b>Date: </b>
                            { new Date(n.noteAddedOn).toLocaleDateString() }
                        </p>
                        <p>
                            <b>Added By: </b>
                            { n.noteAddedBy }
                        </p>
                        <p style={ { whiteSpace: "pre-line" } }>
                            <b>Note: </b>
                            { n.note || "" }
                        </p>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    renderDeletePhoneButton(phone) {
        return (
            <Button
                color={ "red" }
                floating
                size="sm"
                data-tip={ "Delete Phone Number" }
                onClick={ () => {
                    if (
                        window.confirm(
                            "This will delete the phone number for this patient. Are you sure you wish to delete this entry?"
                        )
                    ) {
                        if (phone.id) {
                            patientPhoneService
                                .deletePatientPhone(phone.id)
                                .then((res) => {
                                    this.getPatientPhones(this.state.id);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                            // this.getPatientPhones(this.state.id);
                        } else {
                            let data = this.state.phoneData,
                                res = [];

                            res = data.rows.filter((x) => {
                                return (
                                    x.contactName !== phone.contactName &&
                                    x.phoneNumber !== phone.phoneNumber &&
                                    x.id === null
                                );
                            });

                            data.rows = res;
                            this.setState({ phoneData: data });
                        }
                    }
                } }
            >
                <Fa size="1x" icon={ "minus" }>
                    { " " }
                </Fa>
            </Button>
        );
    }

    toggleCollapse = collapseID => () =>

        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));

    renderSideTable() {
        let highlight = this.state.highlightedRow.id ? this.state.highlightedRow.id : this.state.highlightedRow.orderId;
        let style = {
            cursor: "pointer",
        };
        let highlightStyle = {
            backgroundColor: "#d4e6f7",
            cursor: "pointer",
        };
        const TableRow = ({ row, ...restProps }) => (
            <Table.Row
                { ...restProps }
                onClick={ () => {
                    if (this.state.canEditBasicInfo || this.state.canEditInsuranceInfo) {
                        this.setState({ patientInfoRetrieved: false, canEditBasicInfo: false, canEditInsuranceInfo: false,})
                        return OrderService.getPatientById(this.state.id)
                            .then((data) => {
                                this.setState({
                                    patientAge: moment().diff(data.dateOfBirth, 'years', false),
                                    patient: data,
                                    ePatient: JSON.parse(JSON.stringify(data)),
                                    TabsOneActiveItem: "1",
                                    canEditBasicInfo: false,
                                    patientInfoRetrieved: true,
                                });
                                this.highlightOrderRow(row.id);
                            });
                    }
                    this.highlightOrderRow(row.id);
                } }
                style={ row.id === highlight ? highlightStyle : style }
            />
        );

        if (this.state.isLoaded === true) {
            return (
                <Grid
                    href={ "TEST" }
                    className="sideCardOrderTableRow"
                    style={ { maxWidth: "1800px !important" } }
                    rows={ this.state.sideCardGridData.rows }
                    columns={ this.state.sideCardGridData.columns }
                    spacing={ 0 }

                >
                    <Table rowComponent={ TableRow } />
                </Grid>
            );
        } else {
            return <div />;
        }
    }

    setExpandedRowIds = expandedRowIds => {
        let value = expandedRowIds.length>0 ? [expandedRowIds.pop()] : []
        this.setState({ expandedRowIds: value });
    };

    checkForDefaultExpandedRow(rows) {
        if (this.state.firstLoad) {

            let val = rows.findIndex(x => x.tickets.length > 0);
            if (val >-1) {
                this.setExpandedRowIds([val]);
                this.setState({firstLoad: false});
                return[val];
            }else{
                this.setState({firstLoad: false});
                return this.state.expandedRowIds
            }
        } else {
            return this.state.expandedRowIds
        }
    }

    renderStatusChip(row){
        let r = row;
        if (r.status.toLowerCase() === "in process") {
            var chipColor = "#FFF5DE";
            var chipBorderColor = "#FBC02D51";
            var chipFontColor = "#75570D";
        } else if (r.status.toLowerCase() === "cancelled") {
            chipColor = "#FFE9E9";
            chipBorderColor = "#F9315432";
            chipFontColor = "#881A2D";
        } else if (r.status.toLowerCase() === "ready to deliver") {
            chipColor = "#E5F9E4";
            chipBorderColor = "#52B48F32";
            chipFontColor = "#195D17";
        } else if (r.status.toLowerCase() === "setup") {
            chipColor = "#F2f2f2";
            chipBorderColor = "#8D8D8D32";
            chipFontColor = "#5a5a5a";
        } else if (r.status.toLowerCase() === "new") {
            chipColor = "#E3EBFA";
            chipBorderColor = "#316BCF32";
            chipFontColor = "#316BCF";
        } else {
            chipColor = "#FFF";
            chipBorderColor = "#00032";
            chipFontColor = "#000";
        }
        return(
            <>
            <MDBCol  className="statusChipPatientTable" >

                    { r.status.toLowerCase() === "setup" ? (
                        <div className={ "chipRow orderStatusReasonChip" }
                            style={ { backgroundColor: chipColor, color: chipFontColor, border: `1px solid ${ chipBorderColor }`, cursor: "pointer" } }>
                            { r.reason && r.reason !== null ? (
                                <>
                                    <p className="ticketStatusChipRow">
                                        Delivered
                                        {r.setupDate && r.setupDate !== null ? ": " + globalFunctions.format(r.setupDate, { month: "2-digit", day: "2-digit", year: "2-digit" }) : "" }
                                    </p>
                                    <br />
                                    <p className="ticketReasonChipRow">{ r.reason || "" }</p>
                                </>
                            ) :
                                (
                                    <><p className="ticketStatusChipRow">Delivered
                                        {r.setupDate && r.setupDate !== null ? ": " + globalFunctions.format(r.setupDate, { month: "2-digit", day: "2-digit", year: "2-digit" }) : "" }</p></>
                                ) }

                        </div>
                    )
                        : (
                            <div style={ { cursor: "pointer", color: chipFontColor, backgroundColor: chipColor, border: `1px solid ${ chipBorderColor }` } } className={ "chipRow orderStatusReasonChip" } >
                                { r.reason && r.reason !== null ? (<><p className="ticketStatusChipRow">{ r.status }</p><br /><p className="ticketReasonChipRow">{ r.reason || "" }</p></>) : (<p className="ticketStatusChipRow">{ r.status }</p>) }
                            </div>
                        )}
            </MDBCol>
            <ReactTooltip/>
            </>
        )
    }

    createTicketRowData(parentData, tickets){
        let data = [];
        tickets.forEach(d => {
            data.push({
                padding: null,
                blank: this.renderTicketStatusLabel(d),
                orderIdLink: d.id,
                orderId: d.id,
                id:d.id,
                statusChip: this.renderTicketStatusChip(d),
                category: (d.category || "No Parts Added"),
                points: null,
                setupDate: d.setupDate && d.setupDate !== null ? globalFunctions.format(d.setupDate, { month: "2-digit", day: "2-digit", year: "2-digit" }) : null,
                lastNoteDate: d && d.lastNoteDate && d.lastNoteDate !== null ? moment(d.lastNoteDate).format("MM/DD/YY") : null,
                feedback: this.sortFeedback(d, "ticket"),
                tickets: null,
                status: d.status,
                reason: d.reason,
                serial: d.serial,
                currentModel: parentData.product,
                serialNumber: parentData.serial,
                parentOrder: d.parentOrder,
                serviceReason: d.serviceReason,
                orderType: d.orderType,
                isApproved: d.isApproved,
                orderStatusReasonId: d.orderStatusReasonId,
                routingInformation: null,
            });

        });
        return data;
    }
    renderTicketStatusLabel(r){
        return(
            <MDBCol>
                <MDBRow>
                    <td className="serviceTicketLabel">{r.orderType}</td>
                </MDBRow>
                <MDBRow>
                    <td className="serviceReasonLabel">{r.serviceReason}</td>
                </MDBRow>

            </MDBCol>
        )
    }
    renderTicketStatusChip(r){
        if (r.status.toLowerCase() === "in process") {
            var chipColor = "#FFF5DE";
            var chipBorderColor = "#FBC02D51";
            var chipFontColor = "#75570D";
        } else if (r.status.toLowerCase() === "cancelled") {
            chipColor = "#FFE9E9";
            chipBorderColor = "#F9315432";
            chipFontColor = "#881A2D";
        } else if (r.status.toLowerCase() === "ready to deliver") {
            chipColor = "#E5F9E4";
            chipBorderColor = "#52B48F32";
            chipFontColor = "#195D17";
        } else if (r.status.toLowerCase() === "setup") {
            chipColor = "#F2f2f2";
            chipBorderColor = "#8D8D8D32";
            chipFontColor = "#5a5a5a";
        } else if (r.status.toLowerCase() === "new") {
            chipColor = "#E3EBFA";
            chipBorderColor = "#316BCF32";
            chipFontColor = "#316BCF";
        } else {
            chipColor = "#FFF";
            chipBorderColor = "#00032";
            chipFontColor = "#000";
        }
        return(
            <div className="ticketStatusChip">
                {r.status.toLowerCase() === "setup" ? (
                    <div className={"chipRow ticketStatusReasonChip"} style={{ backgroundColor: chipColor, color: chipFontColor, border: `1px solid ${chipBorderColor}`, cursor: "pointer" }}>
                        {r.reason && r.reason !== null ? (
                            <>
                                <p className="ticketStatusChipRow">
                                    Delivered
                                    {r.setupDate && r.setupDate !== null ? ": " + globalFunctions.format(r.setupDate, { month: "2-digit", day: "2-digit", year: "2-digit" }) : ""}
                                </p>
                                <br />
                                <p className="ticketReasonChipRow">{r.reason || ""}</p>
                            </>
                        ) :
                            (
                                <><p className="ticketStatusChipRow">Delivered
                                    {r.setupDate && r.setupDate !== null ? ": " + globalFunctions.format(r.setupDate, { month: "2-digit", day: "2-digit", year: "2-digit" }) : ""}</p></>
                            )}
                    </div>

                )
                    : (
                        <div className="chipRow ticketStatusReasonChip"
                            style={{ cursor: "pointer", color: chipFontColor, backgroundColor: chipColor, border: `1px solid ${chipBorderColor}` }}>
                            {r.reason && r.reason !== null ? (<><p className="ticketStatusChipRow">{r.status}</p><br /><p className="ticketReasonChipRow">{r.reason || ""}</p></>) : (<p className="ticketStatusChipRow">{r.status}</p>)}

                        </div>
                    )}
            </div>
        )
    }
    renderTable() {
        if (this.state.isLoaded === true) {
            if (!this.state.patientEquipmentLoaded) {
                return <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }><MDBSpinner></MDBSpinner></div>
            }
            let highlight = this.state.highlightedRow.orderId ? this.state.highlightedRow.orderId : this.state.highlightedRow.id;
            let rows = this.state.gridData.rows;
            let expandedRowIds = this.checkForDefaultExpandedRow(rows);
            let expandedOrderId = expandedRowIds.length>0 ? rows[expandedRowIds[0]].orderId : null;
            let style = {
            };
            let highlightStyle = {
                backgroundColor: "#d4e6f7",
            };
            let pageSize = 10;
            let cols = [{
                title: "",
                name: "padding",
            }]
            cols = cols.concat(this.state.gridData.columns);

            function findPage(current) {
                let index = rows.findIndex(x => x.orderId === highlight);
                if (index < 0) {
                    rows.forEach((row, idx) => {
                        let tempIndex = row.tickets.findIndex(x => x.orderId === highlight);
                        if (tempIndex > -1) {
                            index = idx
                        }
                    });
                }

                if (highlight && index > -1) {
                    return (Math.ceil((index + 1) / pageSize)) - 1
                }
                return current;
            };
            let widthsForTicketTable = this.getWidths();
            widthsForTicketTable = widthsForTicketTable.concat([
                { columnName: "padding", width: 48 }

            ])

            const TableRow = ({ row, ...restProps }) => (
                <Table.Row
                    { ...restProps }
                    onClick={(e) => {
                        if ((row.setupDate && row.setupDate !== null) || (row.orderType && row.orderType.toLowerCase() === "service") || (row.category && row.category.props && row.category.props.children && row.category.props.children[0] === "Service")) {
                            this.toggleTicketDetailsModal(row, row.orderId)
                            if ((row.orderType && row.orderType.toLowerCase() === "service") ||(row.category.props && row.category.props.children && row.category.props.children[0] === "Service")){
                                this.setState({
                                    isOrderDetails: false,
                                })
                            }else{
                                this.setState({
                                    isOrderDetails: true,
                                })
                            }

                        } else {
                                this.props.history.push({
                                    pathname: "/order/" + row.orderId,
                                    results: this.state.results,
                                });
                        }
                    }
                    }
                    className="gridRowOrder"
                    style={ (row.orderId === highlight || expandedOrderId === row.orderId) ? highlightStyle : style }
                    id={row.orderId.toString()}

                />
            );
            const TableTicketRow = ({ row, ...restProps }) => (
                <Table.Row
                    {...restProps}
                     onClick = { () => this.toggleTicketDetailsModal(row, row.orderId) }
                    className="gridRowTicket"
                    style={(row.orderId === highlight || expandedOrderId === row.orderId) ? highlightStyle : style}
                    id={row.orderId.toString()}

                />
            );
            const RowDetail = ({ row }) => (
                <MDBRow className="serviceTicketsTableRow">
                        <Grid
                            style={{ maxWidth: "600px !important" }}
                            rows={row.ticketRows}
                            columns={cols}
                        >
                        <Table rowComponent={TableTicketRow} />
                            <TableColumnResizing
                                columnWidths={widthsForTicketTable}
                            />
                        </Grid>
                </MDBRow>

            );

            const ToggleCell = ({ expanded, onToggle, ...restProps }) => {
                const { row } = restProps;
                return row.tickets && !row.tickets.length > 0 ? (
                    <Table.Cell {...restProps}
                        onClick={ () => this.toggleTicketDetailsModal(row, row.orderId) }
                        style={ {
                            cursor: "pointer",

                        }}
                    />
                ) : (
                    <TableRowDetail.ToggleCell
                        { ...restProps }
                        onToggle={ onToggle }
                        expanded={ expanded }
                        />
                );
            }


            return (
                <Grid
                    className="orderTableRow"
                    style={ { maxWidth: "600px !important" } }
                    rows={ this.state.gridData.rows }
                    columns={ this.state.gridData.columns }
                >
                    <RowDetailState
                        expandedRowIds={ expandedRowIds }
                        onExpandedRowIdsChange={ this.setExpandedRowIds }
                    />
                    <PagingState defaultCurrentPage={ 0 } pageSize={ pageSize }
                        currentPage={ findPage(this.currentPage) }
                    />
                    <IntegratedPaging />
                    <Table rowComponent={ TableRow } />
                    <TableColumnResizing
                        columnWidths={ this.getWidths() }
                    />
                    <TableHeaderRow />
                    <TableRowDetail
                    id={"test"}
                        className={"serviceTicketsTableRow"}
                        contentComponent={ RowDetail }
                        toggleCellComponent={ ToggleCell }
                    />

                    <PagingPanel />
                </Grid>
            );
        } else {
            return <div />;
        }
    }


    renderGender() {
        let item = this.state.ePatient,
            types = [
                "Male",
                "Female",
                "Other"
            ];

        return (
            <MDBCol className="smallMargin">
                <TextField
                    select
                    size="small"
                    fullWidth
                    label="Gender"
                    value={ item.gender || "" }
                    disabled={ this.state.canEditBasicInfo ? false : true }
                    onChange={ this.handleChange.bind(this, "gender") }
                    variant="outlined"
                >
                    { types.map((option, idx) => {
                        return (
                            <MenuItem key={ idx } value={ types[idx] }>
                                { option }
                            </MenuItem>
                        );
                    }) }
                </TextField>
            </MDBCol>
        );
    }

    renderBasicInfo() {
        let item = this.state.ePatient,
            dob = item.dateOfBirth;

        if (dob) {
            dob = moment
                .utc(new Date(item.dateOfBirth), "MM-DD-YYYY")
                .format("MMMM DD, YYYY");
        }

        return (
            <Card className="patientInformationCard " >
                <label className="cardLabel">Basic Info</label>
                <div className="cardContent">
                    <MuiPickersUtilsProvider utils={ MomentUtils }>
                        <MDBRow>
                            <MDBCol style={ { paddingTop: 0 } }>
                                <MDBInput
                                    label="First Name"
                                    containerClass="smallMargin"
                                    style={ { paddingTop: 8 } }
                                    valueDefault={ item.firstName || "" }
                                    className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                    outline
                                    disabled={ this.state.canEditBasicInfo ? false : true }
                                    onChange={ this.handleChange.bind(this, "firstName") }
                                />
                            </MDBCol>
                            <MDBCol style={ { paddingTop: 0 } }>
                                <MDBInput
                                    label="Last Name"
                                    containerClass="smallMargin"
                                    style={ { paddingTop: 8 } }
                                    className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                    outline
                                    valueDefault={ item.lastName || "" }
                                    disabled={ this.state.canEditBasicInfo ? false : true }
                                    onChange={ this.handleChange.bind(this, "lastName") }
                                />
                            </MDBCol>
                            <MDBCol
                                size="3"
                                className="smallMargin uiDateOutline"
                                style={ { paddingTop: 0 } }
                            >
                                <DatePicker
                                    format="MM/DD/YYYY"
                                    size="small"
                                    style={ { zIndex: '0' } }
                                    inputVariant="outlined"
                                    label={ "Date of Birth" }
                                    emptyLabel={ "Date of Birth" }
                                    value={ item.dateOfBirth || null }
                                    disabled={ this.state.canEditBasicInfo ? false : true }
                                    onChange={ (date) => this.handleDatePickerChange("dateOfBirth", date) }
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol>
                                <MDBInput
                                    type="number"
                                    label="Height (Inches)"
                                    containerClass="smallMargin"
                                    style={ { paddingTop: 8 } }
                                    className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                    outline
                                    valueDefault={ item.height || "" }
                                    disabled={ this.state.canEditBasicInfo ? false : true }
                                    onChange={ this.handleChange.bind(this, "height") }
                                />
                            </MDBCol>
                            <MDBCol>
                                <MDBInput
                                    type="number"
                                    label="Weight (Pounds)"
                                    containerClass="smallMargin"
                                    style={ { paddingTop: 8 } }
                                    className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                    outline
                                    valueDefault={ item.weight || "" }
                                    disabled={ this.state.canEditBasicInfo ? false : true }
                                    onChange={ this.handleChange.bind(this, "weight") }
                                />
                            </MDBCol>
                            { this.renderGender() }
                        </MDBRow>

                        <MDBRow>
                            <MDBCol>
                                <div className="geocoder addressInputContainer">
                                    <MDBInput
                                        id="txtAddress"
                                        label="Address"
                                        containerClass="smallMargin"
                                        style={ { paddingTop: 8 } }
                                        className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                        outline
                                        value={ item.address || "" }
                                        disabled={ this.state.canEditBasicInfo ? false : true }
                                        //valueDefault={item.address || ""}
                                        onChange={ this.addressTextChanged.bind(this) }
                                    />
                                    { this.renderGoogleSearchResult() }
                                </div>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol>
                                <MDBInput
                                    id="txtCity"
                                    label="City"
                                    containerClass="smallMargin"
                                    style={ { paddingTop: 8 } }
                                    className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                    outline
                                    value={ item.city || "" }
                                    disabled={ this.state.canEditBasicInfo ? false : true }
                                    onChange={ this.handleChange.bind(this, "city") }
                                />
                            </MDBCol>
                            <MDBCol>
                                <Select
                                    placeholder={ item.state || "Select State..." }
                                    options={ this.getStateList() }
                                    onChange={ this.handleChange.bind(this, "state") }
                                    isDisabled={ this.state.canEditBasicInfo ? false : true }

                                />
                            </MDBCol>
                            <MDBCol>
                                <MDBInput
                                    id="txtZip"
                                    label="ZIP Code"
                                    containerClass="smallMargin"
                                    style={ { paddingTop: 8 } }
                                    className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                    outline
                                    value={ item.zipCode || "" }
                                    disabled={ this.state.canEditBasicInfo ? false : true }
                                    onChange={ this.handleChange.bind(this, "zipCode") }
                                />
                            </MDBCol>
                        </MDBRow>
                    </MuiPickersUtilsProvider>
                </div>
            </Card>
        );
    }

    renderDateOfBirth(item) {
        if (this.isPediatric(item.dateOfBirth))
            return (
                <DatePicker className={ "pediatricDate" }
                    format="MM/DD/YYYY"
                    size={ "small" }
                    inputVariant="outlined"
                    label={ "DOB - Pediatric" }
                    emptyLabel={ "Date of Birth" }
                    value={ item.dateOfBirth || null }
                    onChange={ (date) => this.handleDatePickerChange("dateOfBirth", date) }
                />)
        else
            return (
                <DatePicker
                    format="MM/DD/YYYY"
                    size={ "small" }
                    inputVariant="outlined"
                    label={ "Date of Birth" }
                    emptyLabel={ "Date of Birth" }
                    value={ item.dateOfBirth || null }
                    onChange={ (date) => this.handleDatePickerChange("dateOfBirth", date) }
                />)
    }

    isPediatric(dob) {
        let a = moment(new Date());
        let b = moment(dob);

        let y = a.diff(b, 'years');

        if (y < 18) {
            return true;
        }
        return false
    }

    renderPreferredContact() {
        let item = this.state.ePatient,
            types = [
                "SMS",
                "Call",
                "Email"
            ];

        return (
            <MDBCol className="smallMargin">
                <TextField
                    select
                    size="small"
                    fullWidth
                    disabled={ this.state.canEditBasicInfo ? false : true }
                    label="Preferred Contact Method"
                    value={ item.preferredContactMethod || "" }
                    onChange={ this.handleChange.bind(this, "preferredContactMethod") }
                    variant="outlined"
                >
                    { types.map((option, idx) => {
                        return (
                            <MenuItem key={ idx } value={ types[idx] }>
                                { option }
                            </MenuItem>
                        );
                    }) }
                </TextField>
            </MDBCol>
        );
    }

    renderEmergencyRelationship() {
        let item = this.state.ePatient,
            types = [
                "Spouse/Partner",
                "Sibling",
                "Child",
                "Parent",
                "Aunt/Uncle",
                "Other"
            ];

        return (
            <TextField
                select
                size="small"
                fullWidth
                disabled={ this.state.canEditBasicInfo ? false : true }
                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                label="Emergency Contact Relationship"
                value={ item.emergencyRelationship || "" }
                onChange={ this.handleChange.bind(this, "emergencyRelationship") }
                variant="outlined"
            >
                { types.map((option, idx) => {
                    return (
                        <MenuItem key={ idx } value={ types[idx] }>
                            { option }
                        </MenuItem>
                    );
                }) }
            </TextField>
        );
    }

    renderContactInfo() {
        let item = this.state.ePatient;
        return (
            <Card
                className="patientInformationCard"
                style={ { paddingBottom: '4.5rem' } }
            >
                <div className="cardContent">
                    <label className="cardLabel contactLabel">Contact Info</label>
                    <MDBRow>
                        <MDBCol style={ { paddingTop: 0 } }>
                            <MDBInput
                                label="Phone Number"
                                containerClass="smallMargin"
                                style={ { paddingTop: 8 } }
                                valueDefault={ item.phone || "" }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                onChange={ this.handleChange.bind(this, "phone") }
                            />
                            <MDBInput
                                label="Alternative Phone"
                                containerClass="smallMargin"
                                style={ { paddingTop: 8 } }
                                valueDefault={ item.alternativePhone || "" }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                onChange={ this.handleChange.bind(this, "alternativePhone") }
                            />
                            <MDBInput
                                label="Email Address"
                                containerClass="smallMargin"
                                style={ { paddingTop: 8 } }
                                valueDefault={ item.email || "" }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                onChange={ this.handleChange.bind(this, "email") }
                            />
                            <MDBInput
                                label="Emergency Contact"
                                containerClass="smallMargin"
                                style={ { paddingTop: 8 } }
                                valueDefault={ item.emergencyContact || "" }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                onChange={ this.handleChange.bind(this, "emergencyContact") }
                            />
                            <MDBInput
                                label="Emergency Contact Phone Number"
                                containerClass="smallMargin"
                                style={ { paddingTop: 8 } }
                                valueDefault={ item.emergencyContactPhone || "" }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                onChange={ this.handleChange.bind(this, "emergencyContactPhone") }
                            />
                            { this.renderEmergencyRelationship() }
                        </MDBCol>
                    </MDBRow>
                </div>
            </Card>
        );
    }

    renderDiseaseInfo() {
        let item = this.state.ePatient;
        return (
            <Card
                className="patientInformationCard "
            >
                <div className="cardContent">
                    <label className="cardLabel diseaseLabel">Disease Info</label>
                    <MDBRow>
                        <MDBCol size="2.9" style={ { paddingTop: 5, paddingRight: 20 } }>
                            <MDBInput
                                label="Infectious Disease"
                                containerClass="smallMargin"
                                value={ item.hasInfectiousDisease || false }
                                checked={ item.hasInfectiousDisease || false }
                                data-tip="Priority"
                                type="checkbox"
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                onChange={ this.handleCheckboxChange.bind(this, "hasInfectiousDisease") }
                                filled
                                id="diseaseCheckbox"
                            />
                        </MDBCol>
                        <MDBCol style={ { paddingTop: 0 } }>
                            <MDBInput
                                label="Disease Notes"
                                containerClass="smallMargin"
                                style={ { paddingBottom: 8 } }
                                valueDefault={ item.diseaseNotes || "" }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                onChange={ this.handleChange.bind(this, "diseaseNotes") }
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
            </Card>
        );
    }

    renderClinicalInformation() {
        let st = this.state,
            patient = st.ePatient;

        return (
            <Card
                className="patientInformationCard "
            >
                <label className="cardLabel" >
                    Clinical Information
                </label>
                <div className="cardContent">
                    <ReactTooltip />
                    <Row>
                        <Col size={ "12" } className={ "insuranceSelects" } style={ { paddingTop: 5, paddingRight: 20 } }>
                            <MDBInput
                                label={ "Doctor Name" }
                                style={ { paddingTop: 8 } }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                valueDefault={ patient.ciName || "" }
                                onChange={ this.handleChange.bind(this, "ciName") }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col size={ "12" } className={ "insuranceSelects" }>
                            <MDBInput
                                label={ "Address" }
                                style={ { paddingTop: 8 } }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                valueDefault={ patient.ciAddress || "" }
                                onChange={
                                    this.handleChange.bind(this, "ciAddress")
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col size={ "6" } className={ "insuranceSelects" }>
                            <MDBInput
                                label={ "City" }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                style={ { paddingTop: 8 } }
                                valueDefault={ patient.ciCity || "" }
                                onChange={
                                    this.handleChange.bind(this, "ciCity")
                                }
                            />
                        </Col>
                        <Col size={ "6" } className={ "insuranceSelects" }>
                            <Select
                                placeholder={ patient.ciState || "Select State..." }
                                options={ this.getStateList() }
                                isDisabled={ this.state.canEditBasicInfo ? false : true }
                                onChange={ this.handleChange.bind(this, "ciState") }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col size={ "6" } className={ "insuranceSelects" }>
                            <MDBInput
                                label={ "Zip Code" }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                style={ { paddingTop: 8 } }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                valueDefault={ patient.ciZip || "" }
                                onChange={
                                    this.handleChange.bind(this, "ciZip")
                                }
                            />
                        </Col>
                        <Col size={ "6" } className={ "insuranceSelects" }>
                            <MDBInput
                                label={ "Phone Number" }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                style={ { paddingTop: 8 } }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                valueDefault={ patient.ciPhone || "" }
                                onChange={
                                    this.handleChange.bind(this, "ciPhone")
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col size={ "6" } className={ "insuranceSelects" }>
                            <MDBInput
                                label={ "Fax" }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                style={ { paddingTop: 8 } }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                valueDefault={ patient.ciFax || "" }
                                onChange={
                                    this.handleChange.bind(this, "ciFax")
                                }
                            />
                        </Col>
                        <Col size={ "6" } className={ "insuranceSelects" }>
                            <MDBInput
                                label={ "NPI" }
                                outline
                                disabled={ this.state.canEditBasicInfo ? false : true }
                                style={ { paddingTop: 8 } }
                                className={ this.state.canEditBasicInfo ? "" : "basicInfoDisabled" }
                                valueDefault={ patient.ciNpi || "" }
                                onChange={
                                    this.handleChange.bind(this, "ciNpi")
                                }
                            />
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }

    renderPatientInformation() {
        if(this.state.TabsOneActiveItem !== "2"){
            return
        }
        if (this.state.patientInfoRetrieved === false) {
            return <div style={ { display: 'flex', justifyContent: 'center',  minHeight: "100vh", paddingTop: "5vh"} }><MDBSpinner></MDBSpinner></div>
        }
        return (
            <div style={ this.state.canEditBasicInfo && !this.state.createServiceTicketModalOpen ? { minHeight: "100vh" } : {}}>
                <div>
                    { this.renderBasicInfo() }
                </div>
                <div style={ { display: "flex" } }>
                    <div style={ { minWidth: "60%" } }>
                        { this.renderClinicalInformation() }

                        { this.renderDiseaseInfo() }
                    </div>
                    <div style={ { minWidth: "40%" } }>
                        { this.renderContactInfo() }
                    </div>
                </div>
                <div style={ { padding: "1.5rem" } }>
                    <MDBBtnGroup>
                        <MDBBtn
                            color="gray"
                            style={ { marginRight: "1rem" } }
                            onClick={ () => {
                            this.setState({patientInfoRetrieved: false})
                                 return OrderService.getPatientById(this.state.id)
                                    .then((data) => {
                                        this.setState({
                                            patientAge: moment().diff(data.dateOfBirth, 'years', false),
                                            patient: data,
                                            ePatient: JSON.parse(JSON.stringify(data)),
                                            TabsOneActiveItem: "1",
                                            canEditBasicInfo: false,
                                            patientInfoRetrieved: true,
                                        }, () => { this.openOrder() });
                                    });
                            } }>
                            Cancel
                        </MDBBtn>
                        <MDBBtn
                            className="successButton"
                            onClick={ this.savePatient.bind(this) }
                        >
                            Update
                        </MDBBtn>
                    </MDBBtnGroup>
                </div>
            </div>
        )
    }

    renderPrimarySection() {
        let item = this.state.ePatient;
        const { insuranceTypes, insuranceSubTypes } = this.context;

        return (
            <div
                className="insuranceTypeContainer"
            >
                <label className="insuranceTypeLabel">Primary Insurance</label>
                <Dropdown className={ "insuranceDropdown" } >
                    <DropdownToggle className={ this.state.canEditInsuranceInfo ? "idDropdownToggle" : "idDropdownToggle basicInfoDisabled" } nav disabled={ this.state.canEditInsuranceInfo ? false : true }>
                        Payor Source
                        <span style={ { float: "right" } }>
                            { item.insuranceType ? item.insuranceType.name : "" }
                            &nbsp;
                            <Fa icon={ "angle-down" } />
                        </span>
                    </DropdownToggle>
                    <DropdownMenu basic>
                        <DropdownItem disabled>Select Payor Source</DropdownItem>
                        <DropdownItem divider />
                        { insuranceTypes.map((type, index) => {
                            return (
                                <DropdownItem
                                    key={ index }
                                    onClick={ this.changeInsuranceType.bind(this, type, false) }
                                >
                                    { type.name }
                                </DropdownItem>
                            );
                        }) }
                    </DropdownMenu>
                </Dropdown>
                <Dropdown className={ "insuranceDropdown" }>
                    <DropdownToggle className={ this.state.canEditInsuranceInfo ? "idDropdownToggle" : "idDropdownToggle basicInfoDisabled" } nav disabled={ this.state.canEditInsuranceInfo ? false : true }>
                        Insurance
                        <span style={ { float: "right" } }>
                            { item.insuranceSubType ? item.insuranceSubType.name : "" }
                            &nbsp;
                            <Fa icon={ "angle-down" } />
                        </span>
                    </DropdownToggle>
                    <DropdownMenu basic>
                        <DropdownItem disabled>Select Insurance</DropdownItem>
                        <DropdownItem divider />
                        { insuranceSubTypes.map((type, index) => {
                            let ePatient = this.state.ePatient;
                            if (
                                ePatient.insuranceType &&
                                type.insuranceType.id === ePatient.insuranceType.id
                            ) {
                                return (
                                    <DropdownItem
                                        key={ index }
                                        onClick={ this.changeInsuranceSubType.bind(
                                            this,
                                            type,
                                            false
                                        ) }
                                    >
                                        { type.name }{ " " }
                                    </DropdownItem>
                                );
                            }
                            return null;
                        }) }
                    </DropdownMenu>
                </Dropdown>
                <MDBRow>
                    <MDBCol style={ { paddingTop: 10 } }>
                        <Input
                            label={ "Primary Policy Number" }
                            outline
                            className={ this.state.canEditInsuranceInfo ? "" : "basicInfoDisabled" }
                            value={ item.insurancePolicy || "" }
                            disabled={ this.state.canEditInsuranceInfo ? false : true }
                            onChange={ this.handleChange.bind(this, "insurancePolicy") }
                        />
                        <Input
                            label={ "Primary Group Number" }
                            outline
                            className={ this.state.canEditInsuranceInfo ? "" : "basicInfoDisabled" }
                            value={ item.insuranceGroup || "" }
                            disabled={ this.state.canEditInsuranceInfo ? false : true }
                            onChange={ this.handleChange.bind(this, "insuranceGroup") }
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

    renderSecondarySection() {
        let item = this.state.ePatient;
        const { insuranceTypes, insuranceSubTypes } = this.context;

        return (
            <div
                className="insuranceTypeContainer"
            >
                <label className="insuranceTypeLabel">Secondary Insurance</label>

                <Dropdown className={ "insuranceDropdown" }>
                    <DropdownToggle className={ this.state.canEditInsuranceInfo ? "idDropdownToggle" : "idDropdownToggle basicInfoDisabled" } nav disabled={ this.state.canEditInsuranceInfo ? false : true }>
                        Secondary Payor Source
                        <span style={ { float: "right" } }>
                            { item.secondaryInsuranceType
                                ? item.secondaryInsuranceType.name
                                : "" }
                            &nbsp;
                            <Fa icon={ "angle-down" } />
                        </span>
                    </DropdownToggle>
                    <DropdownMenu basic>
                        <DropdownItem disabled>Secondary Payor Source</DropdownItem>
                        <DropdownItem divider />
                        { insuranceTypes.map((type, index) => {
                            return (
                                <DropdownItem
                                    key={ index }
                                    onClick={ this.changeInsuranceType.bind(this, type, true) }
                                >
                                    { type.name }{ " " }
                                </DropdownItem>
                            );
                        }) }
                    </DropdownMenu>
                </Dropdown>

                <Dropdown className={ "insuranceDropdown" }>
                    <DropdownToggle className={ this.state.canEditInsuranceInfo ? "idDropdownToggle" : "idDropdownToggle basicInfoDisabled" } nav disabled={ this.state.canEditInsuranceInfo ? false : true }>
                        Secondary Insurance
                        <span style={ { float: "right" } }>
                            { item.secondaryInsuranceSubType
                                ? item.secondaryInsuranceSubType.name
                                : "" }
                            &nbsp;
                            <Fa icon={ "angle-down" } />
                        </span>
                    </DropdownToggle>
                    <DropdownMenu basic>
                        <DropdownItem disabled>Secondary Insurance</DropdownItem>
                        <DropdownItem divider />
                        { insuranceSubTypes.map((type, index) => {
                            let ePatient = this.state.ePatient;
                            if (
                                ePatient.secondaryInsuranceType &&
                                type.insuranceType.id === ePatient.secondaryInsuranceType.id
                            ) {
                                return (
                                    <DropdownItem
                                        key={ index }
                                        onClick={ this.changeInsuranceSubType.bind(
                                            this,
                                            type,
                                            true
                                        ) }
                                    >
                                        { type.name }{ " " }
                                    </DropdownItem>
                                );
                            }
                            return null;
                        }) }
                    </DropdownMenu>
                </Dropdown>

                <MDBRow>
                    <MDBCol style={ { paddingTop: 10 } }>
                        <Input
                            label={ "Secondary Policy Number" }
                            value={ item.secondaryInsurancePolicy || "" }
                            disabled={ this.state.canEditInsuranceInfo ? false : true }
                            outline
                            className={ this.state.canEditInsuranceInfo ? "" : "basicInfoDisabled" }
                            onChange={ this.handleChange.bind(
                                this,
                                "secondaryInsurancePolicy"
                            ) }
                        />
                        <Input
                            label={ "Secondary Group Number" }
                            value={ item.secondaryInsuranceGroup || "" }
                            disabled={ this.state.canEditInsuranceInfo ? false : true }
                            className={ this.state.canEditInsuranceInfo ? "" : "basicInfoDisabled" }
                            outline
                            onChange={ this.handleChange.bind(this, "secondaryInsuranceGroup") }
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

    openOrder() {
        let id = this.state.shouldOpenOrderId;
        if (!id) {
            return
        }
        this.highlightOrderRow(id, "service")
    }
    renderInsuranceCard() {
        let openOrders = []
        if (this.state.patientEquipmentLoaded && this.state.openAndNotApproved && this.state.openAndNotApproved !== null) {
            this.state.openAndNotApproved.forEach((o) => openOrders.push(o))
            const sortByCurOrder = open => {
                const sorter = (a, b) => {
                    if (a.id !== this.state.curOrderId && b.id === this.state.curOrderId) {
                        return 1;
                    };
                    if (a.id === this.state.curOrderId && b.id !== this.state.curOrderId) {
                        return -1;
                    }
                    return b['id'] - a['id']
                }
                open.sort(sorter)
            }
            sortByCurOrder(openOrders)
        }

        if(!this.state.createServiceTicketModalOpen && this.state.TabsOneActiveItem !== "3" ){
            return <div/>
        }

        if (this.state.patientInfoRetrieved === false) {
            return <div style={ { display: 'flex', justifyContent: 'center', minHeight: "100vh", paddingTop: "5vh" } }><MDBSpinner></MDBSpinner></div>
        }

        let item = this.state.ePatient;

        return (
            <div style={!this.state.createServiceTicketModalOpen ? { minHeight: "100vh" } : {} }>
                <Card className="patientInformationCard ">
                    <label className="cardLabel">Insurance</label>
                    {this.state.canEditInsuranceInfo && this.state.createServiceTicketModalOpen ?
                        <div className="insuranceContainer">
                            <MDBRow>
                                <MDBCol md="6">
                                    {this.renderPrimarySection()}
                                </MDBCol>
                                <MDBCol md="6">
                                    {this.renderSecondarySection()}
                                </MDBCol>
                                <MDBCol md="5"></MDBCol>
                            </MDBRow>
                            {/*<MDBBtn*/}
                            {/*    className="verifyBtn verifyBtnTicketModal"*/}
                            {/*    data-tip="Submit to pVerify"*/}
                            {/*    disabled={ disable }*/}
                            {/*    onClick={ () => this.submitChangesToPverify() }*/}
                            {/*>*/}
                            {/*    Verify*/}
                            {/*</MDBBtn>*/}
                            <ReactTooltip/>
                        </div>
                            :
                            <div className="insuranceContainer">
                                {this.renderPrimarySection()}
                                {this.renderSecondarySection()}
                            </div>
                            }
                </Card>
                { this.state.createServiceTicketModalOpen ? null : (
                    <div>
                        <Card
                            className="patientInformationCard"
                        >
                            <div className="cardContent">
                                <label className="cardLabel mbiLabel">MBI</label>
                                <Input
                                    label={ "MBI" }
                                    outline
                                    disabled={ this.state.canEditInsuranceInfo ? false : true }
                                    className={ this.state.canEditInsuranceInfo ? "" : "basicInfoDisabled" }
                                    value={ item.mbi || "" }
                                    onChange={ this.handleChange.bind(
                                        this,
                                        "mbi"
                                    ) }
                                />
                            </div>
                        </Card >

                        { this.state.curOrder && this.state.curOrder.isApproved === true ? (
                            <MDBContainer>
                                <MUIAlert severity="warning" sx={ { width: '100%', margin: "1.5rem 0rem 0rem" } }>Ticket has been approved and insurance can no longer be updated.</MUIAlert>
                            </MDBContainer>
                        ) : (
                                this.state.openAndNotApproved && this.state.openAndNotApproved !== null && this.state.openAndNotApproved.length > 0 && this.state.openAndNotApproved && this.state.openAndNotApproved !== null && !this.state.createServiceTicketModalOpen ?
                                    (
                                        <MDBContainer>
                                            <MUIAlert severity="info" sx={ { width: '100%', margin: "1.5rem 0rem 0rem" } }>
                                                <MUIAlertTitle>Changes to the insurance will affect the following open tickets:</MUIAlertTitle>
                                                { openOrders.map((o) => <span style={ this.state.curOrderId && this.state.curOrderId === o.id ? { color: MUILightBlueColor[700] } : {}}>#{ o.id } ({ o.orderType }) { o.status.props.label }{ o.reason ? ": " + o.reason : null} {this.state.curOrderId && this.state.curOrderId === o.id ? <b>(Current Ticket)</b> : ""} <br /></span>) }
                                            </MUIAlert>
                                        </MDBContainer>
                                    ) : null
                        )}

                        <div style={ { padding: "1.5rem" } }>
                            <MDBBtnGroup>
                                <MDBBtn
                                    color="gray"
                                    style={ { marginRight: "1rem" } }
                                    onClick={ () => {
                                        this.setState({ patientInfoRetrieved: false })
                                        return OrderService.getPatientById(this.state.id)
                                            .then((data) => {
                                                this.setState({
                                                    patientAge: moment().diff(data.dateOfBirth, 'years', false),
                                                    patient: data,
                                                    ePatient: JSON.parse(JSON.stringify(data)),
                                                    TabsOneActiveItem: "1",
                                                    canEditInsuranceInfo: false,
                                                    patientInfoRetrieved: true,
                                                }, () => { this.openOrder() });
                                            });
                                    } }>
                                    Cancel
                                </MDBBtn>
                                <MDBBtn
                                    style={ { marginRight: "1rem" } }
                                    className="successButton"
                                    disabled={this.state.curOrder && this.state.curOrder.isApproved === true ? true : false}
                                    onClick={ () => {
                                        if (this.state.openAndNotApproved && this.state.openAndNotApproved !== null && this.state.openAndNotApproved.length > 0) {
                                            this.setState({
                                                setDialogOpen: true,
                                            })
                                        } else {
                                            this.savePatient(this)
                                        }
                                    } }
                                >
                                    Update
                                </MDBBtn>
                                <ReactTooltip />
                            </MDBBtnGroup>
                        </div>
                    </div >
                ) }
            </div>
        );
    }

    addABNProduct(row) {
        let o = this.state.eOrder;
        let r = row;
        let abn = o.abnProductOther;

        if (!abn) {
            abn = ""
        }

        let abnArr = abn ? abn.split(",") : "";
        let cat = r.product.replace(/,/g, ""),
            sub = r.equipment.replace(/,/g, "");
        let exIdx = abnArr.indexOf(cat + " " + sub);

        try {
            if (abn.length === 0) {
                o.abnProductOther = cat + " " + sub;
            } else if (exIdx > -1) {
                abnArr.splice(exIdx, 1);
                o.abnProductOther = abnArr.toString();
            } else {
                abnArr.push(cat + " " + sub);
                o.abnProductOther = abnArr.toString();
            }
        } catch (e) {
            o.abnProductOther = cat + " " + sub;
        }

        o.abnProductOther = o.abnProductOther.substring(0, 254);
        this.setState({
            eOrder: o,
        });
    }
    addABNProductNew(row) {
        let o = this.state.eOrder;
        let abn = o.abnProductOther;

        if (!abn) {
            abn = ""
        }

        let abnArr = abn ? abn.split(",") : "";
        // let cat = r.product.replace(/,/g, ""),
        //     sub = r.equipment.replace(/,/g, "");
        let exIdx = abnArr.indexOf(row.text);

        try {
            if (abn.length === 0) {
                o.abnProductOther = row.text;
            } else if (exIdx > -1) {
                abnArr.splice(exIdx, 1);
                o.abnProductOther = abnArr.toString();
            } else {
                abnArr.push(row.text);
                o.abnProductOther = abnArr.toString();
            }
        } catch (e) {
            o.abnProductOther = row.text;
        }
        this.setState({
            eOrder: o,
        });
    }
    turnPartsIntoABNList(){
        let products = this.state.serviceOrderProducts;
        let abnProductArray = [];
        products.forEach((prod, idx)=>{
            let cat = prod.name.replace(/,/g, ""),
                sub = (prod.hcpcs ? prod.hcpcs.replace(/,/g, "") : "");
            abnProductArray.push({ text: cat + " " + sub,
        index: idx,
        abnRequired: this.checkForABNRequired(cat + " " + sub)
        })
        })
        return abnProductArray;
    }

    checkForABNRequired(prodName){
        let o = this.state.eOrder;
        if(!o || !o.abnProductOther){
            return false
        }
        let abn = o.abnProductOther;
        let abnArr = abn ? abn.split(",") : "";
        let exIdx = abnArr.indexOf(prodName);
        if(exIdx > -1){
            return true
        }
        return false


    }


    renderABNProductListAllParts() {
        if (this.state.abnModal) {
            let o = this.state.eOrder;
            if (!o.abnProductOther) {
                o.abnProductOther = ""
            }
            let arr = this.turnPartsIntoABNList();
            return arr.map((p,idx) => {
                return (
                    <MDBRow>
                    <MDBCol md={8} className="abnPatientInfoParts">
                        {p.text}
                    </MDBCol>
                    <MDBCol md={4} className="abnPatientInfoCheckBoxCol">
                            <MDBInput
                                className="abnPatientInfoCheckBox"
                                label="ABN Required"
                                containerClass="smallMargin"
                                value={this.checkForABNRequired(p.text)}
                                checked={this.checkForABNRequired(p.text)}
                                data-tip="ABN Required"
                                type="checkbox"
                                // disabled={this.state.canEditBasicInfo ? false : true}
                                onChange={()=>this.addABNProductNew(p)}
                                filled
                                id={"abnCheckBox"+idx}
                            />
                    </MDBCol>
                    </MDBRow>
                );
            });
        }
    }
    renderABNProductList() {
        if (this.state.abnModal) {
            let o = this.state.eOrder;
            if (!o.abnProductOther) {
                o.abnProductOther = ""
            }
            let arr = o.abnProductOther.split(",");
            return arr.map((p) => {
                return (
                    <MDBListGroupItem className="winter-neva-gradient">
                        {p}
                    </MDBListGroupItem>
                );
            });
        }
    }
    handleABNModalChange(property, e) {
        let o = this.state.eOrder;
        if (property === "abnAmount") {
            o[property] = parseFloat(e.target.value);
        } else {
            o.orderActivityApprovals[0].approvals[property] = e.target.value;
        }
        this.setState({
            eOrder: o,
        });
    }

    toggleABNModal = () => {
        this.setState({
            abnModal: !this.state.abnModal,
        });
    };

    renderAbnModal() {
        let o = this.state.eOrder;
        if (!this.state.abnModal) {
            return
        }

        return (
            <MDBModal className="salesPilotModal" isOpen={this.state.abnModal} toggle={() => this.toggleABNModal()} >
                <MDBModalHeader>
                    ABN
                </MDBModalHeader>
                <MDBModalBody >
                    <div>
                        {this.renderABNProductListAllParts()}
                        <MDBInput
                        className="abnAmount"
                        type="number"
                            label="ABN Amount"
                            outline
                            value={
                                o.abnAmount === null
                                    ? ""
                                    : o.abnAmount.toString()
                            }
                            onChange={this.handleABNModalChange.bind(
                                this,
                                "abnAmount"
                            )}
                        />
                        <label className="grey-text">ABN Required Reason</label>
                        <textarea
                            id="abnreason"
                            className="form-control"
                            rows="3"
                            value={
                                o.orderActivityApprovals &&
                                    o.orderActivityApprovals.length > 0 &&
                                    o.orderActivityApprovals[0].approvals &&
                                    o.orderActivityApprovals[0].approvals
                                        .abnRequiredNote
                                    ? o.orderActivityApprovals[0].approvals
                                        .abnRequiredNote
                                    : ""
                            }
                            onChange={this.handleABNModalChange.bind(
                                this,
                                "abnRequiredNote"
                            )}
                        />
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn
                        color="mdb-color"
                        onClick={() => this.toggleABNModal()}
                    >
                        Close
                    </MDBBtn>
                    <MDBBtn
                        className="successButton"
                        onClick={() => this.saveABN()}
                    >
                        Save
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        );
    }

    saveABN(){
        if (!this.state.eOrder.abnProductOther){
            this.toggleABNModal();
            this.updateOrder(this.state.eOrder, true)
        }else{
            let abnNote = this.state.eOrder.orderActivityApprovals[0].approvals.abnRequiredNote,
                abnAmount = this.state.eOrder.abnAmount;
            if ((abnNote && abnNote !== "") && abnAmount) {
                this.toggleABNModal();
                this.updateOrder(this.state.eOrder, true);

            } else {
               return toast.warn("ABN Amount And Note Required");
            }
        }
    }

    renderTabNav() {
        const { companyName } = this.context;
        let approval = this.state.appApproval;
        let btPId = this.state.ePatient.btPId;

        let breadCrumbs = [{ title: "Home", path: "/dashboard" }, { title: "Patients", path: "/patientList" },
        { title: this.state.patient.firstName + " " + this.state.patient.lastName, path: "" }]
        let arrayOfTabs = [];
        let arrayOfActionItems = [
            <DropdownItem key={3} onClick={ this.toggleOrderModal.bind(this) }>Add New Order</DropdownItem>,
            <DropdownItem key={4} onClick={ () => this.setState({ createPatientNoteModal: true }) }>Add A Patient Note</DropdownItem>,
            this.renderOpenParentOrderOption(),
             this.state.TabsOneActiveItem === "4" ? null : (<DropdownItem key={5} onClick={ () => this.setState({ createComplaintModal: true, addFeedbackFromActions: true }) }>Add Patient Feedback</DropdownItem>),
            <DropdownItem key={6} onClick={ this.clientCSV.bind(this) }>Download Patient Feedback</DropdownItem>,
            approval && approval.length > 0 ? <DropdownItem key={7} onClick={ () => { this.setState({ isChangingPassword: true }); } }>Change App Password</DropdownItem> : null,
            !btPId && companyName !== "corkmedical" ? <DropdownItem key={8} onClick={ () => this.syncPatientToBT() }>Sync Patient To BT</DropdownItem> : null,
           this.renderAddDeliveryNoteDropdown(),
            this.renderCancel(),
            this.renderAddAdditionalParts(),
           this.state.ticketRecords.length > 0 ? this.renderToggleTimelineOption() : null,
            this.state.ticketRecords.length > 0 ? this.renderReopenTicketOption() : null,
            this.renderPaymentInformationOption(),
            this.renderSearchOrderStatusDialogOption(),
        ];

        return (
            <PageHeader tabNames={ arrayOfTabs } TabsOneActiveItem={ this.state.TabsOneActiveItem } toggle={ this.toggle } breadCrumbs={ breadCrumbs } actionItems={ arrayOfActionItems }></PageHeader>
        );
    }

    toggleDeliveryNoteModal = () => {
        this.setState({
            deliveryNoteModalOpen: !this.state.deliveryNoteModalOpen,
        });
    };

    createDeliveryNote() {
        let o = this.state.eOrder;

        return OrderService.updateOrder(o)
            .then((res) => {
                this.toggleDeliveryNoteModal()
                toast.success("Delivery note saved successfully!");
            })
            .catch((err) => {
                console.log(err);
                toast.error("There was an error saving delivery note.");
            })
    }


    setDeliveryNote(prop, val) {
        let eOrder = this.state.eOrder;

        if (eOrder.hasOwnProperty(prop)) {
            eOrder[prop] = val;
        }

        this.setState({ eOrder: eOrder });
    }

    renderDeliveryNoteModal() {
        if (!this.state.deliveryNoteModalOpen) { return }
        let order = this.state.eOrder;
        let o = null;
        let deliveryNotes = null;


        if (this.state.orderRetrieved && order && order !== null) {
            o = order
            if (o.deliveryNotes && o.deliveryNotes !== null) {
                deliveryNotes = o.deliveryNotes
            }
        }


        return (
            <div>
                <MDBModal
                    centered
                    className="salesPilotModal"
                    size={ "lg" }
                    isOpen={ this.state.deliveryNoteModalOpen }
                    toggle={ () => this.toggleDeliveryNoteModal() }
                >
                    <MDBModalHeader>Delivery Notes</MDBModalHeader>
                    <MDBModalBody>
                        <MDBInput
                            id="newDeliveryNoteText"
                            outline
                            placeholder="Add delivery note text here."
                            type={ "textarea" }
                            label={ "Delivery Notes" }
                            style={ { paddingTop: 8, minHeight: '20vh'} }
                            valueDefault={ deliveryNotes }
                            onChange={ (e) =>
                                this.setDeliveryNote("deliveryNotes", e.target.value)
                            }
                        />
                    </MDBModalBody>
                    <MDBModalFooter>

                        <MDBBtn
                            size="sm"
                            color="red"
                            data-tip="Close"
                            floating
                            onClick={ () => this.toggleDeliveryNoteModal() }
                        >
                            <MDBIcon icon="times" />
                        </MDBBtn>
                        <MDBBtn
                            size="sm"
                            floating
                            color="green"
                            onClick={ () => this.createDeliveryNote() }
                        >
                            <MDBIcon icon="check" />
                        </MDBBtn>
                    </MDBModalFooter>
                    <ReactTooltip />
                </MDBModal>
            </div>
        );
    }

    renderOpenParentOrderOption() {
        if (this.state.TabsOneActiveItem !== "4" || this.state.isOrderDetails || !this.state.curOrder || !this.state.curOrder.parentOrder) {
            return null
        }

        return (
            <DropdownItem key={15} onClick={() => {
                this.props.history.push({
                    pathname: "/order/" + this.state.curOrder.parentOrder,
                });
            } }>Open Parent Order</DropdownItem>
        )
    }


    renderCancel(){
        const { currentUser } = this.context;
        let r = currentUser.role;
        let disable = false;
        if (
            r === "OFFICE_MANAGER" ||
            r === "AREA_MANAGER" ||
            r === "TECHNICIAN"
        ) {
            disable = true;
        }
        if(this.state.TabsOneActiveItem !== "4" || this.state.isOrderDetails || !this.state.curOrder){
            return null
        }

        if (this.state.curOrder && this.state.curOrder !== null) {
            if ((this.state.curOrder.status && this.state.curOrder.status !== null && this.state.curOrder.status.toLowerCase() === "ready to deliver" && disable) ||
                (this.state.curOrder.status && this.state.curOrder.status !== null && this.state.curOrder.status.toLowerCase() === "cancelled")) {
                return null
            }
        }

        return(
            <DropdownItem key={1} onClick={ () => { this.setState({ cancelTicketModal: true }); } }>Cancel Ticket</DropdownItem>
        )
    }

    renderAddAdditionalParts(){
        if(this.state.TabsOneActiveItem !== "4" || this.state.isOrderDetails || !this.state.curOrder ||this.state.curOrder.orderType !== "Service"
            || (this.state.curOrder.reason !== "Approval" && this.state.curOrder.reason !== "Obtain Documentation" && this.state.curOrder.reason !== "Service - Approval" && this.state.curOrder.reason !== "Service - Obtain Documentation") ){
            return null
        }
        return(
            <DropdownItem key={2} onClick={ () => { this.setState({ addAdditionalPartsModal: true }); } }>Add Additional Parts</DropdownItem>
        )
    }

    renderAddDeliveryNoteDropdown() {
        if (this.state.TabsOneActiveItem !== "4") {
            return null
        }
        return (
            <DropdownItem key={ 9 } onClick={ () => this.toggleDeliveryNoteModal() }>View Or Add Delivery Note</DropdownItem>
        )
    }

    renderReopenTicketOption() {
        if (this.state.TabsOneActiveItem !== "4" || this.state.isOrderDetails || !this.state.curOrder) {
            return null
        }

        if (this.state.curOrder && this.state.curOrder !== null) {
            if (this.state.curOrder.status && this.state.curOrder.status !== null && this.state.curOrder.status.toLowerCase() !== "cancelled") {
                return null
            }
        }
        return (
            <DropdownItem key={12} onClick={() => this.setState({reopenCancelledTicketModal: true})}>Re-Open Ticket</DropdownItem>
        )
    }

    renderToggleTimelineOption() {
        if (this.state.TabsOneActiveItem !== "4" || this.state.isOrderDetails || !this.state.curOrder) {
            return null
        }

        return (
            <DropdownItem key={ 14 } onClick={ () => this.toggleTimelineModalPopup() }>Toggle Timeline View</DropdownItem>
        )
    }

    renderPaymentInformationOption() {
        if (this.state.TabsOneActiveItem !== "4" || this.state.isOrderDetails || !this.state.curOrder) {
            return null
        }
        return (
            <DropdownItem key={13} onClick={() => this.setState({paymentInformationModal: true})}>Payment Information</DropdownItem>
        )
    }

    renderSearchOrderStatusDialog() {
        if(this.state.searchOrderStatusReasonDialogOpen){
            const handleAdd = (orderStatusReason) => {
                this.statusChanged(orderStatusReason.orderStatus);
                this.updateReason(orderStatusReason.id);
                this.saveStatusReasonChange();
            }
            return (
            <DialogSearchOrderStatusReason
                open={this.state.searchOrderStatusReasonDialogOpen}
                onClose={() => this.setState({ searchOrderStatusReasonDialogOpen: false })}
                onSelect={handleAdd}
            />)
        }
        else{
            return null
        }
    }

    renderSearchOrderStatusDialogOption() {
        const { currentUser } = this.context;
        let adminRole = "ADMIN";

        if (this.state.TabsOneActiveItem !== "4" && this.state.isOrderDetails && !this.state.curOrder && !currentUser.role === adminRole) {
            return null
        }
        return (
            <DropdownItem key={16} onClick={() => this.setState({searchOrderStatusReasonDialogOpen: true})}>Manual Route</DropdownItem>
        )
    }

    changeServiceLocation(loc) {
        let sl;

        if (loc == null) {
            sl = null;
        } else {
            sl = {
                id: loc.id,
                name: loc.name,
            };
        }
        this.setState({ serviceLocation: sl });
    }

    changeLocation(val, type) {
        let o = null;

        if (val) {
            o = {
                name: val.name,
                id: val.id,
            };
        }

        this.setState({ [type]: o });
    }

    changeByRef(prop, val) {
        let o = null;

        if (val) {
            o = {
                name: val.name,
                id: val.id,
            };
        }

        this.setAndUpdate(prop, o);
    }

    filterReps() {
        const { salesReps } = this.context;
        let eOrder = this.state.eOrder;
        //let reps = this.state.salesReps || salesReps;

        if (salesReps && salesReps.length > 0) {
            let copy = salesReps.filter(x => {
                if (x.userprofileLocations && x.userprofileLocations.length > 0 &&
                    x.userprofileLocations.filter(y => y.locationsId === eOrder.salesLocationId).length > 0) {
                    return x;
                }
                return null;
            });

            let idex = salesReps.find((rep) => {
                return rep.id === eOrder.activity.owner.id || rep.id === eOrder.activity.ownerId;
            });

            if (idex && !copy.includes(idex)) {
                copy.push(idex);
            }

            if (!idex) {
                copy.push(eOrder.activity.owner);
                idex = eOrder.activity.owner;
            }

            this.setState({
                filteredReps: copy,
                selectedRep: idex,
                salesReps: salesReps,
            })
        }
        else {

            this.setState({
                filteredReps: [eOrder.activity.owner],
                selectedRep: eOrder.activity.owner,
                //salesReps: salesReps,
            })
        }
    }


     renderTicketSalesLocationDropdown() {
        const { internalLocations } = this.context;
        let eOrder = this.state.eOrder;

        let index = internalLocations.find((loc) => {
            if (this.state.orderRetrieved && eOrder && eOrder !== null && eOrder.salesLocation) {
                return loc.id === eOrder.salesLocation.id;
            } else if (this.state.orderRetrieved && eOrder && eOrder !== null && eOrder.salesLocationId) {
                return loc.id === eOrder.salesLocationId;
            } else {
                return null;
            }
        });

        return (
            <MUIGrid item sm="6" xs="12">
                <MUITextField
                    fullWidth
                    select={ true }
                    label={ "Sales Location" }
                    InputLabelProps={ { shrink: true } }
                    value={ index ? index : ""}
                    onChange={ (e) => {
                        this.changeByRef("salesLocation", e.target.value);
                        this.setAndUpdate("salesLocationId", e.target.value.id);
                        //this.getSalesRepsByLocation();
                        this.filterReps();
                    } }
                    variant="standard"
                >
                    { internalLocations.map((option, idx) => {
                        return (
                            <MUIMenuItem key={ idx } value={option}>
                                { option.name }
                            </MUIMenuItem>
                        );
                    }) }
                </MUITextField>
            </MUIGrid>
        );
    }

    renderTicketServiceLocationDropdown() {
        const { internalLocations } = this.context;
        let eOrder = this.state.eOrder;

        let index = internalLocations.find((loc) => {
            if (this.state.orderRetrieved && eOrder && eOrder !== null && eOrder.serviceLocation) {
                return loc.id === eOrder.serviceLocation.id;
            } else if (this.state.orderRetrieved && eOrder && eOrder !== null && eOrder.serviceLocationId) {
                return loc.id === eOrder.serviceLocationId;
            } else {
                return null;
            }
        });

        return (
            <MUIGrid item sm="6" xs="12">
                <MUITextField
                    fullWidth
                    select={ true }
                    label="Service Location"
                    InputLabelProps={ { shrink: true } }
                    value={ index ? index : "" }
                    onChange={ (e) => {
                        this.changeByRef("serviceLocation", e.target.value);
                        this.setAndUpdate("serviceLocationId", e.target.value.id);
                    } }
                    variant="standard"
                >
                    { internalLocations.map((option, idx) => {
                        return (
                            <MUIMenuItem key={ idx } value={ option }>
                                { option.name }
                            </MUIMenuItem>
                        );
                    }) }
                </MUITextField>
            </MUIGrid>
        );
    }

    renderSalesLocationDropdown() {
        const { internalLocations, salesReps } = this.context;

        let salesRep = this.state.accountRecentSalesRep || this.state.accountOwner;
        let ary = internalLocations,
            index = null;
        let rep = salesReps.find((r) => {
            return r.id === salesRep.id;
        });

        if (rep && rep.userprofileLocations && rep.userprofileLocations.length > 0) {
            ary = rep.userprofileLocations.map(x => x.locations);
        }
        if (this.state.salesLocation) {
            index = ary.find((loc) => {
                return loc.id === this.state.salesLocation.id
            })
        }
        if (index && !this.state.salesLocation) {
            this.changeLocation(index, "salesLocation")
        }

        return (
            <Col size="6">
                <div className={ "uiOutlines" }>
                    <TextField
                        id="Sales-Location"
                        select
                        size="small"
                        fullWidth
                        label="Sales Location"
                        value={ index ? index : "" }
                        onChange={ (e) =>
                            this.changeLocation(e.target.value, "salesLocation")
                        }
                        variant="outlined"
                    >
                        { ary.map((option, idx) => {
                            return (
                                <MenuItem key={ idx } value={ ary[idx] }>
                                    { option.name }
                                </MenuItem>
                            );
                        }) }
                    </TextField>
                </div>
            </Col>
        );
    }

    renderServiceLocationDropdown() {
        const { internalLocations, salesReps } = this.context;
        let salesRep = this.state.accountRecentSalesRep || this.state.accountOwner;

        let ary = internalLocations,
            curr = this.state.serviceLocation,
            index = null;
        let rep = salesReps.find((r) => {
            return r.id === salesRep.id;
        });

        if (curr) {
            index = ary.find((il) => {
                return il.id === curr.id;
            });
        } else {
            if (rep && rep.userprofileLocations && rep.userprofileLocations.length > 0) {
                index = ary.find((il) => {
                    return il.id === rep.userprofileLocations[0].locationsId;
                });
            }
        }
        if (index && !this.state.serviceLocation) {
            this.changeLocation(index, "serviceLocation")
        }

        return (
            <Col size="6">
                <div className={ "uiOutlines" }>
                    <TextField
                        id="Service-Location"
                        select
                        size="small"
                        fullWidth
                        label="Service Location"
                        value={ index ? index : "" }
                        onChange={ (e) =>
                            this.changeLocation(e.target.value, "serviceLocation")
                        }
                        variant="outlined"
                    >
                        { ary.map((option, idx) => {
                            return (
                                <MenuItem key={ idx } value={ ary[idx] }>
                                    { option.name }
                                </MenuItem>
                            );
                        }) }
                    </TextField>
                </div>
            </Col>
        );
    }

    renderPatientProducts() {
        let st = this.state;
        let products = st.patientProducts;
        if (this.state.productsForOrderId && (this.state.productsForOrderId !== this.state.curOrder.id)) {
            this.sortPatientProductsForOrder(products);
        }
        if (!st.patientProductsLoaded) {
            this.getPatientProducts();
                return (
                    <Col>
                        <div style={ { margin: "auto", display: "table" } }>
                            <p>
                                { st.patient.firstName + " " + st.patient.lastName }'s Products
                            </p>
                            <div
                                style={ { margin: "auto", display: "table" } }
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </Col>
                );
        }
        return (
            <Col style={{ marginTop: "5%" }}>
                <label>Patient's Products</label>
                <MDBSelect
                    outline
                    color="default"
                    style={{ maxHeight: "100px", marginTop: "1px" }}
                    options={this.state.productsForOrder}
                    label
                    getValue={(e) => this.handleProductChange(e)}
                />
            </Col>
        );
    }

    sortPatientProductsForOrder(orders) {
        if (this.state.curOrder.id === this.state.productsForOrderId) {
            return
        }
        let products = orders;
        let productsForOrder = [];

        products.forEach((prod) => {
            if (prod.orderId === this.state.curOrder.id) {
                productsForOrder.push(prod)
            }
        })
        if (productsForOrder.length < 1) {
            this.setState({
                productsForOrder: [],
                productsForOrderId: this.state.curOrderId
            })
            return
        }

        let highestPoints = productsForOrder.reduce(function (prev, current) {
            return (prev && prev.points > current.points) ? prev : current
        })
        let defaultProd = productsForOrder.findIndex(prod => prod.value.id === highestPoints.value.id)

        if (this.state.productsAddedList.length < 1) {
            this.setState({
                productsAddedList: [productsForOrder[defaultProd].value],
            })
        }
        productsForOrder[defaultProd].checked = true;
        this.setState({
            productsForOrder: productsForOrder,
            productsForOrderId: this.state.curOrderId
        })
    }
    getPatientProducts() {
        if(this.state.patientProductsLoading){
            return
        }
        this.setState({patientProductsLoading: true})
        let arr = [];
        return PatientOrders.getPatientOrders(this.state.id)
            .then((res) => {
                if (res.length > 0) {
                    res.map(resp => {
                        if (resp.orderType === "New" && resp.orderActivityProducts && resp.orderActivityProducts.length > 0) {
                            resp.orderActivityProducts.map((pr) => {
                                if (pr.products) {
                                    let s = pr.products.serial ? " - " + pr.products.serial : "";
                                    return arr.push({
                                        text: (pr.products.productType ? pr.products.productType.name : "") + " - " + (pr.products.productSubType ? pr.products.productSubType.name : "") + s,
                                        value: pr.products,
                                        orderId: resp.id,
                                        points: pr.products.points
                                    })
                                }
                                return null;
                            });
                        }

                        return null;
                    });
                } else {
                    arr.push({
                        text: "No products found",
                        value: "product",
                        disabled: true,
                    });
                    this.setState({
                        patientProducts: arr,
                        patientProductsLoaded: true,
                    });

                }

                this.setState({
                    patientProducts: arr,
                    patientProductsLoaded: true,
                }, this.sortPatientProductsForOrder(arr));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleProductChange(product) {
        if ((!product || product.length === 0) && (this.state.productsAddedList.length === 0)) {
            return
        }
        this.setState({
            productsAddedList: product,
        });
    }

    getCategoryList() {
        const { productTypes } = this.context;
        let a = [];
        a.push({
            label: "Category...",
            value: "",
        });
        productTypes.map((productType) => {
            return a.push({
                label: productType.name,
                value: productType,
            });
        });
        return a;
    }

    getProductList() {
        const { productSubTypes } = this.context;
        let a = [];
        let cc = this.state.chosenCategory;
        if (cc && Object.keys(cc).length !== 0) {
            productSubTypes.map((product) => {
                if (product.productType.name === cc.name) {
                    let h = product.hcpcs !== null ? " | " + product.hcpcs : "";
                    let l = product.name + h;
                    return a.push({
                        label: l,
                        value: product,
                    });
                }
                return null;
            });
        } else {
            productSubTypes.map((product) => {
                let h = product.hcpcs !== null ? " | " + product.hcpcs : "";
                let l = product.productType.name + " | " + product.name + h;
                return a.push({
                    label: l,
                    value: product,
                });
            });
        }
        return a;
    }

    handleProductSelectChange = (e) => {
        delete e.value.deleted;
        delete e.value.deletedById;
        delete e.value.deletedAt;
        this.addNewProduct(e.value);
    };

    addNewProduct = (productAdded) => {
        const { currentUser, productTypes, productOverrides } = this.context;
        let o = this.state.nOrder,
            np = {
                abnRequired: false,
                approved: false,
                hcpcs: productAdded.hcpcs || "",
                productSubTypeId: productAdded.id,
                productSubType: productAdded,
                productTypeId: productAdded.productType.id,
                productType: {
                    id: productAdded.productType.id,
                    name: productAdded.productType.name,
                },
                points: 0.0,
                qty: 1,
                serial: "",
                amount: 0,
                updatedOn: new Date().toISOString(),
                updatedById: currentUser.id,
                updatedBy: { id: currentUser.id, name: currentUser.username }
            };

        let found = false,
            insuranceID = this.state.patient.insuranceType.id,
            insuranceMatrix = [],
            correctProduct;
        let productAddedID = productAdded.productType.id;

        for (let p of productTypes) {
            if (p.id === productAddedID) {
                correctProduct = p;
                break;
            }
        }

        if (productAdded && productAdded.overridePoints && productAdded.overridePoints.length > 0) {
            let f = productOverrides.filter((pro) => {
                return (
                    pro.insuranceTypeId === insuranceID &&
                    pro.productSubTypeId === productAdded.id
                );
            });
            if (f.length > 0) {
                np.points = f[0].points;
                found = true;
            }
        }
        if (correctProduct && found === false && correctProduct.insuranceMatrixEntry && correctProduct.insuranceMatrixEntry.length > 0) {
            insuranceMatrix = correctProduct.insuranceMatrixEntry;
            for (let i of insuranceMatrix) {
                if (i.insuranceType.id === insuranceID) {
                    np.points = i.points;
                    break;
                }
            }
        }

        o.orderActivityProducts = [{ products: np }];
        this.setState({
            nOrder: o,
            productsAddedList: np,
        });

    };

    renderProductDropdown() {
        return (
            <Col size="6">
                <div className={ "uiOutlines" }>
                    <Select
                        placeholder={ "Product..." }
                        options={ this.getProductList() }
                        onChange={ this.handleProductSelectChange.bind(this) }
                    />
                </div>
            </Col>
        );
    }

    handleCategoryChange = (e) => {
        this.setState({
            chosenCategory: e.value,
        });
    };

    renderCategoryDropdown() {
        return (
            <Col size="6">
                <div className={ "uiOutlines" }>
                    <Select
                        placeholder={ "Category..." }
                        options={ this.getCategoryList() }
                        onChange={ this.handleCategoryChange.bind(this) }
                    />
                </div>
            </Col>
        );
    }

    renderCreateOrderRoute() {
        return (
            <Route
                render={ ({ history }) => (
                    <div>
                        <Button
                            disabled={ this.state.disableCreate }
                            color={ "success" }
                            rounded
                            size="sm"
                            data-tip={ "Create Order" }
                            onClick={ () => this.createOrder(history) }
                        >
                            <Fa size="2x" icon={ "check" }>
                                { " " }
                            </Fa>
                        </Button>
                    </div>
                ) }
            />
        );
    }

    renderPopUpComplaintBody(legalComplaint) {
        let c = [],
            complaints = this.state.popupComplaints || [];

        complaints.forEach((cc) => {
            c.push(cc.complaintDescription)
        })

        return (
            <div>
                <p>{ legalComplaint.length > 0 ? "Please send patient to compliance. This patient has a legal complaint on file: " : "This patient has an open complaint on file:" }</p>
                { c.map(complaint => (
                    <div>
                        <ul><li>{ complaint }</li></ul>
                    </div>
                )) }
            </div>
        );
    }

    removeProduct(idx) {
        let no = this.state.nOrder,
            op = no.orderActivityProducts;

        op.splice(idx, 1);
        this.setState({
            nOrder: no
        })
    }

    renderOrderProductsList() {
        let op = this.state.nOrder.orderActivityProducts ? this.state.nOrder.orderActivityProducts.map(x => x.products) : [];

        if (op.length === 0) {
            return;
        }

        return (
            <div style={ { paddingTop: 6 } }>
                <span style={ { fontWeight: "bold" } }> Products </span>

                <MDBRow>
                    { op.map((p, idx) => {
                        return (
                            <MDBCol size={ 12 } key={ idx }>
                                <span>{ p.productType.name }</span> &nbsp; &nbsp;
                                <span>{ p.productSubType.name }</span>
                                <MDBIcon style={ { color: 'red', float: 'right' } }
                                    icon={ "trash" }
                                    onClick={ () => this.removeProduct(idx) }
                                    data-tip={ "Remove" } />
                            </MDBCol>
                        )
                    }) }
                </MDBRow>
                <ReactTooltip />
            </div>
        )
    }

    renderNewOrderStuff() {
        if (this.state.selectedOrderTypeRadio === 0) {
            return (
                <MDBRow>
                    { this.renderCategoryDropdown() }
                    { this.renderProductDropdown() }
                </MDBRow>
            )
        }
    }

    renderOrderProducts() {
        if (this.state.selectedOrderTypeRadio === 0) {
            return this.renderOrderProductsList();
        }
    }

    handleRadioChange = (value, type) => () => {
        let rb = this.state.radio;
        rb[type] = value;


        this.setState({
            radio: rb,
            radioBtnSelected: true,
        });
    };

    handleRadioChangeQueueRoute(value, type) {
        let rb = this.state.radio;
        rb[type] = value.id || value;

        rb.queueRoutingSubType = ""

        this.setState({
            radio: rb,
            radioBtnSelected: true,
            selectedSubTypeFromQueueRoute: null,
        });
    };

    handleRadioChangeSubType(value, type) {
        let rb = this.state.radio;
        if (rb[type] === value) {
            rb[type] = ""
        } else {
            rb[type] = value;
        }


        this.setState({
            radio: rb,
            radioBtnSelected: true,
            serviceReason: value,
            selectedSubTypeFromQueueRoute: value
        });
    };

    setValue(value) {
        this.setState({
            createTicketNote: value.trimStart(),
        })
    }

    getIDs() {
        const { companyName } = this.context;
        if (companyName !== "corkmedical") {
            let arr = [];
            let products = this.state.serviceOrderProducts;
            if (products.length > 0) {
                products.forEach((product, index) => {
                    arr.push(this.getProductsDetails(product, index))
                })
            }
        }
    }

    getOrderProductsList() {
        if (!this.state.curOrderId) {
            return
        }
        if (this.state.servicePartsLoading || this.state.servicePartsLoaded) {
            return
        }
        this.setState({
            servicePartsLoading: true
        });
        OrderService.getOrderActivity(this.state.curOrderId)
            .then((ord) => {
                if (ord.id !== this.state.curOrderId) {
                    return
                }
                this.setState({
                    orderActivityFull: ord,
                    orderDetailProducts: ord.orderActivityProducts.map(x => x.products),
                });
                this.getServiceQuoteByOrder(this.state.curOrderId);

            })
            .catch((err) => {
                console.log(err);
            });
    }

    getServiceQuoteByOrder() {
        if (this.state.curOrderId == null || this.state.curOrderId === "") {
            return;
        }


        partsService.getServiceOrderProductsByOrder(this.state.curOrderId)
            .then((r) => {

                partsService
                    .getAllParts()
                    .then((r) => {
                        this.setState({
                            allServiceParts: r && r.length > 0 ? r.filter(x => x.deleted !== true) : [],
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                    });

                this.setState({
                    servicePartsLoading:false,
                    serviceOrderProducts: r,
                    orderRetrieved: true,
                    servicePartsLoaded: true
                })
                    this.getIDs();
                this.getApprovalsByOrder();

            })
            .catch((e) => {
                console.log(e);
                this.setState({});
            });
    }


    retrieveNotes(id) {
        let orderId = id ? id : this.state.curOrderId;
        this.setState({
            orderNotesLoading: true,
        })
        NoteService.getNotes(orderId)
            .then((notes) => {
                let no = notes;

                if (no === null || no.length === 0) {
                    //we have no notes, return...BC
                    this.setState({ orderNotes: [], orderNotesLoaded: true });
                    return;
                }
                //copy the notes array...BC
                let con = no.slice();
                //sort the notes...BC
                con.sort((a, b) => {
                    if (a.createdAt > b.createdAt) {
                        return -1;
                    } else {
                        return 1;
                    }
                });

                //set the state for notes...BC
                this.setState({
                    orderNotes: con,
                    orderNotesLoaded: true,
                    orderNotesLoading: false
                });
            })
            .catch(() => {
            });
    }

    getOrderProducts(id) {
        OrderService.getOrderProducts(id)
            .then(data => {
                this.setState({
                    orderProducts: data,
                    orderApprovalsLoaded: true,
                    orderApprovalsLoading: false,
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    getApprovalsByOrder(id) {
        let orderId = id ? id : this.state.curOrderId;
        this.setState({
            orderApprovalsLoading: true,
        })
        if (!this.state.orderRetrieved) {
            if (!this.state.servicePartsLoading) {
                this.getOrderProductsList();
            }
            return this.renderLoadingSpinner()
        }

        let data = this.state.orderActivityFull;

            this.changeLocation(data.salesLocation, "salesLocation")
            this.changeLocation(data.serviceLocation, "serviceLocation")
            this.setState({
                order: data,
                eOrder: JSON.parse(JSON.stringify(data)),
            }, () => {
                this.getOrderProducts(orderId);
            });

    }

    saveStatusReasonChange(addPart, noteToAdd) {
        if(addPart){
            // this.addPartForDeliveryAndAuthorize(addPart);
        }else{
        let obj = this.state.eOrder;

            this.updateOrder(obj, null, noteToAdd)
        }
    }

    updateParentObject(obj) {
        this.setState({
            eOrder: obj,
        });
    }

    renderApprovalInformation() {
        const { productTypes, productSubTypes } = this.context;

        if (!this.state.orderApprovalsLoading && !this.state.orderApprovalsLoaded && this.state.curOrderId) {
            this.setState({
                orderApprovalsLoading: true
            }, () => {
                this.getApprovalsByOrder()
            })

        }
        if (!this.state.orderApprovalsLoaded) {
            return <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }><MDBSpinner></MDBSpinner></div>
        }
        return (
            <ApprovalTab
                orderLoaded={ this.state.orderRetrieved }
                order={ this.state.eOrder }
                products={ this.state.orderProducts }
                productTypes={ productTypes || [] }
                productSubTypes={ productSubTypes || [] }
            />
        )
    }

    addNoteUpdateOrderCloseModal(note, order) {
    }

    toggleNoteModalPopup() {
        let currentState = this.state.noteModalOpen;

        // this.setState({
        //     this.state.patient.account.owner
        // })
        this.setState({ noteModalOpen: !currentState });
        if (currentState === true) {
            this.setState({
                noteText: "",
                isUpdating: false,
            });
        }
    }

    toggleProcessing() {
        this.setState({
            saveProcessStarted: true
        })
    }

    async generateApprovalNote(fromPartAdd, order, fromPickup) {
        return new Promise((res, rej) => {

            const { currentUser, productTypes, productSubTypes } = this.context;

            let st = this.state,
                noteText = "",
                listForPickup = st.orderDetailProducts,
                list = st.orderProducts.filter((p) => {
                    return p.approved;
                }),
                listService = st.serviceOrderProducts,
                cb = { id: currentUser.id, name: currentUser.username },
                currentNotes = st.notes,
                eOrder = st.eOrder,
                totalPoints = 0;


            if (order) {
                eOrder = order;
            }
            let appr = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null,
                preAuth = appr.authNumber ? appr.authNumber.trim() : null;


            if (fromPartAdd) {
                noteText =
                    currentUser.firstname +
                    " " +
                    currentUser.lastname +
                    " approved order " +
                    this.state.order.id +
                    " (Pre-Auth not required) on  " +
                    moment().format("MMMM Do YYYY, h:mm a") +
                    " for the following products : ";
            //TODO check if this was removed or ifit was forgotten
                eOrder.preAuthRequired = false;

            }
            else if (this.state.radio.preAuthAction === "approveWithoutAuth") {
                noteText =
                    currentUser.firstname +
                    " " +
                    currentUser.lastname +
                    " approved order " +
                    this.state.order.id +
                    " and sent to Pre-Auth follow up for authorization on " +
                    moment().format("MMMM Do YYYY, h:mm a") +
                    " for the following products : ";
                //   eOrder.preAuthRequired = true;

            } else if (this.state.radio.preAuthAction === "approveAuthNotRequired" || fromPickup) {
                noteText =
                    currentUser.firstname +
                    " " +
                    currentUser.lastname +
                    " approved order " +
                    this.state.order.id +
                    " (Pre-Auth not required) on " +
                    moment().format("MMMM Do YYYY, h:mm a") +
                    " for the following products : ";
            } else {
                noteText =
                    currentUser.firstname +
                    " " +
                    currentUser.lastname +
                    " approved order " +
                    this.state.order.id +
                    " with Auth Number " +
                    preAuth +
                    " on " +
                    moment().format("MMMM Do YYYY, h:mm a") +
                    " for the following products : ";
            }

            if (fromPartAdd && fromPartAdd.length > 0) {
                list = [];
                listService = [];
                list = fromPartAdd.map((product) => {
                    return product.products
                })

            }
            list.forEach((product) => {
                var pt = product.productTypeId ? productTypes.filter(x => x.id === product.productTypeId)[0].name || "" : "";
                var pst = product.productSubTypeId ? productSubTypes.filter(x => x.id === product.productSubTypeId)[0].name || "" : "";
                totalPoints += parseFloat(product.points);
                noteText += product.qty + " " + pt + " " + pst + " | ";
            });
            if (this.state.order && this.state.order.orderType === "Pickup") {
                if (listForPickup.length > 0) {
                    let products = [];
                    eOrder.orderActivityProducts.forEach((product) => {
                        product.products.approved = true;
                        products.push(product);
                    })
                    eOrder.orderActivityProducts = products;
                    listForPickup.forEach((product) => {
                        var pt = product.productType ? product.productType.name || "" : "";
                        var pst = product.productSubType ? product.productSubType.name || "" : "";
                        totalPoints += parseFloat(product.points);
                        noteText += product.qty + " " + pt + " " + pst + " | ";
                    });
                }
            } else if (listService.length > 0) {
                listService.forEach((product) => {
                    var pt = product.name ? product.name : "";
                    var pst = product.itemId ? product.itemId : "";
                    var hcpcs = product.hcpcs ? product.hcpcs : "";
                    totalPoints += parseFloat(product.points);
                    noteText += product.qty + " " + pt + " " + pst + " - " + hcpcs + " | ";
                });
            }

            let note = {
                type: "ActivityNote",
                text: noteText,
                createdAt: GlobalFunctions.getUTCMoment(),
                account: eOrder.activity.account,
                accountId: eOrder.activity.accountId,
                task: false,
                completed: true,
                createdBy: cb,
                createdById: cb.id,
                patientName: eOrder.patientName,
                activityId: eOrder.id,
            };

            NoteService.createActivityNote(eOrder.id, note)
                .then((res) => {
                    currentNotes.unshift(res);
                })
                .catch((err) => {
                    console.log(err);
                });

            //TODO investigate why this was setting status reasons
            //    if (!fromPartAdd){
            //        eOrder.status = 2; //"ReadyToDeliver";
            //        eOrder.orderStatusReason = null;
            //        eOrder.orderStatusReasonId = null;
            //    }
            //    if (eOrder.orderType.toLowerCase() === "pickup") {
            //        eOrder.orderStatusReasonId = 41;
            //    }

            if (!eOrder.approvalDate) {
                eOrder.approvalDate = new Date();
            }

            eOrder.points = totalPoints;
            eOrder.isApproved = true;
            this.setState({ eOrder: eOrder })
            if (!fromPartAdd && this.state.approvalModalOpen) {
                this.handleSubmitRouteChange("fromNote");
                this.toggleApprovalModalPopup();
            }
            res(true)
        })
    }

    renderOrderDetailsNotes() {
        if (!this.state.orderNotesLoading && !this.state.orderNotesLoaded && this.state.curOrderId) {
            this.retrieveNotes()
        }
        if (!this.state.orderNotesLoaded) {
            return <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }><MDBSpinner></MDBSpinner></div>
        }

        if (this.state.orderNotes.length === 0) {
            return (
                <div>
                    <MDBRow>
                        <MDBBtn
                            size="large"
                            className="addNoteBtnFull"
                            onClick={() => {
                                    this.setState({
                                        radio: {
                                            approvalAction: "",
                                            serviceTicketAction: "",
                                            pickupTicketAction: { label: "", value: "" }
                                        }
                                    })
                                this.toggleNoteModalPopup()
                            }}
                        >
                            Add Note
                        </MDBBtn>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <div style={{
                                display: "flex",
                                marginTop: '.5rem',
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                No Data
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>
            )
        }
        return (
            <div>
                <MDBRow>
                    <MDBBtn
                        size="large"
                        className="addNoteBtnFull"
                        disabled={!this.state.eOrder}
                        onClick={ () => {
                            this.setState({
                                radio: {
                                    approvalAction: "",
                                    serviceTicketAction: "",
                                    pickupTicketAction: { label: "", value: "" }
                                }
                            })
                            this.toggleNoteModalPopup()
                        } }
                    >
                        Add Note
                    </MDBBtn>
                </MDBRow>
                <NotesTab
                    getNotes={() =>
                        this.retrieveNotes(this.state.curOrderId)
                    }
                    allNotes={this.state.orderNotes}
                    eOrder={this.state.eOrder}
                    fullWidth={true}
                    toggleNoteModalPopup={() => this.toggleNoteModalPopup()}
                    isOrderDetails={true}
                />
            </div>
        )
    }

    renderAttachments() {
        if (!this.state.orderAttachmentsLoading && !this.state.orderAttachmentsLoaded && this.state.curOrderId) {
            return (
                    <AttachmentsTab
                        minimal={ true }
                        eOrder={ {
                            patient: this.state.patient,
                            id: this.state.curOrderId,
                            activity: { files: null }
                        } }
                        curOrder={ this.state.curOrder }
                        isOrderDetails={ true }
                        updateParent={ this.updateParentObject.bind(
                            this
                        ) }
                        savePatient={this.savePatient.bind(this)}
                    />
            )
        }
        if (!this.state.orderAttachmentsLoaded) {
            return <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }><MDBSpinner></MDBSpinner></div>
        }
    }

    togglePills = (type, tab) => e => {
        e.preventDefault();
        if (this.state.items[type] !== tab) {
            let items = { ...this.state.items };
            items[type] = tab;
            this.setState({
                items
            });
        }
    };
    async addPartForDeliveryAndAuthorize(val) {
        return new Promise((resu, rej) => {

            const { currentUser } = this.context;
            let id = null;
            if (val === "techSkilled") {
                //1963 Tech Skilled Labor
                id = 1963
            }
            else if (val === "30day") {
                //70  30 Day Fit for You
                id = 70
            } else if (val === "fieldService") {
                //1964 Field Service Diagnosis
                id = 1964
            }

            let newItem = {
                abnRequired: false,
                amount: 0,
                approved: true,
                hcpcs: "na",
                isRental: false,
                orderActivityProducts:
                    { orderActivityId: this.state.curOrder.id },

                points: 0,
                productSubTypeId: id,
                productTypeId: 27,
                qty: 1,
                serial: "",
                updatedById: currentUser.id,
                updatedOn: new Date().toISOString()
            }

            OrderService.updateOrderProducts(this.state.curOrder.id, [newItem]).then(res => {
                if (res) {
                    let order = this.state.eOrder;
                    OrderService.getOrderActivity(order.id).then(res => {
                        order.orderActivityProducts = res.orderActivityProducts;
                        this.generateApprovalNote(res.orderActivityProducts, order).then((result) => {
                            resu(result)
                        })

                        // this.updateOrder(order, true)
                    }).catch(err => {
                        console.log(err)
                    })
                }
                else {
                    toast.warn("There was an issue updating the products.")
                }
            })
                .catch(err => {
                    console.log(err);

                })

        })
    }

    setAndUpdate(prop, val) {
        if (val && val.toString() === "Invalid date") {
            val = null;
        }
        let eOrder = this.state.eOrder;

        if (eOrder.hasOwnProperty(prop)) {
            eOrder[prop] = val;
        }

        if (eOrder.activity.hasOwnProperty(prop)) {
            eOrder.activity[prop] = val;
        }

        if (eOrder.patient.hasOwnProperty(prop)) {
            eOrder.patient[prop] = val;
        }

        this.updateParentObject(eOrder);
    }


    updateReason(v) {
        const { reasons } = this.context;

        this.setState({
            statusOrReasonChange: true,
        })

        if (v) {
            let r = reasons.find((o) => {
                return v === o.id;
            });
            if (r) {
                let q = {
                    id: r.id,
                    description: r.description,
                };
                this.setAndUpdate("orderStatusReason", q);
                this.setAndUpdate("orderStatusReasonId", q.id);
            }
        } else {
            this.setAndUpdate("orderStatusReason", null);
            this.setAndUpdate("orderStatusReasonId", null);
        }
    }

    statusChanged(v) {
        this.setState({
            statusOrReasonChange: true,
        })

        let eOrder = this.state.eOrder;

        eOrder.orderStatusReason = null;
        eOrder.orderStatusReasonId = null;
        if (v === 3 && eOrder.setupDate === null) {
            eOrder.setupDate = new Date().toISOString();
        }

        this.setAndUpdate("status", v);
    }

    getProductsDetails(product, index) {
        const { companyName } = this.context;
        if (companyName !== "corkmedical") {
            let a = [];

            a.push({
                text: "N/A",
                value: "N/A",
            });

            if (product.hcpcs && product.hcpcs.length > 0) {
                BtProductService.getBtProductsByHcpcs(product.hcpcs)
                    .then((res) => {
                        res.map((t) => {
                            return a.push({
                                text: t,
                                value: t,
                                selected: res.length === 1
                            });
                        })

                        if (a.length < 1) {
                            a[0].selected = true;
                        }
                        let productList = this.state.idNames;
                        productList[index] = a;
                        this.setState({
                            idNames: productList
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                        return a.push({
                            text: "N/A",
                            value: "N/A",
                        });
                    });
            }
            else {
                let productList = this.state.idNames;
                productList[index] = a;
                this.setState({
                    idNames: productList
                })
            }
        }
    }
    renderABNButton() {
        let order = this.state.eOrder;

        if (!order || !order.orderStatusReason || !order.orderStatusReason.description || !this.state.serviceOrderProducts ||
            this.state.serviceOrderProducts.length < 1 || !order.orderStatusReason.description.toLowerCase().includes("approval")) {
            return null
        }

        return (
            <MDBRow style={ { padding: '0 1.6rem' }}>
                <MDBCol size={"3"}>
                    <MDBBtn
                        style={{ marginTop: '1rem' }}
                        size="sm"
                        className="addNoteBtnFull addPartsBtn"
                        onClick={() => this.setState({
                            abnModal: !this.state.abnModal,
                        })}
                      disabled={this.state.servicePartsLoading || (this.state.serviceOrderProducts && this.state.serviceOrderProducts.length<1)}
                    >
                        ABN Info
                    </MDBBtn>
            </MDBCol>
        </MDBRow>
        )
    }

    renderAddServicePartButton() {
        if (!this.state.curOrder || !this.state.curOrder.orderStatusReasonId || this.state.curOrder.orderStatusReasonId !== 72) { // 72==="service-cst-needpartnumber")
            return
        }
        if (this.state.createQuoteSmall){
            return (
                <MDBRow style={ { padding: '0 1.6rem' }}>
                <MDBCol size={"3"}>
                    <MDBBtn
                        style={{ marginTop: '1rem' }}
                        size="sm"
                        className="addNoteBtnFull addPartsBtn"
                        onClick={() => this.setState({
                            createQuoteSmall: !this.state.createQuoteSmall,
                        })}
                        disabled={this.state.servicePartsLoading}
                    >
                        Reopen Quote
                    </MDBBtn>
                </MDBCol>

              </MDBRow>
            )
        }
        return(
            <MDBRow style={ { padding: '0 1.6rem' } }>
                <MDBCol size={"10"}></MDBCol>
                <MDBCol>
                <MDBBtn
                    style={{marginTop: '1rem'}}
                    size="sm"
                    className="addNoteBtnFull addPartsBtn"
                    onClick={() => this.toggleAddServicePartsModal()}
                    disabled={this.state.servicePartsLoading || !this.state.baseUnitLoaded}
                >
                    Add Parts
                </MDBBtn>
                </MDBCol>
            </MDBRow>
        )
    }

    renderServiceTicketParts(){
        if (this.state.curOrder && this.state.curOrder.orderType && this.state.curOrder.orderType.toLowerCase() === "pickup") {
            return
        }

        if(!this.state.orderRetrieved){
            if(!this.state.servicePartsLoading){
                this.getOrderProductsList();
            }
            return this.renderLoadingSpinner()
        }
        return this.renderProductsList();
    }

    renderServiceTicketGenericParts() {
        if (!this.state.orderRetrieved) {
            return
            // return this.renderLoadingSpinner()
        }
        return this.renderGenericParts();
    }

    getMaxBillableForLineItem(itemId) {
        if (!this.state.allServiceParts) {
            return 0
        }
        if (itemId === 6128) { return 0 }
        // if (itemId === 1) { return 2 }
        let serviceParts = this.state.allServiceParts;
        let fullQuoteItems = [];

        let fullItem = serviceParts.find((part) => part.id === itemId);
        if (fullItem) {
            fullQuoteItems.push(fullItem)
        }
        let maxBillableLaborUnits = this.findMaxBillableUnits(fullQuoteItems);
        return maxBillableLaborUnits;
    }
    findMaxBillableUnits(items) {
        if (!items) { return }

        const { productSubTypes } = this.context;
        let totalMaxBill = 0;
        let hcpcs = items.map((item) => {
            return item.hcpc.replace(/\s/g, '')
        })
        let pairedHcpcsLabor = [];
        productSubTypes.forEach((subtype) => {
            if (hcpcs.includes(subtype.hcpcs)) {
                pairedHcpcsLabor.push({ hcpcs: subtype.hcpcs, laborUnits: subtype.maxBillableLaborUnits ? subtype.maxBillableLaborUnits : 0 })
            }
        })
        if (pairedHcpcsLabor.length < 1) { return 0 }

        let groupedHcpcs = Object.values(Object.groupBy(pairedHcpcsLabor, ({ hcpcs }) => hcpcs));

        groupedHcpcs.forEach((group) => {
            let frequency = {},
                max = 0;
            for (var v in group) {
                frequency[group[v].laborUnits] = (frequency[group[v].laborUnits] || 0) + 1;
                if (frequency[group[v].laborUnits] > max) {
                    max = frequency[group[v].laborUnits];
                }
            }
            totalMaxBill = totalMaxBill + parseInt(Object.keys(frequency).reduce((a, b) => frequency[a] > frequency[b] ? a : b));
        })

        return totalMaxBill;
    }
    checkIfLaborItem() {
        let p = this.state.serviceOrderProducts;
        let hasLabor = false;
        p.forEach((item) => {
            if (item.hcpcs === "K0739") {
                hasLabor = true
            }
        })
        return hasLabor
    }
    countLaborUnits() {
        let p = this.state.serviceOrderProducts;

        let labor = 0;
        p.forEach((item) => {
            labor = labor + this.getMaxBillableForLineItem(item.id);
        })
        return labor;
    }
    renderProductsList() {
        const { companyName } = this.context;
        let p = this.state.serviceOrderProducts;
        if (this.state.orderRetrieved && p && p.length > 0) {
            let hasLabor = this.checkIfLaborItem();
            let laborUnits = this.countLaborUnits();
            let displayLabor = 0;
            if (laborUnits < 2) {
                displayLabor = 2 - laborUnits
            } else (
                displayLabor = 0
            )
            return (
                <div>
                    <MDBRow className="addedParts">ADDED PARTS</MDBRow>
                    <MDBRow className="addedPartsHeaderRow" style={{fontWeight: "bold" }}>
                        <MDBCol className="addedPartsHeader partsHeaderNoPadding" size="5">{"Brightree Product"} </MDBCol>
                        <MDBCol className="addedPartsHeader partsHeaderNoPadding"  size="2">{companyName !== "corkmedical" ?  "HCPCS" : ""}</MDBCol>
                        <MDBCol className="addedPartsHeader partsHeaderNoPadding" size="2">{companyName !== "corkmedical" ? "Warranty" : ""}</MDBCol>
                        <MDBCol className="addedPartsHeader partsHeaderNoPadding" size="1">{companyName !== "corkmedical" ? "30Day" : ""}</MDBCol>
                        <MDBCol className="addedPartsHeader partsHeaderNoPadding" size="1">{companyName !== "corkmedical" ? "ABN" : ""}</MDBCol>

                        <MDBCol className="addedPartsHeader"  size="1">Qty </MDBCol>
                    </MDBRow>

                    {p.map((prod, idx) => {
                        if (prod.hcpcs === "K0739") {
                            return false
                        }
                        return (
                            <MDBRow key={idx} className={"eqRow"}>
                                <MDBCol size="5" className="partsHeaderNoPadding">
                                    {this.state.servicePartsLoaded  ? prod.name :""}
                                </MDBCol>
                                <MDBCol size="2" className="partsHeaderNoPadding">
                                    {companyName !== "corkmedical" ?
                                        (prod.hcpcs ? prod.hcpcs : "")
                                        : ''}
                                </MDBCol>
                                <MDBCol size="2" className="partsHeaderNoPadding">
                                    {companyName !== "corkmedical" ?
                                        (prod.warranty ? "Yes" : "" )
                                        : ''}
                                </MDBCol>
                                <MDBCol size="1" className="partsHeaderNoPadding">
                                    {prod.thirtyDayFit ? "Yes" : ""}
                                </MDBCol>
                                <MDBCol size="1" className="partsHeaderNoPadding">
                                    {prod.name ?( this.checkForABNRequired(prod.name.replace(/,/g, "") + " " + (prod.hcpcs ? prod.hcpcs.replace(/,/g, "") : "")) ? "Yes" : ""): ""}
                                </MDBCol>
                                <MDBCol size="1" className="partsHeaderNoPadding">{prod.qty}</MDBCol>
                            </MDBRow>
                        );
                    })}
                    {hasLabor ?
                        <>
                            <MDBRow className="addedPartsHeaderRow" style={{ textDecoration: "underline", fontWeight: "bold" }}>
                                <MDBCol className="addedPartsHeader partsHeaderNoPadding" size="5">{"Estimated Labor"} </MDBCol>
                                <MDBCol className="addedPartsHeader partsHeaderNoPadding" size="2">{"Duration"}</MDBCol>

                            </MDBRow>
                            {p.map((prod, idx) => {
                                if (prod.hcpcs !== "K0739") {
                                    return false
                                }
                                return (
                                    <MDBRow key={idx} className={"eqRow"}>
                                        <MDBCol size="5" className="partsHeaderNoPadding">
                                            {this.state.servicePartsLoaded ? prod.name : ""}
                                        </MDBCol>
                                        <MDBCol size="2" className="partsHeaderNoPadding">
                                            {this.state.servicePartsLoaded ? displayLabor * 15 + " Minutes" : ""}
                                        </MDBCol>
                                    </MDBRow>
                                );
                            })}
                        </> : null}

                    {/* {this.renderGenericParts()} */}
                </div>

            );
        } else {
            return
        }
    }

    renderGenericParts(){

        let curOrder = this.state.curOrder;
        let p = this.state.orderDetailProducts;
        if (this.state.orderRetrieved && p && p.length > 0) {
            return (
                <div>
                    <MDBRow className="addedParts">GENERIC PARTS</MDBRow>
                    <MDBRow className="addedPartsHeaderRow" style={{ fontWeight: "bold" }}>
                        <MDBCol className="addedPartsHeader" size="5">{"Product"} </MDBCol>
                        <MDBCol className="addedPartsHeader"  size="4">{ "HCPCS" }</MDBCol>
                        <MDBCol className="addedPartsHeader"  size="1">Qty </MDBCol>
                        <MDBCol className="addedPartsHeader patientRemoveEquipmentButton" size="2">{curOrder.orderType !== "Pickup" ? "Remove" : ""} </MDBCol>
                    </MDBRow>

                    {p.map((prod, idx) => {
                        return (
                            <MDBRow key={idx} className={"eqRow"}>
                                <MDBCol size="5" className="centerAlignCol">
                                    <MDBCol>
                                    {this.state.servicePartsLoaded  ?
                                            (prod.productType ? prod.productType.name : "") +
                                        " " + (prod.productSubType ? prod.productSubType.name : "") : ""}
                                        </MDBCol>
                                </MDBCol>
                                <MDBCol size="4" className="centerAlignCol">
                                    <MDBCol>
                                        {(prod.hcpcs ? prod.hcpcs : "noname")}
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol size="1" className="centerAlignCol" ><MDBCol className="patientRemoveEquipmentButton">
                                    {prod.qty}</MDBCol></MDBCol>
                                {curOrder.orderType !== "Pickup" ?
                                    <MDBCol size="2" className="centerAlignCol"><MDBCol className="patientRemoveEquipmentButton">
                                        {this.renderRemove(prod)}</MDBCol></MDBCol> : null}

                            </MDBRow>
                        );
                    })}
                </div>
            );
        } else {
            return
        }
    }

    renderRemove(product) {
        return (
            <div>
                <MDBIcon
                    icon={"times"}
                    size="sm"
                    style={{ color: "black" }}
                    data-tip={"Remove this product"}
                    onClick={() => this.handleProductRemove(product)}
                />
                <ReactTooltip />
            </div>
        );
    }

    handleProductRemove(product) {
        if (product.id && product.id > 0) {
            OrderService.removeOrderProduct(product.id)
                .then(res => {
                    if (res) {
                        // Remove the product from the state
                        this.setState({
                            orderDetailProducts: this.state.orderDetailProducts.filter(detailProduct => {
                                return detailProduct.id !== product.id;
                            })
                        });
                        toast.success("Product removed!");
                    }
                    else
                        toast.error("There was an issue deleting the product. If the issue persists, refresh the page and try again.");
                })
                .catch(err => {
                    console.log(err);
                });
            }
    }

    toggleApprovalModalPopup = () => {
        let currentState = this.state.approvalModalOpen;
        this.setState({
            approvalModalOpen: !currentState,
            approveAgreement: false,
        });
    };

    checkBoxApprovedChange = (event) => {
        this.setState({ approveAgreement: event.target.checked });
    };


    renderApprovalModal() {
        if(!this.state.approvalModalOpen){return}
        let order = this.state.eOrder;
        let patient = this.state.patient;
        let o = null;
        let appr = null;
        let preAuth = null;

        if (this.state.orderRetrieved && order && order !== null) {
            o = order
            appr = o.orderActivityApprovals[0] ? o.orderActivityApprovals[0].approvals : null
            preAuth = appr.authNumber ? appr.authNumber.trim() : null
        }

        if (!patient) {
            return;
        }

        return (
            <MDBModal
                className="salesPilotModal approvalModal"
                centered
                isOpen={ this.state.approvalModalOpen }
                toggle={ () => {
                    return;
                } }
            >
                  <MDBModalHeader
                        // className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>
                            Approve Order:{ " " }
                            { patient.firstName +
                                " " +
                                patient.lastName }
                        </h3>
                    </MDBModalHeader>
                <MDBModalBody>

                    {appr && preAuth ? (<p style={{ marginLeft: '3px' }}>Pre-Auth Number: <b>{preAuth}</b></p>) : null}
                    { appr && preAuth ? (<div align="left">
                        <Input
                            key={ o.id }
                            onChange={ this.checkBoxApprovedChange.bind(this) }
                            value={ this.state.approveAgreement }
                            label={ `I approve this order with Auth Number: ${ preAuth }` }
                            type="checkbox"
                            id="approveOrder"
                        />
                    </div>) : null }

                    { appr && !preAuth ? (<div align="left">
                        <Input
                            key={ o.id }
                            onClick={ this.handleRadioChange("approveAuthNotRequired", "preAuthAction") }
                            checked={ this.state.radio.preAuthAction === "approveAuthNotRequired" }
                            label="I approve this order and Pre-Auth is Not Required"
                            type="radio"
                            id="approveAuthNotRequired"
                        />
                        <Input
                            key={ o.id }
                            onClick={ this.handleRadioChange("approveWithoutAuth", "preAuthAction") }
                            checked={ this.state.radio.preAuthAction === "approveWithoutAuth" }
                            label="I approve this order and send to Pre-Auth follow up for authorization"
                            type="radio"
                            id="approveOrderWithoutAuth"
                        />
                    </div>) : null }
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn
                        color="mdb-color"
                        onClick={ () => {
                            this.setState({
                                isUpdating: false,
                            })
                            this.toggleApprovalModalPopup()
                        }
                        }
                    >
                        Close
                    </MDBBtn>
                    <MDBBtn
                        disabled={ this.state.approveAgreement || this.state.radio.preAuthAction === "approveWithoutAuth" || this.state.radio.preAuthAction === "approveAuthNotRequired" ? false : true }
                        className="successButton"
                        onClick={ () => this.generateApprovalNote()}
                    >
                        Approve
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        );
    }

    approvalAndUpdate(prop, val) {
        let eOrder = this.state.eOrder;

        eOrder.orderActivityApprovals[0].approvals[prop] = val;
        this.setState({ eOrder: eOrder });
        this.updateParentObject(eOrder);
    }

    handleDate(prop, e) {
        if (prop === "setupDate" || prop === "deliverByDate") {
            if (e) {
                e.set("hour", 12);
                e.set("minute", 0);
            }
        }

        let q = new moment.utc(e);
        let v = q.format("YYYY-MM-DDTHH:mm:ss");

        this.setAndUpdate(prop, v)
    }

    retrieveBaseUnit(id) {
        const { allBaseUnits, allVendors } = this.context;
        let baseUnit = allBaseUnits.find((b) => b.id === id)
        let vendor = allVendors.find((v) => v.id === baseUnit.vendorId)


        this.setState({
            currentBaseUnit: vendor.name + " " + baseUnit.name,
            baseUnitLoaded: true
         })
    }


    checkForAddPart() {
        let subType = this.state.radio.queueRoutingSubType;

        if (!subType) {
            return null
        }
        subType = subType.replace(/\s/g, '').toLowerCase();
        if (subType.includes("30dayfit4u")) {
            return "30day"
        } else if (subType.includes("fieldservicediagnosis")) {
            return "fieldService"
        } else if (subType.includes("techskilledlabor")) {
            return "techSkilled"
        } else {
            return null
        }
    }

    async addUnableToPickupNote() {
        const { currentUser } = this.context;
        let eOrder = this.state.eOrder;
        let cb = { id: currentUser.id, name: currentUser.username };
        let curOrder = this.state.curOrder;
        return new Promise((res, rej) => {
            let noteToAdd = {
                assignedToId: null,
                createdAt: globalFunctions.getUTCMoment(),
                createdBy: cb,
                createdById: cb.id,
                account: eOrder.account,
                accountId: eOrder.accountId,
                patientName: eOrder.patientName,
                type: "ActivityNote",
                activityId: curOrder.id,
                dueDate: null,
                completed: true,
                task: false,
                text: "Unable To Pick Up"
            };
            NoteService.createActivityNote(curOrder.id, noteToAdd)
                .then((result) => {
                    if (result) {
                        if (result.createdAt.indexOf("Z") > 0) {
                            result.createdAt = result.createdAt.split("Z")[0];
                        }
                        this.retrieveNotes(curOrder.id)
                        res(result)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        })
    }
    async routeBackToSender() {
        return new Promise((res, rej) => {
            const { reasons } = this.context;
            let orderStatuses = this.state.orderStatuses;
            let st = this.state;
            let reasonObj = null;
            let statusObj = null;
            let previousReason = st.ticketRecords;

            if (previousReason && previousReason[0].statusFrom && previousReason[0].statusFrom !== null) {
                statusObj = orderStatuses.filter((f) => f.label.toLowerCase().replace(/\s/g, '') === previousReason[0].statusFrom.toLowerCase().replace(/\s/g, ''))
                this.statusChanged(statusObj[0].value)
            }

            if (previousReason && previousReason[0].reasonFrom && previousReason[0].reasonFrom !== null) {
                reasonObj = reasons.filter((f) => f.description === previousReason[0].reasonFrom)
                this.updateReason(reasonObj[0].id)
            } else {
                this.updateReason()
            }

            this.saveStatusReasonChange()
            res(true)
        })
    }
    async handleQueueRouteTriggers(trigger, fromTrigger) {
        return new Promise((res, rej) => {
            if (fromTrigger === "fromNote") {
                res("continue")
                return
            }
            else if (!trigger || fromTrigger) {
                res(false)
            }
            else if (trigger === "addPartAndAuthorization") {
                this.addPartAndAuthorization().then((result) => {
                    res(result)
                });
            }
            else if (trigger === "toggleCreateNoteModal") {
                this.toggleNoteModalPopup()
                res("stop")
            }
            else if (trigger === "togglePreAuthModal") {
                this.toggleApprovalModalPopup()
                res("stop")
            }
            else if (trigger === "createApprovalNote") {
                this.generateApprovalNote(this.state.serviceOrderProducts).then((result) => {
                    res(result)
                })
            }
            else if (trigger === "addUnableToPickupNote") {
                this.addUnableToPickupNote().then((result) => {
                    res(result)
                })
            }
            else if (trigger === "routeBackToSender") {
                this.routeBackToSender().then((result) => {
                    res("stop")
                })
            }
        })



    }
    async addPartAndAuthorization() {
        return new Promise((res, rej) => {
            let addPart = this.checkForAddPart();
            this.addPartForDeliveryAndAuthorize(addPart).then((result) => {
                res(result)
            });
        })


    }

    async handleSubmitRouteChange(fromTrigger) {
        let curOrder = this.state.curOrder;
        const { queueRoutesIndexed } = this.context;
        const { orderStatusReasonsIndexed } = this.context;

        let selectedRoute = queueRoutesIndexed[this.state.radio.queueRouting];
        let queueRoutingSubType = this.state.radio.queueRoutingSubType;
        let hasTrigger = selectedRoute.trigger;

        this.handleQueueRouteTriggers(hasTrigger, fromTrigger).then((res) => {
            if (res === "stop") {
                return
            }

            this.statusChanged(orderStatusReasonsIndexed[selectedRoute.toOrderStatusReasonId].orderStatus);
            this.updateReason(selectedRoute.toOrderStatusReasonId)
            if (queueRoutingSubType && (queueRoutingSubType !== curOrder.serviceReason)) {
                this.updateServiceOrderReasonRecord("serviceReason", queueRoutingSubType)
            }
            this.saveStatusReasonChange();
        });
    }

    getRoutingInformation(order) {
        this.setState({
            loadingRoutingInformation: true
        })
        let orderActivityTypeId = this.findOrderActivityTypeId(order.orderType);
        let orderStatusReasonId = this.findOrderStatusReasonId(order.reason);
        let queueRoutingOptions = this.findQueueRoutingOptions(orderActivityTypeId, orderStatusReasonId);
        if (order.status === "Cancelled") {
            queueRoutingOptions = []
        }

        order.routingInformation = {
            orderId: order.id,
            orderActivityTypeId: orderActivityTypeId,
            orderStatusReasonId: orderStatusReasonId,
            queueRoutingOptions: queueRoutingOptions
        }
        this.setState({
            curOrder: order,
            loadingRoutingInformation: false
        })
    }
    getNewTicketRoutingInformation(orderType) {
        this.setState({
            initialRoutingInformation: null,
            loadingInitialRoutingInformation: true
        })
        let orderActivityTypeId = this.findOrderActivityTypeId(orderType);
        let orderStatusReasonId = null;
        let queueRoutingOptions = this.findQueueRoutingOptions(orderActivityTypeId, orderStatusReasonId);

        let initialRoutingInformation = {
            orderActivityTypeId: orderActivityTypeId,
            orderStatusReasonId: orderStatusReasonId,
            queueRoutingOptions: queueRoutingOptions
        }
        this.setState({
            initialRoutingInformation: initialRoutingInformation,
            loadingInitialRoutingInformation: false
        })
    }

    findQueueRoutingOptions(orderActivityTypeId, orderStatusReasonId) {
        const { queueRoutesIndexed } = this.context;
        let queueRouting = Object.values(queueRoutesIndexed);
        let foundRoutes = [];
        queueRouting.forEach((queueRoute) => {
            if (queueRoute.orderActivityTypeId === orderActivityTypeId &&
                queueRoute.fromOrderStatusReasonId === orderStatusReasonId) {
                foundRoutes.push(queueRoute)
            }
        })
        return foundRoutes;
    }

    findOrderStatusReasonId(orderReason) {
        const { orderStatusReasonsIndexed } = this.context;
        let result = Object.values(orderStatusReasonsIndexed);
        let index = result.find((orderStatusReasonsIndexed) => orderStatusReasonsIndexed.description === orderReason);
        if (!index) {
            return null
        }
        return index.id;
    }

    findOrderActivityTypeId(orderType) {
        const { orderActivityTypesIndexed } = this.context;
        let result = Object.values(orderActivityTypesIndexed);
        let index = result.find((orderActivityType) => orderActivityType.name === orderType);
        if (!index) {
            return null
        }
        return index.id;
    }
    splitArrayIntoChunks(array, size) {
        if (array && size) {
            //Splittign the array into chunks of size 2 for rendering
            let splitArray = array.reduce((resultArray, chunk, index) => {
                const chunkIndex = Math.floor(index / size)
                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []
                }
                resultArray[chunkIndex].push(chunk)
                return resultArray
            }, [])
            return splitArray;
        }
        return []
    }

    sortRoutingOptionsWithHeaders(options) {
        if (!options) {
            return
        }
        let array = {};
        options.forEach((option) => {
            let header = option.name.substring(0, option.name.indexOf("-"));
            // let body = option.name.substring(option.name.indexOf("-"), option.name.length);
            if (!array[header]) {
                array[header] = []
            }
            array[header].push(option);
        })
        let result = Object.keys(array).map((key) => [key, array[key]]);
        return result;

    }
    renderQueueRoutingOptionsInitialCreate(orderType) {
        const { orderActivityTypesIndexed } = this.context;

        if ((!this.state.initialRoutingInformation && !this.state.loadingInitialRoutingInformation) || orderActivityTypesIndexed[this.state.initialRoutingInformation.orderActivityTypeId].name.toLowerCase() !== orderType.toLowerCase()) {
            this.getNewTicketRoutingInformation(orderType)
        }
        let queueRoutingOptions = this.state.initialRoutingInformation ? this.state.initialRoutingInformation.queueRoutingOptions : null;
        let selectedQueueRoute = queueRoutingOptions ? queueRoutingOptions.find((option) => option.id === this.state.radio.queueRouting) : null;
        return (
            <>
                <Card className="patientInformationCard">
                    <MUIBox sx={ { flexGrow: 1, padding: "1rem" } }>
                        { queueRoutingOptions && queueRoutingOptions.length > 0 ? (
                            <>
                                <MUIFormControl>
                                    <MUIFormLabel sx={{marginBottom: "1rem"}}>Routing</MUIFormLabel>
                                    <MUIGrid container spacing={ 2 } style={{paddingLeft: ".5rem"}}>
                                        { queueRoutingOptions.map((o) => {
                                            return (
                                                <MUIGrid item lg={6} sm={12}>
                                                    <MUIFormControlLabel
                                                        sx={ { marginTop: 0, marginBottom: 0 } }
                                                        onChange={ () => this.handleRadioChangeQueueRoute(o, "queueRouting") }
                                                        checked={ this.state.radio.queueRouting === o.id }
                                                        value={ o.id }
                                                        control={ <MUIRadio /> }
                                                        label={ o.name } />

                                                </MUIGrid>

                                            )
                                        }) }
                                        { queueRoutingOptions.some(queueRoute => queueRoute.id === this.state.radio.queueRouting) ? this.renderSubTypeOptions(selectedQueueRoute) : null }
                                    </MUIGrid>
                                </MUIFormControl>
                            </>
                        ) : (
                            <EmptyState
                                    line1={ "No routes available" }
                            />
                        ) }
                    { orderType.toLowerCase() === "pickup" ?
                        <Col>
                            { this.renderPatientProducts() }
                        </Col> :
                        null }

                <div className={ this.state.radioBtnSelected ? "verifyInfoStep step3 infoVerifiedIndicator" : "verifyInfoStep step3" }>{ this.state.typeOfTicketToCreate.toLowerCase() !== "pickup" ? 3 : 2 }</div>
                    </MUIBox>
                </Card>
            </>
        )
    }

    renderSubTypeOptions(queueRoutingOption) {
        if (!queueRoutingOption.jsonSubTypes || !this.state.radio.queueRouting || this.state.radio.queueRouting !== queueRoutingOption.id) {
            return null
        }
        let subTypes = queueRoutingOption.jsonSubTypes,
            count = subTypes.options.length,
            required = !!subTypes.required;
        if (count === 1 && required) {
            if (this.state.selectedSubTypeFromQueueRoute !== subTypes.options[0]) {
                this.setState({
                    serviceReason: subTypes.options[0],
                    selectedSubTypeFromQueueRoute: subTypes.options[0]
                })
            }
            return null
        }

        return (
            <MUIBox sx={ { flexGrow: 1, padding: "1rem 1rem 0rem", width: "100%" } }>
                <MUITypography variant="body1" color={ required ? MUIRedColor[500] : MUIBlueColor[700] } style={{marginBottom: ".5rem"}}>{ required ? "Select One (required):" : "Optional:" }</MUITypography>
                <MUIGrid container spacing={2}>
                    { subTypes.options.map((subType, idx) => {
                        if (required) {
                            return (
                                <MUIGrid item >
                                    <MUIFormControlLabel
                                        onChange={ () => this.handleRadioChangeSubType(subType, "queueRoutingSubType") }
                                        checked={ this.state.radio.queueRoutingSubType === subType }
                                        value={ subType }
                                        control={ <MUIRadio size="small" /> }
                                        label={ subType } />
                                </MUIGrid>
                            )
                        }
                        else {
                            return (
                                <MUIGrid item>
                                    <MUIFormControlLabel
                                        onChange={ () => this.handleRadioChangeSubType(subType, "queueRoutingSubType") }
                                        checked={ this.state.radio.queueRoutingSubType === subType }
                                        value={ subType }
                                        control={ <MUICheckbox size="small" /> }
                                        label={ subType } />
                                </MUIGrid>
                        )
                    }
                }) }
                </MUIGrid>
            </MUIBox>
        )
    }

    renderQueueRoutingOptions() {
        let submitButtonEnabled = true

        if (this.state.isOrderDetails && !this.state.curOrder.id) {
            return
        }

        if (!this.state.curOrder.routingInformation && !this.state.loadingRoutingInformation) {
            this.getRoutingInformation(this.state.curOrder)
        }

        let curOrder = this.state.curOrder;
        let queueRoutingOptions = this.state.curOrder.routingInformation ? this.state.curOrder.routingInformation.queueRoutingOptions : null;
        let selectedQueueRoute = queueRoutingOptions ? queueRoutingOptions.find((option) => option.id === this.state.radio.queueRouting) : null;

        //Handle disable Submit button if subtype is required
        if (selectedQueueRoute && selectedQueueRoute.jsonSubTypes && selectedQueueRoute.jsonSubTypes.required === true ) {
            submitButtonEnabled = false

            selectedQueueRoute.jsonSubTypes.options.forEach((o) => {
                if (this.state.selectedSubTypeFromQueueRoute === o) {
                    submitButtonEnabled = true;
                }
            });
        }

        if (curOrder && curOrder.orderType && curOrder.orderType.toLowerCase().replace(/\s/g, '') !== "new") {
            return (
                <>
                    <Card className="patientInformationCard">
                        <MUIBox sx={ { padding: "1rem"} }>
                            { queueRoutingOptions.length > 0 ? (
                                <>
                                    <MUIFormControl sx={{minWidth: "100%"}}>
                                        <MUIFormLabel>Routing</MUIFormLabel>
                                        <MUIGrid container spacing={2}>
                                            { queueRoutingOptions.map((o, index) => {
                                                return (
                                                    <MUIGrid item lg={4} md={6} sm={12} key={ index } >
                                                        <MUIFormControlLabel
                                                            sx={ { marginTop: 0, marginBottom: 0 } }
                                                            onChange={ () => this.handleRadioChangeQueueRoute(o, "queueRouting") }
                                                            checked={ this.state.radio.queueRouting === o.id }
                                                            value={ o.id }
                                                            control={ <MUIRadio /> }
                                                            label={ o.name } />

                                                    </MUIGrid>

                                                )
                                            }) }
                                            { queueRoutingOptions.some(queueRoute => queueRoute.id === this.state.radio.queueRouting) ? this.renderSubTypeOptions(selectedQueueRoute) : null }
                                        </MUIGrid>
                                    </MUIFormControl>
                                    <MUIContainer disableGutters style={ { display: "flex", justifyContent: "end" } }>
                                        <MUILoadingButton
                                            disabled={ this.state.orderRetrieved && this.state.radio.queueRouting && submitButtonEnabled ? false : true }
                                            loading={ this.state.isUpdating }
                                            component="label"
                                            variant="contained"
                                            endIcon={ <MUIArrowForwardIcon /> }
                                            onClick={ () => {
                                                this.setState({ isUpdating: true })
                                                this.handleSubmitRouteChange()
                                            }}>Route</MUILoadingButton>
                                    </MUIContainer>
                                </>
                            ) : (
                                <EmptyState
                                    line1={ "No routes available" }
                                />
                            ) }

                        </MUIBox>
                    </Card>
                </>

            )
        }
    }

    renderTicketDetailsHeader() {
        let order = this.state.eOrder;
        let o = null;
        let r = this.state.curOrder;
        let statusIconColor = "#009FAF32";

        if (this.state.orderRetrieved && order && order !== null) {
            o = order
        }

        if (r && r.status) {
            if (r.status.toLowerCase() === "in process") {
                statusIconColor = "#CEBD23";
            } else if (r.status.toLowerCase() === "cancelled") {
                statusIconColor = "#CE2323";
            } else if (r.status.toLowerCase() === "ready to deliver") {
                statusIconColor = "#02BD4D";
            } else if (r.status.toLowerCase() === "setup") {
                statusIconColor = "#C4C4C4";
            } else if (r.status.toLowerCase() === "new") {
                statusIconColor = "#2372CE";
            } else {
                statusIconColor = "#000";
            }
        }

        return (
            <MDBRow className="ticketDetailsHeader" id={ "openDetailsHeader" }>
                <MDBCol size="md" className="ticketDetailsHeaderCol ticketDetailsHeaderIconCol">
                    <MDBIcon onClick={ () => this.toggleTicketDetailsModal() } fas icon="arrow-left" className="backArrowIcon" />
                </MDBCol>
                <MDBCol size="md" className="ticketDetailsHeaderCol ticketDetailsColBorderRight">
                    <p className="ticketDetailsHeaderLabel">ID</p>
                    <p className="ticketDetailsHeaderInfo">#{ this.state.curOrderId }</p>
                </MDBCol>
                <MDBCol size="md" className="ticketDetailsHeaderCol ticketDetailsColBorderRight">
                    <p className="ticketDetailsHeaderLabel ">Type</p>
                    <p className="ticketDetailsHeaderInfo">{ r.orderType }</p>
                    <p className="ticketDetailsHeaderInfo ticketDetailsHeaderSubtype">{ r.serviceReason ? r.serviceReason : "" }</p>
                </MDBCol>
                <MDBCol size="md" className="ticketDetailsHeaderCol ticketDetailsColBorderRight">
                    <MDBRow className="ticketDetailsStatusRow">
                        <MDBCol size="sm" className="ticketStatusHeaderIconCol">
                            <MDBIcon style={ { color: statusIconColor, fontSize: '1.7rem' } } far icon="clock" />
                        </MDBCol>
                        <MDBCol size="sm" className="ticketDetailsHeaderCol">
                            <p className="ticketDetailsHeaderLabel">Status</p>
                            <p className="ticketDetailsHeaderInfo">{ r.status && r.status.toLowerCase() === "setup" ? "Delivered" : r.status }</p>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol size="md" className="ticketDetailsHeaderCol ticketDetailsColBorderRight">
                    <p className="ticketDetailsHeaderLabel">Reason</p>
                    <p className="ticketDetailsHeaderInfo">{ r.reason ? r.reason : "None" }</p>
                </MDBCol>

                <MDBCol size="md" className="ticketDetailsHeaderCol">
                    <p className="ticketDetailsHeaderLabel">Created On</p>
                    <p className="ticketDetailsHeaderInfo">{ o && o !== null && o.activity && o.activity.startedAt ? GlobalFunctions.formatDateTime(o.activity.startedAt) : "" }</p>
                </MDBCol>
            </MDBRow>
        )
    }

    renderTicketDetailsInsurance() {
        let order = this.state.eOrder;
        let o = null;

        if (this.state.orderRetrieved && order && order !== null) {
            o = order
        }

        if (this.state.curOrder && this.state.curOrder !== null && this.state.curOrder.orderType === "Pickup") {
            return
        }

        return (
            (o && o !== null && o.isApproved === true) ? (
                <div>
                    <MDBRow className="ticketInfoLabelEditContainer ticketInsuranceHeaderRow">
                        <h3 className="ticketInfoLabel">Insurance</h3>
                        <MUIAlert severity="success" sx={ { width: '100%', margin: "0 .8rem" } }>Ticket has been approved and insurance can no longer be updated.</MUIAlert>
                    </MDBRow>
                    <MDBRow className="insuranceTileWrapper">
                        { o && o !== null && o.insuranceType ? (
                            <MDBCol md={ 6 }>
                                <MDBContainer className="insuranceTile">
                                    <MDBRow className="insuranceTileRow">
                                        <MDBCol size="7" className="insuranceTileCol">
                                            <h4 className="ticketInfoSubLabel">Primary Payor Source</h4>
                                            <p className="ticketInfo">{ o && o !== null && o.insuranceType && o.insuranceType.name !== null ? o.insuranceType.name : "" }</p>
                                        </MDBCol>
                                        <MDBCol className="insuranceTileCol">
                                            <h4 className="ticketInfoSubLabel">Primary Insurance</h4>
                                            <p className="ticketInfo">{ o && o !== null && o.insuranceSubType && o.insuranceSubType.name !== null ? o.insuranceSubType.name : "" }</p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="insuranceTileRow">
                                        <MDBCol size="7" className="insuranceTileCol">
                                            <h4 className="ticketInfoSubLabel">Policy Number</h4>
                                            <p className="ticketInfo">{ o && o !== null && o.insurancePolicy !== null && o.insurancePolicy !== "" ? o.insurancePolicy : "N/A" }</p>
                                        </MDBCol>
                                        <MDBCol className="insuranceTileCol">
                                            <h4 className="ticketInfoSubLabel">Group Number</h4>
                                            <p className="ticketInfo">{ o && o !== null && o.insuranceGroup !== null && o.insuranceGroup !== "" ? o.insuranceGroup : "N/A" }</p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBCol>
                        ) : null }

                        { o && o !== null && o.secondaryInsuranceType ? (
                            <MDBCol md={ 6 }>
                                <MDBContainer className="insuranceTile">
                                    <MDBRow className="insuranceTileRow">
                                        <MDBCol size="7" className="insuranceTileCol">
                                            <h4 className="ticketInfoSubLabel">Secondary Payor Source</h4>
                                            <p className="ticketInfo">{ o && o !== null && o.secondaryInsuranceType && o.secondaryInsuranceType.name !== null ? o.secondaryInsuranceType.name : "" }</p>
                                        </MDBCol>
                                        <MDBCol className="insuranceTileCol">
                                            <h4 className="ticketInfoSubLabel">Secondary Insurance</h4>
                                            <p className="ticketInfo">{ o && o !== null && o.secondaryInsuranceSubType && o.secondaryInsuranceSubType.name !== null ? o.secondaryInsuranceSubType.name : "" }</p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="insuranceTileRow">
                                        <MDBCol size="7" className="insuranceTileCol">
                                            <h4 className="ticketInfoSubLabel">Policy Number</h4>
                                            <p className="ticketInfo">{ o && o !== null && o.secondaryInsurancePolicy !== null && o.secondaryInsurancePolicy !== "" ? o.secondaryInsurancePolicy : "N/A" }</p>
                                        </MDBCol>
                                        <MDBCol className="insuranceTileCol">
                                            <h4 className="ticketInfoSubLabel">Group Number</h4>
                                            <p className="ticketInfo">{ o && o !== null && o.secondaryInsuranceGroup !== null && o.secondaryInsuranceGroup !== "" ? o.secondaryInsuranceGroup : "N/A" }</p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBCol>
                        ) : null }
                    </MDBRow>
                </div>
            ) : null

        )
    }

    renderTicketDetailsInformation() {
        let order = this.state.eOrder;
        let o = null;

        if (!this.state.curOrder) {
            return
        }

        if (!this.state.orderApprovalsLoading && !this.state.orderApprovalsLoaded && this.state.curOrderId) {
            this.setState({
                orderApprovalsLoading: true
            }, () => {
                this.getApprovalsByOrder()
            })
        }

        if (this.state.orderRetrieved && order && order !== null) {
            if (this.state.orderRetrieved && order && order !== null) {
                o = order
                let parentOrder = this.state.gridData.rows.find((f) => this.state.curOrder.parentOrder === f.orderId)

                if (!this.state.serviceReasonLoaded && !this.state.isOrderDetails) {
                    this.retrieveServiceOrderReasonByTicketId(o.id)
                }

                if (!this.state.ticketRecordsLoaded) {
                    this.retrieveTicketRecords(o);
                    if (parentOrder && parentOrder !== null) {
                        if (!(this.state.loadingParentProducts && this.state.loadingParentProducts === parentOrder.orderId)) {
                            this.setState({ loadingParentProducts: parentOrder.orderId })
                            OrderService.getOrderProducts(parentOrder.orderId)
                                .then(data => {
                                    let prod = null;
                                    let filterDataWithBaseUnit = data.filter((d) => d.baseUnitId && d.baseUnitId !== null)

                                    if (filterDataWithBaseUnit && filterDataWithBaseUnit.length > 0) {
                                        prod = filterDataWithBaseUnit.sort((a, b) => {
                                            return b.points - a.points;
                                        });
                                    } else {
                                        prod = data.filter((d) => d.serial && d.serial !== null).sort((a, b) => {
                                            return b.points - a.points;
                                        })
                                    }

                                    if (prod && prod.length > 0) {
                                        this.setState({
                                            baseUnitSerial: prod[0].serial,
                                            baseUnitHCPCS: prod[0].hcpcs,
                                        })
                                        this.retrieveBaseUnit(prod[0].baseUnitId)
                                    } else {
                                        this.setState({
                                            baseUnitLoaded: true,
                                        })
                                    }

                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        }
                    }

                }

            }
        }

        return (
            <div className="ticketDetailsContainer">
                <div className="ticketInfoContainer">
                    <h3 className="ticketInfoLabel">Equipment</h3>
                    <div className="ticketDetailsWrapper">
                        <div className="patientInfoCol1">
                            <h4 className="ticketInfoSubLabel">Base Unit</h4>
                            <p className="ticketInfo">{ this.state.currentBaseUnit && this.state.currentBaseUnit !== null ? this.state.currentBaseUnit : "N/A" }</p>
                            <h4 className="ticketInfoSubLabel">Serial Number</h4>
                            <p className="ticketInfo">{ this.state.baseUnitSerial && this.state.baseUnitSerial !== null ? this.state.baseUnitSerial : "N/A" }</p>
                        </div>
                    </div>
                </div>
                <div className="ticketInfoContainer">
                    <div className="ticketInfoLabelEditContainer">
                        <h3 className="ticketInfoLabel">Location Information</h3>
                        { this.state.orderRetrieved && order && order !== null ?
                            (<div className="editBtnContainer">
                                <svg
                                    onClick={ () => this.setState({ editLocationInformationModal: true, }) }
                                    className="detailHeaderEditBtn" xmlns="http://www.w3.org/2000/svg" width="17.341" height="17.341" viewBox="0 0 17.341 17.341">
                                    <path id="Path_square-edit-outline" data-name="Path / square-edit-outline" d="M4.927,3A1.92,1.92,0,0,0,3,4.927V18.414a1.927,1.927,0,0,0,1.927,1.927H18.414a1.927,1.927,0,0,0,1.927-1.927V11.67H18.414v6.744H4.927V4.927H11.67V3H4.927m12.312.963a.666.666,0,0,0-.462.193L15.6,5.322,18.009,7.73l1.175-1.166a.651.651,0,0,0,0-.915L17.692,4.156a.643.643,0,0,0-.453-.193M14.917,6.006l-7.1,7.11v2.408h2.408l7.1-7.11Z" transform="translate(-3 -3)" fill="#9c9c9c" />
                                </svg>
                            </div>) : null }
                    </div>

                    <div className="ticketDetailsWrapper">
                        <div className="patientInfoCol1">
                            <h4 className="ticketInfoSubLabel">Sales Location</h4>
                            <p className="ticketInfo" >{ o && o !== null && this.state.order.salesLocation && this.state.order.salesLocation.name ? this.state.order.salesLocation.name : "N/A" }</p>
                            <h4 className="ticketInfoSubLabel">Service Location</h4>
                            <p className="ticketInfo">{ o && o !== null && this.state.order.serviceLocation && this.state.order.serviceLocation.name ? this.state.order.serviceLocation.name : "N/A" }</p>

                        </div>
                    </div>
                </div>
                <div className="ticketInfoContainer">
                    <div className="ticketInfoLabelEditContainer">
                        <h3 className="ticketInfoLabel">General</h3>
                        { this.state.orderRetrieved && order && order !== null ?
                            (<div className="editBtnContainer">
                                <svg
                                    onClick={ () => this.setState({ editGeneralInformationModal: true, }) }
                                    className="detailHeaderEditBtn" xmlns="http://www.w3.org/2000/svg" width="17.341" height="17.341" viewBox="0 0 17.341 17.341">
                                    <path id="Path_square-edit-outline" data-name="Path / square-edit-outline" d="M4.927,3A1.92,1.92,0,0,0,3,4.927V18.414a1.927,1.927,0,0,0,1.927,1.927H18.414a1.927,1.927,0,0,0,1.927-1.927V11.67H18.414v6.744H4.927V4.927H11.67V3H4.927m12.312.963a.666.666,0,0,0-.462.193L15.6,5.322,18.009,7.73l1.175-1.166a.651.651,0,0,0,0-.915L17.692,4.156a.643.643,0,0,0-.453-.193M14.917,6.006l-7.1,7.11v2.408h2.408l7.1-7.11Z" transform="translate(-3 -3)" fill="#9c9c9c" />
                                </svg>
                            </div>) : null }
                    </div>
                    <div className="ticketDetailsWrapper">
                        <div className="patientInfoCol1">
                            <h4 className="ticketInfoSubLabel">Auth Number</h4>
                            <p className="ticketInfo">{ o && o !== null && this.state.order.orderActivityApprovals[0] && this.state.order.orderActivityApprovals[0].approvals.authNumber ? this.state.order.orderActivityApprovals[0].approvals.authNumber : "N/A" }</p>
                            <h4 className="ticketInfoSubLabel">Setup Date</h4>
                            <p className="ticketInfo">{ o && o !== null && this.state.order.setupDate && this.state.order.setupDate !== null ? globalFunctions.formatFlatDate(this.state.order.setupDate) : "N/A" }</p>
                            <h4 className="ticketInfoSubLabel">Scheduled Delivery Date</h4>
                            <p className="ticketInfo">{ o && o !== null && this.state.order.scheduledDeliveryDate && this.state.order.scheduledDeliveryDate !== null ? globalFunctions.formatFlatDate(this.state.order.scheduledDeliveryDate) : "N/A" }</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderTicketDetailsBody() {
        if (this.state.isOrderDetails) {
            return
        }
        return (
            <MUIBox>
                { this.renderTicketDetailsInsurance() }
                { this.renderTicketDetailsInformation() }
                { this.renderServiceTicketParts() }
                <MDBRow>
                    { this.renderABNButton() }
                    { this.renderAddServicePartButton() }
                </MDBRow>
                { this.renderServiceTicketGenericParts() }
            </MUIBox>
        )
    }

    renderTabContent() {
        return (
            <MDBTabContent activeItem={ this.state.items["ticketDetails"] } style={ { paddingTop: '0rem !important' } } className={ this.state.isOrderDetails ? "" : "tabContent" }>
                { this.state.isOrderDetails && this.state.curOrder.setupDate && this.state.curOrder.setupDate !== null ?
                    (
                        <MDBTabPane tabId="1">
                            { this.renderApprovalInformation() }
                        </MDBTabPane>
                    )
                    : this.state.curOrder.parentOrder === null ?
                        (
                            <div style={ { width: 'min-content', margin: '0 auto', whiteSpace: "nowrap", color: "#A1A1A1" } }>No Approval Data (order still in process)</div>
                        )
                        : null
                }
                <MDBTabPane tabId={ this.state.isOrderDetails ? "3" : "2" }>
                    { this.renderAttachments() }
                </MDBTabPane>
            </MDBTabContent>
        )
    }


    renderOrderDetailsCard() {
        if (this.state.TabsOneActiveItem !== "4") {
            return
        }

        return (
            <MUIContainer style={ { minHeight: "100vh" } } id={ "orderDetailsContainer" }>
                {/* HEADER */}
                { this.renderTicketDetailsHeader() }
                <MUIBox className="ticketDetailsBody" style={ { overflow: 'hidden', paddingTop: '0' } }>
                    {/* BODY */ }
                    {this.renderTabContent()}
                    {this.renderTicketDetailsBody()}
                    {/* FOOTER */ }
                    { this.renderQueueRoutingOptions() }
                    <ReactTooltip />
                </MUIBox>
            </MUIContainer>
        )
    }

    handleEditBtnClick() {
        this.setState({
            ticketDetailsModalOpen: false,
            canEditBasicInfo: true,
            canEditInsuranceInfo: false,
            TabsOneActiveItem: "2"
        })
        if (this.state.curOrder) {
            this.setState({ shouldOpenOrderId: this.state.curOrder.id || this.state.curOrder.orderId })
        } else {
            this.setState({ shouldOpenOrderId: null })
        }
    }

    handleEditInsuranceBtnClick() {
        this.setState({
            ticketDetailsModalOpen: false,
            canEditInsuranceInfo: true,
            canEditBasicInfo: false,
            TabsOneActiveItem: "3"
        })
        if (this.state.curOrder) {
            this.setState({ shouldOpenOrderId: this.state.curOrder.id || this.state.curOrder.orderId })
            if (this.state.curOrder.isApproved === true) {
                this.setState({
                    canEditInsuranceInfo: false,
                })
            }
        } else {
            this.setState({ shouldOpenOrderId: null })
        }
    }

    toggleEditBasicInfo() {
        this.setState({
            canEditBasicInfo: false,
            canEditInsuranceInfo: false,
            radioBtnSelected: false,
            radio: {
                serviceTicketAction: "",
                pickupTicketAction: { label: "", value: "" }
            },
        })
    }

    refreshTicket(){
        this.togglePills("rightMenu", "2");
        let item = this.state.items;
        item.rightMenu = "2";
        this.setState({
            orderRetrieved: false,
            items: item,
            orderAttachmentsLoading: true,
            servicePartsLoaded: false
        }, ()=>{
            this.getApprovalsByOrder()
        });

            setTimeout(() => {
                this.setState({
                    orderAttachmentsLoading: false
                });
            }, 1);

        this.toggleAddServicePartsModal();
    }

    renderAddPartCreateQuoteModal() {
        if(!this.state.addPartCreateQuoteModal){return}
        const { productTypes, productSubTypes } = this.context;

        let st = this.state;
        if (!st.addPartCreateQuoteModal) {
            return;
        }

        let baseUnitInfo = {
            makeModel: this.state.currentBaseUnit,
            serial: this.state.baseUnitSerial,
            hcpcs: this.state.baseUnitHCPCS
        }

        if(!this.state.curOrder){
            if (this.state.addPartCreateQuoteModal){
                this.setState({
                    addPartCreateQuoteModal:false,
                })
            }
            if(this.state.createQuoteSmall){
            this.setState({
                createQuoteSmall: false,
            });
        }
            return;
        }
        return (
            <CreateQuoteModalFromTicket
                baseUnitInfo={baseUnitInfo}
                refresh={this.refreshTicket.bind(this)}
                parentOrder={this.state.curOrder.parentOrder}
                // canNotMinimize={ true }
                history={ this.props.history }
                serviceTicket={ false }
                addParts={true}
                serviceProducts={ this.state.serviceOrderProducts }
                order={ this.state.curOrder }
                currentFullOrder= { this.state.eOrder}
                products={ this.state.orderProducts }
                productTypes={ productTypes }
                productSubTypes={ productSubTypes }
                saveOrder={ (e) => this.savePatient.bind(null) }
                onToggle={ () => {
                    this.setState({
                        addPartCreateQuoteModal: !this.state.addPartCreateQuoteModal,
                    });
                } }
                patient={ this.state.ePatient }
                onSmall={() => {
                   this.createQuoteSmallToggle();
                }}
                small={this.state.createQuoteSmall}
            />
        );
    }

    createQuoteSmallToggle(){
        toast.warn("Leaving this ticket will cause changes to quote to be lost")
        this.setState({
            createQuoteSmall: !this.state.createQuoteSmall,
        });
    }
    createNewTicket() {
        const { queueRoutesIndexed } = this.context;
        const { orderStatusReasonsIndexed } = this.context;
        const { orderActivityTypesIndexed } = this.context;

        let queueRoute = queueRoutesIndexed[this.state.radio.queueRouting];
        let orderActivityType = orderActivityTypesIndexed[queueRoute.orderActivityTypeId];
        let orderStatusReason = orderStatusReasonsIndexed[queueRoute.toOrderStatusReasonId];
        let ticket = {
            orderActivityType: orderActivityType,
            orderStatusReason: orderStatusReason
        }

        this.createOrder(this.props.history, ticket)
    }

    handleSubtypeCheckbox(event) {
        let td = this.state.thirtyDayFit;
        td = event.target.checked
        this.setState({
            thirtyDayFit: td
        })
    }

    renderCreateServiceTicketModal() {
        if(!this.state.createServiceTicketModalOpen){return}
        if (!this.state.orderApprovalsLoading && !this.state.orderApprovalsLoaded && this.state.curOrderId) {
            this.getApprovalsByOrder()
        }
        let typeOfTicketToCreate = this.state.typeOfTicketToCreate;

        return (
            <MDBModal
                className="serviceTicketModal"
                centered
                size="lg"
                isOpen={ this.state.createServiceTicketModalOpen }
                toggle={ () => {
                    return;
                } }
            >
                {this.renderCreateTicketHeader(typeOfTicketToCreate)}
                {this.renderCreateTicketBody(typeOfTicketToCreate)}
                {this.renderCreateTicketFooter()}
            </MDBModal>
        )
    }
    renderCreateTicketHeader(ticketType) {
        let ticketTypeString = "Create " + ticketType + " Ticket -";

        return (
            <MDBModalHeader className="serviceTicketHeader">
                <div style={{ fontWeight: '500', padding: ".5rem" }}>
                    {this.state.canEditBasicInfo && this.state.canEditInsuranceInfo ? ticketTypeString + "Edit Basic Info" : ticketTypeString + "Confirm Basic Info"}
                    <svg id="Group_206" onClick={() => this.setState({ canEditBasicInfo: !this.state.canEditBasicInfo, canEditInsuranceInfo: !this.state.canEditInsuranceInfo, basicInfoValidated: false, insuranceInfoValidated: false, createTicketNote: null })} className={this.state.canEditBasicInfo ? "serviceTixModalEditBtn editBtnIsHidden" : "serviceTixModalEditBtn"} data-name="Group 206" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35.911 35.911">
                        <g id="Group_167" data-name="Group 167" transform="translate(0 0)">
                            <g id="MDI_square-edit-outline" data-name="MDI / square-edit-outline" opacity="0.8">
                                <rect id="Boundary" width="35" height="35" fill="#9C9C9C" opacity="0" />
                                <path id="Path_square-edit-outline" data-name="Path / square-edit-outline" d="M5.993,3A2.982,2.982,0,0,0,3,5.993V26.941a2.993,2.993,0,0,0,2.993,2.993H26.941a2.993,2.993,0,0,0,2.993-2.993V16.467H26.941V26.941H5.993V5.993H16.467V3H5.993M25.115,4.5a1.034,1.034,0,0,0-.718.3L22.572,6.606l3.741,3.741,1.825-1.811a1.012,1.012,0,0,0,0-1.421L25.819,4.8a1,1,0,0,0-.7-.3M21.509,7.668,10.482,18.711v3.741h3.741L25.25,11.409Z" transform="translate(1.489 1.489)" fill="#9C9C9C" />
                            </g>
                        </g>
                    </svg>
                    {this.state.canEditBasicInfo && this.state.canEditInsuranceInfo ? null : (<svg onClick={() => {
                        this.toggleCreateTicketModal()
                        this.setState({
                            createTicketOrderId: null,
                        })
                    }} className="closeModalIcon closeCreateTicketModalIcon" id="MDI_window-close" data-name="MDI / window-close" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <g id="Boundary" fill="#9c9c9c" stroke="rgba(0,0,0,0)" stroke-width="1" opacity="0">
                            <rect width="32" height="32" stroke="none" />
                            <rect x="0.5" y="0.5" width="32" height="32" fill="none" />
                        </g>
                        <path id="Path_window-close" data-name="Path / window-close" d="M15.653,13.814l6.976,6.976v1.838H20.79l-6.976-6.976L6.838,22.628H5V20.79l6.976-6.976L5,6.838V5H6.838l6.976,6.976L20.79,5h1.838V6.838Z" transform="translate(1.296 1.08)" fill="#9c9c9c" />
                    </svg>)}

                </div>
            </MDBModalHeader>
        )
    }
    renderCreateTicketBody(ticketType) {
        return (
            <MDBModalBody style={{ backgroundColor: '#F9F9F9', padding: '0rem 0rem 1.2rem', maxHeight: '65vh', overflowY: 'auto' }}>
                {this.state.patientInfoRetrieved === false ?
                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: "100vh", paddingTop: "5vh" }}><MDBSpinner></MDBSpinner></div>
                    : (
                        <>
                            {this.renderBasicInfo()}
                            {!this.state.canEditBasicInfo ? (
                                <>
                                    <div className={this.state.basicInfoValidated ? "verifyInfoStep infoVerifiedIndicator" : "verifyInfoStep"}>1</div>
                                    {this.state.basicInfoValidated ? null : (<div className="validateButtonContainer "><button className="validateButton" onClick={() => this.setState({ basicInfoValidated: true })}>Confirm</button></div>)}
                                </>
                            ) : null}
                            {ticketType.toLowerCase() !== "pickup" ? this.renderInsuranceCard() : null}
                            {ticketType.toLowerCase() !== "pickup" ? !this.state.canEditBasicInfo ? (
                                <>
                                    <div className={this.state.insuranceInfoValidated ? "verifyInfoStep step2 infoVerifiedIndicator" : "verifyInfoStep step2"} >2</div>
                                    {this.state.insuranceInfoValidated ? null : (<div className="validateButtonContainer validateButtonContainer2"><button onClick={() => this.setState({ insuranceInfoValidated: true })} className="validateButton">Confirm</button></div>)}
                                </>
                            ) : null : null}

                        </>
                    )}
                {this.state.canEditBasicInfo ? null : (
                    <>
                        {this.renderQueueRoutingOptionsInitialCreate(ticketType)}
                        {this.renderCreateTicketNoteSection()}
                    </>
                )}
            </MDBModalBody>
        )
    }

    renderCreateTicketNoteSection() {
        return (
            <Card className="patientInformationCard" style={{ padding: ".5rem 1rem 1rem" }}>
                <label className="cardLabel addNoteCardLabel">Add Note</label>
                <textarea
                    style={{ padding: '.5rem', borderRadius: '5px' }}
                    placeholder={"Add note text here (required)"}
                    type={"text"}
                    id="createTicketNoteText"
                    rows="2"
                    value={this.state.createTicketNote || ""}
                    onChange={(e) => {
                        this.setValue(e.target.value)
                    }}
                />
                <div className={this.state.createTicketNote ? "verifyInfoStep step4 infoVerifiedIndicator" : "verifyInfoStep step4"}>{this.state.typeOfTicketToCreate.toLowerCase() !== "pickup" ? 4 : 3}</div>
            </Card>
        )
    }


    renderCreateTicketFooter() {
        let disabledSubmit = false;
        return (
            <MDBModalFooter>
                {this.state.canEditBasicInfo && this.state.canEditInsuranceInfo ? (
                    <>
                        <MDBBtn
                            color="gray"
                            disabled={this.state.patientInfoRetrieved === false ? true : false}
                            onClick={() => {
                                this.setState({ patientInfoRetrieved: false })
                                return OrderService.getPatientById(this.state.id)
                                    .then((data) => {
                                        this.setState({
                                            patientAge: moment().diff(data.dateOfBirth, 'years', false),
                                            patient: data,
                                            ePatient: JSON.parse(JSON.stringify(data)),
                                            canEditBasicInfo: false,
                                            patientInfoRetrieved: true,
                                        });
                                        this.toggleEditBasicInfo()
                                    });
                            }}
                        >
                            Cancel
                        </MDBBtn>
                        <MDBBtn
                            disabled={this.state.patientInfoRetrieved === false ? true : false}
                            onClick={this.savePatient.bind(this)}
                            className="successButton"
                        >
                            Update
                        </MDBBtn>
                    </>
                ) :
                    (
                        <>
                            <MDBBtn
                                color="gray"
                                onClick={() => {
                                    this.toggleCreateTicketModal()
                                    this.setState({ createTicketOrderId: null })
                                }}>
                                Close
                            </MDBBtn>
                            <MDBBtn
                                disabled={disabledSubmit || ((this.state.radio.queueRouting) && this.state.basicInfoValidated &&
                                    (this.state.typeOfTicketToCreate.toLowerCase() === "pickup" || this.state.insuranceInfoValidated) && this.state.createTicketNote !== null &&
                                    this.state.createTicketNote !== "" ? false : true)}
                                className="successButton"
                                onClick={() => {
                                    disabledSubmit = true;
                                    this.setState({
                                        patientEquipmentLoaded: false,
                                        createServiceTicketModalOpen: false,
                                    })
                                    this.createNewTicket()
                                }}
                            >
                                {"Create " + this.state.typeOfTicketToCreate}
                            </MDBBtn>
                        </>
                    )
                }
            </MDBModalFooter>
        )
    }
    renderNewOrderModal() {
        if(!this.state.orderModalOpen){return}
        let newOrderTypeArray = this.state.orderTypes;
        return (
            <MDBModal
                size="small"
                isOpen={ this.state.orderModalOpen }
                toggle={ () => {
                    return;
                } }
                className="salesPilotModal"
            >
                <MDBModalHeader
                style={ { borderRadius: "8px" } }
            >
                <h3>Create New Order</h3>
            </MDBModalHeader>
                <MDBModalBody style={ { backgroundColor: "#FFFFFF" } }>
                    <label>Order Type</label>
                    <Row>
                        { newOrderTypeArray.map((orderType, index) => {
                            if (orderType.id === 1 || orderType.id === 2 || orderType.id === 3) {
                                return null
                            }
                            return (
                                <Col md="6" key={ orderType.id }>
                                    <Input
                                        type="radio"
                                        gap
                                        label={ orderType.name }
                                        checked={ this.state.selectedOrderTypeRadio === index }
                                        onClick={ () => this.getOrderType(orderType) }
                                        id={ "radio" + orderType.id }
                                    />
                                </Col>
                            );
                        }) }
                    </Row>
                    <Col size="12">
                        <hr />
                    </Col>
                    <Row>
                        { this.renderSalesLocationDropdown() }
                        { this.renderServiceLocationDropdown() }
                    </Row>
                    { this.renderNewOrderStuff() }
                    { this.renderOrderProducts() }

                </MDBModalBody>
                <ModalFooter color={ "indigo" }>
                    { this.renderCreateOrderRoute() }
                    <Button
                        color={ "red" }
                        rounded
                        size="sm"
                        data-tip={ "Cancel" }
                        onClick={ () => this.toggleOrderModal() }
                    >
                        <Fa size="2x" icon={ "times" }>{ " " }</Fa>
                    </Button>
                    <ReactTooltip />
                </ModalFooter>
            </MDBModal>
        );
    }

    renderEditGeneralInformationModal() {
        if (!this.state.editGeneralInformationModal) { return }
        let order = this.state.eOrder;
        let o = null;
        let preAuth = null;
        let setupDate = null;
        let orderStatus = null;

        if (this.state.orderRetrieved && order && order !== null) {
            o = order
            let appr = o.orderActivityApprovals.length > 0 && o.orderActivityApprovals[0] ? o.orderActivityApprovals[0].approvals : null;
            preAuth = appr && appr.authNumber ? appr.authNumber.trim() : null;
            setupDate = o.setupDate;
            orderStatus = o.status;
        }

        return (
            <MUIDialog
                maxWidth="sm"
                fullWidth
                open={ this.state.editGeneralInformationModal }
                onClose={ () => { return } }
            >
                <MUIDialogTitle variant="h6" color="textPrimary">
                    { "Edit General Information" }
                </MUIDialogTitle>
                <MUIDialogContent>
                    <MUIGrid container spacing={ 2 }>
                        <MUIGrid item sm="6" xs="12">
                            <MUITextField
                                fullWidth
                                label={ "Auth Number" }
                                InputLabelProps={ { shrink: true } }
                                value={ preAuth ? preAuth : "" }
                                onChange={ (evt) =>
                                    this.approvalAndUpdate("authNumber", evt.target.value)
                                }
                                variant="standard"
                            />
                        </MUIGrid>
                        <MUIGrid item sm="6" xs="12">
                            <MuiPickersUtilsProvider utils={ MomentUtils }>
                                <DatePicker
                                    className={ orderStatus === 3 ? "setupDatePicker" : "setupDatePicker basicInfoDisabled" }
                                    format="MM/DD/YYYY"
                                    clearable
                                    disabled={ orderStatus === 3 ? false : true }
                                    style={ { zIndex: '0' } }
                                    inputVariant="standard"
                                    label={ "Setup Date" }
                                    value={ setupDate || null }
                                    onChange={ (e) => this.handleDate("setupDate", e) }
                                />
                            </MuiPickersUtilsProvider>
                        </MUIGrid>
                    </MUIGrid>
                </MUIDialogContent>
                <MUIDialogActions>
                    <MUIButton
                        variant="text"
                        onClick={ () => {
                            this.getApprovalsByOrder()
                            this.setState({ editGeneralInformationModal: false })
                        } }>Cancel</MUIButton>
                    <MUILoadingButton
                        variant="contained"
                        loading={ this.state.isUpdating }
                        loadingPosition="center"
                        onClick={ () => {
                            this.setState({
                                isUpdating: true,
                            })
                            this.updateOrder(o)
                        } }
                    >
                        Confirm
                    </MUILoadingButton>
                </MUIDialogActions>
            </MUIDialog>
        );
    }

    renderEditLocationInformationModal() {
        if (!this.state.editLocationInformationModal) { return }
        let o = this.state.eOrder;

        return (
            <MUIDialog
                maxWidth="sm"
                fullWidth
                open={ this.state.editLocationInformationModal }
                onClose={ () => { return } }
            >
                <MUIDialogTitle variant="h6" color="textPrimary">
                    { "Edit Location Information" }
                </MUIDialogTitle>
                <MUIDialogContent>
                    <MUIGrid container spacing={ 2 }>
                        { this.renderTicketSalesLocationDropdown() }
                        { this.renderTicketServiceLocationDropdown() }
                    </MUIGrid>
                </MUIDialogContent>
                <MUIDialogActions>
                    <MUIButton
                        variant="text"
                        onClick={ () => {
                            this.getApprovalsByOrder()
                            this.setState({ editLocationInformationModal: false })
                        } }>Cancel</MUIButton>
                    <MUILoadingButton
                        variant="contained"
                        loading={ this.state.isUpdating }
                        loadingPosition="center"
                        onClick={ () => {
                            this.setState({
                                isUpdating: true,
                            })
                            this.updateOrder(o)
                        } }
                    >
                        Confirm
                    </MUILoadingButton>
                </MUIDialogActions>
            </MUIDialog>

        );
    }

    renderNewComplaintModal() {
        if(!this.state.createComplaintModal){return}
        const { complaintCategories, complaintReasons, allUsers } = this.context;
        let st = this.state;

        function getComplaintCategories(categories) {
            let c = [];
            categories.forEach((cat) => {
                c.push({ label: cat.name, value: cat });
            });
            return c;
        }

        function getComplaintReasons(reasons) {
            let r = [],
                cat = st.nComplaint.issueCategory;
            reasons.forEach((res) => {
                if (cat && cat.id) {
                    if (res.issueCategory && res.issueCategory.id === cat.id) {
                        r.push({
                            label: res.name,
                            value: res,
                        });
                    }
                }
            });
            return r;
        }

        function getAllUsers(users) {
            let u = [];
            users.forEach((user) => {
                u.push({
                    label: user.firstname + " " + user.lastname,
                    value: user.id
                })
            });
            return u;
        }

        return (
            <MDBModal
                centered
                size="large"
                className="salesPilotModal"
                isOpen={ this.state.createComplaintModal }
                toggle={ () => {
                    return;
                } }
            >
                <MDBModalHeader style={ { borderRadius: "8px" } }>
                    <h3>Add Patient Feedback</h3>
                </MDBModalHeader>
                <MDBModalBody style={ { backgroundColor: "#FFFFFF" } }>
                    <Row>
                        <Col><label>Feedback Type</label></Col>
                        <Col><label>Feedback Reason</label></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Select
                                placeholder={ this.state.nComplaint.issueCategory.name }
                                options={ getComplaintCategories(complaintCategories) }
                                onChange={ (val) => {
                                    let o = this.state.nComplaint;
                                    o.issueCategory = val.value;
                                    this.setState({ nComplaint: o });
                                    if (val.value.id === 3) {
                                        this.setState({
                                            isCompliment: true,
                                        })
                                    } else {
                                        this.setState({
                                            isCompliment: false,
                                        })
                                    }
                                } }
                            />
                        </Col>
                        <Col>
                            <Select
                                placeholder={ this.state.nComplaint.issueReason.name }
                                options={ getComplaintReasons(complaintReasons) }
                                onChange={ (val) => {
                                    let o = this.state.nComplaint;
                                    o.issueReason = val.value;
                                    this.setState({ nComplaint: o });
                                } }
                            />
                        </Col>
                    </Row>
                    { this.state.isCompliment === true ? (
                        <>
                            <Row>
                                <Col><label style={ { paddingTop: '8px' } }>Assign To</label></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Select
                                        isSearchable
                                        placeholder={ this.state.complimentedUser ? this.state.complimentedUser : allUsers.label }
                                        options={ getAllUsers(allUsers) }
                                        onChange={ (val) => {
                                            let o = this.state.nComplaint;
                                            o.complimentedUserId = val.value;
                                            this.setState({
                                                nComplaint: o,
                                                complimentedUser: val.label,
                                            })
                                        } }
                                    />
                                </Col>
                            </Row></>)
                        :
                        null}
                    { this.state.addFeedbackFromActions ? ((
                        <>
                            <label style={ { paddingTop: '8px' } }>Order</label>
                            <Select
                                search
                                placeholder={ this.state.nComplaint.orderId }
                                options={ this.state.allOrdersNotSorted }
                                onChange={ (val) => {
                                    let o = this.state.nComplaint;
                                    o.orderId = val.value;
                                    this.setState({ nComplaint: o });
                                } }
                            /></>)) : null }

                    <br />
                    <Row style={ { paddingBottom: '10px' } }>
                        <MDBInput
                            value={ this.state.nComplaint.actionPlanRequired || false }
                            checked={ this.state.nComplaint.actionPlanRequired || false }
                            onChange={ this.handleComplaintCheckboxChange.bind(this, "actionPlanRequired") }
                            label="Action Plan Required"
                            type="checkbox"
                            id="actionPlanRequiredCheckbox"
                            containerClass="mr-5"
                            filled
                        />{ " " }
                    </Row>
                    <textarea
                        placeholder={ "Feedback Description" }
                        onChange={ (e) => {
                            let nC = this.state.nComplaint;
                            nC.complaintDescription = e.target.value;
                            this.setState({
                                nComplaint: nC,
                            });
                        } }
                        style={ { width: "100%", height: "200px" } }
                    ></textarea>
                </MDBModalBody>
                <MDBModalFooter>
                    <Button
                        color={ "success" }
                        floating
                        size="sm"
                        data-tip={ "Add Patient Feedback" }
                        onClick={ () => this.addPatientComplaint() }
                    >
                        <Fa size="2x" icon={ "check" }>{ " " }</Fa>
                    </Button>
                    <Button
                        color={ "red" }
                        floating
                        size="sm"
                        data-tip={ "Cancel" }
                        onClick={ () => this.setState({ createComplaintModal: false, addFeedbackFromActions: false, }) }
                    >
                        <Fa size="2x" icon={ "times" }>{ " " }</Fa>
                    </Button>
                    <ReactTooltip />
                </MDBModalFooter>
            </MDBModal>
        );
    }

    renderNewPatientNoteModal() {
        if(!this.state.createPatientNoteModal){return}
        return (
            <MDBModal
                centered
                size="large"
                className="salesPilotModal"
                isOpen={ this.state.createPatientNoteModal }
                toggle={ () => {
                    return;
                } }
            >
                <MDBModalHeader
                    style={ { borderRadius: "8px" } }
                >
                    <h3>Add Patient Note</h3>
                </MDBModalHeader>
                <MDBModalBody style={ { backgroundColor: "#FFFFFF" } }>
                    <form className=" mx-3 grey-text">
                        <textarea
                            placeholder={ "Add note text here" }
                            onChange={ this.handleNoteChange.bind(this) }
                            style={ { width: "100%", height: "200px" } }
                        ></textarea>
                        <br />
                    </form>
                    <Col size="12"><hr /></Col>
                </MDBModalBody>
                <MDBModalFooter >
                    <Button
                        color={ "success" }
                        floating
                        size="sm"
                        data-tip={ "Add Patient Note" }
                        onClick={ () => this.createPatientNote() }
                    >
                        <Fa size="2x" icon={ "check" }>{ " " }</Fa>
                    </Button>
                    <Button
                        color={ "red" }
                        floating
                        size="sm"
                        data-tip={ "Cancel" }
                        onClick={ () => this.setState({ createPatientNoteModal: false, noteText: "" }) }
                    >
                        <Fa size="2x" icon={ "times" }>{ " " }</Fa>
                    </Button>
                    <ReactTooltip />
                </MDBModalFooter>
            </MDBModal>
        );
    }

    renderNewPhoneModal() {
        if(!this.state.addPhoneModalOpen){return}
        let phone = this.state.newPhoneNumber;
        return (
            <Modal
                centered
                isOpen={ this.state.addPhoneModalOpen }
                toggle={ () => {
                    this.toggleNewPhoneModal();
                } }
            >
                <ModalBody>
                    <CardHeader
                        style={ { borderRadius: "8px" } }
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>Add Patient Phone</h3>
                    </CardHeader>
                    <Row>
                        <Col size="12">
                            <Input
                                label="Contact Name"
                                value={ phone.contactName }
                                onChange={ (e) => {
                                    let n = this.state.newPhoneNumber;
                                    n.contactName = e.target.value;
                                    this.setState({ newPhoneNumber: n });
                                } }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col size="12">
                            <Input
                                label="Phone Number (dashes only)"
                                value={ phone.phoneNumber }
                                onChange={ (e) => {
                                    let n = this.state.newPhoneNumber;
                                    n.phoneNumber = e.target.value;
                                    this.setState({ newPhoneNumber: n });
                                } }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col size="12">
                            <label>Phone Type</label>
                            <Select
                                placeholder={ "Phone Type" }
                                options={ this.state.phoneTypes }
                                onChange={ (val) => {
                                    let o = this.state.newPhoneNumber;
                                    o.phoneType = val.value;
                                    this.setState({ newPhoneNumber: o });
                                } }
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color={ "success" }
                        floating
                        size="sm"
                        data-tip={ "Add Patient Phone" }
                        onClick={ () => this.createPatientPhone() }>
                        <Fa size="2x" icon={ "check" }>{ " " }</Fa>
                    </Button>
                    <Button
                        color={ "red" }
                        floating
                        size="sm"
                        data-tip={ "Cancel" }
                        onClick={ () => this.setState({ addPhoneModalOpen: false }) }>
                        <Fa size="2x" icon={ "times" }>{ " " }</Fa>
                    </Button>
                    <ReactTooltip />
                </ModalFooter>
            </Modal>
        );
    }

    renderNewComplaintNoteModal() {
        if(!this.state.complaintNoteModal){return}
        return (
            <MDBModal
                className={"salesPilotModal"}
                centered
                size="large"
                isOpen={ this.state.complaintNoteModal }
                toggle={ () => {
                    return;
                } }
            >
                <MDBModalHeader
                    style={ { borderRadius: "8px" } }
                >
                    <h3>Add New Feedback Note</h3>
                </MDBModalHeader>
                <MDBModalBody style={ { backgroundColor: "#FFFFFF" } }>


                    <Input
                        label={ "Note Text" }
                        value={ this.state.complaintNoteText }
                        onChange={ (e) =>
                            this.setState({ complaintNoteText: e.target.value })
                        }
                    />
                </MDBModalBody>
                <MDBModalFooter>
                    <Button
                        color={ "success" }
                        floating
                        size="sm"
                        data-tip={ "Add Feedback Note" }
                        onClick={ () => this.createComplaintNote() }
                    >
                        <Fa size="2x" icon={ "check" }>
                            { " " }
                        </Fa>
                    </Button>

                    <Button
                        color={ "red" }
                        floating
                        size="sm"
                        data-tip={ "Cancel" }
                        onClick={ () =>
                            this.setState({
                                currentComplaint: {},
                                complaintNoteModal: false,
                            })
                        }
                    >
                        <Fa size="2x" icon={ "times" }>
                            { " " }
                        </Fa>
                    </Button>
                    <ReactTooltip />
                </MDBModalFooter>
            </MDBModal>
        );
    }

    renderCancelTicketModal() {
        if (!this.state.cancelTicketModal) { return }
        return (
            <MUIDialog
                maxWidth="md"
                open={ this.state.cancelTicketModal }
                onClose={ () => this.setState({ cancelTicketModal: false }) }
            >
                <MUIDialogTitle variant="h6" color="textPrimary">
                    { "Cancel Ticket?" }
                </MUIDialogTitle>
                <MUIDialogContent>
                    <MUIDialogContentText variant="body1" color="textPrimary">
                         Are you sure you want to cancel this ticket?
                    </MUIDialogContentText>
                </MUIDialogContent>
                <MUIDialogActions>
                    <MUIButton
                        variant="text"
                        onClick={ () => this.setState({ cancelTicketModal: false }) }>Cancel</MUIButton>
                    <MUIButton variant="contained"
                        onClick={ () => {
                            this.cancelTicket()
                            this.setState({
                                cancelTicketModal: false,
                            })
                        } } autoFocus>
                        Confirm
                    </MUIButton>
                </MUIDialogActions>
            </MUIDialog>
        )
    }

    reopenCancelledTicket(r) {
        const { reasons } = this.context;
        let orderStatuses = this.state.orderStatuses;
        let reasonObj = null;
        let statusObj = null;

        if (r && r[0].statusFrom && r[0].statusFrom !== null) {
            statusObj = orderStatuses.filter((f) => f.label.toLowerCase().replace(/\s/g, '') === r[0].statusFrom.toLowerCase().replace(/\s/g, ''))
            this.statusChanged(statusObj[0].value)
        }

        if (r && r[0].reasonFrom && r[0].reasonFrom !== null) {
            reasonObj = reasons.filter((f) => f.description === r[0].reasonFrom)
            this.updateReason(reasonObj[0].id)
        } else {
            this.updateReason()
        }

        this.saveStatusReasonChange()

        this.setState({
            reopenCancelledTicketModal: false,
        })
    }

    renderPaymentInformationModal() {
        if (!this.state.paymentInformationModal) { return }
        let order = this.state.eOrder;
        let o = null;
        let appr = {};
        let statusSetup = false;


        if (this.state.orderRetrieved && order && order !== null) {
            o = order
            let hasApprovals = o.orderActivityApprovals && o.orderActivityApprovals.length > 0
            appr = o.orderActivityApprovals[0] ? o.orderActivityApprovals[0].approvals : null
            statusSetup = o.status === 3 || hasApprovals === false
        }

        if (!this.state.paymentInformationModal) { return }
        return (
            <MDBModal
                className={ "salesPilotModal" }
                centered
                size="large"
                isOpen={ this.state.paymentInformationModal }
                toggle={ () => {
                    this.setState({ paymentInformationModal: false });
                } }
            >
                <MDBModalHeader
                    style={ { borderRadius: "8px" } }
                >
                    <h3>Payment Information</h3>
                </MDBModalHeader>

                <MDBModalBody style={ { backgroundColor: "#FFFFFF" } }>
                    <MDBRow>
                        <MDBCol
                            size={ "6" }
                            className={ "insuranceSelects insuranceSelectsText" }
                        >
                            <MDBInput
                                type={ "number" }
                                outline
                                style={ { paddingTop: 8 } }
                                label={ "Deductible" }
                                disabled={ statusSetup }
                                valueDefault={ appr ? appr.deductible : "" }
                                onChange={ (evt) =>
                                    this.approvalAndUpdate("deductible", evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null)
                                }
                            />
                        </MDBCol>

                        <MDBCol
                            size={ "6" }
                            className={ "insuranceSelects insuranceSelectsText" }
                        >
                            <MDBInput
                                type={ "number" }
                                outline
                                style={ { paddingTop: 8 } }
                                label={ "Co-Insurance" }
                                disabled={ statusSetup }
                                valueDefault={ appr ? appr.coInsurance : "" }
                                onChange={ (evt) =>
                                    this.approvalAndUpdate("coInsurance", evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null)
                                }
                            />
                        </MDBCol>

                        <MDBCol
                            size={ "6" }
                            className={ "insuranceSelects insuranceSelectsText" }
                        >
                            <MDBInput
                                type={ "number" }
                                outline
                                style={ { paddingTop: 8 } }
                                label={ "Pt. Resp" }
                                disabled={ statusSetup }
                                valueDefault={ appr ? appr.patientResponsibility : "" }
                                onChange={ (evt) =>
                                    this.approvalAndUpdate(
                                        "patientResponsibility",
                                        evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null
                                    )
                                }
                            />
                        </MDBCol>

                        <MDBCol
                            size={ "6" }
                            className={ "insuranceSelects insuranceSelectsText" }
                        >
                            <MDBInput
                                type={ "number" }
                                outline
                                style={ { paddingTop: 8 } }
                                label={ "State Sales Tax" }
                                disabled={ true }
                                value={ appr ? appr.stateSalesTax : "" }
                                onChange={ (evt) =>
                                    this.approvalAndUpdate("stateSalesTax", evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null)
                                }
                            />
                        </MDBCol>

                        <MDBCol
                            size={ "12" }
                            className={ "insuranceSelects insuranceSelectsText" }
                        >
                            <MDBInput
                                type={ "number" }
                                outline
                                style={ { paddingTop: 8 } }
                                label={ "Payment On Delivery" }
                                disabled={ statusSetup }
                                valueDefault={ appr ? appr.paymentOnDelivery : "" }
                                onChange={ (evt) =>
                                    this.approvalAndUpdate(
                                        "paymentOnDelivery",
                                        evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null
                                    )
                                }
                            />
                        </MDBCol>

                        <MDBCol
                            size={ "12" }
                            className={ "insuranceSelects insuranceSelectsText" }
                        >
                            <MDBInput
                                type={ "number" }
                                outline
                                style={ { paddingTop: 8 } }
                                label={ "Payment Collected" }
                                disabled={ statusSetup }
                                valueDefault={ appr ? appr.paymentCollected : "" }
                                onChange={ (evt) =>
                                    this.approvalAndUpdate(
                                        "paymentCollected",
                                        evt.target.value && evt.target.value.length > 0 ? parseFloat(evt.target.value) : null
                                    )
                                }
                            />
                        </MDBCol>

                        {/* <MDBCol size={ "12" }>
                            <MDBBtn
                                disabled={ this.state.customerSuccessDisabled }
                                outline
                                onClick={ () => this.confirmCustomerSuccessDate() }
                            >
                                Customer Care Rep Called Patient{ " " }
                                { appr && appr.customerSuccessDate
                                    ? "on " + appr.customerSuccessDate
                                    : "" }
                            </MDBBtn>
                        </MDBCol> */}
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter >
                    <Button
                        color={ "success" }
                        floating
                        disabled={statusSetup || this.state.isUpdating ? true : false}
                        size="sm"
                        data-tip={ "Update" }
                        onClick={ () => {
                        this.setState({
                            isUpdating: true
                        })
                            this.updateOrder(o)
                    }
                        }
                    >
                        <Fa size="2x" icon={ "check" }>
                            { " " }
                        </Fa>
                    </Button>
                    <Button
                        color={ "red" }
                        floating
                        size="sm"
                        data-tip={ "Cancel" }
                        onClick={() => {
                            this.getApprovalsByOrder()
                            this.setState({
                                paymentInformationModal: false,
                            })
                        }
                        }
                    >
                        <Fa size="2x" icon={ "times" }>
                            { " " }
                        </Fa>
                    </Button>
                    <ReactTooltip />
                </MDBModalFooter>
            </MDBModal>
        )
    }

    toggleTimelineModalPopup() {
        let newState = !this.state.timelineModalOpen,
            order = this.state.eOrder;

        if (newState === false) {
            this.setState({
                timelineModalOpen: newState,
            });
        } else {
            OrderService.getOrderTimeline(order).then((records) => {
                this.setState({
                    timelineModalOpen: newState,
                    timelineModalOrder: order,
                    timelineModalEntries: records,
                });
            });
        }
    }

    formatTimelineStatus(timelineEntry) {
        if (timelineEntry.status === "ReadyToDeliver") {
            return "Waiting for Delivery";
        } else if (timelineEntry.status === "InProcess") {
            if (timelineEntry.reason === "Verification") {
                return "Intake / Insurance Verification";
            } else {
                return "Insurance Review";
            }
        } else {
            return timelineEntry.status;
        }
    }

    formatStatus = (status) => {
        const statuses = this.state.orderStatuses;
        let i = statuses.findIndex((x) => x.value === status);
        if (statuses[i] !== undefined) {
            return statuses[i].label;
        }
    };

    generateTimelineEntry() {
        if (this.state.timelineModalEntries == null) {
            return;
        }
        let sorted = []
            .concat(this.state.timelineModalEntries)
            .sort(function (a, b) {
                return new Date(a.updatedOn) - new Date(b.updatedOn);
            });
        return sorted.map((att, idx) => {
            let v = true;
            if (idx === 0 || idx % 2 === 0) {
                v = false;
            }
            return (
                <TimelineStep
                    color="red-gradient darken-4"
                    href="#void"
                    inverted={ v }
                    key={ idx }
                    icon="calendar-check-o"
                >
                    <span style={ { fontSize: "0.88rem" } }>
                        { GlobalFunctions.formatDateTime(att.updatedOn) }
                        <br />
                        { att.updatedBy }
                    </span>
                    <br />
                    <label style={ { fontWeight: 400 } }>
                        { this.formatTimelineStatus(att) }
                    </label>
                    <hr />
                    <span>{ att.reason }</span>
                </TimelineStep>
            );
        });
    }

    renderOrderTimeline() {
        return <Timeline>{ this.generateTimelineEntry() }</Timeline>;
    }

    renderTimelineModal() {
        if (!this.state.timelineModalOpen) { return }
        let order = this.state.eOrder;
        let o = null;
        let status = null
        let lastUpdated = null
        if (this.state.orderRetrieved && order && order !== null) {
            o = order
            status = o.status
            lastUpdated = o.lastUpdatedOn
        }

        return (
            <div>
                <Modal
                    className={ "extraWidth" }
                    isOpen={ this.state.timelineModalOpen }
                    toggle={ () => this.toggleTimelineModalPopup() }
                    fullHeight
                    position="left"
                >
                    <MDBModalHeader
                        style={ {
                            backgroundColor: "#1976D2",
                            color: "white",
                            borderBottom: "3px solid #145EA8",
                        } }
                    >
                        <h5>
                            { o && o !== null  ? this.formatStatus(status) : ""} --
                            Updated:{ " " }
                            { GlobalFunctions.formatDateTime( o && o !== null ? lastUpdated : new Date()) }
                        </h5>
                    </MDBModalHeader>
                    <ModalBody
                        className={ "stepContainer" }
                        style={ { backgroundColor: "#555" } }
                    >
                        { this.renderOrderTimeline() }
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    renderReopenTicketModal() {
        if (!this.state.reopenCancelledTicketModal) { return }
        let st = this.state,
            order = st.eOrder,
            records = st.ticketRecords;

        let r = [];
        let curDate = new Date();
        let lastUpdatedOnDate = null;
        let differenceInTime = null;
        let differenceInDays = null;
        let voidReopen = null;

        if (this.state.orderRetrieved && order && order !== null) {
            r = records
            if (this.state.ticketRecordsLoaded && r && r.length > 0) {
                lastUpdatedOnDate = new Date(r[0].updatedOn)
                differenceInTime = curDate.getTime() - lastUpdatedOnDate.getTime()
                differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24))
                if (differenceInDays > 90) {
                    voidReopen = true;
                } else {
                    voidReopen = false;
                }
            }
        }

        if (!this.state.reopenCancelledTicketModal) { return }

        return (
            <MUIDialog
                maxWidth="md"
                open={ this.state.reopenCancelledTicketModal }
                onClose={ () => this.setState({ reopenCancelledTicketModal: false }) }
            >
                <MUIDialogTitle variant="h6" color="textPrimary">
                    { "Re-Open Cancelled Ticket?" }
                </MUIDialogTitle>
                <MUIDialogContent>
                    <MUIDialogContentText variant="body1" color="textPrimary">
                        { !voidReopen ? "Are you sure you want to re-open this ticket?" : (
                            <>
                                <span style={ { display: "inline-block", paddingBottom: ".5rem" } }>This ticket has been cancelled for more than 90 days and <strong>may not</strong> be re-opened.</span>
                                <br />
                                <span style={ { display: "inline-block", color: MUIRedColor[500] } }>A new ticket is required.</span>
                            </>
                        )}
                    </MUIDialogContentText>
                </MUIDialogContent>
                <MUIDialogActions>
                    {!voidReopen ? (
                        <>
                            <MUIButton
                                variant="text"
                                onClick={ () => this.setState({ reopenCancelledTicketModal: false }) }>Cancel</MUIButton>
                            <MUIButton variant="contained"
                                onClick={ () => {
                                    this.reopenCancelledTicket(r)
                                    this.setState({
                                        reopenCancelledTicketModal: false,
                                    })
                                } } autoFocus>
                                Confirm
                            </MUIButton></>
                    ) : (
                            <MUIButton
                                variant="text"
                                onClick={ () => this.setState({ reopenCancelledTicketModal: false }) }>Cancel
                            </MUIButton>
                    )}

                </MUIDialogActions>
            </MUIDialog>
        )
    }

    renderAddAdditionalPartsModal() {
        if(!this.state.addAdditionalPartsModal){return}
        return (
            <MDBModal
                className={"salesPilotModal"}
                centered
                size="large"
                isOpen={ this.state.addAdditionalPartsModal }
                toggle={ () => {
                    this.setState({ addAdditionalPartsModal: false });
                } }
            >
                <MDBModalHeader
                    style={ { borderRadius: "8px" } }
                >
                    <h3>Add Additional Parts</h3>
                </MDBModalHeader>

                <MDBModalBody style={ { backgroundColor: "#FFFFFF" } }>
                    <Row>
                        <Col><label>Are you sure you want to move this ticket so additional parts can be added?</label></Col>
                    </Row>
                </MDBModalBody>
                <MDBModalFooter >
                    <Button
                        color={ "success" }
                        floating
                        size="sm"
                        data-tip={ "Add Additional Parts" }
                        onClick={ () => {
                            this.addAdditionalPArtsToTicket();
                        } }
                    >
                        <Fa size="2x" icon={ "check" }>
                            { " " }
                        </Fa>
                    </Button>

                    <Button
                        color={ "red" }
                        floating
                        size="sm"
                        data-tip={ "Close" }
                        onClick={ () =>
                            this.setState({
                                addAdditionalPartsModal: false,
                            })
                        }
                    >
                        <Fa size="2x" icon={ "times" }>
                            { " " }
                        </Fa>
                    </Button>
                    <ReactTooltip />
                </MDBModalFooter>
            </MDBModal>
        );
    }

    renderCloseComplaintModal() {
        if(!this.state.closeComplaintModal){return}
        return (
            <MDBModal
                className={"salesPilotModal"}
                centered
                size="large"
                isOpen={ this.state.closeComplaintModal }
                toggle={ () => {
                    this.setState({ closeComplaintModal: false });
                } }
            >
                <MDBModalHeader
                    style={ { borderRadius: "8px" } }
                >
                    <h3>Final Feedback Note</h3>
                </MDBModalHeader>

                <MDBModalBody style={ { backgroundColor: "#FFFFFF" } }>
                    <textarea
                        placeholder="Final feedback note text"
                        onChange={ (e) =>
                            this.setState({ complaintNoteText: e.target.value })
                        }
                        style={ { width: "100%", height: "200px" } }
                    >
                    </textarea>
                </MDBModalBody>
                <MDBModalFooter >
                    <Button
                        color={ "success" }
                        floating
                        size="sm"
                        data-tip={ "Create Final Note" }
                        onClick={ () => {
                            this.createComplaintNote();
                            this.closeComplaint();
                        } }
                    >
                        <Fa size="2x" icon={ "check" }>
                            { " " }
                        </Fa>
                    </Button>

                    <Button
                        color={ "red" }
                        floating
                        size="sm"
                        data-tip={ "Cancel" }
                        onClick={ () =>
                            this.setState({
                                currentComplaint: {},
                                closeComplaintModal: false,
                            })
                        }
                    >
                        <Fa size="2x" icon={ "times" }>
                            { " " }
                        </Fa>
                    </Button>
                    <ReactTooltip />
                </MDBModalFooter>
            </MDBModal>
        );
    }

    renderChangePasswordModal() {
        if(! this.state.isChangingPassword){return}
        var pt = this.state.appApproval;

        return (
            <MDBModal
                centered
                isOpen={ this.state.isChangingPassword }
                toggle={ () => this.setState({ isChangingPassword: false }) }
            >
                <MDBContainer>
                    <CardHeader
                        style={ { marginBottom: 2 } }
                        color={ "indigo" }
                        className="form-header text-center"
                    >
                        Change Patient Password
                    </CardHeader>
                    <MDBRow>
                        <MDBCol size="12">
                            <div>
                                <Input
                                    onChange={ (evt) => {
                                        this.setState({ newPassword: evt.target.value });
                                    } }
                                    filled
                                    label="New Password"
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="6"></MDBCol>
                        <MDBCol size="3">
                            <div style={ { paddingTop: 25 } }>
                                <MDBBtn
                                    size="sm"
                                    color={ "red" }
                                    onClick={ () =>
                                        this.setState({
                                            isChangingPassword: false,
                                            newPassword: null,
                                        })
                                    }
                                >
                                    cancel
                                </MDBBtn>
                            </div>
                        </MDBCol>
                        <MDBCol size="3">
                            <div style={ { paddingTop: 25 } }>
                                <MDBBtn
                                    size="sm"
                                    color={ "success" }
                                    onClick={ () => this.changePassword(pt.id) }
                                >
                                    Save
                                </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBModal>
        );
    }

    renderPatientNameSideCard() {
        const { companyName } = this.context;
        let patient = this.state.patient;
        let st = this.state;

        function convertInches() {
            let feetFromInches = Math.floor(patient.height / 12);
            let inchesRemainder = patient.height % 12;
            let result = feetFromInches + "'" + inchesRemainder + "\"";
            return result
        }

        return (
            <div>
                <MDBCardHeader className={ "patientNameSideCardHeader" }>
                    <MDBRow className={ "patientNameHeaderCol" }>
                        <MDBCol className={ "patientNameHeaderRow" }>
                            <td>{ patient.firstName + " " + patient.lastName }</td>
                        </MDBCol>

                    </MDBRow>
                </MDBCardHeader>
                <MDBCardBody className={ "patientSideCardBody" } style={ { paddingTop: 0 } }>
                    <MDBCol>
                        <MDBRow >
                            <td className={ "patientSideCardInfoText patientSideCardDOBRow" } style={ { paddingRight: "10px" } }>
                                { patient.dateOfBirth ? (patient.dateOfBirth.indexOf("T") > 0 ? patient.dateOfBirth.slice(0, patient.dateOfBirth.indexOf("T")).replaceAll("-", "/") : patient.dateOfBirth) : null }
                            </td>

                            <td className="patientSideCardInfoText patientSideCardDOBRow" style={ { paddingRight: "10px" } }>
                                { patient.gender && patient.gender !== null ? patient.gender.toLowerCase() !== "other" ? "Age: " + this.state.patientAge + patient.gender.substring(0, 1) : "Age: " + this.state.patientAge + "X" : null }
                            </td>

                            {patient.weight && patient.weight !== null ? (
                                <td className="patientSideCardInfoText patientSideCardDOBRow" style={ { paddingRight: "10px" } }>
                                    { patient.weight + "lbs" }
                                </td>
                            ) : null}

                            {patient.height && patient.height !== null ? (
                                <td className="patientSideCardInfoText patientSideCardDOBRow" style={ { paddingRight: "10px" } }>
                                    { convertInches() }
                                </td>
                            ) : null}

                        </MDBRow>

                        {patient.btId && patient.btId !== null ? (
                            <MDBRow>
                                <td className={ "sideCardSmallFont" }>
                                    { companyName !== "corkmedical" ? ("Brightree ID: " + (patient.btPId ? patient.btPId : "")) : "" }
                                </td>
                            </MDBRow>
                        ) : null}

                        {st.accountRecentSalesRep && st.accountRecentSalesRep !== null ? (
                            <MDBRow className={ "salesRepRow" }>
                                <td style={ { minWidth: '8rem' } }>
                                    { "Sales Rep: " }
                                </td>
                                { st.accountRecentSalesRep ?
                                    <td>{ st.accountRecentSalesRep.firstname + " " + st.accountRecentSalesRep.lastname }</td>
                                    : null }
                                {/* <td>{st.accountRecentSalesRep.firstname + " " + st.accountRecentSalesRep.lastname}</td> */ }

                                {/* <td>{ patient.account.owner.firstname + " " + patient.account.owner.lastname }</td> */ }
                            </MDBRow>
                        ) : (
                                <MDBRow style={{minHeight: "1.5rem"}}></MDBRow>
                        )}

                        {this.state.patientSalesLocation && this.state.patientSalesLocation !== null ? (
                            <MDBRow className={ "salesLocationRow" }>
                                <div>
                                    <td style={ { minWidth: '8rem' } }>
                                        { "Sales Location: " }
                                    </td>
                                    <td>{ this.state.patientSalesLocation ? this.state.patientSalesLocation.name : "" }</td>
                                </div>
                            </MDBRow>
                        ) : null}

                        <MDBRow style={ this.state.patientAge < 18 || this.state.lastChartNotes ? {marginTop: ".5rem", marginLeft: "-25px"} : {}}>
                            {/* {this.state.insuranceVerification.loading ?
                                <Chip
                                    label={ "Insurance Loading..." }
                                    variant={ "outlined" } size={ "small" }
                                    style={ { borderColor: "#8D8D8D32", color: "#5a5a5a", backgroundColor: "#F2f2f2" } }
                                   />
                                :
                                (this.state.insuranceVerification.insuranceVerified && this.state.insuranceVerification.inDateRange ?
                                    <Chip icon={ <MDBIcon icon="check-circle" className="insuranceVerifiedIcon" />
                                    } label={ "Insurance Verified" }
                                        className="clickableChip"
                                        variant={"outlined"} size={"small"}
                                        onClick={() => this.setState({ verificationModalOpen: companyName !== "corkmedical" ? true : false })}
                                        style={ { borderColor: "#52B48F32", color: "#195D17", backgroundColor: "#E5F9E4" } } /> :

                                    <Chip
                                        icon={ <MDBIcon icon="exclamation-triangle" className="insuranceNotVerifiedIcon" /> }
                                        label={ "Insurance Not Verified" }
                                        className="clickableChip"
                                        variant={"outlined"} size={"small"}
                                        onClick={() => this.setState({ verificationModalOpen: companyName !== "corkmedical" ? true : false })}
                                        style={ { borderColor: "#F9315432", color: "#881A2D", backgroundColor: "#FFE9E9"} } />
                                ) } */}

                            { this.state.patientAge < 18 ? <MUIChip label={ "Pediatric" } size={ "small" } className={ "chipRow" }
                                style={ { backgroundColor: "#5881C1", color: "white" } } /> : <div></div> }
                                {this.state.lastChartNotes ? (
                                <MUIChip label={"Chart Notes"}
                                    size={ "small" }
                                    className="clickableChip"
                                    style={ { margin: "0 .5rem", backgroundColor: "#E3EBFA", border: "1px solid #316BCF32", color: "#316BCF", borderRadius: "16px" } }
                                    onClick={ () => {
                                        OrderService.downloadDoc(this.state.lastChartNotes.id)
                                            .then((res) => {

                                                if (res) {
                                                    let file = new File([res], this.state.lastChartNotes.filename, null);
                                                    let url = window.URL.createObjectURL(file);
                                                    let temp = document.createElement("a");
                                                    temp.href = url;
                                                    temp.setAttribute("download", this.state.lastChartNotes.filename);
                                                    temp.click();
                                                    window.URL.revokeObjectURL(url);
                                                }
                                                else {
                                                    toast.warn("There was an issue downloading the file. It may not exist on the server yet.");
                                                }
                                            })
                                            .catch(err => {
                                                console.log(err);
                                                toast.error("There was an issue downloading the file.");
                                            })
                                    } }
                                />
                                ) : null}
                        </MDBRow>
                    </MDBCol>
                </MDBCardBody>
            </div>

        )
    }

    renderPatientContactSideCard() {
        let patient = this.state.patient;

        return (
            <div>
                <MDBCardHeader className={ this.state.canEditBasicInfo && !this.state.createServiceTicketModalOpen && this.state.patientInfoRetrieved !== false ? "patientSideCardHeader canEdit" : "patientSideCardHeader" }>
                    <div>Contact </div>
                    { this.state.TabsOneActiveItem === "3" || this.state.patientInfoRetrieved === false ? null : (<div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="sideCardEditBtn" width="24" height="24" viewBox="0 0 24 24" onClick={ () => this.handleEditBtnClick() }>
                            <g id="Group_206" data-name="Group 206" transform="translate(-4.422 -293.097)">
                                <g id="Group_167" data-name="Group 167" transform="translate(4.422 293.097)">
                                    <g id="MDI_square-edit-outline" data-name="MDI / square-edit-outline" transform="translate(0 0)" opacity="0.8">
                                        <rect id="Boundary" width="24" height="24" fill="#ffffff" opacity="0" />
                                        <path id="Path_square-edit-outline" data-name="Path / square-edit-outline" d="M5.25,3A2.242,2.242,0,0,0,3,5.25V21a2.25,2.25,0,0,0,2.25,2.25H21A2.25,2.25,0,0,0,23.25,21V13.125H21V21H5.25V5.25h7.875V3H5.25M19.628,4.125a.777.777,0,0,0-.54.225L17.715,5.711l2.813,2.812L21.9,7.162a.761.761,0,0,0,0-1.069L20.156,4.35a.751.751,0,0,0-.529-.225M16.916,6.51l-8.291,8.3v2.812h2.813l8.291-8.3Z" transform="translate(0.375 0.375)" fill="#ffffff" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>)}
                </MDBCardHeader>
                <MDBCardBody className={ "patientSideCardBody" }>
                    { !patient.address && !patient.phone && !patient.email ? (
                        <MDBCol style={{textAlign: "center", padding: "1rem 0"}}>
                            No Data
                        </MDBCol>
                    ) : (
                        <MDBCol>
                            { patient.address && patient.address !== null ? (
                                <MDBRow className="patientSideCardRow">
                                    <MDBCol sm={ "1" } className={ "iconCol" }>
                                        <MDBIcon icon={ "home" } className={ "sidePanelIcons" }></MDBIcon>
                                    </MDBCol>
                                    <MDBCol style={ { marginLeft: '1rem' } }>
                                        <MDBRow>
                                            <p className="sidePanelInfoSubheader subheaderWithIcon">Address</p>
                                        </MDBRow>
                                        <MDBRow>
                                            { patient.address ?
                                                <td className={ "patientSideCardInfoText subheaderWithIcon" }> { patient.address + (patient.address.slice(-1) === "," ||
                                                    patient.address.slice(-1) === "." ? " " : ", ") } </td>
                                                : <td className={ "patientSideCardInfoText subheaderWithIcon" }> No Address </td>
                                            }
                                        </MDBRow>
                                        <MDBRow style={ { marginTop: "10px" } }>
                                            <td className={ "patientSideCardInfoText subheaderWithIcon" }> { (patient.city ? patient.city + ", " : "")
                                                + (patient.state ? patient.state + ", " : "") + (patient.zipCode ? patient.zipCode : "") } </td>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            ) : null }
                            { patient.phone && patient.phone !== null ? (
                                <MDBRow className="patientSideCardRow">
                                    <MDBCol sm={ "1" } className={ "iconCol" }>
                                        <MDBIcon icon={ "phone-alt" } className={ "sidePanelIcons" }></MDBIcon>
                                    </MDBCol>
                                    <MDBCol style={ { marginLeft: '1rem' } }>
                                        <MDBRow>
                                            <p className="sidePanelInfoSubheader subheaderWithIcon">Home Phone</p>
                                        </MDBRow>
                                        <MDBRow>

                                            <td className={ "patientSideCardInfoText subheaderWithIcon" }> { patient.phone } </td>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            ) : null }
                            { patient.email && patient.email !== null ? (
                                <MDBRow className="patientSideCardRow">
                                    <MDBCol sm={ "1" } className={ "iconCol" }>
                                        <MDBIcon icon={ "envelope" } className={ "sidePanelIcons" }></MDBIcon>
                                    </MDBCol>
                                    <MDBCol style={ { marginLeft: '1rem' } }>
                                        <MDBRow>
                                            <p className="sidePanelInfoSubheader subheaderWithIcon">Email</p>
                                        </MDBRow>
                                        <MDBRow>

                                            <td className={ "patientSideCardInfoText subheaderWithIcon" }> { patient.email } </td>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            ) : null }
                        </MDBCol>
                    )}


                </MDBCardBody>
            </div>
        )
    }
    renderPatientInsuranceSideCard() {
        const { companyName } = this.context;
        let patient = this.state.patient;

        const buttons = [
            <MUIButton
                key="one"
                onClick={ () => this.setState({ verificationModalOpen: companyName !== "corkmedical" ? true : false }) }
            >
                Info
            </MUIButton>,
            this.state.curOrderId ? (
                <MUILoadingButton
                    key="two"
                    loading={ this.state.submitToPverifyLoading }
                    loadingPosition="center"
                    onClick={ () => this.submitChangesToPverify() }
                    data-tip="Submit to pVerify"
                >
                    { this.state.submitToPverifyLoading ? "" : "Verify" }
                </MUILoadingButton>

            ) : null

        ]
        return (
            <div>
                <MDBCardHeader className={ this.state.canEditInsuranceInfo && !this.state.createServiceTicketModalOpen && this.state.patientInfoRetrieved !== false ? "patientSideCardHeader canEdit" : "patientSideCardHeader" }>
                    <div>Insurance </div>
                    { this.state.TabsOneActiveItem === "2" || this.state.patientInfoRetrieved === false ? null : (<div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="sideCardEditBtn" width="24" height="24" viewBox="0 0 24 24" onClick={ () => this.handleEditInsuranceBtnClick() }>
                            <g id="Group_206" data-name="Group 206" transform="translate(-4.422 -293.097)">
                                <g id="Group_167" data-name="Group 167" transform="translate(4.422 293.097)">
                                    <g id="MDI_square-edit-outline" data-name="MDI / square-edit-outline" transform="translate(0 0)" opacity="0.8">
                                        <rect id="Boundary" width="24" height="24" fill="#ffffff" opacity="0" />
                                        <path id="Path_square-edit-outline" data-name="Path / square-edit-outline" d="M5.25,3A2.242,2.242,0,0,0,3,5.25V21a2.25,2.25,0,0,0,2.25,2.25H21A2.25,2.25,0,0,0,23.25,21V13.125H21V21H5.25V5.25h7.875V3H5.25M19.628,4.125a.777.777,0,0,0-.54.225L17.715,5.711l2.813,2.812L21.9,7.162a.761.761,0,0,0,0-1.069L20.156,4.35a.751.751,0,0,0-.529-.225M16.916,6.51l-8.291,8.3v2.812h2.813l8.291-8.3Z" transform="translate(0.375 0.375)" fill="#ffffff" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>)}

                </MDBCardHeader>
                <MDBCardBody className={ "patientSideCardBody" }>
                    { !patient.insuranceSubType && !patient.secondaryInsuranceType ? (
                        <MDBCol style={ { textAlign: "center", padding: "1rem 0" } }>
                            No Data
                        </MDBCol>
                    ) : (
                            <>
                                <MDBCol className={ "insuranceVerificationAlertCol" } >
                                    <MUIAlert
                                        className="insuranceAlert"
                                        severity={ this.state.insuranceVerification.insuranceVerified && this.state.insuranceVerification.inDateRange ? "success" : "error" }
                                        action={
                                            <MUIButtonGroup size="small" variant="outlined" color="inherit">
                                                { buttons }
                                            </MUIButtonGroup>
                                        }>
                                        {this.state.insuranceVerification.insuranceVerified && this.state.insuranceVerification.inDateRange ? "Verified" : "Not Verified" }
                                    </MUIAlert>
                                </MDBCol>
                                <MDBCol>
                                    { patient.insuranceSubType && patient.insuranceSubType !== null ? (
                                        <MDBRow className={ "patientSideCardRow" }>
                                            <MDBCol sm={ "1" } className={ "iconCol" }>
                                            <MDBIcon icon={ "shield-alt" } className={ "sidePanelIcons" }></MDBIcon>
                                        </MDBCol>
                                        <MDBCol sm={ "4" } style={ { marginLeft: '1rem', marginBottom: '1rem' } }>
                                            <MDBRow >

                                                <p className="sidePanelInfoSubheader subheaderWithIcon">Primary Insurance</p>
                                            </MDBRow>
                                            <MDBRow>
                                                <td className={ "patientSideCardInfoText subheaderWithIcon" }>{ patient.insuranceSubType ? patient.insuranceSubType.name : "" }</td>
                                            </MDBRow>
                                            <MDBRow className="patientSideCardPolicyGroupRow">
                                                <MDBCol md={ "6" } className={ "patientSideCardPolicyGroupCol" }><p className={ "patientSideCardPolicyGroupText" }>{ patient.insurancePolicy ? patient.insurancePolicy : "" }</p></MDBCol>
                                                <MDBCol md={ "6" } className={ "patientSideCardPolicyGroupCol" }><p className={ "patientSideCardPolicyGroupText" }>{ patient.insuranceGroup ? patient.insuranceGroup : "" }</p></MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                        <MDBCol sm={ "3" } className={ "borderedInsuranceCol" }>
                                            <MDBRow>
                                                <p className="sidePanelInfoSubheader">Start Date</p>
                                            </MDBRow>
                                            <MDBRow>
                                                <small style={ { marginTop: '-10px' } }>{ this.state.insuranceVerification.start } </small>
                                            </MDBRow>
                                        </MDBCol>
                                        <MDBCol sm={ "3" }>
                                            <MDBRow>
                                                <p p className="sidePanelInfoSubheader">End Date</p>
                                            </MDBRow>
                                            <MDBRow>
                                                <small style={ { marginTop: '-10px' } }>{ this.state.insuranceVerification.end } </small>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                ) : null }
                                { patient.secondaryInsuranceType && patient.secondaryInsuranceType !== null ? (
                                    <MDBRow >
                                        <MDBCol sm={ "1" } className={ "iconCol" }>
                                            <MDBIcon icon={ "shield-alt" } className={ "sidePanelIcons" }></MDBIcon>
                                        </MDBCol>
                                        <MDBCol sm={ "4" } style={ { marginLeft: '1rem', marginBottom: '.5rem' } }>
                                            <MDBRow>
                                                <p className="sidePanelInfoSubheader subheaderWithIcon">Secondary Insurance</p>
                                            </MDBRow>
                                            <MDBRow>
                                                <td className={ "patientSideCardInfoText subheaderWithIcon" }>{ patient.secondaryInsuranceSubType ? patient.secondaryInsuranceSubType.name : "" }</td>
                                            </MDBRow>
                                            <MDBRow className="patientSideCardPolicyGroupRow">
                                                <MDBCol md={ "6" } className={ "patientSideCardPolicyGroupCol" }><p className={ "patientSideCardPolicyGroupText" }>{ patient.secondaryInsurancePolicy ? patient.secondaryInsurancePolicy : "" }</p></MDBCol>
                                                <MDBCol md={ "6" } className={ "patientSideCardPolicyGroupCol" }><p className={ "patientSideCardPolicyGroupText" }>{ patient.secondaryInsuranceGroup ? patient.secondaryInsuranceGroup : "" }</p></MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                ) : null }

                            </MDBCol>
                        </>
                    ) }
                </MDBCardBody>
            </div>
        )
    }

    renderOpenOrdersSideCard() {
        return (
            <div className={ "sideCardOrderHeightDiv" }>
                <MDBCardHeader className={ "patientSideCardHeader" } style={ { marginBottom: 0 } }> Open Orders / Tickets </MDBCardHeader>
                <div className={ "sideCardDataGird" }>
                    <MDBCardBody className={ "patientSideCardBody sideCardOrderList" } >

                        { this.renderSideTable() }
                    </MDBCardBody>
                </div>
            </div>
        )
    }
    renderSideCard() {
        return <MDBCard className={ "patientSideCard" }>
            { this.renderPatientNameSideCard() }
            { this.renderPatientContactSideCard() }
            { this.renderPatientInsuranceSideCard() }
            { this.renderOpenOrdersSideCard() }
        </MDBCard>
    }

    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div>
                <ToastContainer
                    hideProgressBar={ false }
                    newestOnTop={ true }
                    autoClose={ 5000 }
                />
                { this.renderTabNav() }
                <Container style={ { minWidth: "100%" } }>
                    <Row className="patientInformationRow">
                        <Col  className={ "patientInformationCol sideBarColumn leftCol" }>
                            { this.renderSideCard() }
                        </Col>
                        <Col  className={ "patientInformationCol  middleCol"} id={"middleCol"}>
                            <TabContent
                                className="card patientInformationTabContent"
                                activeItem={ this.state.TabsOneActiveItem }
                            >
                                <TabPane tabId="1" role="tabpanel" >
                                    { this.renderOrdersCard() }
                                </TabPane>
                                <TabPane tabId="2" role="tabpanel">

                                    { this.renderPatientInformation() }
                                </TabPane>
                                <TabPane tabId="3" role="tabpanel">
                                    { this.renderInsuranceCard() }
                                </TabPane>
                                <TabPane tabId="4" role="tabpanel">
                                    { this.renderOrderDetailsCard() }
                                </TabPane>
                            </TabContent>
                        </Col>
                        <Col  className={ "patientInformationCol rightCol" }>
                            { this.renderRightCard()}
                        </Col>
                    </Row>
                </Container>
                { this.renderDialog ()}
                { this.renderNewOrderModal() }
                { this.renderCreateServiceTicketModal() }
                { this.renderNewComplaintModal() }
                {this.renderEditLocationInformationModal()}
                { this.renderEditGeneralInformationModal ()}
                { this.renderNewComplaintNoteModal() }
                { this.renderCloseComplaintModal() }
                { this.renderCancelTicketModal() }
                { this.renderAddAdditionalPartsModal() }
                { this.renderNewPatientNoteModal() }
                { this.renderPtnNoteModal() }
                { this.renderChangePasswordModal() }
                { this.renderNewPhoneModal() }
                { this.renderAddPartCreateQuoteModal() }
                { this.renderApprovalModal ()}
                {this.renderDeliveryNoteModal()}
                {this.renderReopenTicketModal()}
                {this.renderPaymentInformationModal()}
                {this.renderAbnModal()}
                { this.renderTimelineModal ()}
                { this.renderSearchOrderStatusDialog() }

                {this.state.noteModalOpen ?
                    <DialogAddNote
                        header={"Add Note"}
                        open={this.state.noteModalOpen}
                        onClose={() => this.toggleNoteModalPopup()}
                        getNotes={() =>
                            this.retrieveNotes(this.state.curOrderId)
                        }
                        eOrder={this.state.eOrder}
                        allNotes={this.state.notes}
                        toggleNoteModal={this.toggleNoteModalPopup.bind(this)}
                        addNoteUpdateOrderCloseModal={this.addNoteUpdateOrderCloseModal.bind(
                            this
                        )}
                        noteAssignedTo={this.state.patient.accountId}
                        text={this.state.noteText}
                        toggleProcessing={this.toggleProcessing.bind(this)}
                        isOrderDetails={this.state.activityId === null}
                        handleRouting={this.handleSubmitRouteChange.bind(this)}
                        approvalAction={this.state.radio.approvalAction}
                        queueRoute={this.state.radio.queueRouting}
                        activityId={this.state.activityId}
                        isAutoVerification={this.state.activityId !== null}
                    />
                    : null}


                <PverifyModal
                    patientId={this.state.id}
                    toggleInsuranceModal={() => this.setState({ verificationModalOpen: !this.state.verificationModalOpen, })}
                    insuranceModal={this.state.verificationModalOpen}
                    routeToVerification={() => {
                        var o = this.state.eOrder;
                        o.status = 1;
                        o.orderStatusReasonId = 15;
                        this.updateOrder(o);
                        this.setState({
                            verificationModalOpen: !this.state.verificationModalOpen,
                        });
                    }}
                    results={this.state.verificationResults}
                    eOrder={this.state.eOrder}
                    toggleNoteModal={(e,id) => {
                        this.setState({
                            activityId: id,
                            noteText: e,
                            verificationModalOpen: !this.state.verificationModalOpen,
                        }, this.toggleNoteModalPopup());
                    }}
                />
                <ReactTooltip />
            </div>
        );
    }
}