import moment from "moment";

class GlobalFunctions {

    ConvertStatus(statusNumber){
        switch(statusNumber){
            case 0:
                return "New"
            case 1:
                return "InProcess"
            case 2:
                return "ReadyToDeliver"
            case 3:
                return "Setup"
            case 4:
                return "Cancelled"
            default:
                return "Unknown"
        }
    }

    format(date, options) {
        if (date == null || date === "" || date.toString() === "Invalid date") {
            return null;
        }
        let d = new Date(),
            temp = new Date(date);

        // handling of bad day/month combos - Michael
        d.setUTCDate(1);

        d.setUTCFullYear(temp.getFullYear());
        d.setUTCMonth(temp.getMonth());
        d.setUTCDate(temp.getDate());
        d.setUTCHours(temp.getHours());
        d.setUTCMinutes(temp.getMinutes());
        d.setUTCSeconds(temp.getSeconds());
        d.setUTCMilliseconds(temp.getMilliseconds());

        return new Intl.DateTimeFormat("en-US", options).format(d);
    }

    //Returns Formatted Year-Month-Day-Hour-Minute-Second
    formatDateTime(date) {
        if (!date)
            return '';
        let options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "2-digit",
        }
        return this.format(date, options);
    }

    //Returns formatted Year-Month-Day-Hour-Minute-Second-TimeZone
    formatDateTimeTimeZone(date) {
        if (!date)
            return "";
        let options = {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "2-digit",
            timeZoneName: "short",
        }
        let res = this.format(date, options),
            pos = res.lastIndexOf(' ');
        res = res.substring(0,pos) + "-" + res.substring(pos+1)
        return res;
    }

    formatFlatDate(date) {
        if (!date)
            return date;
        var dt = new Date(date);
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }).format(dt);
    }

    //Returns formatted Hour-Minute-Second
	formatTime(date) {
        let options = {
            hour: "numeric",
            minute: "numeric",
            second: "2-digit",
        }
        return this.format(date, options);
	}

	//Returns formatted year-month-day
	formatDate(date) {
        let options = {
            year: "numeric",
            month: "short",
            day: "2-digit",
        }
        return this.format(date, options);
	}

	formatDateMoment(date) {
		if (date == null || date === "") {
			return "No Birthday Listed";
		}

		//MUST USE THIS FORMAT because of localdate/timezone issues pushing the DOB earlier...BC
		return moment.utc(new Date(date), "MM/DD/YYYY").format("MMMM DD, YYYY");
	}

    customFormatDateMoment(date, format) {
        if (date == null || date === "") {
            return "No Birthday Listed";
        }
        return moment.utc(new Date(date), "MM/DD/YYYY").format(format);
    }

    getUTCDate(){
        let d = new Date();
        return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0, 0);
    }

    //Returns moment formatted as "YYYY-MM-DDTHH:mm:ss"
    getUTCMoment(date){
        let q = new moment.utc(date ? date : new moment());
        return q.format("YYYY-MM-DDTHH:mm:ss")
    }

    // for dates created in SP, not utc: (DOB, etc.)
    formatJsDate(date) {
        if (!date)
            return null;
        var dt = new Date(date);
        var mo = dt.getMonth() + 1,
            dy = dt.getDate(),
            yr = dt.getFullYear();

        var res = (mo < 10 ? "0" + mo.toString() : mo.toString()) + "-" + (dy.toString() < 10 ? "0" + dy.toString() : dy.toString()) + "-" + yr.toString();
        return res;
    }
    formatJsDateTime(date) {
        if (!date)
            return null;
        var dt = new Date(date);
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "2-digit",
        }).format(dt);
    }

    //Compare two dates to find how many hours apart they are.
    // If date2 is empty, compare a date to this moment instead. Returns integer
    getHoursApart(date1, date2) {
        let date22 = new moment.utc(date2);
        let date11 = new moment.utc(date1);
        const diffInMs = Math.abs(date22 - date11);
        return diffInMs / (1000 * 60 * 60);
    }

    containsHcpcs(hcpc) {
        return [
            "K0001",
            "K0002",
            "K0003",
            "K0004",
            "K0005",
            "K0006",
            "K0007",
            "K0009",
            "E1161",
            "K0800",
            "K0801",
            "K0802",
            "K0813",
            "K0814",
            "K0815",
            "K0816",
            "K0820",
            "K0821",
            "K0822",
            "K0823",
            "K0824",
            "K0825",
            "K0826",
            "K0827",
            "K0828",
            "K0829",
            "K0830",
            "K0835",
            "K0836",
            "K0837",
            "K0838",
            "K0839",
            "K0840",
            "K0841",
            "K0842",
            "K0843",
            "K0848",
            "K0849",
            "K0850",
            "K0851",
            "K0852",
            "K0853",
            "K0854",
            "K0855",
            "K0856",
            "K0857",
            "K0858",
            "K0859",
            "K0860",
            "K0861",
            "K0862",
            "K0863",
            "K0864",
            "E2601",
            "E2602",
            "E2603",
            "E2604",
            "E2605",
            "E2606",
            "E2607",
            "E2608",
            "E2609",
            "E2610",
            "E2611",
            "E2612",
            "E2613",
            "E2614",
            "E2615",
            "E2616",
            "E2617",
            "E2618",
            "E2619",
            "E2620",
            "E2621",
            "E2622",
            "E2623",
            "E2624",
            "E2625",
            "E0260",
            "E0261",
            "E0262",
            "E0263",
            "E0264",
            "E0265",
            "E0277",
            "E0301",
            "E0302",
            "E0303",
            "E0304",
            "E0305",
            "E0371",
            "E0372",
            "E1038",
            "E0373"
        ].includes(hcpc);
    }

    getNpiByLocationId(id) {
        if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 18, 22, 23, 26, 30, 31, 32, 39, 44].includes(id))
            return { office: "Indianapolis North", npi: "1790714624", region: "MCR Region B" };
        else if ([11, 12, 13, 14, 19, 21, 25, 28, 29, 34, 35, 36, 37, 38, 40, 42, 43].includes(id))
            return { office: "Pensacola", npi: "1497166920", region: "MCR Region C" };
        else if ([15, 16, 17].includes(id))
            return { office: "Kansas City", npi: "1972616522", region: "MCR Region D" };
        else
            return null;
    }
}

const globalFunctions = new GlobalFunctions();

export default globalFunctions;