/**
 * Static functions for user settings. Saves everything to localStorage and
 * handles the (de)serialization automatically. Be sure to use good setting
 * names; namespacing to the layer is a good idea. Examples:
 *
 * ui.theme: light|dark
 * queue.68.view: {"sorts":["order_activity.id"]}
 */
export default class Setting {
    static memo = {};

    /**
     * Set a setting.
     *
     * Usage: Setting.set("foo", "bar");
     * Usage: Setting.set({"foo": "bar", "baz": "bam"});
     *
     * @param {string|Object} key Either the string key of the setting or an object containing all of the settings to set.
     * @param {*} value An optional value; must be serializable to JSON.
     */
    static set(key, value) {
        if (typeof key === "object") {
            Object.keys(key).forEach((k) => {
                delete this.memo[k];
                localStorage.setItem(k, JSON.stringify(key[k]));
                window.dispatchEvent(new Event(`setting.${k}`));
            });
        } else {
            delete this.memo[key];
            localStorage.setItem(key, JSON.stringify(value));
            window.dispatchEvent(new Event(`setting.${key}`))
        }
    }

    /**
     * Get a setting.
     *
     * @param {string} key The setting to get.
     *
     * @returns {*} The value of the setting or undefined if not found.
     */
    static get(key) {
        if (this.memo.hasOwnProperty(key) === false) {
            const item = localStorage.getItem(key);
            if (item === null) { // Not set
                this.memo[key] = undefined;
            } else {
                this.memo[key] = JSON.parse(item);
            }
        }
        return this.memo[key];
    }

    /**
     * Clear a setting.
     *
     * @param {string} key The setting to get.
     * @returns {*} The value of the setting or null if not found.
     */
    static clear(key) {
        delete this.memo[key];
        localStorage.removeItem(key);
        window.dispatchEvent(new Event(`setting.${key}`))
    }
}
