import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

export default withRouter(class CardAdminBaseUnitTypeList extends Card {
    static contextTypes = {
        baseUnitTypesIndexed: PropTypes.object
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "name", name: "Name", type: "string" },
                ]}
                rows={Object.values(this.context.baseUnitTypesIndexed)}
                onClick={(baseUnitType) => {
                    this.props.history.push({
                        pathname: "/admin/baseUnitType/" + baseUnitType.id
                    });
                }}
            />
        );
    }
});