import Crud from "../Crud";
import MUIBlueColor from "@mui/material/colors/blue";

/**
 * Static helper functions related to Teams.
 */
export default class Team extends Crud {
    /**
     * @param {object} team The team object.
     * @returns The display name of the team.
     */
    static getDisplayName(team) {
        return team.name;
    }

    /**
     * Determines if a team matches a search query.
     *
     * @param {object} team The team to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the team matches the query.
     */
    static match(team, query) {
        return (
            (team.name !== null && team.name.toLowerCase().includes(query)) ||
            team.id === +query
        );
    }

    /**
     * @param {array} teams The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(teams) {
        return teams.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {object} The color of this object.
     */
    static getBaseColor() {
        return MUIBlueColor;
    }

    /**
     * @returns {number} The base color shade of this object.
     */
    static getBaseColorShade() {
        return 800;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "People";
    }
}