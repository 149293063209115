import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
	Grid,
	Table,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar,
	TableColumnResizing,
	ColumnChooser, TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";

import {
	GroupingState,
	SortingState,
	IntegratedGrouping,
	IntegratedSorting,
	PagingState,
	IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
	Plugin,
	Template,
	TemplateConnector,
	TemplatePlaceholder,
} from "@devexpress/dx-react-core";

import {
	Button,
	Container,
	Col,
	Row,
	Spinner,
	toast,
	ToastContainer,
	MDBIcon,
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "./orderList.css";
import ReactTooltip from "react-tooltip";
import NoteService from "../Security/NoteService/noteService";
import GlobalFunctions from "../Filters/GlobalFunctions";

const ItemCounter = () => (
	<Plugin name="ItemCounter">
		<Template
			name="tableCell"
			predicate={({ tableRow }) => isGroupTableRow(tableRow)}
		>
			{(params) => (
				<TemplateConnector>
					{({ getCollapsedRows }) => {
						const updatedParams = {
							...params,
							tableRow: {
								...params.tableRow,
								row: {
									...params.tableRow.row,
									collapsedRows: getCollapsedRows(params.tableRow.row) || [],
								},
							},
						};
						return <TemplatePlaceholder params={updatedParams} />;
					}}
				</TemplateConnector>
			)}
		</Template>
	</Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

//const compareDate = (a, b) => {
//	//f1 and s1 should be a year eg 2018...BC
//	//f2 and s2 should be a month number eg 11,9,4...BC

//	let f1 = parseInt(a.substring(0, 4)),
//		f2 = parseInt(a.substring(5)),
//		s1 = parseInt(b.substring(0, 4)),
//		s2 = parseInt(b.substring(5));

//	if (f1 > s1) {
//		return 1;
//	} else if (f1 < s1) {
//		return -1;
//	}

//	//if we reach here, f1 and s1 are the same;

//	if (f2 > s2) {
//		return 1;
//	} else if (f2 < s2) {
//		return -1;
//	}

//	//if here, it is the same value but that shouldn't be possible;
//	return 0;
//};

function getVal(row) {
	if (row.collapsedRows.length > 0) {
		return "Count: " + row.collapsedRows.length;
	} else {
		return "";
	}
}

const Content = ({ row, column }) => (
	<span>
		<span>
			{column.title} : {row.value}
		</span>
		<span style={{ fontWeight: "bold" }}>&nbsp; {getVal(row)}</span>
	</span>
);

export default class InternalRequest extends React.Component {
	constructor(props) {
		super(props);
		console.log(props)
		const groupColumns = [
			{ columnName: "orderStatus" },
			{ columnName: "internalRequestReason" },
		];

		const data = {
			columns: [
				{
					title: "Id",
					name: "id",
				},
				{
					title: "Patient Name",
					name: "patientName",
				},
				{
					title: "Order Status",
					name: "orderStatus"
				},
				{
					title: "Internal Request Reason",
					name: "internalRequestReason"
				},
				{
					title: "Phone",
					name: "phone",
				},
				{
					title: "Address",
					name: "address",
				},
				{
					title: "City",
					name: "city",
				},
				{
					title: "State",
					name: "state",
				},
				{
					title: "Email",
					name: "email",
				},
				{
					title: "Payor Source",
					name: "payorSource",
				},
				{
					title: "Insurance",
					name: "insurance",
				},
				{
					title: "Reason",
					name: "reason",
				},
				{
					title: "Sales Location",
					name: "salesLocation",
				},
				{
					title: "Region",
					name: "region",
				},
				{
					title: "Last Note Date",
					name: "lastNoteDate",
				},
				{
					title: "Total Days",
					name: "totalDays",
				},

			],
			rows: [],
		};

		let defaultColumnWidths = [
			{ columnName: "id", width: 80 },
			{ columnName: "patientName", width: 200 },
			{ columnName: "orderStatus", width: 100 },
			{ columnName: "phone", width: 150 },
			{ columnName: "address", width: 300 },
			{ columnName: "city", width: 100 },
			{ columnName: "state", width: 80 },
			{ columnName: "email", width: 150 },
			{ columnName: "payorSource", width: 100 },
			{ columnName: "insurance", width: 150 },
			{columnName: "internalRequestReason", width: 200},
			{columnName: "reason", width: 200},
			{columnName: "salesLocation", width: 120},
			{ columnName: "region", width: 100 },
			{ columnName: "lastNoteDate", width: 100 },
			{ columnName: "totalDays", width: 100 },
		];

		let defaultHiddenColumnNames = ["phone", "address","city","state","email"];

		this.state = {
			data: data,
			isLoaded: false,
			grouping: [],
			groupingColumns: groupColumns,
			defaultColumnWidths: defaultColumnWidths,
			defaultHiddenColumnNames: defaultHiddenColumnNames,
			groupSummaryItems: [],
			irProduct: props.history && props.history.location && props.history.location.state && props.history.location.state.header ? props.history.location.state.header : null,
		};

		this.changeGrouping = (grouping) => {
			let ary = [];

			grouping.forEach((group) => {
				ary.push({ columnName: group.columnName });
			});
			this.setState({
				groupingColumns: ary,
			});
		};

		this.changeHiddenColumns = (columns) => {
			this.setState({
				defaultHiddenColumnNames: columns,
			});
		};

		this.getResults();
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	downloadFunction() {
		this.clientCSV(this.state.data, "Orders.csv");
	}

	//This will convert all the data in the grid to a csv file
	clientCSV(stateData, filename) {
		let result,
			ctr,
			keys = [],
			headers = [],
			columnDelimiter = ",",
			lineDelimiter = "\n",
			data;

		data = stateData.rows || null;
		if (data == null || !data.length) {
			return null;
		}

		stateData.columns.forEach((col) => {
			keys.push(col.name);
			headers.push(col.title);
		});

		let replace = ["category", "address", "patientName", "phone"];

		result = "";
		result += headers.join(columnDelimiter);
		result += lineDelimiter;

		data.forEach(function (item) {
			ctr = 0;
			keys.forEach(function (key) {
				if (ctr > 0) result += columnDelimiter;

				if (replace.indexOf(key) > -1 && item[key] != null) {
					result += item[key].replace(/,/g, " ");
				} else {
					result += item[key];
				}

				ctr++;
			});
			result += lineDelimiter;
		});

		this.downloadBlob(result, filename);
	}

	//this actually prompts the download
	downloadBlob(csvData, filename) {
		let blob = new Blob([csvData], {
			type: "application/csv;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a"),
				csvUrl = URL.createObjectURL(blob);

			link.href = csvUrl;
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	formatDate(date) {
		if (date == null || date === "") {
			return "";
		}
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "short",
			day: "2-digit",
		}).format(date);
	}

	getResults() {
		return OrderService.getInternalRequestViewNative()
			.then((res) => {
				console.log(res)
				this.buildNewResults(res);
			})
			.catch((err) => {
				this.setState({ isLoaded: true });
			});
	}

	buildNewResults(res) {
		let ary = [],
			dt = this.state.data,
			prod = this.state.irProduct;

		res.forEach((val) => {
			if (!prod || val.internalRequestReason === prod) {
				ary.push({
					id: val.orderId,
					patientName: val.patientName,
					orderStatus: val.orderStatus,
					internalRequestReason: val.internalRequestReason,
					phone: val.phone,
					address: val.address,
					city: val.city,
					state: val.state,
					email: val.email,
					payorSource: val.payorSource,
					insurance: val.insurance,
					reason: val.reason,
					salesLocation: val.salesLocation,
					region: val.region,
					lastNoteDate: val.lastNoteDate,
					totalDays: val.totalDays,
				});
			}
		});

		dt.rows = ary;

		toast.success("Found " + res.length + " Results");

		this.setState({
			data: dt,
			isLoaded: true,
		});
	}

	formatToolTip(result) {
		if (result == null) {
			return;
		}
		result = result.sort((a, b) => {
			let da = new Date(a.createdAt).getTime(),
				db = new Date(b.createdAt).getTime();
			return da > db ? -1 : 1
		});
		let strResult = "",
			count = result.length >= 2 ? 2 : result.length;
		if (result !== null && count > 0) {
			for (let i = 0; i < count; i++) {
				strResult +=
					"\nUser: " +
					result[i].createdBy.firstname + " " + result[i].createdBy.lastname +
					"\n" +
					"Date: " +
					GlobalFunctions.formatDate(result[i].createdAt) +
					"\n" +
					"Note: " +
					result[i].text;

				if (i < count - 1) {
					strResult += "\n----------------------";
				}
			}
		}
		return strResult;
	}

	getTooltipContent(id) {
		NoteService.getNotes(id)
			.then((res) => {
				this.setState({ tooltipContent: res });
				return res;
			})
			.catch((err) => {
				console.log(err);
			});
	}

	renderTable() {
		const CellWithTooltip = ({ value, style, ...restProps }) => {
			return (
				<Table.Cell
					data-tip
					data-event={"click focus"}
					data-for={"notes"}
					onMouseEnter={() => this.getTooltipContent(restProps.tableRow.row.id)}
					style={{
						cursor: "pointer",
					}}
				>
					{GlobalFunctions.formatFlatDate(value)}
					<ReactTooltip
						className={"note-tooltip"}
						id={"notes"}
						place={"left"}
						delayHide={0}
						globalEventOff={"click"}
						effect={"solid"}
						getContent={() => (
							<p> {this.formatToolTip(this.state.tooltipContent)} </p>
						)}
					/>
				</Table.Cell>
			);
		};

		const Cell = ({ row, column, ...props }) => {
			if (column.name === "lastNoteDate") {
				return <CellWithTooltip {...props} />;
			}

			if(column.name === "phone"){
				let ff = "tel:" + row.phone;

				return(
					<Table.Cell
						{...props}
						style={{
							cursor: "pointer",
						}}
					>
						<a href={ff}>{row.phone}</a>
					</Table.Cell>
				)
			}


			return (
				<Table.Cell
					{...props}
					onClick={(e) => {
						if (e.ctrlKey === true) {
							this.setState({
								targetLink: row.id,
							});

							setTimeout(() => {
								document.getElementById("orderListId").click();
							}, 5);
						} else {
							this.props.history.push({
								pathname: "/order/" + row.id,
								state: { goto: "internalRequest" },
							});
						}
					}}
					style={{
						cursor: "pointer",
					}}
				/>
			);
		};

		if (this.state.isLoaded === true) {
			return (
				<Grid
					style={{ maxWidth: "1800px !important" }}
					rows={this.state.data.rows}
					rootComponent={Root}
					columns={this.state.data.columns}
				>
					<DragDropProvider />
					<SortingState
						defaultSorting={[{ columnName: "setupMonth", direction: "desc" }]}
					/>
					<IntegratedSorting />
					<GroupingState
						grouping={this.state.groupingColumns}
						onGroupingChange={this.changeGrouping}
					/>
					<IntegratedGrouping />
					<PagingState defaultCurrentPage={0} pageSize={25} />
					<IntegratedPaging />
					<VirtualTable cellComponent={Cell} height={"auto"} />
					<TableColumnResizing
						defaultColumnWidths={this.state.defaultColumnWidths}
					/>
					<TableColumnVisibility
						onHiddenColumnNamesChange={this.changeHiddenColumns}
						defaultHiddenColumnNames={this.state.defaultHiddenColumnNames}
					/>
					<TableHeaderRow showSortingControls />
					<PagingPanel />
					<TableGroupRow contentComponent={Content} showColumnsWhenGrouped />
					<ItemCounter />
					<Toolbar />
					<ColumnChooser />
					<GroupingPanel />
				</Grid>
			);
		} else {
			return <div />;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderTableOrSpinner() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div className={"purchasingGrid ordersListGrid"}
				 style={{height: 0.9 * window.innerHeight, backgroundColor: "white"}}>
				{this.renderTable()}
			</div>
		);
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={true}
					newestOnTop={true}
					autoClose={3000}
					position={"top-right"}
					style={{ marginTop: "75px" }}
				/>

				<div style={{ maxWidth: "98%", marginLeft: "1rem" }}>
					<Row>
						<Col size={"12"}>{this.renderTableOrSpinner()}</Col>
					</Row>

					<Link
						id="orderListId"
						to={{
							pathname: `/order/${this.state.targetLink}`,
							state: { goto: "internalRequest" },
						}}
						target="_blank"
						rel="opener"
						style={{ display: "none" }}
						activeclassname="active"
					/>

					<div className={"fixed-bottom downloadCsvDiv"}>
						<Button
							className={"downloadCsvButton"}
							floating
							size="sm"
							color={"primary"}
							data-tip={"Download CSV"}
							onClick={this.clientCSV.bind(
								this,
								this.state.data,
								"InternalRequest.csv"
							)}
						>
							<MDBIcon icon="download" style={{ fontSize: "2em" }} />
						</Button>

						<ReactTooltip />
					</div>
				</div>
			</div>
		);
	}
}
