import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import IssueReason from "../../Utility/Crud/IssueReason";
import IssueCategory from "../../Utility/Crud/IssueCategory";
import PropTypes from "prop-types";

export default class TileIssueReason extends Tile {
    static contextTypes = {
        issueCategoriesIndexed: PropTypes.object,
    };

    /**
      * @returns {string} The IssueReason name.
      */
    getTitle() {
        return this.props.issueReason ? IssueReason.getDisplayName(this.props.issueReason) : "None";
    }

    /**
      * @returns {string} The IssuReason subtitle.
      */
    getSubtitle() {
        return this.props.issueReason ? IssueCategory.getDisplayName(
            this.context.issueCategoriesIndexed[this.props.issueReason.issueCategoryId]
        ) : null;
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.issueReason ? IssueReason.getDisplayName(this.props.issueReason) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.issueReason ? IssueReason.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return IssueReason.getIcon();
    }
}
