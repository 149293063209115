import React from "react";
import Chip from "../Chip";
import MUIGreyColor from "@mui/material/colors/grey";
import MUIAvatar from "@mui/material/Avatar";
import IssueStatus from "../../Utility/Crud/IssueStatus";

export default class ChipIssueStatus extends Chip {
    /**
     * Renders a MUI Avatar.
     */
    renderAvatar() {
        const color = (this.props.issueStatus ? IssueStatus.getColor() : MUIGreyColor[500]);
        const avatarColor = this.props.isTransparent ? "transparent" : color;
        const iconColor = this.props.isTransparent ? color : "#fff";

        return (
            <MUIAvatar sx={{ bgcolor: avatarColor }}>
                {IssueStatus.getIcon({ fontSize: "small", sx: { color: iconColor } })}
            </MUIAvatar>
        );
    }

    /**
     * @return {string} The issueStatus name.
     */
    getLabel() {
        return this.props.issueStatus ? IssueStatus.getDisplayName(this.props.issueStatus) : "None";
    }
}
