import React from "react";
import Chip from "../Chip";
import MUIGreyColor from "@mui/material/colors/grey";
import MUIAvatar from "@mui/material/Avatar";
import OrderStatus from "../../Utility/Crud/OrderStatus";
import PropTypes from "prop-types";
import * as MUIColors from "@mui/material/colors";

export default class ChipOrderStatus extends Chip {
    static contextTypes = {
        colorsIndexed: PropTypes.object,
    };
    /**
     * Renders a MUI Avatar.
     */
    renderAvatar() {
        const colorObject = this.props.orderStatus ? this.context.colorsIndexed[this.props.orderStatus.colorId] : null;
        const color = (this.props.orderStatus ? MUIColors[colorObject.hue][colorObject.shade] : MUIGreyColor[500]);
        const avatarColor = this.props.isTransparent ? "transparent" : color;
        const iconColor = this.props.isTransparent ? color : "#fff";

        return (
            <MUIAvatar sx={{ bgcolor: avatarColor }}>
                {OrderStatus.getIcon({ fontSize: "small", sx: { color: iconColor } })}
            </MUIAvatar>
        );
    }

    /**
     * @return {string} The orderStatus description.
     */
    getLabel() {
        return this.props.orderStatus ? OrderStatus.getDisplayName(this.props.orderStatus) : "None";
    }
}
