import api from "../api";

class PatientPhoneService {
	async getPatientPhones(patientId) {
		return new Promise((res, reject) => {
			api
				.get("/patientphones/getbypatient/" + patientId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async addPatientPhones(id, phoneNumbers) {
		return new Promise((res, reject) => {
			api
				.post("/patientphones/add/" + id, phoneNumbers)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async deletePatientPhone(id) {
		return new Promise((res, reject) => {
			api
				.delete("/patientphones/remove/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async changePassword(phoneNumber) {
		return new Promise((res, reject) => {
			api
				.put("/patientphones/update", phoneNumber)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}

const patientPhoneService = new PatientPhoneService();

export default patientPhoneService;
