import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import IssueCategory from "../../Utility/Crud/IssueCategory";

export default class TileIssueCategory extends Tile {
    /**
      * @returns {string} The IssueCategory name.
      */
    getTitle() {
        return this.props.issueCategory ? IssueCategory.getDisplayName(this.props.issueCategory) : "None";
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.issueCategory ? IssueCategory.getDisplayName(this.props.issueCategory) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.issueCategory ? IssueCategory.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return IssueCategory.getIcon();
    }
}
