import api from "../api";

class MarketingService {
	async findAllCampaigns() {
		return new Promise((res, reject) => {
			return api
				.get("/campaigns")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getCampaign(id) {
		return new Promise((res, reject) => {
			return api
				.get("/campaigns/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async updateCampaign(campaign) {
		return new Promise((res, reject) => {
			return api
				.put("/campaigns", campaign)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createCampaign(campaign) {
		return new Promise((res, reject) => {
			return api
				.post("/campaigns", campaign)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async deleteCampaign(campaign) {
		return new Promise((res, reject) => {
			return api
				.delete("/campaigns/" + campaign.id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async findAllCategories() {
		return new Promise((res, reject) => {
			return api
				.get("/campaigns/category")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getCategory(id) {
		return new Promise((res, reject) => {
			return api
				.get("/campaigns/category/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	// calls proc spMarketInsuranceMapping
	async getInsuranceFromMapping(params) {
		return new Promise((res, reject) => {
			return api
				.get("/marketingInsuranceMapping", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async updateCategory(category) {
		return new Promise((res, reject) => {
			return api
				.put("/campaigns/category", category)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createCategory(category) {
		return new Promise((res, reject) => {
			return api
				.post("/campaigns/category", category)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async deleteCategory(category) {
		return new Promise((res, reject) => {
			return api
				.delete("/campaigns/category/" + category.id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getActiveCampaigns() {
		return new Promise((res, reject) => {
			return this.findAllCampaigns().then((result) => {
				return res(
					result.filter(function (item) {
						return item.isActive;
					})
				);
			});
		});
	}
}

const marketingService = new MarketingService();

export default marketingService;
