import api from "../api";

class VersionService {

	// calls proc spCurrentWebVersion
	async getCurrentVersion() {
		return new Promise((res, reject) => {
			api
				.get("/currentVersion")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async checkCurrentVersion(company) {
		let c = company || "dev";
		return new Promise((res, reject) => {
			let url = api.buildURL(c);
			fetch(url + "/currentVersion")
				.then((res) => {
					return res.json();
				})
				.catch((err) => {
					reject(err);
				})
				.then((data) => {
					return res(data);
				});
		}).catch((err) => {
							console.log(err);
						});
	}

	async getVersions() {
		return new Promise((res, reject) => {
			api
				.get("/versions")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async addVersion(version) {
		return new Promise((res, reject) => {
			api
				.post("/versions", version)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async addSrc(versionId, file) {
		return new Promise((res, reject) => {
			api
				.downloadPost("/version/" + versionId, file)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async downloadBuild(id) {
		return new Promise((res, reject) => {
			return api
				.get("/versionFile/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}

const versionService = new VersionService();

export default versionService;
