import api from "../api";

class PatientOrdersService {

	// calls proc spGetPatientProducts
	async getPatientProducts(patientId) {
		return new Promise((res, reject) => {
			api
				.get("/patientProducts/" + patientId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getPatientOrders(patientId) {
		return new Promise((res, reject) => {
			api
				.get("/patientOrders/" + patientId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async syncPatientToBT(patientId) {
		return new Promise((res, reject) => {
			api
				.get("/syncPatientToBt/" + patientId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

}

const patientOrdersService = new PatientOrdersService();

export default patientOrdersService;
