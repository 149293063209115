import React from "react";
import PropTypes from "prop-types";
import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {Route} from "react-router-dom";
import {
	Grid,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar, TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

import {
	GroupingState,
	SortingState,
	IntegratedSorting,
	IntegratedGrouping,
	PagingState,
	IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
	Plugin,
	Template,
	TemplateConnector,
	TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import {
	Container,
	Spinner,
	ToastContainer,
	MDBIcon,
} from "mdbreact";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "../PurchaseQueue/purchaseGrid.css";
import moment from "moment";
import NotificationTypesService from "../Security/NotificationService/NotificationTypesService";

export default class PcrNotificationsTable extends React.Component {
	constructor(props) {
		super(props);

		const status = this.props.status;

		const groupColumns = [];

		const data = this.buildData();

		let defaultColumnWidths = [
			{ columnName: "viewOrder", width: 120 },
			{ columnName: "orderId", width: 120 },
			{ columnName: "rep", width: 120 },
			{ columnName: "name", width: 200 },
			{ columnName: "notification", width: 550 },
			{ columnName: "sentOn", width: 120 },
		];

		this.state = {
			data: data,
			isLoaded: false,
			grouping: [],
			groupingColumns: groupColumns,
			defaultColumnWidths: defaultColumnWidths,
			groupSummaryItems: [],
			status: status,
			approvalModal: false,
			nPurchaseQueues: [],
			brightreeId: "",
			errorMessage: "",
			tasks: props.tasks,
		};

		this.changeGrouping = (grouping) => {
			let ary = [];

			grouping.forEach((group) => {
				ary.push({ columnName: group.columnName });
			});
			this.setState({
				groupingColumns: ary,
			});
		};

	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allUsers: PropTypes.array
	};

	buildData() {
		let d = {
			columns: [
				{
					title: "View Order",
					name: "viewOrder",
				},
				{
					title: "Order ID",
					name: "orderId",
				},
				{
					title: "Rep",
					name: "rep",
				},
				{
					title: "Type",
					name: "name",
				},
				{
					title: "Notification",
					name: "notification",
				},
				{
					title: "Sent On",
					name: "sentOn",
				},

			],
			rows: [],
		};

		return d;
	}

	Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

	CellContent = ({ row, column, expanded, ...props }) => {
		let cell = (
			<TableGroupRow.Cell
				{...props}
				row={row}
				column={column}
				expanded={expanded}
			/>
		);
		return cell;
	};

	Content = ({ row, column }) => (
		<span>
			<span>
				{column.title} : {row.value || "(Blank)"}
			</span>
			<span style={{ fontWeight: "bold" }}>&nbsp; {this.getVal(row)}</span>
		</span>
	);

	componentDidMount() {
		this.getResults();
	}

	getVal(row) {
		if (row.collapsedRows.length > 0) {
			return "Count: " + row.collapsedRows.length;
		} else {
			return "";
		}
	}

	getResults() {
		if(this.state.isLoaded){
			return
		}
		const {currentUser} = this.context;
		let ary = [],
			dt = this.state.data;
        NotificationTypesService.getNotificationsForPCR(currentUser.id)
            .then(r => {
                r.forEach((val, idx) => {
				    let row = {
                        viewOrder: val.orderId ? this.createBtn(val.orderId) : "",
                        orderId: val.orderId ? val.orderId : "No order ID",
                        name:  val.name ? val.name : "",
                        rep: val.owner ? val.owner.firstname + " " + val.owner.lastname : "",
                        notification: val.notification ? val.notification : "No notifications",
                        sentOn: val.notificationSentOn ? moment(val.notificationSentOn).format("YYYY-MM-DD") : "",
                    };
                    ary.push(row);
                });

		        dt.rows = ary;
				this.setState({
					data: dt,
					isLoaded: true,
				});


            }).catch(e => {
                console.log(e);
            });
	}

	createBtn(id) {
        return (
            <Route
                render={({history}) => (
                    <MDBIcon icon="forward"
                                size={"2x"}
                                id="btn"
                                style={{color: "#6b93c4"}}
                        // onClick={() => this.completeTask(id, idx)}
                                onClick={(e) => {
                                    history.push({
                                        pathname: "/order/" + id,
                                    });
                                }}/>
                        )}/>
        );
	}

	renderTable() {
		let st = this.state;
		return (
			<Grid
				rootComponent={this.Root}
				rows={st.data.rows}
				columns={st.data.columns}
			>
				<PagingState defaultCurrentPage={0} pageSize={50} />
				<DragDropProvider />
				<SortingState
					defaultSorting={[{ columnName: "status", direction: "desc" }]}
				/>

				<GroupingState
					columnGroupingEnabled={true}
					defaultGrouping={st.groupingColumns}
                    // columnExtensions={st.disabledGroupings}
				/>
				<IntegratedGrouping />
				<IntegratedPaging />
				<IntegratedSorting />
				<VirtualTable height={"auto"} />
				<PagingPanel />
				<TableColumnResizing
					defaultColumnWidths={this.state.defaultColumnWidths}
				/>
				<TableHeaderRow showSortingControls />
				<TableGroupRow
					cellComponent={this.CellContent}
					contentComponent={this.Content}
					showColumnsWhenGrouped={false}
				/>
				{this.ItemCounter()}
				<Toolbar />
				<GroupingPanel />
			</Grid>
		);
	}

	ItemCounter = () => (
		<Plugin name="ItemCounter">
			<Template
				name="tableCell"
				predicate={({ tableRow }) => isGroupTableRow(tableRow)}
			>
				{(params) => (
					<TemplateConnector>
						{({ getCollapsedRows, rows }) => {
							const updatedParams = {
								...params,
								tableRow: {
									...params.tableRow,
									row: {
										...params.tableRow.row,
										collapsedRows: getCollapsedRows(params.tableRow.row) || [],
										rows: rows.filter((r) => {
											return r.groupedBy === undefined;
										}),
									},
								},
							};
							return <TemplatePlaceholder params={updatedParams} />;
						}}
					</TemplateConnector>
				)}
			</Template>
		</Plugin>
	);

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}


    formatDate(date) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "numeric",
            day: "2-digit",
        }).format(date);
    }

	renderTableOrSpinner() {
		if(!this.state.isLoaded){
			return this.renderLoadingSpinner();
		}
		else
		return (
			<div className={"purchasingGrid"} style={{ height: "100%" }}>
				{this.renderTable()}
			</div>
		);
	}

	render() {
		let h = window.innerHeight;
		return (
			<div style={{ overflowY: "scroll", height: h * 0.7, maxHeight: h * 0.7 }}>
				<ToastContainer
					hideProgressBar={true}
					newestOnTop={true}
					autoClose={3000}
					position={"top-right"}
					style={{ marginTop: "75px" }}
				/>
				{this.renderTableOrSpinner()}
			</div>
		);
	}
}
