import React from "react";
import PropTypes from "prop-types";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	DataTable,
	MDBIcon,
	Row,
	Spinner,
} from "mdbreact";
import ContactService from "../Security/ContactService/contactService";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

export default class contactsList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "Name",
					field: "username",
					sort: "asc",
					width: 200,
				},
				{
					label: "Type",
					field: "contactType",
					sort: "asc",
					width: 200,
				},
				{
					label: "Title",
					field: "role",
					sort: "asc",
					width: 200,
				},
				{
					label: "Phone",
					field: "phone",
					sort: "asc",
					width: 200,
				},
				{
					label: "Email",
					field: "email",
					sort: "asc",
					width: 200,
				},
				{
					label: "Account",
					field: "account",
					sort: "asc",
					width: 200,
				},
				{
					label: "Sales Rep",
					field: "owner",
					sort: "asc",
					width: 200,
				},
				{
					label: "Edit",
					field: "button",
					sort: "asc",
					width: 200,
				},
			],
			rows: [],
		};

		this.state = {
			contacts: data,
			isLoaded: true,
			users: [],
			allLocs: [],
			allSalesReps: [],
			locationsSelect: [
				{
					label: "Loading...",
					value: "loading",
				},
			],
			salesRepsSelect: [
				{
					label: "Loading...",
					value: "loading",
				},
			],
			locationsSelected: [],
			salesRepsSelected: null,
			search: "",
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		salesReps: PropTypes.array,
		allLocations: PropTypes.array,
	};

	componentDidMount(){
		this.getLocationsOptions();
		this.getSalesRepsOptions();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { salesReps, currentUser, allLocations } = this.context;
		let st = this.state;
		if (
			st.allLocs.length !== allLocations.length &&
			st.currUser !== currentUser
		) {
			this.getLocationsOptions();
		}
		if (st.allSalesReps.length !== salesReps.length) {
			this.getSalesRepsOptions();
		}
	}

	getSalesRepsOptions(e) {
		const { salesReps } = this.context;

        this.setState({
            salesRepsSelected: [],
		});
		let r = [],
			locs = e && e.length > 0 ? e.map(x => x.value) : null;

		salesReps.map(l => {
			if (locs) {
				// console.log(l.userprofileLocations.filter(x => locs.includes(x.locationsId.toString())).length > 0);
				if (l.userprofileLocations && l.userprofileLocations.filter(x => locs.includes(x.locationsId)).length > 0) {
					return r.push({
						label: l.firstname + " " + l.lastname,
						value: l.id,
					})
                }
			}
			else {
				return r.push({
					label: l.firstname + " " + l.lastname,
					value: l.id,
                })
			}
			return null;
		})

		this.setState({
			salesRepsSelect: r,
			allSalesReps: salesReps,
		});
	}

	getLocationsOptions() {
		const { allLocations, currentUser } = this.context;
		let cu = currentUser;
		let select = [],
			locations = [];
		if (cu.role === "SALES") {
			locations = cu.userprofileLocations.map(x => x.locations);
		} else {
			locations = allLocations;
		}
		locations.map((location) => {
			// Populates the locations dropdown depending on which locations the user is in...JK
			if (location.type === 0) {
				return select.push({
					label: location.name,
					value: location.id,
				});
			}
			return null;
		});
		this.setState({
			locationsSelect: select,
			allLocs: allLocations,
		});
	}


	retrieveContacts() {
		let t = this,
			salesRepSelected = this.state.salesRepsSelected,
			q = this.state.search,
			arr = [];

		if (salesRepSelected && salesRepSelected.value) {
			arr.push(salesRepSelected.value);
		}

		let filters = {
			ids: arr,
			q: q,
		};

		ContactService.getAllContacts(filters)
			.then((res) => {
				let ary = [],
					contacts = res || [],
					dt = this.state.contacts;

				contacts.forEach((contact, index) => {
					ary.push({
						username: contact.name,
						contactType: contact.type || "No Type",
						role: contact.title || "No Role",
						phone: contact.phone || "No Contact",
						email: contact.email || "No Email",
						account: contact.account || "No Account",
						owner: contact.salesrep || "No Owner",
						button: t.renderRouteButton(contact, index),
					});
				});

				dt.rows = ary;

				t.setState({
					contacts: dt,
					currContacts: contacts,
					isLoaded: true,
				});
			})
			.catch((err) => {
				console.log(err)
			});
	}

	renderRouteButton(contact, index) {
		return (
			<MDBIcon
				icon="edit"
				key={index}
				aria-hidden="true"
				color="warning"
				onClick={() => {
					this.props.history.push({
						pathname: "/contact/" + contact.id,
						state: { contact: contact },
					});
				}}
			></MDBIcon>
		);
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<DataTable striped info={false} small data={this.state.contacts}>
					{" "}
				</DataTable>
			);
		} else {
			return this.renderLoadingSpinner();
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	exportClicked = () => {
		let contacts = this.state.currContacts,
			s = "";
		contacts.map((c) => {
			return (s += this.generateContactString(c));
		});
		this.downloadBlob(s, "contacts.vcf");
	};

	downloadBlob(contactData, filename) {
		let blob = new Blob([contactData], {
			type: "application/vcf;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	generateContactString(contact) {
		let account = contact.account ? contact.account.name : "",
			fname = contact.firstname || "",
			lname = contact.lastname || "",
			role = contact.role || "",
			phone = contact.phone || "",
			email = contact.email || "";
		return (
			"BEGIN:VCARD\n" +
			"VERSION:2.1\n" +
			"N:" +
			lname +
			";" +
			fname +
			";\n" +
			"FN:" +
			fname +
			" " +
			lname +
			"\n" +
			"ORG:" +
			account +
			"\n" +
			"TITLE:" +
			role +
			"\n" +
			"TEL;WORK;VOICE:" +
			phone +
			"\n" +
			"EMAIL:" +
			email +
			"\n" +
			"END:VCARD\n"
		);
	}

	renderFilter() {
		let st = this.state;
		return (
			<Card style={{ paddingTop: "10px", paddingBottom: "6px",  marginBottom: "1%", backgroundColor: "#5881C1" }}>
				<CardBody className={"filterSearchBoxCardBody"}>
					<Row>
						<Col md={"6"} className={"filterSearchBoxCol"}>
							<Select
								placeholder="Select Location"
								closeMenuOnSelect={false}
								isMulti
								options={st.locationsSelect}
								onChange={this.handleLocationChange.bind(this)}
								value={st.locationsSelected}
							/>
						</Col>
						<Col md={"3"} className={"filterSearchBoxCol"}>
							<Select
								placeholder="Select Sales Rep"
								isClearable
								options={st.salesRepsSelect}
								onChange={this.handleSaleRepChange.bind(this)}
								value={st.salesRepsSelected}
							/>
						</Col>
						<Col md={"2"} className={"filterSearchBoxCol expenseDateOptions"}>
							<form
								onSubmit={(e) => {
									this.filterClicked(e);
								}}
							>
							<input
								className="form-control my-0 py-1 listSearchInputBackground"
								type="text"
								placeholder="Search...."
								aria-label="Search"
								onChange={(e) => {
									this.setState({ search: e.target.value });
								}}
							/>

							</form>
						</Col>
						<Col md={"1"} className={"filterSearchBoxCol"}>
							<Button
								floating
								size="sm"
								color={"indigo"}
								data-tip={"Search Contacts"}
								onClick={(e) => {
									this.filterClicked(e);
								}}
							>
								<MDBIcon icon="search" style={{ fontSize: "2em" }} />
							</Button>
						</Col>
						<ReactTooltip />
					</Row>
				</CardBody>
			</Card>
		);
	}

	handleLocationChange = (e) => {
		this.setState({
			locationsSelected: e,
		});
		this.getSalesRepsOptions(e);
	};

	handleSaleRepChange = (e) => {
		this.setState({
			salesRepsSelected: e,
		});
	};

	filterClicked = (e) => {
		e.preventDefault();
		this.setState({
			isLoaded: false,
		});
		this.retrieveContacts();
	};

	render() {
		return (
			<div >
				{this.renderFilter()}
				<Card style={{ marginBottom: "1%", marginLeft: "5%", marginRight: "5%" }}>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				<Button
					disabled={!this.state.isLoaded}
					floating
					size="sm"
					data-tip={"Download Contact Cards"}
					onClick={this.exportClicked}
				>
					<MDBIcon icon="download" style={{ fontSize: "2em" }} />
				</Button>
				<ReactTooltip />
			</div>
		);
	}
}
