import React from "react";
import { Button, CardGroup, CardFooter, Card, CardBody } from "mdbreact";
import { CirclePie } from "salad-ui.chart";
import "./home.css";
import { Route } from "react-router-dom";

export default class cardList extends React.Component {
	constructor(props) {
		super(props);

		//set function from parent
		this.toggleTimelineModalPopup = props.toggleTimelineFunction;
		this.toggleNoteTooltip = props.toggleNotesFunction;

		this.state = {
			orders: props.orders || [],
			sortedOrders: props.orders || [],
			timelineModalOpen: false,
			timelineModalOrder: {},
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ sortedOrders: nextProps.sortedOrders });
	}

	shouldComponentUpdate(nextState) {
		return nextState.sortedOrders !== this.state.sortedOrders;
	}

	formatStatus(order) {
		let obj = {
			text: "",
			gradient: "",
		};

		if (order.status === 2) {
			obj.text = "Waiting for Delivery";
			obj.gradient = "purple";
		} else if (order.status === 1) {
			if (
				order.orderStatusReason &&
				order.orderStatusReason.name === "Verification"
			) {
				obj.text = "Intake / Insurance Verification";
				obj.gradient = "peach";
			} else {
				obj.text = "Insurance Review";
				obj.gradient = "blue";
			}
		} else if (order.status === 0) {
			obj.text = "Medical Documents";
			obj.gradient = "aqua";
		} else {
			obj.text = order.status;
			obj.gradient = "aqua";
		}

		return obj;
	}

	titleCase(name) {
		return name
			.toLowerCase()
			.split(" ")
			.map(function (word) {
				return word.charAt(0).toUpperCase() + word.slice(1);
			})
			.join(" ");
	}

	renderRouteButton(order) {
		return (
			<Route
				render={({ history }) => (
					<div
						style={{
							display: "flex",
							fontSize: "26px",
							justifyContent: "space-around",
						}}
					>
						<h1
							className="fa fa-external-link"
							aria-hidden="true"
							onClick={() => {
								history.push({
									pathname: "/order/" + order.id,
									state: { order: order },
								});
							}}
						></h1>

						<h1
							onClick={() => this.toggleNoteTooltip(order)}
							className=" fa fa-sticky-note-o"
						>
							{" "}
						</h1>
					</div>
				)}
			/>
		);
	}

	renderStatusButton(item) {
		let status = this.formatStatus(item);
		return (
			<Button
				style={{
					position: "absolute",
					top: "-3rem",
					left: "5%",
					width: "90%",
					padding: "1rem",
				}}
				onClick={() => this.toggleTimelineModalPopup(item)}
				color="primary"
				rounded
				gradient={status.gradient}
			>
				{status.text}
				<br />
				Days in Status: 8
			</Button>
		);
	}

	renderCircleProgress(status, reason) {
		let value = 10;

		switch (status) {
			case "New":
				value = 10;
				break;

			case "InProcess":
				if (reason && reason.name === "Verification") {
					value = 25;
				} else {
					value = 40;
				}
				break;
			case "ReadyToDeliver":
				value = 80;
				break;
			case "Setup":
				value = 100;
				break;
			default:
				value = 10;
		}

		return (
			<CirclePie
				style={{ marginTop: "1rem" }}
				width={100}
				height={100}
				strokeWidth={3}
				percent={value}
				strokeColor="#4FB64E"
			/>
		);
	}

	renderCardGroup(theme) {
		return (
			<CardGroup style={{ maxWidth: "96%", alignItems: "center" }} deck>
				{this.state.sortedOrders.map((item, idx) => {
					return this.renderCard(item, idx, theme);
				})}
			</CardGroup>
		);
	}

	renderCard(item, idx, theme) {
		return (
			<Card
				border={"indigo"}
				key={idx}
				style={{
					marginTop: "1.5rem",
					marginLeft: ".5rem",
					marginRight: ".5rem",
					minWidth: "18%",
					maxWidth: "25%",
					marginBottom: "2rem",
				}}
			>
				<CardBody>
					{this.renderStatusButton(item)}

					{this.renderCircleProgress(item.status, item.orderStatusReason)}
					<br />
					<h5 style={{ padding: ".5rem" }}>
						{this.titleCase(item.patientName)}
					</h5>
					{item.phone}
				</CardBody>
				<CardFooter
					style={{ backgroundColor: theme.subCard, color: theme.footerText }}
				>
					{this.renderRouteButton(item)}
				</CardFooter>
			</Card>
		);
	}

	render() {
		return <div>{this.renderCardGroup()}</div>;
	}
}