import React from "react";
import {
	Button,
	CardBody,
	Col,
	Input,
	InputFile,
	MDBBtn,
	MDBCard, MDBCardBody, MDBCardHeader,
	MDBCol,
	MDBIcon,
	MDBInput,
	MDBRow,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOption,
	MDBSelectOptions, MDBSpinner,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Spinner,
	toast,
} from "mdbreact";
import PropTypes from "prop-types";
import OrderService from "../../Security/OrderService/orderService";
import ReactTooltip from "react-tooltip";
import DualListBox from "react-dual-listbox";
import GlobalFunctions from "../../Filters/GlobalFunctions";
export default class AttachmentsTab extends React.Component {
	constructor(props) {
		super(props);

		this.updateParent = props.updateParent;
		this.savePatient = props.savePatient;
		this.saveOrder = props.saveOrder;

		this.state = {
			eOrder: props.eOrder,
			curOrder: props.curOrder,
			isOrderDetails: props.isOrderDetails,
			deleteModalOpen: false,
			mergeModalOpen: false,
			modalFile: {},
			documentType: {},
			documentTypes: [],
			otherDocType: "",
			addTimeStamp: true,

			thumbLoading: false,
			thumbnails: [],
			retrievedImages: [],
			selectedDocuments: [],
			formActivePanel1: 1,
			attachmentsLoaded: false,
			minimal:props.minimal,
			addAttachment: false,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		documentTypes: PropTypes.array,
	};

	componentDidMount(){
		this.getDocumentTypes();
		this.getAttachments();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.eOrder !== prevState.eOrder) {
			return { eOrder: nextProps.eOrder };
		} else return null;
	}

	async getDocumentImage(id) {
		let tRef = this;

		return new Promise((resolve, reject) => {
			OrderService.getDocumentThumbnails(id)
				.then((res) => {
					if (res) {
						let obj = {
							id: id,
							data: res.map((r) => {
								return {
									image: r,
									included: true,
								};
							}),
						};
						resolve(obj);
					}
				})
				.catch((err) => {
					// need index in case we can't get the actual image for the doc...BC
					// let idx = tRef.state.selectedDocuments.indexOf(id);

					toast.error(
						"Unable to add document to packet. Ensure documents are of type '.pdf' "
					);

					let sel = tRef.state.selectedDocuments;

					tRef.setState({
						selectedDocuments: sel,
					});

					resolve();
				});
		});
	}

	async changeMergeDocumentSelection(items) {
		//Set the selected documents to be the current selection...BC
		this.setState({
			selectedDocuments: items,
		});

		//diff should be the items that have not been retrieved
		let retrieved = this.state.retrievedImages,
			diff = items.filter((a) => {
				return retrieved.indexOf(a) < 0;
			});

		let img = [];

		this.setState({
			thumbLoading: true,
		});

		for (let id of diff) {
			let val = await this.getDocumentImage(id);

			//do not push this again if we already have it...BC
			if (val && retrieved.indexOf(id) === -1) {
				img.push(val);
				retrieved.push(id);
			}
		}

		let fin = this.state.thumbnails.concat(img);

		this.setState({
			thumbLoading: false,
			thumbnails: fin,
			retrievedImages: retrieved,
		});
	}

	getAttachments() {
		this.setState({ attachmentsLoaded: false})
		let ord = this.state.eOrder,
			id = this.state.eOrder.id;

		OrderService.getOrderAttachments(id)
			.then((res) => {
				if (res) {
					ord.activity.files = res;
					this.setState({
						attachments: res,
						eOrder: ord,
						documentType: {},
						otherDocType: "",
						attachmentsLoaded: true
					})
				}
			})
			.catch((err) => {
				this.setState({
					attachmentsLoaded: true
				})
				console.log(err);
			});
	}

	getDocumentTypes(){
		const { documentTypes } = this.context;
		this.setState({
			documentTypes: documentTypes
		});
	}

	changePageStatus = (index, itemNum) => {
		let thumbs = this.state.thumbnails;
		thumbs[itemNum].data[index].included = !thumbs[itemNum].data[index]
			.included;
		this.setState({ thumbnails: thumbs });
	};

	toggleMergeModalPopup() {
		let open = this.state.mergeModalOpen,
			thumbs = this.state.thumbnails;

		thumbs.forEach((x) => {
			x.data.forEach((y) => {
				y.included = true;
			});
		});
		let dt = new Date(),
			mo =
				dt.getMonth() < 10
					? "0" + (dt.getMonth() + 1).toString()
					: dt.getMonth() + 1,
			dy = dt.getDate() < 10 ? "0" + dt.getDate().toString() : dt.getDate(),
			ho = dt.getHours() < 9 ? "0" + dt.getHours().toString() : dt.getHours(),
			mi =
				dt.getMinutes() < 9
					? "0" + dt.getMinutes().toString()
					: dt.getMinutes(),
			se =
				dt.getSeconds() < 9
					? "0" + dt.getSeconds().toString()
					: dt.getSeconds(),
			newName = `${
				this.state.eOrder.id
			}_${dt.getFullYear()}${mo}${dy}_${ho}${mi}${se}_MergedDocuments`;

		if (open) {
			this.setState({
				selectedDocuments: [],
				rearrangeData: [],
				formActivePanel1: 1,
				formActivePanel1Changed: false,
			});
		}

		this.setState({
			mergeModalOpen: !open,
			thumbLoading: false,
			documentPreview: null,
			mergeDocumentName: newName,
			thumbnails: thumbs,
		});
		ReactTooltip.rebuild();
	}

	toggleDeleteModalPopup(file) {
		let currentState = this.state.deleteModalOpen;

		this.setState({
			deleteModalOpen: !currentState,
			modalFile: file,
		});
	}

	handleOtherDoc(e) {
		this.setState({ otherDocType: e.target.value });
	}

	handleDocumentTypeChange = (e) => {
		if (e.length === 0) {
			return;
		}

		if (e[0].id === 4) {
			this.toggleMergeModalPopup();
		} else {
			this.setState({
				documentType: e[0],
			});
		}
	};

	checkBoxChange = (event) => {
		this.setState({ addTimeStamp: event.target.checked });
	};

	fileInputHandler(files) {
		const { currentUser } = this.context;
		let o = this.state.eOrder,
			pat = o.patient,
			docType = this.state.documentType,
			fileName = "",
			lastAorDate = null;

		let firstName = pat.firstName || "",
			lastName = pat.lastName || "",
			pName = firstName + "_" + lastName;

		if (docType.id === 16) {
			lastAorDate = true
		}

		var ext = "." + files[0].name.split(".").pop();

		if (docType.id === 9) {
			fileName = pName + "-" + this.state.otherDocType + ext;
		} else {
			fileName = pName + "-" + docType.name + ext;
		}


		if (!this.state.addTimeStamp) {
			return OrderService.uploadDocument(o, files, fileName, currentUser.id)
				.then((res) => {
					//fin(this, res);
					if (lastAorDate) {
						if (!this.state.minimal) {
							o.patient.lastAorDate = new Date()
							this.saveOrder(o)
						} else {
							this.savePatient(false, lastAorDate)
						}
					}
					this.setState({
						attachmentsLoaded: false,
					})
					this.getAttachments();
				})
				.catch((err) => {
					console.log(err);
					toast.warn("Upload Unsuccessful");
				});
		} else {
			return OrderService.uploadDocumentWithStamp(o, files, fileName, currentUser.id)
				.then((res) => {
					//console.log(res)
					//fin(this, res);
					if (lastAorDate) {
						if (!this.state.minimal) {
							o.patient.lastAorDate = new Date()
							this.saveOrder(o)
						} else {
							this.savePatient(false, lastAorDate)
						}
					}
					this.setState({
						attachmentsLoaded: false,
                    })
					this.getAttachments();
				})
				.catch((err) => {
					console.log(err);
					toast.warn("Upload Unsuccessful");
				});
		}
	}

	fileDownloadHandler(attachment) {
		return OrderService.downloadDoc(attachment.fileId).then((res) => {
				if (res) {
					let file = new File([res], attachment.file.filename, null);
					let url = window.URL.createObjectURL(file);
					let temp = document.createElement("a");
					temp.href = url;
					temp.setAttribute("download", attachment.file.filename);
					temp.click();
					window.URL.revokeObjectURL(url);
				}
				else {
					toast.warn("There was an issue downloading the file. It may not exist on the server yet.");
                }
			})
			.catch(err => {
				console.log(err);
				toast.error("There was an issue downloading the file.");
            })
	}

	updateFiles(file){
		let ord = this.state.eOrder,
			fIndex = ord.files.findIndex((f) => {
				return f.id === file.id;
			});
		ord.files.splice(fIndex, 1);
		this.setState({
			eOrder: ord,
		})
	}

	deleteFile(file) {
		this.toggleDeleteModalPopup();

		//toast.warn("Attempting To Delete File");
		OrderService.deleteDocument(file.id)
			.then((res) => {
				if (res === true) {
					toast.success("The file has been deleted");
					this.getAttachments();
				}
				else {
					toast.warn("An error occurred when deleting the attachment.");
                }
			})
			.catch((e) => {
				console.log(e);
				toast.error("There was an error trying to delete the file");
			});
	}

	getModalHeaderName() {
		let v = this.state.formActivePanel1;
		if (v === 1) {
			return "Select Documents";
		} else if (v === 2) {
			return "Re-order Pages";
		} else {
			return "Name Packet";
		}
	}

	handleRearrange = (direction) => {
		let files = this.state.rearrangeData,
			indx = files.findIndex((file) => {
				return file.selected;
			});
		if (
			indx === -1 ||
			(direction === -1 && indx === 0) ||
			(direction === 1 && indx === files.length - 1)
		) {
			return;
		}
		let temp = files.splice(indx + direction, 1);
		files.splice(indx, 0, temp[0]);
		this.setState({
			rearrangeData: files,
		});
	};

	handleStepperNext = () => {
		let files = [],
			st = this.state;

		let thumbs = this.state.thumbnails.filter((t) => {
			return this.state.selectedDocuments.indexOf(t.id) > -1;
		});

		thumbs = thumbs.sort(function (a, b) {
			return (
				st.selectedDocuments.indexOf(a.id) - st.selectedDocuments.indexOf(b.id)
			);
		});

		thumbs.map((item) => {
			return item.data.map((dat, indx) => {
				if (dat.included) {
					return files.push({
						fileId: item.id,
						pageIndeces: indx + 1,
						selected: false,
						data: dat.image,
					});
				}
				return null;
			});
		});

		this.setState({
			formActivePanel1: 2,
			formActivePanel1Changed: true,
			rearrangeData: files,
		});
	};

	swapFormActive = (a) => (param) => (e) => {
		this.setState({
			["formActivePanel" + a]: param,
			["formActivePanel" + a + "Changed"]: true,
		});
	};

	handleNextPrevClick = (a) => (param) => (e) => {
		this.setState({
			["formActivePanel" + a]: param,
			["formActivePanel" + a + "Changed"]: true,
		});
	};

	calculateAutofocus = (a) => {
		if (this.state["formActivePanel" + a + "Changed"]) {
			return true;
		}
	};

	handleImageSelect = (fileIndex) => {
		let files = this.state.rearrangeData;
		files.map((f, index) => {
			if (index === fileIndex) {
				f.selected = !f.selected;
			} else {
				f.selected = false;
			}
			return f;
		});
		this.setState({
			rearrangeData: files,
		});
	};

	onSelectedItemChanged = (event) => {
		event.preventDefault();
		let selectedDoc = this.state.documentPreview;

		if (event.target) {
			let id = event.target.getAttribute("data-real-value");
			let par = document.getElementById("mergeList-selected");
			let val = par.value;

			// double click can add/remove items, need to make sure the selected item is still in the listbox
			if (val || val === id) {
				selectedDoc = val.toString();
			} else {
				selectedDoc = null;
			}
		}
		this.setState({
			documentPreview: selectedDoc,
		});
	};

	submitMergeAttachments = () => {
		const { currentUser } = this.context;
		let st = this.state,
			docs = st.selectedDocuments,
			order = st.eOrder,
			name = st.mergeDocumentName + ".pdf",
			data = st.rearrangeData,
			fil = order.activity.files,
			element7 = null,
			dpd = null;

		dpd = fil.find(
			(x) =>
				docs.includes(x.file.id) &&
				x.file.filename.toLowerCase().indexOf("signed dpd") >= 0
		);
		element7 = fil.find(
			(f) =>
				docs.includes(f.file.id) &&
				f.file.filename.toLowerCase().indexOf("7 element") >= 0
		);

		if (dpd != null && element7 != null) {
			if (dpd.uploadedAt < element7.uploadedAt) {
				toast.error(
					"The DPD document needs to be uploaded after the 7 Element."
				);
				return;
			}
			// Get last occuraance of the 7 Element
			let ele7Index = -1;
			for (let i = data.length - 1; i >= 0; i--) {
				if (data[i].fileId === element7.id) {
					ele7Index = i;
					break;
				}
			}
			//Get first occurance of the DPD
			let dpdIndex = data.findIndex((f) => {
				return f.fileId === dpd.id;
			});
			// If first occurance of DPD is less than last occurance of 7 Element then you know its wrong...JK
			if (dpdIndex < ele7Index) {
				toast.error(
					"Error on page " +
						(dpdIndex + 1) +
						". The DPD document must be merged after the 7 Element"
				);
				return;
			}
		}

		data.map((f, index) => {
			delete f.data;
			delete f.selected;
			f.pageIndex = index;
			return f;
		});

		let dist = [...new Set(data.map((f) => f.fileId))];
		let params = {
			documents: dist,
			upload: data,
		};

		OrderService.mergePagedDocuments(order, params, name, currentUser.id)
			.then((res) => {
				if(res){
					// const eorder = this.state.eOrder;
					// eorder.files.push(res);
					 this.setState({
					 	selectedDocuments: [],
					 });
					this.getAttachments();
					this.toggleMergeModalPopup();
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error("An error occurred while merging the documents.");
			});
	};

	renderFileInput() {
		const { currentUser } = this.context;

		let d = this.state.documentType,
			tf = this.state.addTimeStamp;

		if (d) {
			if (d.id === 9 && !this.state.otherDocType) {
				return <div />;
			} else {
				return (
					<MDBRow>
						{!["SALES", "SALES_MANAGER"].includes(currentUser.role) ? (
							<MDBCol size="12" style={{ marginBottom: 18 }}>
								<Input
									id={"chkAddStamp"}
									checked={tf}
									type="checkbox"
									label={"Add Timestamp"}
									onChange={this.checkBoxChange.bind(this)}
								/>
							</MDBCol>
						) : (
							""
						)}

						<MDBCol size="12">
							<InputFile
								type="file"
								name="file"
								getValue={this.fileInputHandler.bind(this)}
							/>
						</MDBCol>
					</MDBRow>
				);
			}
		} else {
			return <div />;
		}
	}

	renderDeleteConfirmation() {
		let f = this.state.modalFile;

		if (!f) {
			return;
		}

		return (
			<div>
				<Modal
					centered
					className={"orderTasksModal"}
					isOpen={this.state.deleteModalOpen}
					toggle={() => this.toggleDeleteModalPopup()}
				>
					<ModalBody>
						<CardBody>
							<h5>Are you sure you want to delete this file?</h5>
							<br />
							{f.filename}

							<div style={{ paddingTop: 6 }}>
								<MDBIcon
									style={{ float: "right", padding: 8, color: "#4caf50" }}
									size={"2x"}
									icon={"check"}
									onClick={() => this.deleteFile(f)}
									data-tip={"Delete File"}
									data-place={ "left" }
								/>
								<MDBIcon
									style={{ float: "right", padding: 8, color: "#f44336" }}
									size={"2x"}
									icon={"times"}
									onClick={() => this.toggleDeleteModalPopup()}
									data-tip={"Cancel"}
								/>
							</div>
						</CardBody>
					</ModalBody>
					<ReactTooltip />
				</Modal>
			</div>
		);
	}

	renderAttachmentButtons(file) {

		const {currentUser} = this.context,
		t = this;

		let tooltip = "Cannot Delete Another User's File"
		let canDelete = false;
		if (currentUser.role === "SYSTEM_ADMIN" || currentUser.role === "ADMIN" || currentUser.role === "EXECUTIVE_MANAGEMENT") {
			tooltip = "Delete File";
			canDelete = true;
		} else if ((file.file.uploader && currentUser.id === file.file.uploader.id) || currentUser.id === file.file.uploaderId) {
			let hours = GlobalFunctions.getHoursApart(file.file.uploadedAt);
			if (hours < 24) {
				canDelete = true;
				tooltip = "Delete File";
			} else (
				tooltip = "Cannot Delete After 24 Hours"
			)
		}

		return (
			<MDBRow className={ this.state.isOrderDetails ? "attachBtnsRow" : ""}>
				<MDBBtn
					className={ this.state.isOrderDetails ? "attachBtnsMin" : "attachBtns"}
					floating
					onClick={() => this.fileDownloadHandler(file)}
					data-tip={"Download"}
					type="submit"
				>
					<MDBIcon
						className={ this.state.isOrderDetails ? "attachIconsMin" : "attachIcons"}
						size={"lg"}
						data-tip={"Download File"}
						icon={"cloud-download-alt"}
					/>

				</MDBBtn>
				<div
					data-place={"left"}
					className={"tooltip-wrapper"}
					data-tip={tooltip}
					data-tip-disable={canDelete}
				>
					<MDBBtn
						className={ this.state.isOrderDetails ? "attachBtnsMin" : "attachBtns"}
						floating
						onClick={() => t.toggleDeleteModalPopup(file)}
						disabled={!canDelete}
						data-tip={tooltip}
						type="submit"

					>
						<MDBIcon
							className={ this.state.isOrderDetails ? "attachIconsMin" : "attachIcons"}
							size={"lg"}
							icon={"trash"}
						/>
					</MDBBtn>
				</div>
				<ReactTooltip/>
			</MDBRow>
		);
	}

	generateAttachmentCard(file, idx) {
		if(this.state.minimal){
			return(
				<MDBCard
					key={idx}
					className={this.state.isOrderDetails ? "patientPageAttachmentsCard" : ""}
				>
					<MDBCardHeader className={ this.state.isOrderDetails ? "orderNoteHeaderPatientPage" : ""}>
						<MDBRow>
							<MDBCol className={ this.state.isOrderDetails ? "noteHeaderDateText" : ""}>
								{file.file.filename}
							</MDBCol>
						</MDBRow>
					</MDBCardHeader>
					<MDBCardBody className={ this.state.isOrderDetails ? "orderNotesCardBodyPatientPage" : ""}>
						<MDBRow style={{ color: "#6B7A85" }}>
							<MDBCol size="12">{GlobalFunctions.formatJsDateTime(file.file.uploadedAt)}</MDBCol>
							<MDBCol size="12">
								{file.file.uploader ? file.file.uploader.firstname + " " + file.file.uploader.lastname : ""}
							</MDBCol>
						</MDBRow>

					</MDBCardBody>
				<MDBRow style={{width: "100%", margin: "0"}}>
				</MDBRow>
					<MDBRow  className={this.state.minimal ? "minimalAttachmentButtons" : ""} >{this.renderAttachmentButtons(file)}</MDBRow>
				</MDBCard>
			)
		}
		return (
			<MDBCard
				key={idx}
				style={{
					padding: 6,
					margin: 4,
					backgroundColor: "#E2F1FF",
				}}
			>
			<MDBRow style={{width: "100%", margin: "0",	paddingRight: "15px"}}>
				<MDBCol size="9">
					<MDBRow style={{ paddingBottom: 4 }}>
						<MDBCol size="12" style={{ fontSize: ".9em" }}>
							{file.file.filename}
						</MDBCol>
					</MDBRow>
					<MDBRow style={{ fontSize: ".9em", color: "#6B7A85" }}>
						<MDBCol size="6">{GlobalFunctions.formatJsDateTime(file.file.uploadedAt)}</MDBCol>
						<MDBCol size="6">
							{file.file.uploader ? file.file.uploader.firstname + " " + file.file.uploader.lastname : ""}
						</MDBCol>
					</MDBRow>
				</MDBCol>
				<MDBCol size="3" style={{padding:0, display: "flex", justifyContent: "right"}}>{this.renderAttachmentButtons(file)}</MDBCol>
			</MDBRow>
			</MDBCard>
		);
	}

	renderThumbnailBar() {
		let st = this.state,
			selectedDoc = st.documentPreview,
			thumbs = st.thumbnails,
			selectedDocuments = st.selectedDocuments;

		if (!this.state.thumbLoading) {
			if (
				selectedDoc &&
				selectedDocuments &&
				selectedDocuments.length > 0 &&
				selectedDocuments.filter((d) => d.toString() === selectedDoc).length > 0
			) {
				if (thumbs && thumbs.length > 0) {
					let filtered = thumbs.filter((x) => x.id.toString() === selectedDoc);
					if (
						filtered &&
						filtered[0] &&
						filtered[0].data &&
						filtered[0].data.length > 0
					) {
						return thumbs.map((item, ind) => {
							if (selectedDoc === item.id.toString()) {
								return item.data.map((dat, index) => {
									let colorCode = dat.included ? "green" : "red",
											iconName = dat.included ? "check" : "ban",
											src = "data:image/jpeg;base64, " + dat.image;

									return (
										<Col key={index} md={"12"} className={"upload-thumbnail"}>
											<img
												style={{
													width: "100%",
													height: "auto",
													border: "1px solid black",
												}}
												src={src}
												data-tip={
													"<img src='" +
													src +
													"' alt='Preview' style='height: 70vh; width: auto;' />"
												}
												data-for="preview"
												data-place={"right"}
												data-html="true"
												className={"thumbnail"}
												onClick={() => this.changePageStatus(index, ind)}
												alt={"Page " + index}
											/>
											<div
												style={{ textAlign: "center", color: colorCode }}
												onClick={() => this.changePageStatus(index, ind)}
											>
												<MDBIcon icon={iconName} />
											</div>
											<ReactTooltip id={"preview"} html={true} />
										</Col>
									);
								});
							}
							return null;
						});
					} else {
						return <div />;
						// return <div>{ReactTooltip.rebuild()}{this.renderLoadingSpinner()}</div>;
					}
				} else {
					return <div />;
					// return <div>{ReactTooltip.rebuild()}{this.renderLoadingSpinner()}</div>;
				}
			} else {
				return <div />;
				// return <div>{ReactTooltip.rebuild()}</div>;
			}
		} else {
			return (
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			);
		}
	}

	renderRearrange() {
		let st = this.state,
			thumbs = st.rearrangeData;
		return thumbs.map((item, index) => {
			let src = "data:image/jpeg;base64, " + item.data;
			return (
				<Col
					key={index}
					md={"2"}
					style={{ paddingBottom: "5px" }}
					className={"upload-thumbnail"}
				>
					<img
						style={{
							width: "100%",
							height: "auto",
							border: item.selected ? "2px solid red" : "1px solid black",
						}}
						src={src}
						data-tip={
							"<img src='" +
							src +
							"' alt='Preview' style='height: 70vh; width: auto;' />"
						}
						data-place={"right"}
						data-for="preview"
						data-html="true"
						className={"thumbnail"}
						onClick={() => this.handleImageSelect(index)}
						alt={"Page " + index}
					/>
					<ReactTooltip id={"preview"} html={true} />
				</Col>
			);
		});
	}

	renderArrangeDocuments() {
		if (this.state.formActivePanel1 === 2) {
			return (
				<MDBCol md="12">
					<div>
						<Row style={{ marginTop: "1%", minHeight: "140px" }}>
							{this.renderRearrange()}
						</Row>
					</div>
				</MDBCol>
			);
		}
	}

	renderPacketName() {
		let st = this.state;
		if (st.formActivePanel1 === 3) {
			return (
				<Col md={"12"}>
					<Input
						value={st.mergeDocumentName}
						label={"Document Name:"}
						onChange={(e) => {
							this.setState({ mergeDocumentName: e.target.value });
						}}
					/>
				</Col>
			);
		}
	}

	renderMergeModalOne(options, selected) {
		if (this.state.formActivePanel1 === 1) {
			return (
				<MDBCol md="12">
					<div>
						<div>
							<Row>
								<Col md={"6"}>
									<span>Available:(Only .PDF files are supported)</span>
								</Col>
								<Col md={"6"}>
									<span>Selected: (Click on an item to select pages)</span>
								</Col>
							</Row>
						</div>
						<div>
							<DualListBox
								id={"mergeList"}
								options={options}
								preserveSelectOrder
								selected={selected}
								showOrderButtons
								selectedRef={(element) => {
									if (element) {
										element.addEventListener(
											"click",
											this.onSelectedItemChanged
										);
									}
								}}
								onChange={this.changeMergeDocumentSelection.bind(this)}
							/>
						</div>
						<div>
							<Row style={{ marginTop: "1%", minHeight: "140px" }}>
								{this.renderThumbnailBar()}
							</Row>
						</div>
					</div>
				</MDBCol>
			);
		}
	}

	renderMergeModalFooterOne(selected) {
		let st = this.state;

		if (st.formActivePanel1 === 1) {
			return (
				<div>
					<MDBBtn
						floating
						onClick={() => this.toggleMergeModalPopup()}
						data-tip={"Cancel"}
						size="sm"
						type="submit"
						color={"red"}
					>
						<MDBIcon icon="times-circle" />
					</MDBBtn>
					<MDBBtn
						floating
						color="mdb-color"
						size="sm"
						disabled={(selected && selected.length < 2) || st.thumbLoading}
						data-tip={"Re-order Pages"}
						onClick={() => this.handleStepperNext()}
					>
						<MDBIcon icon="arrow-alt-circle-right" />
					</MDBBtn>
					<ReactTooltip />
				</div>
			);
		}
	}

	renderMergeModalFooterTwo() {
		if (this.state.formActivePanel1 === 2) {
			return (
				<div>
					<Button
						floating
						size="sm"
						data-tip={"Move Page Left"}
						onClick={() => this.handleRearrange(-1)}
					>
						<MDBIcon icon="arrow-left" />
					</Button>
					<Button
						floating
						size="sm"
						data-tip={"Move Page Right"}
						onClick={() => this.handleRearrange(1)}
					>
						<MDBIcon icon="arrow-right" />
					</Button>
					<MDBBtn
						style={{ textTransform: "none" }}
						color="mdb-color"
						floating
						size="sm"
						data-tip={"Select Documents"}
						onClick={this.handleNextPrevClick(1)(1)}
					>
						<MDBIcon icon="arrow-alt-circle-left" />
					</MDBBtn>
					<MDBBtn
						style={{ textTransform: "none" }}
						color="mdb-color"
						floating
						size="sm"
						data-tip={"Name Packet"}
						onClick={this.handleNextPrevClick(1)(3)}
					>
						<MDBIcon icon="arrow-alt-circle-right" />
					</MDBBtn>

					<MDBBtn
						floating
						onClick={() => this.toggleMergeModalPopup()}
						data-tip={"Cancel"}
						size="sm"
						type="submit"
						color={"red"}
					>
						<MDBIcon icon="times-circle" />
					</MDBBtn>

					<ReactTooltip />
				</div>
			);
		}
	}

	renderMergeModalFooterThree() {
		if (this.state.formActivePanel1 === 3) {
			return (
				<div>
					<MDBBtn
						style={{ textTransform: "none" }}
						color="mdb-color"
						floating
						size="sm"
						className="float-left"
						data-tip={"Re-order Pages"}
						onClick={this.handleNextPrevClick(1)(2)}
					>
						<MDBIcon icon="arrow-alt-circle-left" />
					</MDBBtn>

					<MDBBtn
						floating
						onClick={() => this.submitMergeAttachments()}
						data-tip={"Submit"}
						size="sm"
						disabled={
							!this.state.selectedDocuments ||
							this.state.selectedDocuments.length <= 1
						}
						type="submit"
						color={"success"}
					>
						<MDBIcon icon="cloud-upload-alt" />
					</MDBBtn>

					<MDBBtn
						floating
						onClick={() => this.toggleMergeModalPopup()}
						data-tip={"Cancel"}
						size="sm"
						type="submit"
						color={"red"}
					>
						<MDBIcon icon="times-circle" />
					</MDBBtn>
					<ReactTooltip />
				</div>
			);
		}
	}

	renderMergeDocumentModal() {
		let st = this.state,
			files = st.eOrder.activity.files,
			options = [],
			selected = st.selectedDocuments || [];

		if (files && files.length > 0) {
			files.map((file) => {
				if (file.file && file.file.contentType && file.file.contentType.toLowerCase().includes("pdf"))
					 {
					return options.push({ value: file.file.id, label: file.file.filename });
				}
				return null;
			});
		}

		return (
			<Modal
				className={"merge-modal"}
				centered
				size={"lg"}
				isOpen={st.mergeModalOpen}
				toggle={() => {
					return;
				}}
			>
				<ModalHeader style={{ textAlign: "center" }}>
					{this.getModalHeaderName()}
				</ModalHeader>
				<ModalBody
					className={
						st.thumbLoading
							? "merge-modal-body"
							: "merge-modal-body thumb-scroll"
					}
				>
					<MDBRow>
						{this.renderMergeModalOne(options, selected)}
						{this.renderArrangeDocuments()}
						{this.renderPacketName()}
					</MDBRow>
				</ModalBody>
				<ModalFooter>
					{this.renderMergeModalFooterOne(selected)}
					{this.renderMergeModalFooterTwo()}
					{this.renderMergeModalFooterThree()}
				</ModalFooter>
			</Modal>
		);
	}

	renderSpinnerOrForm(){
		let minimal = this.state.minimal;
		if(!this.state.attachmentsLoaded){
			return(
				<div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
					<MDBSpinner/>
				</div>
			)
		}
		else{
			// const { documentTypes } = this.context;
			let documentTypes = this.state.documentTypes;

			let files = this.state.attachments || [];
			let st = this.state;

			files = files.sort((a, b) => {
				return new Date(b.uploadedAt) - new Date(a.uploadedAt);
			}) || files;

			// let fx = files.filter((f) => !f.deleted)

			if(minimal){
				// if (fx && fx.length === 0) {
				// 	return (
				// 		<div style={ { width: 'min-content', whiteSpace: 'nowrap', margin: '0 auto' } }>No Data</div>
				// 	)
				// }
				return(
					<div>
					{/*<MDBCol>*/}
							<MDBRow>
								<MDBBtn
									size="large"
									className="addNoteBtnFull"
									onClick={()=>{this.setState({addAttachment: true})}}
								>
									Add Attachment
								</MDBBtn>
							</MDBRow>
						<div>
						{this.state.addAttachment ?
							(
							<div>
							<MDBRow>
								<MDBSelect
									className={"minimalDocumentSelect"}
									outline
									getValue={ (e) => this.handleDocumentTypeChange(e) }
									label={"Select a Document Type"}
								>
									<MDBSelectInput />
									<MDBSelectOptions>
										{documentTypes.map((type, idx) => {
											return (
												<MDBSelectOption
													key={idx}
													selected={st.documentType === type.name}
													value={type}
												>
													{type.name}
												</MDBSelectOption>
											);
										})}
									</MDBSelectOptions>
								</MDBSelect>
							</MDBRow>

							{st.documentType.id === 9 ? (
									<MDBRow className={"attachmentsOtherField"}>
										<MDBInput
											outline
											style={{ marginTop: 2, marginBottom: 2 }}
											onChange={this.handleOtherDoc.bind(this)}
											valueDefault={st.otherDocType}
											label="Document Name"
										/>
									</MDBRow>
								) : (
									<div />
								)}

							<MDBRow>{this.renderFileInput()}</MDBRow>
							</div>
								)
							:
							(<div></div>)
						}

						</div>


						<MDBRow className={"pvNotesGroupOuterContainer"}>
						<div className={"pvNotesGroupInnerContainer"}>
								{files.map((f, idx) => {
									if(f.deleted === false){
										return (

												this.generateAttachmentCard(f, idx)

										);
									}else{
										return '';
									}
								})}
						</div>
						</MDBRow>
					{/*</MDBCol>*/}
					</div>
				)
			}
			return(
				<MDBRow>
					<MDBCol size="4">
						<MDBRow>
							<MDBCol size={"12"}>
								<MDBSelect
									outline
									getValue={(e) => this.handleDocumentTypeChange(e)}
									label={"Select a Document Type"}
								>
									<MDBSelectInput />
									<MDBSelectOptions>
										{documentTypes.map((type, idx) => {
											return (
												<MDBSelectOption
													key={idx}
													selected={st.documentType === type.name}
													value={ type }
												>
													{type.name}
												</MDBSelectOption>
											);
										})}
									</MDBSelectOptions>
								</MDBSelect>
							</MDBCol>

							{st.documentType.id === 9 ? (
								<MDBCol size="12" className={"attachmentsOtherField"}>
									<MDBInput
										outline
										style={{ marginTop: 2, marginBottom: 2 }}
										onChange={this.handleOtherDoc.bind(this)}
										valueDefault={st.otherDocType}
										label="Document Name"
									/>
								</MDBCol>
							) : (
								<div />
							)}
						</MDBRow>
						<MDBRow>{this.renderFileInput()}</MDBRow>
					</MDBCol>
					<MDBCol className={"pvNotesGroupOuterContainer"} size="8">
						<div className={"pvNotesGroupInnerContainer"}>
							{files.map((f, idx) => {
								if(f.deleted === false){
									return (

											this.generateAttachmentCard(f,idx)

									);
								}else{
									return '';
								}
							})}
						</div>
					</MDBCol>
				</MDBRow>
			)
		}
	}

	render() {
		return (
			<div>
				{this.renderDeleteConfirmation()}
				{this.renderMergeDocumentModal()}
				{this.renderSpinnerOrForm()}
			</div>
		);
	}
}
