import React from "react";
import PropTypes from "prop-types";
import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
	Grid,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar, TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

import {
	GroupingState,
	SortingState,
	IntegratedSorting,
	IntegratedGrouping,
	PagingState,
	IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
	Plugin,
	Template,
	TemplateConnector,
	TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import {
	Container,
	Spinner,
	ToastContainer,
	MDBIcon,
} from "mdbreact";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "../PurchaseQueue/purchaseGrid.css";
import moment from "moment";
import ReverseQualityService from "../Settings/reverseQualityService";

export default class RQTable extends React.Component {
	constructor(props) {
		super(props);

		const status = this.props.status;

		const groupColumns = [
		];

		const data = this.buildData(status);

		let defaultColumnWidths = [
			{ columnName: "reviewed", width: 130 },
			{ columnName: "orderID", width: 120 },
			{ columnName: "notes", width: 550 },
			{ columnName: "reviewedOn", width: 120 },
			{ columnName: "reportedOn", width: 120 },
			{ columnName: "category", width: 200 },
			{ columnName: "reason", width: 200 },
		];

		this.state = {
			data: data,
			isLoaded: false,
			grouping: [],
			groupingColumns: groupColumns,
			defaultColumnWidths: defaultColumnWidths,
			groupSummaryItems: [],
			status: status,
			approvalModal: false,
			nPurchaseQueues: [],
			brightreeId: "",
			errorMessage: "",
			tasks: props.tasks,
		};

		this.changeGrouping = (grouping) => {
			let ary = [];

			grouping.forEach((group) => {
				ary.push({ columnName: group.columnName });
			});
			this.setState({
				groupingColumns: ary,
			});
		};

	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	buildData() {
		let d = {
			columns: [
				{
					title: "Acknowledge",
					name: "reviewed",
				},
				{
					title: "Order ID",
					name: "orderID",
				},
				{
					title: "Notes",
					name: "notes",
				},
				{
					title: "Reviewed On",
					name: "reviewedOn",
				},
				{
					title: "Reported On",
					name: "reportedOn",
				},
				{
					title: "Category",
					name: "category",
				},
				{
					title: "Reason",
					name: "reason",
				},
			],
			rows: [],
		};

		return d;
	}

	Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

	CellContent = ({ row, column, expanded, ...props }) => {
		let cell = (
			<TableGroupRow.Cell
				{...props}
				row={row}
				column={column}
				expanded={expanded}
			/>
		);
		return cell;
	};

	Content = ({ row, column }) => (
		<span>
			<span>
				{column.title} : {row.value || "(Blank)"}
			</span>
			<span style={{ fontWeight: "bold" }}>&nbsp; {this.getVal(row)}</span>
		</span>
	);

	componentDidMount() {
		this.getResults();
	}

	getVal(row) {
		if (row.collapsedRows.length > 0) {
			return "Count: " + row.collapsedRows.length;
		} else {
			return "";
		}
	}

	getResults() {
		const {currentUser} = this.context;

		let y = new Date().getFullYear(),
			m = new Date().getMonth() + 1,
			sd = m + '/01/' + y

		let f = {
			startDate: sd,
			endDate: new Date().toLocaleDateString(),
			ids: [currentUser.id]
		};

		let ary = [],
			dt = this.state.data;

		ReverseQualityService.getAllLogsNative(f)
			.then((r) => {
				if (r.length === 0) {
					this.setState({
						isLoaded: true
					})
					return
				}
				r.forEach((val, idx) => {
					ary.push({
						reviewed: this.createBtn(val, val.id, idx),
						orderID: val.orderId || "No order ID",
						notes: val.notes ||"No Notes",
						reviewedOn: val.fixedOn ? moment(val.fixedOn).format("YYYY-MM-DD") : "Not Reviewed",
						reportedOn: val.reportedOn ? moment(val.reportedOn).format("YYYY-MM-DD") : "No Due Date",
						category: val.reverseQualityCategory || "No Category",
						reason: val.reverseQualityReason || "No reason",
					});
				})

				dt.rows = ary;
				this.setState({
					data: dt,
					isLoaded: true,
				});
				//r.forEach((val, idx) => {
				//	let row = {
				//		reviewed: this.createBtn(val, val.id, idx),
				//		orderID: val.orderId ? val.orderId : "No order ID",
				//		notes: val.notes ? val.notes : "No Notes",
				//		reviewedOn: val.fixedOn ? moment(val.fixedOn).format("YYYY-MM-DD") : "Not Reviewed",
				//		reportedOn: val.reportedOn ? moment(val.reportedOn).format("YYYY-MM-DD") : "No Due Date",
				//		category: val.reverseQualityCategory ? val.reverseQualityCategory : "No Category",
				//		reason: val.reverseQualityReason ? val.reverseQualityReason : "No reason",
				//	};
				//	ary.push(row);

				//	dt.rows = ary;

				//	this.setState({
				//		data: dt,
				//		isLoaded: true,
				//	});
			}).catch(err => {
				console.log(err);
			});
	}

	renderTable() {
		let st = this.state;
		return (
			<Grid
				rootComponent={this.Root}
				rows={st.data.rows}
				columns={st.data.columns}
			>
				<PagingState defaultCurrentPage={0} pageSize={50} />
				<DragDropProvider />
				<SortingState
					defaultSorting={[{ columnName: "status", direction: "desc" }]}
				/>

				<GroupingState
					columnGroupingEnabled={true}
					defaultGrouping={st.groupingColumns}
				/>
				<IntegratedGrouping />
				<IntegratedPaging />
				<IntegratedSorting />
				<VirtualTable height={"auto"} />
				<PagingPanel />
				<TableColumnResizing
					defaultColumnWidths={this.state.defaultColumnWidths}
				/>
				<TableHeaderRow showSortingControls />
				<TableGroupRow
					cellComponent={this.CellContent}
					contentComponent={this.Content}
					showColumnsWhenGrouped={false}
				/>
				{this.ItemCounter()}
				<Toolbar />
				<GroupingPanel />
			</Grid>
		);
	}

	ItemCounter = () => (
		<Plugin name="ItemCounter">
			<Template
				name="tableCell"
				predicate={({ tableRow }) => isGroupTableRow(tableRow)}
			>
				{(params) => (
					<TemplateConnector>
						{({ getCollapsedRows, rows }) => {
							const updatedParams = {
								...params,
								tableRow: {
									...params.tableRow,
									row: {
										...params.tableRow.row,
										collapsedRows: getCollapsedRows(params.tableRow.row) || [],
										rows: rows.filter((r) => {
											return r.groupedBy === undefined;
										}),
									},
								},
							};
							return <TemplatePlaceholder params={updatedParams} />;
						}}
					</TemplateConnector>
				)}
			</Template>
		</Plugin>
	);

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	createBtn(val, id, idx) {

		if(!val.fixedOn) {
			return (

				<i className="far fa-square fa-2x "
				   size={"2x"}
				   id="btn"
				   style={{color: "#6b93c4"}}
				   onClick={() => this.completeTask(id, idx)}/>

			);
		}else
			return (
				<MDBIcon icon="check-square"
                        size={"2x"}
                        id="btn"
                        style={{color: "#6b93c4"}}
                        //onClick={() => this.completeTask(id, idx)}
				/>
			);

	}

	formatDate(date) {
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "numeric",
			day: "2-digit",
		}).format(date);
	}

	completeTask(id, idx){
		ReverseQualityService.fixReverseQualityLog(id).then(res => {

                this.getResults();

        }).catch(e => {
            console.log(e);
        })

	}
	renderTableOrSpinner() {

		if(!this.state.isLoaded){
			return this.renderLoadingSpinner();
		}
		else
		return (
			<div className={"purchasingGrid"} style={{ height: "100%" }}>
				{this.renderTable()}
			</div>
		);
	}

	render() {
		const {currentUser} = this.context;

		console.log("Current Role is:" + currentUser.role)
		let h = window.innerHeight;
		return (
			<div style={{ overflowY: "scroll", height: h * 0.7, maxHeight: h * 0.7 }}>
				<ToastContainer
					hideProgressBar={true}
					newestOnTop={true}
					autoClose={3000}
					position={"top-right"}
					style={{ marginTop: "75px" }}
				/>
				{this.renderTableOrSpinner()}
			</div>
		);
	}
}
