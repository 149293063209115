import Admin from "../Admin";
import React from "react";
import PropTypes from "prop-types";
import CardAdminBaseUnitGeneral from "../../../Component/Card/Admin/BaseUnit/CardAdminBaseUnitGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete"
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import BaseUnitService from "../../../Seating/Security/BaseUnitService/baseUnitService";
import Validator from "../../../Utility/Validator";
import BaseUnit from "../../../Utility/Crud/BaseUnit";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class AdminBaseUnitCreateEdit extends Admin {
    static contextTypes = {
        baseUnitsIndexed: PropTypes.object,
        updateIndexed: PropTypes.func,
        deleteIndexed: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state.baseUnit = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }

    /**
     * Set the baseUnit in the state.
     */
    afterComponentDidMount() {
        const baseUnitId = this.props.match.params.id ? +this.props.match.params.id : null;
        if (baseUnitId !== null) {
            this.setState({
                baseUnit: this.context.baseUnitsIndexed[baseUnitId]
            });
        }
    }

    /**
     * Creates the baseUnit with the current properties in state.baseUnit.
     */
    async createBaseUnit() {
        this.setState({
            isProcessing: true,
        })

        return BaseUnitService.createBaseUnit(this.state.baseUnit)
            .then(baseUnit => {
                this.context.updateIndexed("baseUnitsIndexed", baseUnit);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Base Unit created successfully!", {variant: "success"})
            })
            .catch((err) => {
                // JZ. We need to test and make sure that Sentry still captures
                // errors when we catch them in the application.
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Base Unit.", {variant: "error"})
                this.setState({
                    isProcessing: false,
                })
            });
    }

    /**
     * Updates the baseUnit with the current properties in state.baseUnit.
     */
    async updateBaseUnit() {
        this.setState({
            isProcessing: true,
        });

        return BaseUnitService.updateBaseUnit(this.state.baseUnit)
            .then(baseUnit => {
                this.context.updateIndexed("baseUnitsIndexed", baseUnit);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Base Unit updated successfully!", {variant: "success"})
            })
            .catch(err => {
                console.log(err)
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Base Unit.", {variant: "error"})
                this.setState({
                    isProcessing: false,
                })
            });
    }

    async deleteBaseUnit() {
        this.setState({
            isProcessing: true,
        });

        return BaseUnitService.deleteBaseUnit(this.state.baseUnit.id)
            .then(() => {
                this.context.deleteIndexed("baseUnitsIndexed", this.state.baseUnit.id);
                this.props.history.goBack()
                NotistackEnqueueSnackbar("Base Unit deleted successfully!", {variant: "success"})
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Base Unit.", {variant: "error"})
                this.setState({
                    confirmDialogOpen: false,
                })
            });

    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (baseUnit) => {
            this.setState({
                baseUnit: {
                    ...this.state.baseUnit,
                    ...baseUnit
                },
            });
        }

        return (
            <>
                <CardAdminBaseUnitGeneral
                    baseUnit={this.state.baseUnit}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteBaseUnit()}
                    text="Are you sure you want to delete this Base Unit? This cannot be undone."
                    header="Delete Base Unit"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Renders the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = "Create Base Unit";
            subtitle = null;
        } else {
            title = "Edit Base Unit";
            subtitle = (this.state.baseUnit && (BaseUnit.getDisplayName(this.state.baseUnit) + " • " + this.state.baseUnit.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack()
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.baseUnit,
                {
                    name: {
                        'required': true
                    },
                    baseUnitTypeId: {
                        'required': true
                    },
                    vendorId: {
                        'required': true
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            
            if (Object.keys(validationIssues).length > 0) {
                return NotistackEnqueueSnackbar("Please correct the displayed issues.", {variant: "error"})
            }

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateBaseUnit()
                } else {
                    this.createBaseUnit()
                }
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        }

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />))
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
