import React from "react";
import MUIIconButton from "@mui/material/IconButton";
import MUIButton from "@mui/material/Button"
import MUIGrid from "@mui/material/Grid";
import TileQueue from "../../../Tile/TileQueue";
import MUIAddIcon from "@mui/icons-material/Add"
import MUIDeleteIcon from "@mui/icons-material/Delete";
import EmptyState from "../../../EmptyState";
import DialogSearchQueue from "../../../Dialog/Search/DialogSearchQueue";
import Queue from "../../../../Utility/Crud/Queue";
import PropTypes from "prop-types";
import MUITextField from "@mui/material/TextField";
import MUIInputAdornment from "@mui/material/InputAdornment";
import MUISearchIcon from "@mui/icons-material/Search";
import Card from "../../../Card";

export default class CardAdminTeamQueue extends Card {
    static contextTypes = {
        queuesIndexed: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state.queueDialogOpen = false;
        this.state.query = "";
        this.state.filteredTeamQueues = this.props.teamQueues;
    }

    /**
     * Set the search results on mount. This ensures that the list is sorted
     * appropriately on first render.
     */
    componentDidMount() {
        this.setState({
            filteredTeamQueues: this.getFilteredTeamQueues(this.state.query),
        });
    }

    /**
     * Handle removes or adds to the teamUsers prop.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.teamQueues.length !== this.props.teamQueues.length) {
            this.setState({
                filteredTeamQueues: this.getFilteredTeamQueues(this.state.query),
            });
        }
    }

    /**
     * Renders a list of members on the team or an empty state if there are none.
     */
    renderContent() {
        const handleRemoveTeamQueue = (removedTeamQueue) => {
            this.props.onChange(this.props.teamQueues.filter((existingTeamQueue) => {
                return (existingTeamQueue.queueId !== removedTeamQueue.queueId);
            }));
        }

        if (this.props.teamQueues.length > 0) {
            if (this.state.filteredTeamQueues.length > 0) {
                return (
                    <>
                        { this.renderSearchBox() }
                        <MUIGrid container spacing={ 2 }>
                            { this.state.filteredTeamQueues.map((teamQueue) => {
                                return (
                                    <MUIGrid key={ teamQueue.queueId } item xs={ 12 } sm={ 6 } md={ 4 }>
                                        <TileQueue
                                            queue={ this.context.queuesIndexed[teamQueue.queueId] }
                                            iconButtons={ [(<MUIIconButton onClick={ () => handleRemoveTeamQueue(teamQueue) }><MUIDeleteIcon /></MUIIconButton>)] }
                                        />
                                    </MUIGrid>
                                );
                            }) }
                            { this.renderDialog() }
                        </MUIGrid>
                    </>
                );
            } else {
                return (
                    <>
                        { this.renderSearchBox() }
                        <EmptyState
                            line1="No results found"
                            line2="Try searching for something a little different."
                        />
                        { this.renderDialog() }
                    </>
                )
            }
        } else {
            return (
                <>
                    <EmptyState
                        line1="No Queues"
                        line2="Queues added to this team will appear here"
                    />
                    { this.renderDialog() }
                </>
            );
        }
    }

    /**
     * Renders a search dialog of all users
     */
    renderDialog() {
        const handleAddTeamQueue = (queue) => {
            const duplicateTeamQueues = this.props.teamQueues.filter((teamQueue) => {
                return teamQueue.queueId === queue.id;
            });
            if (duplicateTeamQueues.length === 0) {
                // TODO: It would be cool to be able to show a snackbar here.
                // Was thinking a component needs access to the layer's snackbar
                // capabilities.
                this.props.onChange(
                    this.props.teamQueues.concat([{
                        teamId: this.props.team.id,
                        queueId: queue.id
                    }])
                );
            }
        }

        return (
            <DialogSearchQueue
                open={ this.state.queueDialogOpen }
                onClose={ () => this.setState({ queueDialogOpen: false }) }
                onSelect={ (queue) => { handleAddTeamQueue(queue); } }
            />
        );
    }

    /**
     * Renders add member button.
     */
    getActions() {
        const handleClick = () => {
            this.setState({
                queueDialogOpen: true,
            });
        }

        return [
            (<MUIButton
                component="label"
                variant="outlined"
                startIcon={ <MUIAddIcon /> }
                onClick={ handleClick }>Add Queue</MUIButton>)
        ];
    }

    /**
     * Searches for team users using the standard user search.
     *
     * @param {string} query The search query.
     * @returns A list of filtered team users.
     */
    getFilteredTeamQueues(query) {
        const queues = [];
        this.props.teamQueues.forEach((teamQueue) => {
            queues.push(this.context.queuesIndexed[teamQueue.queueId]);
        })

        const filteredQueues = Queue.search(
            Object.values(queues),
            query,
            true
        );

        const filteredTeamQueues = [];
        filteredQueues.forEach((queue) => {
            // Nested loop not ideal, but set size is small enough.
            filteredTeamQueues.push(this.props.teamQueues.find((teamQueue) => {
                return teamQueue.queueId === queue.id;
            }));
        });

        return filteredTeamQueues;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Queues";
    }

    /**
     * Render a search box.
     */
    renderSearchBox() {
        const handleChange = (e) => {
            this.setState({
                query: e.target.value,
                filteredTeamQueues: this.getFilteredTeamQueues(e.target.value)
            });
        };

        return (
            <MUITextField
                autoFocus={ true }
                placeholder="Type to search..."
                hiddenLabel={ true }
                fullWidth={ true }
                variant="filled"
                size="small"
                onChange={ handleChange }
                sx={ {
                    marginBottom: 2,
                    "& .MuiInputLabel-root": { display: "none", height: 0 }
                } }
                InputProps={ {
                    startAdornment: (
                        <MUIInputAdornment position="start">
                            <MUISearchIcon />
                        </MUIInputAdornment>
                    ),
                    disableUnderline: true,
                    style: { borderRadius: 4 }
                } }
            />
        );
    }
}