import Crud from "../Crud";
import MUIRedColor from "@mui/material/colors/red";

/**
 * Static helper functions related to IssueReasons.
 */
export default class IssueReason extends Crud {

    /**
     * @param {object} issueReason The issueReason object.
     * @returns The display name of the issueReason.
     */
    static getDisplayName(issueReason) {
        return issueReason ? issueReason.name : null;
    }

    /**
     * Determines if a issueReason matches a search query.
     *
     * @param {object} issueReason The issueReason to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the issueReason matches the query.
     */
    static match(issueReason, query) {
        return (
            (issueReason.name !== null && issueReason.name.toLowerCase().includes(query)) ||
            issueReason.id === +query
        );
    }

    /**
     * @param {array} issueReasons The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(issueReasons) {
        return issueReasons.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIRedColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Feedback";
    }
}