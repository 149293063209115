import React from "react";
import PropTypes from "prop-types";
import {
    Modal,
    MDBCard,
    MDBCol,
    MDBRow,
    MDBStepper,
    MDBStep,
    MDBBtn,
    MDBCardBody,
    MDBInput, MDBFooter,
} from "mdbreact";
import globalFunctions from "../../Seating/Filters/GlobalFunctions";
import NoteService from "../../Seating/Security/NoteService/noteService";

export default class informationChangeModal extends React.Component {
    constructor(props) {
        super(props);
        this.cancelFunction = props.cancelFunction;
        this.submitFunction = props.submitFunction;

        this.state = {
            originalSameSim: props.origionalSamSim,
            formActivePanel1: 1,
            order: props.order,
            changes: {
                address: {
                    streetAddress: "",
                    city: "",
                    state: "",
                    zip: "",
                },
                insurance: {
                    payorSource: "",
                    insurance: "",
                    policyNumber: "",
                    groupNumber: "",
                    secondaryPayorSource: "",
                    secondaryInsurance: "",
                    secondaryPolicyNumber: "",
                    secondaryGroupNumber: "",
                },
                dischargeDate: "",
            },
            radio: {
                insurance: "",
                hospice: "",
                address: "",
                addressType: "",
                dischargeDate: "",
                sameSim: "",
            },
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        myAccounts: PropTypes.array,
        cardTypes: PropTypes.array,
        expenseTypes: PropTypes.array,
    };

    componentWillReceiveProps(nextProps, nextContext) {
        //console.log(nextProps)
        //if (nextProps.updateOrderModal != null) {
        //    this.setState({
        //        updateOrderModal: nextProps.updateOrderModal,
        //    });
        //}
    }

    calculateAutofocus = (a) => {
        if (this.state['formActivePanel' + a + 'Changed']) {
            return true
        }
    }

    handleNextPrevClick = (a) => (param) => (e) => {
        this.setState({
            ['formActivePanel' + a]: param,
            ['formActivePanel' + a + 'Changed']: true
        });
    }

    handleRadioChange = (value, type) => () => {
        let rb = this.state.radio;
        rb[type] = value;
        //let hc = false;
        //if (value === "yes") {
        //    //hc = true;
        //}
        this.setState({
            radio: rb,
        });
    };

    handleChange(e) {
        let c = this.state.changes
        if (e.target.id && e.target.id === "dischargeDate") {
            c[e.target.id] = e.target.value
        } else (
            c[e.target.alt][e.target.name] = e.target.value
        )
        this.setState({
            changes: c,
        })
    }

    handleDischargeDate(e) {
        let i = this.state.changes
        i.dischargeDate = e.target.value
        this.setState({
            changes: i
        })
    }

    handleSubmission = () => {
        let changes = this.checkForChange();
        this.formatNote(changes);
        this.submitFunction(!changes);
    }

    formatNote(changes) {
        let note = "This note was generated from SalesPilot.";
        if (!changes) {
            note += "\n \n Patient has completed registration for this order and indicated no changes."
        }
        else {
            if (this.state.radio.hospice === "yes") {
                note += "\n \n Patient has indicated that they are signed up for hospice."
            }
            if (this.state.radio.address === "yes") {
                let address = this.state.changes.address;
                note += "\n \n New Address \n Address: " + address.streetAddress + "\n City: " +
                    address.city + "\n State: " + address.state + "\n Zip: " + address.zip
            }
            if (this.state.radio.insurance === "yes") {
                let ins = this.state.changes.insurance;
                note += "\n \n New Primary Insurance  \n Payor: " + ins.payorSource + "\n Provider: " + ins.insurance + "\n Policy Number: " + ins.policyNumber +
                    "\n Group Number: " + ins.groupNumber +
                    "\n \n New Secondary Insurance  \n Payor: " + ins.secondaryPayorSource + "\n Provider: " + ins.secondaryInsurance + "\n Policy Number: " + ins.secondaryPolicyNumber +
                    "\n Group Number: " + ins.secondaryGroupNumber
            }
            if (this.state.radio.sameSim === "yes" && this.state.originalSameSim !== true) {
                note += "\n \n Patient has indicated they might have same or similar equipment."
            }
            if (this.checkFacility()) {
                let addressType = this.state.radio.addressType;
                let type = "";
                if (addressType === "hospital") {
                    type = "Hospital"
                } else if (addressType === "nursingFacility") {
                    type = "Nursing Facility"
                }
                note += "\n \n Patient has indicated that they have moved into a: " + type
            }
            if (this.checkFacility() && (this.state.changes.dischargeDate !== "")) {
                note += "\n \n Stated discharge date: " + this.state.changes.dischargeDate
            }
            if (this.checkFacility() && (this.state.changes.dischargeDate === "")) {
                note += "\n \n Patient states they have no discharge date."
            }
        }

        //this.props.orderIds.forEach((id => this.addNote(id, note)))
        this.addNote(this.props.activeOrder.id, note);
    }

    addNote(orderId, text) {
        const { currentUser } = this.context;
        let order = this.props.activeOrder;
        let note = {
            type: "ActivityNote",
            text: text,
            account: order.account,
            completed: true,
            task: false,
            activityId: orderId,
            createdAt: globalFunctions.getUTCMoment(),
            createdById: currentUser.id,
            dueDate: null,
            //createdBy: {
            //    name: currentUser.username, id: currentUser.id
            //},
        };
        NoteService.createActivityNote(orderId, note)
            .then(r => {
                console.log(r)
            }).catch(e => {
                console.log(e)
            })
    }

    checkForChange() {
        if (this.state.radio.addressType !== "homeResidence") {
            return true
        }
        return Object.values(this.state.radio).includes("yes");
    }

    checkFacility() {
        return (this.state.radio.addressType !== "homeResidence")
        // return this.state.changes.assistedLivingFacility || this.state.changes.skilledNursingFacility || this.state.changes.personalCareFacility || this.state.changes.hospital
    }

    validateAddress() {
        let enable = false;
        if (this.state.radio.address === "yes") {
            enable = Object.values(this.state.changes.address).every(item => item)
            if (!enable) {
                return false
            }
        }
        if (this.state.radio.addressType !== "homeResidence") {
            if (this.state.radio.dischargeDate === "") {
                return false
            } else if (this.state.radio.dischargeDate === "yes") {
                return (this.state.changes.dischargeDate)
            } else if (this.state.radio.dischargeDate === "no") {
                return true;
            }
        }
        return true
    }

    validateInsurance() {
        if (!this.state.radio.insurance) {
            return false
        }
        if (this.state.radio.insurance === "yes") {
            return (Object.values(this.state.changes.insurance).some(item => item));
        }
        return true
    }

    renderInsurance(change) {
        let order = this.props.activeOrder;
        let newInsurance = this.state.changes.insurance;
        return (
            <div>

                <MDBRow
                    style={{
                        border: "1px solid #7986cb",
                        margin: 8,
                        padding: 5,
                        paddingTop: 10,
                        marginTop: 20,
                    }}
                    id="patientPrimaryInsurance"
                >

                    <MDBCol size={"12"} style={{ maxHeight: "0px" }}>
                        <label style={{ left: "-10px", top: "-25px", position: "relative", padding: "0 5px", display: "inline-block", overflow: "hidden", whiteSpace: "nowrap", verticalAlign: "center", backgroundColor: "white", textOverflow: "ellipsis" }}>
                            {change ? "New Primary Insurance" : "Primary Insurance on File"}
                        </label>
                    </MDBCol>

                    <MDBCol md={"6"} className={"insuranceSelects"}>
                        <MDBInput
                            label={"Payor Source"}
                            style={{ paddingTop: 8 }}
                            outline
                            // valueDefault={order.insuranceType.name || (change ? "" : "No Payor Information")}
                            valueDefault={change ? (newInsurance && newInsurance.payorSource ? newInsurance.payorSource : "") : (order.insuranceType ? order.insuranceType.name : "No Payor Information")}
                            disabled={!change}
                            name={"payorSource"}
                            id={"payorSource"}
                            alt={"insurance"}
                            onChange={this.handleChange.bind(this)}
                        />
                    </MDBCol>

                    <MDBCol md={"6"} className={"insuranceSelects"}>
                        <MDBInput
                            label={"Insurance"}
                            style={{ paddingTop: 8 }}
                            outline
                            valueDefault={change ? (newInsurance && newInsurance.insurance ? newInsurance.insurance : "") : (order.insuranceSubType ? order.insuranceSubType.name : "No Insurance Information")}
                            // valueDefault={order.insuranceSubType.name || (change ? "" : "No Insurance Information")}
                            disabled={!change}
                            name={"insurance"}
                            id={"insurance"}
                            alt={"insurance"}
                            onChange={this.handleChange.bind(this)}
                        />
                    </MDBCol>

                    <MDBCol md={"6"} className={"insuranceSelects"}>
                        <MDBInput
                            label={"Policy Number"}
                            style={{ paddingTop: 8 }}
                            outline
                            valueDefault={change ? (newInsurance && newInsurance.policyNumber ? newInsurance.policyNumber : "") : (order.insurancePolicy || "No Policy Number")}
                            // valueDefault={order.insurancePolicy || (change ? "" : "No Policy Number")}
                            disabled={!change}
                            name={"policyNumber"}
                            id={"policyNumber"}
                            alt={"insurance"}
                            onChange={this.handleChange.bind(this)}
                        />
                    </MDBCol>

                    <MDBCol md={"6"} className={"insuranceSelects"}>
                        <MDBInput
                            label={"Group Number"}
                            style={{ paddingTop: 8 }}
                            outline
                            valueDefault={change ? (newInsurance && newInsurance.groupNumber ? newInsurance.groupNumber : "") : (order.insuranceGroup || "No Group Number")}

                            // valueDefault={order.insuranceGroup || (change ? "" : "No Group Number")}
                            disabled={!change}
                            name={"groupNumber"}
                            id={"groupNumber"}
                            alt={"insurance"}
                            onChange={this.handleChange.bind(this)}
                        />
                    </MDBCol>
                </MDBRow>

                <div>
                    <MDBRow
                        style={{
                            border: "1px solid #7986cb",
                            margin: 8,
                            padding: 5,
                            paddingTop: 10,
                            marginTop: 20,
                        }}
                        id="patientPrimaryInsurance"
                    >

                        <MDBCol size={"12"} style={{ maxHeight: "0px" }}>
                            <label style={{ left: "-10px", top: "-25px", position: "relative", padding: "0 5px", display: "inline-block", overflow: "hidden", whiteSpace: "nowrap", verticalAlign: "center", backgroundColor: "white", textOverflow: "ellipsis" }}>
                                {change ? "New Secondary Insurance" : "Secondary Insurance on File"}
                            </label>
                        </MDBCol>

                        <MDBCol md={"6"} className={"insuranceSelects"}>
                            <MDBInput
                                label={"Payor Source"}
                                style={{ paddingTop: 8 }}
                                outline
                                // valueDefault={order.secondaryInsuranceType.name || (change ? "" : "No Payor Information")}
                                valueDefault={change ? (newInsurance && newInsurance.secondaryPayorSource ? newInsurance.secondaryPayorSource : "") : (order.secondaryInsuranceType ? order.secondaryInsuranceType.name : "No Payor Information")}
                                disabled={!change}
                                name={"secondaryPayorSource"}
                                id={"secondaryPayorSource"}
                                alt={"insurance"}
                                onChange={this.handleChange.bind(this)}
                            />
                        </MDBCol>

                        <MDBCol md={"6"} className={"insuranceSelects"}>
                            <MDBInput
                                label={"Insurance"}
                                style={{ paddingTop: 8 }}
                                outline
                                valueDefault={change ? (newInsurance && newInsurance.secondaryInsurance ? newInsurance.secondaryInsurance : "") : (order.secondaryInsuranceSubType ? order.secondaryInsuranceSubType.name : "No Insurance Information")}
                                // valueDefault={order.secondaryInsuranceSubType.name || (change ? "" : "No Insurance Information")}
                                disabled={!change}
                                name={"secondaryInsurance"}
                                id={"secondaryInsurance"}
                                alt={"insurance"}
                                onChange={this.handleChange.bind(this)}
                            />
                        </MDBCol>

                        <MDBCol md={"6"} className={"insuranceSelects"}>
                            <MDBInput
                                label={"Policy Number"}
                                style={{ paddingTop: 8 }}
                                outline
                                valueDefault={change ? (newInsurance && newInsurance.secondaryPolicyNumber ? newInsurance.secondaryPolicyNumber : "") : (order.secondaryInsurancePolicy || "No Policy Number")}

                                // valueDefault={order.secondaryInsurancePolicy || (change ? "" : "No Policy Number")}
                                disabled={!change}
                                name={"secondaryPolicyNumber"}
                                id={"secondaryPolicyNumber"}
                                alt={"insurance"}
                                onChange={this.handleChange.bind(this)}
                            />
                        </MDBCol>

                        <MDBCol md={"6"} className={"insuranceSelects"}>
                            <MDBInput
                                label={"Group Number"}
                                style={{ paddingTop: 8 }}
                                outline
                                valueDefault={change ? (newInsurance && newInsurance.secondaryGroupNumber ? newInsurance.secondaryGroupNumber : "") : (order.secondaryInsuranceGroup || "No Group Number")}
                                // valueDefault={order.secondaryInsuranceGroup || (change ? "" : "No Group Number")}
                                disabled={!change}
                                name={"secondaryGroupNumber"}
                                id={"secondaryGroupNumber"}
                                alt={"insurance"}
                                onChange={this.handleChange.bind(this)}
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
            </div>
        )
    }

    renderDischargeDate() {
        if (this.state.radio.addressType && this.state.radio.addressType !== "homeResidence") {
            return (
                <div>

                    <label> Do you have a discharge date? </label>

                    <MDBInput label="Yes" type="radio" id="dischargeYes"
                        checked={this.state.radio.dischargeDate === "yes"}
                        onClick={this.handleRadioChange("yes", "dischargeDate")} />
                    <MDBInput label="No" type="radio" id="dischargeNo"
                        checked={this.state.radio.dischargeDate === "no"}
                        onClick={this.handleRadioChange("no", "dischargeDate")} />


                    {this.state.radio.dischargeDate === "yes" && (
                        <MDBInput outline label="Discharge Date" name="dischargeDate" className="mt-4 datelabel"
                            autoFocus={this.calculateAutofocus(1)}
                            valueDefault={this.state.changes.dischargeDate}
                            id={"dischargeDate"}
                            onChange={this.handleChange.bind(this)} />
                    )}
                </div>
            )
        }
    }

    renderAddressChange() {
        return (
            <MDBRow
                style={{
                    border: "1px solid #7986cb", margin: 8, padding: 5, paddingTop: 10, marginTop: 20,
                }}
                id="patientAddress"
            >
                <MDBCol size={"12"} style={{ maxHeight: "0px" }}>
                    <label style={{ left: "-10px", top: "-25px", position: "relative", padding: "0 5px", display: "inline-block", overflow: "hidden", whiteSpace: "nowrap", verticalAlign: "center", backgroundColor: "white", textOverflow: "ellipsis" }}>
                        {"New Address"}
                    </label>
                </MDBCol>
                <MDBCol>
                    <MDBInput outline label="Street Address" name="streetAddress" className="mt-3"
                        valueDefault={this.state.changes.address.streetAddress}
                        id={"addressStreet"}
                        alt={"address"}
                        autoFocus={this.calculateAutofocus(1)}
                        onChange={this.handleChange.bind(this)} />
                    <MDBInput outline label="City" name="city" className="mt-3"
                        valueDefault={this.state.changes.address.city}
                        id={"addressCity"}
                        alt={"address"}
                        onChange={this.handleChange.bind(this)} />
                    <MDBInput outline label="State" name="state" className="mt-3"
                        valueDefault={this.state.changes.address.state}
                        id={"addressState"}
                        alt={"address"}
                        onChange={this.handleChange.bind(this)} />
                    <MDBInput outline label="Zip Code" name="zip" className="mt-3"
                        valueDefault={this.state.changes.address.zip}
                        id={"addressZip"}
                        alt={"address"}
                        onChange={this.handleChange.bind(this)} />
                </MDBCol>
            </MDBRow>
        )
    }

    renderInsuranceChange() {

        return (
            <MDBCol>
                <h3 className="font-weight-bold pl-0 my-4">
                    <strong>Has your insurance changed?</strong>
                </h3>
                <MDBInput label="Yes" type="radio" id="insuranceYes"
                    checked={this.state.radio.insurance === "yes"}
                    onClick={this.handleRadioChange("yes", "insurance")} />
                <MDBInput label="No" type="radio" id="insuranceNo"
                    checked={this.state.radio.insurance === "no"}
                    onClick={this.handleRadioChange("no", "insurance")} />
                {this.state.radio.insurance !== "yes" && (

                    <div>
                        {this.renderInsurance()}
                    </div>

                )}


                {this.state.radio.insurance === "yes" && (
                    <div>


                        <MDBCol size={"12"} style={{ marginTop: "10px" }}>
                            <label>
                                {"Please fill out the following fields to the best of your knowledge and ability."}
                            </label>
                        </MDBCol>
                        {this.renderInsurance(true)}
                    </div>

                )}

                <MDBFooter style={{ marginTop: "40px" }}>
                    <MDBRow style={{ justifyContent: 'space-around' }}>
                        <MDBBtn color="indigo" rounded className="float-left" onClick={this.handleNextPrevClick(1)(1)} >
                            previous
                        </MDBBtn>
                        <MDBBtn disabled={!this.validateInsurance()} color="indigo" rounded className="float-right"
                            onClick={this.handleNextPrevClick(1)(3)}>
                            next
                        </MDBBtn>

                    </MDBRow>
                </MDBFooter>
            </MDBCol>

        )
    }

    formatAddress() {
        let patient = this.props.activeOrder.patient;
        let address = [patient.address || "No Address Found", patient.city || "No City Found", patient.state || "No State Found", patient.zipCode || "No ZipCode Found"];
        return address.join(", ");
    }

    renderAddressAndFacilityChange() {
        return (
            <MDBCol md="12">
                <h3 className="font-weight-bold pl-0 my-4">
                    <strong>Has your address changed?</strong>
                </h3>


                <MDBInput label="Yes" type="radio" id="addressYes"
                    checked={this.state.radio.address === "yes"}
                    onClick={this.handleRadioChange("yes", "address")} />
                <MDBInput label="No" type="radio" id="addressNo"
                    checked={this.state.radio.address === "no"}
                    onClick={this.handleRadioChange("no", "address")} />

                <MDBRow
                    style={{
                        border: "1px solid #7986cb", margin: 8, padding: 5, paddingTop: 10, marginTop: 20,
                    }}
                    id="patientAddress"
                >
                    <MDBCol size={"12"} style={{ maxHeight: "0px" }}>
                        <label style={{ left: "-10px", top: "-25px", position: "relative", padding: "0 5px", display: "inline-block", overflow: "hidden", whiteSpace: "nowrap", verticalAlign: "center", backgroundColor: "white", textOverflow: "ellipsis" }}>
                            {"Address On File"}
                        </label>
                    </MDBCol>
                    <MDBCol size={"12"}>
                        <label> {this.formatAddress()} </label>
                    </MDBCol>
                </MDBRow>

                {this.state.radio.address === "yes" && (
                    <div>
                        {this.renderAddressChange()}
                    </div>
                )}
                {this.state.radio.address && (

                    <div>
                        <label> This address is a: </label>

                        <MDBInput label="Home Residence" type="radio" id="homeResidence"
                            checked={this.state.radio.addressType === "homeResidence"}
                            onClick={this.handleRadioChange("homeResidence", "addressType")} />
                        <MDBInput label="Nursing Facility" type="radio" id="nursingFacility"
                            checked={this.state.radio.addressType === "nursingFacility"}
                            onClick={this.handleRadioChange("nursingFacility", "addressType")} />
                        <MDBInput label="Hospital" type="radio" id="hospital"
                            checked={this.state.radio.addressType === "hospital"}
                            onClick={this.handleRadioChange("hospital", "addressType")} />
                    </div>
                )}
                {this.renderDischargeDate()}

                <MDBFooter style={{ marginTop: "40px" }}>
                    <MDBRow style={{ justifyContent: 'space-around' }}>
                        <MDBBtn color="indigo" rounded className="float-left" onClick={this.handleNextPrevClick(1)(2)}
                            autoFocus={this.calculateAutofocus(1)}>
                            previous
                        </MDBBtn>
                        <MDBBtn disabled={!this.validateAddress()}
                            color="indigo" rounded className="float-right" onClick={this.handleNextPrevClick(1)(4)}>
                            next
                        </MDBBtn>
                    </MDBRow>
                </MDBFooter>
            </MDBCol>)
    }

    renderSameSim() {
        return (
            <MDBCol md="12">
                <h3 className="font-weight-bold pl-0 my-4">
                    <strong>Do you currently have a piece of equipment from another provider?</strong>
                </h3>


                <MDBInput label="Yes" type="radio" id="sameSimYes"
                    checked={this.state.radio.sameSim === "yes"}
                    onClick={this.handleRadioChange("yes", "sameSim")} />
                <MDBInput label="No" type="radio" id="sameSimNo"
                    checked={this.state.radio.sameSim === "no"}
                    onClick={this.handleRadioChange("no", "sameSim")} />


                <MDBFooter style={{ marginTop: "40px" }}>
                    <MDBRow style={{ justifyContent: 'space-around' }}>
                        <MDBBtn color="indigo" rounded className="float-left" onClick={this.handleNextPrevClick(1)(3)}
                            autoFocus={this.calculateAutofocus(1)}>
                            previous
                        </MDBBtn>
                        <MDBBtn color="indigo" rounded className="float-right"
                            onClick={this.handleNextPrevClick(1)(5)}>
                            next
                        </MDBBtn>
                    </MDBRow>
                </MDBFooter>
            </MDBCol>
        )
    }

    renderHospice() {
        return (
            <MDBCol md="12">
                <h3 className="font-weight-bold pl-0 my-4">
                    <strong>Are you currently signed up for hospice?</strong>
                </h3>
                <MDBInput label="Yes" type="radio" id="hospiceYes"
                    checked={this.state.radio.hospice === "yes"}
                    onClick={this.handleRadioChange("yes", "hospice")} />
                <MDBInput label="No" type="radio" id="hospiceNo"
                    checked={this.state.radio.hospice === "no"}
                    onClick={this.handleRadioChange("no", "hospice")} />
                <MDBFooter style={{ marginTop: "40px" }}>
                    <MDBRow style={{ justifyContent: 'space-around' }}>
                        <MDBBtn color="indigo" rounded className="float-left" onClick={this.handleNextPrevClick(1)(3)}
                            autoFocus={this.calculateAutofocus(1)}>
                            previous
                        </MDBBtn>
                        <MDBBtn color="indigo" rounded className="float-right"
                            disabled={!this.state.radio.hospice}
                            onClick={this.handleNextPrevClick(1)(2)}>
                            next
                        </MDBBtn>
                    </MDBRow>
                </MDBFooter>
            </MDBCol>
        )
    }

    renderPaymentInformation() {
        let order = this.props.activeOrder;
        return (
            <MDBCol>
                <h3 className="font-weight-bold pl-0 my-4">
                    <strong>Payment</strong>
                </h3>
                <MDBCol>
                    <MDBInput outline label="Deductible" name="deductible" className="mt-4"
                        autoFocus={this.calculateAutofocus(1)}
                        disabled={true}
                        valueDefault={order.orderActivityApprovals[0] && order.orderActivityApprovals[0].approvals.deductible !== null ? "$" + order.orderActivityApprovals[0].approvals.deductible : "No deductible Information"} />
                    <MDBInput outline label="Co-Insurance" name="coInsurance" className="mt-4"
                        valueDefault={order.orderActivityApprovals[0] && order.orderActivityApprovals[0].approvals.coInsurance !== null ? "$" + order.orderActivityApprovals[0].approvals.coInsurance : "No Co-Insurance Information"}
                        disabled={true} />
                    <MDBInput outline label="Patient Responsibility" name="patientResponsibility" className="mt-4"
                        valueDefault={order.orderActivityApprovals[0] && order.orderActivityApprovals[0].approvals.patientResponsibility !== null ? "$" + order.orderActivityApprovals[0].approvals.patientResponsibility : "No Patient Responsibility Information"}
                        disabled={true} />
                    <MDBInput outline label="State Sales Tax" name="stateSalesTax" className="mt-4"
                        valueDefault={order.orderActivityApprovals[0] && order.orderActivityApprovals[0].approvals.stateSalesTax !== null ? "$" + order.orderActivityApprovals[0].approvals.stateSalesTax : "No State Sales Tax Information"}
                        disabled={true} />
                    <MDBInput outline label="Payment On Delivery" name="paymentOnDelivery" className="mt-4"
                        valueDefault={order.orderActivityApprovals[0] && order.orderActivityApprovals[0].approvals.paymentOnDelivery !== null ? "$" + order.orderActivityApprovals[0].approvals.paymentOnDelivery : "No Payment On Delivery Information"}
                        disabled={true} />
                </MDBCol>
                <MDBFooter style={{ marginTop: "40px" }}>
                    <MDBRow style={{ justifyContent: 'space-around' }} >
                        <MDBBtn color="indigo" rounded className="float-left" onClick={this.handleNextPrevClick(1)(4)} style={{ margin: "0" }}>
                            previous
                        </MDBBtn>
                        <MDBBtn color="success" rounded className="float-right" style={{ margin: "0" }}
                            onClick={this.handleSubmission}>
                            Submit
                        </MDBBtn>
                    </MDBRow>

                </MDBFooter>
            </MDBCol>
        )
    }

    renderLegal() {
        return (
            <MDBCol>
                <h3 className="font-weight-bold pl-0 my-4">
                    <strong>Legal</strong>
                </h3>
                <MDBCol>
                    <textarea
                        readOnly={true}
                        placeholder={"Legal"}
                        autoFocus
                        id={"legalTExt"}
                        style={{ width: "100%", height: "200px" }}
                    >

                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Magnis dis parturient montes
                        nascetur ridiculus mus mauris vitae ultricies. Risus sed vulputate odio ut. Tincidunt dui ut ornare lectus sit amet est. Arcu vitae elementum curabitur vitae
                        nunc sed velit dignissim. Urna cursus eget nunc scelerisque viverra mauris in aliquam sem. Nibh sed pulvinar proin gravida. Tempor id eu nisl nunc mi ipsum faucibus.
                        Tellus pellentesque eu tincidunt tortor aliquam. Semper auctor neque vitae tempus quam pellentesque nec. Nec sagittis aliquam malesuada bibendum arcu.
                        Eleifend quam adipiscing vitae proin sagittis nisl. Proin nibh nisl condimentum id venenatis. Tempus urna et pharetra pharetra massa massa ultricies mi.

                    </textarea>
                </MDBCol>

                <MDBFooter style={{ marginTop: "40px" }}>
                    <MDBBtn color="indigo" rounded className="float-left" onClick={this.handleNextPrevClick(1)(5)}>
                        previous
                    </MDBBtn>
                    <MDBBtn color="success" rounded className="float-right" onClick={this.handleSubmission}>
                        Acknowledge And Submit
                    </MDBBtn>
                </MDBFooter>
            </MDBCol>
        )
    }

    toggle = () => {
        this.setState({
            originalSameSim: this.props.originalSameSim,
            formActivePanel1: 1,
            order: this.props.order,
            changes: {
                address: {
                    streetAddress: "",
                    city: "",
                    state: "",
                    zip: "",
                },
                insurance: {
                    payorSource: "",
                    insurance: "",
                    policyNumber: "",
                    groupNumber: "",
                    secondaryPayorSource: "",
                    secondaryInsurance: "",
                    secondaryPolicyNumber: "",
                    secondaryGroupNumber: "",
                },
                dischargeDate: "",
            },
            radio: {
                insurance: "",
                hospice: "",
                address: "",
                addressType: "",
                dischargeDate: "",
                sameSim: "",
            }
        })
        this.cancelFunction();
    }

    render() {
        return (
            <Modal isOpen={this.props.modalOpen} toggle={this.toggle}>

                <MDBRow>
                    <MDBCol className={"stepperCard"}>
                        <MDBCard style={{ minWidth: "320px" }} >
                            <MDBCardBody>
                                <h2 className="text-center font-weight-bold pb-4">
                                    <strong>Confirm your information</strong>
                                </h2>
                                <MDBStepper form vertical={true} className={"customStepper"}>
                                    <MDBStep form>
                                        <a href="#formstep1" onClick={() => {
                                            return
                                        }}>
                                            <MDBBtn color={this.state.formActivePanel1 === 1 ? "red" : "indigo"}
                                                circle>
                                                1
                                            </MDBBtn>
                                        </a>
                                        <p>Hospice</p>
                                    </MDBStep>
                                    <MDBStep form>
                                        <a href="#formstep2" onClick={() => {
                                            return
                                        }}>
                                            <MDBBtn color={this.state.formActivePanel1 === 2 ? "red" : "indigo"}
                                                circle>
                                                2
                                            </MDBBtn>
                                        </a>
                                        <p>Insurance</p>
                                    </MDBStep>

                                    <MDBStep form>
                                        <a href="#formstep3" onClick={() => {
                                            return
                                        }}>
                                            <MDBBtn color={this.state.formActivePanel1 === 3 ? "red" : "indigo"}
                                                circle>
                                                3
                                            </MDBBtn>
                                        </a>
                                        <p>Address</p>
                                    </MDBStep>
                                    <MDBStep form>
                                        <a href="#formstep4" onClick={() => {
                                            return
                                        }}>
                                            <MDBBtn color={this.state.formActivePanel1 === 4 ? "red" : "indigo"} circle>
                                                4
                                            </MDBBtn>
                                        </a>
                                        <p>Equipment</p>
                                    </MDBStep>
                                    <MDBStep form>
                                        <a href="#formstep5" onClick={() => {
                                            return
                                        }}>
                                            <MDBBtn color={this.state.formActivePanel1 === 5 ? "red" : "indigo"}
                                                circle>
                                                5
                                            </MDBBtn>
                                        </a>
                                        <p>Payment</p>
                                    </MDBStep>
                                    {/*<MDBStep form>*/}
                                    {/*    <a href="#formstep6" onClick={() => {*/}
                                    {/*        return*/}
                                    {/*    }}>*/}
                                    {/*        <MDBBtn color={this.state.formActivePanel1 === 6 ? "red" : "indigo"}*/}
                                    {/*                circle>*/}
                                    {/*            6*/}
                                    {/*        </MDBBtn>*/}
                                    {/*    </a>*/}
                                    {/*    <p>Legal</p>*/}
                                    {/*</MDBStep>*/}
                                </MDBStepper>

                                <form action="" method="post">
                                    <MDBRow>
                                        {this.state.formActivePanel1 === 1 && (
                                            <MDBCol>
                                                {/*{this.renderAddressChange()}*/}
                                                {this.renderHospice()}
                                            </MDBCol>
                                        )}
                                        {this.state.formActivePanel1 === 2 && (
                                            <MDBCol>
                                                {this.renderInsuranceChange()}
                                            </MDBCol>
                                        )}
                                        {this.state.formActivePanel1 === 3 && (
                                            <MDBCol>
                                                {this.renderAddressAndFacilityChange()}
                                            </MDBCol>
                                        )}
                                        {this.state.formActivePanel1 === 4 && (
                                            <MDBCol>
                                                {this.renderSameSim()}
                                            </MDBCol>
                                        )}
                                        {this.state.formActivePanel1 === 5 && (
                                            <MDBCol>
                                                {this.renderPaymentInformation()}
                                            </MDBCol>
                                        )}
                                        {this.state.formActivePanel1 === 6 && (
                                            <MDBCol>
                                                {this.renderLegal()}
                                            </MDBCol>
                                        )}
                                    </MDBRow>
                                </form>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </Modal>
        );
    };
}
