import Admin from "../Admin";
import React from "react";
import BtProductService from "../../../Seating/Security/BtProductService/btProductService";
import Validator from "../../../Utility/Validator";
import BtProduct from "../../../Utility/Crud/BtProduct";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";
import CardAdminBtProductGeneral from "../../../Component/Card/Admin/BtProduct/CardAdminBtProductGeneral";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete"
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUICard from "@mui/material/Card";
import MUICardContent from "@mui/material/CardContent";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import Progress from "../../../Component/Progress";

export default class AdminBtProductCreateEdit extends Admin {
    constructor(props) {
        super(props);

        this.state.btProduct = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }

    /**
     * Set the data in the state.
     */
    afterComponentDidMount() {
        const btProductId = this.props.match.params.id ? +this.props.match.params.id : null;
        if (btProductId !== null) {
            BtProductService.getBtProductById(btProductId)
                .then((btProduct) => {
                    this.setState({
                        btProduct: btProduct
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    /**
     * Creates the btProduct with the current properties in state.btProduct.
     */
    async createBtProduct() {
        this.setState({
            isProcessing: true,
        })

        return BtProductService.createBtProduct(this.state.btProduct)
            .then(btProduct => {
                // this.context.updateIndexed("btProductsIndexed", btProduct);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Brightree Product created successfully!", { variant: "success" })
            })
            .catch((err) => {
                // JZ. We need to test and make sure that Sentry still captures
                // errors when we catch them in the application.
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Brightree Product.", { variant: "error" })
                this.setState({
                    isProcessing: false,
                })
            });
    }

    /**
     * Updates the btProduct with the current properties in state.btProduct.
     */
    async updateBtProduct() {
        this.setState({
            isProcessing: true,
        });

        return BtProductService.updateBtProduct(this.state.btProduct)
            .then(btProduct => {
                // this.context.updateIndexed("btProductsIndexed", btProduct);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Brightree Product updated successfully!", { variant: "success" })
            })
            .catch(err => {
                console.log(err)
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Brightree Product.", { variant: "error" })
                this.setState({
                    isProcessing: false,
                })
            });
    }

    async deleteBtProduct() {
        this.setState({
            isProcessing: true,
        });

        return BtProductService.deleteBtProduct(this.state.btProduct.id)
            .then(() => {
                // this.context.deleteIndexed("btProductsIndexed", this.state.btProduct.id);
                this.props.history.goBack()
                NotistackEnqueueSnackbar("Brightree Product deleted successfully!", { variant: "success" })
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Brightree Product.", { variant: "error" })
                this.setState({
                    confirmDialogOpen: false,
                })
            });

    }

    /**
     * Render the content.
     */
    renderContent() {
        if (this.state.btProduct) {
            const handleChange = (btProduct) => {
                this.setState({
                    btProduct: {
                        ...this.state.btProduct,
                        ...btProduct
                    },
                });
            }

            return (
                <>
                    <CardAdminBtProductGeneral
                        btProduct={this.state.btProduct}
                        onChange={handleChange.bind(this)}
                        validationIssues={this.state.validationIssues}
                    />
                    <DialogConfirm
                        open={this.state.confirmDialogOpen}
                        onClose={() => this.setState({ confirmDialogOpen: false })}
                        onConfirm={() => this.deleteBtProduct()}
                        text="Are you sure you want to delete this Brightree Product? This cannot be undone."
                        header="Delete Brightree Product"
                        loading={this.state.isProcessing}
                    />
                </>
            );
        }

        return (
            <MUICard>
                <MUICardContent>
                    <Progress message="Loading Brightree Product..." />
                </MUICardContent>
            </MUICard>
        );
    }

    /**
    * Renders the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = "Create Brightree Product";
            subtitle = null;
        } else {
            title = "Edit Brightree Product";
            subtitle = (this.state.btProduct ? (BtProduct.getDisplayName(this.state.btProduct) + " • " + this.state.btProduct.id) : "Loading...");
        }

        const handleClickBack = () => {
            this.props.history.goBack()
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.btProduct,
                {
                    hcpcs: {
                        'required': true
                    },
                    itemId: {
                        'required': true
                    },
                    name: {
                        'required': true
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length > 0) {
                return NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" })
            }

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateBtProduct()
                } else {
                    this.createBtProduct()
                }
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        }

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />))
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
