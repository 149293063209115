import React from "react";
import {
    MDBBtn,
    MDBCol,
    MDBRow,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBIcon,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBInput,
    toast, MDBModal, MDBContainer, Input, MDBSelect,
} from "mdbreact";

import Select from "react-select";
import partsService from "../../Security/ProductService/partsService";
import "./createQuoteModal.css";
import moment from "moment";
import orderService from "../../Security/OrderService/orderService";
import PropTypes from "prop-types";


const columns = [
    {
        label: "ID",
        field: "id",
        sort: "asc",
    },
    {
        label: "Vendor",
        field: "vendor",
        sort: "asc",
    },
    {
        label: "Product",
        field: "product",
        sort: "asc",
    },
    {
        label: "Equipment",
        field: "equipment",
        sort: "asc",
    },
    {
        label: "Qty",
        field: "qty",
        sort: "asc",
    },
    {
        label: "Warranty",
        field: "warranty",
        sort: "asc",
    },
    {
        label: "30 Day Fit",
        field: "thirtyDayFit",
        sort: "asc",
    },
    {
        label: " ",
        field: "remove",
        sort: "asc",
    },
];
const laborColumns = [
    {
        label: "Description",
        field: "description",
        sort: "asc",
    },
    {
        label: "Duration",
        field: "duration",
        sort: "asc",
    }
]


const searchColumns = [
    {
        label: "Item ID",
        field: "itemId",
        sort: "asc",
    },
    {
        label: "Vendor",
        field: "vendor",
        sort: "asc",
    },
    {
        label: "Description",
        field: "description",
        sort: "asc"
    }
]

export default class finalQuoteFromTicketModal extends React.Component {
    constructor(props) {
        super(props);

        this.togglefinalQuoteModalPopup = props.togglefinalQuoteModalPopup;

        this.closeModal = props.closeModal;

        this.state = {
            orderId: props.order.id || props.order.orderId,
            patientName: props.order.patientName,
            eO: props.order,

            isFinalized: false,
            isValid: false,
            allServiceParts: [],
            productList: [],
            equipment: [],
            vendorList: [],
            servicePartsByVendor: [],
            allQuoteItems: [],

            productId: null,
            productVendor: null,
            selectedProduct: null,
            selectedVendor: null,
            selectedEquipment: null,
            qty: 1,
            selectedQty: 1,

            prevOrderId: null,
            currentOrder: props.order,
            prevOrder: null,
            serviceProducts: props.serviceProducts || [],

            ePatient: props.patient,
            currentCondition: null,
            makeModel: props.order.currentModel || "",
            baseCode: null,
            neglectOrNormalUse: null,
            serialNumber: props.order.serialNumber || "",
            currentFullOrder: props.currentFullOrder || null,
            underWarranty: true,
            deliveryDate: null,
            originalPurchaser: null,
            technicianRequired: true,
            serviceDescription: null,
            previousRepairs: null,

            techRequired: "",
            warranty: "",

            small: this.props.small,
            onSmall: this.props.onSmall,
            refresh: this.props.refresh,
            parentOrder: this.props.parentOrder,
            parentOrderInfo: null,

            isDisabled: true,

            nProduct: {
                vendorId: null,
                model: "",
                description: "",
                itemId: "",
                msrp: 0.00,
                hcpc: "",
                cost: 0.00,
                deleted: false,
            },
            nProductQty: 1,
            showAddProduct: false,
            shipToPatient: false,

            searchQuery: '',
            searchResults: [],
            selectedRow: {},

            isOrderDetails: props.isOrderDetails,
            canNotMinimize: props.canNotMinimize,
            equipmentLoaded: false,
            equipmentOptions: [],
            prevOrderSetupDate: null,
            selectedPreviousEquipment: null,
            checkBoxes: {
                warranty: false,
                thirtyDayFit: false,
            },
            shouldAddLaborItem: false,
            maxLabor: 0,
            baseUnitInfo: props.baseUnitInfo,
            allServicePartsLoaded: false
        };
    }
    static contextTypes = {
        productTypes: PropTypes.array,
        productSubTypes: PropTypes.array,
        allVendors: PropTypes.array,
    };

    componentDidMount() {
        this.setBaseUnitInfo();
        this.getAllParts();
        this.retrieveVendors();
        this.getServiceReason();
        this.getParentOrder();
        this.getFullOrder();
    }

    setBaseUnitInfo() {
        this.setState({
            serialNumber: this.state.baseUnitInfo.serial,
            makeModel: this.state.baseUnitInfo.makeModel,
            baseCode: this.state.baseUnitInfo.hcpcs,
        })
    }
    getFullOrder() {
        orderService.getFlatOrderActivity(this.state.parentOrder).then(res => {
            this.setState({
                currentOrder: res,
            })
        })
    }
    getParentOrder() {
        if (!this.state.parentOrder) {
            return
        }
        orderService.getFlatOrderActivity(this.state.parentOrder).then(res => {
            this.setState({
                parentOrderInfo: res,
                serialNumber: this.state.serialNumber ? this.state.serialNumber : res.serialNumber,
                patientName: res.patientName,
                makeModel: this.state.makeModel ? this.state.makeModel : res.currentModel,
            })
        })
    }
    createProductAddToQuote() {
        var prod = this.state.nProduct;

        partsService.createPart(prod)
            .then(res => {
                // console.log(res)
                if (res) {
                    let st = this.state,
                        e = this.state.allQuoteItems,
                        row = {
                            itemId: res.id,
                            vendor: this.state.vendorList.map((v) => {
                                if (v.id === res.vendorId) {
                                    return v.name;
                                }
                                return false;
                            }),
                            product: res.model,
                            equipment: res.itemId,
                            qty: parseInt(st.nProductQty),
                            warranty: this.state.checkBoxes.warranty,
                            thirtyDayFit: this.state.checkBoxes.thirtyDayFit
                        };
                    e.push(row);

                    this.setState({
                        selectedEquipment: null,
                        nProductQty: 1,
                        allQuoteItems: e,
                        nProduct: {
                            vendorId: null,
                            model: "",
                            description: "",
                            itemId: "",
                            msrp: 0.00,
                            hcpc: "",
                            cost: 0.00,
                            deleted: false,
                        },
                        showAddProduct: false,
                        checkBoxes: {
                            warranty: false,
                            thirtyDayFit: false
                        }
                    });
                }
                else
                    toast.warn("There was an issue creating the part.");
            })
            .catch(err => {
                console.log(err);
                toast.error("There was an issue creating the part.");
            })
    }

    getServiceReason() {
        let id = this.state.orderId;

        orderService
            .getServiceOrderReason(id)
            .then((data) => {
                this.setState({
                    prevOrderId: data.previousOrderId,
                });
            })
            .catch((err) => {
                // console.log(err);
            });
    }

    filterOrders() {
        this.setState({
            equipmentLoaded: false
        })

        let prevProducts = this.state.prevOrder.orderActivityProducts.map((e) => e.products);
        let avalibleProducts = [];

        prevProducts = prevProducts.filter(prod => prod.approved === true);

        prevProducts.map((prod, idx) =>
            avalibleProducts.push({
                text: (prod.productType ? prod.productType.name : "") + " | " + (prod.productSubType ? prod.productSubType.name : ""),
                value: idx,
                id: idx,
                makeModel: (prod.productType ? prod.productType.name : "") + " | " + (prod.productSubType ? prod.productSubType.name : ""),
                baseCode: prod.hcpcs,
                serialNumber: prod.serial
            })
        )

        this.setState({
            equipmentLoaded: true,
            equipmentOptions: avalibleProducts,
            prevOrderSetupDate: this.state.prevOrder.setupDate,
        })
    }

    getOrderHistory() {
        let id = this.state.prevOrderId;

        orderService
            .getOrderActivity(id)
            .then((data) => {
                this.setState({
                    prevOrder: data,
                    deliveryDate: moment(data.setupDate).format("MM/DD/YYYY"),
                });
                //this.filterOrders();
                orderService.getOrderProducts(id)
                    .then(res => {
                        this.setState({
                            prevProducts: res,
                        })
                        this.filterOrders();
                    })
                    .catch(e => {
                        console.log(e)
                    })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    setInitialEquipment() {

        let products = this.state.serviceProducts;
        if (!products || products.length < 1) {
            return
        }
        let allServiceParts = this.state.allServiceParts;
        let foundProduct = null;
        products.forEach((prod) => {

            foundProduct = allServiceParts.find((part) => part.id === prod.id);
            foundProduct.qty = prod.qty;
            foundProduct.warranty = prod.warranty;
            foundProduct.thirtyDayFit = prod.thirtyDayFit;
            this.addProduct(foundProduct);
        });

    }

    getAllParts() {
        partsService
            .getAllParts()
            .then((r) => {
                this.setState({
                    allServiceParts: r && r.length > 0 ? r.filter(x => x.deleted !== true) : [],
                    allServicePartsLoaded: true
                }, () => {
                    this.setInitialEquipment()
                });
            })
            .catch((e) => {
                console.log(e);
                this.setState({});
            });
    }

    retrieveVendors() {
        const { allVendors } = this.context;
        this.setState({
            vendorList: allVendors
        })
    }


    handleCreateQuoteBtn() {
        this.props.refresh();
        // this.setState({ isFinalized: true });
        // this.getOrderHistory();
    }

    finalQuote() {

        let arr = [];
        this.state.allQuoteItems.map((r, i) => {
            if (r.itemId === 6128) {
                return null
            }
            return arr.push({
                itemId: r.itemId,
                qty: r.qty,
                warranty: r.warranty,
                thirtyDayFit: r.thirtyDayFit,
                // estimatedBillableLaborUnits: r.maxBillableLaborUnits
            });
        });
        let totalLabor = this.countTotalLabor();
        if (totalLabor < 2) {
            arr.push({
                itemId: 6128,
                qty: 1,
                warranty: false,
                thirtyDayFit: false,
                // estimatedBillableLaborUnits: 0
            });
        }


        orderService.updateOrder(this.state.currentFullOrder).then((res) => {
            // console.log(res)
        }).catch((err) => {
            console.log(err)
        })
        partsService
            .createQuote(this.state.orderId, arr, this.state.shipToPatient)
            .then((r) => {
                if (r === true) {
                    this.setState({
                        isFinalized: true,
                    });
                    this.getOrderHistory();
                    toast.success("Quote created.");
                }
            })
            .catch((e) => {
                console.log(e);
            });
}



    createRxForm() {

        let items = this.state.allQuoteItems || [],
            orderId = this.state.orderId,
            drName = this.state.currentOrder.orderActivityClinicalInfo ? this.state.currentOrder.orderActivityClinicalInfo.name : (this.state.ePatient.ciName || ""),
            drNpi = this.state.currentOrder.orderActivityClinicalInfo ? this.state.currentOrder.orderActivityClinicalInfo.npi : (this.state.ePatient.ciNpi || ""),
            serviceReason = this.state.serviceDescription ? this.state.serviceDescription.trim() : "",
            arr = [];


        let totalLabor = this.countTotalLabor();
        let hasLabor = false;
        items.forEach((item) => {
            if (item.itemId === 6128) {
                hasLabor = true;
            }
        })
        if (totalLabor < 2 && !hasLabor) {
            items.push({
                itemId: 6128,
                qty: 1,
                warranty: false,
                thirtyDayFit: false,
                maxBillableLaborUnits: 0,
                // estimatedBillableLaborUnits: 0
            });
        }

        let totalMaxBillUnits = 0;
        items.forEach((r) => {
            totalMaxBillUnits = totalMaxBillUnits + r.maxBillableLaborUnits;
        });
        if (totalMaxBillUnits > 1) {
            totalMaxBillUnits = 0;
        } else {
            totalMaxBillUnits = 2 - totalMaxBillUnits;
        }
        items.map((r) => {
            if (r.itemId === 6128) {
            return arr.push({
                itemId: r.itemId,
                qty: r.qty,
                estimatedBillableLaborUnits: totalMaxBillUnits
            });
            } else {
                return arr.push({
                    itemId: r.itemId,
                    qty: r.qty,
                    estimatedBillableLaborUnits: 0
                });
            }
        });
        var obj = {
            items: arr,
            doctorName: drName,
            npi: drNpi,
            serviceReason: serviceReason,
        };

        partsService.createRx(orderId, obj)
            .then(res => {
                if (res === true) {
                    toast.success("Prescription created.");
                    this.setState({
                        allQuoteItems: [],
                        //serviceDescription: null,
                    })
                }
                else {
                    toast.warn("There was an error creating the prescription.");
                }
            })
            .catch(ex => {
                console.log(ex)
            })
    }

    getVendorList() {
        let vendors = this.state.vendorList;
        let a = [];

        vendors.map((v) => {
            return a.push({
                label: v.name,
                value: v.id,
            });
        });
        return a;
    }

    getProductItemId(equipment) {
        let cat = this.state.selectedProduct;
        let all = this.state.allServiceParts;

        all.filter(
            (x) => x.description === equipment.value && x.model === cat.value
        ).map((d) => {
            this.setState({
                productId: d.id,
            });
            return true;
        });
    }

    getProductsByVendor(vendor) {
        let a = [];
        let all = this.state.allServiceParts;

        all.filter((x) => x.vendorId === vendor.value).map((p) => {
            if (a.filter((r) => r.label === p.model).length === 0) {
                return a.push({
                    label: p.model,
                    value: p.model,
                })
            }
            return null;
        })

        this.setState({
            productList: [...new Set(a)],
        });
    }

    getEquipmentByProduct(product) {
        if (!product) {
            return
        }
        let a = [];
        let all = this.state.allServiceParts;

        //Filter selected products
        let currentIds = [];
        let products = this.state.allQuoteItems;
        if (products.length > 0) {
            products.map((p) => currentIds.push(p.itemId));
        }
        //End filter selected products

        all.filter(
            (x) =>
                x.model === product.value &&
                x.vendorId === this.state.productVendor.value
        ).map((p) => {
            if (a.filter((r) => r.label === p.description).length === 0) {
                if (currentIds.length > 0 && currentIds.includes(p.id)) {
                    return null;
                }
                return a.push({
                    label: p.description,
                    value: p.description,
                });
            }
            return null;
        });

        a.sort(function (a, b) {
            if (a.label && b.label && a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
            }

            return 0;
        });

        this.setState({
            equipment: [...new Set(a)],
        });
    }

    handleVendorChange = (e) => {
        if (e.value == null) {
            e = null;
        }

        this.setState({
            productVendor: e,
            selectedProduct: null,
            selectedEquipment: null,
            selectedQty: 1,
        });
        this.getProductsByVendor(e);
    };
 

    handleProductChange = (e) => {
        if (e.value == null) {
            e = null;
        }

        this.setState({
            selectedProduct: e,
            selectedEquipment: null,
            selectedQty: 1,
        });
        this.getEquipmentByProduct(e);
    };

    handleEquipmentChange = (e) => {
        if (e.value == null) {
            e = null;
        }

        this.setState({
            selectedEquipment: e,
        });
        this.getProductItemId(e);
    };

    handleQtyChange = (e) => {
        if (e.target.value == null) {
            e.target.value = null;
        }
        this.setState({
            selectedQty: e.target.value,
        });
    };

    renderVendorDropdown() {
        return (
            <div>
                <Select
                    isDisabled={!this.state.allServicePartsLoaded}
                    placeholder={"Vendor..."}
                    options={this.getVendorList()}
                    onChange={this.handleVendorChange.bind(this)}
                    value={this.state.productVendor}
                />
            </div>
        );
    }
 
    renderProductDropdown() {
        return (
            <div>
                <Select
                    isDisabled={
                        this.state.productVendor !== null
                            ? !this.state.isDisabled
                            : this.state.isDisabled
                    }
                    placeholder={"Product..."}
                    value={this.state.selectedProduct}
                    options={this.state.productList}
                    onChange={this.handleProductChange.bind(this)}
                />
            </div>
        );
    }

    handleCheckboxChange = (property, event) => {
        //update the property that the user has edited in the order...BC
        const boxes = this.state.checkBoxes;
        boxes[property] = event.target.checked;
        this.setState({ checkBoxes: boxes });
    };

    renderWarrantyCheckBox() {
        let boxes = this.state.checkBoxes;
        return (
            <div>
                <MDBInput
                    label="Warranty"
                    containerClass="smallMargin"
                    value={boxes.warranty || false}
                    checked={boxes.warranty || false}
                    data-tip="Warranty"
                    type="checkbox"
                    // disabled={this.state.canEditBasicInfo ? false : true}
                    onChange={this.handleCheckboxChange.bind(this, "warranty")}
                    filled
                    id="warrantyCheckbox"
                />
            </div>
        );
    }

    renderThirtyDayFitCheckBox() {
        let boxes = this.state.checkBoxes;
        return (
            <div>
                <MDBInput
                    label="30 Day Fit 4 U"
                    containerClass="smallMargin"
                    value={boxes.thirtyDayFit || false}
                    checked={boxes.thirtyDayFit || false}
                    data-tip="Thirty Day Fit"
                    type="checkbox"
                    // disabled={this.state.canEditBasicInfo ? false : true}
                    onChange={this.handleCheckboxChange.bind(this, "thirtyDayFit")}
                    filled
                    id="thirtyDayFitCheckbox"
                />
            </div>
        );
    }

    renderEquipmentDropdown() {
        return (
            <div>
                <Select
                    isDisabled={
                        this.state.selectedProduct !== null
                            ? !this.state.isDisabled
                            : this.state.isDisabled
                    }
                    placeholder={"Equipment..."}
                    options={this.state.equipment}
                    value={this.state.selectedEquipment}
                    onChange={this.handleEquipmentChange.bind(this)}
                />
            </div>
        );
    }

    renderProductInput() {
        return (
            <>
                <input
                    type="number"
                    id="quantity"
                    className={
                        this.state.selectedEquipment !== null
                            ? "activeInput"
                            : "disabledInput disabled"
                    }
                    style={{
                        borderRadius: "5%",
                        backgroundColor: "white",
                        width: "100%",
                        minHeight: "94%",
                        textAlign: "right",
                    }}
                    placeholder="Qty"
                    min="1"
                    onChange={this.handleQtyChange.bind()}
                    value={this.state.selectedQty}
                ></input>
            </>
        );
    }

    validateInput = (e) => {
        if (!e.target.value || !e.target.value.trim()) {
            e.target.className += " invalid";
        } else {
            e.target.classList.replace("invalid", "normal");
        }
    };

    validateTextarea = (e) => {
        if (!e.target.value || !e.target.value.trim()) {
            e.target.className += " invalidTextarea";
        } else {
            e.target.classList.replace("invalidTextarea", "normalTextarea");
        }
    };

    handleSkipPASummary() {
        this.state.refresh();
    }

    handleSkipCreateQuote() {
        this.setState({
            //allQuoteItems: [],
            isFinalized: true,
        });
        this.getOrderHistory();
    }
    handleCreateTicketPdfBtn() {

    }

    handleCreatePdfBtn() {
        let items = this.state.allQuoteItems || [];
        let totalMaxBillUnits = 0;
        items.forEach((r) => {
            totalMaxBillUnits = totalMaxBillUnits + r.maxBillableLaborUnits;
        });
        if (totalMaxBillUnits > 1) {
            totalMaxBillUnits = 0;
        } else {
            totalMaxBillUnits = 2 - totalMaxBillUnits;
        }
        let st = this.state,
            paSummary = {
                orderId: st.orderId,
                patient: st.patientName ? st.patientName.trim() : this.state.ePatient.firstName + " " + this.state.ePatient.lastName,
                currentCondition: st.currentCondition.trim(),
                makeModel: st.makeModel.trim(),
                baseCode: st.baseCode.trim(),
                neglectOrNormalUse: st.neglectOrNormalUse.trim(),
                serialNumber: st.serialNumber.trim(),
                underWarranty: st.warranty,
                deliveryDate: moment(st.deliveryDate)
                    .format("MM/DD/YYYY")
                    .trim(),
                originalPurchaser: st.originalPurchaser.trim(),
                technicianRequired: st.techRequired,
                serviceDescription: st.serviceDescription.trim(),
                previousRepairs: st.previousRepairs.trim(),
                estimatedBillableLaborUnits: totalMaxBillUnits,
            };

        let paSummaryValues = Object.values(paSummary);

        if (paSummaryValues.includes(null || "")) {
            toast.error("All fields are required.");
        } else {
            orderService
                .buildPaSummary(paSummary)
                .then((r) => {
                    toast.success("PDF Created.");
                    setTimeout(() => {
                        this.createRxForm();
                    }, 100);
                })
                .then((r) => {
                    setTimeout(() => {
                        this.state.refresh()
                        // this.props.onToggle();
                        // this.props.saveOrder(this.state.eO)
                        // window.location.reload();
                    }, 1000);
                })
                .catch((e) => {
                    toast.error(
                        "An error occurred while creating a PA summary."
                    );
                    console.log(e);
                });

        }
    }

    onClick = (nr) => () => {
        this.setState({
            techRequired: nr,
        });
    };

    onWarrantyClick = (nr) => () => {
        this.setState({
            warranty: nr,
        });
    };

    handleTextChange(property, e) {
        e.target.classList.replace("invalid", "normal");

        this.setState({
            [property]: e.target.value,
        });
    }
    setPreviousEquipment(e) {
        if (e.length === 0) {
            return
        }
        let selectedOption = this.state.equipmentOptions[e[0]];
        this.setState({
            makeModel: selectedOption.makeModel,
            baseCode: selectedOption.baseCode,
            serialNumber: selectedOption.serialNumber,
            deliveryDate: new moment(this.state.prevOrderSetupDate).format('YYYY-MM-DD')

        })

    }

    renderPASummary() {
        // let patient = null;
        // if(this.state.serviceTicket){
        //     patient = this.state.ePatient;
        // }
        return (
            <>
                <form
                    className="needs-validation"
                    onSubmit={this.validateInput}
                    noValidate
                >
                    <MDBRow>
                        <MDBCol>
                            <MDBSelect

                                outline
                                label={"Select Equipment From Previous Order"}
                                selected={this.state.selectedPreviousEquipment}
                                style={{ maxHeight: '100px', backgroundColor: 'white', margin: 0 }}
                                options={this.state.equipmentOptions}
                                getValue={this.setPreviousEquipment.bind(this)}
                            />
                        </MDBCol>
                    </MDBRow>


                    <MDBRow>
                        <MDBCol md={"6"}>
                            <MDBInput
                                label="Patient Name"
                                outline
                                required
                                // value={ this.state.serviceTicket ? patient.firstName + " " + patient.lastName:
                                //     this.state.patientName !== null
                                //         ? this.state.patientName
                                //         : this.handleTextChange.bind(
                                //             this,
                                //             "patientName"
                                //         )
                                // }
                                value={
                                    this.state.patientName !== null
                                        ? this.state.patientName
                                        : this.handleTextChange.bind(
                                            this,
                                            "patientName"
                                        )
                                }
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "patientName"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                        <MDBCol md={"6"}>
                            <MDBInput
                                value={this.state.currentCondition}
                                required
                                label="Current Condition"
                                outline
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "currentCondition"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={"9"}>
                            <MDBInput
                                label="Equipment Make/Model"
                                outline
                                value={
                                    this.state.makeModel !== null
                                        ? this.state.makeModel
                                        : this.handleTextChange.bind(
                                            this,
                                            "makeModel"
                                        )
                                }
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "makeModel"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                        <MDBCol md={"3"}>
                            <MDBInput
                                label="Base Code"
                                value={
                                    this.state.baseCode !== null
                                        ? this.state.baseCode
                                        : this.handleTextChange.bind(
                                            this,
                                            "baseCode"
                                        )
                                }
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "baseCode"
                                )}
                                onBlur={this.validateInput}
                                outline
                            ></MDBInput>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={"6"}>
                            <MDBInput
                                value={this.state.neglectOrNormalUse}
                                label="Neglect or Normal Use"
                                outline
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "neglectOrNormalUse"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                        <MDBCol md={"6"}>
                            <MDBInput
                                label="Serial Number"
                                outline
                                value={
                                    this.state.serialNumber !== null
                                        ? this.state.serialNumber
                                        : this.handleTextChange.bind(
                                            this,
                                            "serialNumber"
                                        )
                                }
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "serialNumber"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ padding: "20px 0px 30px" }}>
                        <MDBCol md={"3"}>Under Warranty? </MDBCol>
                        <MDBCol md={"2"}>
                            <MDBInput
                                gap
                                onClick={this.onWarrantyClick(true)}
                                checked={
                                    this.state.warranty === true ? true : false
                                }
                                label="Yes"
                                type="radio"
                                id="warranty1"
                                containerClass="mr-5"
                            />{" "}
                        </MDBCol>
                        <MDBCol md={"1"}>
                            <MDBInput
                                gap
                                onClick={this.onWarrantyClick(false)}
                                checked={
                                    this.state.warranty === false ? true : false
                                }
                                label="No"
                                type="radio"
                                id="warranty2"
                                containerClass="mr-5"
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={"3"}>
                            <MDBInput
                                label="Delivery Date"
                                outline
                                type="date"
                                value={this.state.deliveryDate}
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "deliveryDate"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                        <MDBCol md={"9"}>
                            <MDBInput
                                value={this.state.originalPurchaser}
                                label="Original Purchaser"
                                id="ogPurchaser"
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "originalPurchaser"
                                )}
                                outline
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ padding: "20px 0px 30px" }}>
                        <MDBCol md={"3"}>Technician Required? </MDBCol>

                        <MDBCol md={"2"}>
                            <MDBInput
                                gap
                                onClick={this.onClick(true)}
                                checked={
                                    this.state.techRequired === true
                                        ? true
                                        : false
                                }
                                label="Yes"
                                type="radio"
                                id="techRequired1"
                                containerClass="mr-5"
                            />{" "}
                        </MDBCol>
                        <MDBCol md={"2"}>
                            <MDBInput
                                gap
                                onClick={this.onClick(false)}
                                checked={
                                    this.state.techRequired === false
                                        ? true
                                        : false
                                }
                                label="No"
                                type="radio"
                                id="techRequired2"
                                containerClass="mr-5"
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={"12"}>
                            <div className="form-group">
                                <label
                                    htmlFor="serviceDescription"
                                    style={{
                                        textDecoration: "underline",
                                        fontWeight: "500",
                                    }}
                                >
                                    Description of Service:
                                </label>
                                <MDBInput
                                    value={this.state.serviceDescription}
                                    required
                                    type="textarea"
                                    outline
                                    id="serviceDescription"
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    onChange={this.handleTextChange.bind(
                                        this,
                                        "serviceDescription"
                                    )}
                                    rows="3"
                                    onBlur={this.validateTextarea}
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={"12"}>
                            <div className="form-group">
                                <label
                                    htmlFor="prevRepairs"
                                    style={{
                                        textDecoration: "underline",
                                        fontWeight: "500",
                                    }}
                                >
                                    Previous Repairs:
                                </label>
                                <MDBInput
                                    value={this.state.previousRepairs}
                                    required
                                    type="textarea"
                                    outline
                                    id="prevRepairs"
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    onChange={this.handleTextChange.bind(
                                        this,
                                        "previousRepairs"
                                    )}
                                    rows="3"
                                    onBlur={this.validateTextarea}
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </form>
            </>
        );
    }

    handleRemoveItem(i) {
        let all = this.state.allQuoteItems;
        all.splice(i, 1);
        this.setState({
            allQuoteItems: [...new Set(all)],
        });
        this.getEquipmentByProduct(this.state.selectedProduct);
    }

    renderRemoveItem(i) {
        return (
            <MDBIcon
                icon="times"
                style={{ cursor: "pointer" }}
                onClick={() => this.handleRemoveItem(i)}
            />
        );
    }

    addProduct(prod) {
        let st = this.state,
            e = st.allQuoteItems,
            row = {};
        if (!prod) {
            row = {
                itemId: st.productId,
                vendor: st.productVendor.label,
                product: st.selectedProduct.value,
                equipment: st.selectedEquipment.value,
                qty: parseInt(st.selectedQty),
                warranty: st.checkBoxes.warranty,
                thirtyDayFit: st.checkBoxes.thirtyDayFit,
                maxBillableLaborUnits: this.getMaxBillableForLineItem(st.productId)
            };
        }
        else {
            row = {
                itemId: prod.id,
                vendor: this.state.vendorList.map((v) => {
                    if (v.id === prod.vendorId) {
                        return v.name;
                    }
                    return false;
                }),
                product: prod.model,
                equipment: prod.description,
                qty: parseInt(prod.qty),
                warranty: prod.warranty,
                thirtyDayFit: prod.thirtyDayFit,
                maxBillableLaborUnits: this.getMaxBillableForLineItem(prod.id)
            }

        }
        e.push(row);

        this.setState({
            selectedEquipment: null,
            selectedQty: 1,
            checkBoxes: {
                warranty: false,
                thirtyDayFit: false
            }
        });
        if (!prod) { this.getEquipmentByProduct(this.state.selectedProduct) };
        this.checkShouldAddLaborItem();
    }

    getMaxBillableForLineItem(itemId) {
        //TODO
        if (itemId === 6128) { return 0 }
        // if (itemId === 1) { return 2 }
        let serviceParts = this.state.allServiceParts;
        let fullQuoteItems = [];

        let fullItem = serviceParts.find((part) => part.id === itemId);
        if (fullItem) {
            fullQuoteItems.push(fullItem)
        }
        let maxBillableLaborUnits = this.findMaxBillableUnits(fullQuoteItems);
        return maxBillableLaborUnits;



    }
    checkShouldAddLaborItem() {
        let quoteItems = this.state.allQuoteItems;
        let serviceParts = this.state.allServiceParts;
        let fullQuoteItems = [];
        quoteItems.forEach((item) => {
            let fullItem = serviceParts.find((part) => part.id === item.itemId);
            if (fullItem) {
                fullQuoteItems.push(fullItem)
            }
        })
    }
    findMaxBillableUnits(items) {
        if (!items) { return }

        const { productSubTypes } = this.context;
        let totalMaxBill = 0;
        let hcpcs = items.map((item) => {
            return item.hcpc.replace(/\s/g, '')
        })
        let pairedHcpcsLabor = [];
        productSubTypes.forEach((subtype) => {
            if (hcpcs.includes(subtype.hcpcs)) {
                pairedHcpcsLabor.push({ hcpcs: subtype.hcpcs, laborUnits: subtype.maxBillableLaborUnits ? subtype.maxBillableLaborUnits : 0 })
            }
        })
        if (pairedHcpcsLabor.length < 1) { return 0 }

        let groupedHcpcs = Object.values(Object.groupBy(pairedHcpcsLabor, ({ hcpcs }) => hcpcs));

        groupedHcpcs.forEach((group) => {
            let frequency = {},
                max = 0;
            for (var v in group) {
                frequency[group[v].laborUnits] = (frequency[group[v].laborUnits] || 0) + 1;
                if (frequency[group[v].laborUnits] > max) {
                    max = frequency[group[v].laborUnits];
                }
            }
            totalMaxBill = totalMaxBill + parseInt(Object.keys(frequency).reduce((a, b) => frequency[a] > frequency[b] ? a : b));
        })

        return totalMaxBill;
    }
    countTotalLabor() {
        let items = this.state.allQuoteItems;
        let totalLabor = 0;
        items.forEach((item) => {
            totalLabor = totalLabor + item.maxBillableLaborUnits
        })
        return totalLabor
    }

    renderRows() {
        let arr = [];

        this.state.allQuoteItems.map((r, i) => {
            if (r.itemId !== 6128) {
                return arr.push({
                    id: r.itemId,
                    vendor: r.vendor,
                    product: r.product,
                    equipment: r.equipment,
                    qty: r.qty,
                    warranty: r.warranty ? "Yes" : null,
                    thirtyDayFit: r.thirtyDayFit ? "Yes" : null,
                    remove: this.renderRemoveItem(i),
                });
            }
            return false
        });

        return arr;
    }

    renderLaborRow() {
        let arr = [];
        let totalLaborUnits = this.countTotalLabor();
        let laborMax = 2;

        if (totalLaborUnits > 1) {
            laborMax = 0
        } else {
            laborMax = 2 - totalLaborUnits
        }
        arr.push({
            // id: 6128,
            description: "Repair or nonroutine service",

            duration: (15 * laborMax) + " Minutes",
            // vendor: "",
            // product: "Rehab Medical",
            // qty: 1,
            // warranty: null,
            // thirtyDayFit: null,
            // remove: null,
        })
        return arr;
    }
    renderSearchRows() {
        let arr = [];

        this.state.searchResults.map((r, i) => {
            return arr.push({
                itemId: r.itemId,
                vendor: this.state.vendorList.map((v) => {
                    if (v.id === r.vendorId) {
                        return v.name;
                    }
                    return false;
                }),
                description: r.description,
                clickEvent: () => {
                    this.setState({ selectedRow: r });
                    this.rowClickHandler(r);
                }
            });
        });

        return arr;
    }

    rowClickHandler = (r) => {
        this.setState({
            searchResults: [],
            searchQuery: '',
            selectedRow: r,
            productId: r.id,
            productVendor: {
                label: this.state.vendorList.map((v) => {
                    if (v.id === r.vendorId) {
                        return v.name;
                    }
                    return false;
                }), 
                value: this.state.vendorList.map((v) => {
                    if (v.id === r.vendorId) {
                        return v.id;
                    }
                    return false;
                }),
            },
            selectedProduct: { label: r.model, value: r.model },
            selectedEquipment: { label: r.description, value: r.description },
            selectedQty: 1,
        })
    }

    productIsValid() {
        let prod = this.state.nProduct;

        return !(prod.vendorId && prod.vendorId !== null &&
            prod.model && prod.model.trim().length > 0 &&
            prod.itemId && prod.itemId.trim().length > 0 &&
            prod.hcpc && prod.hcpc.trim().length > 0 &&
            prod.msrp !== null && prod.cost !== null &&
            prod.description && prod.description.trim().length > 0);
    }

    render() {
        if (this.props.small) {
            return null
        }
        else {
            let totalLaborUnits = this.countTotalLabor();
            return (
                <div>
                    {!this.state.isFinalized ? (
                        <MDBModal centered size={"lg"} isOpen={true} disableBackdrop={false}>
                            <div>
                                <MDBModalHeader>
                                    {<h3>Create Quote And Add Parts</h3>}
                                    <MDBIcon
                                        icon="minus"
                                        onClick={this.state.onSmall.bind(this)}
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            top: "22px",
                                            right: "55px",
                                        }}
                                    />
                                    <MDBIcon
                                        icon="times"
                                        onClick={this.props.onToggle}
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            top: "22px",
                                            right: "25px",
                                        }}
                                    />
                                </MDBModalHeader>
                                <MDBModalBody>

                                    <MDBContainer>
                                        <MDBRow center className="form-group">
                                            <MDBCol md={"12"} >
                                                <MDBInput
                                                    outline
                                                    icon="search"
                                                    group
                                                    label="Item ID"
                                                    style={{ backgroundColor: "white" }}
                                                    containerClass={"smallMargin"}
                                                    value={this.state.searchQuery}
                                                    onChange={(e) => {
                                                        let parts = this.state.allServiceParts,
                                                            filteredParts = e.target && e.target.value ? parts.filter((p) => p.itemId ? p.itemId.toLowerCase().includes(e.target.value.toLowerCase()) : null) : null;

                                                        this.setState({
                                                            searchQuery: e.target.value,
                                                            searchResults: filteredParts,
                                                        })
                                                    }}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                    {this.state.searchResults && this.state.searchResults.length > 0 ? (
                                        <div style={{ maxHeight: "400px", overflowY: "auto", position: "absolute", width: "96%", backgroundColor: "white", zIndex: "9999", border: "1px solid darkgray" }}>
                                            <MDBTable hover striped small>
                                                <MDBTableHead columns={searchColumns} />
                                                <MDBTableBody id="tableBody" style={{ cursor: "pointer" }} rows={this.renderSearchRows()} />
                                            </MDBTable>
                                        </div>)
                                        : ""}
                                    {this.state.showAddProduct ? <div></div> :
                                        <div>
                                            <MDBRow>
                                                <MDBCol md={"3"}>
                                                    {" "}
                                                    {this.renderVendorDropdown()}
                                                </MDBCol>
                                                <MDBCol md={"3"}>
                                                    {" "}
                                                    {this.renderProductDropdown()}
                                                </MDBCol>
                                                <MDBCol md={"4"}>
                                                    {" "}
                                                    {this.renderEquipmentDropdown()}
                                                </MDBCol>
                                                <MDBCol md={"2"}>
                                                    {" "}
                                                    {this.renderProductInput()}
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow className="checkboxRow">
                                                <MDBCol size={"6"}></MDBCol>
                                                <MDBCol md={"3"}>
                                                    {" "}
                                                    {this.renderWarrantyCheckBox()}
                                                </MDBCol>
                                                <MDBCol md={"3"}>
                                                    {" "}
                                                    {this.renderThirtyDayFitCheckBox()}
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow end>
                                                <MDBBtn
                                                    style={{ marginRight: "14px" }}
                                                    size="sm"
                                                    color="primary"
                                                    data-tip={"Add Product"}
                                                    onClick={() => this.setState({ showAddProduct: true })}
                                                >
                                                    Add New Product
                                                </MDBBtn>
                                                <MDBBtn
                                                    className={
                                                        this.state.selectedEquipment !== null
                                                            ? ""
                                                            : "disabled"
                                                    }
                                                    style={{ marginRight: "14px" }}
                                                    size="sm"
                                                    color="primary"
                                                    data-tip={"Add to Quote"}
                                                    onClick={() => this.addProduct()}
                                                >
                                                    Add to Quote
                                                </MDBBtn>
                                            </MDBRow>
                                            <MDBRow end style={{ marginRight: "0px" }}>
                                                <Input
                                                    onClick={() => this.setState({ shipToPatient: true })}
                                                    checked={this.state.shipToPatient}
                                                    label="Ship To Patient"
                                                    type="radio"
                                                    id="shipToPatient"
                                                />
                                                <Input
                                                    onClick={() => this.setState({ shipToPatient: false })}
                                                    checked={!this.state.shipToPatient}
                                                    label="Ship To Service Location"
                                                    type="radio"
                                                    id="shipToService"
                                                />
                                            </MDBRow>
                                        </div>
                                    }
                                    {this.state.showAddProduct ?
                                        <MDBRow style={{ display: (this.state.showAddProduct === false ? "none" : "flex") }}>
                                            <MDBCol md="4">
                                                <div>
                                                    <Select
                                                        placeholder={ "Vendor..." }
                                                        options={ this.getVendorList() }
                                                        onChange={(e) => {
                                                            var p = this.state.nProduct;
                                                            p.vendorId = e.value;
                                                            this.setState({
                                                                nProduct: p,
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="4">
                                                <MDBInput
                                                    placeholder="Model"
                                                    outline
                                                    type="text"
                                                    onChange={(e) => {
                                                        var p = this.state.nProduct;
                                                        p.model = e.target.value;
                                                        this.setState({
                                                            nProduct: p,
                                                        })
                                                    }}
                                                />
                                            </MDBCol>
                                            <MDBCol md="4">
                                                <MDBInput
                                                    placeholder="Item ID"
                                                    outline
                                                    type="text"
                                                    onChange={(e) => {
                                                        var p = this.state.nProduct;
                                                        p.itemId = e.target.value;
                                                        this.setState({
                                                            nProduct: p,
                                                        })
                                                    }}
                                                />
                                            </MDBCol>
                                            <MDBCol md="3">
                                                <MDBInput
                                                    placeholder="HCPC"
                                                    outline
                                                    type="text"
                                                    onChange={(e) => {
                                                        var p = this.state.nProduct;
                                                        p.hcpc = e.target.value;
                                                        this.setState({
                                                            nProduct: p,
                                                        })
                                                    }}
                                                />
                                            </MDBCol>
                                            <MDBCol md="3">
                                                <MDBInput
                                                    placeholder="MSRP"
                                                    outline
                                                    type="number"
                                                    onChange={(e) => {
                                                        var p = this.state.nProduct;
                                                        var val = e.target.value;
                                                        if (val)
                                                            p.msrp = parseFloat(parseFloat(e.target.value).toFixed(2));
                                                        else
                                                            p.msrp = null;
                                                        this.setState({
                                                            nProduct: p,
                                                        })
                                                    }}
                                                />
                                            </MDBCol>
                                            <MDBCol md="3">
                                                <MDBInput
                                                    placeholder="Cost"
                                                    outline
                                                    type="number"
                                                    onChange={(e) => {
                                                        var p = this.state.nProduct;
                                                        var val = e.target.value;
                                                        if (val)
                                                            p.cost = parseFloat(parseFloat(e.target.value).toFixed(2));
                                                        else
                                                            p.cost = null;
                                                        this.setState({
                                                            nProduct: p,
                                                        })
                                                    }}
                                                />
                                            </MDBCol>
                                            <MDBCol md="3">
                                                <MDBInput
                                                    placeholder="Cost"
                                                    valueDefault={this.state.nProductQty || 1}
                                                    outline
                                                    type="number"
                                                    onChange={(e) => {
                                                        var val = parseInt(e.target.value);
                                                        this.setState({
                                                            nProductQty: val,
                                                        })
                                                    }}
                                                />
                                            </MDBCol>

                                            <MDBCol md="12">
                                                <MDBInput
                                                    placeholder="Description"
                                                    style={{ marginTop: "0!important" }}
                                                    outline
                                                    type="text"
                                                    onChange={(e) => {
                                                        var p = this.state.nProduct;
                                                        p.description = e.target.value;
                                                        this.setState({
                                                            nProduct: p,
                                                        })
                                                    }}
                                                />
                                            </MDBCol>
                                            <MDBCol md="3">
                                                {this.renderWarrantyCheckBox()}
                                            </MDBCol>
                                            <MDBCol md="6">
                                                {this.renderThirtyDayFitCheckBox()}
                                            </MDBCol>

                                            <MDBCol md="7"></MDBCol>
                                            <MDBCol md="2">
                                                <MDBBtn
                                                    color="red"
                                                    size="md"
                                                    onClick={() => {
                                                        var p = {
                                                            vendorId: null,
                                                            model: "",
                                                            description: "",
                                                            itemId: "",
                                                            msrp: 0.00,
                                                            hcpc: "",
                                                            cost: 0.00,
                                                            deleted: false,
                                                        };
                                                        this.setState({
                                                            nProduct: p,
                                                            showAddProduct: false,
                                                            nProductQty: 1,
                                                        })
                                                    }}
                                                >
                                                    Cancel
                                                </MDBBtn>
                                            </MDBCol>
                                            <MDBCol md="3">
                                                <MDBBtn
                                                    disabled={this.productIsValid()}
                                                    color="success"
                                                    size="md"
                                                    onClick={() => this.createProductAddToQuote()}
                                                >
                                                    Create and Add Product
                                                </MDBBtn>
                                            </MDBCol>

                                            <MDBCol><hr /></MDBCol>
                                        </MDBRow> : <div></div>}

                                    <MDBRow>
                                        <MDBCol>Parts in Quote</MDBCol>
                                    </MDBRow>
                                    <MDBTable striped small>
                                        <MDBTableHead columns={columns} />
                                        <MDBTableBody rows={this.renderRows()} />
                                    </MDBTable>
                                    {totalLaborUnits < 2 ?
                                        <>
                                            <MDBRow>
                                                <MDBCol>Estimated Labor</MDBCol>
                                            </MDBRow>
                                            <MDBTable striped small>
                                                <MDBTableHead columns={laborColumns} />
                                                <MDBTableBody rows={this.renderLaborRow()} />
                                            </MDBTable>
                                        </>
                                        : null}

                                </MDBModalBody>
                                <MDBModalFooter>
                                    {/*{this.state.serviceTicket ? null :*/}
                                    <MDBBtn color="secondary" size="md" onClick={() => this.handleSkipCreateQuote()}>Skip</MDBBtn>


                                    <MDBBtn
                                        size="md"
                                        color="success"
                                        className={
                                            this.state.allQuoteItems < 1
                                                ? "disabled"
                                                : ""
                                        }
                                        onClick={() => this.finalQuote()}
                                    >
                                        {/* { this.state.isOrderDetails ? "Create Service Ticket" : "Create Quote(s)" }     */}
                                        {"Create Quote(s)"}
                                    </MDBBtn>
                                </MDBModalFooter>
                            </div>
                        </MDBModal>
                    ) : (
                        <>
                            <MDBModal centered size={"lg"} isOpen={true} disableBackdrop={false}>
                                <MDBModalHeader>
                                    <h5
                                        style={{
                                            fontWeight: "bold",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        Prior Authorization Repair Summary
                                    </h5>

                                        <MDBIcon
                                            icon="minus"
                                            onClick={this.state.onSmall.bind(this)}
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: "22px",
                                                right: "55px",
                                            }}
                                        />
                                </MDBModalHeader>

                                <MDBModalBody>{this.renderPASummary()}</MDBModalBody>

                                <MDBModalFooter>
                                    <span
                                        style={{
                                            textDecoration: "none",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                            // marginTop: "20px",
                                            position: "absolute",
                                            left: "18px",
                                            color: "red",
                                        }}
                                    >
                                        All fields are required.
                                    </span>
                                    <MDBBtn color="secondary" size="md" onClick={() => this.handleSkipPASummary()}>Skip</MDBBtn>
                                    {/*<MDBBtn color="primary" size="md" onClick={() => this.createRxForm()}>Create RX</MDBBtn>*/}
                                    <MDBBtn
                                        className={
                                            !this.state.currentCondition ||
                                                !this.state.makeModel ||
                                                !this.state.baseCode ||
                                                !this.state.neglectOrNormalUse ||
                                                !this.state.serialNumber ||
                                                this.state.underWarranty === "" ||
                                                !this.state.deliveryDate ||
                                                !this.state.originalPurchaser ||
                                                this.state.techRequired === "" ||
                                                !this.state.serviceDescription ||
                                                !this.state.previousRepairs
                                                ? "disabled"
                                                : ""
                                        }
                                        size="md"
                                        color="success"
                                        type="submit"
                                        onClick={() => this.handleCreatePdfBtn()}
                                    >
                                        <MDBIcon
                                            icon="download"
                                            style={{ marginRight: "6px" }}
                                        />
                                        Submit
                                    </MDBBtn>
                                </MDBModalFooter>
                            </MDBModal>
                        </>
                    )}


                </div>
            );
        }
    }
}
