import Crud from "../Crud";
import MUIBrownColor from "@mui/material/colors/brown";

/**
 * Static helper functions related to ProductTypes.
 */
export default class ProductType extends Crud {

    /**
     * @param {object} productType The productType object.
     * @returns The display name of the productType.
     */
    static getDisplayName(productType) {
        return productType ? productType.name : null;
    }

    /**
     * Determines if a productType matches a search query.
     *
     * @param {object} productType The productType to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the productType matches the query.
     */
    static match(productType, query) {
        return (
            (productType.name !== null && productType.name.toLowerCase().includes(query)) ||
            productType.id === +query
        );
    }

    /**
     * @param {array} productTypes The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(productTypes) {
        return productTypes.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIBrownColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Inventory";
    }
}