import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import Department from "../../../../Utility/Crud/Department";

export default withRouter(class CardAdminDepartmentList extends Card {
    static contextTypes = {
        departmentsIndexed: PropTypes.object
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
 
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "name", name: "Name", type: "string" },
                    { key: "executiveId", name: "Executive", type: "user" },
                    { key: "directorId", name: "Director", type: "user" },
                    { key: "ordinal", name: "Ordinal", type: "number" }
                ]}
                rows={Department.sort(Object.values(this.context.departmentsIndexed))}
                onClick={(department) => {
                    this.props.history.push({
                        pathname: "/admin/department/" + department.id
                    });
                }}
            />
        );
    }
});