import React from "react";
import Card from "../../../Card";
import EmptyState from "../../../EmptyState";
import MUIButton from "@mui/material/Button"
import MUIIconButton from "@mui/material/IconButton"
import MUIAddIcon from "@mui/icons-material/Add"
import MUIDeleteIcon from "@mui/icons-material/Delete"
import MUIGrid from "@mui/material/Grid";
import DialogSearchOrderStatusReason from "../../../Dialog/Search/DialogSearchOrderStatusReason";
import TileOrderStatusReason from "../../../Tile/TileOrderStatusReason";
import PropTypes from "prop-types";

export default class CardAdminQueueFilterOrderActivityOrderStatusReason extends Card {
    static contextTypes = {
        orderStatusReasonsIndexed: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state.orderStatusReasonDialogOpen = false;
    }

    /**
     * Render the content of the card.
     */
    renderContent() {
        const handleRemove = (orderStatusReasonId) => {
            const filter = this.props.queue.filter;
            filter["order_activity.order_status_reason_id"].splice(
                filter["order_activity.order_status_reason_id"].indexOf(orderStatusReasonId),
                1
            );

            this.props.onChange({
                "filter": filter
            });
        }

        if (
            this.props.queue &&
            this.props.queue.filter &&
            this.props.queue.filter["order_activity.order_status_reason_id"] &&
            this.props.queue.filter["order_activity.order_status_reason_id"].length > 0
        ) {
            return (
                <>
                    <MUIGrid container spacing={2}>
                        {this.props.queue.filter["order_activity.order_status_reason_id"].map((orderStatusReasonId) => {
                            return (
                                <MUIGrid key={orderStatusReasonId} item xs={12} sm={6} md={4}>
                                    <TileOrderStatusReason
                                        orderStatusReason={this.context.orderStatusReasonsIndexed[orderStatusReasonId]}
                                        iconButtons={[(<MUIIconButton onClick={() => handleRemove(orderStatusReasonId)}><MUIDeleteIcon /></MUIIconButton>)]}
                                    />
                                </MUIGrid>
                            );
                        })}
                        {this.renderDialog()}
                    </MUIGrid>
                </>
            );
        } else {
            return (
                <>
                    <EmptyState
                        line1="Not Configured"
                        line2="Showing all Order Status Reasons"
                    />
                    {this.renderDialog()}
                </>
            )
        }
    }



    /**
     * Renders a search dialog of Order Status Reasons
     */
    renderDialog() {
        const handleAdd = (orderStatusReason) => {
            const filter = (this.props.queue && this.props.queue.filter) || {};
            if (!filter["order_activity.order_status_reason_id"]) {
                filter["order_activity.order_status_reason_id"] = [];
            }
            filter["order_activity.order_status_reason_id"].push(orderStatusReason.id);
            filter["order_activity.order_status_reason_id"] = [...new Set(filter["order_activity.order_status_reason_id"])];

            this.props.onChange({
                "filter": filter
            });
        }

        return (
            <DialogSearchOrderStatusReason
                open={this.state.orderStatusReasonDialogOpen}
                onClose={() => this.setState({ orderStatusReasonDialogOpen: false })}
                onSelect={handleAdd}
            />
        );
    }

    /**
     * Renders add Order Status Reason button
     */
    getActions() {
        const handleAdd = () => {
            this.setState({
                orderStatusReasonDialogOpen: true,
            });
        }

        const buttonItems = [
            (<MUIButton
                component="label"
                variant="outlined"
                startIcon={<MUIAddIcon />}
                onClick={handleAdd}>Add Order Status Reason</MUIButton>)
        ];

        return buttonItems;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Filter: Order Activity > Order Status Reason";
    }
}