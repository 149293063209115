import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Input,
	toast,
	CardHeader,
	MDBIcon,
	Row,
	Col,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import Select from "react-select";
import ProductSubTypeService from "../Security/ProductService/productSubTypeService";

export default class addProductSubType extends React.Component {
	constructor(props) {
		super(props);

		let pt = props.location.state.productType,
			id = props.location.state.productId;

		let luList = [
			{ label: "0 Units", value: "0" },
			{ label: "1 Unit", value: "1" },
			{ label: "2 Units", value: "2" },
			{ label: "3 Units", value: "3" },
			{ label: "4 Units", value: "4" },
		];

		this.state = {
			productId: id,
			productName: "",
			hcpcs: "",
			errorMessage: "",
			productType: pt,
			allVendors: [],
			vendorOptions: [],
			selectedVendor: null,
			nProduct: {
				overridePoints: false,
			},
			laborUnits: null,
			laborUnitOptions: luList,
		};
	}

	static contextTypes = {
		allVendors: PropTypes.array,
	};

	componentDidMount() {
		this.getVendorsOptions();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { allVendors } = this.context;
		if (
			allVendors.length > 0 &&
			allVendors.length !== prevState.allVendors.length
		) {
			this.getVendorsOptions();
		}
	}

	getVendorsOptions() {
		const { allVendors } = this.context;
		let arr = [];
		allVendors.map((v) => {
			return arr.push({
				label: v.name,
				value: v.id,
				val: { id: v.id, name: v.name },
			});
		});
		this.setState({
			allVendors: allVendors,
			vendorOptions: arr,
		});
	}

	addNameChange = (e) => {
		this.setState({
			productName: e.target.value,
		});
	};

	addHcpcsChange = (e) => {
		this.setState({
			hcpcs: e.target.value,
		});
	};

	handleChange(prop, evt) {
		let p = this.state.nProduct;
		console.log(evt)
		if (prop === "overridePoints") {
			p[prop] = !p[prop];
		} else if (prop === "maxBillableLaborUnits") {
			p[prop] = parseInt(evt.value);
			this.setState({
				laborUnits: evt,
            })
		} else {
			p[prop] = evt.target.value;
		}
		this.setState({
			nProduct: p,
		});
	}

	renderAddProductButton() {
		let st = this.state,
			pt = st.productType,
			np = st.nProduct;
		return (
			<Row>
				<Col md="12">
					<p style={{ color: "red" }}>{this.state.errorMessage}</p>
					<Input
						value={np.name || ""}
						label={"Product Name"}
						onChange={this.handleChange.bind(this, "name")}
						/>
				</Col>
				<Col md="12">
					<Input
						value={np.hcpcs || ""}
						label={"HCPCS"}
						onChange={this.handleChange.bind(this, "hcpcs")}
						/>
				</Col>
				<Col md="12">
					<Input
						onChange={this.handleChange.bind(this, "itemId")}
						label="Item Id"
						value={np.itemId || ""}
						size="sm"
						/>
				</Col>
				<Col md="12">
					<Select
						placeholder={"Vendor"}
						options={st.vendorOptions}
						onChange={this.handleVendorChange.bind(this)}
						value={st.selectedVendor}
						/>
				</Col>
				<Col md="12" style={{ marginTop: "16px", marginBottom: "16px" }}>
					<Select
						placeholder={"Labor Units"}
						options={st.laborUnitOptions}
						onChange={this.handleChange.bind(this, "maxBillableLaborUnits")}
						value={st.laborUnits}
						/>
				</Col>
				<Col md="12">
					<Input
						id="overridepnts"
						checked={np.overridePoints}
						type={"checkbox"}
						onChange={this.handleChange.bind(this, "overridePoints")}
						label="Override Points"
						size="sm"
						/>
				</Col>
				<Col md="12">
					<div style={{ float: "right" }}>
						<Button
							size="sm"
							color={"warning"}
							data-tip={"Back to List"}
							onClick={() =>
								this.props.history.push({
									pathname: "/products/" + st.productId,
									state: {
										product: pt,
									},
								})
							}
						>
							<MDBIcon icon="backward" style={{ fontSize: "2em" }} />
						</Button>

						<Button
							size="sm"
							color={"success"}
							data-tip={"Save New Product"}
							onClick={this.addSaveButtonClick}
						>
							<MDBIcon far icon="save" style={{ fontSize: "2em" }} />
						</Button>
					</div>
					<ReactTooltip />
				</Col>
			</Row>
		);
	}

	handleVendorChange = (e) => {
		this.setState({
			selectedVendor: e,
		});
	};

	addSaveButtonClick = () => {
		if (this.validate()) {
			let st = this.state,
				p = this.state.nProduct;
			//p.productType = st.productType;
			p.productTypeId = parseInt(st.productId);
			if (st.selectedVendor) {
				p.vendor = st.selectedVendor.val;
				p.vendorId = st.selectedVendor.val.id;
			}
			return ProductSubTypeService.addProductSubType(p)
				.then((res) => {
					this.setState({
						errorMessage: "",
						nProduct: { overridePoints: false },
						selectedVendor: null,
					});
					toast.success("Product has been Added!");
				})
				.catch((err) => {
					toast.warn("An error occured adding a new product");
				});
		}
	};

	validate() {
		let p = this.state.nProduct;
		if (!p.name) {
			this.setError("Please enter a product name");
			return false;
		} else if (!p.hcpcs) {
			this.setError("Please enter HCPCS");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Container style={{ width: "30%" }}>
					<Card>
						<CardHeader
							color={"blue-grey lighten-2"}
							style={{ textAlign: "center" }}
						>
							{" "}
							Add New Product
						</CardHeader>
						<CardBody>{this.renderAddProductButton()}</CardBody>
					</Card>
				</Container>
			</div>
		);
	}
}
