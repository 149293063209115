import Crud from "../Crud";
import MUIRedColor from "@mui/material/colors/red";

/**
 * Static helper functions related to InsuranceSubTypes.
 */
export default class InsuranceSubType extends Crud {

    /**
     * @param {object} insuranceSubType The insuranceSubType object.
     * @returns The display name of the insuranceSubType.
     */
    static getDisplayName(insuranceSubType) {
        return insuranceSubType ? insuranceSubType.name : null;
    }

    /**
     * Determines if a insuranceSubType matches a search query.
     *
     * @param {object} insuranceSubType The insuranceSubType to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the insuranceSubType matches the query.
     */
    static match(insuranceSubType, query) {
        return (
            (insuranceSubType.name !== null && insuranceSubType.name.toLowerCase().includes(query)) ||
            insuranceSubType.id === +query
        );
    }

    /**
     * @param {array} insuranceSubTypes The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(insuranceSubTypes) {
        return insuranceSubTypes.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIRedColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "LocalHospital";
    }
}