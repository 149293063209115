import Crud from "../Crud";
import MUICyanColor from "@mui/material/colors/cyan";

/**
 * Static helper functions related to BaseUnits.
 */
export default class BaseUnit extends Crud {

    /**
     * @param {object} baseUnit The baseUnit object.
     * @returns The display name of the baseUnit.
     */
    static getDisplayName(baseUnit) {
        return baseUnit.name;
    }

    /**
     * Determines if a baseUnit matches a search query.
     *
     * @param {object} baseUnit The baseUnit to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the baseUnit matches the query.
     */
    static match(baseUnit, query) {
        return (
            (baseUnit.name !== null && baseUnit.name.toLowerCase().includes(query)) ||
            baseUnit.id === +query
        );
    }

    /**
     * @param {array} baseUnits The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(baseUnits) {
        return baseUnits.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUICyanColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Accessible";
    }
}