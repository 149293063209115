import CardQueueDataGrid from "../CardQueueDataGrid";

export default class CardQueueDataGridPatientIssue extends CardQueueDataGrid {

    columns = {
        "patient_issue.id": {
            "type": "string",
            "name": "Issue #",
            "width": 110,
        },
        "patient_issue.order_id": {
            "type": "string",
            "name": "Order #",
            "width": 100,
        },
        "patient_issue._queue_age": {
            "type": "ageHours",
            "name": "Age",
            "width": 70,
        },
        "patient_issue._queue_age_business": {
            "type": "ageBusinessHours",
            "name": "Age",
            "width": 70,
        },
        "patient_issue._queue_age_days": {
            "source": "patient_issue._queue_age",
            "type": "ageDays",
            "name": "Age",
            "width": 70,
        },
        "patient_issue._queue_age_business_days": {
            "source": "patient_issue._queue_age_business",
            "type": "ageBusinessDays",
            "name": "Age",
            "width": 70,
        },
        "patient_issue.issue_logged_on": {
            "type": "date",
            "name": "Created On",
            "width": 110,
        },
        "patient_issue.issue_logged_by_id": {
            "type": "user",
            "name": "Created By",
            "width": 140,
        },
        "patient_issue.issue_closed_on": {
            "type": "date",
            "name": "Closed On",
            "width": 110,
        },
        "patient_issue.issue_closed_by_id": {
            "type": "user",
            "name": "Closed By",
            "width": 140,
        },
        "patient_issue.issue_status": {
            "type": "patientIssueIssueStatus",
            "name": "Status",
            "width": 100,
        },
        "patient_issue.issue_category_id": {
            "type": "issueCategory",
            "name": "Category",
            "width": 120,
        },
        "patient_issue.issue_reason_id": {
            "type": "issueReason",
            "name": "Reason",
            "width": 120,
        },
        "patient_issue.assigned_to_id": {
            "type": "user",
            "name": "Assigned To",
            "width": 140,
        },
        "patient_issue.next_followup_date": {
            "type": "date",
            "name": "F/U Date",
            "width": 110,
        },
        "patient_issue.patient._name": {
            "type": "string",
            "name": "Patient",
            "width": 140,
        },
        "patient_issue.patient_id": {
            "type": "string",
            "name": "Patient",
            "width": 120,
        },
        "patient_issue._last_patient_issue_note.note_added_on": {
            "type": "date",
            "name": "Last Note",
            "width": 110,
        },
        "patient_issue._last_patient_issue_note.note_text": {
            "type": "stringTooltip",
            "name": "Last Note Text",
            "width": 300,
        },
        "out_of_bounds": {
            "type": "boolean",
            "name": "OOB",
            "width": 80,
        },
        "patient_issue.order_activity.order_type": {
            "type": "string",
            "name": "Order Type",
            "width": 120,
        },
    };

    /**
     * Handle clicking on a grid row.
     */
    handleClickRow(row) {
        if (this.props.onClickRow) {
            this.props.onClickRow();
        }

        this.openOrder(row);
    }

    /**
     * Opens the order.
     *
     * @param {Object} row The grid row.
     */
    openOrder(row) {
        let orderActivityType = Object.values(this.context.orderActivityTypesIndexed).find((o) => o.name.toLowerCase() === row["patient_issue.order_activity.order_type"].toLowerCase())

        if (orderActivityType.activityType.toLowerCase() === "ticket") {
            window.open(`/patientInformation/${row["patient_issue.patient_id"]}/${row["patient_issue.order_id"]}`, "_blank");
        } else {
            window.open(`/order/${row["patient_issue.order_id"]}`, "_blank");
        }

        this.setState({
            queueDirty: true,
        });
    }

}