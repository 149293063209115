import React from "react";
import Card from "../../../Card";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import MUITealColor from "@mui/material/colors/teal";
import MUIRedColor from "@mui/material/colors/red";
import MUIGreyColor from "@mui/material/colors/grey";
import MUIStack from "@mui/material/Stack";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIDownloadIcon from "@mui/icons-material/Download";
import ChipOrderActivityType from "../../../Chip/ChipOrderActivityType";
import QueueRoute from "../../../../Utility/Crud/QueueRoute";
import OrderStatusReason from "../../../../Utility/Crud/OrderStatusReason";
import OrderActivityType from "../../../../Utility/Crud/OrderActivityType";
import Menu from "../../../Menu";

export default withRouter(class CardAdminQueueRouteDiagram extends Card {
    static contextTypes = {
        queueRoutesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
        orderActivityTypesIndexed: PropTypes.object,
        colorsIndexed: PropTypes.object,
        orderStatusesIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        if (this.state.orderActivityType) {
            return (
                <>
                    {this.renderOrderActivityTypeSelector()}
                    <div style={{ height: this.state.treeRenderDetails.dimensions.height + 8, overflowX: "scroll", position: "relative" }}>
                        {this.state.treeRenderDetails.dom}
                    </div>
                </>
            );
        }

        return (
            <>
                {this.renderOrderActivityTypeSelector()}
            </>
        );
    }

    /**
     * Renders the Order Activity Type selector.
     */
    renderOrderActivityTypeSelector() {
        const handleClick = (orderActivityType) => {
            let tree = this.getTree(orderActivityType);
            this.setTreeNodeSizes(tree);

            let treeRenderDetails = this.getTreeRenderDetails(tree);

            this.setState({
                orderActivityType: orderActivityType,
                treeRenderDetails: treeRenderDetails
            });
        };

        const handleDelete = () => {
            this.setState({
                orderActivityType: null
            });
        };

        return (
            <MUIStack direction="row" spacing={1} flexWrap="wrap" useFlexGap={true} sx={{ marginBottom: 2 }}>
                {Object.values(this.context.orderActivityTypesIndexed).map((orderActivityType) => {
                    return (
                        <ChipOrderActivityType
                            key={orderActivityType.id}
                            orderActivityType={orderActivityType}
                            onClick={() => handleClick(orderActivityType)}
                            onDelete={this.state.orderActivityType && this.state.orderActivityType.id === orderActivityType.id ? handleDelete : null}
                        />
                    );
                })}
            </MUIStack>
        );
    }

    /**
     * Renders the tree routing tree.
     *
     * @param {object} tree
     */
    getTreeRenderDetails(tree) {
        const handleClick = (queueRoute) => {
            this.props.history.push({
                pathname: "/admin/queueRoute/" + queueRoute.id
            });
        }

        let dom = [];

        // Width and height of each node.
        const nodeWidth = 180;
        const nodeHeight = 40;
        const nodeIconWidth = 26;
        const nodeIconHeight = nodeHeight;

        // Spacing between nodes.
        const gutter_x = 40;
        const gutter_y = 15;

        let x = 0;
        let y = 0;

        // Track the overall tree dimensions to help size the container.
        const dimensions = {
            "height": 0,
            "width": 0,
        }

        const queue = [tree];

        const renderQueueRouteIcon = (current) => {
            const nodeParts = [];

            if (current.terminationReason === "noChildren") {
                nodeParts.push(
                    <rect
                        key={current.self.id + "-iconRect"}
                        height={nodeIconHeight}
                        width={nodeIconWidth}
                        rx="4"
                        ry="4"
                        style={{ "fill": MUIRedColor[500] }}
                    >
                        <title>No children</title>
                    </rect>
                );
                nodeParts.push(
                    <path
                        key={current.self.id + "-icon"}
                        transform="translate(1, 8)"
                        fill="#fff"
                        d="M18,18H6V6H18V18Z"
                    />
                );
            } else if (current.terminationReason === "previouslySeen") {
                nodeParts.push(
                    <rect
                        key={current.self.id + "-iconRect"}
                        height={nodeIconHeight}
                        width={nodeIconWidth}
                        rx="4"
                        ry="4"
                        style={{ "fill": MUITealColor[800] }}
                    >
                        <title>Node previously expanded</title>
                    </rect >
                );
                nodeParts.push(
                    <path
                        key={current.self.id + "-icon"}
                        transform="translate(1, 8)"
                        fill="#fff"
                        d="M12,18A6,6 0 0,1 6,12C6,11 6.25,10.03 6.7,9.2L5.24,7.74C4.46,8.97 4,10.43 4,12A8,8 0 0,0 12,20V23L16,19L12,15M12,4V1L8,5L12,9V6A6,6 0 0,1 18,12C18,13 17.75,13.97 17.3,14.8L18.76,16.26C19.54,15.03 20,13.57 20,12A8,8 0 0,0 12,4Z"
                    />
                );
            }

            return nodeParts;
        }

        const renderQueueRoute = (current) => {
            const nodeParts = [];

            const toOrderStatusReason = this.context.orderStatusReasonsIndexed[current.self.toOrderStatusReasonId];

            let line1 = QueueRoute.getDisplayName(current.self);
            let line2 = toOrderStatusReason ? ("↳ " + OrderStatusReason.getDisplayName(toOrderStatusReason)) : "";

            // Main rectangle
            nodeParts.push(
                <rect
                    title="test"
                    key={current.self.id + "-mainRect"}
                    className={current.self.id !== 0 ? "node" : "node_initial"}
                    height={nodeHeight}
                    width={nodeWidth}
                    rx="4"
                    ry="4"
                    onClick={() => current.self.id !== 0 && handleClick(current.self)}
                >
                    <title>{`${line1}\n${line2}`}</title>
                </rect>
            );

            // Icons
            const hasIcon = current.terminationReason !== undefined;
            if (hasIcon === true) {
                nodeParts.push(
                    <g key={current.self.id + "-iconGroup"} transform={`translate(${nodeWidth - nodeIconWidth}, 0)`}>
                        {renderQueueRouteIcon(current)}
                    </g>
                );
            }

            const fontFamily = "Arial, Helvetica, sans-serif";
            const fontSize = "11px";

            // Text
            const availableWidth = nodeWidth - (hasIcon ? nodeIconWidth : 0) - 13;

            if (this.getTextWidth(line1, fontFamily, fontSize) > availableWidth) {
                while (this.getTextWidth(line1, fontFamily, fontSize) > availableWidth) {
                    line1 = line1.slice(0, -2).trimEnd();
                }
                line1 += "...";
            }

            if (this.getTextWidth(line2, fontFamily, fontSize) > availableWidth) {
                while (this.getTextWidth(line2, fontFamily, fontSize) > availableWidth) {
                    line2 = line2.slice(0, -2).trimEnd();
                }
                line2 += "...";
            }

            nodeParts.push(
                <text key={current.self.id + "-textLine1"} x="6" y="16" fontFamily={fontFamily} fontSize={fontSize} fill="#fff">
                    {line1}
                </text>
            );

            nodeParts.push(
                <text key={current.self.id + "-textLine2"} x="6" y="32" fontFamily={fontFamily} fontSize={fontSize} fill="#fff">
                    {line2}
                </text>
            );

            // Lines
            const stroke = MUIGreyColor[500];
            let x1, y1, x2, y2;
            if (previous) {
                // Left horiontal line
                x1 = 0;
                y1 = nodeHeight / 2;
                x2 = -1 * (gutter_x / 2);
                y2 = y1;
                nodeParts.push(
                    <line key={current.self.id + "-leftHorizontalLine"} x1={x1} y1={y1} x2={x2} y2={y2} stroke={stroke} />
                );

                // Arrow
                nodeParts.push(
                    <polygon key={current.self.id + "-arrow"} points={`0,${nodeHeight / 2} -4,${(nodeHeight / 2) + 4} -4,${(nodeHeight / 2) - 4}`} fill={stroke} />
                );
            }

            if (previous && current.parent === previous.parent) {
                // Left vertical line
                x1 = -1 * (gutter_x / 2);
                y1 = nodeHeight / 2;
                x2 = x1;
                y2 = (-1 * (current.y - previous.y)) + y1;
                nodeParts.push(
                    <line key={current.self.id + "-leftVerticalLine"} x1={x1} y1={y1} x2={x2} y2={y2} stroke={stroke} />
                );

            }

            if (current.children.length > 0) {
                // Right horiontal line
                x1 = nodeWidth;
                y1 = nodeHeight / 2;
                x2 = nodeWidth + (gutter_x / 2);
                y2 = y1;
                nodeParts.push(
                    <line key={current.self.id + "-rightHorizontalLine"} x1={x1} y1={y1} x2={x2} y2={y2} stroke={stroke} />
                );
            }

            return nodeParts;
        }

        let previous;
        while (queue.length > 0) {
            const current = queue.shift();

            x = (current.depth * (nodeWidth + gutter_x));

            // Set y of current to y of the parent when the parent changes.
            if (!previous || current.parent !== previous.parent) {
                y = current.parent ? current.parent.y : 0;
            }

            current.x = x;
            current.y = y;

            dimensions.height = Math.max(dimensions.height, (y + nodeHeight + gutter_y));
            dimensions.width = Math.max(dimensions.width, (x + nodeWidth + gutter_x));

            let fillColor = MUITealColor[500];
            let fillHoverColor = MUITealColor[300];

            // Leaving this here for future someone to deal with. This is
            // roughly how you would get the elements to use the color of their
            // order status. It wasn't quite working though; everything was
            // orange.

            // if (current.self.toOrderStatusReasonId) {
            //     const toOrderStatusReason = this.context.orderStatusReasonsIndexed[current.self.toOrderStatusReasonId];
            //     const toOrderStatus = this.context.orderStatusesIndexed[toOrderStatusReason.orderStatus];
            //     const color = this.context.colorsIndexed[toOrderStatus.colorId];
            //     fillColor = MUIColors[color.hue][color.shade];
            //     fillHoverColor = MUIColors[color.hue][color.shade - 200];
            // } else {
            //     fillColor = MUIGreyColor[500];
            //     fillHoverColor = MUIGreyColor[300];
            // }

            // Styles to allow hover effects.
            dom.push(
                <style key={current.self.id + "-style"}>
                    {`
                        .node_initial {
                            fill: ${OrderActivityType.getColor()};
                        }
                        .node {
                            cursor: pointer;
                            fill: ${fillColor};
                            transition: fill 200ms ease;
                        }
                        .node:hover {
                            fill: ${fillHoverColor};
                        }
                        text {
                            pointer-events: none;
                        }
                    `}
                </style>
            );

            dom.push(
                <g key={current.self.id + "-mainGroup"} transform={`translate(${x}, ${y})`}>
                    {renderQueueRoute(current)}
                </g>
            );

            y += (current.size * (nodeHeight + gutter_y));

            for (const node of current.children) {
                queue.push(node);
            }

            previous = current;
        }

        return {
            dom: (<svg id="routingTree" xmlns="http://www.w3.org/2000/svg" width={dimensions.width} height={dimensions.height}>{dom}</svg>),
            dimensions: dimensions
        };
    }

    /**
     * Get the width of a text string.
     *
     * @param {string} textContent Text to get the width of.
     * @param {string} fontFamily
     * @param {string} fontSize
     * @returns {number} Width of the text.
     */
    getTextWidth(textContent, fontFamily, fontSize) {
        const svgNode = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svgNode.setAttribute("style", "position: absolute; top: -1000; left: -1000");

        const textNode = document.createElementNS("http://www.w3.org/2000/svg", "text");
        textNode.setAttribute("font-family", fontFamily);
        textNode.setAttribute("font-size", fontSize);
        textNode.textContent = textContent;

        svgNode.appendChild(textNode);
        document.body.appendChild(svgNode);
        const boundingBox = textNode.getBBox();
        document.body.removeChild(svgNode);

        return boundingBox.width;
    }

    /**
     * Iterates over the Queue Routes and returns a tree structure. The tree is
     * generated breadth-first and if a reason already exists as a node in the
     * tree, does not process any children for that node. This eliminates loops.
     *
     * @param {object} orderActivityType The orderActivityType to get the tree
     * for.
     * @returns {object}
     */
    getTree(orderActivityType) {
        const seenOrderStatusReasonIds = [];

        // Making up a fake route for the first node in the tree.
        const rootQueueRoute = {
            id: 0,
            orderActivityTypeId: orderActivityType.id,
            fromOrderStatusReasonId: null,
            toOrderStatusReasonId: null,
            name: OrderActivityType.getDisplayName(orderActivityType) + " - Initial"
        };

        const rootNode = {
            self: rootQueueRoute,
            children: []
        };
        const queue = [rootNode];

        while (queue.length > 0) {
            const current = queue.shift();

            const actualChildren = this.getChildren(current.self);
            let usedChildren = [];
            if (actualChildren.length === 0) {
                current.terminationReason = "noChildren";
            } else {
                if (seenOrderStatusReasonIds.includes(current.self.toOrderStatusReasonId) === true) {
                    current.terminationReason = "previouslySeen";
                } else {
                    usedChildren = actualChildren;
                }
            }

            seenOrderStatusReasonIds.push(current.self.toOrderStatusReasonId);

            for (const child of usedChildren) {
                const childNode = {
                    self: child,
                    parent: current,
                    children: []
                };
                current.children.push(childNode);
                queue.push(childNode);
            }
        }

        return rootNode;
    }

    /**
     * Gets the children of a specific queueRoute as a helper to build the
     * initial tree.
     *
     * @param {object} fromQueueRoute
     * @returns {array} An array of queueRoutes.
     */
    getChildren(fromQueueRoute) {
        return QueueRoute.sort(
            Object.values(this.context.queueRoutesIndexed).filter((queueRoute) => {
                return (
                    queueRoute.orderActivityTypeId === fromQueueRoute.orderActivityTypeId &&
                    queueRoute.fromOrderStatusReasonId === fromQueueRoute.toOrderStatusReasonId
                );
            })
        );
    }

    /**
     * Calculates the size of each subtree and stores it in the tree. Also
     * stores the depth to make things easier later on.
     *
     * @param {object} tree
     * @returns {number} The size of the current subtree.
     */
    setTreeNodeSizes(tree, depth = 0) {
        let size = 0;
        tree.children.forEach((node) => {
            size += this.setTreeNodeSizes(node, depth + 1);
        });
        tree.size = Math.max(1, size);
        tree.depth = depth;

        return tree.size;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Routing Tree";
    }

    getActions() {
        const handleDownload = () => {
            const svgBlob = new Blob([document.getElementById("routingTree").outerHTML], { type: 'image/svg+xml;charset=utf-8' });
            const url = URL.createObjectURL(svgBlob);

            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = this.state.treeRenderDetails.dimensions.width;
                canvas.height = this.state.treeRenderDetails.dimensions.height;
                const context = canvas.getContext("2d");

                context.drawImage(
                    image,
                    0,
                    0,
                    this.state.treeRenderDetails.dimensions.width,
                    this.state.treeRenderDetails.dimensions.height
                );
                URL.revokeObjectURL(url);

                canvas.toBlob((blob) => {
                    const a = document.createElement("a");
                    a.href = URL.createObjectURL(blob);
                    a.download = `Queue Routes - ${OrderActivityType.getDisplayName(this.state.orderActivityType)} - ${new Date().toISOString().slice(0, 10)}.png`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }, "image/png");
            };

            image.src = url;
        }

        return ([
            <Menu
                menuItems={[(
                    <MUIMenuItem disabled={!this.state.orderActivityType} onClick={handleDownload}>
                        <MUIListItemIcon>
                            <MUIDownloadIcon fontSize="small" />
                        </MUIListItemIcon>
                        Download
                    </MUIMenuItem>
                )]}
            >
            </Menu>
        ]);
    }
});