import Crud from "../Crud";
import MUIRedColor from "@mui/material/colors/red";

/**
 * Static helper functions related to users.
 */
export default class User extends Crud {

    /**
     * @param {object} user The user object.
     * @returns The display name of the user.
     */
    static getDisplayName(user) {
        if (!user) {
            return null;
        }

        const parts = [];

        if (user.firstname !== null) {
            parts.push(user.firstname.trim());
        }

        if (user.lastname !== null) {
            parts.push(user.lastname.trim());
        }

        return parts.join(' ');
    }

    /**
     * Determines if a user matches a search query.
     *
     * @param {object} user The user to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the user matches the query.
     */
    static match(user, query) {
        return (
            (
                user.firstname !== null &&
                user.firstname.toLowerCase().includes(query)
            ) ||
            (
                user.firstname !== null &&
                user.lastname !== null &&
                (
                    (user.firstname.toLowerCase() + ' ' + user.lastname.toLowerCase()).includes(query) ||
                    (user.lastname.toLowerCase() + ' ' + user.firstname.toLowerCase()).includes(query) ||
                    (user.lastname.toLowerCase() + ', ' + user.firstname.toLowerCase()).includes(query) ||
                    (user.lastname.toLowerCase() + ',' + user.firstname.toLowerCase()).includes(query)
                )
            ) ||
            (
                user.lastname !== null &&
                user.lastname.toLowerCase().includes(query)
            ) ||
            (
                user.email !== null &&
                user.email.toLowerCase().includes(query)
            ) ||
            user.id === +query
        );
    }

    /**
     * @param {array} users The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(users) {
        return users.sort((a, b) => {
            return (a.firstname + ' ' + a.lastname).localeCompare(b.firstname + ' ' + b.lastname);
        });
    }

    /**
     * @returns {object} The color of this object.
     */
    static getBaseColor() {
        return MUIRedColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Person";
    }
}
