import React from "react";
import PropTypes from "prop-types";
import {
    Container,
    Spinner,
    Col,
    CardHeader,
    Row,
    toast,
    ToastContainer,
    MDBBtn, MDBCol, MDBRow, 
} from "mdbreact";
import UserService from "../Security/UserService/userService";
import Select from "react-select";

export default class PCRLink extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            isLoaded: true,
            allLocs: [],
            allReps: [],
            allLinks: [],
            filteredLinks: [],
            locationsSelect: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            locationsSelected: [],
            repsSelect: [
                {
                    label: "loading...",
                    value: "loading",
                }
            ],
            allPCR: [],
            pcrSelect: [
                {
                    label: "loading...",
                    value: "loading",
                }
            ],
            repsSelected: [],
            pcrSelected: [],
            allLinkRecords: [],
            locationRepIds: [],
        };

    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allLocations: PropTypes.array,
        internalLocations: PropTypes.array,
        salesReps: PropTypes.array,
        allPCR: PropTypes.array,
    };

    componentDidMount() {
        this.getLocationsOptions();
        this.getPcrLinks();
        this.getPCROptions();
        this.getRepOptions();
    }

    //componentDidUpdate(prevProps, prevState, snapshot) {
    //    const { allLocations, allPCR, salesReps } = this.context;
    //    let st = this.state;

    //    if (st.allLocs.length !== allLocations.length) {
    //        this.getLocationsOptions();
    //    }

    //    if (st.allPCR.length !== allPCR.length) {
    //        this.getPCROptions();
    //    }

    //    if (st.allReps.length !== salesReps.length) {
    //        this.getRepOptions();
    //    }
    //}

    getPcrLinks() {
        return UserService.getPCRLinks().then(r => {
            this.setState({
                allLinks: r,
                //filteredLinks: r,
            })
        }).catch(e => {
            console.log(e)
        })
    }

    createPCRLink() {
        let r = this.state.repsSelected,
            pcr = this.state.pcrSelected;

        if (!r.value || !pcr.value) {
            toast.warn("Please select a PCR and a Sales Rep")
            return;
        }

        let o = {
            name: "nothing",
            salesRepId: r.value,
            // salesRep: {
            //     name: r.label,
            //     id: r.value
            // },
            patientCareRepId: pcr.value,
            // patientCareRep: {
            //     name: pcr.label,
            //     id: pcr.value
            // }
        }

        UserService.createPCRLink(o).then(ret => {
            toast.success("Link Created!");

                this.setState({
                    pcrSelected: [],
                    repsSelected: [],
                    filteredLinks: []
                })
                this.getPcrLinks();
            }).catch(e => {
                console.log(e)
                toast.warn("There was an error creating the link")
            })

    }

    updateExistingLink(v) {
        UserService.updatePCRLink(v).then(ret => {
            toast.success("Link Updated!");
        }).catch(e => {
            toast.warn("There was an error updating the link")
        })
    }

    getRepOptions(e) {
        const {salesReps} = this.context;
        let options = [],
            locUserIds = [];
        console.log(e)
        if (!e || e.length === 0) {
            //options = salesReps;
            salesReps.map((rep) => {
                return options.push({
                    label: rep.firstname + " " + rep.lastname,
                    value: rep.id,
                });
            });
        } else {
            salesReps.map((rep) => {
                let hasLocation = rep.userprofileLocations.filter(l => {
                    return l.locationsId === e.value
                })

                if (hasLocation.length > 0) {
                    locUserIds.push(rep.id);
                    return options.push({
                        label: rep.firstname + " " + rep.lastname,
                        value: rep.id,
                    });
                }
                return null;
            });
        }
        console.log(locUserIds)
        let al = this.state.allLinks;

        let fl = al.filter(f => {
            if (locUserIds.includes(f.salesRepId))
                return f;
            else
                return null;
            });
            console.log(fl)

        //let fl = al.filter(f => {
        //    let v = options.find(r => {
        //        return r.value === f.salesRepId
        //    })

        //    if (v != null) {
        //        return f;
        //    }
        //    return null;
        //})

        //TODO: Filter the records from the repLinkTable with the sales reps in the list.

        this.setState({
            allReps: options,
            repsSelect: options,
            filteredLinks: fl,
        });

    }

    getLocationsOptions() {
        const {allLocations} = this.context;

        let select = [];

        allLocations.map((location) => {
            // Populates the locations dropdown depending on which locations the user is in...JK
            if (location.type === 0) {
                return select.push({
                    label: location.name,
                    value: location.id,
                });
             }
             return null;
        });

        this.setState({
            locationsSelect: select,
            allLocs: allLocations,
        });
    }

    getPCROptions() {
        const {allPCR} = this.context;

        let select = [];

        allPCR.map((pcr) => {
            return select.push({
                label: pcr.firstname + " " + pcr.lastname,
                value: pcr.id,
            });
        });

        this.setState({
            pcrSelect: select,
            allPCR: allPCR
        });
    }

    handleLocationChange = (e) => {
        this.setState({
            locationsSelected: e,
        });
        this.getRepOptions(e);
    };

    handleRepChange = (e) => {
        console.log(e)
        let links = this.state.filteredLinks,
            idx = links.findIndex(v => {
                return e === v.salesRepId;
            });

        if (idx > -1) {
            toast.warning("Rep Link already exists.  Please update the existing record below");
            return;
        }

        this.setState({
            repsSelected: e,
        });
    }

    handlePCRChange = e => {
        console.log(e)
        this.setState({
            pcrSelected: e,
        });
    }

    updateProfile(profile) {
        return UserService.updateUser(profile)
            .then((data) => {
                toast.success("Saved Successfully!");
            })
            .catch((err) => {
                toast.warn("An error occurred while saving.");
            });
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            this.renderPCRLinkCards()
        );
    }

    renderPCRLinkCards() {
        let at = this.state.filteredLinks,
            t = this;

        function renderCard(link, idx) {
            if(link.salesRep === null || link.patientCareRep === null){
                return;
            }

            let v = t.state.pcrSelect.findIndex(r => {
                    return r.value === link.patientCareRepId;
                }),
                sval;

            if (v != null) {
                sval = t.state.pcrSelect[v]
            }

            return (

                <CardHeader style={{marginLeft: "4%", marginRight: "4%", backgroundColor: "#ffffff", marginTop: 4}}>
                    <MDBRow key={link.salesRep.id}>

                        <MDBCol size={3} style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold'}}>
                            Sales Rep: {link.salesRep.firstname + " " + link.salesRep.lastname}
                        </MDBCol>
                        <MDBCol size={3}>
                            <Select
                                placeholder="PCR"
                                options={t.state.pcrSelect}
                                onChange={(e) => {
                                    link.patientCareRep = {id: e.value, name: e.label};
                                    link.patientCareRepId = e.value;
                                    sval = e;
                                    t.forceUpdate();
                                }}
                                value={sval}
                            />

                        </MDBCol>
                        <MDBCol size={3}/>
                        <MDBCol size={3}>
                            <MDBBtn size={"sm"} onClick={() => t.updateExistingLink(link)}>
                                Update
                            </MDBBtn>
                        </MDBCol>


                    </MDBRow>
                </CardHeader>

            )
        }

        return (
            at.map((rep, idx) => {
                return (renderCard(rep, idx))
            })
        )


    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    renderLocationsFilter() {
        const {currentUser} = this.context;
        let role = currentUser.role;

        switch (role) {
            case "SALES":
            case "OFFICE_SUPPORT":
            case "CARE_COORDINATOR":
            case "CALL_CENTER":
            case "TECHNICIAN":
            case "DATA_EXPORT":
            case "VERIFICATION":
            case "FOLLOWUP":
            case "COLLECTIONS":
            case "BILLING":
            case "PURCHASING":
            case "FINANCE":
            default:
                return <div/>;
            case "SYSTEM_ADMIN":
            case "ADMIN":
            case "EXECUTIVE_MANAGEMENT":
            case "OFFICE_MANAGER":
            case "SALES_MANAGER":
                return (
                    <Row>
                        <Col md={12} style={{marginTop: 5}}>
                            <Select
                                placeholder="Select Location"
                                // closeMenuOnSelect={false}
                                options={this.state.locationsSelect}
                                onChange={(e) => this.handleLocationChange(e)}
                                value={this.state.locationsSelected}
                            />
                        </Col>
                    </Row>
                );
        }
    }

    renderRepsFilter() {
        return (
            <Row>
                <Col md={12} style={{marginTop: 5}}>
                    <Select
                        placeholder="Select Rep"
                        // closeMenuOnSelect={false}
                        options={this.state.repsSelect}
                        onChange={this.handleRepChange.bind(this)}
                        value={this.state.repsSelected}
                    />
                </Col>
            </Row>
        );
    }

    renderPCRFilter() {
        return (
            <Row>
                <Col md={12} style={{marginTop: 5}}>
                    <Select
                        placeholder="Select PCR"
                        // closeMenuOnSelect={false}
                        options={this.state.pcrSelect}
                        onChange={this.handlePCRChange.bind(this)}
                        value={this.state.pcrSelected}
                    />
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <div >
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />

                <CardHeader style={{backgroundColor: "#5881C1"}}>
                    <Row>
                        <Col md={3} style={{color: "#000"}}>
                            {this.renderLocationsFilter()}
                        </Col>
                        <Col md={3} style={{color: "#000"}}>
                            {this.renderRepsFilter()}
                        </Col>
                        <Col md={3} style={{color: "#000"}}>
                            {this.renderPCRFilter()}
                        </Col>
                        <Col md={3} style={{color: "#000"}}>
                            <MDBBtn size={"sm"} onClick={() => this.createPCRLink()}>
                                Add PCR Link
                            </MDBBtn>
                        </Col>
                    </Row>
                </CardHeader>


                {this.renderTableOrSpinner()}

            </div>
        );
    }
}
