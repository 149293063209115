import React from "react";
import { MDBContainer, Spinner } from "mdbreact";
import OrderService from "../Security/OrderService/orderService";

export default class DeliveryManifest extends React.Component {
	constructor(props) {
		super(props);
		let id = "";

		if (props.match && props.match.params) {
			id = props.match.params.id;
			if (id) {
				this.retrieveOrder(id);
				this.getOrderProducts(id);
			}
		}

		var pt = JSON.parse(sessionStorage.getItem("productTypes"));
		var pst = JSON.parse(sessionStorage.getItem("productSubTypes"));
		this.state = {
			order: {},
			orderProducts: [],
			isLoaded: false,
			productTypes: pt || [],
			productSubTypes: pst || [],
		};
	}

	retrieveOrder(id) {
		return OrderService.getOrderActivity(id).then((data) => {
				this.setState({
					order: data,
					isLoaded: true,
				});
				this.renderDeliveryManifest();
			});
	}

	getOrderProducts(id) {
		OrderService.getOrderProducts(id)
			.then(data => {
				this.setState({
					orderProducts: data,
				})
			})
			.catch(err => {
				console.log(err);
			})
	}

	renderDeliveryManifest() {
		let productTypes = this.state.productTypes,
			productSubTypes = this.state.productSubTypes;

		let o = this.state.order;
		return (
			<div>
				<h2>{o.patientName}</h2>
				<p>{o.address + " " + o.city + ", " + o.state + " " + o.zipCode}</p>
				<p>
					<b>Order ID: </b>
					{o.id}
				</p>
				<p>
					{" "}
					<b>Setup Date: </b>{" "}
					{o.setupDate ? new Date(o.setupDate).toLocaleDateString() : "NO SETUP DATE"}
				</p>
				<table style={{ width: "100%" }}>
					<thead>
						<tr style={{ padding: "5%" }}>
							<th>Category</th>
							<th>Product</th>
							<th>HCPCS</th>
							<th>Quantity</th>
							<th>Serial Number</th>
						</tr>
					</thead>
					<tbody>
						{this.state.orderProducts.map((product, index) => {
							if (product.approved === true) {
								var pt = product.productTypeId && productTypes && productTypes.length > 0 ? productTypes.filter(x => x.id === product.productTypeId)[0] : null;
								var pst = product.productSubTypeId && productSubTypes && productSubTypes.length > 0 ? productSubTypes.filter(x => x.id === product.productSubTypeId)[0] : null;

								return (
									<tr style={{ padding: "5%" }}>
										<td>{pt ? pt.name : "NO CATEGORY NAME"}</td>
										<td>{pst ? pst.name : "NO PRODUCT NAME"}</td>
										<td>{product.hcpcs || (pst ? pst.hcpcs : "NO HCPCS")}</td>
										<td>{product.qty || "NO QUANTITY"}</td>
										<td>{product.serial || "NO SERIAL NUMBER"}</td>
									</tr>
								);
							} else {
								return null;
							}
						})}
					</tbody>
				</table>
			</div>
		);
	}

	renderLoadingSpinner() {
		return (
			<MDBContainer>
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</MDBContainer>
		);
	}

	render() {
		if (!this.state.isLoaded) {
			return this.renderLoadingSpinner();
		}
		return (
			<div style={{ backgroundColor: "white", margin: "5%" }}>
				{this.renderDeliveryManifest()}
			</div>
		);
	}
}
