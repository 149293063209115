import Admin from "../Admin";
import React from "react";
import BtProductService from "../../../Seating/Security/BtProductService/btProductService";
import MUIButton from "@mui/material/Button";
import MUIAddIcon from "@mui/icons-material/Add";
import MUICard from "@mui/material/Card";
import MUICardContent from "@mui/material/CardContent";
import CardAdminBtProductList from "../../../Component/Card/Admin/BtProduct/CardAdminBtProductList";
import PageHeader from "../../../Component/PageHeader";
import Progress from "../../../Component/Progress";

export default class AdminBtProductList extends Admin {
    constructor(props) {
        super(props);
        this.state.btProducts = null;
    }

    /**
     * Render the content.
     */
    renderContent() {
        if (this.state.btProducts) {
            return (
                <CardAdminBtProductList btProducts={this.state.btProducts} />
            );
        }

        return (
            <MUICard>
                <MUICardContent>
                    <Progress message="Loading Brightree Products..." />
                </MUICardContent>
            </MUICard>
        );
    }

    /**
     * Set the data in the state.
     */
    afterComponentDidMount() {
        BtProductService.getAllBtProducts()
            .then((btProducts) => {
                this.setState({
                    btProducts: btProducts.data
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderHeader() {
        const handleClickCreate = () => {
            this.props.history.push({
                pathname: "/admin/btProduct"
            });
        };

        const buttons = [
            (<MUIButton
                component="label"
                variant="contained"
                startIcon={<MUIAddIcon />}
                onClick={handleClickCreate}>Create New</MUIButton>),
        ];

        return (
            <PageHeader title="Brightree Products" buttons={buttons} />
        );
    }
}
