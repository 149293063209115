import React from "react";
import Layer from "../../Layer";
import PageHeader from "../../Component/PageHeader";
import PropTypes from "prop-types";
import MUIGrid from "@mui/material/Grid";
import Team from "../../Utility/Crud/Team";
import TeamQueue from "../../Utility/Crud/TeamQueue";
import TeamUser from "../../Utility/Crud/TeamUser";
import Queue from "../../Utility/Crud/Queue";
import CardQueueDashboard from "../../Component/Card/Queue/CardQueueDashboard";
import queueService from "../../Seating/Security/QueueService/queueService";
import Department from "../../Utility/Crud/Department";
import Security from "../../Utility/Security";
import EmptyState from "../../Component/EmptyState";
import MUICard from "@mui/material/Card";
import MUICardContent from "@mui/material/CardContent";

export default class QueueDashboard extends Layer {
    static contextTypes = {
        currentUser: PropTypes.object,
        departmentsIndexed: PropTypes.object,
        teamsIndexed: PropTypes.object,
        teamQueuesIndexed: PropTypes.object,
        teamUsersIndexed: PropTypes.object,
        queuesIndexed: PropTypes.object,
        termsIndexed: PropTypes.object,
    };

    /**
     * Set the data in the state.
     */
    afterComponentDidMount() {
        let queueIds = [];

        Object.values(this.context.departmentsIndexed).forEach((department) => {
            let teams = Team.read(
                this.context.teamsIndexed,
                [{
                    key: "departmentId",
                    value: department.id
                }]
            );

            // If user can't view all queues.
            if (Security.hasPermission("queue.viewAll") === false) {
                // Do I have a teamUser for one of the teams in the current department?
                let teamUsers = TeamUser.read(
                    this.context.teamUsersIndexed,
                    [{
                        key: "teamId",
                        value: teams.map(team => team.id)
                    }, {
                        key: "userId",
                        value: this.context.currentUser.id
                    }]
                );

                // Am I a manager of one of the teams in the current department?
                let teamsCurrentUserManages = Team.read(
                    this.context.teamsIndexed,
                    [{
                        key: "departmentId",
                        value: department.id
                    },
                    {
                        key: "managerId",
                        value: this.context.currentUser.id
                    }]
                );

                // If the current user is not a member of one of the teams in
                // the department, and the current user is not a manager of one
                // of the teams in the department, don't show this department.
                if (
                    teamUsers.length === 0 &&
                    teamsCurrentUserManages.length === 0
                ) {
                    return;
                }
            }

            const teamQueues = TeamQueue.read(
                this.context.teamQueuesIndexed,
                [{
                    key: "teamId",
                    value: teams.map(team => team.id)
                }]
            );

            queueIds = queueIds.concat(teamQueues.map(teamQueue => teamQueue.queueId));
        });

        // Create an array of promises for each queueId
        // const promises = queueIds.map(queueId =>
        //     queueService.getQueueCounts([queueId])
        //         .then(queueCounts => {
        //             console.info('foo');
        //             // Update the state with the cumulative result
        //             this.setState(prevState => ({
        //                 queueCounts: {
        //                     ...prevState.queueCounts,
        //                     [queueId]: queueCounts[queueId]
        //                 }
        //             }));
        //         })
        //         .catch(err => {
        //             console.log(err);
        //         })
        // );

        // Execute all promises
        // Promise.all(promises);

        /**
         * Maybe temporary, maybe not. getQueueCounts is now being cached on the
         * frontend to limit database connections from loading this page
         * frequently. When the results are cached, the promises resolve very
         * fast and each setState seems to take 50ms to complete, causing the
         * page to hang. This just accumulates the results and does one state
         * update at the end.
         */

        // Create an array of promises for each queueId
        const promises = queueIds.map(queueId =>
            queueService.getQueueCounts([queueId])
                .then(queueCounts => ({
                    queueId,
                    queueCounts: queueCounts[queueId]
                }))
                .catch(err => {
                    console.log(err);
                    return { queueId, queueCounts: null }; // Handle errors gracefully
                })
        );

        // Execute all promises and update state once
        Promise.all(promises)
            .then(results => {
                // Create a result object with cumulative results
                const updatedQueueCounts = results.reduce((acc, { queueId, queueCounts }) => {
                    acc[queueId] = queueCounts;
                    return acc;
                }, {});

                // Update state with the cumulative result
                this.setState(prevState => ({
                    queueCounts: {
                        ...prevState.queueCounts,
                        ...updatedQueueCounts
                    }
                }));
            });
    }

    /**
     * Render the content.
     */
    renderContent() {
        let showEmptyState = true;

        const grid = (
            <MUIGrid container spacing={2}>
                {Department.sort(Object.values(this.context.departmentsIndexed)).map((department) => {
                    const teams = Team.read(
                        this.context.teamsIndexed,
                        [{
                            key: "departmentId",
                            value: department.id
                        }]
                    );

                    // If user can't view all queues.
                    if (Security.hasPermission("queue.viewAll") === false) {
                        // Do I have a teamUser for one of the teams in the current department?
                        let teamUsers = TeamUser.read(
                            this.context.teamUsersIndexed,
                            [{
                                key: "teamId",
                                value: teams.map(team => team.id)
                            }, {
                                key: "userId",
                                value: this.context.currentUser.id
                            }]
                        );

                        // Am I a manager of one of the teams in the current department?
                        let teamsCurrentUserManages = Team.read(
                            this.context.teamsIndexed,
                            [{
                                key: "departmentId",
                                value: department.id
                            },
                            {
                                key: "managerId",
                                value: this.context.currentUser.id
                            }]
                        );

                        // If the current user is not a member of one of the teams in
                        // the department, and the current user is not a manager of one
                        // of the teams in the department, don't show this department.
                        if (
                            teamUsers.length === 0 &&
                            teamsCurrentUserManages.length === 0
                        ) {
                            return null;
                        }
                    }

                    const teamQueues = TeamQueue.read(
                        this.context.teamQueuesIndexed,
                        [{
                            key: "teamId",
                            value: teams.map(team => team.id)
                        }]
                    );

                    const productionQueues = Queue.sort(Queue.read(
                        this.context.queuesIndexed,
                        [
                            {
                                key: "id",
                                value: teamQueues.map(teamQueue => teamQueue.queueId)
                            },
                            {
                                key: "queueTypeTermId",
                                value: 1 // Production
                            }
                        ]
                    ));

                    const errorQueues = Queue.sort(Queue.read(
                        this.context.queuesIndexed,
                        [
                            {
                                key: "id",
                                value: teamQueues.map(teamQueue => teamQueue.queueId)
                            },
                            {
                                key: "queueTypeTermId",
                                value: 2 // Error
                            }
                        ]
                    ));

                    if (
                        productionQueues.length > 0 ||
                        errorQueues.length > 0
                    ) {
                        showEmptyState = false;

                        return (
                            <React.Fragment key={department.id}>
                                <MUIGrid item xs={12} sm={6}>
                                    <CardQueueDashboard
                                        queueCounts={this.state.queueCounts}
                                        department={department}
                                        queueTypeTerm={this.context.termsIndexed[1]} // Production
                                        queues={productionQueues}
                                        size={Math.max(productionQueues.length, errorQueues.length)}
                                    />
                                </MUIGrid>
                                <MUIGrid item xs={12} sm={6}>
                                    <CardQueueDashboard
                                        queueCounts={this.state.queueCounts}
                                        department={department}
                                        queueTypeTerm={this.context.termsIndexed[2]} // Error
                                        queues={errorQueues}
                                        size={Math.max(productionQueues.length, errorQueues.length)}
                                    />
                                </MUIGrid>
                            </React.Fragment>
                        );
                    }

                    return null;
                })}
            </MUIGrid>
        );

        if (showEmptyState === true) {
            return (
                <MUICard>
                    <MUICardContent>
                        <EmptyState line1="No Queues Assigned" line2="Please reach out to your manager to request access." />
                    </MUICardContent>
                </MUICard>
            );
        }

        return grid;
    }

    renderHeader() {
        return (
            <PageHeader title="Queue Dashboard" />
        );
    }

    /**
     * @returns The max width of this page.
     */
    getMaxWidth() {
        return 1000;
    }
}
