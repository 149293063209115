import React from "react";
import MUIBox from "@mui/material/Box"
import MUIContainer from "@mui/material/Container"
import MUIThemeProvider from '@mui/material/styles/ThemeProvider';
import MUICreateTheme from '@mui/material/styles/createTheme';
import MUICssBaseline from '@mui/material/CssBaseline';
import MUIGreyColor from "@mui/material/colors/grey";
import Setting from "./Utility/Setting";

export default class Layer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mounted: false,
            theme: Setting.get("ui.theme") || "light"
        };
    }

    handleThemeStorage = () => {
        this.setState({
            theme: Setting.get("ui.theme")
        });
    }

    /**
     * Context is not available in the constructor, so update the state with
     * this info after the initial render.
     *
     * Do not override this; just use afterComponentDidMount().
     */
    componentDidMount() {
        if (window.salesPilot.loaded === true) {
            this.setState({
                mounted: true
            });
            document.body.scrollTo(0, 0);
            window.addEventListener('setting.ui.theme', this.handleThemeStorage);
            this.afterComponentDidMount();
        }
    }

    /**
     * Override this in the subclass if needed.
     */
    afterComponentDidMount() {
        return null;
    }

    /**
     * Remover the event listener.
     */
    componentWillUnmount() {
        window.removeEventListener('setting.ui.theme', this.handleThemeStorage);
    }

    /**
     * Render a layer inside some containers. Requires this component to be
     * mounted before rendering anything. To further explain: Layers are often
     * reading data from context and placing it in state to be mutated. Context
     * is not accessible in the constructor, so it must be done in
     * componentDidMount(). This means that the component will render, mount,
     * set state, then render again. The second render isn't a performance
     * problem, but it does require coding around the missing data which is
     * annoying. To fix that, this basically only performs the second render.
     */
    render() {
        if (this.state.mounted === true) {
            const themes = {
                "light": {
                    palette: {
                        background: {
                            default: MUIGreyColor[50]
                        }
                    }
                },
                "dark": {
                    palette: {
                        background: {
                            default: "#292929"
                        }
                    }
                }
            }

            const theme = MUICreateTheme(
                {
                    palette: {
                        mode: this.state.theme
                    }
                },
                themes[this.state.theme]
            );

            return (
                <MUIThemeProvider theme={theme}>
                    <MUICssBaseline />
                    <MUIBox sx={{ backgroundColor: "background.default", paddingTop: 2, paddingBottom: 2, paddingLeft: 1, paddingRight: 1, minHeight: '100vh' }} >
                        <MUIContainer disableGutters style={{ maxWidth: this.getMaxWidth() }}>
                            {this.renderHeader()}
                            {this.renderContent()}
                        </MUIContainer>
                    </MUIBox>
                </MUIThemeProvider>
            );
        }

        return null;
    }

    /**
     * Render the header. Defaults to null; subclass should override.
     */
    renderHeader() {
        return null;
    }

    /**
     * Render the content. Defaults to null; subclass should override.
     */
    renderContent() {
        return null;
    }

    /**
     * @returns The max width of this page. Overrideable by child layers.
     * Defaults to an absurdly large number for no max width.
     */
    getMaxWidth() {
        return 9999;
    }
}
