import Crud from "../Crud";
import MUIRedColor from "@mui/material/colors/red";

/**
 * Static helper functions related to IssueCategories.
 */
export default class IssueCategory extends Crud {

    /**
     * @param {object} issueCategory The issueCategory object.
     * @returns The display name of the issueCategory.
     */
    static getDisplayName(issueCategory) {
        return issueCategory ? issueCategory.name : null;
    }

    /**
     * Determines if a issueCategory matches a search query.
     *
     * @param {object} issueCategory The issueCategory to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the issueCategory matches the query.
     */
    static match(issueCategory, query) {
        return (
            (issueCategory.name !== null && issueCategory.name.toLowerCase().includes(query)) ||
            issueCategory.id === +query
        );
    }

    /**
     * @param {array} issueCategories The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(issueCategories) {
        return issueCategories.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIRedColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "CommentBank";
    }
}