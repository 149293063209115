import React from "react";
import Card from "../../../Card";
import EmptyState from "../../../EmptyState";
import MUIButton from "@mui/material/Button"
import MUIIconButton from "@mui/material/IconButton"
import MUIAddIcon from "@mui/icons-material/Add"
import MUIDeleteIcon from "@mui/icons-material/Delete"
import MUIGrid from "@mui/material/Grid";
import DialogSearch from "../../../Dialog/DialogSearch";
// import DialogSearchCompleted from "../../../Dialog/Search/DialogSearchCompleted";
import Tile from "../../../Tile";
// import TileCompleted from "../../../Tile/TileCompleted";
// import PropTypes from "prop-types";

export default class CardAdminQueueFilterPendingPreauthCompleted extends Card {
    // static contextTypes = {
    //     completedesIndexed: PropTypes.object
    // };

    constructor(props) {
        super(props);

        this.state.completedDialogOpen = false;
    }

    /**
     * Render the content of the card.
     */
    renderContent() {
        const handleRemove = (completed) => {
            const filter = this.props.queue.filter;
            filter["pending_preauth.completed"].splice(
                filter["pending_preauth.completed"].indexOf(completed),
                1
            );

            this.props.onChange({
                "filter": filter
            });
        }

        if (
            this.props.queue &&
            this.props.queue.filter &&
            this.props.queue.filter["pending_preauth.completed"] &&
            this.props.queue.filter["pending_preauth.completed"].length > 0
        ) {
            return (
                <>
                    <MUIGrid container spacing={2}>
                        {this.props.queue.filter["pending_preauth.completed"].map((completed) => {
                            return (
                                <MUIGrid key={completed} item xs={12} sm={6} md={4}>
                                    <Tile
                                        title={completed === 1 ? "Completed" : "Not Completed"}
                                        avatarString={completed === 1 ? "Completed" : "Not Completed"}
                                        iconButtons={[(<MUIIconButton onClick={() => handleRemove(completed)}><MUIDeleteIcon /></MUIIconButton>)]}
                                    />
                                </MUIGrid>
                            );
                        })}
                        {this.renderDialog()}
                    </MUIGrid>
                </>
            );
        } else {
            return (
                <>
                    <EmptyState
                        line1="Not Configured"
                        line2="Showing all Completed States"
                    />
                    {this.renderDialog()}
                </>
            )
        }
    }



    /**
     * Renders a search dialog of Issue Statuses
     */
    renderDialog() {
        const handleAdd = (completed) => {
            const filter = (this.props.queue && this.props.queue.filter) || {};
            if (!filter["pending_preauth.completed"]) {
                filter["pending_preauth.completed"] = [];
            }
            filter["pending_preauth.completed"].push(completed.value);
            filter["pending_preauth.completed"] = [...new Set(filter["pending_preauth.completed"])];

            this.props.onChange({
                "filter": filter
            });
        }

        return (
            <DialogSearch
                data={[{ value: 1, name: "Completed" }, { value: 0, name: "Not Completed" }]}
                open={this.state.completedDialogOpen}
                onClose={() => this.setState({ completedDialogOpen: false })}
                onSelect={handleAdd}
            />
        );
    }

    /**
     * Renders add Issue Status button
     */
    getActions() {
        const handleAdd = () => {
            this.setState({
                completedDialogOpen: true,
            });
        }

        const buttonItems = [
            (<MUIButton
                component="label"
                variant="outlined"
                startIcon={<MUIAddIcon />}
                onClick={handleAdd}>Add Completed Status</MUIButton>)
        ];

        return buttonItems;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Filter: Pending Preauth > Completed";
    }
}