import React from "react";
import Chip from "../Chip";
import MUIGreyColor from "@mui/material/colors/grey";
import MUIAvatar from "@mui/material/Avatar";
import OrderStatusReason from "../../Utility/Crud/OrderStatusReason";
import PropTypes from "prop-types";
import * as MUIColors from "@mui/material/colors";

export default class ChipOrderStatusReason extends Chip {
    static contextTypes = {
        colorsIndexed: PropTypes.object,
        orderStatusesIndexed: PropTypes.object,
    };

    /**
     * Renders a MUI Avatar.
     */
    renderAvatar() {
        let color;
        if (this.props.orderStatusReason) {
            const orderStatus = this.context.orderStatusesIndexed[this.props.orderStatusReason.orderStatus];
            const colorObject = this.context.colorsIndexed[orderStatus.colorId];
            color = MUIColors[colorObject.hue][colorObject.shade];
        } else {
            color = MUIGreyColor[500];
        }

        const avatarColor = this.props.isTransparent ? "transparent" : color;
        const iconColor = this.props.isTransparent ? color : "#fff";

        return (
            <MUIAvatar sx={{ bgcolor: avatarColor }}>
                {OrderStatusReason.getIcon({ fontSize: "small", sx: { color: iconColor } })}
            </MUIAvatar>
        );
    }

    /**
     * @return {string} The orderStatusReason description.
     */
    getLabel() {
        return this.props.orderStatusReason ? OrderStatusReason.getDisplayName(this.props.orderStatusReason) : "None";
    }
}
