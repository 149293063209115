import React from "react";
import DialogSearch from "../DialogSearch"
import TileOrderStatusReason from "../../Tile/TileOrderStatusReason";
import PropTypes from "prop-types";
import OrderStatusReason from "../../../Utility/Crud/OrderStatusReason";

export default class DialogSearchOrderStatusReason extends DialogSearch {

    static contextTypes = {
        orderStatusReasonsIndexed: PropTypes.object,
    };

    /**
     * Searches for Order Activity Types by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered orderStatusReasons.
     */
    getSearchResults(query) {
        return OrderStatusReason.search(
            Object.values(this.context.orderStatusReasonsIndexed),
            query,
            true
        );
    }

    /**
     * @param {object} orderStatusReason The orderStatusReason object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileOrderStatusReason component for this orderStatusReason.
     */
    renderTile(orderStatusReason, onClick) {
        const handleClick = () => {
            onClick(orderStatusReason);
            this.close();
        }

        return (
            <TileOrderStatusReason
                key={orderStatusReason.id}
                orderStatusReason={orderStatusReason}
                onClick={handleClick}
            />
        );
    }

    /**
     * @returns {number} Infinity - show them all.
     */
    getMaxResults() {
        return Infinity;
    }

}
