import React from "react";
import DialogSearch from "../DialogSearch"
import TileServiceReason from "../../Tile/TileServiceReason";
import PropTypes from "prop-types";
import ServiceReason from "../../../Utility/Crud/ServiceReason";

export default class DialogSearchServiceReason extends DialogSearch {

    static contextTypes = {
        serviceReasonsIndexed: PropTypes.object,
    };

    /**
     * Searches for Order Activity Types by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered serviceReasons.
     */
    getSearchResults(query) {
        return ServiceReason.search(
            Object.values(this.context.serviceReasonsIndexed),
            query,
            true
        );
    }

    /**
     * @param {object} serviceReason The serviceReason object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileServiceReason component for this serviceReason.
     */
    renderTile(serviceReason, onClick) {
        const handleClick = () => {
            onClick(serviceReason);
            this.close();
        }

        return (
            <TileServiceReason
                key={serviceReason.id}
                serviceReason={serviceReason}
                onClick={handleClick}
            />
        );
    }
}
