import api from "../api";

class ProductSubTypeService {
	async getAllProductSubTypes() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				paged: false,
			};
			api
				.get("/productSubTypes", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	// Gets prodcts from the ID of the product category
	async getOneProductSubType(id) {
		return new Promise((res, reject) => {
			api
				.get("/productType/" + id + "/productSubTypes")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getProductSubType(id) {
		return new Promise((res, reject) => {
			api
				.get("/productSubTypes" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async updateProductSubType(product) {
		return new Promise((res, reject) => {
			api
				.put("/productSubTypes", product)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async deleteProductSubType(id) {
		return new Promise((res, reject) => {
			api
				.delete("/productSubTypes/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async addProductSubType(product) {
		return new Promise((res, reject) => {
			api
				.post("/productSubTypes", product)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}

const productSubTypeService = new ProductSubTypeService();

export default productSubTypeService;
