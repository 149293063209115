import Admin from "../Admin";
import React from "react";
import CardAdminQueueRouteList from "../../../Component/Card/Admin/QueueRoute/CardAdminQueueRouteList";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIAddIcon from "@mui/icons-material/Add";
import CardAdminQueueRouteDiagram from "../../../Component/Card/Admin/QueueRoute/CardAdminQueueRouteDiagram";

export default class AdminQueueRouteList extends Admin {
    /**
     * Render the content.
     */
    renderContent() {
        return (
            <>
                <CardAdminQueueRouteDiagram />
                <CardAdminQueueRouteList />
            </>
        );
    }

    /**
     * Render the header.
     */
    renderHeader() {
        const title = "Queue Routes";

        const handleClickCreate = () => {
            this.props.history.push({
                pathname: "/admin/queueRoute"
            });
        };

        const buttons = [
            (<MUIButton
                component="label"
                variant="contained"
                startIcon={<MUIAddIcon />}
                onClick={handleClickCreate}>Create New</MUIButton>),
        ];

        return (
            <PageHeader title={title} buttons={buttons} />
        );
    }

    /**
     * @returns The max width of this page. Made this one a bit wider to better
     * fit the tree.
     */
    getMaxWidth() {
        return 1500;
    }

}
