import api from "../api";

class NoteService {
	async getOpenActivityNotesByOwner() {
		return new Promise((res, reject) => {
			// let params={
			//     page: 0,
			//     size: 25,
			//     openTasks: true
			// };

			return api
				.get("/activityNotes/tasks")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
							console.log(err);
						});
		});
	}
	async getOpenTasks(id) {
		return new Promise((res, reject) => {
			return api
				.get("/myTasks?id="+id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					console.log(err);
				});
		});
	}

	async getOpenAccountNotesByOwner() {
		return new Promise((res, reject) => {
			// let params={
			//     page: 0,
			//     size: 25,
			    // openTasks: true
			// };

			return api
				.get("/accountNotes/tasks")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
							console.log(err);
						});
		});
	}

	async getOpenActivityNotesAssignedOut() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 25,
			};

			return api
				.get("/activityNotes/tasksAssignedOut", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
							console.log(err);
							reject([]);
						});
		});
	}

	async getNotes(id, params) {
		let p = {
				page: params && params.page ? params.page : 0,
				size: params && params.size ? params.size : 250,
				activityId: id,
			},
			url = "/activities/" + id + "/notes";

		return new Promise((res, reject) => {
			return api
				.get(url, p)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getOpenTherapistNotesByOwner() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				openTasks: true,
			};

			return api
				.get("/therapistNotes/tasks", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
							console.log(err);
						});
		});
	}

	async getTherapistNotes(id) {
		return new Promise((res, reject) => {
			let config = {
					orderId: id,
				},
				url = "/therapistNotes/" + id;

			return api
				.get(url, { params: config })
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createTherapistNote(note) {
		return api.post("/therapistNotes", note);
	}

	async updateTherapistNote(note) {
		let id = note.id;

		return api.put("/therapistNotes/" + id, note);
	}

	async getOrderTimeline(id) {
		return new Promise((res, reject) => {
			let config = {
					orderId: id,
				},
				url = "/orderTimeline/" + id;

			return api
				.get(url, config)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createActivityNote(id, note) {
		note.type = "ActivityNote";
		return api.post("/activities/" + id + "/notes", note);
	}

	async createContactNote(id, note) {
		note.type = "ContactNote";
		return api.post("/contacts/" + id + "/notes", note);
	}

	async createAccountNote(note) {
		note.type = "AccountNote";
		return api.post("/notes", note);
	}
	async addSdfuNote(order) {
		return api.post("/addSdfuNote", order);
	}

	async updateActivityNote(note) {
		return new Promise((res, reject) => {
			return api
				.put("/notes", note)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getNotesByAccount(id) {
		return new Promise((res, reject) => {
			let params = {
					page: 0,
					size: 100,
					accountId: id,
				},
				url = "/sources/" + id + "/notes";

			return api
				.get(url, params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getNotesAndActivityNotesByAccount(id) {
		return new Promise((res, reject) => {
			let url = "/accountActivityNotes/" + id;

			return api
				.get(url, {})
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getNotesByContact(id) {
		return new Promise((res, reject) => {
			let params = {
					page: 0,
					size: 100,
					contactId: id,
				},
				url = "/contacts/" + id + "/notes";

			return api
				.get(url, params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createOrderFollowup(followup){
		return api.post('/orders/orderActivityFollowups', followup);
	}

	async getAllFollowUpNotes() {
		return new Promise((res, reject) => {
			let url = "/followUpNotes";

			return api
				.get(url)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getFollowUpNotesByOrderId(id) {
		return new Promise((res, reject) => {
			let url = "/followUpNotesForOrder/" + id;

			return api
				.get(url)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getFollowUpNoteByNoteId(id) {
		return new Promise((res, reject) => {
			let url = "/followUpNotes/" + id;

			return api
				.get(url)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

}

const noteService = new NoteService();

export default noteService;
