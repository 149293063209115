import React, { Component } from "react";
import { Card } from "@material-ui/core";
import InlineTable from "../../Components/Custom/InlineTable";
import partsService from "../Security/ProductService/partsService";
import VendorService from "../Security/VendorService/vendorService";
import {
    Button, MDBIcon,
    MDBSpinner,
    MDBToastContainer,
} from "mdbreact";
import { toast } from "react-toastify";

export default class ServicePartList extends Component {
    constructor(props) {
        super(props);

        const columnMap = [
            { columnName: "ID", fieldName: "id", canEdit: false, type: "text", required: false, hidden: true, width: "40px" },
            { columnName: "Vendor", fieldName: "vendorId", canEdit: true, type: "dropdown", required: true, options: [], width: "225px" },
            { columnName: "Model", fieldName: "model", canEdit: true, type: "text", required: true, },
            { columnName: "Description", fieldName: "description", canEdit: true, type: "text", required: false, },
            { columnName: "Item ID", fieldName: "itemId", canEdit: true, type: "text", required: true, },
            { columnName: "MSRP", fieldName: "msrp", canEdit: true, type: "float", required: false, width: "100px" },
            { columnName: "HCPC", fieldName: "hcpc", canEdit: true, type: "text", required: false, width: "100px" },
            { columnName: "HCPC (1)", fieldName: "hcpc1", canEdit: true, type: "float", required: false, width: "100px" },
            { columnName: "HCPC (2)", fieldName: "hcpc2", canEdit: true, type: "text", required: false, width: "100px" },
            { columnName: "Cost", fieldName: "cost", canEdit: true, type: "float", required: false, width: "100px" },
            { columnName: "Stock", fieldName: "stock", canEdit: true, type: "bool", required: false, defaultValue: false, width: "60px" },
            { columnName: "Bin", fieldName: "bin", canEdit: true, type: "text", required: false, width: "100px" },
            { columnName: "Inventory", fieldName: "inventory", canEdit: true, type: "int", required: false, width: "100px" },
            { columnName: "Deleted", fieldName: "deleted", canEdit: true, type: "bool", required: false, hidden: true, defaultValue: false, width: "60px" }
        ];
        const data = [];

        this.state = {
            data: data,
            alteredData: [],
            columnMap: columnMap,
            isLoaded: false,
            addModalOpen: false,
            vendorList: [],
        };
    }

    componentDidMount() {
        this.getParts();
        this.getVendors()
    }

    getParts() {
        partsService.getAllParts()
            .then(data => {
                this.setState({
                    // data: data,
                    data: data && data.length > 0 ? data.filter(x => x.deleted !== true) : [],
                    isLoaded: true,
                }, () => this.updateColumnMap());
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    isLoaded: true,
                })
            })
    }

    getVendors() {
        VendorService.getAllVendors()
            .then((res) => {
                this.setState({
                    vendorList: res
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getVendorList() {
        let vendors = this.state.vendorList;
        let a = [];
        vendors.map((v) => {
            return a.push({
                label: v.name,
                value: v.id,
            });
        });
        return a;
    }

    updateColumnMap() {
        let vendors = this.getVendorList()

        let map = this.state.columnMap;
        map[1].options = vendors;
        this.setState({
            columnMap: map,
        })
    }

    createNewPart(obj) {
        partsService.createPart(obj)
            .then(res => {
                if (res) {
                    toast.success("Part Created!");
                    this.setState({
                        isLoaded: false,
                    })
                    this.getParts();
                }
            })
            .catch(err => {
                console.log(err);
                toast.error("An error occurred creating the new entry: " + err);
            })
    }

    editRows(rows) {
        partsService.batchEditParts(rows)
            .then(res => {
                if (res) {
                    toast.success("Part(s) saved");
                    this.setState({
                        isLoaded: false,
                    })
                    this.getParts();
                }
            })
            .catch(err => {
                console.log(err)
                toast.error("An error occurred updating the entries: " + err);
            })
    }
    clientCSV(columnMap, rows, filename) {
        let result,
            ctr,
            keys = [],
            headers = [],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data;

        data = rows || null;
        if (data == null || !data.length) {
            return null;
        }

        columnMap.forEach((col) => {
            keys.push(col.fieldName);
            headers.push(col.columnName);
        });

        let replace = ["hcpc", "hcpc2","itemId","description"];
        let bool=['stock'];

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if(bool.indexOf(key) > -1) {
                    result += !!item[key]
                }
                else if (replace.indexOf(key) > -1 && item[key] != null) {
                    result += item[key].replace(/,/g, " ");
                }
                else {
                    result += item[key];
                }
                ctr++;
            });
            result += lineDelimiter;
        });
        this.downloadBlob(result, filename);
    }

    //this actually prompts the download
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {
        return (
            <div className="mt-5">
                <MDBToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                {this.state.isLoaded === false ?
                    <div style={{ textAlign: "center", verticalAlign: "middle" }}><MDBSpinner /></div> :
                    <Card style={{ backgroundColor: "rgb(88,129,193)", maxWidth: "90vw", marginLeft: "5vw" }}>
                        <InlineTable
                            filterRoles={["EXECUTIVE_MANAGEMENT", "ADMIN", "PURCHASING"]}
                            tableHeader="Service Parts Catalog"
                            paging={true}
                            columnFieldMap={this.state.columnMap}
                            allowRowSearch={true}
                            batchSave={true}
                            inlineEdit={true}
                            rows={this.state.data}
                            vendorList={this.state.vendorList}
                            addItemMethod={this.createNewPart.bind(this)}
                            editRowsMethod={this.editRows.bind(this)}
                            modalHeader="Add Service Part"
                            />
                    <Button
                    className={"downloadCsvButton"}
                    floating
                    size="sm"
                    color={"primary"}
                    data-tip={"Download CSV"}
                    onClick={this.clientCSV.bind(
                    this,
                        this.state.columnMap,
                        this.state.data,
                    "Service-Parts-Catalog.csv"
                    )}
                    >
                    <MDBIcon icon="download" style={{fontSize: "2em"}}/>
                    </Button>
                    </Card>
                }
            </div>)
    }
}