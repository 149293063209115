import Crud from "../Crud";
import MUIYellowColor from "@mui/material/colors/yellow";

/**
 * Static helper functions related to TeamUsers.
 */
export default class TeamUser extends Crud {
    /**
     * @param {object} teamUser The teamUser object.
     * @returns The display name of the teamUser.
     */
    static getDisplayName(teamUser) {
        return teamUser.name;
    }

    /**
     * Determines if a teamUser matches a search query.
     *
     * @param {object} teamUser The teamUser to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the teamUser matches the query.
     */
    static match(teamUser, query) {
        return (
            teamUser.id === +query
        );
    }

    /**
     * @param {array} teamUsers The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(teamUsers) {
        return teamUsers.sort((a, b) => {
            return (a.id).localeCompare(b.id);
        });
    }

    /**
     * @returns {object} The color of this object.
     */
    static getBaseColor() {
        return MUIYellowColor;
    }

    /**
     * @returns {number} The base color shade of this object.
     */
    static getBaseColorShade() {
        return 800;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "CallSplit";
    }
}