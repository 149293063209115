import React from "react";
import DialogSearch from "../DialogSearch"
import TileIssueCategory from "../../Tile/TileIssueCategory";
import PropTypes from "prop-types";
import IssueCategory from "../../../Utility/Crud/IssueCategory";

export default class DialogSearchIssueCategory extends DialogSearch {

    static contextTypes = {
        issueCategoriesIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered issueCategory.
     */
    getSearchResults(query) {
        return IssueCategory.search(Object.values(this.context.issueCategoriesIndexed), query, true);
    }

    /**
     * @param {object} issueCategory The issueCategory object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileIssueCategory component for this issueCategory.
     */
    renderTile(issueCategory, onClick) {
        const handleClick = () => {
            onClick(issueCategory);
            this.close();
        }

        return (
            <TileIssueCategory
                key={issueCategory.id}
                issueCategory={issueCategory}
                onClick={handleClick}
            />
        );
    }
}
