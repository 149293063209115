import Admin from "../Admin";
import React from "react";
import PropTypes from "prop-types";
import CardAdminDepartmentGeneral from "../../../Component/Card/Admin/Department/CardAdminDepartmentGeneral";
import CardAdminDepartmentManagement from "../../../Component/Card/Admin/Department/CardAdminDepartmentManagement";
import CardAdminDepartmentTeams from "../../../Component/Card/Admin/Department/CardAdminDepartmentTeams";
import PageHeader from "../../../Component/PageHeader";
import MUIButton from "@mui/material/Button";
import MUIDeleteIcon from "@mui/icons-material/Delete"
import MUISaveButton from "@mui/icons-material/Save";
import MUILoadingButton from "@mui/lab/LoadingButton";
import Menu from "../../../Component/Menu";
import DialogConfirm from "../../../Component/Dialog/DialogConfirm";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import DepartmentService from "../../../Seating/Security/DepartmentService/departmentService";
import Validator from "../../../Utility/Validator";
import Department from "../../../Utility/Crud/Department";
import CardAdminDepartmentStyle from "../../../Component/Card/Admin/Department/CardAdminDepartmentStyle";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class AdminDepartmentCreateEdit extends Admin {
    static contextTypes = {
        departmentsIndexed: PropTypes.object,
        teamsIndexed: PropTypes.object,
        updateIndexed: PropTypes.func,
        deleteIndexed: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state.department = null;
        this.state.teams = null;
        this.state.isProcessing = false;
        this.state.isUpdate = !!props.match.params.id;
        this.state.confirmDialogOpen = false;
        this.state.validationIssues = {};
    }


    /**
     * Set the department in the state.
     */
    afterComponentDidMount() {
        const departmentId = this.props.match.params.id ? +this.props.match.params.id : null;

        if (departmentId !== null) {
            const department = this.context.departmentsIndexed[departmentId];
            this.setState({
                department: department,
                teams: Object.values(this.context.teamsIndexed).filter((team) => team.departmentId === department.id)
            });
        }
    }

    /**
     * Creates the department with the current properties in state.department.
     */
    async createDepartment() {
        this.setState({
            isProcessing: true,
        })

        return DepartmentService.createDepartment(this.state.department)
            .then(department => {
                this.context.updateIndexed("departmentsIndexed", department);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Department created successfully!", {variant: "success"})
            })
            .catch((err) => {
                // JZ. We need to test and make sure that Sentry still captures
                // errors when we catch them in the application.
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error creating a new Department.", {variant: "error"})
                this.setState({
                    isProcessing: false,
                })
            });
    }

    /**
     * Updates the department with the current properties in state.department.
     */
    async updateDepartment() {
        this.setState({
            isProcessing: true,
        });

        return DepartmentService.updateDepartment(this.state.department)
            .then(department => {
                this.context.updateIndexed("departmentsIndexed", department);
                this.props.history.goBack();
                NotistackEnqueueSnackbar("Department updated successfully!", {variant: "success"})
            })
            .catch(err => {
                console.log(err)
                NotistackEnqueueSnackbar("Oh no! There was an error updating the Department.", {variant: "error"})
                this.setState({
                    isProcessing: false,
                })
            });
    }

    async deleteDepartment() {
        this.setState({
            isProcessing: true,
        });

        return DepartmentService.deleteDepartment(this.state.department.id)
            .then(() => {
                this.context.deleteIndexed("departmentsIndexed", this.state.department.id);
                this.props.history.goBack()
                NotistackEnqueueSnackbar("Department deleted successfully!", {variant: "success"})
            })
            .catch((err) => {
                console.log(err);
                NotistackEnqueueSnackbar("Oh no! There was an error deleting the Department.", {variant: "error"})
                this.setState({
                    confirmDialogOpen: false,
                })
            });

    }

    /**
     * Render the content.
     */
    renderContent() {
        const handleChange = (department) => {
            this.setState({
                department: {
                    ...this.state.department,
                    ...department
                },
            });
        }

        return (
            <>
                <CardAdminDepartmentGeneral
                    department={this.state.department}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminDepartmentStyle
                    department={this.state.department}
                    onChange={handleChange.bind(this)}
                    validationIssues={this.state.validationIssues}
                />
                <CardAdminDepartmentManagement
                    department={this.state.department}
                    onChange={handleChange.bind(this)}
                />
                <CardAdminDepartmentTeams
                    teams={this.state.teams}
                    onChange={handleChange.bind(this)}
                    history={this.props.history}
                />
                <DialogConfirm
                    open={this.state.confirmDialogOpen}
                    onClose={() => this.setState({ confirmDialogOpen: false })}
                    onConfirm={() => this.deleteDepartment()}
                    text="Are you sure you want to delete this department? This cannot be undone."
                    header="Delete Department"
                    loading={this.state.isProcessing}
                />
            </>
        );
    }

    /**
    * Render the header.
    */
    renderHeader() {
        let title;
        let subtitle;
        const buttonText = this.state.isUpdate ? "Save" : "Create";

        if (!this.state.isUpdate) {
            title = 'Create Department';
            subtitle = null;
        } else {
            title = 'Edit Department';
            subtitle = (this.state.department && (Department.getDisplayName(this.state.department) + " • " + this.state.department.id));
        }

        const handleClickBack = () => {
            this.props.history.goBack()
        };

        const handleClickSave = () => {
            const validationIssues = Validator.validate(
                this.state.department,
                {
                    name: {
                        'required': true
                    },
                    colorId: {
                        'required': true,
                    },
                    ordinal: {
                        type: 'integer'
                    }
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                if (this.state.isUpdate) {
                    this.updateDepartment()
                } else {
                    this.createDepartment()
                }
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", {variant: "error"})
            }

        };

        const handleDelete = () => {
            this.setState({
                confirmDialogOpen: true,
            });
        }

        const buttons = [
            (<MUIButton onClick={handleClickBack}>Back</MUIButton>),
            (
                <MUILoadingButton
                    loading={this.state.isProcessing}
                    loadingPosition="start"
                    onClick={handleClickSave}
                    variant="contained"
                    startIcon={<MUISaveButton />}>
                    {buttonText}
                </MUILoadingButton>
            )
        ];

        if (this.state.isUpdate) {
            const menuItems = [
                (
                    <MUIMenuItem onClick={handleDelete}>
                        <MUIListItemIcon>
                            <MUIDeleteIcon fontSize="small" />
                        </MUIListItemIcon>
                        Delete
                    </MUIMenuItem>
                )
            ];
            buttons.push((<Menu menuItems={menuItems} />))
        }

        return (
            <PageHeader title={title} subtitle={subtitle} buttons={buttons} />
        );
    }
}
