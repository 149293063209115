import TermList from "../AdminTermList";

export default class AdminTermListInsuranceSubTypeGroup extends TermList {
    /**
     * @returns {string} The term type.
     */
    getType() {
        return 'insurance_sub_type_group';
    }
}
