import React from "react";
import PropTypes from "prop-types";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	Input,
	toast,
	CardHeader,
	MDBIcon,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	ToastContainer,
	MDBCol,
	MDBRow,
	MDBCard,
	MDBSelect,
} from "mdbreact";
import AddProdcutMatrixEntry from "./addProdcutMatrixEntry";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import EquipmentTrainingLinkService from "./EquipmentTrainingLinkService";
import TrainingLinkService from "./trainingLinkService";
import ProductSubTypeService from "../Security/ProductService/productSubTypeService";
import prodcutMatrixEntryService from "../Security/ProdcutMatrixEntryService/prodcutMatrixEntryService";


export default class editProductSubType extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
					width: 100,
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
					width: 200,
				},
				{
					label: "HCPCS",
					field: "hcpcs",
					sort: "asc",
					width: 100,
				},
				{
					label: "Item ID",
					field: "itemid",
					sort: "asc",
					width: 100,
				},
				{
					label: "Max Billable Units",
					field: "laborUnits",
					sort: "asc",
					width: 70,
				},{
					label: "Vendor",
					field: "vendor",
					sort: "asc",
					width: 100,
				},
				{
					label: "Override Points",
					field: "overridepoints",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "button",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		let productType = props.location.state.productType || {},
			id = props.match.params.id;

		if (id) {
			this.retrieveProducts(id);
		}

		this.state = {
			id: id,
			data: data,
			isLoaded: false,
			updatedProduct: {},
			productType: productType,
			modal: false,
			toggleWarning: false,
			errorMessage: "",
			currIndex: -1,
			allVendors: [],
			vendorOptions: [],
			maxBillableLaborUnitsOptions: [{label: "0 Labor Units", value: 0},{label: "1 Labor Units", value: 1},
				{label: "2 Labor Units", value: 2},{label: "3 Labor Units", value: 3},{label: "4 Labor Units", value: 4}],
			currentEquipmentVideos: [],
			videosLoaded: false,
			selectedVendor: null,
			selectedLaborUnits: null,
			overrideModal: false,
			allVideos: [],
			vidsList: [],
			fullVideosLoaded: false,
			chosenVideo: {},
			insuranceOptions: [],
			insuranceTypes: [],
			selectedInsurance: null,
			editPointsId: null,
			productMatrixEntrySubTypes: [],
			nProduct: {},
		};

		this.getAllVideos();
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allVendors: PropTypes.array,
		insuranceTypes: PropTypes.array,
	};

	componentDidMount() {
		this.getVendorsOptions();
		this.getInsuranceOptions();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { allVendors, insuranceTypes } = this.context;
		if (
			allVendors.length > 0 &&
			allVendors.length !== prevState.allVendors.length
		) {
			this.getVendorsOptions();
		}
		if (
			insuranceTypes.length > 0 &&
			insuranceTypes.length !== prevState.insuranceTypes.length
		) {
			this.getInsuranceOptions();
		}

	}


	getInsuranceOptions() {
		const { insuranceTypes } = this.context;
		let arr = [];
		insuranceTypes.map((v) => {
			return arr.push({
				label: v.name,
				value: v.id,
			});
		});
		this.setState({
			insuranceTypes: insuranceTypes,
			insuranceOptions: arr,
		});
	}

	getVendorsOptions() {
		const { allVendors } = this.context;
		let arr = [];
		allVendors.map((v) => {
			return arr.push({
				label: v.name,
				value: v.id,
				val: { id: v.id, name: v.name },
			});
		});
		this.setState({
			allVendors: allVendors,
			vendorOptions: arr,
		});
	}

	getAllProductMatrixEntrySubTypes(id) {
		prodcutMatrixEntryService.getProductMatrixEntryBySubType(id)
			.then((entries) => {
				// console.log(entries)
				this.setState({
					productMatrixEntrySubTypes: entries,
				})
			})
			.catch((err) => {
				console.log(err)
			})
	}

	createProductMatrixEntry(p) {
		prodcutMatrixEntryService.createProduct(p)
			.then((res) => {
				// console.log(res)
			})
			.catch((err) => {
				console.log(err)
				toast.warn("An error occured adding a new product");
			});
	}

	renderCardHeader() {
		return (
			<CardHeader color={"blue-grey lighten-2"} style={{ textAlign: "center" }}>
				<Button
					style={{ float: "left" }}
					floating
					size="sm"
					color={"warning"}
					data-tip={"Go Back"}
					onClick={() => this.props.history.push("/products")}
				>
					<MDBIcon icon="backward" style={{ fontSize: "2em" }} />
				</Button>

				{this.state.productType ? this.state.productType.name : " Products"}

				<Button
					style={{ float: "right" }}
					floating
					size="sm"
					color={"success"}
					data-tip={"Add New Product"}
					onClick={() => this.addButtonClick()}
				>
					<MDBIcon icon="plus" style={{ fontSize: "2em" }} />
				</Button>

				<Button
					style={{ float: "right" }}
					floating
					size="sm"
					color={"secondary"}
					data-tip={"View Point Overrides"}
					onClick={() =>
						this.props.history.push({
							pathname: "/productMatrixEntry",
							state: {
								id: this.state.productType.id,
								product: this.state.productType,
							},
						})
					}
				>
					<MDBIcon icon="external-link-alt" />
				</Button>
			</CardHeader>
		);
	}

	retrieveProducts(id) {
		let t = this;

		return ProductSubTypeService.getOneProductSubType(id)
			.then((res) => {
				let arr = [],
					data = this.state.data;
				res.forEach((element,index) => {
					arr.push({
						id: element.id,
						name: element.name,
						hcpcs: element.hcpcs,
						itemid: element.itemId || "",
						laborUnits:  element.maxBillableLaborUnits,
						vendor: element.vendor ? element.vendor.name : "",
						overridepoints: element.overridePoints ? "true" : "",
						edit: t.editButton(element, index),
					});
				});
				data.rows = arr;
				this.setState({ 
					data: data,
					isLoaded: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	getAllVideos() {
		TrainingLinkService.getAllTrainingLinks()
			.then((r) => {
				let obj = [];

				r.forEach((v) => {
					obj.push({ text: v.name + ":   " + v.description, value: v });
				});

				this.setState({
					allVideos: r,
					vidsList: obj,
					fullVideosLoaded: true,
				});
			});
	}

	getProductVideos(id) {
		EquipmentTrainingLinkService.getVideosByCategory(id)
			.then((r) => {
				this.setState({
					currentEquipmentVideos: r,
					videosLoaded: true,
				});
			});
	}

	addVideo() {
		let video = this.state.chosenVideo,
			prod = this.state.updatedProduct,
			v = this.state.currentEquipmentVideos;

		if (!video.id) {
			return;
		}

		if (
			v.findIndex((vals) => {
				return vals.trainingLinkId === video.id;
			}) > -1
		) {
			toast.warning("Video already exists for this product.");
			return;
		}

		this.setState({
			videosLoaded: false,
		});

		let vo = {
			name: video.name,
			productSubTypeId: prod.id,
			trainingLinkId: video.id,
			productCategoryId: -1,
		};

		EquipmentTrainingLinkService.createTrainingLink(vo)
			.then((r) => {
				this.setState({
					// currentEquipmentVideos: v,
					chosenVideo: {},
				});
				this.getProductVideos(this.state.updatedProduct.id);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally((a) => {
				this.setState({
					videosLoaded: true,
				});
			});
	}

	editButton(product, index) {
		return (
			<MDBIcon
				style={{ color: "green" }}
				icon="edit"
				aria-hidden="true"
				onClick={() => {
					this.editButtonClick(product, index);
				}}
			/>
		);
	}

	renderRemoveButton(id) {
		return (
			<MDBIcon
				style={{ color: "red" }}
				icon="trash"
				aria-hidden="true"
				onClick={() => this.handleRemoveClick(id)}
			/>
		);
	}

	editButtonClick(product, index) {
		let p = JSON.parse(JSON.stringify(product));
		this.setState({
			updatedProduct: p,
			currIndex: index,
		});
		this.toggleEdit();
		this.getProductVideos(product.id);
		this.getAllProductMatrixEntrySubTypes(product.id);
	}

	toggleEdit = () => {
		this.setState({
			modal: !this.state.modal,
			currentEquipmentVideos: [],
			videosLoaded: false,
			errorMessage: "",
			selectedVendor: null,
			selectedLaborUnits: null,
			chosenVideo: {},
		});
	};

	toggleWarningModal = () => {
		this.setState({
			toggleWarning: !this.state.toggleWarning,
			modal: !this.state.modal,
		})
	}

	handlePointsChange = (i, e) => {
		let prod = this.state.productMatrixEntrySubTypes;

		if (prod && prod.length > 0 && prod.filter(x => x.insuranceTypeId === i.id).length > 0) {
			prod.forEach(it => {
				if (it.insuranceTypeId === i.id) {
					it.points = e.target.value ? parseFloat(e.target.value) : null;
                }
            })
		}
		else {
			prod.push({
				deleted: false,
				points: e.target.value ? parseFloat(e.target.value) : null,
				insuranceTypeId: i.id,
				productSubTypeId: this.state.updatedProduct.id,
            })
		}

		this.setState({
			productMatrixEntrySubTypes: prod,
        })

	};

	updateProductMatrixEntries(prod) {
		prodcutMatrixEntryService.editProduct(prod)
			.then((res) => {
				// console.log(res)
			})
			.catch((err) => {
				console.log(err)
			})
	}


	removePoints(id) {
		prodcutMatrixEntryService.deleteProduct(id)
			.then((res) => {
				// console.log(id,res)
			})
			.catch((err) => {
				console.log(err)
				toast.error("There was an issue removing override points.")
			})
	}


	savePointsChange(item) {
		if (!item.id) {
			this.createProductMatrixEntry(item)
		}
		else {
			this.updateProductMatrixEntries(item);
		}
	}

	renderWarning() {
		return (
			<Container>
				<Modal size={ "med" } isOpen={ this.state.toggleWarning } toggle={ this.toggleWarningModal }>
					<ModalHeader style={ { backgroundColor: "#F93154", color: "white" } }>
						Warning
					</ModalHeader>
					<ModalBody style={ { backgroundColor: "white" } }>
						<Container>
							<MDBRow>
								<p>All override points will be deleted. Are you sure you want to continue?</p>
							</MDBRow>
						</Container>
					</ModalBody>
					<ModalFooter style={ { backgroundColor: "white" } }>
						<Button
							size={ "sm" }
							data-tip={ "Cancel" }
							color="red"
							onClick={ this.toggleWarningModal }
						>
							<MDBIcon icon="times" style={ { fontSize: "2em" } } />
						</Button>
						<Button
							size={ "sm" }
							data-tip={ "Save" }
							onClick={ this.editSaveButtonClick }
							color="success"
						>
							<MDBIcon far icon="save" style={ { fontSize: "2em" } } />
						</Button>
					</ModalFooter>
					<ReactTooltip />
				</Modal>
			</Container>
		)
	}

	renderModal() {
		if (this.state.currIndex > -1) {
			let st = this.state,
				up = st.updatedProduct,
				entries = [],
				insurance = st.insuranceTypes;

			if (st.productMatrixEntrySubTypes !== null && st.productMatrixEntrySubTypes.length > 0) {
				entries = this.state.productMatrixEntrySubTypes.filter((e) => e.productSubTypeId === up.id)
			}

			return (
				<Container>
					<Modal size={"lg"} isOpen={this.state.modal} toggle={this.toggleEdit}>
						<ModalHeader style={{ backgroundColor: "white" }}>
							Edit Product
						</ModalHeader>
						<ModalBody style={{ backgroundColor: "white" }}>
							<Container>
								<MDBRow>
									<MDBCol size="12">
										<p style={{ color: "red" }}>{this.state.errorMessage}</p>
									</MDBCol>
									<MDBCol size="12">
										<Input
											containerClass={"smallMargin"}
											outline
											value={up.name || ""}
											onChange={this.handleChange.bind(this, "name")}
											label="Name"
											size="sm"
										/>
									</MDBCol>
									<MDBCol size="6">
										<Input
											containerClass={"smallMargin"}
											outline
											value={up.hcpcs || ""}
											onChange={this.handleChange.bind(this, "hcpcs")}
											label="HCPCS"
											size="sm"
										/>
									</MDBCol>
									<MDBCol size="6">
										<Input
											containerClass={"smallMargin"}
											outline
											value={up.itemId || ""}
											onChange={this.handleChange.bind(this, "itemId")}
											label="Item Id"
											size="sm"
										/>
									</MDBCol>
									<MDBCol size="5" style={ { zIndex: "99" } } >
										<Select
											placeholder={up.vendor ? up.vendor.name : "Select Vendor"}
											options={st.vendorOptions}
											onChange={this.handleVendorChange.bind(this)}
											value={st.selectedVendor}
										/>
									</MDBCol>
									<MDBCol size="3" style={ { zIndex: "99" } } >
										<Select
											placeholder={up.maxBillableLaborUnits ? up.maxBillableLaborUnits : "Labor Units"}
											options={st.maxBillableLaborUnitsOptions}
											onChange={this.handleLaborChange.bind(this)}
											value={st.selectedLaborUnits}
										/>
									</MDBCol>
									<MDBCol size="4" style={{ paddingTop: 8 }}>
										<Input
											value={up.overridePoints}
											id="overridepnts"
											checked={up.overridePoints}
											type={"checkbox"}
											onChange={this.handleChange.bind(this, "overridePoints")}
											label="Override Points"
											size="sm"
										/>
									</MDBCol>
								</MDBRow>
								<MDBRow>
									{ insurance.map((i, index) => {
										return (
											<MDBCol size="4" style={ { marginTop: "20px" } } >
												<Input
													key={ index }
													type="number"
													disabled={!up.overridePoints ? true : false} 
													label={ i.name }
													value={ entries && entries.length > 0 ? entries.filter((e) => e.insuranceTypeId === i.id).map((x) => x.points) : null }
													onChange={ this.handlePointsChange.bind(this, i) }
												/>
											</MDBCol>
										)
									}) }
								</MDBRow>
								<hr style={{ paddingTop: 10 }} />
								<MDBRow>
									<MDBCol size="10">
										<MDBSelect
											outline
											color="default"
											style={{ maxHeight: "100px", marginTop: 1 }}
											options={this.state.vidsList}
											label={"Choose a Video to Add"}
											getValue={this.handleVideoChange.bind(this)}
										/>
									</MDBCol>
									<MDBCol size="2">
										<MDBIcon
											data-tip={"Add Video"}
											size={"2x"}
											icon={"plus"}
											style={{ color: "green" }}
											onClick={() => this.addVideo()}
										/>
									</MDBCol>
								</MDBRow>
								<MDBRow>{ this.renderProductVideos() }</MDBRow>
							</Container>
						</ModalBody>
						<ModalFooter style={{ backgroundColor: "white" }}>
							{/*<Button*/}
							{/*	size={ "sm" }*/}
							{/*	color="primary"*/}
							{/*	data-tip={ "Add Product Override" }*/}
							{/*	onClick={ this.handleOverrideClick }*/}
							{/*	disabled={ !up.overridePoints ? true : false }*/}
							{/*>*/}
							{/*	<MDBIcon icon="cart-plus" style={ { fontSize: "2em" } } />*/}
							{/*</Button> */}
							<Button
								size={"sm"}
								data-tip={"Close"}
								color="red"
								onClick={this.toggleEdit}
							>
								<MDBIcon icon="times" style={{ fontSize: "2em" }} />
							</Button>
							<Button
								size={"sm"}
								data-tip={"Save"}
								onClick={ entries !== null && entries.length > 0 && up.overridePoints === false ? this.toggleWarningModal : this.editSaveButtonClick }
								color="success"
							>
								<MDBIcon far icon="save" style={{ fontSize: "2em" }} />
							</Button>
						</ModalFooter>
						<ReactTooltip />
					</Modal>
				</Container>
			);
		}
	}

	removeVideoFromEquipment(v, idx) {
		this.setState({
			videosLoaded: false,
		});

		EquipmentTrainingLinkService.deleteTrainingLink(v.id)
			.then((r) => {
				this.getProductVideos(this.state.updatedProduct.id);
			})
			.catch((e) => {
				toast.warning("There was an error.  Try again later.");
			})
			.finally((a) => {
				this.setState({
					videosLoaded: true,
				});
			});
	}

	renderProductVideos() {
		let t = this;
		let video = t.state.chosenVideo;

		function deleteButton(v, idx) {
			return (
				<MDBIcon
					style={{ color: "red" }}
					icon={"trash"}
					onClick={() => t.removeVideoFromEquipment(v, idx)}
				/>
			);
		}

		if (!video && !this.state.videosLoaded) {
			return <Spinner small />;
		}
		return this.state.currentEquipmentVideos.map((v, idx) => {
			return (
				<MDBCol size="6">
					<MDBCard key={idx} style={{ margin: 6, padding: 4 }}>
						<MDBRow>
							<MDBCol size="9">{v.name}</MDBCol>

							<MDBCol size="3">{deleteButton(v, idx)}</MDBCol>
						</MDBRow>
					</MDBCard>
				</MDBCol>
			);
		});
	}

	handleVideoChange(video) {
		this.setState({ chosenVideo: video[0] });
	}

	handleOverrideClick = () => {
		this.setState((state) => ({
			productSubType: {
				name: state.updatedProduct.name,
				id: state.updatedProduct.id,
			},
		}));
		this.toggleEdit();
		this.toggleOverrideModal();
	};

	overrideModal() {
		let st = this.state,
			entries = st.productMatrixEntrySubTypes;

		return (
			<Modal
				isOpen={this.state.overrideModal}
				toggle={() => {
					return;
				}}
			>
				<AddProdcutMatrixEntry
					closeButton={this.toggleOverrideModal.bind(this)}
					productSubType={this.state.productSubType}
					getAllProductMatrixEntrySubTypes={() => this.getAllProductMatrixEntrySubTypes()}
					entries={ entries }
					editSaveButtonClick={ () => this.editSaveButtonClick() }
				/>
			</Modal>
		);
	}

	toggleOverrideModal = () => {
		this.setState({
			overrideModal: !this.state.overrideModal,
		});
	};

	handleVendorChange = (e) => {
		this.setState({
			selectedVendor: e,
		});
	};
	handleLaborChange = (e) => {
		this.setState({
			selectedLaborUnits: e,
		});
	};

	handleChange(property, e) {
		let up = this.state.updatedProduct;
		if (property === "overridePoints") {
			up[property] = !up[property];
		} else {
			up[property] = e.target.value;
		}
		this.setState({
			updatedProduct: up,
		});
	}

	addButtonClick() {
		return this.props.history.push({
			pathname: "/productType/new",
			state: { productType: this.state.productType, productId: this.state.id },
		});
	}

	validate() {
		let up = this.state.updatedProduct;
		if (up.name === "") {
			this.setError("Please enter Product Name");
			return false;
		} else if (up.hcpcs === "") {
			this.setError("Please enter HCPCS");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	editSaveButtonClick = () => {
		if (this.validate()) {
			let t = this,
				st = t.state,
				cp = st.updatedProduct,
				d = st.data,
				i = st.currIndex,
				v = st.selectedVendor,
				lh = st.selectedLaborUnits,
				entries = st.productMatrixEntrySubTypes;

			if (v) {
				cp.vendor = v.val;
			}
			if (lh) {
				cp.maxBillableLaborUnits = lh.value;
			}


			return ProductSubTypeService.updateProductSubType(cp)
				.then(() => {
					toast.success("Saved Successfully!");
					d.rows[i].id = cp.id;
					d.rows[i].name = cp.name;
					d.rows[i].hcpcs = cp.hcpcs;
					d.rows[i].itemid = cp.itemId || "";
					d.rows[i].laborUnits = cp.maxBillableLaborUnits ? cp.maxBillableLaborUnits : null;
					d.rows[i].vendor = cp.vendor ? cp.vendor.name : "";
					d.rows[i].overridepoints = cp.overridePoints ? "true" : "";
					d.rows[i].edit = t.editButton(cp, i);
					if (cp.overridePoints === false && entries !== null && entries.length > 0) {
						entries.forEach((e) => {
							if(e.id)
								this.removePoints(e.id)
						})
					}
					else if (entries !== null && entries.length > 0) {
						entries.forEach((e) => this.savePointsChange(e))

						this.getAllProductMatrixEntrySubTypes(cp.id);
					}
					t.setState({
						data: d,
						modal: false,
						toggleWarning: false,
						errorMessage: "",
						productSubType: { name: cp.name, id: cp.id },
						productMatrixEntrySubTypes: [],
					});
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div></div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}

		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Container className="mt-5">
					<Card>
						{this.renderCardHeader()}
						<CardBody>{this.renderTable()}</CardBody>
						<ReactTooltip />
					</Card>
					{this.overrideModal()}
					{this.renderModal()}
					{ this.renderWarning() }
				</Container>
			</div>
		);
	}
}
