import React from "react";
import Card from "../../../Card";
import PropTypes from "prop-types";
import MUIAlert from '@mui/material/Alert';
import Queue from "../../../../Utility/Crud/Queue";
import OrderStatusReason from "../../../../Utility/Crud/OrderStatusReason";
import EmptyState from "../../../EmptyState";
import TileQueue from "../../../Tile/TileQueue";
import MUIGrid from "@mui/material/Grid";

export default class CardAdminQueueRouteQueue extends Card {
    static contextTypes = {
        queuesIndexed: PropTypes.object,
        teamQueuesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        if (this.props.queueRoute && this.props.queueRoute.toOrderStatusReasonId) {
            const queues = Queue.getMatchingQueuesForQueueRoute(
                Object.values(this.context.queuesIndexed),
                Object.values(this.context.teamQueuesIndexed),
                this.props.queueRoute
            );

            if (queues.length === 0) {
                return (
                    <MUIAlert severity="warning">
                        {`This route potentially has no queues associated with it. Please ensure that an Order Activity queue exists, is assigned to a team, has an Order Status Reason filter containing ${OrderStatusReason.getDisplayName(this.context.orderStatusReasonsIndexed[this.props.queueRoute.toOrderStatusReasonId])}, and has no other filters.`}
                    </MUIAlert>
                );
            } else {
                return (
                    <>
                        <MUIGrid container spacing={2} sx={{ "paddingBottom": 2 }}>
                            {queues.map((queue) => {
                                return (
                                    <MUIGrid key={queue.id} item xs={12} sm={6} md={4}>
                                        <TileQueue
                                            queue={this.context.queuesIndexed[queue.id]}
                                        />
                                    </MUIGrid>
                                );
                            })}
                        </MUIGrid>
                        <MUIAlert severity="info">
                            {`This is a dynamically linked queue for ${OrderStatusReason.getDisplayName(this.context.orderStatusReasonsIndexed[this.props.queueRoute.toOrderStatusReasonId])}. It cannot be manually adjusted.`}
                        </MUIAlert>
                    </>
                );
            }
        } else {
            return (
                <EmptyState
                    line1="Missing Order Status Reason"
                    line2="Add a To Order Status Reason to find a linked queue"
                />
            );
        }
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Queue";
    }
}