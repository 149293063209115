import React from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Spinner,
    CardHeader,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBContainer,
    MDBRow,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
    toast,
} from "mdbreact";
import {ToastContainer} from "react-toastify";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import NotificationTypesService from "../Security/NotificationService/NotificationTypesService";

export default class notificationTypes extends React.Component {
    constructor(props) {
        super(props);

        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Edit",
                    field: "edit",
                    sort: "asc",
                },
                {
                    label: "Delete",
                    field: "delete",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        this.state = {
            data: data,
            isLoaded: false,
            editModal: false,
            confirmationModal: false,
            name: "",
            currIndex: -1,
            errorMessage: "",
            currNotification: {},
            newNotification: {
                name: "",
            },
            addNewModal: false
        };
    }

	static contextTypes = {
		currentUser: PropTypes.object,
	};

    // Complaining that component wasn't mounted so I couldn't set States therefore had to use this
    componentDidMount() {
        this.retrieveNotificationType();
    }

    createNewNotification= () => {
        //promise
        let i = this.state.newNotification;

        if (i.name === "") {
            this.setState({errorMessage: "Please Enter A Notification"});
            return;
        }

        NotificationTypesService.createNotificationType(i)
            .then(r => {
                this.setState({
                    errorMessage: "",
                    newNotification:{
                        name: ""
                    }
                })
                toast.success("Notification Type Added!");
                this.retrieveNotificationType();
                this.closeModal();
            }).catch((e) => {
            toast.warn("There was an issue creating the Notification Type")
        })

    }

    closeModal = () => {

        this.setState({
            addNewModal: false,
            newNotification: {},
            errorMessage: "",
        })
    }

    retrieveNotificationType() {
        let t = this;
        return NotificationTypesService.getAllNotificationTypes()
            .then((res) => {
                let arr = [],
                    d = this.state.data;
                res.forEach((type, index) => {
                    arr.push({
                        id: type.id,
                        name: type.name,
                        edit: t.renderEditButton(type, index),
                        remove: t.renderRemoveButton(type),
                    });
                });


                arr.sort((a,b) => {
                    return a.id > b.id ? 1 : -1;
                });
                d.rows = arr;

                t.setState({
                    data: d,
                    isLoaded: true,
                });
            })
            .catch((err) => {
                //handle error...BC
            });
    }

    renderEditButton(type, index) {
        return (
                <MDBIcon icon="edit"
                         onClick={() => this.handleEditClick(type, index)}
                         style={{color: "#7ac5ff"}}
                />
        );
    }

    renderRemoveButton(id) {
        return (
                <MDBIcon icon="trash"
                         onClick={() => this.handleRemoveClick(id)}
                         style={{color: "#db0404"}}
                />
        );
    }

    renderAddNewModal() {
        return (
            <Modal isOpen={this.state.addNewModal} toggle={this.closeModal}>
                <ModalHeader
                    style={{ backgroundColor: "#5881C1", color: "white" }}
                    toggle={this.closeModal}
                >
                    Add New Notification
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    {this.renderAddNewModalBody()}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Add New Notification"}
                        onClick={this.createNewNotification}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.closeModal}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderAddNewModalBody() {
        if (this.state.addNewModal) {
            return (
                <div>
                    <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                    <Input
                        outline
                        label="Notification Name/Description"
                        onChange={this.handleAddNewChange.bind(this)}
                    />
                </div>
            );
        }
    }

    renderConfirmationModal() {
        return (
            <Modal isOpen={this.state.confirmationModal} toggle={this.confirmationToggle}>
                <ModalHeader
                    style={{ backgroundColor: "#F93154", color: "white" }}
                    toggle={this.confirmationToggle}
                >
                    Confirm Delete
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    {this.renderConfirmationModalBody()}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Confirm Delete"}
                        onClick={() => this.handleConfirmationDelete(this.state.currNotification.id)}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.handleConfirmationClose}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderConfirmationModalBody() {
        return (
            <div>
                <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                <MDBContainer>
                    <MDBRow>
                        Are you sure you want to delete {this.state.currNotification.name}?
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }

    confirmationToggle=()=> {
        this.setState({
            confirmationModal: !this.state.confirmationModal,
            errorMessage: "",
        })
    }

    handleConfirmationClose= () => {
        let i = {
            id: -1,
            name: "",
        };
        this.setState({
            currNotification: i,
            currIndex: -1,
            errorMessage: "",
            confirmationModal: !this.state.confirmationModal,
        });
    }

    handleAddNewChange(e){
        let i = this.state.newNotification;
        i.name = e.target.value;
        this.setState({
            newNotification: i,
            }

        )
    }
    handleConfirmationDelete(id) {
        NotificationTypesService.deleteNotificationType(id)
            .then((res) => {
                this.retrieveNotificationType();
                toast.success("Notification Type Removed");
                this.handleConfirmationClose();
            })
            .catch((err) => {
                toast.warn("Error occurred while removing Notification Type");
            });
    }

    handleRemoveClick(type){
        let i = {
            id: type.id,
            name: type.name,
        };
        this.setState({
            confirmationModal: !this.state.confirmationModal,
            currNotification: i,
        });

    }

    handleEditClick(type, index) {
        let a = {
            id: type.id,
            name: type.name,
        };
        this.setState({
            currNotification: a,
            editModal: !this.state.editModal,
            currIndex: index,
        });
    }

    editToggle = () => {
        this.setState({
            editModal: !this.state.editModal,
            errorMessage: "",
        });
    };

    renderEditModal() {
        return (
            <Modal isOpen={this.state.editModal} toggle={this.editToggle}>
                <ModalHeader
                    style={{backgroundColor: "#5881C1", color: "white"}}
                    toggle={this.editToggle}
                >
                    Edit Notification Type
                </ModalHeader>
                <ModalBody style={{backgroundColor: "white"}}>
                    <div>
                        <p style={{color: "red"}}>{this.state.errorMessage}</p>
                        <Input
                            outline
                            label="Name"
                            value={this.state.currNotification.name}
                            onChange={this.handleEditChange.bind(this)}
                        />
                    </div>
                </ModalBody>
                <ModalFooter style={{backgroundColor: "white"}}>
                    <Button
                        style={{float: "right"}}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Update Notification Type"}
                        onClick={this.handleEditSave}
                    >
                        <MDBIcon icon="check" style={{fontSize: "2em"}}/>
                    </Button>

                    <Button
                        style={{float: "right"}}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.handleEditClose}
                    >
                        <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                    </Button>
                </ModalFooter>
                <ReactTooltip/>
            </Modal>
        );
    }

    handleEditSave = () => {
        if (this.validateEdit()) {
            let d = this.state.data,
                i = this.state.currIndex,
                t = this;
            return NotificationTypesService.updateNotificationType(this.state.currNotification)
                .then((type) => {
                    d.rows[i].name = type.name;
                    d.rows[i].edit = t.renderEditButton(type, i);
                    this.setState({
                        data: d,
                        editModal: !this.state.editModal,
                    });
                    toast.success("Notification Type Edited!");
                })
                .catch((err) => {
                    toast.warn("An error occurred while saving.");
                });
        }
    };

    validateEdit() {
        let a = this.state.currNotification;
        if (a.name === "") {
            this.setError("Please add a Notification Name");
            return false;
        }
        return true;
    }

    setError(e) {
        this.setState({
            errorMessage: e,
        });
    }

    handleEditClose = () => {
        this.setState({
            currIndex: -1,
            errorMessage: "",
            editModal: !this.state.editModal,
            currNotification: {},
        });
    };

    handleEditChange(e) {
        let a = this.state.currNotification;
        a.name = e.target.value;
        this.setState({
            currNotification: a,
        });
    }

    renderTable() {
        if (this.state.isLoaded === true) {
            return (
                <MDBTable
                    small
                    striped
                    style={{textAlign: "center", fontSize: "16px"}}
                >
                    <MDBTableHead columns={this.state.data.columns}/>
                    <MDBTableBody rows={this.state.data.rows}/>
                </MDBTable>
            );
        } else {
            return <div></div>;
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    addNewClick = () => {
        this.setState({
            addNewModal: true
        })
    };

    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <Container className="mt-5">
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                {this.renderAddNewModal()}
                {this.renderConfirmationModal()}
                <Card>
                    <CardHeader
                        style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30}}
                    >
                        Notification Types
                        <Button
                            style={{float: "right"}}
                            floating
                            size="sm"
                            color={"secondary"}
                            data-tip={"Add New Notification Type"}
                            onClick={this.addNewClick}
                        >
                            <MDBIcon icon="address-card" style={{fontSize: "2em"}}/>
                        </Button>
                    </CardHeader>
                    <CardBody>{this.renderTable()}</CardBody>
                </Card>
                {this.renderEditModal()}
                <ReactTooltip/>
            </Container>
        );
    }
}
