import React from "react";
import {
	Button,
	Card,
	CardBody,
	Col,
	Fa,
	Row,
	MDBSelect,
	MDBIcon,
	toast,
	MDBInput,
	MDBRow,
	MDBCol,
} from "mdbreact";
import Moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "./filterStyle.css";
import UserService from "../Security/UserService/userService";
import PropTypes from "prop-types";
import Select from "react-select";
import QuickFilter from "./quickFilter";
import ReactTooltip from "react-tooltip";

export default class UserFilters extends React.Component {
	constructor(props) {
		super(props);

		this.newSearchFunction = props.newSearchFunction;

		this.theme = props.theme;

		let f = props.userFilters,
			otherUsers = f.user ? f.user.slice(0) : [],
			startDate = f.startDate ? new Date(f.startDate) : Moment().subtract(11, "months").toDate(),
			endDate = f.endDate ? new Date(f.endDate) : new Date(),
			filterOptions = QuickFilter.getOptions(),
			filterSelected = null,
			selectedLocations = [],
			statuses = [],
			reasons = [],
			users = [];
 
		let statusOptions = [
			{ label: "New", value: "0" },
			{ label: "In Process", value: "1" },
			{ label: "Ready to Deliver", value: "2" },
			{ label: "Setup", value: "3" },
			{ label: "Cancelled", value: "4" },
		];

		if(f.status && f.status.length > 0){
			statuses = f.status.map(x => x.statusValue);
		}
		if(f.statuses && f.statuses.length > 0){
			statuses = f.statuses;
		}

		if (f.userprofileLocations && f.userprofileLocations.length > 0 && props.shouldOverride) {
			selectedLocations = f.userprofileLocations.map((x) => x.locationsId)
		}
		
		if (f.userpreferenceInternalLocation && f.userpreferenceInternalLocation.length > 0 && !props.shouldOverride){
			selectedLocations = f.userpreferenceInternalLocation.map(x => x.internalLocationId);
		}
		if (f.userpreferenceReason && f.userpreferenceReason.length > 0 && !props.shouldOverride){
			reasons = f.userpreferenceReason.map(x => x.reasonId);
		}
		else if(f.reasonIds){
			reasons = f.reasonIds;
		}
		
		if (f.userpreferenceUser && f.userpreferenceUser.length > 0 && !props.shouldOverride){
			users = f.userpreferenceUser.map(x => x.userId);
		}

		let eqIds = [];
		if (f.userpreferenceEquipment && f.userpreferenceEquipment.length > 0 && !props.shouldOverride) {
			eqIds = f.userpreferenceEquipment.map(x => x.equipmentId);
		}

		let itIds = [];
		let istIds = [];
		if (f.userpreferenceInsurance && f.userpreferenceInsurance.length > 0 && !props.shouldOverride) {
			itIds = f.userpreferenceInsurance.map(x => x.payors && x.payors.length > 0 ? x.payors.split(',') : []);
			istIds = f.userpreferenceInsurance.map(x => x.insurance && x.insurance.length > 0 ? x.insurance.split(',') : []);
		}

		if (f.userpreferenceInternalLocation && f.userpreferenceInternalLocation.length > 0 && !props.shouldOverride) {
			selectedLocations = f.userpreferenceInternalLocation.map(x => x.internalLocationId);
		}

		//let rIds = [];
		//if (f.userpreferenceReason && f.userpreferenceReason.length > 0 && !props.shouldOverride) {
		//	rIds = f.userpreferenceReason.map(x => x.reasonId);
		//}

		if (f.userpreferenceStatus && f.userpreferenceStatus.length > 0 && !props.shouldOverride) {
			statuses = f.userpreferenceStatus.map(x => x.statusValue);
		}

		//check for null and empty string because 0 could be a value...BC
		if (f.dateFilterType != null && f.dateFilterType !== "") {
			//9 is custom
			if (f.dateFilterType === 9) {
				if (f.startDate) {
					startDate = new Date(f.startDate);
				}

				if (f.endDate) {
					endDate = new Date(f.endDate);
				}
			} else {
				let a = QuickFilter.getDates(f.dateFilterType);

				startDate = a[0];
				endDate = a[1];
			}
		}

		if (f.dateFilterType != null) {
			let v = filterOptions.find((o) => {
				return o.value === f.dateFilterType;
			});

			if (v) {
				filterSelected = v;
			}
		}

		this.state = {
			userFilters: f,
			startDate: startDate,
			endDate: endDate,
			dateFilterType: f.dateFilterType || 0,
			searchText: f.q || "",
			equipmentIds: f.equipment && f.equipment.length > 0 ? f.equipment.map(x => x.equipmentId) : eqIds,
			insuranceIds: f.insurance && f.insurance.length > 0 && f.insurance[0].payors ? f.insurance[0].payors.split(',').map(x => parseInt(x)) : itIds,
			insuranceSelected: f.insurance && f.insurance.length > 0 && f.insurance[0].payors ? f.insurance[0].payors.split(',').map(x => parseInt(x)) : itIds,
			insuranceSubTypeIds: f.insurance && f.insurance.length > 0 && f.insurance[0].insurance ? f.insurance[0].insurance.split(',').map(x => parseInt(x)) : istIds,
			insuranceSubTypeSelected: f.insurance && f.insurance.length > 0 && f.insurance[0].insurance ? f.insurance[0].insurance.split(',').map(x => parseInt(x)) : istIds,
			statuses: statuses || [],
			selectedStatuses: statuses || [],
			reasonIds: reasons || [],
			selectedReasonIds: reasons || [],
			locationIds: selectedLocations || [],
            selectedLocations: selectedLocations || [],
			clinicLocations: f.seatingClinics || [],
            users: users,
			groupingColumns: props.groupingColumns || f.groupColumns,
			defaultHiddenColumns: props.defaultHiddenColumns || f.defaultHiddenColumns,
			dob: null,
			breakWidth: 1300,
			windowWidth: 0,
			locationsRadio: f.salesServiceClinic || "Sales",
			allEquipment: [],
			allInsurances: [],
			allInsuranceSubTypes:[],
			filteredInsuranceSubTypes:[],
			allInternalLocations: [],
			allStatuses: [],
			allReasons: [],
			availableReasons: [],
			allSalesReps: [],
			filteredSalesReps: [],
			orderModalOpen: false,
			shouldOverride: props.shouldOverride || false,
			showDatePicker: true,
			quickFilter: [],
			quickFilterOptions: filterOptions,
			quickFilterSelected: filterSelected,
			otherUsers: otherUsers,
			orderId: "",

			statusOptions: statusOptions,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		productTypes: PropTypes.array,
		reasons: PropTypes.array,
		salesReps: PropTypes.array,
		internalLocations: PropTypes.array,
		insuranceTypes: PropTypes.array,
		insuranceSubTypes: PropTypes.array,
		userFunction: () => {},
	};

	 //componentDidUpdate(prevProps, prevState, snapshot) {
	 //	const {
	 //		salesReps,
	 //		internalLocations,
	 //		productTypes,
	 //		reasons,
	 //	} = this.context;
	 //	let st = this.state;

	 //	if (st.allInternalLocations.length !== internalLocations.length) {
	 //		this.getLocationOptions();
	 //	}

	 //	if (st.allEquipment.length !== productTypes.length) {
	 //		this.getEquipmentOptions();
	 //	}
	 //	if (st.allReasons.length < reasons.length) {
	 //		this.getReasonOptions();
	 //	}
	 //	if (st.allStatuses.length !== 5) {
	 //		this.getStatusOptions();
	 //	}
	 //	if (st.allSalesReps.length !== salesReps.length) {
	 //		this.getSalesRepOptions();
	 //	}
	 //}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.groupingColumns !== this.state.groupingColumns) {
			this.setState({
				groupingColumns: nextProps.groupingColumns,
			});
		}

		if (nextProps.defaultHiddenColumns !== this.state.defaultHiddenColumns) {
			this.setState({
				defaultHiddenColumns: nextProps.defaultHiddenColumns,
			});
		}
	}

	componentDidMount() {
		this.getStatusOptions();
		this.getReasonOptions();

		this.getLocationOptions();
		this.getSalesRepOptions();
		this.getEquipmentOptions();
		this.getInsuranceTypes();
		this.getInsuranceSubTypes();

		this.newSearchPass();
	}

	componentDidUpdate() {
		const { productTypes, reasons, salesReps, internalLocations, insuranceTypes, insuranceSubTypes } = this.context;
		if (internalLocations.length !== this.state.allInternalLocations.length) {
			this.getLocationOptions();
		}
		if (reasons.length !== this.state.allReasons.length) {
			this.getReasonOptions();
		}
		if (insuranceTypes.length !== this.state.allInsurances.length) {
			this.getInsuranceTypes();
		}
		if (insuranceSubTypes.length !== this.state.allInsuranceSubTypes.length) {
			this.getInsuranceSubTypes();
		}
		if (salesReps.length !== this.state.allSalesReps.length) {
			this.getSalesRepOptions();
		}
		if (productTypes.length !== this.state.allEquipment.length) {
			this.getEquipmentOptions();
		}
    }

	handleResize = () =>
		this.setState({
			windowWidth: window.innerWidth,
		});

	newSearchPass() {
		const { currentUser } = this.context;

		let st = this.state,
			filters = {};

		if (st.quickFilter == null || st.quickFilter.length === 0) {
			if (
				st.startDate == null ||
				st.startDate === "" ||
				st.endDate == null ||
				st.endDate === ""
			) {
				toast.error(
					"You must select a quick date range or input custom start and end dates"
				);
				return;
			}
		}

		if (st.quickFilter && st.quickFilter[0] != null) {
			filters.start = st.quickFilter[0].toLocaleDateString();
		} else {
			filters.start = st.startDate.toLocaleDateString();
		}

		if (st.quickFilter && st.quickFilter[1] != null) {
			filters.end = st.quickFilter[1].toLocaleDateString();
		} else {
			filters.end = st.endDate.toLocaleDateString();
		}

		//This should run when we load the page for auto populating the list...BC
		if (st.selectedStatuses && st.selectedStatuses.length > 0) {
			//let temp = [];
			//st.statuses.forEach(s => {
			//	temp.push(stats.indexOf(s));
			//})
			filters.status = [];//st.selectedStatuses.map(x => typeof x === "string" ? stats.indexOf(x) : x);

			st.selectedStatuses.forEach(s => {
				switch (s) {
					case "New":
						filters.status.push("0");
						break;
					case "InProcess":
					case "In Process":
						filters.status.push("1");
						break;
					case "Ready To Deliver":
					case "Ready to Deliver":
					case "ReadyToDeliver":
						filters.status.push("2");
						break;
					case "Setup":
					case "Set Up":
						filters.status.push("3");
						break;
					case "Cancelled":
						filters.status.push("4");
						break;
					default:
						filters.status.push(s);
						break;
                }
            })
		}

		if (st.selectedReasonIds && st.selectedReasonIds.length > 0) {
			filters.reasons = st.selectedReasonIds.toString();
		}

		filters.locType = st.locationsRadio;

		if (st.equipmentIds && st.equipmentIds.length > 0) {
			filters.equipment = st.equipmentIds.toString();
		}
		//TODO - uncomment
		if (st.insuranceSelected && st.insuranceSelected.length > 0) {
			filters.payors = st.insuranceSelected.toString();
		}
		if (st.insuranceSubTypeSelected && st.insuranceSubTypeSelected.length > 0) {
			filters.insurance = st.insuranceSubTypeSelected.toString();
		}

		//Don't let sales reps see anything except orders assigned to them...BC
		if (currentUser.role === "SALES") {
			delete filters.locations;
			filters.users = currentUser.id.toString();
		} else {
			if (st.selectedLocations && st.selectedLocations.length > 0) {
				filters.locations = st.selectedLocations.toString();
			}

			//This should only happen on load of the page from going back...BC
			if (st.users && st.users.length > 0) {
				filters.users = st.users;
			}
		}

		this.newSearchFunction(filters);
	}

	saveUserFilters() {
		const { currentUser, userFunction } = this.context;

		let obj = {
			userpreferenceId: currentUser.userpreferenceId || 0,
			dateFilterType: this.state.dateFilterType,
			endDate: this.state.endDate,
			salesServiceClinic: this.state.locationsRadio,
			startDate: this.state.startDate,
			userpreferenceDefaultHiddenColumns: this.state.defaultHiddenColumns.map(x => { return { userpreferenceId: currentUser.userpreferenceId || 0, defaultHiddenColumns: x }; }) || [],
			userpreferenceEquipment: this.state.equipmentIds.map(x => { return { userpreferenceId: currentUser.userpreferenceId || 0, equipmentId: parseInt(x) } }) || [],
			userpreferenceGroupColumns: this.state.groupingColumns.map(x => { return { userpreferenceId: currentUser.userpreferenceId || 0, groupColumns: x }; }) || [],
			userpreferenceInsurance: (this.state.insuranceSelected && this.state.insuranceSelected.length > 0) || (this.state.insuranceSubTypeSelected && this.state.insuranceSubTypeSelected.length > 0) ? [{ userpreferenceId: currentUser.userpreferenceId || 0, payors: this.state.insuranceSelected.toString() || "", insurance: this.state.insuranceSubTypeSelected.toString() || "" }] : [],
			userpreferenceInternalLocation: this.state.selectedLocations.map(x => { return { userpreferenceId: currentUser.userpreferenceId || 0, internalLocationId: parseInt(x) } }),
			userpreferenceReason: this.state.selectedReasonIds.map(x => { return { userpreferenceId: currentUser.userpreferenceId || 0, reasonId: parseInt(x) } }) || [],
			userpreferenceSeatingClinics: [], //this.state.clinicLocations.map(x => { return { userpreferenceId: currentUser.userpreferenceId, seatingClinicId: x } }) || [],
			userpreferenceStatus: this.state.selectedStatuses.map(x => { return { userpreferenceId: currentUser.userpreferenceId || 0, statusValue: this.state.statusOptions[x].label } }) || [],
			userpreferenceTypes: [],
			userpreferenceUser: this.state.users.map(x => { return { userpreferenceId: currentUser.userpreferenceId || 0, userId: x } }),
		};

		//currentUser.userpreference = obj;

		if (obj.userpreferenceId > 0) {
			// update
			UserService.updatePreferences(obj)
				.then(res => {
					//console.log(res)
					//currentUser.userpreferenceId = res.userpreferenceId;
					currentUser.userpreference = res;
					userFunction(currentUser);
					toast.success("Saved Filters Successfully!");

					if (res) {
						this.setState({
							users: res.userPreferenceUser,
						});
					}
				})
				.catch(err => {
					console.log(err);
					toast.error("There was an error saving your filters :(");
				});
		}
		else {
			// create
			UserService.createPreferences(currentUser.id, obj)
				.then(res => {
					//console.log(res)
					currentUser.userpreferenceId = res.userpreferenceId;
					currentUser.userpreference = res;
					userFunction(currentUser);
					toast.success("Saved Filters Successfully!");

					if (res) {
						this.setState({
							users: res.userPreferenceUser,
						});
					}
				})
				.catch(err => {
					console.log(err);
					toast.error("There was an error saving your filters :(");
				});
        }

		//return UserService.updateUser(currentUser)
		//	.then((res) => {
		//		userFunction(res);
		//		toast.success("Saved Filters Successfully!");

		//		if (res.userPreferences) {
		//			this.setState({
		//				users: res.userPreferences.users,
		//			});
		//		}
		//	})
		//	.catch((err) => {
		//		toast.error("There was an error saving your filters :(");
		//	});
	}

	clearUserFilters() {
        this.clearSelectedStatuses();
        this.clearSelectedEquipment();
        this.clearSelectedLocations();
        this.clearSelectedSalesReps();
		this.clearSelectedInsurances();
		this.clearSelectedInsuranceSubTypes();
		//TODO

		toast.success("Filters have been cleared (not saved)");
	}

	onLocationsRadioClick = (val) => {
		this.setState({
			locationsRadio: val,
		});
	};

	getStatusOptions() {
		let oldNames = this.state.selectedStatuses,
			a = [];

		a.push({
			checked: oldNames.indexOf("New") > -1 || oldNames.indexOf(0) > -1,
			disabled: false,
			text: "New",
			value: 0,
		});

		a.push({
			checked: oldNames.indexOf("InProcess") > -1 || oldNames.indexOf("In Process") > -1 || oldNames.indexOf(1) > -1,
			disabled: false,
			text: "In Process",
			value: 1,
		});

		a.push({
			checked: oldNames.indexOf("ReadyToDeliver") > -1 || oldNames.indexOf("Ready to Deliver") > -1 || oldNames.indexOf(2) > -1,
			disabled: false,
			text: "Ready to Deliver",
			value: 2,
		});

		a.push({
			checked: oldNames.indexOf("Setup") > -1 || oldNames.indexOf("Set Up") > -1 || oldNames.indexOf(3) > -1,
			disabled: false,
			text: "Setup",
			value: 3,
		});

		a.push({
			checked: oldNames.indexOf("Cancelled") > -1 || oldNames.indexOf(4) > -1,
			disabled: false,
			text: "Cancelled",
			value: 4,
		});

		this.setState({
			allStatuses: a,
		});
	}

	getReasonOptions() {
		const { reasons } = this.context;
		let a = [],
			statuses = this.state.selectedStatuses || [],
			rsns = reasons || this.state.allReasons,
			ids = this.state.reasonIds,
			oldIds = this.state.selectedReasonIds;

		let sortOrder = [
			"New",
			"In Process",
			"Ready to Deliver",
			"Setup",
			"Cancelled",
		];

		rsns.sort(function (a, b) {
			// Pass a function to the sort that takes 2 elements to compare
			if (a.orderStatus === b.orderStatus) {
				// If the elements both have the same `type`,
				return a.description.localeCompare(b.description); // Compare the elements by `name`.
			} else {
				// Otherwise,
				return (a.orderStatus - b.orderStatus);
				//	sortOrder.indexOf(a.orderStatus) - sortOrder.indexOf(b.orderStatus)
				//); // Subtract indexes, If element `a` comes first in the array, the returned value will be negative, resulting in it being sorted before `b`, and vice versa.
			}
		});

		statuses.forEach(s => {
			var id = s; //sortOrder.indexOf(s);
			if (!id || id < 0) {
				var t = this.state.statusOptions.find(x => x.label === s);
				if (t)
					id = t.value;
            }
			a.push({
				checked: false,
				disabled: true,
				text: sortOrder[s],
				value: s,
			})
			// adding a handler for 'blank' -- AFTER we rework the userpreferences - TODO
			//a.push({
			//	checked: false,
			//	disabled: false,
			//	text: 'Blank',
			//	value: '-',
			//});
			rsns.forEach(r => {
				if (statuses && statuses.length > 0 && id === r.orderStatus) {
					a.push({
						checked: ids.includes(r.id) || oldIds.includes(r.id) || false,
						disabled: false,
						text: r.description,
						value: r.id,
                    })
				}
			})
		});

		this.setState({
			availableReasons: a,
			allReasons: reasons,
		});
	}

	getLocationOptions() {
		const { internalLocations } = this.context;

		let a = [],
			oldIds = this.state.internalLocations || [],
			ids = this.state.locationIds;

		//Must add the checked and disabled for it to remain 'uncontrolled'...BC
		internalLocations.map((loc) => {
			let checked = false;

			//If the user filter had old ids in it, set them to default be selected...BC
			if (oldIds.indexOf(loc.id) > -1 || ids.indexOf(loc.id) > -1) {
				checked = true;
			}

			return a.push({
				checked: checked,
				disabled: false,
				text: loc.name,
				value: loc.id,
			});
		});

		this.setState({
			allInternalLocations: a,
		});
	}

	getInsuranceTypes(){
		const { insuranceTypes } = this.context;
		let oldIds = this.state.insuranceSelected,
			ids = this.state.insuranceIds.map(x => parseInt(x));

		let arr = [];

		insuranceTypes.forEach(ins => {
			let checked = false;
			if (oldIds.indexOf(ins.id) > -1 || ids.indexOf(ins.id) > -1) {
				checked = true;
			}
			arr.push({
				checked: checked,
				disabled: false,
				text: ins.name,
				value: ins.id,
			});
		});

		this.setState({
			allInsurances: arr,
		});
	}

	getInsuranceSubTypes(){
		const { insuranceSubTypes } = this.context;
		let oldIds = this.state.insuranceSubTypeSelected,
			ids = this.state.insuranceSubTypeIds.map(x => parseInt(x));

		let arr = [];

		insuranceSubTypes.forEach(ins => {
			let checked = false;
			if (oldIds.indexOf(ins.id) > -1 || ids.indexOf(ins.id) > -1) {
				checked = true;
			}
			arr.push({
				insuranceType: ins.insuranceType,
				checked: checked,
				disabled: false,
				text: ins.name,
				value: ins.id,
			});
		});

		this.setState({
			allInsuranceSubTypes: arr,
			filteredInsuranceSubTypes: arr,
		});
	}

	filterInsuranceSubTypes(e) {
		let insuranceSubTypes = this.state.allInsuranceSubTypes;

		let ary = [];

		if (e.length === 0) {
			insuranceSubTypes = insuranceSubTypes.map(x => {
				x.checked = false;
				return x;
			})
			this.setState({
				filteredInsuranceSubTypes: insuranceSubTypes,
			})
			return;
		}

		e.map((insurance) => {
			insuranceSubTypes.map((t) => {
				if (t.insuranceType.id === insurance) {
					return ary.push(t);
				}
				return null;
			});
			return null;
		})
		this.setState({
			filteredInsuranceSubTypes: ary,
		});

	}

	getSalesRepOptions(e) {
		const { salesReps } = this.context;
		let options = [],
			oldIds = this.state.users || [];

		salesReps.map((rep) => {
			if (!e || e.length === 0 || (rep.userprofileLocations && rep.userprofileLocations.length > 0 && rep.userprofileLocations.filter(x => !e || e.length === 0 || e.includes(x.locationsId)).length > 0)) {
				let checked = false; 

				if (oldIds.indexOf(rep.id) > -1) {
					checked = true;
				}

				return options.push({
					disabled: false,
					checked: checked,
					text: rep.firstname + " " + rep.lastname,
					value: rep.id,
				});
			}
			return null;
		});

		if (salesReps.length !== 0) {
			this.setState({
				filteredSalesReps: options,
				allSalesReps: salesReps,
				updatedCheckedReps: true,
			});
		}
	}

	getEquipmentOptions() {
		const { productTypes } = this.context;

		let a = [],
			oldIds = this.state.equipmentIds;

		//Must add the checked and disabled for it to remain 'uncontrolled'...BC
		productTypes.map((prod) => {
			let checked = false;

			//If the user filter had old ids in it, set them to default be selected...BC
			if (oldIds.indexOf(prod.id) > -1) {
				checked = true;
			}

			return a.push({
				checked: checked,
				disabled: false,
				text: prod.name,
				value: prod.id.toString(),
			});
		});

		this.setState({
			allEquipment: a,
		});
	}

	renderDates() {
		let st = this.state;
		return (
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<div>
					<MDBRow>
						<Col size="12" style={{ zIndex: "1000" }}>
							<Select
								placeholder="Quick Filter"
								options={st.quickFilterOptions}
								onChange={this.handleQuickFilter.bind(this)}
								value={st.quickFilterSelected}
							/>
						</Col>
					</MDBRow>

					{st.showDatePicker ? (
						<MDBRow style={{ marginTop: 10, marginBottom: 10 }}>
							<Col size="6" className={"inputDateWrapper uiDateOutline"}>
								<DatePicker
									size="small"
									inputVariant={"outlined"}
									format="MM-DD-YYYY"
									value={st.startDate || null}
									onChange={(evt) =>
										this.setState({ startDate: new Date(evt._d) })
									}
									mask={[
										/\d/,
										/\d/,
										"-",
										/\d/,
										/\d/,
										"-",
										/\d/,
										/\d/,
										/\d/,
										/\d/,
									]}
									label="Start Date"
								/>
							</Col>

							<Col size="6" className={"inputDateWrapper uiDateOutline"}>
								<DatePicker
									size="small"
									inputVariant={"outlined"}
									format="MM-DD-YYYY"
									value={st.endDate || null}
									onChange={(evt) =>
										this.setState({ endDate: new Date(evt._d) })
									}
									mask={[
										/\d/,
										/\d/,
										"-",
										/\d/,
										/\d/,
										"-",
										/\d/,
										/\d/,
										/\d/,
										/\d/,
									]}
									label="End Date"
								/>
							</Col>
						</MDBRow>
					) : (
						<div style={{ margin: "68px" }}>
							<div
								style={{ marginLeft: "15px", marginRight: "55px" }}
								className="spinner-border text-info"
								role="status"
							>
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}
				</div>
			</MuiPickersUtilsProvider>
		);
	}

	handleQuickFilter = (e) => {
		let a = QuickFilter.getDates(e.value),
			st = this.state,
			t = this;

		if (a.length > 0) {
			st.startDate = a[0];
			st.endDate = a[1];
		} else {
			st.startDate = "";
			st.endDate = "";
		}

		this.setState({
			quickFilter: a,
			dateFilterType: e.value,
			startDate: st.startDate,
			endDate: st.endDate,
			showDatePicker: false,
			quickFilterSelected: e,
		});

		setTimeout(() => {
			t.setState({
				showDatePicker: true,
			});
		}, 500);
	};

	renderStatuses() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						color="danger"
						selected={"Choose Statuses"}
						style={{ maxHeight: "100px" }}
						options={this.state.allStatuses}
						getValue={this.getStatusValue.bind(this)}
					/>
				</Col>
				<Col size="2">
					<Fa
						style={{ paddingTop: 20 }}
						onClick={this.clearSelectedStatuses.bind(this)}
						icon={"times"}
					/>
				</Col>
			</Row>
		);
	}

	getStatusValue(e) {
		let t = this;

		this.setState({
			selectedStatuses: e,
		});
        setTimeout(() => {
			t.getReasonOptions();
        }, 300);
	}

	clearSelectedStatuses() {
        let t = this;

		this.setState({
			statuses: [],
			selectedStatuses: [],
		});

		this.state.allStatuses.forEach((stat) => {
			stat.checked = false;
		});

        setTimeout(() => {
            t.getStatusOptions();
            t.clearSelectedReasons();
        }, 100);
	}

	renderReasons() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						color="warning"
						selected={"Choose Reasons"}
						style={{ maxHeight: "100px" }}
						options={this.state.availableReasons}
						getValue={this.getReasonValue.bind(this)}
					/>
				</Col>
				<Col size="2" style={{ paddingTop: 20 }}>
					<Fa onClick={this.clearSelectedReasons.bind(this)} icon={"times"} />
				</Col>
			</Row>
		);
	}

	getReasonValue(e) {
		//let res = e && e.length > 0 ? e.map(x => x.id) : [];
		this.setState({
			selectedReasonIds: e || [],
		});
	}

	clearSelectedReasons() {
        let t = this,
            r = this.state.availableReasons.slice();

        r.forEach((res) => {
            res.checked = false;
        });

		this.setState({
            selectedReasonIds: [],
			//reasonIds: [],
            availableReasons: r
		});

        setTimeout(() => {
            t.getReasonOptions();
        }, 100);
	}

	renderLocationType() {
		return (
			<Row>
				<MDBCol size="6">
					<MDBInput
						onClick={() => this.onLocationsRadioClick("Sales")}
						checked={this.state.locationsRadio === "Sales"}
						label="Sales"
						type="radio"
						id="salesLocations"
					/>
				</MDBCol>

				<MDBCol size="6">
					<MDBInput
						onClick={() => this.onLocationsRadioClick("Service")}
						checked={this.state.locationsRadio === "Service"}
						label="Service"
						type="radio"
						id="serviceLocations"
					/>
				</MDBCol>
			</Row>
		);
	}

	renderLocations() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						color="info"
						selected={"Choose Locations"}
						style={{ maxHeight: "100px" }}
						options={this.state.allInternalLocations}
						getValue={this.getLocationValue.bind(this)}
					/>
				</Col>
				<Col size="2" style={{ paddingTop: 20 }}>
					<Fa onClick={this.clearSelectedLocations.bind(this)} icon={"times"} />
				</Col>
			</Row>
		);
	}

	getLocationValue(e) {
		this.setState({
			selectedLocations: e,
		});
		this.getSalesRepOptions(e);
	}

	clearSelectedLocations() {
        let t = this;

		this.setState({
			locationIds: [],
			selectedLocations: [],
		});

		this.state.allInternalLocations.forEach((loc) => {
			loc.checked = false;
		});

        setTimeout(() => {
            t.getLocationOptions();
        }, 100);
	}

	renderSalesReps() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						search
						color="default"
						selected={"Choose Sales Reps"}
						className={"searchField"}
						style={{ maxHeight: "100px" }}
						options={this.state.filteredSalesReps}
						getValue={this.getSalesRepsValue.bind(this)}
					/>
				</Col>
				<Col size="2" style={{ paddingTop: 20 }}>
					<Fa onClick={this.clearSelectedSalesReps.bind(this)} icon={"times"} />
				</Col>
			</Row>
		);
	}

	getSalesRepsValue(e) {
		this.setState({
			users: e,
		});
	}

	clearSelectedSalesReps() {
        let ary = [];

		this.state.filteredSalesReps.forEach((rep) => {
			rep.checked = false;
			ary.push(rep);
		});

		this.setState({
			users: [],
			filteredSalesReps: ary,
		});

        setTimeout(() => {
            this.getSalesRepOptions(this.state.selectedLocations);
        }, 100);
	}

	renderEquipment() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						color="secondary"
						selected={"Choose Equipment"}
						style={{ maxHeight: "100px" }}
						options={this.state.allEquipment}
						getValue={this.getEquipmentValue.bind(this)}
					/>
				</Col>
				<Col size="2" style={{ paddingTop: 20 }}>
					<Fa onClick={this.clearSelectedEquipment.bind(this)} icon={"times"} />
				</Col>
			</Row>
		);
	}

	renderInsurance() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						color="secondary"
						selected={"Choose Payor Source"}
						style={{ maxHeight: "100px" }}
						options={this.state.allInsurances}
						getValue={this.getInsuranceValue.bind(this)}
					/>
				</Col>
				<Col size="2" style={{ paddingTop: 20 }}>
					<Fa onClick={this.clearSelectedInsurances.bind(this)} icon={"times"} />
				</Col>
			</Row>
		);
	}

	renderInsuranceSubType() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						color="secondary"
						selected={"Choose Insurance"}
						style={{ maxHeight: "100px" }}
						options={this.state.filteredInsuranceSubTypes}
						getValue={this.getInsuranceSubTypeValue.bind(this)}
					/>
				</Col>
				<Col size="2" style={{ paddingTop: 20 }}>
					<Fa onClick={this.clearSelectedInsuranceSubTypes.bind(this)} icon={"times"} />
				</Col>
			</Row>
		);
	}

	getEquipmentValue(e) {
		this.setState({
			equipmentIds: e,
		});
	}

	getInsuranceValue(e){
		this.setState({
			insuranceSelected: e || [],
			insuranceIds: e,
		});
		this.filterInsuranceSubTypes(e);
	}

	getInsuranceSubTypeValue(e){
		this.setState({
			insuranceSubTypeSelected: e,
		});
	}

	clearSelectedEquipment() {
        let t = this;

		this.setState({
			equipmentIds: [],
		});

		this.state.allEquipment.forEach((eq) => {
			eq.checked = false;
		});


        setTimeout(() => {
            t.getEquipmentOptions();
        }, 100);


	}

	clearSelectedInsurances() {
		let ai = this.state.allInsurances;
		ai = ai.map(x => {
			x.checked = false;
			return x;
        })
		this.setState({
			insuranceIds: [],
			insuranceSelected: [],
			allInsurances: ai,
		});
		this.clearSelectedInsuranceSubTypes();
	}

	clearSelectedInsuranceSubTypes() {
		var items = this.state.filteredInsuranceSubTypes;
		items = items.map(x => {
			x.checked = false;
			return x;
        })
		this.setState({
			insuranceSubTypeSelected: [],
			insuranceSubTypeIds: [],
			filteredInsuranceSubTypes: items,
        })
	}

	//method to hide/show the location filters based on if you are a sales rep or not...BC
	renderLocationUserFilters() {
		const { currentUser } = this.context;
		if (currentUser && currentUser.role !== "SALES") {
			return (
				<div>
					<Col size="12">{this.renderLocations()}</Col>

					<Col size="12">{this.renderSalesReps()}</Col>
				</div>
			);
		}
		return <div />;
	}

	render() {
		return (
			<Card>
				<CardBody>
					<Row>
						<Col size="12">{this.renderDates()}</Col>

						<Col size="12">{this.renderStatuses()}</Col>

						<Col size="12">{this.renderReasons()}</Col>

						<div style={{ width: "100%" }}>
							{this.renderLocationUserFilters()}
						</div>

						<Col size="12">{this.renderEquipment()}</Col>
                        {/*TODO uncomment when ready to implement*/}
						<Col size="12">{this.renderInsurance()}</Col>

						<Col size="12">{this.renderInsuranceSubType()}</Col>


						<Col size="12" style={{ paddingTop: 10, paddingBottom: 10 }}>
							{this.renderLocationType()}
						</Col>
					</Row>

					<Row style={{ justifyContent: "space-around" }}>
						<Button
							floating
							size="sm"
							color={"indigo"}
							data-tip={"Search"}
							onClick={this.newSearchPass.bind(this, this.state)}
						>
							<MDBIcon icon="search" style={{ fontSize: "2em" }} />
						</Button>

						<Button
							floating
							size="sm"
							color={"success"}
							data-tip={"Save Filters"}
							onClick={this.saveUserFilters.bind(this)}
						>
							<MDBIcon icon="save" style={{ fontSize: "2em" }} />
						</Button>

						<Button
							floating
							size="sm"
							color={"warning"}
							data-tip={"Clear Filters"}
							onClick={this.clearUserFilters.bind(this)}
						>
							<MDBIcon icon="eraser" style={{ fontSize: "2em" }} />
						</Button>

						<ReactTooltip />
					</Row>
				</CardBody>
			</Card>
		);
	}
}
