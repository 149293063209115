import React from "react";
import Card from "../../../Card";
import MUITextField from "@mui/material/TextField";
import MUIGrid from "@mui/material/Grid";
import MUIMenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import Term from "../../../../Utility/Crud/Term";

export default class CardAdminQueueGeneral extends Card {
    static contextTypes = {
        termsIndexed: PropTypes.object
    };

    /**
     * Render the content of the card.
     */
    renderContent() {
        return (
            <MUIGrid container spacing={2}>
                <MUIGrid item xs={12} sm={6} md={3}>
                    <MUITextField
                        value={this.props.queue && this.props.queue.name ? this.props.queue.name : ""}
                        autoFocus={true}
                        size="small"
                        label="Name"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{
                            maxLength: 255,
                        }}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ name: e.target.value.trim() })}
                        onChange={(e) => this.props.onChange({ name: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.name ? true : false}
                        helperText={this.props.validationIssues.name ? this.props.validationIssues.name : ""}
                    />
                </MUIGrid>

                <MUIGrid item xs={12} sm={6} md={3}>
                    <MUITextField
                        select={true}
                        value={this.props.queue && this.props.queue.queueTypeTermId ? this.props.queue.queueTypeTermId : ""}
                        size="small"
                        label="Type"
                        variant="standard"
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => this.props.onChange({ queueTypeTermId: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.queueTypeTermId ? true : false}
                        helperText={this.props.validationIssues.queueTypeTermId ? this.props.validationIssues.queueTypeTermId : ""}
                    >
                        {
                            Term.sort(Term.read(this.context.termsIndexed, [{ key: "type", value: "queue_type" }])).map((term) => {
                                return (<MUIMenuItem key={term.id} value={term.id}>{term.name}</MUIMenuItem>);
                            })
                        }
                    </MUITextField>
                </MUIGrid>

                <MUIGrid item xs={12} sm={6} md={3}>
                    <MUITextField
                        select={true}
                        value={this.props.queue ? this.props.queue.table : ""}
                        size="small"
                        label="Table"
                        variant="standard"
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => this.props.onChange({ table: e.target.value })}
                        required={true}
                        error={this.props.validationIssues.table ? true : false}
                        helperText={this.props.validationIssues.table ? this.props.validationIssues.table : ""}
                    >
                        <MUIMenuItem value="order_activity">Order Activity</MUIMenuItem>
                        <MUIMenuItem value="patient_issue">Patient Issue</MUIMenuItem>
                        <MUIMenuItem value="pending_preauth">Pending Preauth</MUIMenuItem>
                    </MUITextField>
                </MUIGrid>

                <MUIGrid item xs={12} sm={6} md={3}>
                    <MUITextField
                        value={this.props.queue && this.props.queue.ordinal ? this.props.queue.ordinal : ""}
                        size="small"
                        label="Ordinal"
                        variant="standard"
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => this.props.onChange({ ordinal: parseInt(e.target.value.trim()) })}
                        onChange={(e) => this.props.onChange({ ordinal: e.target.value })}
                        required={false}
                        error={this.props.validationIssues.ordinal ? true : false}
                        helperText={this.props.validationIssues.ordinal ? this.props.validationIssues.ordinal : ""}
                    />
                </MUIGrid>
            </MUIGrid>
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "General";
    }
}