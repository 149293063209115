import React from "react";
import Card from "../../../Card";
import EmptyState from "../../../EmptyState";
import Table from "../../../Table";

export default class CardAdminQueueSorts extends Card {
    /**
     * Render the content of the card.
     */
    renderContent() {
        const rows = [];
        let i = 0;

        if (
            this.props.queue &&
            this.props.queue.view &&
            this.props.queue.view.sorts
        ) {
            this.props.queue.view.sorts.forEach((sort) => {
                rows.push({
                    id: i++,
                    key: sort.key,
                    direction: sort.direction
                });
            });
        }

        if (rows.length > 0) {
            return (
                <Table
                    columns={[
                        { key: "key", name: "Key", type: "string" },
                        { key: "direction", name: "Direction", type: "string" },
                    ]}
                    rows={rows}
                />
            );
        }

        return (
            <EmptyState
                line1="Not Configured"
                line2="This queue has no sorts set"
            />
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Sorts";
    }
}