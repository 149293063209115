import Crud from "../Crud";
import MUIYellowColor from "@mui/material/colors/yellow";

/**
 * Static helper functions related to Queue Routes.
 */
export default class Queue extends Crud {

    /**
     * @param {object} queue The queue object.
     * @returns The display name of the queue.
     */
    static getDisplayName(queue) {
        return queue.name;
    }

    /**
     * Determines if a queue matches a search query.
     *
     * @param {object} queue The queue to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the queue matches the query.
     */
    static match(queue, query) {
        return (
            (queue.name.toLowerCase().includes(query)) ||
            queue.id === +query
        );
    }

    /**
     * @param {array} queues The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(queues) {
        return queues.sort((a, b) => {
            if (a.ordinal !== null && b.ordinal !== null) {
                return a.ordinal - b.ordinal
            } else {
                return (a.ordinal === null) - (b.ordinal === null) || (a.name).localeCompare(b.name);
            }
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIYellowColor;
    }

    /**
     * @returns {number} The base color shade of this object.
     */
    static getBaseColorShade() {
        return 800;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "CallSplit";
    }

    /**
     * @param {object} queue The queue
     * @param {string} mode Either verbose or simple, defaults to verbose.
     * @returns A nice string describing the OOB rule for this queue.
     */
    static getOutOfBoundsString(queue, mode = "verbose") {
        const thresholds = [];

        if (queue && queue.thresholdSize !== undefined && queue.thresholdSize !== null) {
            thresholds.push(`${mode === "verbose" ? "size exceeds" : ""} ${queue.thresholdSize}`);
        }
        if (queue && queue.thresholdAgeHours !== undefined && queue.thresholdAgeHours !== null) {
            const business = queue.thresholdAgeHoursIsBusiness === true ? ' business' : '';
            if (queue.thresholdAgeHours > 8) {
                const thresholdAgeDays = Math.round(10 * queue.thresholdAgeHours / (queue.thresholdAgeHoursIsBusiness === true ? 8 : 24)) / 10;
                thresholds.push(`${mode === "verbose" ? "age greater than" : ""} ${thresholdAgeDays}${business} day${thresholdAgeDays === 1 ? '' : 's'}`);
            } else {
                thresholds.push(`${mode === "verbose" ? "age greater than" : ""} ${queue.thresholdAgeHours}${business} hour${queue.thresholdAgeHours === 1 ? '' : 's'}`);
            }
        }

        if (thresholds.length > 0) {
            return thresholds.join(' or ');
        } else {
            return null;
        }
    }

    /**
     * Checks the list of queues to determine if one exists that will include
     * the queueRoute's destination. It checks to ensure queue.table =
     * "order_activity", the queue is assigned to a team (via
     * teamQueue.queueId), and the queue filter (ex:
     * {"order_activity.order_status_reason_id":[57]}) contains only a single
     * filter for order_activity.order_status_reason_id and the
     * queueRoute.toOrderStatusReasonId is in the list.
     *
     * @param {array} queues List of queues.
     * @param {array} teamQueues List of teamQueues.
     * @param {object} queueRoute The queueRoute.
     *
     * @return {boolean} True if a matching queue exists, otherwise false.
     */
    // static queueExistsForQueueRoute(queues, teamQueues, queueRoute) {
    //     return queues.some(queue => {
    //         if (queue.table !== "order_activity") {
    //             return false
    //         };

    //         const teamQueueExists = teamQueues.some(teamQueue => teamQueue.queueId === queue.id);
    //         if (!teamQueueExists) {
    //             return false;
    //         }

    //         if (
    //             !queue.filter ||
    //             !queue.filter["order_activity.order_status_reason_id"] ||
    //             queue.filter.length > 1
    //         ) {
    //             return false;
    //         }

    //         return queue.filter["order_activity.order_status_reason_id"].includes(queueRoute.toOrderStatusReasonId);
    //     });
    // }


    /**
     * Checks the list of queues to determine which ones include the
     * queueRoute's destination. It checks to ensure queue.table =
     * "order_activity", the queue is assigned to a team (via
     * teamQueue.queueId), and the queue filter (ex:
     * {"order_activity.order_status_reason_id":[57]}) contains only a single
     * filter for order_activity.order_status_reason_id and the
     * queueRoute.toOrderStatusReasonId is in the list.
     *
     * @param {array} queues List of queues.
     * @param {array} teamQueues List of teamQueues.
     * @param {object} queueRoute The queueRoute.
     *
     * @return {array} Array of matching queues. Returns an empty array if no
     * matching queues exist.
     */
    static getMatchingQueuesForQueueRoute(queues, teamQueues, queueRoute) {
        return queues.filter(queue => {
            if (queue.table !== "order_activity") {
                return false;
            }

            const teamQueueExists = teamQueues.some(teamQueue => teamQueue.queueId === queue.id);
            if (!teamQueueExists) {
                return false;
            }

            if (
                !queue.filter ||
                !queue.filter["order_activity.order_status_reason_id"] ||
                Object.keys(queue.filter).filter(key => queue.filter[key].length > 0).length > 1) {
                return false;
            }

            return queue.filter["order_activity.order_status_reason_id"].includes(queueRoute.toOrderStatusReasonId);
        });
    }

}