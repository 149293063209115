import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Container,
	DatePicker,
	Input,
	InputFile,
	MDBIcon,
	Row,
	Spinner,
	toast,
	ToastContainer,
} from "mdbreact";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import ExpenseService from "../Security/ExpenseService/expenseService";

export default class expenseEdit extends React.Component {
	constructor(props) {
		super(props);

		let id = props.match.params.id;

		this.retrieveExpense(id);

		this.state = {
			isLoaded: false,
			expense: {},
			eExpense: {},
			cardTypes: [],
			expenseTypes: [],
			receipt: null,
			filtersData: props.location ? props.location.filters : null,

			allCardTypes: [],
			allExpenseTypes: [],
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		cardTypes: PropTypes.array,
		expenseTypes: PropTypes.array,
	};

	componentDidMount(){
		this.getCardTypes();
		this.getExpenseTypes();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { cardTypes, expenseTypes } = this.context;

		if (cardTypes.length !== this.state.allCardTypes.length) {
			this.getCardTypes();
		}
		if (expenseTypes.length > 0 && expenseTypes.length !== this.state.allExpenseTypes.length) {
			this.getExpenseTypes();
		}
    }

	retrieveExpense(id) {
		return ExpenseService.getExpense(id)
		.then((data) => {
			this.setState({
				expense: data,
				eExpense: JSON.parse(JSON.stringify(data)),
				isLoaded: true,
			});
		});
	}

	renderExpenseInfo() {
		let expense = this.state.eExpense;
		return (
			<div>
				<Row>
					<Col md={4} style={{border: "1px solid #418CBF",
						borderRadius: 3}}>
						<Select
							placeholder={
								expense.cardType ? expense.cardType.name : "Select Card Type"
							}
							options={this.state.cardTypes}
							onChange={this.handleCardTypeSelected.bind(this)}
						/>

						<br />

						<Select
							placeholder={
								expense.expenseType
									? expense.expenseType.name
									: "Select Expense Type"
							}
							options={this.state.expenseTypes}
							onChange={this.handleExpenseTypeSelected.bind(this)}
						/>

						<Row>
							<Col style={{marginTop:"5px"}}>
								<Input
									style={{textAlign:'left'}}
									value={expense.expenses || ""}
									label="Amount"
									type="number"
									outline
									step=".01"
									onChange={this.handleChange.bind(this, "expenses")}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="datePickerOptions">
									<DatePicker
										label="Expense Date"
										value={expense.expenseDate}
										getValue={this.handleDatePickerChange.bind(
											this,
											"expenseDate"
										)}
									/>
								</div>
							</Col>
						</Row>

						<Input
							value={expense.vendor || ""}
							label="Vendor"
							onChange={this.handleChange.bind(this, "vendor")}
							size="sm"
							// icon="user"
						/>

						<Input
							value={expense.expenseDesc || ""}
							label="Description"
							onChange={this.handleChange.bind(this, "expenseDesc")}
							size="sm"
							// icon="commenting-o"
						/>

						<InputFile
							textFieldTitle=".png or .jpg files only"
							getValue={this.fileInputHandler.bind(this)}
						/>
					</Col>

					<Col md={8}>{this.renderReceipt()}</Col>
				</Row>
			</div>
		);
	}

	getCardTypes() {
		const { cardTypes } = this.context;
		let ary = [];
		cardTypes.map((card) => {
			return ary.push({
				label: card.name,
				value: card,
			});
		});
		this.setState({
			cardTypes: ary,
			allCardTypes: cardTypes,
		})
	}

	getExpenseTypes() {
		let oExpense = this.state.eExpense;

		const { expenseTypes } = this.context;
		let ary = [];

		if (oExpense.cardType === null) {
			ary.push({
				label: "Choose Card Type",
				value: "",
			});
		} else {
			expenseTypes.map((expense) => {
				return expense.expenseTypeCreditCards.forEach((card) => {
					if (oExpense.cardType && oExpense.cardType.id === card.creditCardsId) {
						ary.push({
							label: expense.name,
							value: expense,
						});
					}
				});
			});
			this.setState({
				expenseTypes: ary,
				allExpenseTypes: expenseTypes,
			})
		}
	}

	handleCardTypeSelected = (e) => {
		let expense = this.state.eExpense;

		expense.cardType = { name: e.value.name, id: e.value.id };
		expense.cardTypeId = e.value.id;

		this.setState({
			eExpense: expense,
		});
		this.getExpenseTypes();
	};

	handleExpenseTypeSelected = (e) => {
		let expense = this.state.eExpense;

		expense.expenseType = { name: e.value.name, id: e.value.id };
		expense.expenseTypeId = e.value.id;

		this.setState({
			eExpense: expense,
		});
	};

	fileInputHandler(value) {
		let reader = new FileReader(),
			file = value[0],
			t = this,
			expense = this.state.eExpense;

		function callback(im){

			let idx = im.indexOf(","),
				newStr = im.slice(idx + 1);
			expense.expenseReceipt = newStr;
			t.setState({ eExpense: expense });

		}
		reader.onloadend = function () {
			t.base64Resize(reader.result, .5, callback);
		};
		reader.readAsDataURL(file);
	}

	base64Resize(sourceBase64, scale , setImage) {
		const _scale = scale;
		var img = document.createElement('img');
		img.setAttribute("src", sourceBase64);

		img.onload = () => {
			let canvas = document.createElement('canvas');
			canvas.width = img.width * _scale;
			canvas.height = img.height * _scale;
			let ctx = canvas.getContext("2d");
			let iw = img.width;
			let ih = img.height;
			let scl = Math.min((1000 / iw), (1000 / ih));
			let iwScaled = iw * scl;
			let ihScaled = ih * scl;
			canvas.width = iwScaled;
			canvas.height = ihScaled;
			ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
			const newBase64 = canvas.toDataURL("image/jpeg", scl);

			setImage(newBase64);
		}
	}
	renderReceipt() {
		let expense = this.state.eExpense;
		if (expense.expenseReceipt) {
			return (
				<Row>
					<Col md="2"></Col>
					<Col>
						<div className={"expenseImage"}>
							<img
								src={"data:image/jpeg;base64," + expense.expenseReceipt}
								style={{ height: "24rem" }}
								alt={"Receipt"}
							/>
						</div>
					</Col>
				</Row>
			);
		} else {
			return <div style={{ height: "24rem" }} />;
		}
	}

	ableToApproveDeny() {
		const { currentUser } = this.context;
		let expense = this.state.eExpense;

		switch (currentUser.role) {
			case "ADMIN":
			case "SYSTEM_ADMIN":
				return true;
			case "EXECUTIVE_MANAGEMENT":
			case "SALES_MANAGER":
				return !(expense.owner && expense.owner.id === currentUser.id);
			default:
				return false;
		}
	}

	changeApprovedBy() {
		const { currentUser } = this.context;

		let oExpense = this.state.eExpense;

		oExpense.approvedBy = { id: currentUser.id, name: currentUser.username };
		oExpense.deniedBy = null;
		oExpense.deniedById = null;
		oExpense.approvedById = currentUser.id;

		this.setState({ eExpense: oExpense });
	}

	changeDenyBy() {
		const { currentUser } = this.context;

		let oExpense = this.state.eExpense;

		oExpense.deniedBy = { id: currentUser.id, name: currentUser.username };
		oExpense.approvedBy = null;
		oExpense.deniedById = currentUser.id;
		oExpense.approvedById = null;

		this.setState({ eExpense: oExpense });
	}

	validateForm() {
		let e = this.state.eExpense,
			status;

		if (e.cardType === null) {
			toast.warning("Select a Card Type.");
			status = false;
		}
		if (e.expenseType === null) {
			toast.warning("Select an Expense Type.");
			status = false;
		}
		if (e.expenses === 0) {
			toast.warning("Enter an amount greater than 0.");
			status = false;
		}
		if (e.vendor === null) {
			toast.warning("Enter Vendor.");
			status = false;
		}
		if (e.expenseDesc === null) {
			toast.warning("Please enter a description.");
			status = false;
		} else {
			status = true;
		}
		return status;
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	handleChange = (property, event) => {
		//update the property that the user has edited in the order...BC
		const nExpense = this.state.eExpense;
		if (property === "expenses")
			nExpense[property] = parseFloat(event.target.value);
		else
			nExpense[property] = event.target.value;

		this.setState({ eExpense: nExpense });
	};

	handleDatePickerChange = (property, value) => {
		//update the date property on the object that the user has edited in the order...BC
		const nExpense = this.state.eExpense;
		nExpense[property] = value;
		this.setState({ eExpense: nExpense });
	};

	updateExpense = () => {
		let expense = this.state.eExpense;

		if(!expense.expenseDate) {
			expense.expenseDate = new Date();
		}
		//// expense.expenseReceipt = expense.receiptString;
		//delete expense.receiptString;
		//expense.cardTypeId = expense.cardType ? expense.cardType.id : null;
		//delete expense.cardType;
		//expense.expenseTypeId = expense.expenseType ? expense.expenseType.id : null;
		//delete expense.expenseType;
		//expense.approvedById = expense.approvedBy ? expense.approvedBy.id : null;
		//delete expense.approvedBy;
		//expense.deniedById = expense.deniedBy ? expense.deniedBy.id : null;
		//delete expense.deniedBy;
		//delete expense.activity;

		//need to update the expense in the list of devData if it exists;
		if (this.validateForm()) {
			return ExpenseService.updateExpense(expense)
				.then((data) => {
					if(data){
						toast.success("Saved Successfully!");
						this.retrieveExpense(expense.id);
					}
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	deleteExpense = () => {
		let expense = this.state.eExpense;

		expense.activity.endedAt = new Date();
		expense.activity.isDeleted = true;

		return ExpenseService.updateExpense(expense)
			.then((data) => {

			})
			.catch((err) => {
				toast.warn("An error occurred while saving.");
			});
	};

	renderExpensesListReturn() {
		return (
			<div>
				<Route
					render={({ history }) => (
						<Button
							floating
							size="sm"
							color={"warning"}
							data-tip={"Back to Expenses"}
							onClick={() => {
								history.push({
									pathname: "/expenses",
									filters: this.state.filtersData,
								});
							}}
						>
							<MDBIcon icon="backward" style={{ fontSize: "2em" }} />
						</Button>
					)}
				/>
				<ReactTooltip />
			</div>
		);
	}

	renderButtons() {
		if (this.ableToApproveDeny()) {
			return (
				<div>
					<Button
						floating
						size="sm"
						color={"indigo"}
						data-tip={"Approve Expense"}
						onClick={() => {
							this.changeApprovedBy();
						}}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						floating
						size="sm"
						color={"red"}
						data-tip={"Deny Expense"}
						onClick={() => {
							this.changeDenyBy();
						}}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</div>
			);
		}
	}

	renderApproveDenyInfo() {
		let e = this.state.eExpense;
		if (e.approvedBy) {
			return (
				<h6 style={{ float: "right" }}>
					Approved By : {e.approvedBy ? e.approvedBy.firstname + " " + e.approvedBy.lastname : "Not Approved"}
				</h6>
			);
		} else if (e.deniedBy) {
			return (
				<h6 style={{ float: "right" }}>
					Denied By : {e.deniedBy ? e.deniedBy.firstname + " " + e.deniedBy.lastname : "Not Denied"}
				</h6>
			);
		} else {
			return <div></div>;
		}
	}

	render() {
		let expense = this.state.eExpense;

		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>

				<Container style={{ width: "60%", maxWidth: "60%" }}>
					<Card style={{ marginBottom: "1%", marginTop: "2rem" }}>
						<CardHeader style={{backgroundColor: "#5881C1", color:"white"}} className="text-center">
							<Row>
								<Col size="2">{this.renderExpensesListReturn()}</Col>

								<Col
									size="6"
									style={{
										textAlign: "center",
										margin: "auto",
										fontSize: "1.5rem",
									}}
								>
									Edit Expense : {expense.id}
								</Col>

								<Col
									style={{
										textAlign: "center",
										margin: "auto",
										fontSize: "1.1rem",
									}}
								>
									{this.renderApproveDenyInfo()}
								</Col>
							</Row>
						</CardHeader>
						<CardBody>{this.renderExpenseInfo()}</CardBody>
						<CardFooter>
							{
								<Row style={{ justifyContent: "space-between" }}>
									{this.renderButtons()}

									<Button
										floating
										size="sm"
										color={"success"}
										data-tip={"Update Expense"}
										onClick={() => this.updateExpense()}
									>
										<MDBIcon far icon="save" style={{ fontSize: "2em" }} />
									</Button>

									<Button
										floating
										size="sm"
										color={"red"}
										data-tip={"Delete Expense"}
										onClick={() => this.deleteExpense()}
									>
										<MDBIcon fas icon="trash" style={{ fontSize: "2em" }} />
									</Button>
								</Row>
							}
						</CardFooter>
					</Card>
				</Container>
			</div>
		);
	}
}
