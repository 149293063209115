import React from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Container,
	Input,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Spinner,
	toast,
	MDBIcon,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import ReasonService from "./reasonService";

export default class reasons extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Status",
					field: "status",
					sort: "asc",
				},
				{
					label: "Description",
					field: "description",
					sort: "asc",
				},
				{
					label: "Third Party Description",
					field: "thirdPartyDescription",
					sort: "asc",
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
				},
				{
					label: "Delete",
					field: "delete",
					sort: "asc",
				},
			],
			rows: [],
		};

		const o = [
			{
				label: "New",
				value: "New",
			},
			{
				label: "In Process",
				value: "InProcess",
			},
			{
				label: "Ready To Deliver",
				value: "ReadyToDeliver",
			},
			{
				label: "Setup",
				value: "Setup",
			},
			{
				label: "Cancelled",
				value: "Cancelled",
			},
		];

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			description: "",
			thirdPartyDescription: "",
			status: "",
			currIndex: -1,
			errorMessage: "",
			currReason: {},
			selectOptions: o,
			click: "",
			addReason: {},
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveReasons();
	}

	retrieveReasons() {
		let t = this;
		return ReasonService.getAllReasons()
			.then((res) => {
				let arr = [],
					d = this.state.data,
					statuses = this.state.selectOptions.map(x => x.label);
				res.forEach((reason, index) => {
					arr.push({
						id: reason.id,
						status: reason.orderStatus >= 0 ? statuses[reason.orderStatus] : "None",
						description: reason.description,
						thirdPartyDescription: reason.thirdPartyDescription,
						edit: t.renderEditButton(reason, index),
						remove: t.renderRemoveButton(reason.id),
					});
				});

				d.rows = arr;
				t.setState({
					data: d,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderEditButton(reason, index) {
		return (
			<MDBIcon
				onClick={() => {this.handleEditClick(reason, index);}}
				icon="edit"
				style={{color: "#7ac5ff"}}
			/>
		);
	}

	renderRemoveButton(id) {
		return (
			<MDBIcon
				onClick={() => this.handleRemoveClick(id)}
				icon="trash"
				style={{color: "#db0404"}}
			/>
		);
	}

	handleRemoveClick(id) {
		ReasonService.deleteReason(id)
			.then((res) => {
				this.retrieveReasons();
				toast.success("Reason Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Reason");
			});
	}

	handleEditClick(reason, index) {
		let r = JSON.parse(JSON.stringify(reason));
		this.setState({
			currReason: r,
			click: "edit",
			editModal: !this.state.editModal,
			currIndex: index,
		});
	}

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};

	renderEditModal() {
		let c = this.state.click;
		return (
			<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
				<ModalHeader
					style={{ backgroundColor: "#90A4AE", color: "white" }}
					toggle={this.editToggle}
				>
					{c === "edit" ? "Edit Reason" : "Add New Reason"}
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{c === "edit" ? this.editModalBody() : this.addModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					{this.modalEditButtons(c)}
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	modalEditButtons(edit) {
		let gFunction = this.handleAddSave,
			cFunction = this.handleAddClose,
			gTip = "Save New Status Reason";

		if (edit === "edit") {
			gFunction = this.handleEditSave;
			cFunction = this.handleEditClose;
			gTip = "Update Status Reason";
		}

		return (
			<div>
				<Button
					style={{ float: "left" }}
					floating
					size="sm"
					color={"success"}
					data-tip={gTip}
					onClick={gFunction}
				>
					<MDBIcon icon="check" style={{ fontSize: "2em" }} />
				</Button>

				<Button
					style={{ float: "left" }}
					floating
					size="sm"
					color={"red"}
					data-tip={"Cancel"}
					onClick={cFunction}
				>
					<MDBIcon icon="times" style={{ fontSize: "2em" }} />
				</Button>
			</div>
		);
	}

	editModalBody() {
		let i = this.state.selectOptions.map(x => x.label)[this.state.currReason.orderStatus];
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<Select
					placeholder={i || "Select Status"}
					options={this.state.selectOptions}
					onChange={this.handleSelectChange.bind(this)}
				/>
				<div className="py-4 mt-3">
					<Input
						label="Description"
						value={this.state.currReason.description}
						onChange={this.handleDescriptionChange.bind(this)}
					/>
					<Input
						label="Third Party Description"
						value={this.state.currReason.thirdPartyDescription}
						onChange={this.handleThirdPartyDescriptionChange.bind(this)}
					/>
				</div>
			</div>
		);
	}

	addModalBody() {
		let r = this.state.addReason.description;
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<Select
					placeholder={"Select Status..."}
					options={this.state.selectOptions}
					onChange={this.handleAddChange.bind(this, "orderStatus")}
				/>
				<div className="py-4 mt-3">
					<Input
						label="Description"
						value={r}
						onChange={this.handleAddChange.bind(this, "description")}
					/>
					<Input
						label="Third Party Description"
						value={this.state.addReason.thirdPartyDescription}
						onChange={this.handleAddChange.bind(this, "thirdPartyDescription")}
					/>
				</div>
			</div>
		);
	}

	handleAddChange = (property, e) => {
		let r = this.state.addReason;
		r[property] = property === "orderStatus" ? this.state.selectOptions.map(x => x.value).indexOf(e.value) : e.target.value;
		this.setState({
			addReason: r,
		});
	};

	handleSelectChange = (e) => {
		let r = this.state.currReason;
		r.orderStatus = this.state.selectOptions.map(x => x.value).indexOf(e.value);
		this.setState({
			status: r,
		});
	};

	handleDescriptionChange(e) {
		let r = this.state.currReason;
		r.description = e.target.value;
		this.setState({
			description: r,
		});
	}

	handleThirdPartyDescriptionChange(e) {
		let r = this.state.currReason;
		r.thirdPartyDescription = e.target.value;
		this.setState({
			thirdPartyDescription: r,
		});
	}

	handleEditSave = () => {
		if (this.validateEdit()) {
			let r = this.state.currReason;

			return ReasonService.updateReason(r)
				.then((res) => {
					this.setState({
						editModal: !this.state.editModal,
						errorMessage: "",
					});
					toast.success("Reason Edited!");
					this.retrieveReasons();
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	handleAddSave = () => {
		if (this.validateEdit()) {
			let r = this.state.addReason;

			this.setState({
				isLoaded: false,
			});

			return ReasonService.createReason(r)
				.then((res) => {

					this.setState({
						editModal: !this.state.editModal,
						errorMessage: "",
						addReason: {},
					});
					toast.success("Reason Added!");
					this.retrieveReasons();
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateEdit() {
		if (this.state.click === "edit") {
			let a = this.state.currReason;
			if (a.description === "") {
				this.setError("Please add a Description");
				return false;
			}
			return true;
		} else {
			let r = this.state.addReason;
			if (r.orderStatus === undefined || r.orderStatus === "") {
				this.setError("Please select a Status");
				return false;
			} else if (r.description === undefined || r.description === "") {
				this.setError("Please add a Description");
				return false;
			}
			return true;
		}
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleEditClose = () => {
		this.setState({
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
			currReason: {},
		});
	};

	handleAddClose = () => {
		this.setState({
			errorMessage: "",
			editModal: !this.state.editModal,
			addReason: {},
		});
	};

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable small striped style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	addButtonClick = () => {
		this.setState({
			click: "add",
			editModal: !this.state.editModal,
		});
	};

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30 }}>
						Status Reasons
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Status Reason"}
							onClick={this.addButtonClick}
						>
							<MDBIcon far icon="question-circle" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				<ReactTooltip />
				{this.renderEditModal()}
			</Container>
		);
	}
}
