import React from "react";
import PropTypes from "prop-types";
import {
	Container,
	toast,
	ToastContainer,
	Card,
	CardBody,
	CardHeader,
	Row,
	Col,
	Input,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Modal,
	ModalBody,
	ModalFooter,
	Button,
	Nav,
	NavItem,
	NavLink,
	MDBIcon,
	TabContent,
	TabPane,
} from "mdbreact";
import ContactService from "../Security/ContactService/contactService";
import NoteService from "../Security/NoteService/noteService";
import classnames from "classnames";
import generator from "generate-password";
import UserService from "../Security/UserService/userService";
import ReactTooltip from "react-tooltip";
import GlobalFunctions from "../Filters/GlobalFunctions";

export default class contactEdit extends React.Component {
	constructor(props) {
		super(props);

		let contact = {},
			id = props.match.params.id;

		// this.retrieveContact(id);

		this.state = {
			isLoaded: false,
			addModalOpen: false,
			contact: contact,
			eContact: JSON.parse(JSON.stringify(contact)),
			contactTypes: [],
			notes: [],
			completed: false,
			noteModalOpen: false,
			noteText: "",
			createdBy: {},
			createdAt: "",
			activeItem: "1",
			taskCompleted: true,
			thirdPartyUsername: "",
			thirdPartyUser: {},
			retypeEmail: "",
			nUser: {},
		};

		this.retrieveContact(id);
		this.checkThirdPartyUserTable(id);
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	taskCompletedChange = (value) => {
		this.setState({ taskCompleted: value });
	};

	generatePw(length, uppercase, numbers) {
		let pin = generator.generate({
			length: length,
			uppercase: uppercase,
			numbers: numbers,
		});

		return pin;
	}

	toggle = (tab) => {
		if (this.state.activeItem !== tab) {
			this.setState({
				activeItem: tab,
			});
		}
	};

	retrieveContact(id) {
		return ContactService.getContact(id).then((data) => {
			this.setState({
				contact: data,
				eContact: JSON.parse(JSON.stringify(data)),
				notes: data.note,
				isLoaded: true,
			});

			this.updateThirdPartyObject(data);
		});
	}

	checkThirdPartyUserTable(id) {
		UserService.checkThirdPartyUser(id)
			.then((res) => {
				let name = "";

				if (res) {
					name = res.username;
				}

				this.setState({
					thirdPartyUser: res[0],
					thirdPartyUsername: name + " -- Pin: " + res.pin,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	componentDidMount() {
		this.retrieveContactTypes();
	}

	retrieveContactTypes() {
		return ContactService.findAllContactTypes()
			.then((res) => {
				let data = res,
					ary = [];
				data.forEach((type) => {
					ary.push({ id: type.id, name: type.name });
				});

				this.setState({ contactTypes: ary });
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	renderNoteModal() {
		return (
			<Container>
				<Modal
					centered
					size="large"
					isOpen={this.state.noteModalOpen}
					toggle={() => this.toggleNoteModalPopup()}
				>
					<ModalBody style={{ backgroundColor: "#FFFFFF" }} className="mx-3">
						<CardHeader color={"indigo"} className="form-header text-center">
							Add New Note
						</CardHeader>
						<form className=" mx-3 grey-text">
							<Input
								label="Note Text"
								value={this.state.noteText}
								onChange={this.noteChange.bind(this, "noteText")}
							/>

							<Row>
								<Col size="6">
									<Input
										type="radio"
										gap
										label={"Note"}
										checked={this.state.taskCompleted}
										onClick={this.taskCompletedChange.bind(this, true)}
										id={"noteRadio"}
									/>
								</Col>

								<Col size="6">
									<Input
										type="radio"
										gap
										label={"Task"}
										checked={!this.state.taskCompleted}
										onClick={this.taskCompletedChange.bind(this, false)}
										id={"taskRadio"}
									/>
								</Col>
							</Row>

							<br />
							{/*//we might use this in the future*/}
							{/*<label> Assigned to (optional) </label>*/}
							{/*<Select*/}
							{/*placeholder={this.state.noteAssignedTo ? this.state.noteAssignedTo.name :"Select User..."}*/}
							{/*options={this.getUserList()}*/}
							{/*onChange={this.handleNoteUserSelected.bind(this)}*/}
							{/*/>*/}
							<br />
						</form>
					</ModalBody>
					<ModalFooter>
						<Button
							floating
							size="sm"
							color={"success"}
							data-tip={"Add Note"}
							onClick={this.addNewNote.bind(this)}
						>
							<MDBIcon icon="check" style={{ fontSize: "2em" }} />
						</Button>
					</ModalFooter>
					<ReactTooltip />
				</Modal>
			</Container>
		);
	}

	toggleNoteModalPopup() {
		let currentState = this.state.noteModalOpen;
		this.setState({ noteModalOpen: !currentState });
	}

	generateNote(note, index) {
		let t = this;

		function renderCompleteButton(note) {
			if (note.completed === false || note.completed === "No") {
				return (
					<Button
						className={"pvButtonFloat"}
						color={"red darken-2"}
						onClick={() => t.updateNote(note, true)}
						size="sm"
					>
						Complete
					</Button>
				);
			}
		}

		return (
			<div className={"pvNoteContainer"} key={index}>
				<Card>
					<CardHeader color={"indigo"}>
						{note.createdBy.firstname + " " + note.createdBy.lastname}
						<span style={{ float: "right" }}>
							{GlobalFunctions.formatDate(note.createdAt)}
						</span>
					</CardHeader>
					<CardBody className={"pvNoteText"}>
						{note.text}
						<br />
						{renderCompleteButton(note)}
					</CardBody>
				</Card>
				<br />
			</div>
		);
	}

	noteChange = (property, event) => {
		this.setState({ [property]: event.target.value });
	};


	addNewNote() {
		//get the current user context that can be passed into the note DTO...BC
		const { currentUser } = this.context;

		let cb = { id: currentUser.id, name: currentUser.username };

		let currentNotes = this.state.notes,
			contact = this.state.contact;

		let note = {
			type: "ContactNote",
			text: this.state.noteText,
			createdAt: GlobalFunctions.getUTCMoment(),
			account: { id: contact.account.id, name: contact.account.name },
			accountId: contact.account.id,
			task: false,
			createdBy: cb,
			createdById: cb.id,
			completed: this.state.taskCompleted,
			patientName: null,
			contact: {
				id: contact.id,
				firstname: contact.firstname,
				lastname: contact.lastname,
				role: contact.role,
			},
			contactId: contact.id,
		};

		if (!this.state.taskCompleted) {
			note.assignedTo = contact.owner;
			note.assignedToId = contact.owner.Id;
		}

		return NoteService.createContactNote(contact.id, note)
			.then((res) => {
				res.createdBy.name = note.createdBy.name;

				this.toggleNoteModalPopup();

				currentNotes.unshift(res);
				this.setState({
					notes: currentNotes,
					noteText: "",
				});
			})
			.catch((err) => {
				//Maybe send an error to the user?...BC
			});
	}

	updateNote(note, completed) {
		let notes = this.state.notes,
			noteToReplace = notes.findIndex((n) => {
				return (n.id = note.id);
			});

		if (noteToReplace > -1) {
			notes.splice(noteToReplace, 1, note);
		}

		if (completed) {
			note.completed = completed;
		}
		note.type = "ContactNote";

		return NoteService.updateActivityNote(note)
			.then((res) => {
				this.setState({
					notes: notes,
				});
				//success, don't need to do anything...BC
			})
			.catch((err) => {
				//maybe throw an error if it fails...BC
			});
	}

	handleChange = (property, event) => {
		//update the property that the user has edited in the order...BC
		const nContact = this.state.eContact;
		nContact[property] = event.target.value;
		this.setState({ eContact: nContact });
	};

	changeContactType(contactType) {
		let oContact = this.state.eContact;
		oContact.contactType = { id: contactType.id, name: contactType.name };
		oContact.contactTypeId = contactType.id;
		this.setState({ eContact: oContact });
	}

	checkProperties(contact) {
		let shouldUpdate = true;

		if (!contact.firstname) {
			toast.error("Please add a first name");
			shouldUpdate = false;
		}

		if (!contact.lastname) {
			toast.error("Please add a last name");
			shouldUpdate = false;
		}

		if (!contact.contactType) {
			toast.error("Please select a contact type");
			shouldUpdate = false;
		}

		return shouldUpdate;
	}

	updateContact() {
		let obj = this.state.eContact,
			proceed = this.checkProperties(obj);

		if (!proceed) {
			return;
		}

		return ContactService.updateContact(obj)
			.then((data) => {
				toast.success("Contact updated successfully");

				this.setState({
					contact: data,
					eContact: JSON.parse(JSON.stringify(data)),
				});

				this.updateThirdPartyObject(data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	updateThirdPartyObject(src) {
		let pw = this.generatePw(12, true, true);
		let pin = this.generatePw(6, false, false);

		let nUser = {
			account: src.account,
			contactId: src.id,
			pin: pin,
			username: "",
			password: pw,
			retypePassword: pw,
			phone: src.phone,
			firstname: src.firstname,
			lastname: src.lastname,
			email: src.email,
			passwordResetDate: new Date(),
			role: "THIRD_PARTY_USER",
		};

		this.setState({ nUser: nUser });
	}

	resetThirdPartyUser() {
		let user = this.state.thirdPartyUser,
			pw = this.generatePw(12, true, true);

		let d = new Date();
		d.setDate(d.getDate() + 5);

		user.passwordResetDate = d;
		user.password = user.retypePassword = pw;

		UserService.updateUserAndSendEmail(user)
			.then((res) => {
				toast.success("Third party user updated successfully!");

				this.setState({
					thirdPartyUser: res,
				});
			})
			.catch((err) => {
				toast.warn(err);
			});
	}

	createThirdPartyUser() {
		//We want to make sure we use the base contact here not the edit...BC
		let nUser = this.state.nUser;

		if (nUser.email !== this.state.retypeEmail) {
			toast.warn("Emails must match");
			return;
		}

		UserService.createThirdPartyUser(nUser)
			.then((u) => {
				toast.success("Third party user created successfully!");
				this.setState({
					thirdPartyUsername: u.username + " -- Pin: " + u.pin,
					addModalOpen: false,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	cancelNewUser() {
		let pw = this.generatePw(12, true, true);

		let pin = this.generatePw(6, false, false);

		let nUser = {
			account: {},
			contactId: null,
			username: "",
			password: pw,
			retypePassword: pw,
			pin: pin,
			email: "",
			phone: "",
			firstname: "",
			lastname: "",
			role: "THIRD_PARTY_USER",
		};

		this.setState({
			nUser: nUser,
			addModalOpen: false,
			retypeEmail: "",
			selectedContact: "",
			availableContacts: [],
			availableAccounts: [],
		});
	}

	checkCreate() {
		let c = this.state.contact,
			u = this.state.nUser;

		let fn = c.firstname ? c.firstname.substring(0, 1) : "";

		u.username = fn + c.lastname + c.id;

		if (!c.firstname) {
			toast.warn("Saved contact must have a first name");
			return;
		}

		if (!c.lastname) {
			toast.warn("Saved contact must have a last name");
			return;
		}

		if (!c.email) {
			toast.warn("Saved contact must have a valid email");
			return;
		}

		if (!c.phone) {
			toast.warn("Saved contact must have a valid phone number");
			return;
		}

		this.setState({
			nUser: u,
			addModalOpen: true,
		});
	}

	// renderThirdPartyUser() {
	// 	const { currentUser } = this.context,
	// 		t = this;

	// 	function renderResetButton(u) {
	// 		if (u && u.passwordResetDate < new Date()) {
	// 			return (
	// 				<Button
	// 					floating
	// 					rounded
	// 					size="sm"
	// 					color={"blue"}
	// 					data-tip={"Resend User Email"}
	// 					onClick={() => t.resetThirdPartyUser()}
	// 				>
	// 					<MDBIcon icon="sync-alt" style={{ fontSize: "2em" }} />
	// 				</Button>
	// 			);
	// 		}
	// 	}

	// 	let role = currentUser.role,
	// 		id = currentUser.id,
	// 		oid = this.state.contact.owner ? this.state.contact.owner.id : -1;

	// 	if (id === oid) {
	// 		//this is fine, they shall pass...BC
	// 	} else if (
	// 		role !== "ADMIN" &&
	// 		role !== "SYSTEM_ADMIN" &&
	// 		role !== "SALES_MANAGER"
	// 	) {
	// 		//this user role cannot create an account.  don't show the button...BC
	// 		return <div />;
	// 	}

	// 	//if we have a third party user, display that username instead
	// 	if (this.state.thirdPartyUsername !== "") {
	// 		return (
	// 			<div
	// 				style={{ float: "right", paddingRight: "2rem", lineHeight: "50px" }}
	// 			>
	// 				Username: {this.state.thirdPartyUsername}
	// 				{renderResetButton(this.state.thirdPartyUser)}
	// 			</div>
	// 		);
	// 	}

	// 	return (
	// 		<div style={{ float: "right", paddingRight: "2rem" }}>
	// 			<Button
	// 				floating
	// 				size="medium"
	// 				color={"indigo"}
	// 				data-tip={"Create Rehab Seating Account"}
	// 				onClick={() => {
	// 					this.checkCreate();
	// 				}}
	// 			>
	// 				<MDBIcon far icon="hospital" style={{ fontSize: "2em" }} />
	// 			</Button>
	// 		</div>
	// 	);
	// }

	renderInformationSection() {
		let contact = this.state.eContact;

		return (
			<div>
				<Row style={{ flex: 1, justifyContent: "space-around" }}>
					<Col size="4">
						<Button
							color={"indigo"}
							onClick={() => {
								this.props.history.push({
									pathname: "/account/" + contact.account.id + "/detail",
								});
							}}
						>
							{contact.account ? contact.account.name : ""}
						</Button>
					</Col>

					<Col size="4">
						<Dropdown>
							<DropdownToggle caret color="primary">
								{contact.contactType
									? contact.contactType.name
									: "Contact Type"}
							</DropdownToggle>
							<DropdownMenu basic>
								{this.state.contactTypes.map((type, index) => {
									return (
										<DropdownItem
											key={index}
											onClick={this.changeContactType.bind(this, type)}
										>
											{type.name}{" "}
										</DropdownItem>
									);
								})}
							</DropdownMenu>
						</Dropdown>
					</Col>
				</Row>

				<Row>
					<Col size="4">
						<Input
							value={contact.firstname || ""}
							label="First Name"
							onChange={this.handleChange.bind(this, "firstname")}
							size="sm"
							icon="user"
						/>
					</Col>

					<Col size="4">
						<Input
							value={contact.lastname || ""}
							label="Last Name"
							onChange={this.handleChange.bind(this, "lastname")}
							size="sm"
							icon="user"
						/>
					</Col>

					<Col size="4">
						<Input
							value={contact.email || ""}
							label="Email"
							onChange={this.handleChange.bind(this, "email")}
							size="sm"
							icon="at"
						/>
					</Col>
				</Row>

				<Row>
					<Col size="4">
						<Input
							value={contact.role || ""}
							label="Role"
							onChange={this.handleChange.bind(this, "role")}
							size="sm"
							icon="id-card-o"
						/>
					</Col>

					<Col size="4">
						<Input
							value={contact.npi || ""}
							label="NPI"
							onChange={this.handleChange.bind(this, "npi")}
							size="sm"
							icon="info"
						/>
					</Col>

					<Col size="4">
						<Input
							value={contact.phone || ""}
							label="Phone"
							onChange={this.handleChange.bind(this, "phone")}
							size="sm"
							icon="phone"
						/>
					</Col>
				</Row>

				{/*{this.renderThirdPartyUser()}*/}
				<ReactTooltip />
			</div>
		);
	}

	renderNotesList() {
		let notes = this.state.notes;

		return (
			<Row>
				<Col size="12">
					<div className={"pvNotesGroupOuterContainer"}>
						<div className={"pvNotesGroupInnerContainer"}>
							{notes.map((note, idx) => {
								return this.generateNote(note, idx);
							})}
						</div>
					</div>
					<hr />
				</Col>
			</Row>
		);
	}

	renderAddUserModal() {
		return (
			<Modal
				position={"top"}
				className=""
				isOpen={this.state.addModalOpen}
				toggle={() => {
					this.setState({ addModalOpen: false });
				}}
			>
				<Card>
					<CardHeader color={"indigo"}>New Third Party User</CardHeader>
					<CardBody>
						<Row>
							<Col size="6">
								<Input
									label={"First Name"}
									value={this.state.nUser.firstname}
									disabled={true}
								/>
							</Col>

							<Col size="6">
								<Input
									label={"Last Name"}
									value={this.state.nUser.lastname}
									disabled={true}
								/>
							</Col>
						</Row>

						<Row>
							<Col size="6">
								<Input
									label={"Username"}
									disabled={true}
									value={this.state.nUser.username}
								/>
							</Col>
							<Col size="6">
								<Input
									label={"Phone"}
									disabled={true}
									value={this.state.nUser.phone}
								/>
							</Col>
						</Row>

						<Input
							label={"Email"}
							value={this.state.nUser.email}
							disabled={true}
						/>

						<Input
							label={"Retype Email"}
							value={this.state.retypeEmail}
							onChange={(e) => {
								this.setState({ retypeEmail: e.target.value });
							}}
						/>

						<Row
							style={{
								display: "flex",
								justifyContent: "space-between",
								paddingLeft: "2rem",
								paddingRight: "2rem",
							}}
						>
							<Button
								color={"success"}
								onClick={() => this.createThirdPartyUser()}
							>
								Create User
							</Button>

							<Button color={"warning"} onClick={() => this.cancelNewUser()}>
								Cancel
							</Button>
						</Row>
					</CardBody>
				</Card>
			</Modal>
		);
	}

	render() {
		let contact = this.state.contact;

		return (
			<Container>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Container>
					{this.renderAddUserModal()}
					<CardHeader color={"indigo"} style={{ textAlign: "center" }}>
						<Row>
							<Col size="5">
								<h3>{contact.firstname + " " + contact.lastname}</h3>
							</Col>
							<Col size="5">
								<h3>
									Owner: {contact.owner ? contact.owner.firstname + " " + contact.owner.lastname : "No Owner"}
								</h3>
							</Col>
							<Col size="2">
								<Button
									floating
									size="sm"
									color={"success"}
									data-tip={"Update Contact"}
									onClick={this.updateContact.bind(this)}
								>
									<MDBIcon far icon="save" style={{ fontSize: "2em" }} />
								</Button>
							</Col>
						</Row>
					</CardHeader>
				</Container>

				<Row>
					<Col md="12">
						<Nav
							tabs
							className="nav-justified"
							style={{ marginTop: 20 }}
							color={"blue-grey lighten-2"}
						>
							<NavItem>
								<NavLink
									link
									to="#"
									className={classnames({
										active: this.state.activeItem === "1",
									})}
									onClick={() => {
										this.toggle("1");
									}}
									role="tab"
								>
									<MDBIcon icon="info-circle" size="2x" />
									<br />
									Details
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink
									link
									to="#"
									className={classnames({
										active: this.state.activeItem === "2",
									})}
									onClick={() => {
										this.toggle("2");
									}}
									role="tab"
								>
									<MDBIcon icon="comments" size="2x" />
									<br />
									Notes & Tasks
								</NavLink>
							</NavItem>
						</Nav>

						<TabContent className="card" activeItem={this.state.activeItem}>
							<TabPane tabId="1" role="tabpanel">
								{this.renderInformationSection()}
							</TabPane>
							<TabPane tabId="2" role="tabpanel">
								{this.renderNotesList()}
								{this.renderNoteModal()}

								<Button
									floating
									size="sm"
									style={{ float: "right" }}
									color={"secondary"}
									data-tip={"Add Note / Task"}
									onClick={() => {
										this.toggleNoteModalPopup();
									}}
								>
									<MDBIcon icon="plus" style={{ fontSize: "2em" }} />
								</Button>
								<ReactTooltip />
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</Container>
		);
	}
}
