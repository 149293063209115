import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import Queue from "../../Utility/Crud/Queue";
import PropTypes from "prop-types";
import Term from "../../Utility/Crud/Term";

export default class TileQueue extends Tile {
    static contextTypes = {
        termsIndexed: PropTypes.object,
    };

    /**
      * @returns {string} The Queue name.
      */
    getTitle() {
        return this.props.queue ? Queue.getDisplayName(this.props.queue) : "None";
    }

    /**
      * @returns {string} The Queue activityType.
      */
    getSubtitle() {
        const subtitleParts = [];
        subtitleParts.push(Term.getDisplayName(
            this.context.termsIndexed[this.props.queue.queueTypeTermId]
        ));

        const thresholdParts = [];
        if (this.props.queue.thresholdSize !== null) {
            thresholdParts.push(this.props.queue.thresholdSize);
        }
        if (this.props.queue.thresholdAgeHours !== null) {
            thresholdParts.push(
                `${this.props.queue.thresholdAgeHours}${this.props.queue.thresholdAgeHoursIsBusiness === true ? "b" : ""}h`
            );
        }

        if (thresholdParts.length > 0) {
            subtitleParts.push(thresholdParts.join("/"))
        }

        return subtitleParts.join(" • ");
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.queue ? Queue.getDisplayName(this.props.queue) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.queue ? Queue.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return Queue.getIcon()
    }
}
