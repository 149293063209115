import React from "react";
import { Card } from "mdbreact";
import { toast } from "react-toastify";
import "./deliveries.css";
import PropTypes from "prop-types";
import StreetMap from "./StreetMap";
import locationService from "../Security/Locations/locationsService";
import OrderService from "../Security/OrderService/orderService";

const colorGroups = [
	{ tech: null, stroke: [0, 0, 0, 0.5], fill: [75, 192, 192, 1] },
	{ tech: null, stroke: [0, 0, 0, 0.5], fill: [75, 90, 192, 1] },
	{ tech: null, stroke: [0, 0, 0, 0.5], fill: [192, 75, 115, 1] },
	{ tech: null, stroke: [0, 0, 0, 0.5], fill: [75, 188, 66, 1] },
	{ tech: null, stroke: [0, 0, 0, 0.5], fill: [147, 81, 160, 1] },
	{ tech: null, stroke: [0, 0, 0, 0.5], fill: [244, 171, 68, 1] },
	{ tech: null, stroke: [0, 0, 0, 0.5], fill: [191, 244, 68, 1] },
	{ tech: null, stroke: [0, 0, 0, 0.5], fill: [68, 244, 171, 1] },
	{ tech: null, stroke: [0, 0, 0, 0.5], fill: [33, 75, 89, 1] },
	{ tech: null, stroke: [0, 0, 0, 0.5], fill: [81, 97, 160, 1] },
];

export default class MapComponentWindow extends React.Component {
	constructor(props) {
		super(props);

		let id = null;

		if (props.match && props.match.params && props.match.params.id) {
			let tid = props.match.params.id;
			if (tid !== undefined && tid !== "undefined") {
				id = tid;
			}
		}

		this.state = {
			isLoaded: false,
			techScheduleLoaded: false,
			rootData: [],

			//dataNotScheduled: dataNotScheduled,
			//dataApproved: dataApproved,
			//dataEquipmentOrdered: dataEquipmentOrdered,

			dataTechSchedule: [],
			userLocations: [],
			selectedLocationIds: id,
			locationList: [],
			//startDate: sd.toLocaleDateString(),
			//endDate: ed.toLocaleDateString(),
			editRow: {},
			toggleModal: false,
			errorMessage: "",
			deliveryWindow: null,
			mapWindow: null,
			tooltipContent: "",
			geoLocations: [],
			noteToggle: false,
			scheduledStyle: [],
			nNote: "",
			TabsOneActiveItem: "4",
			techs: [],

			hiddenOrders: [],
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allLocations: PropTypes.array,
		productTypes: PropTypes.array,
		techs: PropTypes.array,
		reasons: PropTypes.array,
	};

	componentDidMount() {
		if (this.state.selectedLocationIds) {
			this.getUserLocations();
			this.retrieveDeliveries();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {}

	getUserLocations() {
		const { allLocations } = this.context;
		let id = this.state.selectedLocationIds;

		if (id && allLocations.length > 0) {
			let loc = allLocations.find((lo) => {
				return lo.value === id;
			});
			if (loc && loc.locVal.address != null) {
				let lo = loc.locVal;
				let addr = lo.address; // + " " + lo.city + ", " + lo.state + " " + lo.zipCode;
				addr += lo.city === null ? "" : " " + lo.city;
				addr += lo.state === null ? "" : ", " + lo.state;
				addr += lo.zipCode === null ? "" : " " + lo.zipCode;

				window.mapComponent.setOfficeLocation(
					addr,
					loc.locVal.latitude || null,
					loc.locVal.longitude || null
				);
			}
		} else if (id) {
			locationService.findAllLocations()
			.then((res) => {
				let o = res.find((x) => x.id.toString() === id.toString());
				if (o) {
					let addr =
						(o.address ? o.address + " " : "") +
						(o.city ? o.city + ", " : "") +
						(o.state ? o.state + " " : "") +
						(o.zipCode ? o.zipCode : "");
					window.mapComponent.setOfficeLocation(addr, o.latitude, o.longitude);
				}
			});
		}

		this.setState({
			locationList: allLocations,
		});
	}

	retrieveDeliveries() {
		let ids = this.state.selectedLocationIds;

		if (ids.length > 0) {
			OrderService.getDeliveriesForLocation(ids)
				.then((res) => {
					this.buildDataSets(res.deliveries);
				})
				.catch((err) => {
					console.log(err);
					toast.error("An error occurred getting the deliveries");
				});
		}
	}

	buildDataSets(data) {
		let rest = data.orders.filter((bl) => {
			return bl.reason && bl.reasonId !== 8;
		});

		data.techs.forEach((tech, index) => {
			let currStyle = colorGroups[index];
			currStyle.tech = tech.id;
			currStyle.name = tech.firstname + " " + tech.lastname;
			currStyle.visible = true;

			window.mapComponent.setTechStyle(currStyle);
		});

		window.mapComponent.setOrderLocations(rest);

		this.setState({
			rootData: data,
			isLoaded: true,
		});
	}

	renderMap() {
		return <StreetMap />;
	}

	render() {
		return (
			<Card style={{ width: "90vw", height: "80vh", margin: "auto" }}>
				<div id={"mapHost"} style={{ height: "100%", width: "100%" }}>
					{this.renderMap()}
				</div>
			</Card>
		);
	}
}
