import Crud from "../Crud";
import MUIBrownColor from "@mui/material/colors/brown";

/**
 * Static helper functions related to ProductSubTypes.
 */
export default class ProductSubType extends Crud {

    /**
     * @param {object} productSubType The productSubType object.
     * @returns The display name of the productSubType.
     */
    static getDisplayName(productSubType) {
        return productSubType ? productSubType.name : null;
    }

    /**
     * Determines if a productSubType matches a search query.
     *
     * @param {object} productSubType The productSubType to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the productSubType matches the query.
     */
    static match(productSubType, query) {
        return (
            (productSubType.name !== null && productSubType.name.toLowerCase().includes(query)) ||
            productSubType.id === +query
        );
    }

    /**
     * @param {array} productSubTypes The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(productSubTypes) {
        return productSubTypes.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIBrownColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Inventory";
    }
}