// Core
import React, { Component } from "react";
import PropTypes from "prop-types";
// import ClearCache from "react-clear-cache";
import { Redirect, BrowserRouter as Router, Route } from "react-router-dom";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { MainProvider } from './MainContext';
import Routes from "./Routes";
import Setting from "./Utility/Setting";

// Seating
import PODeliveryUpdate from "./Seating/PurchaseQueue/PODeliveryUpdate";
import InventoryRequest from "./Seating/PurchaseQueue/inventoryRequest";
import AccountService from "./Seating/Security/AccountService/accountService";
import ActivityService from "./Seating/Security/ActivityService/activityService";
import ComplaintService from "./Seating/Security/ComplaintService/ComplaintService";
import ContactService from "./Seating/Security/ContactService/contactService";
import InsuranceService from "./Seating/Security/InsuranceService/insuranceService";
import LocationService from "./Seating/Security/Locations/locationsService";
import MarketingService from "./Seating/Security/MarketingService/marketingService";
import orderService from "./Seating/Security/OrderService/orderService";
import ProductMatrixService from "./Seating/Security/ProdcutMatrixEntryService/prodcutMatrixEntryService";
import ProductSubTypeService from "./Seating/Security/ProductService/productSubTypeService";
import ProductTypeService from "./Seating/Security/ProductService/productTypeService";
import userRoleService from "./Seating/Security/UserRoleService/userRoleService";
import UserService from "./Seating/Security/UserService/userService";
import VendorService from "./Seating/Security/VendorService/vendorService";
import ColorService from "./Seating/Security/ColorService/colorService";
import DepartmentService from "./Seating/Security/DepartmentService/departmentService";
import TeamService from "./Seating/Security/TeamService/teamService";
import TermService from "./Seating/Security/TermService/termService";
import TeamUserService from "./Seating/Security/TeamUserService/teamUserService";
import TeamQueueService from "./Seating/Security/TeamQueueService/teamQueueService";
import QueueRouteService from "./Seating/Security/QueueRouteService/queueRouteService";
import QueueService from "./Seating/Security/QueueService/queueService";
import OrderActivityTypeService from "./Seating/Security/OrderActivityTypeService/orderActivityTypeService";
import API from "./Seating/Security/api";
import PatientCommunicationService from "./Seating/Settings/PatientCommunicationService";
import DocumentTypesService from "./Seating/Settings/documentTypesService";
import ReasonService from "./Seating/Settings/reasonService";
import RegionService from "./Seating/Security/RegionService/regionService";
import ReverseQualityService from "./Seating/Settings/reverseQualityService";
import StateSalesTaxService from "./Seating/Settings/stateSalesTaxService";
import BaseUnitTypeService from "./Seating/Security/BaseUnitTypeService/baseUnitTypeService";
import BaseUnitService from "./Seating/Security/BaseUnitService/baseUnitService";

// UI
import { AiOutlineSearch } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import background from "./images/blue_background.png";
import MUIBackdrop from '@mui/material/Backdrop';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Footer,
    FormInline,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol, MDBCollapse, MDBDropdownItem, MDBIcon,
    MDBInput,
    MDBModal,
    MDBModalHeader,
    MDBRow,
    ModalBody,
    NavItem,
    NavLink,
    Navbar,
    NavbarBrand,
    NavbarNav,
    toast,
} from "mdbreact";
import "../node_modules/open-iconic/font/css/open-iconic-bootstrap.css";
import "./index.css";
import MUIPaper from "@mui/material/Paper";
import MUISnackbar from '@mui/material/Snackbar';
import MUIAlert from '@mui/material/Alert';
import MUIButton from '@mui/material/Button';
import MUISwitch from '@mui/material/Switch';
import MUIFormControlLabel from '@mui/material/FormControlLabel';
import { SnackbarProvider as NotistackSnackbarProvider } from "notistack";

// Components
import Progress from "./Component/Progress";
import QualityAssuranceScorecard from "./Components/QualityAssuranceScorecard";
import UpdateNotes from "./Components/UpdateNotes";
import UserMetricCollapse from "./Components/UserMetrics/UserMetricCollapse";
import SecurityProvider from "./Component/SecurityProvider";

import * as msal from "@azure/msal-browser";
import * as Sentry from "@sentry/browser";
import moment from "moment";

require("moment-timezone");

class App extends Component {
    constructor(props) {
        super(props);

        let cu = window.sessionStorage.getItem("currentUser");

        if (cu != null) {
            cu = JSON.parse(cu);

            Sentry.setUser({
                id: cu.id,
                username: cu.username.toLowerCase(),
                email: cu.email.toLowerCase()
            });
        }


        this.state = {
            pca: null,
            collapseID: "",
            allThemes: [
                "Gorgeous Contrast",
                "Alternate Contrast",
                "Corporate",
                "Bold",
                "Plum",
                "Indigo",
                "Umber",
            ],
            currentUser: cu || {},
            company: "",
            companyName: "",
            poDeliveryUpdate: false,
            inventoryRequestOpen: false,
            insuranceTypes: [],
            insuranceSubTypes: [],
            productTypes: [],
            productSubTypes: [],
            searchText: "",
            reasons: [],
            therapists: [],
            salesReps: [],
            accounts: [],
            myAccounts: [],
            allLocations: [],
            internalLocations: [],
            allRegions: [],
            allUsers: [],
            userRefs: [],
            allActiveUsers: [],
            allLiaisons: [],
            allPCR: [],
            allAtp: [],
            thirdPartyUsers: [],
            thirdPartyUsersLoaded: false,
            cardTypes: [],
            expenseTypes: [],
            documentTypes: [],
            contactTypes: [],
            accountTypes: [],
            patientCommunicationTriggerDefs: [],
            techs: [],
            marketingCampaigns: [],
            complaintCategories: [],
            complaintReasons: [],
            allVendors: [],
            allBaseUnits: [],
            allBaseUnitTypes: [],
            productOverrides: [],
            fullLiaisonKeys: [],
            orderModalOpen: false,
            orderErrorMessage: "",
            orderFound: false,
            orderId: "",
            stateSalesTax: [],
            reverseQualityCategories: [],
            reverseQualityCategoriesRefs: [],
            reverseQualityReasons: [],
            assuranceModalOpen: false,
            rqModalOpen: false,
            rqObject: {},
            rqOrderId: "",
            userMetricsVisible: false,
            userRoleQuotas: [],
            notLoggedIn: cu == null,
            updateNotesModalOpen: false,
            mslaAccount: null,

            /**
             * This section is for new context entries that are now indexed by
             * their primary key. It may include some duplications of the above
             * data for backwards compatability.
             *
             * Nomenclature is the plural name of the object followed by the
             * word "indexed".
             *
             * Note that this should be limited to one entry per object type.
             * For example, do not use "allUsers" and "allActiveUsers". Those
             * distinctions should be handled with appropriate filtering.
             */
            updateIndexed: (key, data) => {
                this.setState(prevState => ({
                    [key]: {
                        ...prevState[key],
                        [data.id]: data
                    }
                }));
                window.sessionStorage.removeItem(key);
            },
            deleteIndexed: (key, id) => {
                this.setState(prevState => {
                    const { [id]: deleted, ...rest } = prevState[key];
                    return { [key]: rest };
                });
                window.sessionStorage.removeItem(key);
            },

            termsIndexed: {},
            usersIndexed: {},
            departmentsIndexed: {},
            teamsIndexed: {},
            teamUsersIndexed: {},
            teamQueuesIndexed: {},
            queueRoutesIndexed: {},
            queuesIndexed: {},
            orderActivityTypesIndexed: {},
            issueStatusesIndexed: {},
            orderStatusesIndexed: {},
            orderStatusReasonsIndexed: {},
            insuranceTypesIndexed: {},
            insuranceSubTypesIndexed: {},
            serviceReasonsIndexed: {},
            baseUnitTypesIndexed: {},
            baseUnitsIndexed: {},
            vendorsIndexed: {},
            colorsIndexed: {},
            locationsIndexed: {},
            regionsIndexed: {},
            productTypesIndexed: {},
            productSubTypesIndexed: {},
            issueCategoriesIndexed: {},
            issueReasonsIndexed: {},
            newVersionAvailable: false,
            manifestVersion: null,
        };

        // Check for a new version of the app by parsing meta.json on the server.
        const checkForUpdate = async () => {
            try {
                const response = await fetch('/manifest.json', { cache: 'no-store' });
                const manifest = await response.json();

                if (manifest.version) {
                    if (
                        window.salesPilot.meta.version.localeCompare(
                            manifest.version,
                            undefined,
                            {
                                numeric: true,
                                sensitivity: 'base'
                            }
                        ) < 0
                    ) {
                        this.setState({
                            newVersionAvailable: true,
                            manifestVersion: manifest.version
                        });
                    }
                }
            }
            catch (e) {
                // For some reason this fetch fails a lot. Not sure if it's just
                // because it's happening right on page load or what. For now
                // ignoring errors. If this check does not fix the version lag
                // we're seeing I'll re-enable the exception here and deal with
                // it. - JZ
            }
        };

        // Check for updates every hour
        window.setInterval(checkForUpdate, 3600000);
        checkForUpdate();
    }



    /**
     * Index an array of rows by ID.
     *
     * @param {array} rows An array of rows to index. Each row *must* have an
     * "id" property.
     * @returns {object} An object containing the indexed rows.
     */
    indexById(rows) {
        const indexedById = {};
        rows.forEach((row) => {
            indexedById[row.id] = row;
        });
        return indexedById;
    }

    static childContextTypes = {
        userFunction: () => {
        },
        userDataFunction: () => {
        },
        companyFunction: () => {
        },
        //getPcaFunction: () => { },
        //logoutFunction: () => { },
        //configurePcaFunction: (config) => {},
        setContextItem: (key, value) => { },
        company: PropTypes.string,
        companyName: PropTypes.string,
        currentUser: PropTypes.object,
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        productTypes: PropTypes.array,
        productSubTypes: PropTypes.array,
        reasons: PropTypes.array,
        therapists: PropTypes.array,
        salesReps: PropTypes.array,
        accounts: PropTypes.array,
        myAccounts: PropTypes.array,
        allLocations: PropTypes.array,
        internalLocations: PropTypes.array,
        allRegions: PropTypes.array,
        allUsers: PropTypes.array,
        userRefs: PropTypes.array,
        allActiveUsers: PropTypes.array,
        allLiaisons: PropTypes.array,
        allPCR: PropTypes.array,
        allAtp: PropTypes.array,
        thirdPartyUsers: PropTypes.array,
        thirdPartyUsersLoaded: PropTypes.bool,
        patientCommunicationTriggerDefs: PropTypes.array,
        cardTypes: PropTypes.array,
        expenseTypes: PropTypes.array,
        documentTypes: PropTypes.array,
        contactTypes: PropTypes.array,
        accountTypes: PropTypes.array,
        techs: PropTypes.array,
        marketingCampaigns: PropTypes.array,
        complaintCategories: PropTypes.array,
        complaintReasons: PropTypes.array,
        allVendors: PropTypes.array,
        allBaseUnits: PropTypes.array,
        allBaseUnitTypes: PropTypes.array,
        productOverrides: PropTypes.array,
        fullLiaisonKeys: PropTypes.array,
        timezoneVariation: PropTypes.number,
        stateSalesTax: PropTypes.array,
        reverseQualityCategories: PropTypes.array,
        reverseQualityReasons: PropTypes.array,
        userRoleQuotas: PropTypes.array,

        // Context entries indexed by primary key.
        updateIndexed: PropTypes.func,
        deleteIndexed: PropTypes.func,
        termsIndexed: PropTypes.object,
        usersIndexed: PropTypes.object,
        departmentsIndexed: PropTypes.object,
        teamsIndexed: PropTypes.object,
        teamUsersIndexed: PropTypes.object,
        teamQueuesIndexed: PropTypes.object,
        queueRoutesIndexed: PropTypes.object,
        queuesIndexed: PropTypes.object,
        orderActivityTypesIndexed: PropTypes.object,
        issueStatusesIndexed: PropTypes.object,
        orderStatusesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
        insuranceTypesIndexed: PropTypes.object,
        insuranceSubTypesIndexed: PropTypes.object,
        serviceReasonsIndexed: PropTypes.object,
        baseUnitTypesIndexed: PropTypes.object,
        baseUnitsIndexed: PropTypes.object,
        vendorsIndexed: PropTypes.object,
        colorsIndexed: PropTypes.object,
        locationsIndexed: PropTypes.object,
        regionsIndexed: PropTypes.object,
        productTypesIndexed: PropTypes.object,
        productSubTypesIndexed: PropTypes.object,
        issueCategoriesIndexed: PropTypes.object,
        issueReasonsIndexed: PropTypes.object,
    };

    setUserDataFunction(user, msalAcct) {
        this.setState({ currentUser: user });

        if (user) {
            Sentry.setUser({
                id: user.id,
                username: user.username.toLowerCase(),
                email: user.email.toLowerCase()
            });
            this.setState({ notLoggedIn: false, msalAccount: msalAcct });
            window.sessionStorage.setItem("currentUser", JSON.stringify(user));
            this.getAll();
        } else {
            this.setState({ notLoggedIn: true, msalAccount: null });
            window.sessionStorage.removeItem("currentUser");
            this.clearState();
            Sentry.setUser(null);
        }
    }

    setUserFunction(user) {
        this.setState({ currentUser: user });
        if (user) {
            this.setState({ notLoggedIn: false });
            window.sessionStorage.setItem("currentUser", JSON.stringify(user));
            this.getAll();
        } else {
            this.setState({ notLoggedIn: true });
            window.sessionStorage.removeItem("currentUser");
            this.clearState();
        }
    }

    setCompanyFunction(friendlyCompany, company) {
        window.sessionStorage.setItem("companyName", company);
        this.setState({
            companyName: company,
            company: friendlyCompany,
        });

        Sentry.setTag("company", company ? company.toLowerCase() : null);
    }

    //getPcaFunction() {
    //    return this.state.pca;
    //}

    //configurePcaFunction(company) {
    //    var pca = this.state.pca;

    //    if (company && company.value.authConfig) {
    //        pca = new msal.PublicClientApplication(company.value.authConfig);
    //        this.setState({
    //            company: company.value.name,
    //            companyLabel: company.name,
    //        })
    //    }

    //    this.setState({
    //        pca: pca,
    //    })
    //}

    //logoutFunction() {
    //    var pca = this.state.pca,
    //        user = this.state.currentUser;

    //    if (user) {

    //    }
    //}

    setContextItem(key, value) {
        console.log('setContextItem ' + key);
        this.setState({
            [key]: value,
        });

        if (key === "allUsers") {
            this.sortUsers(value);
        }
    }

    getChildContext() {
        return {
            userFunction: this.setUserFunction.bind(this),
            userDataFunction: this.setUserDataFunction.bind(this),
            companyFunction: this.setCompanyFunction.bind(this),
            setContextItem: this.setContextItem.bind(this),
            company: this.state.company,
            companyName: this.state.companyName,
            currentUser: this.state.currentUser,
            insuranceTypes: this.state.insuranceTypes,
            insuranceSubTypes: this.state.insuranceSubTypes,
            productTypes: this.state.productTypes,
            productSubTypes: this.state.productSubTypes,
            reasons: this.state.reasons,
            therapists: this.state.therapists,
            salesReps: this.state.salesReps,
            accounts: this.state.accounts,
            myAccounts: this.state.myAccounts,
            allLocations: this.state.allLocations,
            internalLocations: this.state.internalLocations,
            allRegions: this.state.allRegions,
            allUsers: this.state.allUsers,
            allActiveUsers: this.state.allActiveUsers,
            userRefs: this.state.userRefs,
            allLiaisons: this.state.allLiaisons,
            allPCR: this.state.allPCR,
            allAtp: this.state.allAtp,
            thirdPartyUsers: this.state.thirdPartyUsers,
            thirdPartyUsersLoaded: this.state.thirdPartyUsersLoaded,
            patientCommunicationTriggerDefs: this.state.patientCommunicationTriggerDefs,
            cardTypes: this.state.cardTypes,
            expenseTypes: this.state.expenseTypes,
            documentTypes: this.state.documentTypes,
            contactTypes: this.state.contactTypes,
            accountTypes: this.state.accountTypes,
            techs: this.state.techs,
            marketingCampaigns: this.state.marketingCampaigns,
            complaintCategories: this.state.complaintCategories,
            complaintReasons: this.state.complaintReasons,
            allVendors: this.state.allVendors,
            allBaseUnits: this.state.allBaseUnits,
            allBaseUnitTypes: this.state.allBaseUnitTypes,
            productOverrides: this.state.productOverrides,
            fullLiaisonKeys: this.state.fullLiaisonKeys,
            timezoneVariation: this.state.timezoneVariation,
            stateSalesTax: this.state.stateSalesTax,
            reverseQualityCategories: this.state.reverseQualityCategories,
            reverseQualityReasons: this.state.reverseQualityReasons,
            userRoleQuotas: this.state.userRoleQuotas,

            // Context entries indexed by primary key.
            updateIndexed: this.state.updateIndexed,
            deleteIndexed: this.state.deleteIndexed,
            termsIndexed: this.state.termsIndexed,
            usersIndexed: this.state.usersIndexed,
            departmentsIndexed: this.state.departmentsIndexed,
            teamsIndexed: this.state.teamsIndexed,
            teamUsersIndexed: this.state.teamUsersIndexed,
            teamQueuesIndexed: this.state.teamQueuesIndexed,
            queueRoutesIndexed: this.state.queueRoutesIndexed,
            queuesIndexed: this.state.queuesIndexed,
            orderActivityTypesIndexed: this.state.orderActivityTypesIndexed,
            issueStatusesIndexed: this.state.issueStatusesIndexed,
            orderStatusesIndexed: this.state.orderStatusesIndexed,
            orderStatusReasonsIndexed: this.state.orderStatusReasonsIndexed,
            insuranceTypesIndexed: this.state.insuranceTypesIndexed,
            insuranceSubTypesIndexed: this.state.insuranceSubTypesIndexed,
            serviceReasonsIndexed: this.state.serviceReasonsIndexed,
            baseUnitTypesIndexed: this.state.baseUnitTypesIndexed,
            baseUnitsIndexed: this.state.baseUnitsIndexed,
            vendorsIndexed: this.state.vendorsIndexed,
            colorsIndexed: this.state.colorsIndexed,
            locationsIndexed: this.state.locationsIndexed,
            regionsIndexed: this.state.regionsIndexed,
            productTypesIndexed: this.state.productTypesIndexed,
            productSubTypesIndexed: this.state.productSubTypesIndexed,
            issueCategoriesIndexed: this.state.issueCategoriesIndexed,
            issueReasonsIndexed: this.state.issueReasonsIndexed,
        };
    }

    //region context entities
    // async getInsuranceTypes() {
    //     const insuranceTypes = window.sessionStorage.getItem('insuranceTypes');
    //     if (insuranceTypes !== null) {
    //         return new Promise((resolve) => {
    //             resolve({ insuranceTypes: JSON.parse(insuranceTypes) });
    //         });
    //     }

    //     return InsuranceService.findAllInsurance()
    //         .then((insuranceTypes) => {
    //             window.sessionStorage.setItem("insuranceTypes", JSON.stringify(insuranceTypes));
    //             return ({ insuranceTypes: insuranceTypes });
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }




    async getInsuranceTypes() {
        const insuranceTypes = window.sessionStorage.getItem('insuranceTypes');
        const insuranceTypesIndexed = window.sessionStorage.getItem('insuranceTypesIndexed');
        if (insuranceTypes !== null && insuranceTypesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    insuranceTypes: JSON.parse(insuranceTypes),
                    insuranceTypesIndexed: JSON.parse(insuranceTypesIndexed),
                });
            });
        }

        return InsuranceService.findAllInsurance()
            .then((insuranceTypes) => {
                const insuranceTypesIndexed = this.indexById(insuranceTypes);

                window.sessionStorage.setItem("insuranceTypes", JSON.stringify(insuranceTypes));
                window.sessionStorage.setItem("insuranceTypesIndexed", JSON.stringify(insuranceTypesIndexed));
                return ({
                    insuranceTypes: insuranceTypes,
                    insuranceTypesIndexed: insuranceTypesIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getInsuranceSubTypes() {
        const insuranceSubTypes = window.sessionStorage.getItem('insuranceSubTypes');
        const insuranceSubTypesIndexed = window.sessionStorage.getItem('insuranceSubTypesIndexed');
        if (insuranceSubTypes !== null && insuranceSubTypesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    insuranceSubTypes: JSON.parse(insuranceSubTypes),
                    insuranceSubTypesIndexed: JSON.parse(insuranceSubTypesIndexed),
                });
            });
        }

        return InsuranceService.findAllSubInsurance()
            .then((types) => {
                const insuranceSubTypesIndexed = this.indexById(types);

                let insuranceSubTypes = types.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                window.sessionStorage.setItem("insuranceSubTypes", JSON.stringify(insuranceSubTypes));
                window.sessionStorage.setItem("insuranceSubTypesIndexed", JSON.stringify(insuranceSubTypesIndexed));
                return ({
                    insuranceSubTypes: insuranceSubTypes,
                    insuranceSubTypesIndexed: insuranceSubTypesIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getProductTypes() {
        const productTypes = window.sessionStorage.getItem('productTypes');
        const productTypesIndexed = window.sessionStorage.getItem('productTypesIndexed');
        if (productTypes !== null && productTypesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    productTypes: JSON.parse(productTypes),
                    productTypesIndexed: JSON.parse(productTypesIndexed),
                });
            });
        }

        return ProductTypeService.getAllProducts()
            .then((productTypes) => {
                const productTypesIndexed = this.indexById(productTypes);

                window.sessionStorage.setItem("productTypes", JSON.stringify(productTypes));
                window.sessionStorage.setItem("productTypesIndexed", JSON.stringify(productTypesIndexed));
                return ({
                    productTypes: productTypes,
                    productTypesIndexed: productTypesIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getProductSubTypes() {
        const productSubTypes = window.sessionStorage.getItem('productSubTypes');
        const productSubTypesIndexed = window.sessionStorage.getItem('productSubTypesIndexed');
        if (productSubTypes !== null && productSubTypesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    productSubTypes: JSON.parse(productSubTypes),
                    productSubTypesIndexed: JSON.parse(productSubTypesIndexed),
                });
            });
        }

        return ProductSubTypeService.getAllProductSubTypes()
            .then((productSubTypes) => {
                const productSubTypesIndexed = this.indexById(productSubTypes);

                window.sessionStorage.setItem("productSubTypes", JSON.stringify(productSubTypes));
                window.sessionStorage.setItem("productSubTypesIndexed", JSON.stringify(productSubTypesIndexed));
                return ({
                    productSubTypes: productSubTypes,
                    productSubTypesIndexed: productSubTypesIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }



    // async getInsuranceTypes() {
    //     const insuranceTypes = window.sessionStorage.getItem('insuranceTypes');
    //     const insuranceTypesIndexed = window.sessionStorage.getItem('insuranceTypesIndexed');
    //     if (insuranceTypes !== null && insuranceTypesIndexed !== null) {
    //         return new Promise((resolve) => {
    //             resolve({
    //                 insuranceTypes: JSON.parse(insuranceTypes),
    //                 insuranceTypesIndexed: JSON.parse(insuranceTypesIndexed),
    //             });
    //         });
    //     }

    //     return InsuranceService.findAllInsurance()
    //         .then((insuranceTypes) => {
    //             const insuranceTypesIndexed = this.indexById(insuranceTypes);

    //             window.sessionStorage.setItem("insuranceTypes", JSON.stringify(insuranceTypes));
    //             window.sessionStorage.setItem("insuranceTypesIndexed", JSON.stringify(insuranceTypesIndexed));
    //             return ({
    //                 insuranceTypes: insuranceTypes,
    //                 insuranceTypesIndexed: insuranceTypesIndexed,
    //             });
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }



    async getLocations() {
        const allLocations = window.sessionStorage.getItem('allLocations');
        const locationsIndexed = window.sessionStorage.getItem('locationsIndexed');
        const internalLocations = window.sessionStorage.getItem('internalLocations');
        if (allLocations !== null && internalLocations !== null && locationsIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    allLocations: JSON.parse(allLocations),
                    internalLocations: JSON.parse(internalLocations),
                    locationsIndexed: JSON.parse(locationsIndexed)
                });
            });
        }

        return LocationService.findAllLocations()
            .then((locations) => {
                const locationsIndexed = this.indexById(locations);

                var locs = locations.sort((a, b) => {
                    return a.name < b.name ? -1 : 1;
                });
                const allLocations = locs.sort((a, b) => a.name > b.name ? 1 : -1);
                const internalLocations = locs.filter(x => x.type !== null && x.type === 0).sort((a, b) => a.name > b.name ? 1 : -1);

                window.sessionStorage.setItem("allLocations", JSON.stringify(allLocations));
                window.sessionStorage.setItem("locationsIndexed", JSON.stringify(locationsIndexed));
                window.sessionStorage.setItem("internalLocations", JSON.stringify(internalLocations));

                return ({
                    allLocations: allLocations,
                    internalLocations: internalLocations,
                    locationsIndexed: locationsIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getRegions() {
        const allRegions = window.sessionStorage.getItem('allRegions');
        const regionsIndexed = window.sessionStorage.getItem('regionsIndexed');
        if (allRegions !== null && regionsIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    allRegions: JSON.parse(allRegions),
                    regionsIndexed: JSON.parse(regionsIndexed),
                });
            });
        }

        return RegionService.getAllRegions()
            .then((allRegions) => {
                const regionsIndexed = this.indexById(allRegions);

                window.sessionStorage.setItem("allRegions", JSON.stringify(allRegions));
                window.sessionStorage.setItem("regionsIndexed", JSON.stringify(regionsIndexed));
                return ({
                    allRegions: allRegions,
                    regionsIndexed: regionsIndexed,
                });
            })
            .catch(err => {
                console.log(err);
            })
    }

    sortUsers(users) {
        let cu = this.state.currentUser;
        //let locations = cu.location || [];
        let atp = [],
            thera = [],
            active = [],
            liaisons = [],
            techs = [],
            third = [],
            pcr = [],
            reps = cu.role === "SALES" ? [cu] : [],
            ary = [];

        users.map(x => {
            if (x.isAtp === true) {
                atp.push(x);
            }
            if (x.active === true) {
                active.push(x);
            }
            if (x.deleted !== true) {
                switch (x.role) {
                    case "THERAPIST":
                        thera.push(x);
                        break;
                    case "SALES_LIAISON":
                        //case "SALES_LIAISON_MANAGER":
                        liaisons.push(x);
                        break;
                    case "OFFICE_MANAGER":
                        techs.push(x);
                        pcr.push(x);
                        break;
                    case "AREA_MANAGER":
                    case "TECHNICIAN":
                        techs.push(x);
                        break;
                    case "PATIENT_CARE_REP":
                    case "OFFICE_SUPPORT":
                        pcr.push(x);
                        break;
                    case "SALES_MANAGER":
                        pcr.push(x);
                        if (cu.role !== "SALES" && x.deleted !== true) {
                            reps.push(x);
                        }
                        break;
                    case "SALES":
                    case "ADMIN":
                        if (cu.role !== "SALES" && x.deleted !== true) {
                            reps.push(x);
                        }
                        break;
                    default:
                        break;
                }
            }
            ary.push({
                label: x.firstname + " " + x.lastname,
                value: { id: x.id, name: x.username },
            });

            return x;
        });

        this.setState({
            allUsers: users,
            allAtp: atp,
            userRefs: ary,
            allActiveUsers: active,
            allLiaisons: liaisons,
            techs: techs,
            salesReps: reps,
            therapists: thera,
            thirdPartyUsers: third,
            thirdPartyUsersLoaded: true,
            allPCR: pcr,
        });
        this.populateLiaisonKeys(liaisons);
    }

    async getAllUsers() {
        const usersIndexed = window.sessionStorage.getItem('usersIndexed');
        if (usersIndexed !== null) {
            return new Promise((resolve) => {
                resolve({ usersIndexed: JSON.parse(usersIndexed) });
            });
        }

        return UserService.getAllUsers()
            .then((res) => {
                var resp = res.sort((a, b) => {
                    if (a.firstname && a.lastname && b.firstname && b.lastname)
                        return a.firstname.trim() + " " + a.lastname.trim() > b.firstname.trim() + " " + b.lastname.trim() ? 1 : -1
                    else
                        return 1;
                });

                const usersIndexed = this.indexById(resp);
                window.sessionStorage.setItem("usersIndexed", JSON.stringify(usersIndexed));
                return ({ "usersIndexed": usersIndexed });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    populateLiaisonKeys(allLiaisons) {
        let aryDates = [],
            liaisonsKeys = [],
            startDate = new Date();

        let currentTzo = new Date().getTimezoneOffset(),
            id = moment.tz(new Date(), "America/Indiana/Indianapolis"),
            idTzo = id._offset,
            variation = 0;

        //subtract indiana's timezone offset...BC (it is negative 300, so add negative)
        currentTzo += idTzo;

        //get the offset in hours...BC
        if (currentTzo !== 0) {
            variation = currentTzo / 60;
        }

        let start = 9,
            end = 16;

        //they will have a lot less choices to schedule a meeting based on their timezone...BC
        if (variation !== 0) {
            start = 9 - variation;
            end = 16 - variation;
        }

        for (let i = 0; i <= 5; i++) {
            let currentDate = new Date();
            currentDate.setDate(startDate.getDate() + i);

            if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
                continue;
            }

            let mString =
                (currentDate.getMonth() + 1).toString() +
                "/" +
                currentDate.getDate().toString() +
                "/" +
                currentDate.getFullYear().toString() +
                " | ";

            //this should generate a keystring of DDMMYYYYTTTT
            for (let i = start; i <= end; i++) {
                aryDates.push(mString + i + ":00:00--");
            }
        }

        aryDates.forEach((d) => {
            allLiaisons.forEach((l) => {
                liaisonsKeys.push(d + l.firstname + " " + l.lastname);
            });
        });

        this.setState({
            fullLiaisonKeys: liaisonsKeys,
            timezoneVariation: variation,
        });
    }

    async getContactTypes() {
        const contactTypes = window.sessionStorage.getItem('contactTypes');
        if (contactTypes !== null) {
            return new Promise((resolve) => {
                resolve({ contactTypes: JSON.parse(contactTypes) });
            });
        }

        return ContactService.findAllContactTypes()
            .then((contactTypes) => {
                window.sessionStorage.setItem("contactTypes", JSON.stringify(contactTypes));
                return ({ contactTypes: contactTypes });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getMyAccounts() {
        let cu = window.sessionStorage.getItem("currentUser");
        cu = JSON.parse(cu);

        return AccountService.getAccountsByUser(cu.id)
            .then((accounts) => {
                return ({ myAccounts: accounts });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getCreditCardTypes() {
        const cardTypes = window.sessionStorage.getItem('cardTypes');
        if (cardTypes !== null) {
            return new Promise((resolve) => {
                resolve({ cardTypes: JSON.parse(cardTypes) });
            });
        }

        return ActivityService.getCreditCardTypes()
            .then((cardTypes) => {
                window.sessionStorage.setItem("cardTypes", JSON.stringify(cardTypes));
                return ({ cardTypes: cardTypes });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getExpenseTypes() {
        const expenseTypes = window.sessionStorage.getItem('expenseTypes');
        if (expenseTypes !== null) {
            return new Promise((resolve) => {
                resolve({ expenseTypes: JSON.parse(expenseTypes) });
            });
        }

        return ActivityService.getExpenseTypes()
            .then((expenseTypes) => {
                window.sessionStorage.setItem("expenseTypes", JSON.stringify(expenseTypes));
                return ({ expenseTypes: expenseTypes });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getAccountTypes() {
        const accountTypes = window.sessionStorage.getItem('accountTypes');
        if (accountTypes !== null) {
            return new Promise((resolve) => {
                resolve({ accountTypes: JSON.parse(accountTypes) });
            });
        }

        return AccountService.getAllAccountTypes()
            .then((accountTypes) => {
                window.sessionStorage.setItem("accountTypes", JSON.stringify(accountTypes));
                return ({ accountTypes: accountTypes });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getOrderStatuses() {
        const orderStatusesIndexed = window.sessionStorage.getItem('orderStatusesIndexed');
        if (orderStatusesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    orderStatusesIndexed: JSON.parse(orderStatusesIndexed),
                });
            });
        }

        // Manually provide these since there's no API or table yet.
        return new Promise((resolve) => {
            const orderStatusesIndexed = {
                "0": {
                    id: 0,
                    name: "New",
                    colorId: 76
                },
                "1": {
                    id: 1,
                    name: "In Process",
                    colorId: 202
                },
                "2": {
                    id: 2,
                    name: "Ready to Deliver",
                    colorId: 118
                },
                "3": {
                    id: 3,
                    name: "Setup",
                    colorId: 132
                },
                "4": {
                    id: 4,
                    name: "Cancelled",
                    colorId: 6
                },
            }

            window.sessionStorage.setItem("orderStatusesIndexed", JSON.stringify(orderStatusesIndexed));
            resolve({
                orderStatusesIndexed: orderStatusesIndexed,
            });
        });
    }

    async getIssueStatuses() {
        const issueStatusesIndexed = window.sessionStorage.getItem('issueStatusesIndexed');
        if (issueStatusesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    issueStatusesIndexed: JSON.parse(issueStatusesIndexed),
                });
            });
        }

        // Manually provide these since there's no API or table yet.
        return new Promise((resolve) => {
            const issueStatusesIndexed = {
                "0": {
                    id: 0,
                    name: "Open",
                },
                "1": {
                    id: 1,
                    name: "Closed",
                },
            }

            window.sessionStorage.setItem("issueStatusesIndexed", JSON.stringify(issueStatusesIndexed));
            resolve({
                issueStatusesIndexed: issueStatusesIndexed,
            });
        });
    }

    async getServiceReasons() {
        const serviceReasonsIndexed = window.sessionStorage.getItem('serviceReasonsIndexed');
        if (serviceReasonsIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    serviceReasonsIndexed: JSON.parse(serviceReasonsIndexed),
                });
            });
        }

        // Manually provide these since there's no API or table yet.
        // select count(*), service_reason from service_order_reason group by service_reason order by count(*) desc;

        // TODO: This probably needs to be moved into the term table? These
        // exist for service tickets right now(service_order_reason) but we will
        // be adding subtypes for new soon enough. That might mean moving this
        // column from service_order_reason onto
        // order_activity.order_activity_subtype_term_id. Or it might need it's
        // own table if there is configuration like which things they apply to
        // (new vs service etc)
        return new Promise((resolve) => {
            const serviceReasonsIndexed = {
                "1": {
                    id: 1,
                    name: "Tech Skilled Labor",
                    description: "Tech Skilled Labor",
                },
                "2": {
                    id: 2,
                    name: "Setup Issue",
                    description: "Setup Issue – patient was not setup correctly on initial delivery",
                },
                "3": {
                    id: 3,
                    name: "Quality Issue",
                    description: "Quality Issue – equipment is a lemon or has repeat issues on same parts",
                },
                "4": {
                    id: 4,
                    name: "Service Part Needed",
                    description: "Service Part Needed",
                },
                "5": {
                    id: 5,
                    name: "Created In Field",
                    description: "CreatedInField",
                },
                "6": {
                    id: 6,
                    name: "Equipment Fit",
                    description: "Equipment Fit – ATP recommendations were not correct initially",
                },
                "7": {
                    id: 7,
                    name: "Misdiagnosed",
                    description: "Misdiagnosed – last part recommendation was incorrect",
                },
                "8": {
                    id: 8,
                    name: "Equipment Abuse",
                    description: "Equipment Abuse – patient is using equipment incorrectly or abusing equipment",
                },
                "9": {
                    id: 9,
                    name: "Manufacturer Error",
                    description: "Manufacturer Error – vendor sent wrong parts or suggested wrong parts",
                },
                "10": {
                    id: 10,
                    name: "New Accessory After The Sale",
                    description: "New Accessory After The Sale",
                },
                "11": {
                    id: 11,
                    name: "30 Day Fit 4 U",
                    description: "30 Day Fit 4 U",
                },
                "12": {
                    id: 12,
                    name: "Field Service Diagnosis",
                    description: "Field Service Diagnosis",
                },
                "13": {
                    id: 13,
                    name: "Issue Resolved Over the Phone",
                    description: "Issue Resolved Over the Phone",
                },
                "14": {
                    id: 14,
                    name: "Patient Request - 5 Day",
                    description: "Patient Request - 5 Day",
                },
                "15": {
                    id: 15,
                    name: "Patient Request - Rental",
                    description: "Patient Request - Rental",
                },
                "16": {
                    id: 16,
                    name: "Patient Request - Loaner",
                    description: "Patient Request - Loaner",
                },
                "17": {
                    id: 17,
                    name: "Patient Request - Exchange",
                    description: "Patient Request - Exchange",
                },
                "18": {
                    id: 18,
                    name: "Active Rental - Insurance Change",
                    description: "Active Rental - Insurance Change",
                },
                "19": {
                    id: 19,
                    name: "Active Rental - SNF/Hospice",
                    description: "Active Rental - SNF/Hospice",
                },
                "20": {
                    id: 20,
                    name: "Active Rental - Insurance Auth Expired",
                    description: "Active Rental - Insurance Auth Expired",
                },
                "21": {
                    id: 21,
                    name: "Delivery Denial - Insurance Change",
                    description: "Delivery Denial - Insurance Change",
                },
                "22": {
                    id: 22,
                    name: "Delivery Denial - SNF/Hospice",
                    description: "Delivery Denial - SNF/Hospice",
                },
                "23": {
                    id: 23,
                    name: "ATP Visit",
                    description: "ATP Visit",
                },
            }

            window.sessionStorage.setItem("serviceReasonsIndexed", JSON.stringify(serviceReasonsIndexed));
            resolve({
                serviceReasonsIndexed: serviceReasonsIndexed,
            });
        });
    }

    async getOrderStatusReasons() {
        const reasons = window.sessionStorage.getItem('reasons');
        const orderStatusReasonsIndexed = window.sessionStorage.getItem('orderStatusReasonsIndexed');
        if (reasons !== null && orderStatusReasonsIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    reasons: JSON.parse(reasons),
                    orderStatusReasonsIndexed: JSON.parse(orderStatusReasonsIndexed),
                });
            });
        }

        return ReasonService.getAllReasons()
            .then((orderStatusReasons) => {
                const orderStatusReasonsIndexed = this.indexById(orderStatusReasons);

                window.sessionStorage.setItem("reasons", JSON.stringify(orderStatusReasons));
                window.sessionStorage.setItem("orderStatusReasonsIndexed", JSON.stringify(orderStatusReasonsIndexed));
                return ({
                    reasons: orderStatusReasons,
                    orderStatusReasonsIndexed: orderStatusReasonsIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getDocumentTypes() {
        const documentTypes = window.sessionStorage.getItem('documentTypes');
        if (documentTypes !== null) {
            return new Promise((resolve) => {
                resolve({ documentTypes: JSON.parse(documentTypes) });
            });
        }

        return DocumentTypesService.getAllDocumentTypes()
            .then((documentTypes) => {
                window.sessionStorage.setItem("documentTypes", JSON.stringify(documentTypes));
                return ({ documentTypes: documentTypes });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getMarketingCampaigns() {
        const marketingCampaigns = window.sessionStorage.getItem('marketingCampaigns');
        if (marketingCampaigns !== null) {
            return new Promise((resolve) => {
                resolve({ marketingCampaigns: JSON.parse(marketingCampaigns) });
            });
        }

        return MarketingService.findAllCampaigns()
            .then((marketingCampaigns) => {
                window.sessionStorage.setItem("marketingCampaigns", JSON.stringify(marketingCampaigns));
                return ({ marketingCampaigns: marketingCampaigns });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getComplaintCategories() {
        const issueCategories = window.sessionStorage.getItem('complaintCategories');
        const issueCategoriesIndexed = window.sessionStorage.getItem('issueCategoriesIndexed');
        if (issueCategories !== null && issueCategoriesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    complaintCategories: JSON.parse(issueCategories),
                    issueCategoriesIndexed: JSON.parse(issueCategoriesIndexed),
                });
            });
        }

        return ComplaintService.getAllIssueCategories()
            .then((issueCategories) => {
                const issueCategoriesIndexed = this.indexById(issueCategories);

                window.sessionStorage.setItem("complaintCategories", JSON.stringify(issueCategories));
                window.sessionStorage.setItem("issueCategoriesIndexed", JSON.stringify(issueCategoriesIndexed));
                return ({
                    complaintCategories: issueCategories,
                    issueCategoriesIndexed: issueCategoriesIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getComplaintReasons() {
        const issueReasons = window.sessionStorage.getItem('complaintReasons');
        const issueReasonsIndexed = window.sessionStorage.getItem('issueReasonsIndexed');
        if (issueReasons !== null && issueReasonsIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    complaintReasons: JSON.parse(issueReasons),
                    issueReasonsIndexed: JSON.parse(issueReasonsIndexed),
                });
            });
        }

        return ComplaintService.getAllIssueReasons()
            .then((issueReasons) => {
                const issueReasonsIndexed = this.indexById(issueReasons);

                window.sessionStorage.setItem("complaintReasons", JSON.stringify(issueReasons));
                window.sessionStorage.setItem("issueReasonsIndexed", JSON.stringify(issueReasonsIndexed));
                return ({
                    complaintReasons: issueReasons,
                    issueReasonsIndexed: issueReasonsIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getVendors() {
        const allVendors = window.sessionStorage.getItem('allVendors');
        const vendorsIndexed = window.sessionStorage.getItem('vendorsIndexed');
        if (allVendors !== null && vendorsIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    allVendors: JSON.parse(allVendors),
                    vendorsIndexed: JSON.parse(vendorsIndexed)
                });
            });
        }

        return VendorService.getAllVendors()
            .then((allVendors) => {
                const vendorsIndexed = this.indexById(allVendors);

                window.sessionStorage.setItem("allVendors", JSON.stringify(allVendors));
                window.sessionStorage.setItem("vendorsIndexed", JSON.stringify(vendorsIndexed));
                return ({
                    allVendors: allVendors,
                    vendorsIndexed: vendorsIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getColors() {
        const colorsIndexed = window.sessionStorage.getItem("colorsIndexed");
        if (colorsIndexed !== null) {
            return new Promise((resolve) => {
                resolve({ colorsIndexed: JSON.parse(colorsIndexed) });
            });
        }

        return ColorService.getAllColors()
            .then((colors) => {
                const colorsIndexed = this.indexById(colors);
                window.sessionStorage.setItem("colorsIndexed", JSON.stringify(colorsIndexed));

                return ({
                    colorsIndexed: colorsIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getProductOverrides() {
        const productOverrides = window.sessionStorage.getItem('productOverrides');
        if (productOverrides !== null) {
            return new Promise((resolve) => {
                resolve({ productOverrides: JSON.parse(productOverrides) });
            });
        }

        return ProductMatrixService.getAllProducts()
            .then((productOverrides) => {
                window.sessionStorage.setItem("productOverrides", JSON.stringify(productOverrides));
                return ({
                    productOverrides: productOverrides,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getStateSalesTax() {
        const stateSalesTax = window.sessionStorage.getItem('stateSalesTax');
        if (stateSalesTax !== null) {
            return new Promise((resolve) => {
                resolve({ stateSalesTax: JSON.parse(stateSalesTax) });
            });
        }

        return StateSalesTaxService.getAllStates().then((res) => {
            let arr = [];

            res.forEach((state) => {
                arr.push({
                    id: state.id,
                    name: state.name,
                    abbr: state.abbr,
                    salesTax: parseFloat(state.salesTax),
                });
            });
            window.sessionStorage.setItem("stateSalesTax", JSON.stringify(arr));
            return ({ stateSalesTax: arr });
        })
            .catch(err => {
                console.log(err);
            });
    }

    async getReverseQualityCategories() {
        const reverseQualityCategories = window.sessionStorage.getItem('reverseQualityCategories');
        const reverseQualityCategoriesRefs = window.sessionStorage.getItem('reverseQualityCategoriesRefs');
        if (reverseQualityCategories !== null && reverseQualityCategoriesRefs !== null) {
            return new Promise((resolve) => {
                resolve({
                    reverseQualityCategories: JSON.parse(reverseQualityCategories),
                    reverseQualityCategoriesRefs: JSON.parse(reverseQualityCategoriesRefs)
                });
            });
        }

        return ReverseQualityService.getAllCategories().then((res) => {
            let arr = [],
                refAry = [];

            res.forEach((category) => {
                refAry.push({
                    label: category.name,
                    value: {
                        id: category.id,
                        name: category.name,
                    },
                });

                arr.push({
                    id: category.id,
                    name: category.name,
                });
            });

            window.sessionStorage.setItem("reverseQualityCategories", JSON.stringify(arr));
            window.sessionStorage.setItem("reverseQualityCategoriesRefs", JSON.stringify(refAry));

            return ({
                reverseQualityCategories: arr,
                reverseQualityCategoriesRefs: refAry,
            });
        })
            .catch(err => {
                console.log(err);
            });
    }

    async getPatientCommunicationTriggers() {
        const patientCommunicationTriggerDefs = window.sessionStorage.getItem('patientCommunicationTriggerDefs');
        if (patientCommunicationTriggerDefs !== null) {
            return new Promise((resolve) => {
                resolve({ patientCommunicationTriggerDefs: JSON.parse(patientCommunicationTriggerDefs) });
            });
        }

        return PatientCommunicationService.getAllPatientCommunicationTriggerDefs()
            .then((patientCommunicationTriggerDefs) => {
                window.sessionStorage.setItem("patientCommunicationTriggerDefs", JSON.stringify(patientCommunicationTriggerDefs));

                return ({
                    patientCommunicationTriggerDefs: patientCommunicationTriggerDefs,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getDepartments() {
        const departmentsIndexed = window.sessionStorage.getItem('departmentsIndexed');
        if (departmentsIndexed !== null) {
            return new Promise((resolve) => {
                resolve({ departmentsIndexed: JSON.parse(departmentsIndexed) });
            });
        }

        return DepartmentService.getAllDepartments()
            .then((departments) => {
                const departmentsIndexed = this.indexById(departments);
                window.sessionStorage.setItem("departmentsIndexed", JSON.stringify(departmentsIndexed));

                return ({
                    departmentsIndexed: departmentsIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getTeams() {
        const teamsIndexed = window.sessionStorage.getItem('teamsIndexed');
        if (teamsIndexed !== null) {
            return new Promise((resolve) => {
                resolve({ teamsIndexed: JSON.parse(teamsIndexed) });
            });
        }

        return TeamService.getAllTeams()
            .then((teams) => {
                const teamsIndexed = this.indexById(teams);
                window.sessionStorage.setItem("teamsIndexed", JSON.stringify(teamsIndexed));

                return ({
                    teamsIndexed: teamsIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getTeamUsers() {
        const teamUsersIndexed = window.sessionStorage.getItem('teamUsersIndexed');
        if (teamUsersIndexed !== null) {
            return new Promise((resolve) => {
                resolve({ teamUsersIndexed: JSON.parse(teamUsersIndexed) });
            });
        }

        return TeamUserService.getAllTeamUsers()
            .then((teams) => {
                const teamUsersIndexed = this.indexById(teams);
                window.sessionStorage.setItem("teamUsersIndexed", JSON.stringify(teamUsersIndexed));

                return ({
                    teamUsersIndexed: teamUsersIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getTeamQueues() {
        const teamQueuesIndexed = window.sessionStorage.getItem('teamQueuesIndexed');
        if (teamQueuesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({ teamQueuesIndexed: JSON.parse(teamQueuesIndexed) });
            });
        }

        return TeamQueueService.getAllTeamQueues()
            .then((teams) => {
                const teamQueuesIndexed = this.indexById(teams);
                window.sessionStorage.setItem("teamQueuesIndexed", JSON.stringify(teamQueuesIndexed));

                return ({
                    teamQueuesIndexed: teamQueuesIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getTerms() {
        const termsIndexed = window.sessionStorage.getItem('termsIndexed');
        if (termsIndexed !== null) {
            return new Promise((resolve) => {
                resolve({ termsIndexed: JSON.parse(termsIndexed) });
            });
        }

        return TermService.getAllTerms()
            .then((terms) => {
                const termsIndexed = this.indexById(terms);
                window.sessionStorage.setItem("termsIndexed", JSON.stringify(termsIndexed));

                return ({
                    termsIndexed: termsIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getQueueRoutes() {
        const queueRoutesIndexed = window.sessionStorage.getItem('queueRoutesIndexed');
        if (queueRoutesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({ queueRoutesIndexed: JSON.parse(queueRoutesIndexed) });
            });
        }

        return QueueRouteService.getAllQueueRoutes()
            .then((teams) => {
                const queueRoutesIndexed = this.indexById(teams);
                window.sessionStorage.setItem("queueRoutesIndexed", JSON.stringify(queueRoutesIndexed));

                return ({
                    queueRoutesIndexed: queueRoutesIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getQueues() {
        const queuesIndexed = window.sessionStorage.getItem('queuesIndexed');
        if (queuesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({ queuesIndexed: JSON.parse(queuesIndexed) });
            });
        }

        return QueueService.getAllQueues()
            .then((teams) => {
                const queuesIndexed = this.indexById(teams);
                window.sessionStorage.setItem("queuesIndexed", JSON.stringify(queuesIndexed));

                return ({
                    queuesIndexed: queuesIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getOrderActivityTypes() {
        const orderActivityTypesIndexed = window.sessionStorage.getItem('orderActivityTypesIndexed');
        if (orderActivityTypesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({ orderActivityTypesIndexed: JSON.parse(orderActivityTypesIndexed) });
            });
        }

        return OrderActivityTypeService.getAllOrderActivityTypes()
            .then((teams) => {
                const orderActivityTypesIndexed = this.indexById(teams);
                window.sessionStorage.setItem("orderActivityTypesIndexed", JSON.stringify(orderActivityTypesIndexed));

                return ({
                    orderActivityTypesIndexed: orderActivityTypesIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getBaseUnitTypes() {
        const allBaseUnitTypes = window.sessionStorage.getItem('allBaseUnitTypes');
        const baseUnitTypesIndexed = window.sessionStorage.getItem('baseUnitTypesIndexed');
        if (allBaseUnitTypes !== null && baseUnitTypesIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    allBaseUnitTypes: JSON.parse(allBaseUnitTypes),
                    baseUnitTypesIndexed: JSON.parse(baseUnitTypesIndexed)
                });
            });
        }

        return BaseUnitTypeService.getAllBaseUnitTypes()
            .then((allBaseUnitTypes) => {
                const baseUnitTypesIndexed = this.indexById(allBaseUnitTypes);

                window.sessionStorage.setItem("allBaseUnitTypes", JSON.stringify(allBaseUnitTypes));
                window.sessionStorage.setItem("baseUnitTypesIndexed", JSON.stringify(baseUnitTypesIndexed));
                return ({
                    allBaseUnitTypes: allBaseUnitTypes,
                    baseUnitTypesIndexed: baseUnitTypesIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getBaseUnits() {
        const allBaseUnits = window.sessionStorage.getItem('allBaseUnits');
        const baseUnitsIndexed = window.sessionStorage.getItem('baseUnitsIndexed');
        if (allBaseUnits !== null && baseUnitsIndexed !== null) {
            return new Promise((resolve) => {
                resolve({
                    allBaseUnits: JSON.parse(allBaseUnits),
                    baseUnitsIndexed: JSON.parse(baseUnitsIndexed)
                });
            });
        }

        return BaseUnitService.getAllBaseUnits()
            .then((allBaseUnits) => {
                const baseUnitsIndexed = this.indexById(allBaseUnits);

                window.sessionStorage.setItem("allBaseUnits", JSON.stringify(allBaseUnits));
                window.sessionStorage.setItem("baseUnitsIndexed", JSON.stringify(baseUnitsIndexed));
                return ({
                    allBaseUnits: allBaseUnits,
                    baseUnitsIndexed: baseUnitsIndexed,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }









    async getCurrentUserRoleQuotaValues() {
        const userRoleQuotas = window.sessionStorage.getItem('userRoleQuotas');
        if (userRoleQuotas !== null) {
            return new Promise((resolve) => {
                resolve({ userRoleQuotas: JSON.parse(userRoleQuotas) });
            });
        }

        return userRoleService.getAllUserRoleQuotas().then((userRoleQuotas) => {
            window.sessionStorage.setItem("userRoleQuotas", JSON.stringify(userRoleQuotas));
            return ({
                userRoleQuotas: userRoleQuotas
            })
        }).catch(err => {
            console.log(err);
        })
    }

    //endregion
    async getReverseQualityReasons() {
        const reverseQualityReasons = window.sessionStorage.getItem('reverseQualityReasons');
        const currentReverseQualityReasonRefs = window.sessionStorage.getItem('currentReverseQualityReasonRefs');
        if (reverseQualityReasons !== null && currentReverseQualityReasonRefs !== null) {
            return new Promise((resolve) => {
                resolve({
                    reverseQualityReasons: JSON.parse(reverseQualityReasons),
                    currentReverseQualityReasonRefs: JSON.parse(currentReverseQualityReasonRefs)
                });
            });
        }

        return ReasonService.getAllReverseQualityReasons().then((res) => {
            let refAry = [];

            res.forEach((category) => {
                refAry.push({
                    label: category.name,
                    value: {
                        id: category.id,
                        name: category.name,
                    },
                });
            });

            window.sessionStorage.setItem("reverseQualityReasons", JSON.stringify(res));
            window.sessionStorage.setItem("currentReverseQualityReasonRefs", JSON.stringify(refAry));

            return ({
                reverseQualityReasons: res,
                currentReverseQualityReasonRefs: refAry,
            });
        })
            .catch(err => {
                console.log(err);
            })
    }

    componentDidMount() {
        let cu = window.sessionStorage.getItem("currentUser");

        // if (true) {
        if (cu != null && cu.length > 0) {
            this.getAll();

            //get the company name
            API.getToken()
                .then((res) => {
                    if (res) {
                        this.setState({ companyName: res.company, company: res.company });
                        Sentry.setTag("company", res.company.toLowerCase());
                    } else {
                        return <Redirect to={"/login"} />;
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            this.setState({ notLoggedIn: true });

        }
    }

    /**
     * Load all of the dictionaries. For now this fires off one API call per
     * dictionary; we may adjust this to be a single API call in the future.
     * When all of the calls are complete, the app is marked as loaded and the
     * state is set.
     */
    getAll() {
        this.setState({ loadingData: true });

        const promises = [
            this.getAllUsers(),
            this.getTerms(),
            this.getDepartments(),
            this.getTeams(),
            this.getTeamUsers(),
            this.getTeamQueues(),
            this.getQueues(),
            this.getQueueRoutes(),
            this.getOrderActivityTypes(),
            this.getBaseUnitTypes(),
            this.getBaseUnits(),
            this.getContactTypes(),
            this.getInsuranceTypes(),
            this.getInsuranceSubTypes(),
            this.getProductTypes(),
            this.getProductSubTypes(),
            this.getLocations(),
            this.getRegions(),
            this.getOrderStatuses(),
            this.getIssueStatuses(),
            this.getOrderStatusReasons(),
            this.getServiceReasons(),
            this.getCreditCardTypes(),
            this.getExpenseTypes(),
            this.getDocumentTypes(),
            this.getAccountTypes(),
            this.getMarketingCampaigns(),
            this.getComplaintCategories(),
            this.getComplaintReasons(),
            this.getVendors(),
            this.getColors(),
            this.getProductOverrides(),
            this.getStateSalesTax(),
            this.getReverseQualityCategories(),
            this.getReverseQualityReasons(),
            this.getPatientCommunicationTriggers(),
            this.getCurrentUserRoleQuotaValues(),
            this.getMyAccounts(),
        ];

        this.setState({ loadingCount: promises.length });
        this.setState({ loadingDone: 0 });

        promises.forEach((promise) => {
            promise.then(() => {
                this.setState({ loadingDone: this.state.loadingDone + 1 });
            });
        });

        Promise.all(promises).then(data => {
            window.salesPilot.loaded = true;
            this.setState(Object.assign({ loadingData: false }, ...data));

            /**
             * The users one sets a bunch of other helper state objects. This is
             * stupid, but for now make sure that one is first in the
             * Promise.all() call so the below line of code works.
             */
            this.sortUsers(Object.values(data[0].usersIndexed));
        }).catch(err => {
            console.log(err);
        });
    }

    clearState() {
        this.setState({
            collapseID: "",
            currentUser: {},
            company: "",
            inventoryRequestOpen: false,
            insuranceTypes: [],
            insuranceSubTypes: [],
            productTypes: [],
            productSubTypes: [],
            searchText: "",
            reasons: [],
            therapists: [],
            salesReps: [],
            accounts: [],
            myAccounts: [],
            allLocations: [],
            internalLocations: [],
            allUsers: [],
            userRefs: [],
            allActiveUsers: [],
            allLiaisons: [],
            allPCR: [],
            thirdPartyUsers: [],
            thirdPartyUsersLoaded: false,
            cardTypes: [],
            expenseTypes: [],
            documentTypes: [],
            contactTypes: [],
            accountTypes: [],
            techs: [],
            marketingCampaigns: [],
            complaintCategories: [],
            complaintReasons: [],
            allVendors: [],
            allBaseUnits: [],
            allBaseUnitTypes: [],
            productOverrides: [],
            fullLiaisonKeys: [],
            reverseQualityCategories: [],
            userRoleQuotas: [],

            // Context entries indexed by primary key.
            termsIndexed: {},
            usersIndexed: {},
            departmentsIndexed: {},
            teamsIndexed: {},
            teamUsersIndexed: {},
            teamQueuesIndexed: {},
            queueRoutesIndexed: {},
            queuesIndexed: {},
            orderActivityTypesIndexed: {},
            issueStatusesIndexed: {},
            orderStatusesIndexed: {},
            orderStatusReasonsIndexed: {},
            insuranceTypesIndexed: {},
            insuranceSubTypesIndexed: {},
            serviceReasonsIndexed: {},
        });

        window.sessionStorage.clear();
    }

    closeInventoryRequest() {
        this.setState({
            inventoryRequestOpen: false,
        });
    }


    renderInventoryRequestModal() {
        return (
            <MDBModal
                size="lg"
                isOpen={this.state.inventoryRequestOpen}
                toggle={() => {
                    this.setState({
                        inventoryRequestOpen: false,
                    });
                }}
            >
                <InventoryRequest onClose={this.closeInventoryRequest.bind(this)} />
            </MDBModal>
        );
    }

    renderPODeliveryUpdateModal() {
        return (
            <MDBModal
                size="md"
                isOpen={this.state.poDeliveryUpdateOpen}
                toggle={() => {
                    this.setState({
                        poDeliveryUpdateOpen: false,
                    });
                }}
            >
                <PODeliveryUpdate onClose={this.closePODeliveryUpdate.bind(this)} />
            </MDBModal>
        );
    }

    closePODeliveryUpdate() {
        this.setState({
            poDeliveryUpdateOpen: false,
        });
    }

    renderOrderSearchModal() {
        return (
            <MDBModal
                isOpen={this.state.orderModalOpen}
                centered
                size="sm"
                toggle={() => {
                    this.setState({
                        //orderFound: false,
                        orderErrorMessage: "",
                        orderModalOpen: false,
                    });
                }}
            >
                <ModalBody style={{ backgroundColor: "#FFFFFF" }}>
                    <MDBRow style={{ justifyContent: "space-around" }}>
                        <MDBCol>
                            <FormInline
                                onSubmit={(e) => {
                                    this.searchForId(e);
                                }}
                            >
                                <MDBInput
                                    outline
                                    size="lg"
                                    icon={"search"}
                                    label="Search Order Or Ticket ID"
                                    value={this.state.orderId}
                                    onChange={(e) =>
                                        this.setState({
                                            orderId: e.target.value,
                                        })
                                    }
                                />
                                <span>{this.state.orderErrorMessage}</span>
                            </FormInline>
                        </MDBCol>
                    </MDBRow>
                </ModalBody>
            </MDBModal>
        );
    }

    handleRQUserSelected = (e) => {
        let obj = this.state.rqObject;

        obj.rqUser = e.value;
        obj.rqUserId = e.value.id;
        this.setState({
            rqObject: obj,
        });
    };

    handleRqCategorySelected = (e) => {
        let obj = this.state.rqObject;

        obj.rqQualityCategory = e.value;
        obj.rqQualityCategoryId = e.value.id;

        let r = JSON.parse(JSON.stringify(this.state.reverseQualityReasons)),
            ref = [];

        r = r && r.length > 0 ? r.filter(v => {
            return v.reverseQualityCategoryId === e.value.id;
        }) : r;

        if (r && r.length > 0) {
            r.forEach(nv => {
                ref.push({
                    label: nv.name,
                    value: {
                        id: nv.id,
                        name: nv.name,
                    },
                });
            })
        }

        this.setState({
            rqObject: obj,
            currentReverseQualityReasonRefs: ref
        });
    };

    handleRqReasonSelected = (e) => {
        let obj = this.state.rqObject;

        obj.rqQualityReason = e.value;
        obj.rqQualityReasonId = e.value.id;
        this.setState({
            rqObject: obj,
        });
    }

    handleRqNote(e) {
        let obj = this.state.rqObject;

        obj.notes = e.target.value;

        this.setState({
            rqObject: obj,
        });
    }

    submitRqCategory() {
        let currentUser = this.state.currentUser,
            obj = this.state.rqObject;

        if (!obj.notes) {
            return toast.warn("You must enter some notes");
        } else if (!obj.orderId) {
            return toast.warn("You must enter an order id");
        } else if (!obj.rqUser) {
            return toast.warn("You must select a user");
        } else if (!obj.rqQualityCategory) {
            return toast.warn("You must select a category");
        } else if (!obj.rqQualityReason) {
            return toast.warn("You must select a reason");
        }

        obj.rqReportedBy = { name: currentUser.username, id: currentUser.id };
        obj.rqReportedById = currentUser.id;
        obj.rqReportedOn = new Date();

        ReverseQualityService.createReverseQualityLog(obj)
            .then((r) => {
                this.setState({
                    rqModalOpen: false,
                    rqObject: {},
                });
                toast.success("RQ Submitted!");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    submitQualityAssurance(obj) {
        ReverseQualityService.createQualityAssuranceLog(obj)
            .then((r) => {
                toast.success("Quality Assurance Log Submitted!");
            })
            .catch((err) => {
                console.log(err);
                toast.success("There was an issue submitting the quality assurance log");
            })
            .finally(() => {
                this.setState({
                    assuranceModalOpen: false,
                });
            })
    }

    renderQualityAssuranceScorecard() {
        return (
            <MDBModal
                isOpen={this.state.assuranceModalOpen}
                centered
                size="lg"
                toggle={() => {
                    this.setState({
                        assuranceModalOpen: false,
                    });
                }}>
                <QualityAssuranceScorecard
                    onSubmit={this.submitQualityAssurance.bind(this)}
                    onCancel={() => this.setState({ assuranceModalOpen: false })} />
            </MDBModal>)
    }

    renderReverseQualityModal() {
        return (
            <MDBModal
                isOpen={this.state.rqModalOpen}
                centered
                size="lg"
                toggle={() => {
                    this.setState({
                        rqModalOpen: false,
                        rqObject: {},
                    });
                }}
            >
                <MDBRow style={{ justifyContent: "center" }}>
                    <span
                        style={{ fontSize: 20, padding: 10, textDecoration: "underline" }}
                    >
                        Report Reverse Quality
                    </span>
                </MDBRow>

                <ModalBody style={{ backgroundColor: "#FFFFFF", padding: 20 }}>
                    <MDBRow style={{ justifyContent: "center" }}>
                        <MDBCol size="5">
                            <label> Order Id </label>
                            <MDBInput
                                containerClass={"noMargin"}
                                outline
                                type="number"
                                valueDefault={this.state.rqOrderId}
                                onChange={(e) => {
                                    let obj = this.state.rqObject;
                                    obj.orderId = parseInt(e.target.value);

                                    this.setState({
                                        rqObject: obj,
                                        rqOrderId: e.target.value,
                                    });
                                }}
                            />
                        </MDBCol>
                        <MDBCol size="5">
                            <label> User Being Reported </label>
                            <Select
                                placeholder={
                                    this.state.rqObject.reportedOn
                                        ? this.state.rqObject.reportedOn.name
                                        : "Select User..."
                                }
                                options={this.state.userRefs}
                                onChange={this.handleRQUserSelected.bind(this)}
                            />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow style={{ justifyContent: "center" }}>
                        <MDBCol size="5">
                            <label> RQ Category </label>
                            <Select
                                placeholder={
                                    this.state.rqObject.rqQualityCategory
                                        ? this.state.rqObject.rqQualityCategory.name
                                        : "Select Category..."
                                }
                                options={this.state.reverseQualityCategoriesRefs}
                                onChange={this.handleRqCategorySelected.bind(this)}
                            />
                        </MDBCol>

                        <MDBCol size="5">
                            <label> RQ Reason </label>
                            <Select
                                placeholder={
                                    this.state.rqObject.rqReason
                                        ? this.state.rqObject.rqReason.name
                                        : "Select Reason..."
                                }
                                options={this.state.currentReverseQualityReasonRefs}
                                onChange={this.handleRqReasonSelected.bind(this)}
                            />
                        </MDBCol>

                        <MDBCol size="10">
                            <MDBInput
                                style={{ border: "1px solid black", textAlign: "left" }}
                                type="textarea"
                                label="Enter notes.."
                                rows="4"
                                cols="50"
                                valueDefault={this.state.rqObject.notes}
                                onChange={this.handleRqNote.bind(this)}
                            />

                            <MDBBtn
                                color={"indigo"}
                                className="float-right"
                                onClick={() => this.submitRqCategory()}
                            >
                                Submit
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </ModalBody>
            </MDBModal>
        );
    }

    searchForId(e) {
        e.preventDefault();
        let v = this.state.orderId;
        orderService
            .getOrderActivity(v)
            .then((res) => {
                if ((res && res.id && res.activity && res.activity.type.toLowerCase() === "order") || res.activity.type.toLowerCase() === "ticket") {
                    this.setState({
                        orderFound: true,
                        tempOrder: res,
                    });
                } else {
                    this.setState({
                        orderErrorMessage: "Order not found!",
                    });
                }
            })
            .catch((err) => {
                console.log(err)
                this.setState({
                    orderErrorMessage: "Order not found!",
                });
            });
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    }

    hasAccess(type) {
        let cu = this.state.currentUser;

        if (cu.role === "ADMIN" || cu.role === "SYSTEM_ADMIN") {
            return true;
        }

        switch (type) {
            case "userAdmin":
                if (
                    ["USER_ADMIN"].includes(cu.role)
                ) {
                    return true;
                }
                break;
            case "reverseQuality":
                if (
                    ["EXECUTIVE_MANAGEMENT", "SALES_MANAGER", "AREA_MANAGER", "SALES_LIAISON_MANAGER"].includes(cu.role) ||
                    cu.role.indexOf("MANAGER") > -1
                ) {
                    return true;
                }
                break;
            case "patientApp":
            case "presidentsClub":
                //if (
                //    ["EXECUTIVE_MANAGEMENT", "SALES_MANAGER", "SALES"].includes(cu.role)
                //) {
                //    return true;
                //}
                if (
                    ["EXECUTIVE_MANAGEMENT", "ADMIN"].includes(cu.role)
                ) {
                    return true;
                }
                break;
            case "salesBonus":
                if (["FINANCE"].includes(cu.role)) {
                    return true;
                }
                break;
            case "techProfile":
                if (
                    ["EXECUTIVE_MANAGEMENT", "OFFICE_MANAGER", "AREA_MANAGER"].includes(cu.role)
                ) {
                    return true;
                }
                break;
            case "pcrLink":
                if (
                    ["EXECUTIVE_MANAGEMENT", "SALES_MANAGER"].includes(cu.role)
                ) {
                    return true;
                }
                break;
            case "liaisonMeetings":
                if (["SALES_LIAISON", "SALES_LIAISON_MANAGER", "EXECUTIVE_MANAGEMENT"].includes(cu.role)) {
                    return true;
                }
                break;
            case "purchasing":
                if (
                    [
                        "EXECUTIVE_MANAGEMENT",
                        "PURCHASING",
                        "AREA_MANAGER",
                        "WAREHOUSE_TECHNICIAN",
                        "OFFICE_MANAGER",
                    ].includes(cu.role)
                ) {
                    return true;
                }
                break;
            case "marketing":
                if (["EXECUTIVE_MANAGEMENT", "SALES_MANAGER", "MARKETING"].includes(cu.role)) {
                    return true;
                }
                break;
            case "followup":
                if (["EXECUTIVE_MANAGEMENT", "FOLLOWUP"].includes(cu.role) || cu.canFollowUp) {
                    return true;
                }
                break;
            case "reports":
                return true;
            // if(["EXECUTIVE_MANAGEMENT","AREA_MANAGER","SALES_MANAGER","OFFICE_MANAGER"].includes(cu.role)){
            //     return true;
            // }
            case "faxes":
                return true;
            case "productCategories":
                if (["EXECUTIVE_MANAGEMENT", "PURCHASING"].includes(cu.role)) {
                    return true;
                }
                break;
            default:
                break;
        }
    }

    renderRedirect() {
        if (this.state.searchedPatient === true) {
            this.setState({ searchedPatient: false });
            return <Redirect to={"/patientList/" + this.state.searchText} />;
        } else if (this.state.orderFound === true) {
            this.setState({
                orderFound: false,
                orderModalOpen: false,
            });
            if (this.state.tempOrder.activity.type === "Ticket") {
                return <Redirect to={{ pathname: "/patientInformation/" + this.state.tempOrder.patientId, state: { shouldOpenOrderId: this.state.tempOrder.id } }} />;
            } else {
                return <Redirect to={{ pathname: "/order/" + this.state.orderId, state: { order: this.state.tempOrder } }} />;
            }


        } else {
            return <div />;
        }
    }

    renderOrderIdSearch() {
        return (
            <NavItem>
                <NavLink
                    onClick={() => this.setState({ orderModalOpen: true })}
                    to="#!"
                >
                    <AiOutlineSearch
                        size="2em"
                        data-place={"bottom"}
                        data-tip={"Search By Order Or Ticket ID"}
                        style={{ color: "#FFFFFF" }}
                    />
                </NavLink>
            </NavItem>
        );
    }

    renderReverseQualityModalIcon() {
        let t = this;

        function showLogs() {
            if (t.hasAccess("reverseQuality") === true) {
                return (
                    <DropdownItem>
                        <NavLink style={{ padding: 0 }} to="/reverseQualityLogs">
                            Reverse Quality Logs
                        </NavLink>
                    </DropdownItem>
                );
            }
        }

        function showReasons() {
            if (t.hasAccess("admin") === true) {
                return (
                    <DropdownItem>
                        <NavLink style={{ padding: 0 }} to="/reverseQualityReasons">
                            Reverse Quality Reasons
                        </NavLink>
                    </DropdownItem>
                );
            }
        }

        return (
            <NavItem>
                <Dropdown size="sm">
                    <DropdownToggle nav caret style={{ color: "#FFFFFF" }}>
                        <span className={"mainSiteNavText"}> REVERSE QUALITY</span>
                        {/*<MdErrorOutline*/}
                        {/*    size="2em"*/}
                        {/*    data-place={"bottom"}*/}
                        {/*    data-tip={"Reverse Quality"}*/}
                        {/*    style={{color: "#5881C1"}}*/}
                        {/*/>*/}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem>
                            <NavItem>
                                <NavLink
                                    style={{ padding: 0 }}
                                    onClick={() => this.setState({ rqModalOpen: true })}
                                    to="#!"
                                >
                                    Report Reverse Quality
                                </NavLink>
                            </NavItem>
                        </DropdownItem>
                        {/*<DropdownItem>*/}
                        {/*    <NavItem>*/}
                        {/*        <NavLink*/}
                        {/*            style={{ padding: 0 }}*/}
                        {/*            onClick={() => this.setState({ assuranceModalOpen: true })}*/}
                        {/*            to="#!"*/}
                        {/*        >*/}
                        {/*            Quality Assurance*/}
                        {/*        </NavLink>*/}
                        {/*    </NavItem>*/}
                        {/*</DropdownItem>*/}
                        {showLogs()}
                        {showReasons()}
                    </DropdownMenu>
                </Dropdown>
            </NavItem>
        );
    }

    renderReportsDropdown() {
        return (
            <NavItem>
                <Dropdown size="sm">
                    <DropdownToggle nav caret style={{ color: "#FFFFFF" }}>
                        <span className={"mainSiteNavText"}>REPORTS</span>
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.renderReports()}
                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/reports/salesPipeline">
                                Sales Pipeline Chart
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/reports/monthGlance">
                                Month at a Glance
                            </NavLink>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </NavItem>
        );
    }

    renderReports() {
        if (this.hasAccess("reports")) {
            return (
                <DropdownItem>
                    <NavLink style={{ padding: 0 }} to="/reports/home">
                        Home
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderTasksNav() {
        return (
            <NavItem>
                <NavLink to="/tasks">
                    <span className={"mainSiteNavText"}> TASKS </span>
                </NavLink>
            </NavItem>
        )
    }

    renderFaxesNav() {
        if (this.hasAccess("faxes")) {
            return (
                <NavItem>
                    <NavLink to="/faxes">
                        <span className={"mainSiteNavText"}> FAXES </span>
                        {/*<AiOutlinePrinter*/}
                        {/*    size="2em"*/}
                        {/*    data-place={"bottom"}*/}
                        {/*    data-tip={"Faxes"}*/}
                        {/*    style={{color: "#5881C1"}}*/}
                        {/*/>*/}
                    </NavLink>
                </NavItem>
            );
        }
    }

    renderFollowUp() {
        if (this.hasAccess("followup")) {
            return (
                <DropdownItem>
                    <NavLink style={{ padding: 0 }} to="/followup">
                        Follow-Up
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderAtp() {
        return (
            <DropdownItem>
                <NavLink style={{ padding: 0 }} to="/atp">
                    ATP
                </NavLink>
            </DropdownItem>
        );
    }


    renderTechProfiles() {
        if (this.hasAccess("techProfile") === true) {
            return (
                <DropdownItem>
                    <NavLink style={{ padding: 0 }} to="/techProfiles">
                        Technician Profiles
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderInventoryTracking() {
        // if (this.hasAccess("inventoryTracking") === true)
        //{
        return (
            <DropdownItem>
                <NavLink style={{ padding: 0 }} to="/inventoryTracking">
                    Inventory Tracking
                </NavLink>
            </DropdownItem>
        );
        //}
    }

    renderPCRLink() {
        if (this.hasAccess("pcrLink") === true) {
            return (
                <DropdownItem>
                    <NavLink style={{ padding: 0 }} to="/pcrLinks">
                        PCR Links
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderProductCategories() {
        if (this.hasAccess("productCategories") === true) {
            return (
                <div>
                    <MDBDropdownItem divider />
                    <MDBDropdownItem header>Products</MDBDropdownItem>
                    <MDBDropdownItem divider />

                    <DropdownItem>
                        <NavLink style={{ padding: 0 }} to="/products">
                            Product Categories
                        </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                        <NavLink style={{ padding: 0 }} to="/productSizes">
                            Product Sizes
                        </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                        <NavLink style={{ padding: 0 }} to="/admin/baseUnitTypes">
                            Base Unit Types
                        </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                        <NavLink style={{ padding: 0 }} to="/admin/baseUnits">
                            Base Units
                        </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                        <NavLink style={{ padding: 0 }} to="/admin/btproducts">
                            Brightree Products
                        </NavLink>
                    </DropdownItem>
                </div>
            )
        }
    }

    renderFieldOperationsNav() {
        return (
            <NavItem>
                <Dropdown size="sm">
                    <DropdownToggle nav caret style={{ color: "#FFFFFF" }}>
                        {/*<MDBIcon icon="truck-moving"*/}
                        {/*    size={"2x"}*/}
                        {/*    data-place={"bottom"}*/}
                        {/*    data-tip={"Field Operations"}*/}
                        {/*/>*/}
                        <span className={"mainSiteNavText"}> FIELD OPERATIONS </span>

                    </DropdownToggle>
                    <DropdownMenu>

                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/deliveries">
                                Deliveries
                            </NavLink>
                        </DropdownItem>

                        {this.renderAtp()}

                        {/*{this.renderTechProfiles()}*/}
                        {this.renderInventoryTracking()}

                        {/* {this.renderEquipment()} */}
                    </DropdownMenu>
                </Dropdown>
            </NavItem>
        );
    }

    renderEquipment() {
        let end = new Date(),
            start = null,
            statusName = "ReadyToDeliver",
            targetId = 8;
        return (
            <Route
                render={({ history }) => (
                    <DropdownItem
                        onClick={(e) => {
                            e.stopPropagation();
                            var pathname = "/order";

                            history.push({
                                pathname: [pathname],
                                state: {
                                    shouldOverride: true,
                                    filters: {
                                        statuses: [statusName],
                                        startDate: start,
                                        endDate: end,
                                        reasonIds: [targetId],
                                        locType: "Sales",
                                        userprofileLocations: this.state.currentUser.userprofileLocations,
                                    },
                                },
                            });
                        }}
                    >
                        <div style={{ fontWeight: 300 }}>
                            Equipment Ordered
                        </div>
                    </DropdownItem>
                )}
            />
        );
    }

    renderOperationsNav() {
        // let rsns = this.state.reasons.filter(x =>
        //     x.orderStatus === 1 &&
        //     x.deleted !== true &&
        //     !["Follow Up Task", "Verification", "PDF Review", "Review", "Pre-Auth Submission", "Approval"].includes(x.description) &&
        //     x.description.indexOf("Service -"));
        // let srsns = this.state.reasons.filter(x =>
        //     x.orderStatus === 1 &&
        //     x.deleted !== true &&
        //     [61, 72].includes(x.id));

        return (
            <NavItem>
                <Dropdown size="sm" className={"customNavDropdown"}>
                    <DropdownToggle nav caret style={{ color: "#FFFFFF" }}>
                        {/*<GiGears*/}
                        {/*    size="2em"*/}
                        {/*    data-place={"bottom"}*/}
                        {/*    data-tip={"Operations"}*/}
                        {/*    style={{color: "#5881C1"}}*/}
                        {/*/>*/}
                        <span className={"mainSiteNavText"}> OPERATIONS </span>
                    </DropdownToggle>

                    <DropdownMenu>
                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/order">
                                Orders
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/patientList">
                                Patients
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/internalRequest">
                                Internal Request
                            </NavLink>
                        </DropdownItem>

                        {/*<DropdownItem>*/}
                        {/*    <NavLink style={{padding: 0}} to="/verification">*/}
                        {/*        Verification*/}
                        {/*    </NavLink>*/}
                        {/*</DropdownItem>*/}

                        {/*<DropdownItem>*/}
                        {/*    <NavLink style={{ padding: 0 }} to="/insuranceView">*/}
                        {/*        Insurance View*/}
                        {/*    </NavLink>*/}
                        {/*</DropdownItem>*/}

                        {/*{this.renderFollowUp()}*/}

                        {/* <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/billing">
                                Billing
                            </NavLink>
                        </DropdownItem> */}

                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/collections">
                                Patient Collections
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/recurringOrders">
                                Recurring Orders
                            </NavLink>
                        </DropdownItem>

                        {/* <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/feedback">
                                Customer Feedback
                            </NavLink>
                        </DropdownItem> */}
                        {/* <MDBDropdownItem divider />
                        <MDBDropdownItem header>In Process</MDBDropdownItem>
                        <MDBDropdownItem divider /> */}

                        {this.hasAccess("patientApp") ?
                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/patientApprovals">
                                    Patient App Approvals
                                </NavLink>
                            </DropdownItem>
                            : ""}

                        {/* {this.renderFollowUp()} */}

                        {/* <DropdownItem>
                            <NavLink style={{ padding: 0 }} to={{ pathname: "/verification", state: { header: "Verification" } }}>
                                Verification
                            </NavLink>
                        </DropdownItem> */}
                        {/*
                        {rsns && rsns.length > 0 ?
                            rsns.map((r, idx) => {
                                return <DropdownItem key={idx}>
                                    <NavLink style={{ padding: 0 }} to={{ pathname: "/inProcess/" + r.id, state: { header: r.description } }}>
                                        {r.description}
                                    </NavLink>
                                </DropdownItem>
                            })
                            : ""} */}

                        {/* <MDBDropdownItem divider />
                        <MDBDropdownItem header>Service</MDBDropdownItem>
                        <MDBDropdownItem divider /> */}
                        {/* <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/serviceDoc">
                                Documentation Follow Up
                            </NavLink>
                        </DropdownItem> */}
                        {/* <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/pendingTicket">
                                CSR – Need Service Ticket
                            </NavLink>
                        </DropdownItem> */}

                        {/* {srsns && srsns.length > 0 ?
                            srsns.map((r, idx) => {
                                return <DropdownItem key={idx}>
                                    <NavLink style={{ padding: 0 }} to={{ pathname: "/inProcess/" + r.id, state: { header: r.description } }}>
                                        {r.description}
                                    </NavLink>
                                </DropdownItem>
                            })
                            : ""} */}


                        {/*{this.renderProductCategories()}*/}

                    </DropdownMenu>
                </Dropdown>
            </NavItem>
        );
    }

    renderSalesNav() {
        return (
            <NavItem>
                <Dropdown size="sm" className={"customNavDropdown"}>
                    <DropdownToggle nav caret style={{ color: "#FFFFFF" }}>
                        {/*<GiShakingHands*/}
                        {/*    size="2em"*/}
                        {/*    data-place={"bottom"}*/}
                        {/*    data-tip={"Sales"}*/}
                        {/*    style={{color: "#5881C1"}}*/}
                        {/*/>*/}
                        <span className={"mainSiteNavText"}> SALES </span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/accounts">
                                Accounts
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/activities">
                                Activities
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to="/contacts">
                                Contacts
                            </NavLink>
                        </DropdownItem>

                        {this.renderPCRLink()}
                        {this.renderPresidentsClubNav()}
                        {this.renderBonusNav()}
                        {this.renderLiaisonMeetingsNav()}
                    </DropdownMenu>
                </Dropdown>
            </NavItem>
        );
    }

    renderPresidentsClubNav() {
        if (this.hasAccess("presidentsClub")) {
            return (
                <DropdownItem>
                    <NavLink style={{ padding: 0 }} to="/presidentsClub">
                        Presidents Club
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderBonusNav() {
        if (this.hasAccess("salesBonus")) {
            return (
                <DropdownItem>
                    <NavLink style={{ padding: 0 }} to="/bonus">
                        Sales Bonus
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderLiaisonMeetingsNav() {
        if (this.hasAccess("liaisonMeetings")) {
            return (
                <DropdownItem>
                    <NavLink style={{ padding: 0 }} to="/liaisonMeetings">
                        Sales Liaison Meetings
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderExpensesNav() {
        return (
            <NavItem>
                <NavLink to="/expenses">
                    {/*<FaDollarSign*/}
                    {/*    size="2em"*/}
                    {/*    data-place={"bottom"}*/}
                    {/*    data-tip={"Expenses"}*/}
                    {/*    style={{color: "#5881C1"}}*/}
                    {/*/>*/}
                    <span className={"mainSiteNavText"}> EXPENSES </span>
                </NavLink>
            </NavItem>
        );
    }

    renderPurchasingNav() {
        if (this.hasAccess("purchasing")) {
            return (
                <NavItem>
                    <Dropdown size="sm" className={"customNavDropdown"}>
                        <DropdownToggle nav caret style={{ color: "#FFFFFF" }}>
                            {/*<FaBoxes*/}
                            {/*    size="2em"*/}
                            {/*    data-place={"bottom"}*/}
                            {/*    data-tip={"Purchasing"}*/}
                            {/*    style={{color: "#5881C1"}}*/}
                            {/*/>*/}
                            <span className={"mainSiteNavText"}> PURCHASING </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/purchaseQueues">
                                    Purchase Queues
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink
                                    style={{ padding: 0 }}
                                    to="#!"
                                    onClick={() => {
                                        this.setState({ inventoryRequestOpen: true });
                                    }}
                                >
                                    Inventory Request
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink
                                    style={{ padding: 0 }}
                                    to="#!"
                                    onClick={() => {
                                        this.setState({ poDeliveryUpdateOpen: true });
                                    }}
                                >
                                    PO Delivery Update
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/inventoryProducts">
                                    Inventory Products
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/vendors">
                                    Vendors
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/shippingDetails">
                                    Shipping Details Report
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/serviceParts">
                                    Manage Service Parts
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>
            );
        }
        return <div />;
    }

    renderMarketingNav() {
        if (this.hasAccess("marketing")) {
            return (
                <NavItem>
                    <Dropdown size="sm">
                        <DropdownToggle nav caret style={{ color: "#FFFFFF" }}>
                            {/*<MdPermMedia*/}
                            {/*    size="2em"*/}
                            {/*    data-place={"bottom"}*/}
                            {/*    data-tip={"Marketing"}*/}
                            {/*    style={{color: "#5881C1"}}*/}
                            {/*/>*/}
                            <span className={"mainSiteNavText"}> MARKETING </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/campaignList">
                                    Campaigns
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/campaignCategories">
                                    Categories
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/campaignCharts">
                                    Charts
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>
            );
        }
        return <div />;
    }

    renderAdminNav() {
        if (this.hasAccess("admin") || this.hasAccess("userAdmin")) {
            return (
                <NavItem>
                    <Dropdown size="sm" className={"customNavDropdown"}>
                        <DropdownToggle nav caret style={{ color: "#FFFFFF" }}>
                            {/*<MdSecurity*/}
                            {/*    size="2em"*/}
                            {/*    data-place={"bottom"}*/}
                            {/*    data-tip={"Admin"}*/}
                            {/*    style={{color: "#5881C1"}}*/}
                            {/*/>*/}
                            <span className={"mainSiteNavText"}> ADMIN </span>
                        </DropdownToggle>
                        <DropdownMenu>

                            <MDBDropdownItem divider style={{ marginTop: "0px" }} />
                            <MDBDropdownItem header>User Management</MDBDropdownItem>
                            <MDBDropdownItem divider />

                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/userList">
                                    Users
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/admin/departments">
                                    Departments
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/admin/teams">
                                    Teams
                                </NavLink>
                            </DropdownItem>

                            {/* <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/userRoleQuotas">
                                    Roles and Quotas
                                </NavLink>
                            </DropdownItem> */}

                            {/* <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/thirdPartyUserList">
                                    Third Party Users
                                </NavLink>
                            </DropdownItem> */}
                            {/*
                            <MDBDropdownItem divider />
                            <MDBDropdownItem header>Products</MDBDropdownItem>
                            <MDBDropdownItem divider />

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/products">
                                    Product Categories
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/productSizes">
                                    Product Sizes
                                </NavLink>
                            </DropdownItem> */}

                            {(this.hasAccess("admin")) && (
                                <>
                                    <MDBDropdownItem divider />
                                    <MDBDropdownItem header>Insurance</MDBDropdownItem>
                                    <MDBDropdownItem divider />

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/insurance">
                                            Payor Sources
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/insuranceSubTypes">
                                            Insurance
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/admin/insuranceSubTypeGroups">
                                            Insurance Groups
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/insuranceSubTypeDetails">
                                            Insurance Details
                                        </NavLink>
                                    </DropdownItem>

                                    <MDBDropdownItem divider />
                                    <MDBDropdownItem header>Sales</MDBDropdownItem>
                                    <MDBDropdownItem divider />

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/accountTypes">
                                            Account Types
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/notificationTypes">
                                            Notification Types
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/contactTypes">
                                            Contact Types
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/creditCardTypes">
                                            Credit Card Types
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/expenseTypes">
                                            Expense Types
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/documentTypes">
                                            Document Types
                                        </NavLink>
                                    </DropdownItem>

                                    <MDBDropdownItem divider />
                                    <MDBDropdownItem header>System Config</MDBDropdownItem>
                                    <MDBDropdownItem divider />

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/reasons">
                                            Status Reasons
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/locations">
                                            Locations
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/admin/regions">
                                            Regions
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/stateSalesTax">
                                            State Sales Tax
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/manualDocumentBuilder">
                                            Manual Document Builder
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/homeAssessmentCompare">
                                            Home Assessment Compare
                                        </NavLink>
                                    </DropdownItem>

                                    {/* <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/deployments">
                                            Deployment
                                        </NavLink>
                                    </DropdownItem> */}

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/deliveryAcknowledgements">
                                            Delivery Form Acknowledgments
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/version">
                                            Web Version
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/admin/orderActivityTypes">
                                            Order Activity Types
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/admin/queues">
                                            Queues
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/admin/queueTypes">
                                            Queue Types
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/admin/queueRoutes">
                                            Queue Routes
                                        </NavLink>
                                    </DropdownItem>

                                    {this.renderProductCategories()}

                                    <MDBDropdownItem divider />
                                    <MDBDropdownItem header>Patient Config</MDBDropdownItem>
                                    <MDBDropdownItem divider />

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/customerFeedback">
                                            Customer Feedback Settings
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/trainingLink">
                                            Patient Training Videos
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/productMatrixEntry">
                                            Product Matrix Entries
                                        </NavLink>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <NavLink style={{ padding: 0 }} to="/patientApprovals">
                                            Patient App Approvals
                                        </NavLink>
                                    </DropdownItem>
                                </>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>
            );
        }
        return <div />;
    }

    renderLogoutNav() {
        return (
            <NavItem>
                <NavLink
                    onClick={() => {
                        let msalAccount = this.state.msalAccount,
                            companies = [
                                {
                                    name: 'Rehab Medical', value: {
                                        name: 'rehabmedical',
                                        authConfig: {
                                            auth: {
                                                clientId: "a06bbb83-c8df-45ea-b686-452403137476",
                                                authority: "https://login.microsoftonline.com/f2c81385-bf14-4df0-ac96-e01bda2adf46/",
                                                replyUrl: window.location.origin + "/dashboard",
                                                request: {
                                                    scopes: ["openid", "profile", "User.Read"],
                                                    forceRefresh: false,
                                                },
                                            },
                                        },
                                    }
                                },
                                {
                                    name: 'Cork Medical', value: {
                                        name: 'corkmedical',
                                        authConfig: {
                                            auth: {
                                                clientId: "12640c30-8132-4ae0-a7f0-f3532247643c",
                                                authority: "https://login.microsoftonline.com/ea8b3ecd-6c46-48e8-aad6-583c904c3d0a/",
                                                replyUrl: window.location.origin + "/dashboard",
                                                request: {
                                                    scopes: ["openid", "profile", "User.Read"],
                                                    forceRefresh: false,
                                                },
                                            },
                                        },
                                    }
                                },
                                {
                                    name: 'UAT', value: {
                                        name: 'uat',
                                        authConfig: {
                                            auth: {
                                                clientId: "a06bbb83-c8df-45ea-b686-452403137476",
                                                authority: "https://login.microsoftonline.com/f2c81385-bf14-4df0-ac96-e01bda2adf46/",
                                                replyUrl: window.location.origin + "/dashboard",
                                                request: {
                                                    scopes: ["openid", "profile", "User.Read"],
                                                    forceRefresh: false,
                                                },
                                            },
                                        },
                                    }
                                },
                            ],
                            company = companies.filter(x => {
                                if (x.name === this.state.company)
                                    return x;
                                else
                                    return companies[2];
                            })[0];

                        if (company && msalAccount) {
                            let pca = new msal.PublicClientApplication({ auth: company.value.authConfig, system: { allowRedirectInIframe: true } }),
                                logoutRequest = {
                                    account: pca.getAccountByUsername(msalAccount.username),
                                    onRedirectNavigate: () => {
                                        return false;
                                    }
                                };
                            //const hint = msalAccount.idTokenClaims.login_hint;
                            pca.logoutRedirect(logoutRequest);
                        }

                        window.sessionStorage.clear();
                        this.setUserFunction(null);
                        this.setCompanyFunction(null, null);
                        this.setState({ userMetricsVisible: false });
                    }}
                    to={"/login"}
                >
                    <FiLogOut
                        size="2em"
                        data-place={"bottom"}
                        data-tip={"Logout"}
                        style={{ color: "#FFFFFF" }}
                    />
                </NavLink>
            </NavItem>
        );
    }

    retrieveCurrentMetrics() {
        orderService.getUserQuotaMetrics(this.state.currentUser.id).then(res => {

            this.setState({
                userMetricsVisible: true,
                metricsData: res
            })
        }).catch(e => {
            console.log(e);
        })
    }

    toggleMetrics() {
        let v = !this.state.userMetricsVisible;

        if (v === true) {
            this.retrieveCurrentMetrics()
        } else {
            this.setState({ userMetricsVisible: v })
        }
    }

    renderNavBarItems() {
        //TODO: remove this check...BC
        if (this.state.notLoggedIn === true) {
            //don't need a navbar here.  show nothing...BC
            return <div />;
        } else {
            return (
                <Navbar
                    className={"mainSiteNavBar"}
                    // color={"white"}
                    // style={{paddingTop: 0, paddingBottom: 0}}
                    expand="xs"
                    fixed="top">
                    <ReactTooltip />
                    {/* <MDBIcon size={"1x"} icon="angle-double-down"
                             style={{paddingRight: 8, color: "#5881C1"}}
                             onClick={() => this.toggleMetrics()}
                    /> */}
                    <NavbarBrand
                        style={{ fontWeight: 500, color: "#FFFFFF" }}
                        href="/dashboard">

                        <img
                            src={require("./images/salesPilotLogoIconBWsmall.png")}
                            alt={"Sales Pilot Logo"}
                        />
                        &nbsp; Sales Pilot
                    </NavbarBrand>

                    <NavbarNav className={"mainSiteNavBarNav"}>
                        {this.renderOrderIdSearch()}
                        {this.renderReverseQualityModalIcon()}
                        {this.renderReportsDropdown()}
                        {this.renderTasksNav()}
                        {this.renderFaxesNav()}
                        {this.renderOperationsNav()}
                        {this.renderFieldOperationsNav()}
                        {this.renderSalesNav()}
                        {this.renderExpensesNav()}
                        {this.renderPurchasingNav()}
                        {this.renderMarketingNav()}
                        {this.renderAdminNav()}
                        {this.renderLogoutNav()}
                    </NavbarNav>
                </Navbar>
            );
        }
    }

    renderNavbarCollapse() {
        return (
            <MDBCollapse id="collapse1"
                isOpen={this.state.userMetricsVisible}
                style={{
                    position: "fixed",
                    top: 50,
                    backgroundColor: '#5881C1',
                    boxShadow: "0px 5px 5px #375177",
                    zIndex: 5000,
                    right: 0,
                    left: 0
                }}>
                <UserMetricCollapse history={this.props.history} metricsData={this.state.metricsData}
                    roleQuotas={this.state.userRoleQuotas} />
            </MDBCollapse>
        )
    }

    renderUpdateNotesModal() {
        return (
            <MDBModal
                size="lg"
                isOpen={this.state.updateNotesModalOpen}
                toggle={() => {
                    this.setState({
                        updateNotesModalOpen: false,
                    });
                }}>
                <MDBModalHeader style={{ color: 'white', backgroundColor: '#5881c1' }}>
                    Update Notes
                </MDBModalHeader>

                <MDBCard style={{ maxHeight: 450, overflow: 'scroll' }}>
                    <MDBCardBody>
                        <UpdateNotes />
                    </MDBCardBody>
                </MDBCard>
            </MDBModal>
        );
    }

    render() {
        let st = this.state;

        function loggedIn() {
            if (st.notLoggedIn === true) {
                return <Redirect to={"/"} />
            }
        }

        if (this.state.loadingData === true) {
            return (
                <div className="flyout App-background mainBackground" style={{ backgroundImage: `url(${background})` }}>
                    <MUIBackdrop open={true}>
                        <MUIPaper sx={{ padding: 4 }}>
                            <Progress
                                variant="determinate"
                                value={this.state.loadingDone / this.state.loadingCount * 100}
                                message="One moment while we fetch some data..."
                            />
                        </MUIPaper>
                    </MUIBackdrop>
                </div>
            );
        }

        let displayedCompany;
        switch (this.state.company) {
            case 'Rehab Medical':
            case 'rehabmedical':
                displayedCompany = 'Rehab Medical';
                break;
            case 'Cork Medical':
            case 'corkmedical':
                displayedCompany = 'Cork Medical';
                break;
            case 'UAT':
            case 'uat':
                displayedCompany = 'UAT';
                break;
            default:
                displayedCompany = this.state.company;
                break;
        }

        return (
            <Router>
                <SecurityProvider />
                <NotistackSnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} maxSnack={3} />
                {loggedIn()}
                <div className="flyout App-background mainBackground" style={{ backgroundImage: `url(${background})` }}>

                    <div>
                        {this.renderOrderSearchModal()}
                        {this.renderReverseQualityModal()}
                        {this.renderQualityAssuranceScorecard()}
                        {this.renderInventoryRequestModal()}
                        {this.renderPODeliveryUpdateModal()}
                        {this.renderRedirect()}
                        {this.renderNavBarItems()}
                        {this.renderNavbarCollapse()}
                        {this.renderUpdateNotesModal()}
                        <MainProvider value={this.state}>
                            <main className={"App-main"}>
                                <Routes />
                            </main>
                        </MainProvider>
                    </div>

                    <div>
                        {st.notLoggedIn === false && (
                            <Footer className={"App-footer"}>
                                <span style={{ paddingLeft: 8, cursor: "pointer" }}
                                    onClick={() => this.setState({ updateNotesModalOpen: true })}>
                                    <MDBIcon icon="info-circle"
                                        style={{ paddingRight: 8 }}
                                    />
                                    Version {window.salesPilot.meta.version} - {displayedCompany}
                                </span>
                                <span>
                                    <MUIFormControlLabel
                                        sx={{ height: "20px", paddingTop: "10px", float: "right" }}
                                        control={<MUISwitch color="default" defaultChecked={Setting.get("ui.theme") === "dark" ? true : false} />}
                                        onChange={(e) => {
                                            Setting.set("ui.theme", e.target.checked ? "dark" : "light");
                                        }}
                                        label={"Dark Mode"}
                                    />
                                </span>
                            </Footer>
                        )}
                    </div>
                </div>
                {/* TODO replace with Notistack */}
                <MUISnackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={this.state.newVersionAvailable}
                >
                    <MUIAlert
                        severity="info"
                        variant="filled"
                        action={
                            <MUIButton
                                color="inherit"
                                size="small"
                                onClick={() => { window.location.reload() }}
                            >
                                FINISH UPDATE
                            </MUIButton>
                        }
                    >{`Sales Pilot ${this.state.manifestVersion} available.`}</MUIAlert>
                </MUISnackbar>
            </Router>
        );

    }
}

export default App;
