import Crud from "../Crud";

/**
 * Static helper functions related to order statuses.
 */
export default class OrderStatus extends Crud {
    /**
     * @param {object} orderStatus The orderStatus object.
     * @returns The display name of the orderStatus.
     */
    static getDisplayName(orderStatus) {
        return orderStatus.name;
    }

    /**
     * Determines if an orderStatus matches a search query.
     *
     * @param {object} orderStatus The orderStatus to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the orderStatus matches the query.
     */
    static match(orderStatus, query) {
        return (
            (orderStatus.name.toLowerCase().includes(query)) ||
            orderStatus.id === +query
        );
    }

    /**
     * @param {array} orderStatuses The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(orderStatuses) {
        return orderStatuses.sort((a, b) => {
            return a.id - b.id;
        });
    }


    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        throw new Error("Use orderStatus.colorId");
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Topic";
    }
}