import React from "react";
import {
	Button,
	Card,
	CardBody,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	CardHeader,
	MDBRow,
	MDBCol,
	MDBIcon,
} from "mdbreact";
import { Route } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";
import Api from "../Security/api";
import AuthService from "../Security/authService";
import ReactTooltip from "react-tooltip";
import OrderService from "../Security/OrderService/orderService";
import MarketingService from "../Security/MarketingService/marketingService";

const companies = [
	{ label: "Rehab Medical", value: "rehabmedical" },
	{ label: "Cork Medical", value: "corkmedical" },
	{ label: "OMS Rehab", value: "omsrehab" },
	{ label: "UAT", value: "uat" },
];

const removals = [
	"id",
	"insuranceGroup",
	"insurancePolicy",
	"insuranceSubType",
	"secondaryInsuranceGroup",
	"secondaryInsurancePolicy",
	"secondaryInsuranceSubType",
	"secondaryInsuranceType",
];

export default class PatientCopy extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			loginModal: false,
			patientModal: false,
			credentials: {
				username: "",
				password: "",
				company: "",
				loggedIn: false,
				error: "",
				companies: companies,
				currentUser: {},
			},
			otherCompanyCredentials: {},
			nPatient: {},
			patientId: "",
			companiesSelectOptions: [],
			patientReady: false,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	componentDidMount() {
		this.getT();
	}

	getT = () => {
		Api.getToken()
			.then((res) => {
				this.setState({
					companiesSelectOptions: companies.filter(
						(c) => c.value !== res.company
					),
				});
			})
			.catch((err) => {
							console.log(err);
						});
	};

	toggleLoginModal = () => {
		this.setState({
			loginModal: !this.state.loginModal,
		});
	};

	togglePatientModal = () => {
		let st = this.state;
		let p = st.nPatient;
		if (st.patientModal === true) {
			p = {};
		}
		this.setState({
			patientModal: !this.state.patientModal,
			nPatient: p,
		});
	};

	loginClicked = () => {
		AuthService.login(this.state.credentials, true)
			.then((res) => {
				let c = res;
				c.company = this.state.credentials.company;
				this.setState({
					otherCompanyCredentials: c,
					loginModal: !this.state.loginModal,
				});
				this.patientClicked();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	handleLoginChange(prop, e) {
		let c = this.state.credentials;
		c[prop] = e.target.value;
		this.setState({
			credentials: c,
		});
	}

	patientClicked = () => {
		let st = this.state;
		// Make sure they are signed into the correct other company and a patient ID has been entered
		if (
			Object.keys(st.otherCompanyCredentials).length > 0 &&
			st.otherCompanyCredentials.company === st.credentials.company &&
			st.patientId
		) {
			AuthService.getPatientByIdFromOtherCompany(this.state.patientId, null, this.state.otherCompanyCredentials)
				.then((res) => {
					// remove unneeded properties from the patient obj...JK
					removals.map((e) => {
						return delete res[e];
					});

					res.account = { id: 49974 };

					this.setState({
						nPatient: res,
					});
					this.getInsurance(res.insuranceType.id);
				})
				.catch((err) => {
							console.log(err);
						});
		} else {
			this.toggleLoginModal();
		}
	};

	getInsurance(insuranceId) {
		let params = {
				company: this.state.otherCompanyCredentials.company,
				insuranceId: insuranceId,
			},
			p = this.state.nPatient;

		MarketingService.getInsuranceFromMapping(params)
			.then((res) => {
				// Map the insurance using the correct insurance ID
				p.insuranceType = { id: res.marketingInsuranceTypes[0].id, name: res.marketingInsuranceTypes[0].name };
				this.setState({
					nPatient: p,
				});
				this.togglePatientModal();
			})
			.catch((err) => {
				console.log(err);
			});
	}

	savePatient(history) {
		let patient = this.state.nPatient;
		return OrderService.createPatient(patient)
			.then((res) => {
				history.push({
					pathname: "/patientInformation/" + res.id,
					state: { patient: res },
				});
			})
			.catch((err) => {
							console.log(err);
						});
	}

	renderLogin() {
		return (
			<Modal
				centered
				size="fluid"
				isOpen={this.state.loginModal}
				toggle={this.toggleLoginModal}
			>
				<CardHeader
					style={{ borderRadius: "8px" }}
					className="form-header text-center font-weight-bold blue-gradient"
				>
					<h3>Log into {this.state.credentials.company}</h3>
				</CardHeader>
				<ModalBody>
					<Input
						type="text"
						label={"Username"}
						autocomplete="username"
						onChange={this.handleLoginChange.bind(this, "username")}
					/>
					<Input
						type="password"
						label={"Password"}
						autocomplete="password"
						onChange={this.handleLoginChange.bind(this, "password")}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						floating
						size="sm"
						data-tip={"Sign In"}
						onClick={this.loginClicked}
					>
						<MDBIcon icon="user" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	patientModal() {
		let patient = this.state.nPatient;
		return (
			<Modal
				centered
				size="fluid"
				isOpen={this.state.patientModal}
				toggle={this.togglePatientModal}
			>
				<CardHeader
					style={{ borderRadius: "8px" }}
					className="form-header text-center font-weight-bold blue-gradient"
				>
					<h3>
						{" "}
						{patient.firstName}&nbsp;{patient.lastName}
					</h3>
				</CardHeader>
				<ModalBody>
					<div>
						<p>{patient.dateOfBirth}</p>
						<p>{patient.address}</p>
						<p>
							{patient.city + " , " + patient.state + " " + patient.zipCode}
						</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<Route
						render={({ history }) => (
							<Button
								floating
								size="sm"
								data-tip={"Save"}
								onClick={() => this.savePatient(history)}
							>
								<MDBIcon icon="paste" style={{ fontSize: "2em" }} />
							</Button>
						)}
					/>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	handleCompanySelect = (e) => {
		let c = this.state.credentials;
		c.company = e.value;
		this.setState({
			credentials: c,
		});
	};

	render() {
		let st = this.state;
		return (
			<div style={{ marginLeft: "15%", marginRight: "15%", marginTop: "5%" }}>
				<Card>
					<CardHeader>Patient Copy</CardHeader>
					<CardBody>
						<MDBRow>
							<MDBCol>
								<Input
									label="Patient Id"
									onChange={(event) =>
										this.setState({ patientId: event.target.value })
									}
								/>
							</MDBCol>
						</MDBRow>
						<MDBRow style={{ marginTop: "5%" }}>
							<MDBCol>
								<Select
									placeholder="Copy from Company..."
									options={st.companiesSelectOptions}
									onChange={this.handleCompanySelect.bind(this)}
								/>
							</MDBCol>
						</MDBRow>
						<MDBRow style={{ marginTop: "5%" }}>
							<MDBCol>
								<Button
									floating
									size="sm"
									data-tip={"Copy Patient"}
									style={{ float: "right" }}
									onClick={this.patientClicked}
								>
									<MDBIcon icon="clone" style={{ fontSize: "2em" }} />
								</Button>
							</MDBCol>
						</MDBRow>
					</CardBody>
				</Card>
				<ReactTooltip />
				{this.renderLogin()}
				{this.patientModal()}
			</div>
		);
	}
}
