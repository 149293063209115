import React from "react";
import { Route } from "react-router-dom";
import AuthService from "../Security/authService";
import {
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBStepper,
	MDBStep,
	MDBBtn,
	MDBInput,
	CardBody,
	Card,
	toast,
	ToastContainer,
} from "mdbreact";
import Select from "react-select";
import UserService from "../Security/UserService/userService";

let companies = [
	{ label: "Rehab Medical", value: "rehabmedical" },
	{ label: "Cork Medical", value: "corkmedical" },
	{ label: "OMS Rehab", value: "omsrehab" },
	{ label: "UAT", value: "uat" },
];

export default class forgotPassword extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			formActivePanel1: 1,
			formActivePanel1Changed: false,
			company: props.location.state || null,
			form: {
				username: "",
				email: "",
				code: "",
				password: "",
				confirmPassword: "",
			},
			userId: "",
			token: "",
		};
	}

	handleNextPrevClick = (a) => (param) => (e) => {
		this.setState({
			["formActivePanel" + a]: param,
			["formActivePanel" + a + "Changed"]: true,
		});
	};

	calculateAutofocus = (a) => {
		if (this.state["formActivePanel" + a + "Changed"]) {
			return true;
		}
	};

	sendValidationCode = () => {
		let st = this.state;
		if (!st.company) {
			toast.warn("Please select a company from login page");
			return;
		}
		if (!st.form.email || !st.form.username) {
			toast.warn("Please enter your username and email");
			return;
		}
		AuthService.validateCode(
			{ email: st.form.email, username: st.form.username },
			st.company,
			"/sendValidateCode"
		)
			.then((res) => {
				if (res.message.substr(0, 5) === "User:") {
					toast.success("Email Sent");
					this.setState({
						formActivePanel1: 2,
						formActivePanel1Changed: true,
						userId: res.message.substr(5),
					});
				} else {
					toast.warn(res.message);
					return;
				}
			})
			.catch((err) => {
							console.log(err);
						});
	};

	// calls proc spGetTextCode
	checkCode = () => {
		let st = this.state;
		if (!st.form.code) {
			toast.warn("Please enter code from email");
			return;
		}
		// actual endpoint URL for proc call
		AuthService.validateCode(
			{ userId: st.userId, code: st.form.code },
			st.company,
			"/checkValidateCode"
		)
			.then((res) => {
				if (res.token) {
					this.setState({
						formActivePanel1: 3,
						formActivePanel1Changed: true,
						token: res,
					});
				} else {
					toast.warn("Invalid Code");
				}
			})
			.catch((err) => {
							console.log(err);
						});
	};

	changePassword = () => {
		if (this.validatePassword()) {
			let st = this.state,
				t = this.state.token;
			t.company = st.company;

			UserService.changePassword({
				username: st.form.username,
				password: st.form.password,
			})
				.then((res) => {
					window.sessionStorage.setItem("credentials", JSON.stringify(t));
					this.props.history.push({
						pathname: "/login",
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	validatePassword() {
		let st = this.state,
			p = st.form.password,
			p1 = st.form.confirmPassword;
		if (p && p1 && p === p1) {
			return true;
		}
		toast.warn("Please make sure passwords match");
		return false;
	}

	handleInputChange(prop, e) {
		let f = this.state.form;
		f[prop] = e.target.value;
		this.setState({
			form: f,
		});
	}

	render() {
		let st = this.state;
		return (
			<MDBContainer>
				<ToastContainer />
				<Card>
					<CardBody>
						<h2 className="text-center font-weight-bold pt-4 pb-5 mb-2">
							<strong>Forgot Password</strong>
						</h2>
						<MDBStepper icon>
							<MDBStep far icon="user" stepName="Basic Information"></MDBStep>
							<MDBStep icon="pencil-alt" stepName="Verification"></MDBStep>
							<MDBStep icon="lock" stepName="New Password"></MDBStep>
						</MDBStepper>

						<form action="" method="post">
							<MDBRow>
								{st.formActivePanel1 === 1 && (
									<MDBCol md="12">
										<h3 className="font-weight-bold pl-0 my-4">
											<strong>Basic Information</strong>
										</h3>
										<Select
											placeholder={
												st.company
													? companies.filter((c) => {
															return c.value === st.company;
													  })[0].label
													: "Select Company..."
											}
											options={companies}
											onChange={(e) => {
												this.setState({ company: e.value });
											}}
										/>
										<MDBInput
											label="Username"
											onChange={this.handleInputChange.bind(this, "username")}
											className="mt-4"
											autoFocus={this.calculateAutofocus(1)}
										/>
										<MDBInput
											label="Email"
											onChange={this.handleInputChange.bind(this, "email")}
											className="mt-4"
										/>
										<Route
											render={({ history }) => (
												<MDBBtn
													color="mdb-color"
													rounded
													className="float-left"
													onClick={() =>
														history.push({
															pathname: "/login/",
														})
													}
												>
													Return to Login
												</MDBBtn>
											)}
										></Route>
										<MDBBtn
											color="mdb-color"
											rounded
											className="float-right"
											onClick={this.sendValidationCode}
										>
											next
										</MDBBtn>
									</MDBCol>
								)}

								{st.formActivePanel1 === 2 && (
									<MDBCol md="12">
										<h3 className="font-weight-bold pl-0 my-4">
											<strong>Verification</strong>
										</h3>
										<MDBInput
											label="Validation Code"
											onChange={this.handleInputChange.bind(this, "code")}
											className="mt-3"
											autoFocus={this.calculateAutofocus(1)}
										/>
										<MDBBtn
											color="mdb-color"
											rounded
											className="float-left"
											onClick={this.handleNextPrevClick(1)(1)}
										>
											Didn't Receive Code?
										</MDBBtn>
										<MDBBtn
											color="mdb-color"
											rounded
											className="float-right"
											onClick={this.checkCode}
										>
											next
										</MDBBtn>
									</MDBCol>
								)}

								{st.formActivePanel1 === 3 && (
									<MDBCol md="12">
										<h3 className="font-weight-bold pl-0 my-4">
											<strong>New Password</strong>
										</h3>
										<MDBInput
											label="New Password"
											type="password"
											onChange={this.handleInputChange.bind(this, "password")}
											className="mt-4"
											autoFocus={this.calculateAutofocus(1)}
										/>
										<MDBInput
											label="Retype Password"
											type="password"
											onChange={this.handleInputChange.bind(
												this,
												"confirmPassword"
											)}
											className="mt-4"
										/>
										<MDBBtn
											color="mdb-color"
											rounded
											className="float-right"
											onClick={this.changePassword}
										>
											Save
										</MDBBtn>
									</MDBCol>
								)}
							</MDBRow>
						</form>
					</CardBody>
				</Card>
			</MDBContainer>
		);
	}
}