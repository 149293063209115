import React from "react";
import PropTypes from "prop-types";
import {
	Button,
	MDBTabContent,
	MDBTabPane,
	Nav,
	NavItem,
	NavLink,
	MDBIcon,
} from "mdbreact";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import ReactTooltip from "react-tooltip";
import classnames from "classnames";
const InventoryProductsTable = React.lazy(() =>
	import("./inventoryProductTable")
);

export default class inventoryProductView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeItemJustified: "1",
			printTable: 0,
			currentUser: {},
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	componentDidMount() {
		const { currentUser } = this.context;
		this.setState({
			currentUser: currentUser,
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { currentUser } = this.context;
		if (this.state.currentUser.id !== currentUser.id) {
			this.setState({
				currentUser: currentUser,
			});
		}
	}

	toggleJustified = (tab) => (e) => {
		if (this.state.activeItemJustified !== tab) {
			this.setState({
				activeItemJustified: tab,
			});
		}
	};

	getLocations() {
		return { location: this.state.currentUser.userprofileLocations[0].locationsId };
	}

	getAckPrams() {
		let p = this.getLocations();
		p.acknowledged = "true";
		return p;
	}

	render() {
		let st = this.state;
		return (
			<div>
				<div
					style={{
						position: "absolute",
						top: "9%",
						left: "1rem",
						zIndex: "50000",
					}}
				>
					<Button
						style={{ padding: "inherit" }}
						floating
						size="md"
						data-tip={"Print Table"}
						onClick={() => {
							let pt = this.state.printTable;
							this.setState({
								printTable: ++pt,
							});
						}}
					>
						<MDBIcon icon="print" style={{ fontSize: "2em" }} />
					</Button>
					<ReactTooltip />
				</div>
				<Nav tabs className="nav-justified" style={{width: "100%", backgroundColor: "#5881C1", margin: 0}}>
					<NavItem>
						<NavLink
							link
							to="#"
							className={classnames({
								active: this.state.activeItemJustified === "1",
							})}
							onClick={this.toggleJustified("1")}
							role="tab"
						>
							Not Acknowledged
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							link
							to="#"
							className={classnames({
								active: this.state.activeItemJustified === "2",
							})}
							onClick={this.toggleJustified("2")}
							role="tab"
						>
							Acknowleged
						</NavLink>
					</NavItem>
				</Nav>
				<MDBTabContent  style={{ margin: "2% 2% 0 2%" }} className="card" activeItem={st.activeItemJustified}>
					<MDBTabPane tabId="1" role="tabpanel">
						{st.activeItemJustified === "1" &&
						st.currentUser.id !== undefined ? (
							<React.Suspense fallback={<div>...Loading...</div>}>
								<InventoryProductsTable
									params={this.getLocations()}
									printer={this.state.printTable}
								/>
							</React.Suspense>
						) : (
							<div />
						)}
					</MDBTabPane>
					<MDBTabPane tabId="2" role="tabpanel">
						{st.activeItemJustified === "2" &&
						st.currentUser.id !== undefined ? (
							<React.Suspense fallback={<div>...Loading...</div>}>
								<InventoryProductsTable
									params={this.getAckPrams()}
									printer={this.state.printTable}
								/>
							</React.Suspense>
						) : (
							<div />
						)}
					</MDBTabPane>
				</MDBTabContent>
			</div>
		);
	}
}
