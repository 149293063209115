import React from "react";
import {
	Button,
	MDBCloseIcon,
	MDBIcon,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	Row,
	Col,
	MDBModalFooter,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import ReactDOMServer from 'react-dom/server';
import GlobalFunctions from "../../Filters/GlobalFunctions";

export default class PverifyModal extends React.Component {
	constructor(props) {
		super(props);

		this.toggleInsuranceModal = props.toggleInsuranceModal;
		this.toggleNoteModal = props.toggleNoteModal;
		this.routeToVerification = props.routeToVerification;

		this.state = {
			patientId: props.patientId,
			results: props.results || [],
			eOrder: props.eOrder,
		}
    }

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	toCamel(obj) {
		var newO,
			origKey,
			newKey,
			value;
		if (obj instanceof Array) {
			return obj.map(value => {
				if (typeof value === "object") {
					value = this.toCamel(value);
				}
				return value;
			})
		}
		else {
			newO = {};
			for (origKey in obj) {
				if (obj.hasOwnProperty(origKey)) {
					if (["HCPCS"].includes(origKey))
						newKey = origKey.toLowerCase();
					else if (origKey.toLowerCase() === "dosend")
						newKey = "dosEnd";
					else if (origKey.toLowerCase() === "dosstart")
						newKey = "dosStart";
					else if (origKey.toLowerCase() === "apiresponsecode")
						newKey = "apiResponseCode";
					else if (origKey.toLowerCase() === "apiresponsemessage")
						newKey = "apiResponseMessage";
					else
						newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
					value = obj[origKey];
					if (value instanceof Array || (value !== null && typeof value === "object")) {
						value = this.toCamel(value);
					}
					newO[newKey] = value;
				}
			}
		}
		return newO;
	}

	renderOptions(op) {
		if (!op)
			return <div />;

		let pvPrimaryTemp = op.source === "pVerify" && op.resultType === "Primary" ? JSON.parse(op.stringResult.replace(/\r\n/g, "")) : null;
		let pvSecondaryTemp = op.source === "pVerify" && op.resultType === "Secondary" ? JSON.parse(op.stringResult.replace(/\r\n/g, "")) : null;

		let pvPrimary = [];

		let pvSameSim = op.source === "pVerify" && op.resultType === "SameSim" ? JSON.parse(op.stringResult) : null;
		let pvSnf = op.source === "pVerify" && op.resultType === "SNF" ? JSON.parse(op.stringResult) : null;
		let msg = this.state.insuranceError;

		let pvSnf2 = null,
			pvSameSim2 = [];

		if (pvPrimaryTemp) {
			pvPrimary.push(pvPrimaryTemp);
		}

		if (pvSecondaryTemp) {
			pvPrimary.push(pvSecondaryTemp);
		}

		if (pvSnf && pvSnf.APIResponseMessage) {
			pvSnf2 = this.toCamel(pvSnf);
		}

		if (pvSameSim) {
			pvSameSim2.push(this.toCamel(pvSameSim))
		}

		let claims = [];
		if (pvSameSim2 && pvSameSim2.length > 0) {
			pvSameSim2.forEach(x => {
				if (x.claims && x.claims.length > 0)
					x.claims.map(c => claims.push(c))
			});
		}

		let lst = [];
		if (claims && claims.length > 0) {
			claims.forEach(x => {
				if (x) {
					lst.push({
						code: x.hcpcs.substring(0, 5),
						start: x,
						end: x,
					})
				}
			})
		}

		let details = pvSnf2 && pvSnf2.details && pvSnf2.isDataError !== true ? pvSnf2.details : [];

		var icon = claims.length === 0 || claims.filter(x => x.status === "Denied" && x.hcpcs && x.hcpcs.length > 0 && GlobalFunctions.containsHcpcs(x.hcpcs)).length === 0 ? "check-circle" : "times-circle";
		var bgco = claims.length === 0 || claims.filter(x => x.status === "Denied" && x.hcpcs && x.hcpcs.length > 0 && GlobalFunctions.containsHcpcs(x.hcpcs)).length === 0 ? "green" : "red";

		var icon2 = details.length === 0 || details.filter(x => !x.dischargedDate && !x.dosEnd).length === 0 ? "check-circle" : "times-circle";
		var bgco2 = details.length === 0 || details.filter(x => !x.dischargedDate && !x.dosEnd).length === 0 ? "green" : "red";

		let asOf = pvPrimary && pvPrimary.length > 0 ? pvPrimary.map(x => x.DOS) : [];
		let showSnf = pvSnf2 ? "block" : "none";
		let showSs = pvSameSim2 && pvSameSim2.length > 0 ? "block" : "none";
		let showCvg = pvPrimary && pvPrimary.length > 0 ? "block" : "none";

		return (<div>
			{msg && msg.length > 0 ?
				<Row>
					<Col size="12" style={{ color: this.state.msgColor }}>
						<b>{msg}</b>
					</Col>
				</Row>
				: ''
			}

			<Row>
				{/*SAME/SIM*/}
				<Col size="3" style={{ display: showSs}}>
					<div style={{ padding: "10px", borderRadius: "20px", border: "2px solid black", backgroundColor: "aliceblue" }}
						data-place={"bottom"}
						data-tip={ReactDOMServer.renderToString(
							<div>
								<p>Claims:<br />
									<table style={{ minWidth: "500px" }}>
										<tr>
											<th>HCPCS</th>
											<th>Status</th>
											<th>Start Date</th>
											<th>End Date</th>
											<th>Same Category billed?</th>
											<th>Supplier</th>
										</tr>
										{(lst.length > 0 ? lst.map(x => {
											return (
												<tr>
													<td>{x.code}</td>
													<td>{x.end ? x.end.status : "-"}</td>
													<td>{(x.start ? x.start.dosStart : "-")}</td>
													<td>{(x.end ? x.end.dosEnd : "-")}</td>
													<td>{x.end ? x.end.sameCategoryBilled : "-"}</td>
													<td>{x.end.supplierName}</td>
												</tr>)
										}) :
											<tr>
												<td>No Claims to show</td>
											</tr>)}
									</table>
									<br />
								</p>
							</div>)}
						data-html={true}>
						<div style={{ textAlign: "center" }}><b>Same/Sim</b></div>
						{(claims && claims.length > 0) && (pvSameSim2 && (pvSameSim2.filter(x => x.status === "Pending" || x.isDataError === true || x.apiResponseCode === 4).length === 0 || pvSameSim2[0].status === "Processed")) ?
							<div style={{ textAlign: "center" }}>
								<MDBIcon
									icon={icon}
									style={{ fontSize: "2em", color: bgco }}
								/>
							</div>
							: (pvSameSim2 && pvSameSim2.length > 0 && pvSameSim2.filter(x => x.apiResponseCode === 4).length > 0 ? <div style={{ textAlign: "center", color: "red" }}>{pvSameSim2.apiResponseMessage}</div> : (pvSameSim2 && pvSameSim2.length > 0 ? <div style={{ textAlign: "center" }}>Status: {pvSameSim2[0].status}</div> : <div style={{ textAlign: "center" }}>No Records collected</div>))
						}
					</div>
				</Col>

				<Col size="12" style={{ display: showCvg }}>
					{this.props.eOrder ?
						<div><Button onClick={() => this.createVerificationNote(op)}> Create Verification Note</Button></div>
						: ''}
					<div><b>Requested Service Type:</b> {pvPrimary && pvPrimary.length > 0 ? pvPrimary[0].ResultPracticeType : "unknown"} - (pVerify)</div>
				</Col>

				<Col size="12" style={{ display: showCvg}}>
					<div id="dvPrimary">
						{pvPrimary && pvPrimary.length > 0 ?
							<div>
								<div><b>Last Updated (DOS): </b>{asOf && asOf.length > 0 ? asOf[0] : "unknown"}</div>
								{
									pvPrimary.map(p => {
										if (p.ErrorCode && p.ErrorDescription) {
											return <div style={{ color: "red" }}>{p.ErrorDescription} <div>{p.APIResponseMessage || ""}</div><Button onClick={() => this.routeToVerification()}>Route to Verification</Button></div>
										}
										else if (p.message && p.message === "Authorization has been denied for this request.") {
											return <div style={{ color: "red" }}>{p.ErrorDescription} <div>{p.APIResponseMessage || ""}</div><Button onClick={() => this.routeToVerification()}>Route to Verification</Button></div>
										}

										return (
											<div>
												<Row>
													<Col md="4">
														<div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
															<label>Status</label>
															<h4>{p && p.PlanCoverageSummary && p.PlanCoverageSummary.Status ? p.PlanCoverageSummary.Status : "Unknown"}</h4>
														</div>
													</Col>
													<Col md="4">
														<div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
															<label>Payer</label>
															<h4>{p && p.PayerName ? p.PayerName : "Unknown"}</h4>
														</div>
													</Col>
													<Col md="4">
														<div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
															<label>Verification Type</label>
															<h4>{p && p.VerificationType ? p.VerificationType : "Unknown"}</h4>
														</div>
													</Col>
												</Row>

												<Row style={{ marginTop: "8px" }}>
													<Col md="3">
														<div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
															<label>Provider Network</label>
															<label>{p && p.IsProviderInNetwork && p.IsProviderInNetwork !== "None" ? p.IsProviderInNetwork : ""}</label>
														</div>
													</Col>
													<Col md="3">
														<div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
															<label>DOS</label>
															<div>
																<label>{p && p.DOS ? p.DOS : ""}</label>
															</div>
														</div>
													</Col>
													<Col md="6">
														<div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
															<label>Eligibility Period</label>
															<div>
																<span style={{ width: "50%", textAlign: "left", float: "left", marginLeft: "6px" }}>
																	<label>Effective From: {p && p.PlanCoverageSummary && p.PlanCoverageSummary.EffectiveDate ? p.PlanCoverageSummary.EffectiveDate : ""}</label>
																</span>
																<span style={{ width: "50%", textAlign: "right" }}>
																	<label>Expired On: {p && p.PlanCoverageSummary && p.PlanCoverageSummary.ExpiryDate ? p.PlanCoverageSummary.ExpiryDate : ""}</label>
																</span>
															</div>
														</div>
													</Col>
												</Row>

												<Row style={{ marginTop: "8px" }}>
													{p && p.PlanCoverageSummary ?
														<Col md="6">
															<div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
																<label>Coverage Summary</label>
																<div style={{ textAlign: "left" }}>
																	<Button id="btnPlanCoverage" size="sm" color="primary"
																		onClick={e => {
																			document.getElementById("tblPlan").style.display = "table";
																			document.getElementById("tblMisc").style.display = "none";
																			document.getElementById("tblPcpAuth").style.display = "none";
																			document.getElementById("tblOtherPayer").style.display = "none";
																		}}
																	>
																		Plan Coverage
																	</Button>

																	{p.PCPAuthInfoSummary ?
																		<Button id="btnPcpAuthInfo" size="sm" color="primary"
																			onClick={e => {
																				document.getElementById("tblPlan").style.display = "none";
																				document.getElementById("tblMisc").style.display = "none";
																				document.getElementById("tblPcpAuth").style.display = "table";
																				document.getElementById("tblOtherPayer").style.display = "none";
																			}}
																		>
																			PCP/Auth. Info
																		</Button>
																		: ''}

																	{p.OtherPayerInfo ?
																		<Button id="btnOtherPayerInfo" size="sm" color="primary"
																			onClick={e => {
																				document.getElementById("tblPlan").style.display = "none";
																				document.getElementById("tblMisc").style.display = "none";
																				document.getElementById("tblPcpAuth").style.display = "none";
																				document.getElementById("tblOtherPayer").style.display = "table";
																			}}
																		>
																			Other Payer Info
																		</Button>
																		: ''}

																	{p.MiscellaneousInfoSummary ?
																		<Button id="btnMiscInfo" size="sm" color="primary"
																			onClick={e => {
																				document.getElementById("tblPlan").style.display = "none";
																				document.getElementById("tblMisc").style.display = "table";
																				document.getElementById("tblPcpAuth").style.display = "none";
																				document.getElementById("tblOtherPayer").style.display = "none";
																			}}
																		>
																			Misc. Info
																		</Button>
																		: ''}
																</div>

																<div style={{ maxHeight: "300px", padding: "6px", overflowY: "scroll" }}>
																	<table id="tblPlan" style={{ textAlign: "left" }}>
																		{[p.PlanCoverageSummary].map(d => {
																			var res = [];
																			for (var pkey in d) {
																				if (d[pkey] !== null) {
																					res.push({
																						name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																						value: d[pkey],
																					})
																				}
																			}
																			if (res && res.length > 0) {
																				return res.map(r => {
																					return (<tr>
																						<td><b>{r.name} &nbsp;</b></td>
																						<td>{r.value}</td>
																					</tr>)
																				})
																			}
																			return null;
																		})}
																	</table>

																	<table id="tblOtherPayer" style={{ textAlign: "left", display: "none" }}>
																		{p.OtherPayerInfo ?
																			[p.OtherPayerInfo].map(d => {
																				var res = [];
																				for (var pkey in d) {
																					if (d[pkey] !== null && d[pkey] !== "None") {
																						res.push({
																							name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																							value: d[pkey],
																						})
																					}
																				}
																				if (res && res.length > 0) {
																					return res.map(r => {
																						return (<tr>
																							<td><b>{r.name} &nbsp;</b></td>
																							<td>{r.value}</td>
																						</tr>)
																					})
																				}
																				return null;
																			})
																			: ''}
																	</table>

																	<table id="tblPcpAuth" style={{ textAlign: "left", display: "none" }}>
																		{p.PCPAuthInfoSummary ?
																			[p.PCPAuthInfoSummary].map(d => {
																				var res = [];
																				for (var pkey in d) {
																					if (d[pkey] !== null && d[pkey] !== "None") {
																						res.push({
																							name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																							value: d[pkey],
																						})
																					}
																				}
																				if (res && res.length > 0) {
																					return res.map(r => {
																						return (<tr>
																							<td><b>{r.name} &nbsp;</b></td>
																							<td>{r.value}</td>
																						</tr>)
																					})
																				}
																				return null;
																			})
																			: ''}
																	</table>

																	<table id="tblMisc" style={{ textAlign: "left", display: "none" }}>
																		{p.MiscellaneousInfoSummary ?
																			[p.MiscellaneousInfoSummary].map(d => {
																				var res = [];
																				for (var pkey in d) {
																					if (d[pkey] !== null) {
																						res.push({
																							name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																							value: d[pkey],
																						})
																					}
																				}
																				if (res && res.length > 0) {
																					return res.map(r => {
																						return (<tr>
																							<td><b>{r.name} &nbsp;</b></td>
																							<td>{r.value}</td>
																						</tr>)
																					})
																				}
																				return null;
																			})
																			: ''}
																	</table>
																</div>
															</div>
														</Col>
														: ''}
													{p && p.MedicareInfoSummary ?
														<Col md="6">
															<div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
																<label>Medicare Benefit Summary</label>

																<div style={{ textAlign: "left" }}>
																	<Button id="btnMedicareInfo" size="sm" color="primary"
																		onClick={e => {
																			document.getElementById("tblMedicare").style.display = "table";
																			document.getElementById("tblDedOop").style.display = "none";
																		}}
																	>
																		Medicare Info
																	</Button>

																	<Button id="btnDedOop" size="sm" color="primary"
																		onClick={e => {
																			document.getElementById("tblMedicare").style.display = "none";
																			document.getElementById("tblDedOop").style.display = "table";
																		}}
																	>
																		Plan Deductible OOP
																	</Button>
																</div>

																<div style={{ maxHeight: "200px", overflowY: "scroll", textAlign: "left" }}>
																	<table id="tblMedicare">
																		{[p.MedicareInfoSummary].map(v => {
																			var ll = [];
																			for (var oKey in v) {
																				if (oKey.toLowerCase().indexOf("part_a") === -1 &&
																					oKey.toLowerCase().indexOf("pt_slp_") === -1 &&
																					oKey.toLowerCase().indexOf("ot_capamount") === -1) {
																					if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
																						ll.push({
																							name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																							value: v[oKey].Value,
																							note: v[oKey].Notes,
																						})
																					}
																					else if (v[oKey] !== null && v[oKey] !== "None" &&
																						oKey.toLowerCase().indexOf("pharmacy") < 0 &&
																						!["bin", "pcn"].includes(oKey.toLowerCase())) {
																						ll.push({
																							name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																							value: v[oKey],
																							note: null,
																						})
																					}
																				}
																			}
																			if (ll && ll.length > 0) {
																				return ll.map(l => {
																					return (
																						<tr>
																							<td>
																								<b>{l.name}: </b>
																								{l.note ? <div>{l.note}</div> : ''}
																							</td>
																							<td>{l.value}</td>
																						</tr>
																					);
																				})
																			}
																			return '';
																		})}
																	</table>

																	<table id="tblDedOop" style={{ display: "none" }}>
																		{p.HBPC_Deductible_OOP_Summary ?
																			[p.HBPC_Deductible_OOP_Summary].map(v => {
																				var ll = [];
																				for (var oKey in v) {
																					if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
																						ll.push({
																							name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																							value: v[oKey].Value,
																							note: v[oKey].Notes,
																						})
																					}
																					else if (v[oKey] !== null) {
																						ll.push({
																							name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																							value: v[oKey],
																							note: null,
																						})
																					}
																				}
																				if (ll && ll.length > 0) {
																					return ll.map(l => {
																						return (
																							<tr>
																								<td>
																									<b>{l.name}: </b>
																									{l.note ? <div>{l.note}</div> : ''}
																								</td>
																								<td>{l.value}</td>
																							</tr>
																						);
																					})
																				}
																				return '';
																			})
																			: ""}
																	</table>

																</div>
															</div>
														</Col>
														: ''}

													{p && !p.MedicareInfoSummary && p.PlanCoverageSummary ?
														<Col md="6">
															<div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
																<label>Plan Benefit & Service Summary</label>

																<div style={{ textAlign: "left" }}>

																	<Button id="btnDedOop2" size="sm" color="primary"
																		onClick={e => {
																			document.getElementById("tblDME").style.display = "none";
																			document.getElementById("tblDedOop2").style.display = "table";
																		}}
																	>
																		Plan Deductible OOP
																	</Button>

																	{p && p.DMESummary ?
																		<Button id="btnDME" size="sm" color="primary"
																			onClick={e => {
																				document.getElementById("tblDME").style.display = "table";
																				document.getElementById("tblDedOop2").style.display = "none";
																			}}
																		>
																			DME
																		</Button>
																		: ""}

																</div>

																<div style={{ maxHeight: "200px", overflowY: "scroll", textAlign: "left" }}>
																	<table id="tblDedOop2">
																		{p.HBPC_Deductible_OOP_Summary ?
																			[p.HBPC_Deductible_OOP_Summary].map(v => {
																				var ll = [];
																				for (var oKey in v) {
																					if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
																						ll.push({
																							name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																							value: v[oKey].Value,
																							note: v[oKey].Notes,
																						})
																					}
																					else if (v[oKey] !== null) {
																						ll.push({
																							name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																							value: v[oKey],
																							note: null,
																						})
																					}
																				}
																				if (ll && ll.length > 0) {
																					return ll.map(l => {
																						return (
																							<tr>
																								<td>
																									<b>{l.name}: </b>
																									{l.note ? <div>{l.note}</div> : ''}
																								</td>
																								<td>{l.value}</td>
																							</tr>
																						);
																					})
																				}
																				return '';
																			})
																			: ""}
																	</table>

																	<table id="tblDME" style={{ display: "none" }}>
																		{p.DMESummary ?
																			[p.DMESummary].map(v => {
																				var ll = [];
																				for (var oKey in v) {
																					if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
																						ll.push({
																							name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																							value: v[oKey].Value,
																							note: v[oKey].Notes,
																						})
																					}
																					else if (v[oKey] !== null && v[oKey] !== "None") {
																						ll.push({
																							name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
																							value: v[oKey],
																							note: null,
																						})
																					}
																				}
																				if (ll && ll.length > 0) {
																					return ll.map(l => {
																						return (
																							<tr>
																								<td>
																									<b>{l.name}: </b>
																									{l.note ? <div>{l.note}</div> : ''}
																								</td>
																								<td>{l.value}</td>
																							</tr>
																						);
																					})
																				}
																				return '';
																			})
																			: ""}
																	</table>
																</div>
															</div>
														</Col>
														: ''}
												</Row>

												{p.ExceptionNotes ?
													<Row style={{ marginTop: "8px" }}>
														<Col md="12">
															<div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
																<label>Exception Info</label>
																<div style={{ width: "100%", textAlign: "left", padding: "8px" }}>
																	<label>{p.ExceptionNotes}</label>
																</div>
															</div>
														</Col>
													</Row>
													: ''}


												{p.AddtionalInfo ?
													<Row style={{ marginTop: "8px" }}>
														<Col md="12">
															<div style={{ textAlign: "center", border: "1px solid gray", borderRadius: "8px" }}>
																<label>Additional Info / Additional Payer Info</label>
																<div style={{ width: "100%", textAlign: "left", padding: "8px" }}>
																	<label>{p.AddtionalInfo}</label>
																</div>
															</div>
														</Col>
													</Row>
													: ''}

												<Row style={{ marginTop: "8px" }}>
													{p.ServiceDetails && p.ServiceDetails.length > 0 ?
														p.ServiceDetails.map(x => {
															if (x.ServiceName && (x.ServiceName === "Health Benefit Plan Coverage" || x.ServiceName.indexOf("Durable Medical Equipment") > -1)) {
																return (
																	<Col md="6">
																		<div>
																			<div><b>{x.ServiceName}</b></div>
																			<hr style={{ marginTop: "2px", marginBottom: "2px" }} />
																			{x.EligibilityDetails.map(v => {
																				return (<div>
																					{v.TimePeriodQualifier && v.TimePeriodQualifier !== "None" && v.EligibilityOrBenefit && v.EligibilityOrBenefit !== "None" &&
																						v.PlanCoverageDescription && v.PlanCoverageDescription !== "None" ?
																						<div><b>{v.TimePeriodQualifier || ""} {v.EligibilityOrBenefit || ""} ({v.PlanCoverageDescription || ""}): </b>{!v.MonetaryAmount || v.MonetaryAmount.toString() === "None" ? "" : "$" + parseFloat(v.MonetaryAmount).toFixed(2)}</div>
																						: ""}
																					{v.InsuranceType ?
																						<div><b>Policy Type: </b>{v.InsuranceType || ""}</div>
																						: ""}
																					{v.Identifications && v.Identifications.length > 0 ?
																						<div><b>{v.Identifications && v.Identifications.length > 0 ? v.Identifications[0].Type : ""}: </b>{v.Identifications && v.Identifications.length > 0 ? v.Identifications[0].Code : ""}</div>
																						: ""}
																					{v.Date && v.Date.length > 0 ?
																						<div><b>{v.Date && v.Date.length > 0 ? v.Date[0].Type : ""}: </b>{v.Date && v.Date.length > 0 ? v.Date[0].Date : ""}</div>
																						: ""}
																					{(v.TimePeriodQualifier && v.TimePeriodQualifier !== "None" && v.EligibilityOrBenefit && v.EligibilityOrBenefit !== "None" &&
																						v.PlanCoverageDescription && v.PlanCoverageDescription !== "None") ||
																						v.InsuranceType ||
																						(v.Identifications && v.Identifications.length > 0) ||
																						(v.Date && v.Date.length > 0) ?
																						<hr style={{ marginTop: "2px", marginBottom: "2px" }} />
																						: ""}
																				</div>)
																			})}
																		</div>
																	</Col>)
															}
															return '';
														})
														: ''}
												</Row>
											</div>
										);
									})
								}
							</div>
							: (pvPrimary && pvPrimary.ErrorCode ? <div style={{ color: 'red' }}><b>Error: </b>{pvPrimary.ErrorDescription}</div> : '')}
					</div>
				</Col>

				{/*SNF*/}
				<Col size="3" style={{ display: showSnf}}>
					<div style={{ padding: "10px", borderRadius: "20px", border: "2px solid black", backgroundColor: "aliceblue" }}
						data-place={"bottom"}
						data-tip={ReactDOMServer.renderToString(
							<div>
								<p>Details:<br />
									<table style={{ minWidth: "500px" }}>
										<tr>
											<th>Service Start</th>
											<th>Service End</th>
											<th>Discharge Date</th>
										</tr>
										{(details.length > 0 || (pvSnf2 && pvSnf2.isDataError !== true && (!details || details.length === 0)) ? details.map(x => {
											return (
												<tr>
													<td>{(x.dosStart || "-")}</td>
													<td>{(x.dosEnd || "-")}</td>
													<td>{(x.dischargedDate || "-")}</td>
												</tr>)
										}) : (pvSnf2 && pvSnf2.isDataError === true && pvSnf2.errorMessage && pvSnf2.errorMessage.indexOf("Inpatient / SNF info not found for this Patient for requested DOS") > -1 ?
											<tr><td colSpan="3">{pvSnf2.errorMessage}</td></tr> : <tr><td colSpan="3">No details to show</td></tr>))}
									</table>
									<br />
								</p>
							</div>)}
						data-html={true}>
						<div style={{ textAlign: "center" }}><b>SNF</b></div>
						{(details && details.length > 0) || (pvSnf2 && pvSnf2.isDataError === true && pvSnf2.apiResponseCode !== 4 && pvSnf2.errorMessage && pvSnf2.errorMessage.indexOf("Inpatient / SNF info not found") > -1) ?
							<div style={{ textAlign: "center" }}>
								<MDBIcon
									icon={icon2}
									style={{ fontSize: "2em", color: bgco2 }}
								/>
							</div>
							: (pvSnf2 && pvSnf2.apiResponseCode === 4 ? <div style={{ textAlign: "center", color: "red" }}>{pvSnf2.apiResponseMessage}</div> : (pvSnf2 && pvSnf2.status ? <div style={{ textAlign: "center" }}><div>Status: {pvSnf2.status}</div><div>{(pvSnf2.expectedTimeInSeconds ? "ETA: " + pvSnf2.expectedTimeInSeconds + " seconds" : "")}</div></div> : <div style={{ textAlign: "center" }}>No Records collected</div>))
						}
					</div>
				</Col>

			</Row>
			<ReactTooltip />
		</div>);
	}

	createVerificationNote(selectedCoverage) {
		let txt = selectedCoverage.orderId + "\nCoverage Summary";

		let rs = this.props.results,
			op = rs.filter(x => x.resultType !== selectedCoverage.resultType && x.orderId === selectedCoverage.orderId);

		let pvPrimaryTemp = null,
			pvSecondaryTemp = null,
			pvSameSim = [],
			pvSnf = null;

		if (selectedCoverage.resultType === "Primary") {
			if (selectedCoverage && selectedCoverage.stringResult && selectedCoverage.resultType === "Primary")
				pvPrimaryTemp = JSON.parse(selectedCoverage.stringResult);
		}
		else {
			var pTemp = op && op.length > 0 ? op.filter(x => x.resultType === "Primary" && x.stringResult.indexOf("Authorization has been denied") < 0).map(x => JSON.parse(x.stringResult)) : [];
			if (pTemp && pTemp.length > 0)
				pvPrimaryTemp = JSON.parse(pTemp.filter(x => x.stringResult !== null)[0].stringResult);
		}

		if (selectedCoverage.resultType === "Secondary") {
			if (selectedCoverage && selectedCoverage.stringResult && selectedCoverage.resultType === "Secondary")
				pvSecondaryTemp = JSON.parse(selectedCoverage.stringResult);
		}
		else {
			var sTemp = op && op.length > 0 ? op.filter(x => x.resultType === "Secondary" && x.stringResult.indexOf("Authorization has been denied") < 0) : [];
			console.log(sTemp)
			if (sTemp && sTemp.length > 0)
				pvSecondaryTemp = JSON.parse(sTemp.filter(x => x.stringResult !== null)[0].stringResult);
		}

		if (op && op.length > 0 && op.filter(x => x.resultType === "SNF").length > 0) {
			var snfs = op.filter(x => x.resultType === "SNF").map(x => JSON.parse(x.stringResult)) || [];
			var firstSnf = snfs && snfs.length > 0 ? snfs.filter(x => x && !x.ErrorMessage) : [];
			if (firstSnf && firstSnf.length > 0)
				pvSnf = firstSnf[0];
		}

		if (op && op.length > 0 && op.filter(x => x.resultType === "SameSim").length > 0) {
			var sss = op.filter(x => x.resultType === "SameSim").map(x => JSON.parse(x.stringResult)) || [];
			pvSameSim = sss && sss.length > 0 ? sss.filter(x => x && !x.errorMessage) : [];
		}

		let pvPrimary = [],
			pvSecondary = [];

		let pvSnf2 = {},
			pvSameSim2 = [];

		if (pvPrimaryTemp)
			pvPrimary.push(pvPrimaryTemp)

		if (pvSecondaryTemp)
			pvSecondary.push(pvSecondaryTemp)

		if (pvSnf && pvSnf.APIResponseMessage) {
			pvSnf2 = this.toCamel(pvSnf);
		}

		if (pvSameSim && pvSameSim.length > 0) {
			pvSameSim.forEach(p => {
				pvSameSim2.push(this.toCamel(p))
			});
		}

		let claims = [];
		if (pvSameSim2 && pvSameSim2.length > 0) {
			pvSameSim2.map(s => {
				if (s.claims && s.claims.length > 0) {
					return s.claims.forEach(sc => {
						if (sc.hcpcs && sc.hcpcs.length > 0 && GlobalFunctions.containsHcpcs(sc.hcpcs.substring(0, 5))) {
							return claims.push(sc);
						}
					})
				}
				return null;
			})
		}
		let details = pvSnf2 && pvSnf2.details && pvSnf2.isDataError !== true ? pvSnf2.details : [];

		let address = null;

		if (pvPrimary && pvPrimary.length > 0) {
			pvPrimary.map(p => {

				if (p.DemographicInfo && p.DemographicInfo.Subscriber && p.DemographicInfo.Subscriber.Address1) {
					txt += "\n" + p.DemographicInfo.Subscriber.FullName + "\nPrimary Member ID: " + (p.DemographicInfo.Subscriber.Identification && p.DemographicInfo.Subscriber.Identification.length > 0 ? p.DemographicInfo.Subscriber.Identification.filter(x => x.Type === "Member ID")[0].Code : "");
					address = "\n" + (p.DemographicInfo.Subscriber.Address1 || "") + "\n" +
						(p.DemographicInfo.Subscriber.Address2 || "") + "\n" +
						(p.DemographicInfo.Subscriber.City || "") + ", " +
						(p.DemographicInfo.Subscriber.State || "") + " " +
						(p.DemographicInfo.Subscriber.Zip || "");
				}

				if (p.PlanCoverageSummary) {
					[p.PlanCoverageSummary].map(v => {
						var ll = [];
						for (var oKey in v) {
							if (v[oKey] !== null) {
								ll.push({
									name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: v[oKey],
								})
							}
						}
						if (ll && ll.length > 0) {
							return ll.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return '';
					})
				}

				if (p.OtherPayerInfo) {
					[p.OtherPayerInfo].map(d => {
						var res = [];
						for (var pkey in d) {
							if (d[pkey] !== null && d[pkey] !== "None") {
								res.push({
									name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: d[pkey],
								})
							}
						}
						if (res && res.length > 0) {
							return res.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return null;
					})
				}

				if (p.MedicareInfoSummary) {
					[p.MedicareInfoSummary].map(v => {
						var ll = [];
						for (var oKey in v) {
							if (oKey.toLowerCase().indexOf("part_a") === -1 &&
								oKey.toLowerCase().indexOf("pt_slp_") === -1 &&
								oKey.toLowerCase().indexOf("ot_capamount") === -1) {
								if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
									ll.push({
										name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
										value: v[oKey].Value,
										note: v[oKey].Notes,
									})
								}
								else if (v[oKey] !== null && v[oKey] !== "None" &&
									oKey.toLowerCase().indexOf("pharmacy") < 0 &&
									!["bin", "pcn"].includes(oKey.toLowerCase())) {
									ll.push({
										name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
										value: v[oKey],
										note: null,
									})
								}
							}
						}
						if (ll && ll.length > 0) {
							return ll.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return '';
					})
				}

				if (p.PCPAuthInfoSummary) {
					[p.PCPAuthInfoSummary].map(d => {
						var res = [];
						for (var pkey in d) {
							if (d[pkey] !== null && d[pkey] !== "None") {
								res.push({
									name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: d[pkey],
								})
							}
						}
						if (res && res.length > 0) {
							return res.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return null;
					})
				}

				if (p.MiscellaneousInfoSummary) {
					[p.MiscellaneousInfoSummary].map(d => {
						var res = [];
						for (var pkey in d) {
							if (d[pkey] !== null) {
								res.push({
									name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: d[pkey],
								})
							}
						}
						if (res && res.length > 0) {
							return res.map(r => {
								return txt += "\n" + r.name + ": " + r.value;
							})
						}
						return '';
					})
				}

				if (p.HBPC_Deductible_OOP_Summary) {
					[p.HBPC_Deductible_OOP_Summary].map(v => {
						var ll = [];
						for (var oKey in v) {
							if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
								ll.push({
									name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: v[oKey].Value,
								})
							}
							else if (v[oKey] !== null) {
								ll.push({
									name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: v[oKey],
								})
							}
						}
						if (ll && ll.length > 0) {
							return ll.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return '';
					})
				}

				if (p.DMESummary) {
					[p.DMESummary].map(v => {
						var ll = [];
						for (var oKey in v) {
							if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
								ll.push({
									name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: v[oKey].Value,
									note: v[oKey].Notes,
								})
							}
							else if (v[oKey] !== null && v[oKey] !== "None") {
								ll.push({
									name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: v[oKey],
									note: null,
								})
							}
						}
						if (ll && ll.length > 0) {
							return ll.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return '';
					})
				}

				txt += "\n"

				return txt;
			})
		}
		if (pvSecondary && pvSecondary.length > 0) {
			pvSecondary.map(p => {
				//let currentCoverage = null;
				//if (p.ServiceDetails && p.ServiceDetails.length > 0) {
				//	p.ServiceDetails.map((d, i) => {
				//		if (d.ServiceName.indexOf("Durable Medical Equipment") === 0) {
				//			currentCoverage = d;
				//			return currentCoverage;
				//		}
				//		return '';
				//	})
				//}

				if (p.DemographicInfo && p.DemographicInfo.Subscriber && p.DemographicInfo.Subscriber.Address1) {
					txt += "\n" + p.DemographicInfo.Subscriber.FullName + "\nSecondary Member ID: " + (p.DemographicInfo.Subscriber.Identification && p.DemographicInfo.Subscriber.Identification.length > 0 ? p.DemographicInfo.Subscriber.Identification.filter(x => x.Type === "Member ID")[0].Code : "");
					address = "\n" + (p.DemographicInfo.Subscriber.Address1 || "") + "\n" +
						(p.DemographicInfo.Subscriber.Address2 || "") + "\n" +
						(p.DemographicInfo.Subscriber.City || "") + ", " +
						(p.DemographicInfo.Subscriber.State || "") + " " +
						(p.DemographicInfo.Subscriber.Zip || "");
				}
				//else {
				//	txt += "\nMember ID: " + order.insurancePolicy;
				//	address = "\n" + order.address + "\n" + order.city + ", " + order.state + " " + order.zipCode;
				//}

				//if (currentCoverage) {
				if (p.PlanCoverageSummary) {
					[p.PlanCoverageSummary].map(v => {
						var ll = [];
						for (var oKey in v) {
							if (v[oKey] !== null) {
								ll.push({
									name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: v[oKey],
								})
							}
						}
						if (ll && ll.length > 0) {
							return ll.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return '';
					})
				}

				if (p.OtherPayerInfo) {
					[p.OtherPayerInfo].map(d => {
						var res = [];
						for (var pkey in d) {
							if (d[pkey] !== null && d[pkey] !== "None") {
								res.push({
									name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: d[pkey],
								})
							}
						}
						if (res && res.length > 0) {
							return res.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return null;
					})
				}

				if (p.MedicareInfoSummary) {
					[p.MedicareInfoSummary].map(v => {
						var ll = [];
						for (var oKey in v) {
							if (oKey.toLowerCase().indexOf("part_a") === -1 &&
								oKey.toLowerCase().indexOf("pt_slp_") === -1 &&
								oKey.toLowerCase().indexOf("ot_capamount") === -1) {
								if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
									ll.push({
										name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
										value: v[oKey].Value,
										note: v[oKey].Notes,
									})
								}
								else if (v[oKey] !== null && v[oKey] !== "None" &&
									oKey.toLowerCase().indexOf("pharmacy") < 0 &&
									!["bin", "pcn"].includes(oKey.toLowerCase())) {
									ll.push({
										name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
										value: v[oKey],
										note: null,
									})
								}
							}
						}
						if (ll && ll.length > 0) {
							return ll.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return '';
					})
				}

				if (p.PCPAuthInfoSummary) {
					[p.PCPAuthInfoSummary].map(d => {
						var res = [];
						for (var pkey in d) {
							if (d[pkey] !== null && d[pkey] !== "None") {
								res.push({
									name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: d[pkey],
								})
							}
						}
						if (res && res.length > 0) {
							return res.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return null;
					})
				}

				if (p.MiscellaneousInfoSummary) {
					[p.MiscellaneousInfoSummary].map(d => {
						var res = [];
						for (var pkey in d) {
							if (d[pkey] !== null) {
								res.push({
									name: pkey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: d[pkey],
								})
							}
						}
						if (res && res.length > 0) {
							return res.map(r => {
								return txt += "\n" + r.name + ": " + r.value;
							})
						}
						return '';
					})
				}

				if (p.HBPC_Deductible_OOP_Summary) {
					[p.HBPC_Deductible_OOP_Summary].map(v => {
						var ll = [];
						for (var oKey in v) {
							if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
								ll.push({
									name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: v[oKey].Value,
								})
							}
							else if (v[oKey] !== null) {
								ll.push({
									name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: v[oKey],
								})
							}
						}
						if (ll && ll.length > 0) {
							return ll.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return '';
					})
				}

				if (p.DMESummary) {
					[p.DMESummary].map(v => {
						var ll = [];
						for (var oKey in v) {
							if (v[oKey] && typeof v[oKey] === "object" && v[oKey].hasOwnProperty("Value")) {
								ll.push({
									name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: v[oKey].Value,
									note: v[oKey].Notes,
								})
							}
							else if (v[oKey] !== null && v[oKey] !== "None") {
								ll.push({
									name: oKey.replace(/[_]/g, ' ').replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
									value: v[oKey],
									note: null,
								})
							}
						}
						if (ll && ll.length > 0) {
							return ll.map(l => {
								return txt += "\n" + l.name + ": " + l.value;
							})
						}
						return '';
					})
				}

				txt += "\n"
				//}

				return txt;
			})
		}

		txt += "\nSame / Sim Supplier: " + (claims && claims.length > 0 && claims.filter(x => x.supplierName && x.supplierName !== "" && x.supplierName !== null).length > 0 ?
			[...new Set(claims.filter(x => x.supplierName && x.supplierName !== "" && x.supplierName !== null).map(x => x.supplierName))].join(",")
			: "No Result Collected");
		txt += "\nSNF: " + (details && details.length > 0 && details.filter(x => !x.dischargedDate && !x.dosEnd).length > 0 ? "Results Found" : "No Results");

		txt += "\n\nAddress on file: " + address;

		this.toggleNoteModal(txt, selectedCoverage.orderId);
	}

	render() {
		let o = this.props.results;

		return (
			<MDBModal
				className="availityModal"
				isOpen={this.props.insuranceModal}
				toggle={() => this.toggleInsuranceModal()}
			>
				<MDBModalHeader style={{ color: 'white', backgroundColor: '#5881c1' }}>Insurance Details</MDBModalHeader>
				<MDBModalBody>
				</MDBModalBody>
				<MDBModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>

					<div>
						<Row>
							<Col size="3">
								<ul>
									{
										o && o.length > 0 ?
											o.map(x => {
												if (x.source !== "pVerify")
													return null;
												return <li style={{cursor: "pointer"}}
													data-tip={"Created at: " + x.createDate +
													"\nType: " + x.resultType +
													"\nOrder: " + x.orderId}
													onClick={() => this.setState({selectedCoverage: x})}
												>{x.orderId + " - " + x.resultType}</li>
											})
										: <div>Nothing to show,</div>
                                    }
								</ul>
							</Col>
							<Col size="9">
								{this.renderOptions(this.state.selectedCoverage)}
							</Col>
						</Row>
					</div>
				</MDBModalBody>
				<MDBModalFooter>
					<MDBCloseIcon
						style={{ right: "0" }}
						onClick={this.toggleInsuranceModal}
						className={"closeBtn"}
					/>
				</MDBModalFooter>
				<ReactTooltip />
			</MDBModal>
		);

    }
}