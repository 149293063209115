import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import ServiceReason from "../../Utility/Crud/ServiceReason";

export default class TileServiceReason extends Tile {
    /**
      * @returns {string} The ServiceReason name.
      */
    getTitle() {
        return this.props.serviceReason ? ServiceReason.getDisplayName(this.props.serviceReason) : "None";
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.serviceReason ? ServiceReason.getDisplayName(this.props.serviceReason) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.serviceReason ? ServiceReason.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return ServiceReason.getIcon();
    }
}
