import React from "react";
import DialogSearch from "../DialogSearch"
import TileIssueReason from "../../Tile/TileIssueReason";
import PropTypes from "prop-types";
import IssueReason from "../../../Utility/Crud/IssueReason";

export default class DialogSearchIssueReason extends DialogSearch {

    static contextTypes = {
        issueReasonsIndexed: PropTypes.object,
    };

    /**
     * Searches for issue reasons by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered issueReason.
     */
    getSearchResults(query) {
        return IssueReason.search(Object.values(this.context.issueReasonsIndexed), query, true);
    }

    /**
     * @param {object} issueReason The issueReason object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileIssueReason component for this issueReason.
     */
    renderTile(issueReason, onClick) {
        const handleClick = () => {
            onClick(issueReason);
            this.close();
        }

        return (
            <TileIssueReason
                key={issueReason.id}
                issueReason={issueReason}
                onClick={handleClick}
            />
        );
    }
}
