import React from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import "./login.css";
import * as msal from "@azure/msal-browser";
import authService from "../Security/authService";

import MUICard from '@mui/material/Card';
import MUICardContent from '@mui/material/CardContent';
import MUIContainer from "@mui/material/Container"
import MUICircularProgress from "@mui/material/CircularProgress"
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUIMenuItem from "@mui/material/MenuItem"
import MUIGrid from "@mui/material/Grid";
import MUIBusinessIcon from "@mui/icons-material/Business"
import MUITypography from "@mui/material/Typography"
import MUITextField from "@mui/material/TextField"
import MUIBox from "@mui/material/Box"
import MUIAlert from "@mui/material/Alert"

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;

const companies = [
        {
            name: 'Rehab Medical', value: {
                name: 'rehabmedical',
                authConfig: {
                    auth: {
                        clientId: "a06bbb83-c8df-45ea-b686-452403137476",
                        authority: "https://login.microsoftonline.com/f2c81385-bf14-4df0-ac96-e01bda2adf46/",
                        replyUrl: window.location.origin + "/dashboard",
                        request: {
                            scopes: ["openid", "profile", "User.Read"],
                            forceRefresh: false,
                        },
                    },
                },
            }
        },
        {
            name: 'Cork Medical', value: {
                name: 'corkmedical',
                authConfig: {
                    auth: {
                        clientId: "12640c30-8132-4ae0-a7f0-f3532247643c",
                        authority: "https://login.microsoftonline.com/ea8b3ecd-6c46-48e8-aad6-583c904c3d0a/",
                        replyUrl: window.location.origin + "/dashboard",
                        request: {
                            scopes: ["openid", "profile", "User.Read"],
                            forceRefresh: false,
                        },
                    },
                },
            }
        },
        {
            name: 'UAT', value: {
                name: 'uat',
                authConfig: {
                    auth: {
                        clientId: "a06bbb83-c8df-45ea-b686-452403137476",
                        authority: "https://login.microsoftonline.com/f2c81385-bf14-4df0-ac96-e01bda2adf46/",
                        replyUrl: window.location.origin + "/dashboard",
                        request: {
                            scopes: ["openid", "profile", "User.Read"],
                            forceRefresh: false,
                        },
                    },
                },
            }
        },
];
export default class login extends React.Component {
    constructor() {
        super();

        let pca = null;

        let prevPage = window.sessionStorage.getItem("prevPage");
        this.state = {
            username: "",
            password: "",
            company: "",
            companyLabel: "",
            loggedIn: false,
            error: "",
            currentUser: {},
            needUpdate: false,
            currVersion: -1,
            prevPage: prevPage,

            isAuthenticated: false,
            user: {},
            token: null,
            publicClient: pca,
            loading: false,
            popupOpen: false,
            loginError: false,
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        userDataFunction: () => {
        },
        companyFunction: () => {
        },
    };

    componentDidMount() {
        const { userDataFunction, currentUser } = this.context;

        let pca = this.state.publicClient;
        if (!pca) {
            var company = {
                name: null, value: {
                    name: null,
                    authConfig: {
                        auth: {
                            clientId: "a06bbb83-c8df-45ea-b686-452403137476",
                            authority: "https://login.microsoftonline.com/f2c81385-bf14-4df0-ac96-e01bda2adf46/",
                            replyUrl: "http://localhost:3000/dashboard",
                            request: {
                                scopes: ["openid", "profile", "User.Read"],
                                forceRefresh: false,
                            },
                        },
                    },
                }
            }
            this.setPublicClient(company);
            if(currentUser)
                userDataFunction(null, null);
        }
    }

    setPublicClient(company) {
        let pca = this.state.publicClient;
        if (company && company.value.authConfig) {
            pca = new msal.PublicClientApplication(company.value.authConfig);
            this.setState({
                company: company.value.name,
                companyLabel: company.name,
            })
        }

        this.setState({
            publicClient: pca,
        })
    }

    processResult(response) {
        if (response && response.account) {

            let token = response.idToken ? response.idToken : null,
                auth = true;
            var obj = {
                id: null,
                username: response.account.username,
                token: response.idToken,
                issuedAt: new Date(),
                company: this.state.company || "local"
            };
            authService.setToken(obj);

            authService.getMyUserDetails()
                .then(user => {
                    if (user && user.id && user.id > 0) {
                        this.setState({ loggedIn: true, loading: false, user: user, msalAcct: response.account });
                    } else {
                        this.setState({error: "You are not authorized to use this site"});
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        error: "There was in issue preventing login.",
                        loading: false,
                    })
                });

            this.setState({
                isAuthenticated: auth,
                token: token,
            })
        }
    }

    localHostLogin(evt) {
        if (evt.detail === 3) {
            this.checkVersion(evt)
        }
    }

    checkVersion(evt) {
        evt.preventDefault();
        this.validateAndLogin();
        return;
    }

    //Attempt to log the user into the service
    validateAndLogin(evt) {
        let loginRequest = {
            scopes: ["openid", "profile", "User.Read"],
            forceRefresh: false,
        };
        this.login(loginRequest, "loginPopup");
    }

    logout = () => {
        let publicClient = this.state.publicClient;
        publicClient.logout();
    }

    login = async (loginRequest, method) => {
        let publicClient = this.state.publicClient;
        const signInType = (isIE || isEdge) ? "loginRedirect" : method;

        if (this.state.error !== "") {
            this.setState({
                error: "",
            })
        }

        if (signInType === "loginPopup") {
            this.setState({
                popupOpen: true,
                loading: true,
            });

            try {
                await publicClient.loginPopup(loginRequest)
                    .then(res => {
                        this.processResult(res);
                    })

            } catch (error) {
                console.log(error);
                this.setState({
                    loading: false,
                });
            } finally {
                this.setState({
                    popupOpen: false,
                });
            }
        } else if (signInType === "loginRedirect") {
            this.setState({
                popupOpen: true,
                loading: true
            });

            publicClient.loginRedirect(loginRequest)
        }
    }

    getTokenPopup = async (loginRequest) => {
        let publicClient = this.state.publicClient;
        try {
            const response = await publicClient.acquireTokenSilent(loginRequest);
            this.setState({token: response.accessToken});
        } catch (error) {
            try {
                this.setState({popupOpen: true});

                const response = await publicClient.acquireTokenPopup(loginRequest);

                this.setState({token: response.accessToken});
            } catch (error) {
                console.log(error);
                this.setState({
                    loginError: error,
                    loading: false,
                });
            } finally {
                this.setState({
                    popupOpen: false,
                });
            }
        }
    }

    getTokenRedirect = async (loginRequest) => {
        let publicClient = this.state.publicClient;
        try {
            let tkn = await publicClient.acquireTokenSilent(loginRequest);
            this.setState({token: tkn});
        } catch (error) {

            try {
                this.setState({loading: true});

                publicClient.acquireTokenRedirect(loginRequest);
            } catch (error) {
                console.log(error);
                this.setState({
                    loginError: error,
                    loading: false,
                });
            }
        }
    }

    getToken = async (loginRequest, method) => {
        const signInType = (isIE || isEdge) ? "loginRedirect" : method;
        if (signInType === "loginRedirect") {
            return await this.getTokenRedirect(loginRequest);
        } else {
            return await this.getTokenPopup(loginRequest);
        }
    }

    renderIntro() {
        return (
            <video
                width="100%"
                height="100%"
                autoPlay
                muted
                style={{height: 235, maxHeight: 235, border: ".1em solid #AAA"}}
            >
                <source
                    src={require("../../images/Sales_pilot_logo_animation_1.0.mp4")}
                    type={"video/mp4"}
                />
                <img
                    src={require("../../images/salesPilotLogo3.png")}
                    style={{marginBottom: 6}}
                    alt={"Sales Pilot Logo"}
                />
                <h2 style={{fontWeight: 500}}>Sales Pilot</h2>
            </video>
        );
    }

    render() {
        const { userDataFunction, companyFunction } = this.context;

        let st = this.state;

        if (st.loggedIn === true) {
            if (st.prevPage === "null" || st.prevPage === null) {
                userDataFunction(st.user, st.msalAcct);
                companyFunction(this.state.companyLabel, this.state.company);
                return <Redirect to="/dashboard"/>;
            }
            window.sessionStorage.setItem("prevPage", null);
            return <Redirect to={ st.prevPage } />;
        }

        let idx = "";
        if (this.state.company) {
            idx = companies.find((c) => {
                return c.value.name === this.state.company;
            });
        }

        function renderLoginButton() {
            return (
                <MUILoadingButton
                    type="submit"
                    fullWidth
                    size="large"
                    disabled={ !st.company || st.company === null }
                    loading={ st.loading }
                    loadingIndicator={ <MUICircularProgress size={ 26 } color="inherit" /> }
                    variant={"contained"}
                >
                    LOG IN
                </MUILoadingButton>
            )
        }

        return (
            <MUIContainer maxWidth="xs">
                <MUICard>
                    <MUICardContent
                        style={ {
                            fontSmoothingEnabled: true,
                            WebkitFontSmoothing: "antialiased",
                        } }
                    >
                        <MUIContainer disableGutters className="text-center">{ this.renderIntro() }</MUIContainer>
                        <form onSubmit={ this.checkVersion.bind(this) }>
                            <MUIGrid container alignItems="center" sx={ { padding: "10px 0px 20px" } } >
                                <MUIGrid item xs={ 12 }>
                                    <MUIBox sx={ { display: 'flex', alignItems: 'flex-end' } }>
                                        <MUIBusinessIcon sx={ { color: 'text.secondary', mr: 1, my: 0.5 } } />
                                        <MUITextField
                                            fullWidth
                                            select={true}
                                            label={ "Company" }
                                            InputLabelProps={{shrink: true}}
                                            value={ idx }
                                            onChange={ (e) => {
                                                this.setPublicClient(e.target.value);
                                            } }
                                            variant="standard"
                                        >
                                            { companies.map((option, idx) => {
                                                return (
                                                    <MUIMenuItem key={ idx } value={ companies[idx] }>
                                                        { option.name }
                                                    </MUIMenuItem>
                                                );
                                            }) }
                                        </MUITextField>
                                    </MUIBox>
                                </MUIGrid>
                            </MUIGrid>
                            <MUIGrid container>
                                { renderLoginButton() }
                            </MUIGrid>
                        </form>
                        <MUIContainer disableGutters sx={ { textAlign: "right" } }>
                            { st.error ? (
                                <MUIAlert severity="error" sx={{marginTop: "10px"}} >{ st.error }</MUIAlert>
                            ) :
                                null
                            }
                            <MUIContainer disableGutters sx={{ paddingTop: "4%" }}>
                                <MUITypography onClick={this.localHostLogin.bind(this)} variant="body" style={{ marginTop: "10px" }}>
                                    Version {window.salesPilot.meta.version}
                                </MUITypography>
                            </MUIContainer>
                        </MUIContainer>
                    </MUICardContent>
                </MUICard>
            </MUIContainer>
        );
    }
}

