import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	Input,
	toast,
	CardHeader,
	MDBIcon,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import ProductSubTypeService from "../Security/ProductService/productSubTypeService";
import ProdcutSizeService from "./productSizeService";
import PropTypes from "prop-types";

export default class addProductSize extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			size: "",
			product: {},
			order: "",
			isLoaded: false,
			productNames: [],
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allVendors: PropTypes.array,
	};

	componentDidMount() {
		this.retrieveProductNames();
	}

	retrieveProductNames() {
		let t = this;

		return ProductSubTypeService.getAllProductSubTypes()
			.then((res) => {
				let arr = [];
				
				res.forEach((product) => {
					arr.push({
						label: product.name,
						value: {
							id: product.id,
							name: product.name,
						},
					});
				});

				t.setState({
					insuranceNames: arr,
					isLoaded: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleSizeChange = (e) => {
		this.setState({
			size: e.target.value,
		});
	};

	handleOrderChange = (e) => {
		this.setState({
			order: e.target.value,
		});
	};

	handleProductNameChange(e) {
		this.setState({
			product: e.value,
		});
	}

	renderAddProductButton() {
		let i = this.state.insuranceNames;
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<Input
					label={"Size"}
					onChange={this.handleSizeChange.bind(this)}
					value={this.state.size}
				/>
				<Select
					placeholder={"Product"}
					options={i}
					onChange={this.handleProductNameChange.bind(this)}
				/>
				<Input
					label={"Order"}
					onChange={this.handleOrderChange.bind(this)}
					value={this.state.order}
				/>
			</div>
		);
	}

	saveButtonClick = () => {
		if (this.validate()) {
			let obj = {
				size: this.state.size,
				sortOrder: parseInt(this.state.order),
				productSubType: this.state.product,
				productSubTypeId: this.state.product.id,
			};

			return ProdcutSizeService.createProductSizes(obj)
				.then((res) => {
					console.log(res)
					this.setState({
						errorMessage: "",
						size: "",
						order: "",
						product: {},
					});
					toast.success(obj.size + " Product Size has been Added!");
				})
				.catch((err) => {
					toast.warn(
						"An error occurred: Make sure order number is different from others"
					);
				});
		}
	};

	validate() {
		let s = this.state.size,
			o = this.state.order,
			p = this.state.product;
		if (s === "") {
			this.setError("Please enter Size");
			return false;
		} else if (Object.keys(p).length === 0) {
			this.setError("Please choose a Product");
			return false;
		} else if (o === "") {
			this.setError("Please enter an Order Number");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Container className="mt-5">
					<Card>
						<CardHeader
							color={"blue-grey lighten-2"}
							style={{ textAlign: "center" }}
						>
							<Button
								style={{ float: "left" }}
								floating
								size="sm"
								color={"warning"}
								data-tip={"Back"}
								onClick={() => this.props.history.push("/productSizes")}
							>
								<MDBIcon icon="backward" style={{ fontSize: "2em" }} />
							</Button>
							Add New Product Size
							<Button
								style={{ float: "right" }}
								floating
								size="sm"
								color={"success"}
								data-tip={"Save New Product Size"}
								onClick={this.saveButtonClick}
							>
								<MDBIcon far icon="save" style={{ fontSize: "2em" }} />
							</Button>
						</CardHeader>
						<CardBody>{this.renderAddProductButton()}</CardBody>
						<ReactTooltip />
					</Card>
				</Container>
			</div>
		);
	}
}