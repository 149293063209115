import api from "../api";

class ColorService {
    async getAllColors() {
        return new Promise((res, reject) => {
            api
                .get("/allColors")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getColorById(id) {
        return new Promise((res, reject) => {
            api
                .get("/colors/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }



    async createColor(obj) {
        return new Promise((res, reject) => {
            api
                .post("/colors", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateColor(obj) {
        return new Promise((res, reject) => {
            api
                .put("/colors", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }



    async deleteColor(id) {
        return new Promise((res, rej) => {
            return api.delete('/colors/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            })
        })
    }




}

const colorService = new ColorService();

export default colorService;
