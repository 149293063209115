import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import OrderStatus from "../../Utility/Crud/OrderStatus";
import PropTypes from "prop-types";
import * as MUIColors from "@mui/material/colors";

export default class TileOrderStatus extends Tile {
    static contextTypes = {
        orderStatusesIndexed: PropTypes.object,
        colorsIndexed: PropTypes.object,
    };

    /**
      * @returns {string} The OrderStatus name.
      */
    getTitle() {
        return this.props.orderStatus ? OrderStatus.getDisplayName(this.props.orderStatus) : "None";
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.orderStatus ? OrderStatus.getDisplayName(this.props.orderStatus) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        const color = this.props.orderStatus ? this.context.colorsIndexed[this.props.orderStatus.colorId] : null;
        return this.props.orderStatus ? MUIColors[color.hue][color.shade] : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return OrderStatus.getIcon();
    }
}