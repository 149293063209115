import React from "react";
import PropTypes from "prop-types";
import Security from "../Utility/Security";

/**
 * This provides context to the static Security class.
 */
export default class SecurityProvider extends React.Component {
    static contextTypes = {
        currentUser: PropTypes.object,
        usersIndexed: PropTypes.object,
        departmentsIndexed: PropTypes.object,
        teamsIndexed: PropTypes.object,
        teamUsersIndexed: PropTypes.object,
    };

    /**
     * @returns {*} Null; nothing to render
     */
    render() {
        return null;
    }

    /**
     * Set the context on the static Security class.
     */
    componentDidMount() {
        Security.setContext(this.context);
    }
}