import React from "react";
import PropTypes from "prop-types";
import {
    Container, Input,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBRow,
    Spinner
} from "mdbreact";
import { Route } from "react-router-dom";
import ReverseQualityService from "../Settings/reverseQualityService";

export default class MyReverseQuality extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rqLogs: [],
            isLoading: true
        }


    }

    componentDidMount() {
        this.getReverseQualityLogs();
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        reverseQualityReasons: PropTypes.array
    }

    getReverseQualityLogs() {
        const {currentUser} = this.context;

        let y = new Date().getFullYear(),
            m = new Date().getMonth() + 1,
            sd = new Date(m + '/01/' + y).toLocaleDateString()

        let f = {
            startDate: sd,
            endDate: new Date().toLocaleDateString(),
            ids: [currentUser.id]
        };

        ReverseQualityService.getAllLogsNative(f).then((r) => {
                this.setState({
                    rqLogs: r.reverseQualityLogs,
                    isLoading: false
                });
            })
            .catch(err => {
                console.log(err)
            })
    }

    markAsFixed(row, idx) {
        // let logs = this.state.rqLogs.slice();

        ReverseQualityService.fixReverseQualityLog(row.id).then(res => {
            if(res){
                this.getReverseQualityLogs();
            }
        }).catch(e => {
            console.log(e);
        })
    }

    renderLogRows() {
        // const {reverseQualityReasons} = this.context;
        let t = this;

        let bgColor = "#E2F1FF",
            headerColor = "#CDE9FF",
            canBeFixed = true;

        // function determineFixable(row) {
        //     if (reverseQualityReasons.length > 0) {

        //         let reason = reverseQualityReasons.find(v => {
        //             return v.name === row.reverseQualityReason
        //         })

        //         if (reason && reason.canBeFixed === true) {
        //             return true;
        //         }
        //     }
        //     return false;
        // }

        function renderCheckbox(row, idx) {
            if (row.fixedOn) {
                return (
                    <MDBCol size={2} style={{color: '#798E75', fontWeight: 'bold'}}>
                        Reviewed: {new Date(row.fixedOn).toLocaleDateString()}
                    </MDBCol>
                )
            }

            if (canBeFixed) {
                return (
                    <MDBCol size={3} style={{color: '#B5678F', fontWeight: 'bold'}}>
                        <Input
                            onChange={(evt) => {
                                t.markAsFixed(row, idx);
                            }}
                            filled
                            label="Mark as Reviewed"
                            type="checkbox"
                            id={row.reverseQualityReason + idx + "checkbox"}
                        />
                    </MDBCol>
                )
            }
        }

        return (
            this.state.rqLogs.map((l, idx) => {

                //canBeFixed = determineFixable(l);

                if (canBeFixed && !l.fixedOn) {
                    bgColor = "#FFE4F1";
                    headerColor = "#FFCCE5";
                } else if (l.fixedOn) {
                    bgColor = "#E4F9E0";
                    headerColor = "#CBF1C4";
                }

                return (<MDBCard key={idx} style={{marginTop: 10, backgroundColor: bgColor}}>
                        <MDBCardHeader style={{backgroundColor: headerColor}}>
                            <MDBRow style={{
                                fontSize: 12,
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}>
                                <MDBCol size={1}>
                                    <Route
                                        render={({history}) => (
                                            <span onClick={() => {
                                                if (l.orderId) {
                                                    history.push({
                                                        pathname: "/order/" + l.orderId,
                                                    });
                                                }
                                            }}>
                                                {l.orderId}
                                            </span>
                                        )}/>

                                </MDBCol>
                                <MDBCol size={4}>
                                    {l.rqQualityCategory.name}
                                </MDBCol>
                                <MDBCol size={3}>
                                    {l.rqQualityReason.name}
                                </MDBCol>

                                {renderCheckbox(l, idx)}
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody style={{paddingBottom: 2}}>


                            <MDBRow style={{paddingLeft: 10, paddingTop: 6}}>
                                <p>
                                    {l.notes}
                                </p>
                            </MDBRow>
                            <span style={{
                                float: 'right',
                                paddingRight: 10,
                                fontSize: 12
                            }}>Reported: {new Date(l.rqReportedOn).toLocaleDateString()}</span>
                        </MDBCardBody>

                    </MDBCard>
                )
            }))
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    render() {
        if (this.state.isLoading === true) {
            return this.renderLoadingSpinner();
        }
        return (
            <MDBContainer>
                <MDBCardHeader style={{marginTop: "3%"}} color={"indigo"} className="form-header text-center">
                    My Reverse Quality
                </MDBCardHeader>

                {this.renderLogRows()}


            </MDBContainer>
        );
    }
}
