import React from "react";
import PropTypes from "prop-types";
import {
    MDBRow,
    MDBCol,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBTabContent,
    MDBTabPane,
} from "mdbreact";
import "../Home/home.css";
import NoteService from "../Security/NoteService/noteService";
import TaskTable from "./taskTable";
import PCRNotificationsTable from "./pcrNotificationsTable";
import RQTable from "./rqTable";


class Tasks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            tasks: [],
            priority: [],
            isLoaded: false,
            activeItemJustified: "1",
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allRegions: PropTypes.array,
        reasons: PropTypes.array,
    };


    componentDidMount() {
        this.getTasksForUser();
    }

    getTasksForUser() {
        const { currentUser } = this.context;

        NoteService.getOpenTasks(currentUser.id)
            .then((tasks) => {
                this.setState({
                    tasks: tasks,
                    tasksLoaded: true,
                });
                return Promise.resolve(tasks);
            })
            .catch((err) => {
                console.log(err);
                return Promise.resolve([]);
            });
    }

    toggleJustified = (tab) => () => {
        if (this.state.activeItemJustified !== tab) {
            this.setState({
                activeItemJustified: tab,
            });
        }
    };

    render() {
        let st = this.state;
        let props = {
            tasks: this.state.tasks,
            loaded: this.state.tasksLoaded,
        };
        return (
            <MDBRow>
                <MDBCol size="12" style={{ padding: '20px 30px 0px' }}>
                    <MDBNav tabs className="nav-justified navFull" color="primary">
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={st.activeItemJustified === "1"}
                                onClick={this.toggleJustified("1")}
                                role="tab"
                            >
                                All Tasks
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={st.activeItemJustified === "4"}
                                onClick={this.toggleJustified("4")}
                                role="tab"
                            >
                                Reverse Quality
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={st.activeItemJustified === "5"}
                                onClick={this.toggleJustified("5")}
                                role="tab"
                            >
                                Sales Notifications
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav>

                    <MDBTabContent
                        className="card"
                        style={{ paddingTop: "1rem" }}
                        activeItem={st.activeItemJustified}
                    >
                        <MDBTabPane tabId="1" role="tabpanel">
                            {st.activeItemJustified === "1" ? (
                                <TaskTable {...props} />
                            ) : (
                                <div />
                            )}
                        </MDBTabPane>
                        <MDBTabPane tabId="4" role="tabpanel">
                            {st.activeItemJustified === "4" ? <RQTable /> : <div />}
                        </MDBTabPane>
                        <MDBTabPane tabId="5" role="tabpanel">
                            {st.activeItemJustified === "5" ? (
                                <PCRNotificationsTable />
                            ) : (
                                <div />
                            )}
                        </MDBTabPane>
                    </MDBTabContent>
                </MDBCol>
            </MDBRow>
        );
    }
}

export default Tasks;
