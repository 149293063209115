import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import User from "../../Utility/Crud/User";

export default class TileUser extends Tile {
    /**
      * @returns {string} The User name.
      */
    getTitle() {
        return this.props.user ? User.getDisplayName(this.props.user) : "None";
    }

    /**
     * @returns {string} The user's email. Ideally in the future we can change
     * this to title or something more meaningful.
     */
    getSubtitle() {
        return this.props.user ? this.props.user.email : null;
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.user ? User.getDisplayName(this.props.user) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.user ? User.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return User.getIcon();
    }
}