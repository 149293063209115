import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

export default withRouter(class CardAdminBaseUnitList extends Card {
    static contextTypes = {
        baseUnitsIndexed: PropTypes.object
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "baseUnitTypeId", name: "Base Unit Type", type: "baseUnitType", filter: true },
                    { key: "vendorId", name: "Vendor", type: "vendor", filter: true },
                    { key: "name", name: "Name", type: "string" },
                ]}
                sort={[
                    {
                        key: "baseUnitTypeId",
                        direction: "ascending",
                        type: "baseUnitType",
                    },
                    {
                        key: "vendorId",
                        direction: "ascending",
                        type: "vendor",
                    },
                    {
                        key: "name",
                        direction: "ascending",
                    },
                ]}
                rows={Object.values(this.context.baseUnitsIndexed)}
                onClick={(baseUnit) => {
                    this.props.history.push({
                        pathname: "/admin/baseUnit/" + baseUnit.id
                    });
                }}
            />
        );
    }
});