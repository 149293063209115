import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Input,
    Button,
    MDBIcon,
    MDBCardHeader,
    MDBCardBody,
    MDBCardFooter,
    Row,
    Col,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import CreateModal from "./CreateModal";
import Select from "react-select";
import { toast } from "react-toastify";

export default class InlineTable extends Component {
    constructor(props) {
        super(props);

        /********************************************
         * Columns sets the display order in the table header.
         * ColumnFieldMap sets the column header order with the row field.
         ********************************************/

        this.updateValues = props.editRowsMethod;
        this.deleteRow = props.deleteRowMethod;

        this.state = {
            columnFieldMap: props.columnFieldMap || [],
            rows: props.rows || [],
            isLoaded: props.isLoaded || false,
            allowRowSearch: props.allowRowSearch || false,
            inlineEdit: props.inlineEdit || false,
            batchSave: props.batchSave || false,
            fieldSearchVals: [],
            hideDeleted: false,
            filteredRows: props.rows ? JSON.parse(JSON.stringify(props.rows)) : [],
            page: 1,
            totalPages: 1,
            itemsPerPage: 10,
            paging: props.paging || false,
            filteredPagedRows: props.rows ? JSON.parse(JSON.stringify(props.rows)) : [],
            alteredRows: [],
            addModalOpen: false,
            modalHeader: props.modalHeader,
            filterRoles: props.filterRoles || [],
            vendorList: props.vendorList || [],
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };

    componentDidMount() {
        this.formatPages(1, 10);
    }

    insertSearchVal(field, val) {
        let searchVals = this.state.fieldSearchVals,
            fil = searchVals.filter(x => x.fieldName !== field),
            rows = this.state.rows,
            filteredRows = [];

        if (![null, undefined, "", []].includes(val)) {
            fil.push({
                fieldName: field,
                value: val,
            })
        }

        if (this.props.tableHeader === "Service Parts Catalog" && field === "vendorId") {
            rows.forEach((r) => {
                let vendor = this.state.vendorList.find((v) => v.id === r.vendorId)
                if (r.vendorId && r.vendorId !== null && vendor && vendor !== null) {
                    r.vendorName = vendor.name
                }  
            })
        }

        if (fil && fil.length > 0) {
            rows.filter(x => {
                fil.forEach(a => {
                    if (x[a.fieldName] && x[a.fieldName].toString().toLowerCase().indexOf(a.value.toLowerCase()) >= 0) {
                        return filteredRows.push(x)
                    }
                    if (this.props.tableHeader === "Service Parts Catalog" && field === "vendorId") {
                        if (x["vendorName"] && x["vendorName"].toString().toLowerCase().indexOf(a.value.toLowerCase()) >= 0) {
                            return filteredRows.push(x)
                        }
                    }
                })
                return x;
            })
        }
        else
            filteredRows = JSON.parse(JSON.stringify(this.state.rows));

        this.setState({
            fieldSearchVals: fil,
            filteredRows: filteredRows,
        })

        let paging = this.state.paging,
            eRows = this.state.editedRows || [],
            fRows = JSON.parse(JSON.stringify(filteredRows)),
            page = 1,
            perPage = this.state.itemsPerPage;

        if (paging) {
            var pgCt = Math.ceil(fRows.length / perPage);
            var temp = fRows.splice(perPage * (page - 1), perPage);
            if (eRows && eRows.length > 0) {
                temp.forEach((r, y) => {
                    var i = eRows.filter(x => x.id === r.id)[0];
                    if (i) {
                        temp.splice(y, 1, i);
                    }
                })
            }

            this.setState({
                page: page,
                totalPages: pgCt,
                filteredPagedRows: temp,
                itemsPerPage: perPage,
            })
        }
        else {
            console.log(filteredRows)
            this.setState({
                filteredPagedRows: filteredRows,
            })
        }
    }

    toggleDeleted() {
        this.setState({
            hideDeleted: !this.state.hideDeleted,
        })
    }

    formatPages(page, perPage) {
        let paging = this.state.paging,
            eRows = this.state.editedRows || [],
            fRows = JSON.parse(JSON.stringify(this.state.filteredRows));

        if (paging) {
            var pgCt = Math.ceil(fRows.length / perPage);
            var temp = fRows.splice(perPage * (page - 1), perPage);
            if (eRows && eRows.length > 0) {
                temp.forEach((r, y) => {
                    var i = eRows.filter(x => x.id === r.id)[0];
                    if (i) {
                        temp.splice(y, 1, i);
                    }
                })
            }

            this.setState({
                page: page,
                totalPages: pgCt,
                filteredPagedRows: temp,
                itemsPerPage: perPage,
            })
        }
    }

    toggleModal() {
        let isOpen = this.state.addModalOpen || false;

        this.setState({
            addModalOpen: !isOpen,
        })
    }

    changeValue(row, field, val) {
        let r = JSON.parse(JSON.stringify(this.state.filteredPagedRows)),
            or = this.state.rows,
            rr = r.filter(y => y.id === row.id)[0],
            tr = JSON.parse(JSON.stringify(or.filter(y => y.id === row.id)[0])),
            ind = r.indexOf(rr),
            eRows = this.state.editedRows && this.state.editedRows.length > 0 ? this.state.editedRows.filter(x => x.id !== row.id) : [],
            fieldMap = this.state.columnFieldMap.map(x => x.fieldName);

        row[field] = val;

        for (var i = 0; i < fieldMap.length; i++) {
            if (row[fieldMap[i]] !== tr[fieldMap[i]]) {
                row.modified = true;
                eRows.push(row);
                break;
            }
            else {
                row.modified = false;
            }
        }

        r.splice(ind, 1, row);

        this.setState({
            filteredPagedRows: r,
            editedRows: eRows,
        })
    }

    validateAndSave() {
        let r = this.state.editedRows,
            dm = this.state.columnFieldMap,
            canSave = true,
            msg = "";
        if (!r || r.length === 0) {
            toast.error("No entries have been modified.");
            return;
        }
        else {
            var rf = dm.filter(x => x.required === true) || [];
            if (rf && rf.length > 0) {
                var f = rf.map(x => x.fieldName);
                r.map(x => {
                    return f.map(y => {
                        if (!x[y] || x[y] === null || x[y] === undefined) {
                            msg += "Row (id: " + x.id + ") requires the " + y + " value.\n";
                            return canSave = false;
                        }
                        return null;
                    })
                })
            }
            if (canSave)
                this.updateValues(r);
            else if (msg)
                toast.error(msg);
        }
    }

    render() {
        const { currentUser } = this.context;
        let fieldMap = this.props.fieldMap || this.state.columnFieldMap,
            eRows = this.state.editedRows,
            rows = this.state.filteredPagedRows || this.state.filteredRows || this.state.rows,
            addDisabled = this.state.filterRoles && this.state.filterRoles.length > 0 ? !this.state.filterRoles.includes(currentUser.role) : false;

        let opts = [
            { label: "10", value: 10 },
            { label: "25", value: 25 },
            { label: "50", value: 50 },
            { label: "100", value: 100 },
        ];

        return (
            <div>
                <MDBCardHeader style={{ color: "white" }}>
                    <div>
                        <Row>
                            <Col md={2} style={{ alignSelf: "center" }}>
                                <Row>
                                    <label
                                        style={{ float: "left", paddingRight: ".5rem", alignSelf: "center" }}> Results
                                        Per Page:</label>
                                    <Col>
                                        <Select
                                            style={{ margin: "0" }}
                                            options={opts}
                                            placeholder={this.state.itemsPerPage}
                                            onChange={(e) => {
                                                this.setState({
                                                    itemsPerPage: e.value || 10,
                                                })
                                                this.formatPages(1, e.value || 10);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{ textAlign: "center", alignSelf: "center" }}>
                                <h3 style={{ color: "white" }}>{this.props.tableHeader}</h3>
                            </Col>
                            <Col md={2} style={{ justifyItems: "right" }}>

                                <Button
                                    style={{ float: "right" }}
                                    color={"green"}
                                    floating
                                    size="sm"
                                    data-tip={"Save Changes"}
                                    disabled={!this.state.editedRows || this.state.editedRows.length === 0}
                                    onClick={() => this.validateAndSave()}
                                >
                                    <MDBIcon icon="save" style={{ fontSize: "2em" }} />
                                </Button>

                                <Button
                                    disabled={addDisabled}
                                    style={{ float: "right" }}
                                    color={"primary"}
                                    floating
                                    size="sm"
                                    data-tip={"Add item"}
                                    onClick={() => this.toggleModal()}
                                >
                                    <MDBIcon icon="plus" style={{ fontSize: "2em" }} />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </MDBCardHeader>

                <MDBCardBody style={{ backgroundColor: "white", overflowX: "auto", minHeight: "35vh" }}>
                    <div style={{ display: "grid" }}>
                        <table cellpadding="3" style={{ tableLayout: "fixed", width: "100%" }}>
                            <thead>
                                <tr>
                                    {fieldMap && fieldMap.length > 0 ?
                                        fieldMap.map(x => {
                                            return (<th style={{ fontWeight: "bold", width: (x.width ? x.width : "185px") }}>{x.columnName}</th>)
                                        })
                                        : ""}
                                </tr>
                                {this.state.allowRowSearch === true ?
                                    <tr>
                                        {fieldMap && fieldMap.length > 0 ?
                                            fieldMap.map(x => {
                                                if (x.type !== "bool" && x.hidden !== true)
                                                    return (
                                                        <td style={{ paddingBottom: "16px" }}>
                                                            <input name={"search" + x.fieldName} type="text" placeholder="Search..." style={{ width: "100%" }} onChange={(evt) => this.insertSearchVal(x.fieldName, evt.target.value)} />
                                                        </td>)
                                                else
                                                    return <td style={{ minWidth: "20px" }} />
                                            })
                                            : ""}
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {
                                    rows && rows.length > 0 && rows.length <= 100 ?
                                        rows.map((r, index) => {
                                            let bg = (index % 2 === 0 ? "#efefef" : "white");
                                            bg = (r.modified && r.modified === true) || (eRows && eRows.length > 0 && eRows.filter(x => x.id === r.id).length > 0) ? "lightgreen" : bg;

                                            return (
                                                <tr style={{ backgroundColor: bg }}>
                                                    {fieldMap ? fieldMap.map(m => {
                                                        if (m.canEdit !== true) {
                                                            return (<td><label style={{ paddingTop: "8px", minWidth: "20px" }}>{r[m.fieldName]}</label></td>);
                                                        }
                                                        if (addDisabled) {
                                                            return (<td><label style={{ paddingTop: "8px", minWidth: "20px" }}>{r[m.fieldName]}</label></td>);
                                                        }
                                                        switch (m.type) {
                                                            case "bool":
                                                                return (<td>
                                                                    <Input
                                                                        style={{ width: "100%" }}
                                                                        type="checkbox" label=" "
                                                                        id={"chk_" + m.fieldName + "_" + r.id}
                                                                        checked={(r[m.fieldName] === true)}
                                                                        onChange={(e) => this.changeValue(r, m.fieldName, e.target.checked)}
                                                                    />
                                                                </td>)
                                                            case "int":
                                                                return (<td>
                                                                    <input
                                                                        style={{ width: "100%" }}
                                                                        type={"number"}
                                                                        value={r[m.fieldName] || ""}
                                                                        title={r[m.fieldName]}
                                                                        onChange={(e) => {
                                                                            this.changeValue(r, m.fieldName, parseInt(e.target.value))
                                                                        }}
                                                                    />
                                                                </td>)
                                                            case "float":
                                                                return (<td>
                                                                    <input
                                                                        style={{ width: "100%" }}
                                                                        type={"number"}
                                                                        value={r[m.fieldName] || ""}
                                                                        title={r[m.fieldName]}
                                                                        onChange={(e) => {
                                                                            this.changeValue(r, m.fieldName, parseFloat(e.target.value))
                                                                        }}
                                                                    />
                                                                </td>)
                                                            case "number":
                                                                return (<td>
                                                                    <input
                                                                        style={{ width: "100%" }}
                                                                        type={"number"}
                                                                        value={r[m.fieldName] || ""}
                                                                        title={r[m.fieldName]}
                                                                        onChange={(e) => {
                                                                            this.changeValue(r, m.fieldName, parseInt(e.target.value))
                                                                        }}
                                                                    />
                                                                </td>)
                                                            case "dropdown":
                                                                var opt = m.options.filter(x => x.value === r[m.fieldName]);
                                                    
                                                                return (<td>
                                                                    <Select
                                                                        menuPortalTarget={ document.body }
                                                                        styles={ { menuPortal: base => ({ ...base, zIndex: 9999 }) } }
                                                                        value={opt}
                                                                        options={m.options.sort((a, b) => a.label.localeCompare(b.label))}
                                                                        onChange={(e) => {
                                                                            this.changeValue(r, m.fieldName, parseInt(e.value))
                                                                        }}
                                                                    />
                                                                </td>)
                                                            case "icon":
                                                                if (m.fieldName === "delete") {
                                                                    return (<td>
                                                                        <MDBIcon
                                                                            icon={"trash"}
                                                                            size="2x"
                                                                            style={{ color: "red", marginLeft: "1em" }}
                                                                            data-tip={"Remove this product"}
                                                                            onClick={() => this.deleteRow(r.id)}
                                                                        />
                                                                    </td>)
                                                                }
                                                                else {
                                                                    return '';
                                                                }
                                                            case "text":
                                                                return (<td>
                                                                    <input
                                                                        style={{ width: "100%" }}
                                                                        type={"text"}
                                                                        value={r[m.fieldName] || ""}
                                                                        title={r[m.fieldName]}
                                                                        onChange={(e) => {
                                                                            this.changeValue(r, m.fieldName, e.target.value)
                                                                        }}
                                                                    />
                                                                </td>)
                                                            default:
                                                                return (<td>
                                                                    <input
                                                                        style={{ width: "100%" }}
                                                                        type={"text"}
                                                                        value={r[m.fieldName] || ""}
                                                                        title={r[m.fieldName]}
                                                                        onChange={(e) => {
                                                                            this.changeValue(r, m.fieldName, e.target.value)
                                                                        }}
                                                                    />
                                                                </td>)
                                                        }

                                                    }) : ''}
                                                </tr>)
                                        })
                                        : <tr><td colSpan={fieldMap.length}>No rows to show</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </MDBCardBody>

                <MDBCardFooter style={{ backgroundColor: "rgb(88,129,193)", color: "white" }}>
                    <Row>
                        <Col md={2} style={{ alignSelf: "center" }}>
                            <label >{this.state.filteredRows.length || 0} items</label>
                        </Col>
                        <Col >
                            <div style={{ textAlign: "center", display: "inline-block", width: "100%" }}>
                                {this.state.paging ?
                                    <span>
                                        <Button
                                            disabled={this.state.page === 1 ? true : false}
                                            data-tip="First page"
                                            size="sm"
                                            floating
                                            onClick={() => this.formatPages(1, this.state.itemsPerPage)}
                                        >
                                            <MDBIcon icon="angle-double-left" style={{ fontSize: "2em" }} />
                                        </Button>
                                        <Button
                                            disabled={this.state.page === 1 ? true : false}
                                            data-tip="Previous page"
                                            size="sm"
                                            floating
                                            onClick={() => this.formatPages(this.state.page - 1, this.state.itemsPerPage)}
                                        >
                                            <MDBIcon icon="angle-left" style={{ fontSize: "2em" }} />
                                        </Button>
                                        <label>{this.state.page} of {this.state.totalPages}</label>
                                        <Button
                                            disabled={(this.state.page === this.state.totalPages || this.state.totalPages === 0 ? true : false)}
                                            size="sm"
                                            floating
                                            data-tip="Next page"
                                            onClick={() => this.formatPages(this.state.page + 1, this.state.itemsPerPage)}
                                        >
                                            <MDBIcon icon="angle-right" style={{ fontSize: "2em" }} />
                                        </Button>
                                        <Button
                                            disabled={(this.state.page === this.state.totalPages || this.state.totalPages === 0 ? true : false)}
                                            size="sm"
                                            floating
                                            data-tip="Last page"
                                            onClick={() => this.formatPages(this.state.totalPages, this.state.itemsPerPage)}
                                        >
                                            <MDBIcon icon="angle-double-right" style={{ fontSize: "2em" }} />
                                        </Button>
                                    </span>
                                    : ''}
                            </div>
                        </Col>
                        <Col md={2} style={{ justifyItems: "right" }}>

                            <div style={{ textAlign: "center", display: "inline-block", width: "100%" }}>
                                <span>
                                    <Button
                                        style={{ float: "right" }}
                                        color={"green"}
                                        floating
                                        size="sm"
                                        data-tip={"Save Changes"}
                                        disabled={!this.state.editedRows || this.state.editedRows.length === 0}
                                        onClick={() => this.validateAndSave()}
                                    >
                                        <MDBIcon icon="save" style={{ fontSize: "2em" }} />
                                    </Button>

                                    <Button
                                        disabled={addDisabled}
                                        style={{ float: "right" }}
                                        color={"primary"}
                                        floating
                                        size="sm"
                                        data-tip={"Add item"}
                                        onClick={() => this.toggleModal()}
                                    >
                                        <MDBIcon icon="plus" style={{ fontSize: "2em" }} />
                                    </Button>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </MDBCardFooter>

                <CreateModal
                    toggleMethod={this.toggleModal.bind(this)}
                    saveMethod={this.props.addItemMethod}
                    isOpen={this.state.addModalOpen}
                    dataModel={fieldMap}
                    header={this.state.modalHeader}
                />

                <ReactTooltip />
            </div>)
    }
}