import React from "react";
import {
    Container,
    Spinner,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBListGroup,
    MDBListGroupItem,
    MDBBadge,
} from "mdbreact";
import moment from "moment";
import Select from "react-select";
import PresidentsClubService from "../Security/PresidentsClubService/presidentsClubService";
import PropTypes from "prop-types";
import "./presidentsClub.css";

export default class presidentsClubView extends React.Component {
    constructor(props) {
        super(props);

        let years = [];
        // Get current year and last year...JK
        for (let i = 0; i < 2; i++) {
            years.push({
                label: moment().subtract(i, "years").get("year"),
                value: moment().subtract(i, "years").get("year"),
            });
        }
        this.state = {
            isLoaded: false,
            data: [],
            top: [],
            years: years,
            applyLoaded: true,
            activeChunk: 0,
            chunkLength: 0,
            timer: {},
        };
    }

    componentDidMount() {
        this.retrievePresidentsClub();
    }

    setTimer() {
        clearInterval(this.state.timer);

        let t = setInterval(() => {
            let ac = this.state.activeChunk,
                cl = this.state.chunkLength,
                res = (ac += 1) % cl;
            this.setState({activeChunk: res});
        }, 500000);
        this.setState({
            timer: t,
        });
    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };

    retrievePresidentsClub(year) {
        let filters = {year: year || ""};
        return PresidentsClubService.getPresidentsClub(filters)
            .then((res) => {
                let top = res.slice(0, 10);
                res.splice(0, 10);
                let arr = [];
                //Remove service users...JK
                let regEx = new RegExp("service|Service");
                res.map((e) => {
                    if (!regEx.test(e.username)) {
                        return arr.push(e);
                    }
                    return null;
                });
                let i = 0;
                let chunks = [];
                while (i < arr.length) {
                    chunks.push(arr.slice(i, 14 + i));
                    i += 14;
                }
                this.setTimer();
                this.setState({
                    data: JSON.stringify(chunks),
                    top: top,
                    isLoaded: true,
                    applyLoaded: true,
                    chunkLength: Math.floor(chunks.length),
                    activeChunk: 0,
                });
                //// Separate the top 5 sales reps from the rest of the list...JK
                //let top = res.slice(0, 13);
                //res.splice(0, 13);
                //let arr = [];
                ////Remove service users...JK
                //let regEx = new RegExp("service|Service");
                //res.map((e) => {
                //    if (!regEx.test(e.username)) {
                //        return arr.push(e);
                //    }
                //    return null;
                //});
                //let i = 0;
                //let chunks = [];
                //while (i < arr.length) {
                //    chunks.push(arr.slice(i, 16 + i));
                //    i += 16;
                //}
                //this.setTimer();
                //this.setState({
                //    data: JSON.stringify(chunks),
                //    top: top,
                //    isLoaded: true,
                //    applyLoaded: true,
                //    chunkLength: Math.floor(chunks.length),
                //});
            })
            .catch((err) => {
                console.log(err);
            });
    }

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

    renderTop(range) {
        let st = this.state.top;
        let t = range === "top" ? st.slice(0, 5) : st.slice(5, st.length);

        return t.map((obj, ind) => {
            return (
                <MDBRow className="topRow" key={ind}>
                    <MDBCol key={obj.username}>
                        <MDBCard pricing className="topCard white-text">
                            <div className="topCardDiv rounded-top">
                                <h4
                                    className="option"
                                    style={{
                                        padding: "1rem",
                                        marginBottom: 0,
                                        fontWeight: 500,
                                    }}
                                >
                                    #{obj.place}
                                </h4>
                            </div>
                            <MDBCardBody className={"topCardBody "}>
                                <h4>
                                    {obj.username}: {obj.totalpoints} Points
                                </h4>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            );
        });
    }

    renderBody() {
        return (
            <MDBListGroup className={"listGroup"}>
                {this.renderTableBody()}
            </MDBListGroup>
        );
    }

    renderDots() {
        let data = JSON.parse(this.state.data),
            activeChunk = this.state.activeChunk;
        return data.map((e, index) => {
            return (
                <span
                    key={index}
                    onClick={() => this.dotClicked(index)}
                    className={index === activeChunk ? "activeDot" : "dot"}
                />
            );
        });
    }

    dotClicked(dot) {
        clearInterval(this.state.timer);
        this.setTimer();
        if (this.state.activeChunk !== dot) {
            this.setState({
                activeChunk: dot,
            });
        }
    }

    renderFilter() {
        let y = this.state.years;
        return (
            <Select
                defaultValue={y[0]}
                options={y}
                onChange={this.handleYearChange.bind(this)}
            />
        );
    }

    handleYearChange(e) {
        this.retrievePresidentsClub(e.value);
    }

    renderTableBody() {
        let data = JSON.parse(this.state.data);

        if (data && data.length > 0) {
            return data[this.state.activeChunk].map((obj, index) => {
                return (
                    <MDBListGroupItem
                        key={index}
                        color={index % 2 === 0 ? "primary" : "secondary"}
                        className=" d-flex justify-content-between align-items-center listItem"
                    >
                        <b> {"#" + obj.place}</b>
                        <b>{obj.username}</b>
                        <MDBBadge className={"badge"} color={"unique-color"} pill>
                            {obj.totalpoints}
                        </MDBBadge>
                    </MDBListGroupItem>
                );
            });
        }
    }
    render() {
        const { currentUser } = this.context;
        if (
            [
                "ADMIN",
                "SYSTEM_ADMIN",
                "EXECUTIVE_MANAGEMENT",
                "SALES_MANAGER",
                "SALES",
            ].includes(currentUser.role)
        ) {
            if (this.state.isLoaded === false) {
                return this.renderLoadingSpinner();
            }
            return (
                <Container className={"prezContainer"} style={{ marginTop: "10px" }}>
                    <MDBRow>
                        <MDBCol>
                            <div>{this.renderTop("top")}</div>
                        </MDBCol>
                        <MDBCol>
                            <MDBRow>{this.renderTop("bottom")}</MDBRow>
                        </MDBCol>
                        <MDBCol>{this.renderBody()}</MDBCol>
                        <MDBCol>
                            {this.renderFilter()}
                            <div className={"dotDiv"}>{this.renderDots()}</div>
                        </MDBCol>
                    </MDBRow>
                </Container>
            );
        } else {
            return (
                <div>
                    <h1>Not Authorized to view this page</h1>
                </div>
            );
        }
    }
}
