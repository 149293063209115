import Card from "../../../Card";
import React from "react";
import PropTypes from "prop-types";
import MUIGrid from "@mui/material/Grid"
import MUIFormControlLabel from '@mui/material/FormControlLabel';
import MUISwitch from '@mui/material/Switch';
import MUITextField from "@mui/material/TextField";
import MUIMenuItem from '@mui/material/MenuItem';
import MUIFormControl from '@mui/material/FormControl';
import MUIAlert from "@mui/material/Alert";

export default class CardAdminQueueRouteTrigger extends Card {
    constructor(props) {
        super(props);
        this.state.hasTrigger = props.queueRoute && props.queueRoute.trigger !== null;
        this.props.onChange({ hasTrigger: this.state.hasTrigger });
    }

    static contextTypes = {
        orderActivityTypesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
    };

    // Name and description can change but do not change value!
    static triggers = [
        { value: "addPartAndAuthorization", name: "Add part", description: "Adds a service part (depending on subtype), then approves that part" },
        { value: "toggleCreateNoteModal", name: "Require note", description: "Requires adding a note" },
        { value: "toggleAuthNoteModal", name: "Require auth note", description: "Requires adding an auth note" },
        { value: "createApprovalNote", name: "Approve", description: "Creates an approval note" },
        { value: "togglePreAuthModal", name: "Approve with preauth ", description: "Creates an approval note and asks if preauth is required" },
        { value: "addUnableToPickupNote", name: "Add \"Unable to pick up\" note", description: "Creates a note stating that the equipment was unable to be picked up" },
        { value: "routeBackToSender", name: "Route back to sender ", description: "Routes the ticket back to sender" }

    ];

    /**
     * Render the content of the card.
     */
    renderContent() {
        const selectedTrigger = this.props.queueRoute ? CardAdminQueueRouteTrigger.triggers.find((trigger) => {
            return (trigger.value === this.props.queueRoute.trigger)
        }) : null;

        const handleTriggerNameChange = (e) => {
            this.props.onChange({ trigger: e.target.value });
        };

        const handleHasTriggerChange = (e) => {
            this.props.onChange({ hasTrigger: e.target.checked });
            if (e.target.checked === false) {
                this.props.onChange({ trigger: null });
            }
            this.setState({ hasTrigger: e.target.checked });
        }

        const selectTriggerField = (
            <MUIGrid item xs={12} sm={6} md={6}>
                <MUIFormControl fullWidth={true} required={true} variant="standard" >
                    <MUITextField
                        select={true}
                        value={selectedTrigger ? selectedTrigger.value : ""}
                        size="small"
                        label="Trigger"
                        variant="standard"
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleTriggerNameChange}
                        required={true}
                        error={this.props.validationIssues.trigger ? true : false}
                        helperText={this.props.validationIssues.trigger ? this.props.validationIssues.trigger : ""}
                    >
                        {CardAdminQueueRouteTrigger.triggers.map((trigger, i) => {
                            return (
                                <MUIMenuItem key={i} value={trigger.value}>{trigger.name}</MUIMenuItem>
                            )
                        })}
                    </MUITextField>
                </MUIFormControl>
            </MUIGrid>
        );

        return (
            <MUIGrid container spacing={2} >
                <MUIGrid item xs={12} sm={6} md={6}>
                    <MUIFormControlLabel
                        control={<MUISwitch />}
                        label="Enable Trigger"
                        onChange={handleHasTriggerChange}
                        checked={this.state.hasTrigger}
                    />
                </MUIGrid>

                {this.state.hasTrigger && selectTriggerField}
                <MUIGrid item xs={12}>
                    {selectedTrigger && selectedTrigger.value !== "routeBackToSender" && (
                        <MUIAlert severity="info">
                            {selectedTrigger.description + " prior to routing the "}
                            <strong>
                                {
                                    (this.context.orderActivityTypesIndexed[this.props.queueRoute.orderActivityTypeId] ? this.context.orderActivityTypesIndexed[this.props.queueRoute.orderActivityTypeId].name : "No Order Type") + " " +
                                    (this.context.orderActivityTypesIndexed[this.props.queueRoute.orderActivityTypeId] ? this.context.orderActivityTypesIndexed[this.props.queueRoute.orderActivityTypeId].activityType : "No Activity Type") + " "
                                }
                            </strong>
                            {"to "}
                            <strong>
                                {this.context.orderStatusReasonsIndexed[this.props.queueRoute.toOrderStatusReasonId] ? this.context.orderStatusReasonsIndexed[this.props.queueRoute.toOrderStatusReasonId].description : "No To Order Status"}
                            </strong>
                            {"."}
                        </MUIAlert>
                    )}
                    {selectedTrigger && selectedTrigger.value === "routeBackToSender" && (
                        <MUIAlert severity="info">
                            {selectedTrigger.description + " and ignores the configured route."}
                        </MUIAlert>
                    )}
                </MUIGrid>
            </MUIGrid >
        );
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Trigger";
    }
}