import api from "../api";

class ReportsService {

	//setups/sales report data
	// calls proc spGetSalesReport
	async getSalesReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				start: filters.startDate,
				end: filters.endDate,
				users: filters.users ? filters.users.toString() : "",
				pointsOnly: filters.pointsOnlyProducts,
			};
			api
				.post("/salesReport", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getComplaintReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				start: filters.startDate,
				end: filters.endDate,
			};
			api
				.post("/complaintsView", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getServiceOrderReasonReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				start: filters.startDate,
				end: filters.endDate,
			};
			api
				.post("/serviceOrderReasonReport", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	//activity report data
	// calls proc spGetActivityReport
	async getActivityReport(filters) {
		console.log(filters)
		return new Promise((res, reject) => {
			let params = {
				start: filters.startDate,
				end: filters.endDate,
				users: filters.users ? filters.users.toString() : "",
				locations: filters.location ? filters.location.toString() : "",
			};
			api
				.post("/activityReport", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	//approvals report data
	async getApprovalsReport(filters) {
		console.log(filters)
		return new Promise((res, reject) => {
			let params = {
				zero: filters.pointsOnlyProducts || "n",
				start: filters.startDate,
				end: filters.endDate,
				users: filters.ids ? filters.ids.toString() : "",
			};
			api
				.post("/approvalReport", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	//approvals report data
	// calls proc spApprovalReport
	async getApprovalsReportNative(filters) {
		return new Promise((res, reject) => {
			api
				.get("/reports/approvalsNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	//service report data
	// calls proc spGetServiceReport
	async getServiceReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				start: filters.startDate,
				end: filters.endDate,
				locations: filters.locations ? filters.locations.toString() : "",
			};
			api
				.post("/serviceReport", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	//delivery report data
	// calls proc spGetDeliveryReport
	async getDeliveryReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				start: filters.startDate,
				end: filters.endDate,
				users: filters.users ? filters.users.toString() : "",
				locations : filters.location || "",
			};
			api
				.post("/deliveryReport", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	//expense report data
	// calls proc spGetExpensesReport
	async getExpensesReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				start: filters.startDate,
				end: filters.endDate,
				users: filters.users ? filters.users.toString() : "",
			};
			//exec spGetExpensesReport :ids, :from, :until
			api
				.post("/expenseReport", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	//accounts report data
	// calls proc spGetAccountReport
	async getAccountsReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				start: filters.startDate,
				end: filters.endDate,
				users: filters.users ? filters.users.toString() : "",
			};
			api
				.post("/accountReport", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	// calls proc spCalcRepBonus
	async getBonusReport(filters) {
		return new Promise((res, reject) => {
			api
				.get("/repBonus", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	// calls TWO procs
	// calls proc spProductionMonthly
	// calls proc spProductionMonthlyNotatedOrders
	async getProductionReport(filters) {
		return new Promise((res, rej) => {
			api
				.get("/productionReport", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					rej(err);
				});
		});
	}
	async getProductionMonthlyNotationsReport(filters) {
		return new Promise((res, rej) => {
			api
				.get("/productionMonthlyNotationsReport", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					rej(err);
				});
		});
	}

	// calls proc spGetOrderCountsByDay
	async getDailyOrderCounts(filters) {
		return new Promise((res, rej) => {
			api
				.get("/dailyOrderCounts", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					rej(err);
				});
		});
	}

	// calls proc spOrderCountsByDayByLocation
	async getDailyOrderCountsLocation(filters) {
		return new Promise((res, rej) => {
			api
				.get("/dailyLocationOrderCounts", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					rej(err);
				});
		});
	}

	// calls proc spOrderCountsByDayByLocation
	async getShippingDetailsReport(filters) {
		return new Promise((res, rej) => {
			api
				.get("/shippingDetailsReport", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					rej(err);
				});
		});
	}

	async getInventoryReport(filters) {
		return new Promise((res, rej) => {
			api
				.get("/inventoryReport", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					rej(err);
				});
		});
	}

}

const reportsService = new ReportsService();
export default reportsService;
