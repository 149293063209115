import Crud from "../Crud";
import MUIRedColor from "@mui/material/colors/red";

/**
 * Static helper functions related to Issue Statuses.
 */
export default class IssueStatus extends Crud {

    /**
     * @param {object} issueStatus The issueStatus object.
     * @returns The display name of the issueStatus.
     */
    static getDisplayName(issueStatus) {
        return issueStatus.name;
    }

    /**
     * Determines if an issueStatus matches a search query.
     *
     * @param {object} issueStatus The issueStatus to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the issueStatus matches the query.
     */
    static match(issueStatus, query) {
        return (
            (issueStatus.name !== null && issueStatus.name.toLowerCase().includes(query)) ||
            issueStatus.id === +query
        );
    }

    /**
     * @param {array} issueStatuses The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(issueStatuses) {
        return issueStatuses.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIRedColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "MarkChatRead";
    }
}