import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import Queue from "../../../../Utility/Crud/Queue";
import MUIAmberColor from "@mui/material/colors/amber";

export default withRouter(class CardAdminQueueRouteList extends Card {
    static contextTypes = {
        queueRoutesIndexed: PropTypes.object,
        queuesIndexed: PropTypes.object,
        teamQueuesIndexed: PropTypes.object,
    };

    /**
     * Renders the content of the card.
     */
    renderContent() {
        const modifiedRows = Object.values(this.context.queueRoutesIndexed).map((queueRoute) => {

            return {
                ...queueRoute,
                ...{
                    matchingQueueMissing: Queue.getMatchingQueuesForQueueRoute(
                        Object.values(this.context.queuesIndexed),
                        Object.values(this.context.teamQueuesIndexed),
                        queueRoute
                    ).length === 0
                },
            };
        });

        return (
            <Table
                search={true}
                columns={[
                    { key: "matchingQueueMissing", name: "", type: "boolean", icon: "Warning", color: MUIAmberColor[500], width: "50" },
                    { key: "orderActivityTypeId", name: "Order Activity Type", type: "orderActivityType", width: "180", filter: true },
                    { key: "fromOrderStatusReasonId", name: "From Order Status Reason", type: "orderStatusReason", filter: true },
                    { key: "toOrderStatusReasonId", name: "To Order Status Reason", type: "orderStatusReason", filter: true },
                    { key: "name", name: "Name", type: "string" },
                ]}
                sort={[
                    {
                        key: "orderActivityTypeId",
                        direction: "ascending",
                        type: "orderActivityType",
                    },
                    {
                        key: "fromOrderStatusReasonId",
                        direction: "ascending",
                        type: "orderStatusReason",
                    },
                    {
                        key: "toOrderStatusReasonId",
                        direction: "ascending",
                        type: "orderStatusReason",
                    },
                    {
                        key: "name",
                        direction: "ascending",
                    },
                ]}
                rows={modifiedRows}
                onClick={(queueRoute) => {
                    this.props.history.push({
                        pathname: "/admin/queueRoute/" + queueRoute.id
                    });
                }}
            />
        );
    }
});