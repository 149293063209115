import React from "react";
import DialogSearch from "../DialogSearch"
import TileInsuranceType from "../../Tile/TileInsuranceType";
import PropTypes from "prop-types";
import InsuranceType from "../../../Utility/Crud/InsuranceType";

export default class DialogSearchInsuranceType extends DialogSearch {

    static contextTypes = {
        insuranceTypesIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered insuranceType.
     */
    getSearchResults(query) {
        return InsuranceType.search(Object.values(this.context.insuranceTypesIndexed), query, true);
    }

    /**
     * @param {object} insuranceType The insuranceType object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileInsuranceType component for this insuranceType.
     */
    renderTile(insuranceType, onClick) {
        const handleClick = () => {
            onClick(insuranceType);
            this.close();
        }

        return (
            <TileInsuranceType
                key={insuranceType.id}
                insuranceType={insuranceType}
                onClick={handleClick}
            />
        );
    }
}
