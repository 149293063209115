import React from "react";
import {Route, Switch} from "react-router-dom";

import Login from "./Seating/Login/login";
import PatientOrderView from "./Seating/Patient/patientOrderView";
import PatientInformationView from "./Seating/Patient/patientInformationView";
import Home from "./Seating/Home/home";
import UserList from "./Seating/Users/userList";
import UserEdit from "./Seating/Users/userEdit";
import UserAdd from "./Seating/Users/userAdd";

// Dictionary Admin Layers
import AdminQueueTypeCreateEdit from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditQueueType";
import AdminQueueTypeList from "./Layer/Admin/Term/TermList/AdminTermListQueueType";
import AdminDepartmentCreateEdit from "./Layer/Admin/Department/AdminDepartmentCreateEdit";
import AdminDepartmentList from "./Layer/Admin/Department/AdminDepartmentList";
import AdminOrderActivityTypeCreateEdit from "./Layer/Admin/OrderActivityType/AdminOrderActivityTypeCreateEdit";
import AdminOrderActivityTypeList from "./Layer/Admin/OrderActivityType/AdminOrderActivityTypeList"
import AdminQueueCreateEdit from "./Layer/Admin/Queue/AdminQueueCreateEdit";
import AdminQueueList from "./Layer/Admin/Queue/AdminQueueList";
import AdminRegionCreateEdit from "./Layer/Admin/Region/AdminRegionCreateEdit";
import AdminRegionList from "./Layer/Admin/Region/AdminRegionList";
import AdminQueueRouteCreateEdit from "./Layer/Admin/QueueRoute/AdminQueueRouteCreateEdit";
import AdminQueueRouteList from "./Layer/Admin/QueueRoute/AdminQueueRouteList";
import AdminTeamCreateEdit from "./Layer/Admin/Team/AdminTeamCreateEdit";
import AdminTeamList from "./Layer/Admin/Team/AdminTeamList";
import AdminBaseUnitTypeCreateEdit from "./Layer/Admin/BaseUnitType/AdminBaseUnitTypeCreateEdit";
import AdminBaseUnitTypeList from "./Layer/Admin/BaseUnitType/AdminBaseUnitTypeList";
import AdminBaseUnitCreateEdit from "./Layer/Admin/BaseUnit/AdminBaseUnitCreateEdit";
import AdminBaseUnitList from "./Layer/Admin/BaseUnit/AdminBaseUnitList";
import AdminTermListInsuranceGroup from "./Layer/Admin/Term/TermList/AdminTermListInsuranceSubTypeGroup";
import AdminTermCreateEditInsuranceGroup from "./Layer/Admin/Term/TermCreateEdit/AdminTermCreateEditInsuranceSubTypeGroup";
import AdminBtProductList from "./Layer/Admin/BtProduct/AdminBtProductList";
import AdminBtProductCreateEdit from "./Layer/Admin/BtProduct/AdminBtProductCreateEdit";

// Queues
import Queue from "./Layer/Queue/Queue"
import QueueDashboard from "./Layer/Queue/QueueDashboard";

import ThirdPartyUserList from "./Seating/Users/thirdPartyUserList";
import UserRoleQuotas from "./Seating/Settings/userRoleQuotas";
import Kiosk from "./Seating/Home/kiosk";
import OrderList from "./Seating/Orders/orderList";
import PatientList from "./Seating/Patient/patientList";
import Locations from "./Seating/Settings/locations";
import IssueCategoryList from "./Seating/Complaints/CustomerComplaintsSettings";
import RecurringOrders from "./Seating/Orders/RecurringOrders";
import DeliveryManifest from "./Seating/Patient/deliveryManifest";
import MapComponentWindow from "./Seating/Deliveries/mapComponentWindow";
import ForgotPassword from "./Seating/Login/forgotPassword";
import PurchaseQueueView from "./Seating/PurchaseQueue/purchaseQueueView";
import InventoryRequest from "./Seating/PurchaseQueue/inventoryRequest";
import StateSalesTax from "./Seating/Settings/stateSalesTax";
import DailyOrderCounts from "./Seating/Reports/dailyOrderCounts";
import ReverseQualityList from "./Seating/Settings/reverseQualityList";
import ReverseQualityReasons from "./Seating/Settings/reverseQualityReasons";
import Faxes from "./Seating/Faxes/faxes";
import InventoryTacking from "./Seating/Deliveries/inventoryTracking";
import PatientApprovals from "./Seating/Settings/patientApprovals";
//account files
import AccountList from "./Seating/Accounts/accountList";
import AccountEdit from "./Seating/Accounts/accountEdit";
import AccountAdd from "./Seating/Accounts/accountAdd";
import AccountDetail from "./Seating/Accounts/accountDetail";
//activity files
import ActivityList from "./Seating/Activities/activities-list";
import ActivityEdit from "./Seating/Activities/activities-edit";
import ActivityAdd from "./Seating/Activities/activities-add";
//settings files
import AddVersion from "./Seating/Settings/addVersion";
import ProductTypes from "./Seating/Settings/productTypes";
import EditProductSubType from "./Seating/Settings/editProductSubTypes";
import AddProductSubType from "./Seating/Settings/addProductSubType";
import Insurance from "./Seating/Settings/insurance";
import InsuranceSubType from "./Seating/Settings/insuranceSubType";
import InsuranceSubTypeDetails from "./Seating/Settings/InsuranceSubTypeDetails";
import ProductSize from "./Seating/Settings/productSize";
import AddProductSize from "./Seating/Settings/addProductSize";
import AccountTypes from "./Seating/Settings/accountTypes";
import NotificationTypes from "./Seating/Settings/notificationTypes";
import ContactTypes from "./Seating/Settings/contactTypes";
import CreditCardTypes from "./Seating/Settings/creditCardTypes";
import DocumentTypes from "./Seating/Settings/documentTypes";
import ExpenseTypes from "./Seating/Settings/expenseTypes";
import DeliveryAcknowledgements from "./Seating/Settings/deliveryAcknowledgements";
import AddDeliveryAcknowledgement from "./Seating/Settings/addDeliveryAcknowledgement";
import Reasons from "./Seating/Settings/reasons";
import Deployment from "./Seating/Settings/deployment";
//import ScheduledDeliveries from "./Seating/Deliveries/scheduledDeliveries";
import DeliveryList from "./Seating/Deliveries/deliveryList";
import CollectionsView from "./Seating/Orders/collectionsView";
import ServiceDocumentationView from "./Seating/Orders/serviceDocumentationView";

import ATPView from "./Seating/Orders/ATPView";
import TechList from "./Seating/Deliveries/techList";
import ManualDocBuilder from "./Seating/Settings/manualDocumentBuilder";
import Vendor from "./Seating/Settings/vendor";
import ProductMatrixEntry from "./Seating/Settings/productMatrixEntry";
import InventoryProductView from "./Seating/InventoryProduct/inventoryProductView";
//report files
import Reports from "./Seating/Reports/reports";
import BonusReport from "./Seating/Reports/bonusReport";
import SalesPipelineGraphView from "./Seating/Reports/salesPipelineGraphView"
import MonthGlanceView from "./Components/Pipeline/MonthGlance";

//Task files
import Tasks from "./Seating/Tasks/Tasks";

//report files
import Expenses from "./Seating/Expenses/expenses-list";
import ExpenseEdit from "./Seating/Expenses/expenses-edit";
//import ExpenseAdd from "./Seating/Expenses/expenses-add";

//contact files
import Contacts from "../src/Seating/Contacts/contact-list";
import ContactEdit from "../src/Seating/Contacts/contact-edit";

// marketing
import CampaignList from "./Seating/Marketing/campaignList";
import CategoryList from "./Seating/Marketing/categoryList";
import CampaignCharts from "./Seating/Marketing/campaignCharts";

// presidents club
import PresidentsClubView from "./Seating/PresidentsClub/presidentsClubView";

// sales liaison meetings
import LiaisonMeetingView from "./Seating/LiaisonMeetings/liaisonMeetingView";

import PatientCopy from "./Seating/Marketing/patientCopy";
import TrainingLink from "./Seating/Settings/trainingLink";
import MyReverseQuality from "./Seating/Users/myReverseQuality";
import PCRLink from "./Seating/Users/pcrLink";
import InternalRequest from "./Seating/Orders/InternalRequest";
import HomeAssessmentCompare from "./Seating/Settings/HomeAssessmentCompare";
import ShippingDetailsReport from "./Seating/PurchaseQueue/ShippingDetailsReport";
import ServicePartList from "./Seating/PurchaseQueue/ServicePartList";

class Routes extends React.Component {
	render() {
		return (
			<Switch>
				<Route exact path="/login" component={Login} />
				<Route exact path="/forgotPassword" component={ForgotPassword} />
				<Route exact path="/" component={Login} />
				<Route exact path="/dashboard" component={QueueDashboard} />
				<Route exact path="/patients" component={Home} />
				<Route exact path="/order/:id" component={PatientOrderView} />
				<Route
					exact
					path="/patientInformation/:id"
					component={PatientInformationView}
				/>
				<Route
					exact
					path="/patientInformation/:id/:orderId"
					component={PatientInformationView}
				/>
				<Route exact path="/userList" component={UserList} />
				<Route exact path="/user/:id" component={UserEdit} />

				{/* Admin */}
				<Route exact path="/admin/queueTypes" component={AdminQueueTypeList} />
				<Route exact path="/admin/queueType/:id" component={AdminQueueTypeCreateEdit} />
				<Route exact path="/admin/queueType" component={AdminQueueTypeCreateEdit} />

				<Route exact path="/admin/departments" component={AdminDepartmentList} />
				<Route exact path="/admin/department/:id" component={AdminDepartmentCreateEdit} />
				<Route exact path="/admin/department" component={AdminDepartmentCreateEdit} />

				<Route exact path="/admin/teams" component={AdminTeamList} />
				<Route exact path="/admin/team" component={AdminTeamCreateEdit} />
				<Route exact path="/admin/team/:id" component={AdminTeamCreateEdit} />

				<Route exact path="/admin/orderActivityTypes" component={AdminOrderActivityTypeList} />
				<Route exact path="/admin/orderActivityType" component={AdminOrderActivityTypeCreateEdit} />
				<Route exact path="/admin/orderActivityType/:id" component={AdminOrderActivityTypeCreateEdit} />

				<Route exact path="/admin/queues" component={AdminQueueList} />
				<Route exact path="/admin/queue" component={AdminQueueCreateEdit} />
				<Route exact path="/admin/queue/:id" component={AdminQueueCreateEdit} />

				<Route exact path="/admin/queueRoutes" component={AdminQueueRouteList} />
				<Route exact path="/admin/queueRoute" component={AdminQueueRouteCreateEdit} />
				<Route exact path="/admin/queueRoute/:id" component={AdminQueueRouteCreateEdit} />

				<Route exact path="/admin/baseUnits" component={AdminBaseUnitList} />
				<Route exact path="/admin/baseUnit" component={AdminBaseUnitCreateEdit} />
				<Route exact path="/admin/baseUnit/:id" component={AdminBaseUnitCreateEdit} />

				<Route exact path="/admin/baseUnitTypes" component={AdminBaseUnitTypeList} />
				<Route exact path="/admin/baseUnitType" component={AdminBaseUnitTypeCreateEdit} />
				<Route exact path="/admin/baseUnitType/:id" component={AdminBaseUnitTypeCreateEdit} />

				<Route exact path="/admin/insuranceSubTypeGroups" component={AdminTermListInsuranceGroup} />
				<Route exact path="/admin/insuranceSubTypeGroup/:id" component={AdminTermCreateEditInsuranceGroup} />
				<Route exact path="/admin/insuranceSubTypeGroup" component={AdminTermCreateEditInsuranceGroup} />

				<Route exact path="/admin/btProducts" component={AdminBtProductList} />
				<Route exact path="/admin/btProduct" component={AdminBtProductCreateEdit} />
				<Route exact path="/admin/btProduct/:id" component={AdminBtProductCreateEdit} />

				<Route exact path="/admin/regions" component={AdminRegionList} />
				<Route exact path="/admin/region" component={AdminRegionCreateEdit} />
				<Route exact path="/admin/region/:id" component={AdminRegionCreateEdit} />

				{/* Queue */}
				<Route exact path="/queue/:id" component={Queue} />

				<Route exact path="/userRoleQuotas/" component={UserRoleQuotas} />
				<Route
					exact
					path="/order/:id/deliveryManifest"
					component={DeliveryManifest}
				/>
				<Route
					exact
					path={"/reverseQualityLogs"}
					component={ReverseQualityList}
				/>

				<Route
					exact
					path={"/reverseQualityReasons"}
					component={ReverseQualityReasons}
				/>

				<Route
					exact
					path={"/myReverseQuality"}
					component={MyReverseQuality} />

				<Route
					exact
					path="/thirdPartyUserList"
					component={ThirdPartyUserList}
				/>
				<Route exact path="/faxes/" component={Faxes} />
				<Route exact path="/inventoryTracking/" component={InventoryTacking} />
				<Route exact path="/addUser/" component={UserAdd} />
				<Route exact path="/kiosk" component={Kiosk} />
				<Route exact path="/order" component={OrderList} />
				<Route exact path="/patientList/:patient" component={PatientList} />
				<Route exact path="/patientList/" component={PatientList} />

				{/* Account Route */}
				<Route exact path="/accounts" component={AccountList} />
				<Route exact path="/account/:id" component={AccountEdit} />
				<Route exact path="/addAccount" component={AccountAdd} />
				<Route exact path="/account/:id/detail" component={AccountDetail} />
				{/* Activity Route */}
				<Route exact path="/activities" component={ActivityList} />
				<Route exact path="/activity/:id" component={ActivityEdit} />
				<Route exact path="/addActivity" component={ActivityAdd} />
				{/*Settings Route */}
				<Route exact path="/version" component={AddVersion} />
				<Route exact path="/products" component={ProductTypes} />
				<Route exact path="/products/:id" component={EditProductSubType} />
				<Route exact path="/productType/new" component={AddProductSubType} />
				<Route exact path="/insurance" component={Insurance} />
				<Route exact path="/insuranceSubTypes" component={InsuranceSubType} />
				<Route exact path="/insuranceSubTypeDetails" component={InsuranceSubTypeDetails} />
				<Route exact path={"/stateSalesTax"} component={StateSalesTax} />
				<Route exact path="/productSizes" component={ProductSize} />
				<Route exact path="/productSizes/new" component={AddProductSize} />
				<Route exact path="/accountTypes" component={AccountTypes} />
				<Route exact path="/NotificationTypes" component={NotificationTypes} />
				<Route exact path="/contactTypes" component={ContactTypes} />
				<Route exact path="/customerFeedback/" component={IssueCategoryList} />
				<Route exact path="/recurringOrders/" component={RecurringOrders} />
				<Route exact path="/trainingLink/" component={TrainingLink} />
				<Route exact path="/creditCardTypes" component={CreditCardTypes} />
				<Route exact path="/documentTypes" component={DocumentTypes} />
				<Route exact path="/expenseTypes" component={ExpenseTypes} />
				<Route
					exact
					path="/deliveryAcknowledgements"
					component={DeliveryAcknowledgements}
				/>
				<Route
					exact
					path="/deliveryAcknowledgements/new"
					component={AddDeliveryAcknowledgement}
				/>
				<Route exact path="/reasons" component={Reasons} />
				<Route exact path="/deployments" component={Deployment} />
				<Route exact path="/deliveries" component={DeliveryList} />
				<Route exact path="/mapWindow/:id" component={MapComponentWindow} />
				{/* <Route
					exact
					path="/deliveriesList/:id"
					component={ScheduledDeliveries}
				/> */}
				{/* <Route exact path="/billing" component={BillingView} /> */}
				{/* <Route exact path="/serviceDoc" component={ServiceDocumentationView} /> */}
				<Route exact path="/pendingTicket" component={ServiceDocumentationView} />
				{/* <Route exact path="/verification" component={InsuranceVerificationView} /> */}
				{/* <Route exact path="/inProcess/:id" component={InProcessQueue} /> */}
				{/* <Route exact path="/inProcess/31" component={InProcessQueue} /> */}
				<Route exact path="/internalRequest" component={InternalRequest} />
				<Route exact path="/collections" component={CollectionsView} />
				{/* <Route exact path="/feedback" component={ComplaintsList} /> */}
				<Route exact path="/locations" component={Locations} />
				{/* <Route exact path="/regions" component={Regions} /> */}
				<Route exact path="/atp" component={ATPView} />
				<Route exact path="/techProfiles" component={TechList} />
				<Route exact path="/pcrLinks" component={PCRLink} />
				{/* <Route exact path="/pcrNotifications" component={PcrNotificationsView}/> */}
				{/* <Route exact path="/followup" component={FollowUpView} /> */}
				<Route
					exact
					path="/manualDocumentBuilder"
					component={ManualDocBuilder}
				/>
				<Route exact path="/homeAssessmentCompare" component={HomeAssessmentCompare} />
				<Route exact path="/vendors" component={Vendor} />
				<Route
					exact
					path="/productMatrixEntry"
					component={ProductMatrixEntry}
				/>
				<Route exact path="/patientApprovals" component={PatientApprovals} />

				{/*Report Routes*/}
				<Route exact path="/reports/home" component={Reports} />
				<Route exact path="/reports/salesPipeline" component={SalesPipelineGraphView} />
				<Route exact path="/reports/monthGlance" component={MonthGlanceView} />
				<Route exact path="/dailyOrderCounts" component={DailyOrderCounts} />

				{/* Task Route */}
				<Route exact path="/tasks" component={Tasks} />

				{/*Expenses Routes*/}
				<Route exact path="/expenses" component={Expenses} />
				<Route exact path="/expenses/:id" component={ExpenseEdit} />
				{/*<Route exact path="/addExpense" component={ExpenseAdd} />*/}

				{/*Purchasing Routes*/}
				<Route exact path="/purchaseQueues" component={PurchaseQueueView} />
				<Route exact path="/inventoryRequest" component={InventoryRequest} />
				<Route exact path="/shippingDetails" component={ShippingDetailsReport} />

				{/*Inventory Product Routes*/}
				<Route
					exact
					path="/inventoryProducts"
					component={InventoryProductView}
				/>

				{/*Contacts Routes*/}
				<Route exact path="/contacts" component={Contacts} />
				<Route exact path="/contact/:id" component={ContactEdit} />

				{/*Marketing Routes*/}
				<Route exact path="/campaignList" component={CampaignList} />
				<Route exact path="/campaignCategories" component={CategoryList} />
				<Route exact path="/campaignCharts" component={CampaignCharts} />
				<Route exact path="/patientCopy" component={PatientCopy} />

				{/*President's Club Routes*/}
				<Route exact path="/presidentsClub" component={PresidentsClubView} />

				{/*Liaison Meeting View*/}
				<Route exact path="/liaisonMeetings" component={LiaisonMeetingView} />

				{/*Bonus Points Routes*/}
				<Route exact path="/bonus" component={BonusReport} />

				<Route exact path="/serviceParts" component={ServicePartList} />

				<Route
					render={function () {
						return <h1>Not Found</h1>;
					}}
				/>
			</Switch>
		);
	}
}

export default Routes;
