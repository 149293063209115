import api from "../api";

class OrderService {
    formatDate(date) {
        if (date == null || date === "") {
            return "";
        }
        return new Intl.DateTimeFormat("en-US", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
        }).format(date);
    }

    GetFormattedDate(date) {
        if (date == null || date === "") {
            return "";
        }

        let month = date.getMonth() + 1,
            day = date.getDate(),
            year = date.getFullYear();

        return month + "/" + day + "/" + year;
    }

    async getAllOrders(filters) {
        return new Promise((res, reject) => {
            if (filters) {
                filters.startDate = this.GetFormattedDate(filters.startDate);
                filters.endDate = this.GetFormattedDate(filters.endDate);
            }

            api.get("/findOrdersByStatuses", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getOrdersWithLocations(filters) {
        return new Promise((res, reject) => {
            let params = {
                statuses: filters.statuses || [],
                reasonIds: filters.reasonIds || [],
                equipmentIds: filters.equipmentIds || [],
                internalLocations: filters.internalLocations || [],
                clinicLocations: filters.seatingClinics || [],
                salesOrService: filters.salesOrService || "Sales",
                startDate: this.GetFormattedDate(filters.startDate) || "",
                endDate: this.GetFormattedDate(filters.endDate) || "",
                DOB: this.GetFormattedDate(filters.dob) || "",
                q: filters.searchText || "",
                pageSize: filters.pageSize || 150,
            };
            api.get("/findOrdersWithLocations", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getOrderActivity(id) {
        return new Promise((res, rej) => {
            api.get("/order?id=" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    async getFlatOrderActivity(id) {
        return new Promise((res, rej) => {
            api.get("/orderFlat?id=" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    getOrderProducts(id) {
        return new Promise((res, rej) => {
            api
                .get("/orders/getOrderProducts/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    updateOrderProducts(id, prods) {
        return new Promise((res, rej) => {
            api
                .put("/orders/updateOrderProducts/" + id, prods)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    removeOrderProduct(id) {
        return new Promise((res, rej) => {
            api
                .post("/orders/removeOrderProduct/" + id) // id of the item to be removed
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    createPreAuth(id) {
        return new Promise((res, rej) => {
            api
                .post("/orders/createPreauth/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        })
    }

    getCurrentlyWorkingByOrder(id) {
        return new Promise((res, rej) => {
            api
                .get("/getCurrentlyWorkingOrder/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    async syncToBrightTree(id) {
        return new Promise((res, rej) => {
            api.post("/syncToBrightTree/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    // calls proc spGetTechTimeline
    async getDeliveriesForLocation(locationId) {
        return new Promise((res, reject) => {
            api.get("/orders/deliveries/" + locationId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateDelivery(order) {
        return new Promise((res, reject) => {
            api.put("/orders/deliveriesUpdate", order)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getTechDeliveryTimeline(locationId, startDate, endDate) {
        return new Promise((res, reject) => {
            let params = {
                startDate: startDate,
                endDate: endDate,
            };
            api.get("/getTechTimeline/" + locationId, params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getOrdersWithLocationsNoDeliveryDate(filters) {
        return new Promise((res, reject) => {
            let params = {
                statuses: filters.statuses || "",
                internalLocations: filters.internalLocations || [],
                startDate: this.GetFormattedDate(filters.startDate) || "",
                endDate: this.GetFormattedDate(filters.endDate) || "",
                noScheduledDate: "true",
                q: filters.q,
                pageSize: filters.pageSize || 100,
            };
            api.get("/findOrdersWithLocationsNoScheduledDate", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getOrdersWithLocationsDeliveryDate(filters) {
        return new Promise((res, reject) => {
            let params = {
                statuses: filters.statuses || "",
                internalLocations: filters.internalLocations || [],
                startDate: filters.deliveryDateStart || "",
                endDate: filters.deliveryDateEnd || "",
                noScheduledDate: "true",
                users: filters.users || [],
                pageSize: filters.pageSize || 150,
            };
            api.get("/findDeliveries", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async searchOrders(filters) {
        return new Promise((res, reject) => {
            if (filters) {
                filters.startDate = this.GetFormattedDate(filters.startDate);
                filters.endDate = this.GetFormattedDate(filters.endDate);
            }

            api.get("/searchOrdersByStatuses", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spGetDashboardCounts
    async getDashboardCounts() {
        return new Promise((res, reject) => {
            api.get("/dashboard", null)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spGetOrderListing
    async getAllOrdersNative(filters) {
        return new Promise((res, reject) => {
            api.get("/orderListing", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spGetOrderById
    async getOrdersByIdNative(filters) {
        return new Promise((res, reject) => {
            api.get("/orderByIdNative", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spInsuranceVerificationView
    async getInsuranceVerificationViewNative(id) {
        return new Promise((res, reject) => {
            api.get("/verificationView?id=" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc inProcessQueue
    async getInProcessView(regionId, reasonId) {
        return new Promise((res, reject) => {
            api.get("/inProcessView/" + reasonId + (regionId ? "?regionId=" + regionId : ""))
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spBillingView
    async getBillingViewNative(filters) {
        return new Promise((res, reject) => {
            api.get("/billingView", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }


    async getServiceDocFollowUpNative(id) {
        return new Promise((res, reject) => {
            api
                .get("/orders/sdfuOrders/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spInternalRequest
    async getInternalRequestViewNative(filters) {
        return new Promise((res, reject) => {
            api.get("/internalRequest", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spGetFollowupView
    async getFollowUpViewNative(id) {
        return new Promise((res, reject) => {
            api.get("/followupView?id=" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spGetATPView
    async getATPViewNative(filters) {
        return new Promise((res, reject) => {
            api.get("/atpView", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spCollectionsView
    async getCollectionsViewNative(filters) {
        return new Promise((res, reject) => {
            api.get("/collectionsView", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getActivity(id) {
        return new Promise((res, reject) => {
            api.get("/activities/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getApprovalsByOrder(orderId) {
        return new Promise((res, reject) => {
            api.get("/approvalByOrder/" + orderId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    async updateApprovalByOrder(obj) {
        return new Promise((res, reject) => {
            api.put("/approval", obj)
                .then((data) => {
                    res(data)
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    async createOrder(order) {
        return new Promise((res, reject) => {
            api.post("/activities", order)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    //could be {data: order}
    async updateOrder(order) {
        return new Promise((res, reject) => {
            return api
                .put("/orders/update", order)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateFlatOrder(order) {
        return new Promise((res, reject) => {
            return api
                .put("/orders/updateFlat", order)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateOrderProperties(id, orderData) {
        return new Promise((res, reject) => {
            return api
                .post("/orders/updateOrderProperties/" + id, orderData)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async clearBeingWorkedBy(orderId) {
        return new Promise((res, rej) => {
            return api
                .post("/orders/clearBeingWorkedBy/" + orderId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    async setBeingWorkedBy(orderId) {
        return new Promise((res, rej) => {
            return api.post("/orders/setBeingWorkedBy/" + orderId)
                .then(data => {
                    res(data);
                })
                .catch(err => {
                    rej(err);
                });
        });
    }

    async uploadDocument(order, file, fileName, userId) {
        var sterilFilename = fileName.replaceAll(/[^.a-z0-9_-]/gi, '_').toLowerCase();
        return new Promise((res, reject) => {
            return api
                .downloadPost("/activities/" + order.id + "/files/" + sterilFilename + "/" + userId, file)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async uploadDocumentWithStamp(order, file, fileName, userId) {
        var sterilFilename = fileName.replaceAll(/[^.a-z0-9_-]/gi, '_').toLowerCase();
        return new Promise((res, reject) => {
            return api
                .downloadPost(
                    "/activities/" + order.id + "/fileswithstamp/" + sterilFilename + "/" + userId,
                    file
                )
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getDocumentThumbnails(docId) {
        return new Promise((res, reject) => {
            return api
                .get("/deliveryPaperwork/image/" + docId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async downloadDocument(orderId, fileName, userId, file) {
        return new Promise((res, reject) => {
            return api.downloadPost("/api/activities/" + orderId + "/files/" + fileName + "/" + userId, file)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async downloadDoc(id) {
        return new Promise((res, reject) => {
            return api
                .getFile("/files/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async deleteDocument(id) {
        return new Promise((res, reject) => {
            return api
                .delete("/files/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getOrderTimeline(order) {
        return new Promise((res, reject) => {
            return api
                .get("/orders/orderHistory/" + order.id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getOrdersByAccount(id) {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 25,
            },
                url = "/accounts/" + id + "/orders";

            return api
                .get(url, params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getOrderAttachments(id) {
        return new Promise((res, reject) => {
            return api
                .get("/activity/files/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getPatients() {
        return new Promise((res, reject) => {
            return api
                .get("/patients")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getPatientById(patientId) {
        return new Promise((res, reject) => {
            return api
                .get("/patient?id=" + patientId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getPatientEquipment(patientId) {
        return new Promise((res, reject) => {
            return api
                .get("/patientEquipmentTickets/" + patientId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }

    async getPatientByIdFromOtherCompany(patientId, params, credentials) {
        return new Promise((res, reject) => {
            return api
                .getFromOtherCompany("/patients/" + patientId, params, credentials)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getPatientOrdersById(patientId) {
        return new Promise((res, reject) => {
            return api
                .get("/patientOrders/" + patientId)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spGetPatientList
    async getPatientsByInformation(fName, lName, dob, phone) {
        let params = {
            firstname: fName || "",
            lastname: lName || "",
            birthDate: dob || "",
            phone: phone || "",
        };
        return new Promise((res, reject) => {
            return api
                .get("/patients", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updatePatient(data, updateOrders) {
        let params = ""
        if (updateOrders) {
            params = "?updateOrders=true"
        }
        return new Promise((res, reject) => {
            return api
                .put("/patient" + params, data)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createPatient(data) {
        return new Promise((res, reject) => {
            return api
                .post("/patient", data)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spMergePatients
    async mergePatients(params) {
        return new Promise((res, reject) => {
            return api
                .get("/mergePatients", params)
                .then(() => {
                    res();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async mergeDocuments(order, fileIds) {
        return new Promise((res, reject) => {
            return api
                .post("/activities/" + order.id + "/merge", fileIds)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async mergePagedDocuments(order, files, name, userId) {
        return new Promise((res, reject) => {
            return api
                .post("/activities/" + order.id + "/mergepages/" + name + "/" + userId, files)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // calls proc spGetDeliveryAverages
    async getDashboardDeliveryAverages(props) {
        let params = null;

        if (props != null) {
            params = {id: props};
        }

        return new Promise((res, reject) => {
            api.get("/deliveryAverages", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getUserQuotaMetrics(id) {
        let params = {
            id: id,
        };

        return new Promise((res, reject) => {
            api.get("/dashboard/quotaMetrics", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getAllOrderFrequency() {
        return new Promise((res, reject) => {
            api.get("/recurringOrders")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getOrderFrequency(id) {
        return new Promise((res, reject) => {
            api.get("/orders/recurringOrders/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async createOrderFrequency(data) {
        return new Promise((res, reject) => {
            return api
                .post("/orders/recurringOrders", data)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateOrderFrequency(data) {
        return new Promise((res, reject) => {
            return api
                .put("/orders/recurringOrders", data)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getOrderListing(filters) {
        return new Promise((res, reject) => {
            return api
                .get("/orderListing", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async syncOrderWithBT(id) {
        if (window.salesPilot.meta.environment === "development") {
            return new Promise((res, reject) => {
                setTimeout(() => {
                    // Mock condition: If id is odd, resolve; if even, reject
                    if (id % 2 === 1) {
                        res({ success: true, message: `Order ${id} synced successfully.` });
                    } else {
                        reject({ success: false, message: `Order ${id} failed to sync.` });
                    }
                }, 3000);
            });
        } else {
            return new Promise((res, reject) => {
                return api
                    .post("/orders/syncToBt/" + id)
                    .then((data) => {
                        res(data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        }
    }

    async getServiceOrderReason(id) {
        return new Promise((res, rej) => {
            return api
                .get("/orders/serviceOrderReasonByCurrent/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    async buildPaSummary(items) {
        return new Promise((res, reject) => {
            return api
                .post("/buildPaSummary", items)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getPatientLastChartNotes(id) {
        return new Promise((res, rej) => {
            return api
                .get("/patientChartNoteMeta/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    async getPipelineView(id) {
        return new Promise((res, rej) => {
            return api
                .get("/pipelineView" + (id ? "?regionId=" + id : ""))
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    async getPaymentsCollected(filters) {
        return new Promise((res, reject) => {
            api
                .get("/getPaymentsCollected", filters)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

}

const orderService = new OrderService();

export default orderService;
