import Crud from "../Crud";
import MUIYellowColor from "@mui/material/colors/yellow";

/**
 * Static helper functions related to TeamQueues.
 */
export default class TeamQueue extends Crud {
    /**
     * @param {object} teamQueue The teamQueue object.
     * @returns The display name of the teamQueue.
     */
    static getDisplayName(teamQueue) {
        return teamQueue.name;
    }

    /**
     * Determines if a teamQueue matches a search query.
     *
     * @param {object} teamQueue The teamQueue to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the teamQueue matches the query.
     */
    static match(teamQueue, query) {
        return (
            teamQueue.id === +query
        );
    }

    /**
     * @param {array} teamQueues The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(teamQueues) {
        return teamQueues.sort((a, b) => {
            return (a.id).localeCompare(b.id);
        });
    }

    /**
     * @returns {object} The color of this object.
     */
    static getBaseColor() {
        return MUIYellowColor;
    }

    /**
     * @returns {number} The base color shade of this object.
     */
    static getBaseColorShade() {
        return 800;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "CallSplit";
    }
}