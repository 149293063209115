import React from "react";
import Dialog from "../Dialog";
import MUITypography from "@mui/material/Typography";
import MUIBox from '@mui/material/Box';
import Table from "../Table";
import MUIButton from "@mui/material/Button"
import MUIDialogActions from "@mui/material/DialogActions"

export default class DialogTableFilter extends Dialog {
    constructor(props) {
        super(props);

        this.state.selectedRows = this.props.selectedRows || {};
    }

    /**
     * Closing this dialog results in a rerender and clearing of selectedRows.
     * This dialog never sees that prop change outside of that, so a simple "not
     * set" to "set" check is all that's necessary here.
     *
     * @param {object} prevProps
     */
    componentDidUpdate(prevProps) {
        if (!prevProps.selectedRows && this.props.selectedRows) {
            this.setState({
                selectedRows: this.props.selectedRows
            });
        }
    }

    /**
     * Clear state. Called when the dialog closes.
     */
    clearState() {
        super.clearState();

        this.setState({
            selectedRows: {}
        });
    }

    /**
     * Render the search box.
     */
    renderHeader() {
        return (
            <MUITypography variant="h6" color="textPrimary">
                {this.props.column ? this.props.column.name : ""}
            </MUITypography>
        );
    }

    /**
     * Render search result tiles in a grid.
     */
    renderContent() {
        if (!this.props.rows || this.props.rows.length === 0) {
            return null;
        }

        const handleClick = (row) => {
            const selectedRows = { ...this.state.selectedRows };
            if (selectedRows[row.id] === true) {
                delete selectedRows[row.id];
            } else {
                selectedRows[row.id] = true;
            }

            this.setState({
                selectedRows: selectedRows
            });
        }

        const height = 400;

        return (
            <MUIBox height={height}>
                <Table
                    search={true}
                    showHeader={false}
                    checkbox={true}
                    onClick={handleClick}
                    columns={[
                        { key: this.props.column.key, name: this.props.column.name, type: this.props.column.type },
                    ]}
                    sort={[
                        {
                            key: this.props.column.key,
                            direction: "ascending",
                            type: this.props.column.type,
                        }
                    ]}
                    rows={this.props.rows}
                    selectedRows={this.state.selectedRows}
                />
            </MUIBox>
        );
    }

    /**
     * @returns {boolean} Whether or not to show dividers on content area.
     */
    getDividers() {
        return true;
    }

    /**
     * Render action buttons.
     */
    renderActions() {
        const handleClear = () => {
            this.setState({
                selectedRows: {}
            });
            this.props.onChange({});
            this.close();
        }
        const handleApply = () => {
            this.props.onChange(this.state.selectedRows);
            this.close();
        }

        // TODO move the MUIDialogActions to the parent class
        return (
            <MUIDialogActions spacing={2}>
                <MUIButton variant="text" onClick={() => { this.close(); }}>
                    Cancel
                </MUIButton>
                {
                    Object.keys(this.state.selectedRows).length > 0 &&
                    (
                        <MUIButton variant="text" onClick={handleClear} color="error">
                            Clear Filter
                        </MUIButton>
                    )
                }
                <MUIButton variant="contained" onClick={handleApply}>
                    Apply Filter
                </MUIButton>
            </MUIDialogActions>
        );
    }
}