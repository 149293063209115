import React from "react";
import Chip from "../Chip";
import MUIGreyColor from "@mui/material/colors/grey";
import MUIAvatar from "@mui/material/Avatar";
import User from "../../Utility/Crud/User";

export default class ChipUser extends Chip {
    /**
     * Renders a MUI Avatar.
     */
    renderAvatar() {
        const color = (this.props.user ? User.getColor() : MUIGreyColor[500]);
        const avatarColor = this.props.isTransparent ? "transparent" : color;
        const iconColor = this.props.isTransparent ? color : "#fff";

        return (
            <MUIAvatar sx={{ bgcolor: avatarColor }}>
                {User.getIcon({ fontSize: "small", sx: { color: iconColor } })}
            </MUIAvatar>
        )
    }

    /**
     * @return {string} The user's first and last name.
     */
    getLabel() {
        return this.props.user ? User.getDisplayName(this.props.user) : "None";
    }
}
