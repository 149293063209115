import React from "react"
import Dialog from "../Dialog"
import MUILoadingButton from "@mui/lab/LoadingButton"
import MUIButton from "@mui/material/Button"
import MUIDialogActions from "@mui/material/DialogActions"
import MUITypography from "@mui/material/Typography";

export default class DialogConfirm extends Dialog {
    /**
     * Render header title of dialog
     */
    renderHeader() {
        return (
            <MUITypography variant="h6" color="textPrimary">
                {this.props.header}
            </MUITypography>
        );
    }

    /**
     * Render main content text of dialog
     */
    renderContent() {
        return (
            <MUITypography variant="body1" color="textPrimary">
                {this.props.text}
            </MUITypography>
        );
    }

    /**
     * Render cancel and confirm button actions of dialog.
     */
    renderActions() {
        return (
            <MUIDialogActions spacing={2}>
                <MUIButton variant="text" onClick={this.props.onClose}>
                    Cancel
                </MUIButton>
                <MUILoadingButton variant="contained" loading={this.props.loading} onClick={this.props.onConfirm}>
                    Confirm
                </MUILoadingButton>
            </MUIDialogActions>
        );
    }

    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "sm";
    }
}
