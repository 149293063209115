import React from "react";
import DialogSearch from "../DialogSearch"
import TileProductSubType from "../../Tile/TileProductSubType";
import PropTypes from "prop-types";
import ProductSubType from "../../../Utility/Crud/ProductSubType";

export default class DialogSearchProductSubType extends DialogSearch {

    static contextTypes = {
        productSubTypesIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered productSubType.
     */
    getSearchResults(query) {
        return ProductSubType.search(Object.values(this.context.productSubTypesIndexed), query);
    }

    /**
     * @param {object} productSubType The productSubType object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileProductSubType component for this productSubType.
     */
    renderTile(productSubType, onClick) {
        const handleClick = () => {
            onClick(productSubType);
            this.close();
        }

        return (
            <TileProductSubType
                key={productSubType.id}
                productSubType={productSubType}
                onClick={handleClick}
            />
        );
    }
}
