import React from "react";
import Card from "../../../Card";
import Table from "../../../Table";
import { withRouter } from 'react-router-dom';

export default withRouter(class CardAdminBtProductList extends Card {
    /**
     * Renders the content of the card.
     */
    renderContent() {
        return (
            <Table
                search={true}
                columns={[
                    { key: "id", name: "ID", type: "number" },
                    { key: "hcpcs", name: "HCPCS", type: "string", filter: true },
                    { key: "itemId", name: "Item ID", type: "string" },
                    { key: "name", name: "name", type: "string" },
                ]}
                sort={[
                    {
                        key: "hcpcs",
                        direction: "ascending",
                    },
                    {
                        key: "itemId",
                        direction: "ascending",
                    },
                    {
                        key: "name",
                        direction: "ascending",
                    },
                ]}
                rows={Object.values(this.props.btProducts)}
                onClick={(btProduct) => {
                    this.props.history.push({
                        pathname: "/admin/btProduct/" + btProduct.id
                    });
                }}
            />
        );
    }
});