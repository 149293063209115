import React from "react";
import "./jkTooltip.css";

export default class UpdateNotes extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <strong>Version 5.1.3</strong>
                    <ul>
                        <li>Fixed Brightree product lookup sometimes failing behind the scenes</li>
                        <li>Fixed managers not able to see their department if they are a member of another team</li>
                        <li>Added ordinals to queue for dashboard sorting</li>
                        <li>Added member/manager icons to queue dashboard</li>
                        <li>Updated region admin page to new look & feel</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 5.1.2</strong>
                    <ul>
                        <li>Restricted dashboard access by department</li>
                        <li>Added history chart to queues for managers and above</li>
                        <li>Fixed orders not opening in new window automatically on deliveries page</li>
                        <li>Fixed queue groups sometimes jumping around on hover</li>
                        <li>Added "Assigned To" column to Complaints Report</li>
                        <li>Fixed deleted files showing up on quotes dialog</li>
                        <li>Added followup note date to add note dialog</li>
                        <li>Added Brightree Product admin page</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 5.1.1</strong>
                    <ul>
                        <li>Added Find by Policy # to Patient Search screen</li>
                        <li>Fixed service tickets not opening in new window from deliveries page</li>
                        <li>Fixed saving patient info sometimes displays MBI error</li>
                        <li>Added technician to sales report</li>
                        <li>Updated team users on queue to sort alphabetically</li>
                        <li>Added scheduled delivery date to patient info screen</li>
                        <li>Updated add note box to be expandable; made larger overall</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 5.1.0</strong>
                    <ul>
                        <li>Fixed service tickets not opening in a new window</li>
                        <li>Replaced dashboard with new queue dashboard</li>
                        <li>Replaced corporate queues with new dynamic queue</li>
                        <li>Moved Sales Pipeline and Month at a Glance to reports dropdown</li>
                        <li>Moved tasks to a separate page</li>
                        <li>Moved followup note to main "Add Note" dialog on ticket view</li>
                        <li>Updated look and feel of main "Add Note" dialog on ticket view</li>
                        <li>Removed individual queue pages from navigation in favor of new dashboard</li>
                        <li>Created new Insurance Group for Followup</li>
                        <li>Added auto tracking of queue statistics</li>
                        <li>Added dark mode for certain pages</li>
                        <li>Removed auto-popup of Quote dialog from ticket view</li>
                        <li>Fixed attachments header not staying fixed when scrolling on patient information page</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 5.0.6</strong>
                    <ul>
                        <li>Fixed routing issue with pickup tickets (redux)</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 5.0.5</strong>
                    <ul>
                        <li>Fixed routing issue with pickup tickets</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 5.0.4</strong>
                    <ul>
                        <li>Added Technician to Orders page</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 5.0.3</strong>
                    <ul>
                        <li>Fixed Sales Liaison scheduling allowing overlaps</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 5.0.2</strong>
                    <ul>
                        <li>Updated access for Sales Liaisons / Business Support</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 5.0.1</strong>
                    <ul>
                        <li>Fixed accounts not loading after logging in</li>
                        <li>Fixed deliveries map duplicating when reloading page</li>
                        <li>Updated billing page to save state after leaving it</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 5.0.0</strong>
                    <ul>
                        <li>Replaced static routing engine with a dynamic one</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 4.10.10</strong>
                    <ul>
                        <li>Added update notification</li>
                        <li>Updated orders/tickets to require a reason</li>
                        <li>Changed Sales Liaison meetings to 30 minute increments</li>
                        <li>Fixed issue completing Sales Liaison meetings</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 4.10.9</strong>
                    <ul>
                        <li>Improved performance of deliveries map for Rehab Express</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 4.10.8</strong>
                    <ul>
                        <li>Added version tracking with sessions</li>
                        <li>Various minor bug fixes</li>
                        <li>Automated some steps of user inactivation</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 4.10.7</strong>
                    <ul>
                        <li>Fixed Customer Feedback page not loading</li>
                        <li>Removed legacy redirect for old pickup tickets</li>
                        <li>Improved performance of deliveries map</li>
                        <li>Updated some styling on the patient info page</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 4.10.6</strong>
                    <ul>
                        <li>Fixed minor issue when creating notes</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 4.10.5</strong>
                    <ul>
                        <li>Improved login page on small screens</li>
                        <li>Added better error messages to login page</li>
                        <li>Added instant feedback to Brighttree sync order button</li>
                        <li>Fixed sales report sometimes not loading when selecting all locations</li>
                        <li>Fixed notes sometimes showing up in the wrong order</li>
                        <li>Fixed serial number sometimes not showing up on ticket page</li>
                        <li>Fixed reverse quality log report sometimes not loading when selecting all locations</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 4.10.4</strong>
                    <ul>
                        <li>Added NPI field to location dictionary</li>
                        <li>Restored serial number field on products list</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 4.10.3</strong>
                    <ul>
                        <li>Fixed bug when opening certain patients</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 4.10.2</strong>
                    <ul>
                        <li>Improved initial loading performance after version update</li>
                        <li>Fixed inactive users showing up in sales rep dropdown</li>
                        <li>Fixed bug affecting setting of last note date</li>
                        <li>Fixed merging documents not working for pickup tickets</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 4.10.1</strong>
                    <ul>
                        <li>Fixed insurance verification button styles</li>
                        <li>Alphabetized sales rep dropdown on orders page</li>
                    </ul>
                </div>
                <div>
                    <strong>Version 4.10.0</strong>
                    <ul>
                        <li>Updated serial number capture to include base unit</li>
                        <li>Improved speed of Service Parts Catalog</li>
                    </ul>
                </div>
            </div>
        );
    }
}
