import React from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Spinner,
    CardHeader,
    MDBIcon,
    toast,
    MDBToastContainer,
    MDBModal,
    MDBModalBody,
    MDBRow,
    MDBCol,
    MDBModalHeader,
    MDBInput,
    MDBModalFooter,
    MDBBtn,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import userRoleService from "../Security/UserRoleService/userRoleService";

export default class UserRoleQuotas extends React.Component {
    constructor(props) {
        super(props);

        const columns = ["Id", "Name", "Quota", "Task Zero"];
        const data = [];

        this.state = {
            data: data,
            alteredData: [],
            columns: columns,
            editedRows: [],
            differenceModalOpen: false,
            isLoaded: false,
            name: "",
            currIndex: -1,
            errorMessage: "",
            currAccount: {},
        };
    }

    // Complaining that component wasn't mounted so I couldn't set States therefore had to use this
    componentDidMount() {
        this.retrieveRolesQuotas();
    }

    retrieveRolesQuotas() {
        userRoleService.getAllUserRoleQuotas().then((quotas) => {
            console.log(quotas)
            this.setState({
                data: quotas,
                alteredData: [quotas],
                differenceModalOpen: false,
                isLoaded: true,
            })
        }).catch(err => {
            console.log(err);
        })
    }

    handleCheckboxChange(obj, e) {
        let ad = this.state.alteredData;

        let idx = ad.findIndex((v) => {
            return v.id === obj.id;
        });

        if (idx > -1) {
            ad[idx].taskZero = e.target.checked;
            ad[idx].edited = true;
        } else {
            obj.taskZero = e.target.checked;
            obj.edited = true;
            ad.push(obj);
        }

        this.setState({
            alteredData: ad,
        });
    }

    alterData(obj, e) {
        let ad = this.state.alteredData;

        let idx = ad.findIndex((v) => {
            return v.id === obj.id;
        });

        if (idx > -1) {
            ad[idx].dailyQuota = e.target.valueAsNumber;
            ad[idx].edited = true;
        } else {
            obj.dailyQuota = e.target.valueAsNumber;
            obj.edited = true;
            ad.push(obj);
        }

        this.setState({
            alteredData: ad,
        });
    }

    compareData() {
        let ad = this.state.alteredData;

        let difference = ad.filter((x) => x.edited === true);

        this.setState({
            differenceModalOpen: true,
            editedRows: difference,
        });

        return;
    }

    async updateRoleQuotas() {
        let ad = this.state.editedRows,
            errors = [];

        if(ad && ad.length > 0){
            this.setState({
                isLoaded: false,
            });

            await Promise.all(
                ad.map(async (r) => {
                    delete (r.edited);
                    
                    userRoleService.updateUserRoleQuota(r)
                        .then((res) => {

                            if (errors.length === 0) {
                                toast.success("Updated Successfully");
                            } else {
                                toast.danger("There was an error while saving");
                            }
                
                            this.retrieveRolesQuotas();
                        })
                        .catch((e) => {
                            errors.push(r);
                        });
                })
            );
        }
    }

    renderQuotasRows() {
        let data = this.state.alteredData || [];
        if (this.state.isLoaded === true) {
            return data.map((t, idx) => {
                let border = "";
                if (idx % 2 === 0) {
                    border = "2px solid #CCC";
                }

                return (
                    <MDBRow style={{border: border, padding: 2}} key={idx}>
                        <MDBCol>
                            <h5 style={{lineHeight: 2}}>{t.name}</h5>
                        </MDBCol>
                        <MDBCol>
                            <MDBInput
                                label="Has Task Zero"
                                containerClass="smallMargin"
                                value={t.taskZero || false}
                                checked={t.taskZero || false}
                                data-tip="Priority"
                                type="checkbox"
                                onChange={this.handleCheckboxChange.bind(this, t)}
                                filled
                                id={t.name}
                            />
                        </MDBCol>
                        <MDBCol>
                            <MDBInput
                                style={{backgroundColor: "#FFF"}}
                                type={"number"}
                                containerClass={"containerMargin"}
                                label={"Quota Number"}
                                outline
                                onChange={(e) => this.alterData(t, e)}
                                valueDefault={t.dailyQuota}
                            />
                        </MDBCol>


                    </MDBRow>
                );
            });
        }
    }

    renderDifferenceModal() {
        let difference = this.state.editedRows;

        return (
            <MDBModal
                centered
                isOpen={this.state.differenceModalOpen}
                toggle={() =>
                    this.setState({
                        differenceModalOpen: false,
                    })
                }
            >
                <MDBModalHeader>Review Changes</MDBModalHeader>
                <MDBModalBody>
                    <MDBRow style={{fontWeight: "bold"}}>
                        <MDBCol>Name</MDBCol>
                        <MDBCol>Task Zero</MDBCol>
                        <MDBCol>Daily Quota</MDBCol>
                    </MDBRow>

                    {difference.map((d, idx) => {
                        return (
                            <MDBRow key={idx}>
                                <MDBCol>{d.name}</MDBCol>
                                <MDBCol>{d.taskZero.toString()}</MDBCol>
                                <MDBCol>{d.dailyQuota}</MDBCol>
                            </MDBRow>
                        );
                    })}
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn data-text={"Confirm"} onClick={() => this.updateRoleQuotas()}>
                        <MDBIcon icon={"check"} color={"success"} />
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        );
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <Container className="mt-5">
                {this.renderDifferenceModal()}
                <MDBToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Card>
                    <CardHeader
						style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30 }}>
                        User Roles and Quotas
                        <Button
                            style={{float: "right"}}
                            floating
                            size="sm"
                            color={"secondary"}
                            data-tip={"Review Changes"}
                            onClick={() => this.compareData()}
                        >
                            <MDBIcon icon="address-card" style={{fontSize: "2em"}}/>
                        </Button>
                    </CardHeader>
                    <CardBody>
                        <MDBRow style={{fontWeight: "bold"}}>
                            <MDBCol>Role</MDBCol>
                            <MDBCol>Task Zero</MDBCol>
                            <MDBCol>Quota</MDBCol>

                        </MDBRow>
                        {this.renderQuotasRows()}
                    </CardBody>
                </Card>
                <ReactTooltip/>
            </Container>
        );
    }
}
