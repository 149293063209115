import Crud from "../Crud";
import MUICyanColor from "@mui/material/colors/cyan";

/**
 * Static helper functions related to orderActivityTypes.
 */
export default class OrderActivityType extends Crud {
    /**
     * @param {object} orderActivityType The orderActivityType object.
     * @returns The display name of the orderActivityType.
     */
    static getDisplayName(orderActivityType) {
        return orderActivityType.name;
    }

    /**
     * Determines if an orderActivityType matches a search query.
     *
     * @param {object} orderActivityType The orderActivityType to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the orderActivityType matches the query.
     */
    static match(orderActivityType, query) {
        return (
            (orderActivityType.name.toLowerCase().includes(query)) ||
            orderActivityType.id === +query
        );
    }

    /**
     * @param {array} orderActivityTypes The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(orderActivityTypes) {
        return orderActivityTypes.sort((a, b) => {
            return a.id - b.id;
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUICyanColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Workspaces";
    }
}