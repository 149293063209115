import React from "react";
import Menu from "./Menu";
import Setting from "../Utility/Setting";
import moment from "moment";

// MUI
import MUIGrid from "@mui/material/Grid";
import MUIBox from "@mui/material/Box";
import MUITextField from "@mui/material/TextField";
import MUIArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import MUIChildCareIcon from '@mui/icons-material/ChildCare';
import MUIInputAdornment from "@mui/material/InputAdornment";
import MUISearchIcon from "@mui/icons-material/Search";
import MUIRedColor from "@mui/material/colors/red";
import MUIBlueColor from "@mui/material/colors/blue";
import MUIGreyColor from "@mui/material/colors/grey";
import MUITooltip from "@mui/material/Tooltip";
import MUIStyled from '@mui/material/styles/styled';

// DevExpress
import {
    Grid as DevExpressGrid,
    Table as DevExpressTable,
    TableHeaderRow as DevExpressTableHeaderRow,
    PagingPanel as DevExpressPagingPanel,
    TableGroupRow as DevExpressTableGroupRow,
    GroupingPanel as DevExpressGroupingPanel,
    DragDropProvider as DevExpressDragDropProvider,
    Toolbar as DevExpressToolbar,
    TableColumnResizing as DevExpressTableColumnResizing,
    TableSummaryRow as DevExpressTableSummaryRow,
} from "@devexpress/dx-react-grid-material-ui";
import {
    SortingState as DevExpressSortingState,
    IntegratedSorting as DevExpressIntegratedSorting,
    PagingState as DevExpressPagingState,
    IntegratedPaging as DevExpressIntegratedPaging,
    GroupingState as DevExpressGroupingState,
    IntegratedGrouping as DevExpressIntegratedGrouping,
    SummaryState as DevExpressSummaryState,
    IntegratedSummary as DevExpressIntegratedSummary,
} from "@devexpress/dx-react-grid";
export default class DataGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filteredRows: props.rows,
            query: "",
            filters: {},
        };
    }

    /**
     * Set the search results on mount. This ensures that the list is sorted
     * appropriately on first render.
     */
    componentDidMount() {
        this.setState({
            filteredRows: this.getSearchedAndFilteredRows(this.state.query, this.state.filters),
        });
    }

    componentDidUpdate(prevProps) {
        /**
         * BIG TODO: This is probably not performant, but it will update the
         * state if the row count changes (fast) or if the content of the rows
         * changes (slow).
         */
        if (
            prevProps.rows.length !== this.props.rows.length
            || JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows)
        ) {
            this.setState({
                filteredRows: this.getSearchedAndFilteredRows(this.state.query, this.state.filters)
            });
        }
    }

    /**
    * Searches rows in the table. Currently this only supports string and number
    * columns. Other column types can be added as necessary.
    *
    * @param {string} query The search query.
    * @param {object} filters The search filters.
    * @returns A list of rows that match the search.
    */
    getSearchedAndFilteredRows(query, filters) {
        // Filter
        let filteredRows = [];
        if (Object.keys(filters).length === 0) {
            filteredRows = this.props.rows;
        } else {
            this.props.rows.forEach((row) => {
                let filterMatches = true;
                Object.keys(filters).forEach((key) => {
                    if (Object.keys(filters[key]).includes(row[key] ? row[key].toString() : "null") === false) {
                        filterMatches = false;
                    }
                });
                if (filterMatches === true) {
                    filteredRows.push(row);
                }
            });
        }

        // Search
        let searchedAndFilteredRows = [];
        const queryCleaned = query
            .toLowerCase()
            .trim()
            .replace(/\s+/g, ' ');
        if (queryCleaned === '') {
            searchedAndFilteredRows = filteredRows;
        } else {
            filteredRows.forEach((row) => {
                if (Object.values(row).some(value =>
                    String(value).toLowerCase().includes(queryCleaned)
                )) {
                    searchedAndFilteredRows.push(row);
                }
            });
        }

        return searchedAndFilteredRows;
    }

    renderSearchAndMenuBar() {
        const handleChangeSearch = (e) => {
            this.setState({
                query: e.target.value,
                filteredRows: this.getSearchedAndFilteredRows(e.target.value, this.state.filters)
            });
        };

        return (
            <MUIGrid container spacing={1}>
                <MUIGrid item xs>
                    <MUITextField
                        autoFocus={true}
                        placeholder="Type to search..."
                        hiddenLabel={true}
                        fullWidth={true}
                        variant="filled"
                        size="small"
                        sx={{
                            marginBottom: 2,
                            "& .MuiInputLabel-root": { display: "none", height: 0 }
                        }}
                        InputProps={{
                            startAdornment: (
                                <MUIInputAdornment position="start">
                                    <MUISearchIcon />
                                </MUIInputAdornment>
                            ),
                            disableUnderline: true,
                            style: { borderRadius: 4 }
                        }}
                        onChange={handleChangeSearch}
                    />
                </MUIGrid>
                <MUIGrid item xs="auto">
                    <Menu menuItems={this.props.actions} />
                </MUIGrid>
            </MUIGrid>

        )
    }

    render() {
        /**
         * FYI moving these outside the render function seems to be the
         * preferred way to do this, but doing so created the weird bug where
         * clearing/changing the groupings would disappear the data grid and
         * scroll it over and it would eventually reappear.
         */

        const DevExpressGridRootStyled = MUIStyled(DevExpressGrid.Root)(() => (
            Setting.get("ui.theme") === "dark" ? {
                height: "100%",
                '.TableContainer-root': {
                    /* Handles dark mode scrollbar for Chrome, Edge, Safari (Firefox does this automatically) */
                    '::-webkit-scrollbar': {
                        backgroundColor: MUIGreyColor[900],
                        width: "16px"
                    },
                    "::-webkit-scrollbar-track": {
                        backgroundColor: MUIGreyColor[900],
                    },
                    "::-webkit-scrollbar-thumb": {
                        background: MUIGreyColor[500],
                        border: `3px solid ${MUIGreyColor[900]}`
                    },
                    "::-webkit-scrollbar-corner": {
                        display: "none !important"
                    },
                },
                /* Handles dark mode pagination page number buttons for all browsers */
                '.Pager-pager': {
                    '.Pagination-pagination': {
                        '.Pagination-text': {
                            color: MUIGreyColor[100]
                        }
                    }
                }
            } : { height: "100%" }))

        const DevExpressGridRoot = (props) => <DevExpressGridRootStyled {...props} style={{ height: "100%" }} />;

        const DevExpressTableStyled = MUIStyled(DevExpressTable.Table)(() => ({
            'tr': {
                height: "40px",
                cursor: "pointer",
            },
            'td': {
                padding: 0,
                fontSize: "12px",
                whiteSpace: "nowrap !important",
                overflow: "hidden !important",
            },
            '.oddLight': {
                backgroundColor: MUIGreyColor[200],
                "&:hover": {
                    backgroundColor: MUIGreyColor[300]
                }
            },
            '.evenLight': {
                backgroundColor: MUIGreyColor[50],
                "&:hover": {
                    backgroundColor: MUIGreyColor[300]
                }
            },
            '.oddLightOutOfBounds': {
                backgroundColor: MUIRedColor[100],
                "&:hover": {
                    backgroundColor: MUIRedColor[300],
                }
            },
            '.evenLightOutOfBounds': {
                backgroundColor: MUIRedColor[200],
                "&:hover": {
                    backgroundColor: MUIRedColor[300],
                }
            },
            '.oddDark': {
                backgroundColor: MUIGreyColor[900],
                "&:hover": {
                    backgroundColor: MUIGreyColor[700]
                }
            },
            '.evenDark': {
                backgroundColor: MUIGreyColor[800],
                "&:hover": {
                    backgroundColor: MUIGreyColor[700]
                }
            },
            '.oddDarkOutOfBounds': {
                backgroundColor: MUIRedColor[800],
                "&:hover": {
                    backgroundColor: MUIRedColor[500],
                }
            },
            '.evenDarkOutOfBounds': {
                backgroundColor: MUIRedColor[600],
                "&:hover": {
                    backgroundColor: MUIRedColor[500],
                }
            }
        }));

        const TableComponent = props => (
            <DevExpressTableStyled
                {...props}
            />
        );

        let i = 0;

        const TableRowComponent = ({ row, ...restProps }) => {
            let themePart = Setting.get("ui.theme") || "light";
            themePart = themePart.charAt(0).toUpperCase() + themePart.slice(1);

            var className = (i % 2 === 0) ? `even${themePart}` : `odd${themePart}`;
            if (row.out_of_bounds === 'Yes') {
                className += 'OutOfBounds';
            }
            i++;

            return (
                <DevExpressTable.Row
                    {...restProps}
                    className={className}
                    onClick={() => this.props.onClick(row)}
                />
            );
        }

        const TableCellComponent = ({ row, column, ...props }) => {
            // TODO: Can probably clean this up and have the switch only
            // generate the value, then at the end return the table.cell
            // component to avoid the duplication of events etc.
            switch (column.type) {
                case "ageHours":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {`${row[column.name]}h`}
                        </DevExpressTable.Cell>
                    );
                case "ageBusinessHours":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {`${row[column.name]}bh`}
                        </DevExpressTable.Cell>
                    );
                case "ageDays":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {`${Math.floor(row[column.name] / 24)}d`}
                        </DevExpressTable.Cell>
                    );
                case "ageBusinessDays":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {`${Math.floor(row[column.name] / 8)}bd`}
                        </DevExpressTable.Cell>
                    );
                case "currency":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                                row[column.name],
                            )}
                        </DevExpressTable.Cell>
                    );
                case "points":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {row[column.name] === 0 ? "0" : row[column.name].toFixed(1)}
                        </DevExpressTable.Cell>
                    );
                case "date":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {`${row[column.name] ? moment(row[column.name]).format("M/D/YYYY") : ""}`}
                        </DevExpressTable.Cell>
                    );
                case "priority":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {row[column.name] === true ? <MUIArrowCircleUpIcon fontSize="small" sx={{ color: Setting.get("ui.theme") === "dark" && row.out_of_bounds ? MUIRedColor[100] : MUIRedColor[500] }} /> : " "}
                        </DevExpressTable.Cell>
                    );
                case "pediatric":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {row[column.name] === true ? <MUIChildCareIcon fontSize="small" sx={{ color: Setting.get("ui.theme") === "dark" && row.out_of_bounds ? MUIBlueColor[200] : MUIBlueColor[500] }} /> : " "}
                        </DevExpressTable.Cell>
                    );
                case "stringTooltip":
                    return (
                        <DevExpressTable.Cell {...props}>
                            <MUITooltip title={row[column.name]} placement="bottom-start">
                                <span>{row[column.name]}</span>
                            </MUITooltip>
                        </DevExpressTable.Cell>
                    );
                case "productSubTypes":
                    return (
                        <DevExpressTable.Cell {...props}>
                            <MUITooltip title={row[column.name]} placement="bottom-start">
                                <span>{row[column.name]}</span>
                            </MUITooltip>
                        </DevExpressTable.Cell>
                    );
                case "rowActions":
                    const menuItems = this.props.rowActions.map((rowAction, i) =>
                        React.cloneElement(rowAction.component, {
                            key: i,
                            onClick: () => {
                                this.props.onClickRowAction(rowAction.rowAction, row);
                            },
                            disabled: rowAction.isDisabled(row)
                        })
                    );

                    return (
                        <DevExpressTable.Cell {...props} onClick={(e) => { e.stopPropagation(); }}>
                            <Menu anchorOriginHorizontal="left" transformOriginHorizontal="left" menuItems={menuItems} />
                        </DevExpressTable.Cell>
                    );
                default:
                    return (
                        <DevExpressTable.Cell
                            {...props}
                        />);
            }
        };

        const groupSummaryItems = this.props.grouping && this.props.grouping.length > 0 ? [
            { columnName: this.props.grouping[0].columnName, type: 'count', showInGroupFooter: false },
        ] : []

        return (
            <>
                <MUIBox sx={{ height: "100%" }}>
                    <DevExpressGrid
                        rows={this.state.filteredRows}
                        columns={this.props.columns}
                        rootComponent={DevExpressGridRoot}
                    >
                        <DevExpressSortingState
                            sorting={this.props.sorting}
                            onSortingChange={this.props.onSortingChange}
                        />
                        <DevExpressIntegratedSorting />
                        <DevExpressGroupingState
                            grouping={this.props.grouping}
                            onGroupingChange={this.props.onGroupingChange}
                            expandedGroups={this.props.expandedGroups}
                            onExpandedGroupsChange={this.props.onExpandedGroupsChange}
                        />
                        <DevExpressSummaryState
                            groupItems={groupSummaryItems}
                        />
                        <DevExpressIntegratedGrouping />
                        <DevExpressIntegratedSummary />
                        <DevExpressPagingState defaultCurrentPage={0} pageSize={100} />
                        <DevExpressIntegratedPaging />
                        <DevExpressDragDropProvider />
                        <DevExpressTable
                            // Performance note: This TableCellComponent seems to be a large source of performance issues.
                            cellComponent={TableCellComponent}
                            tableComponent={TableComponent}
                            rowComponent={TableRowComponent}
                            height="auto"
                        />
                        <DevExpressTableColumnResizing defaultColumnWidths={this.props.defaultColumnWidths} />
                        <DevExpressTableHeaderRow showSortingControls />
                        <DevExpressPagingPanel />
                        <DevExpressTableGroupRow showColumnsWhenGrouped />
                        <DevExpressTableSummaryRow />
                        <DevExpressToolbar />
                        <DevExpressGroupingPanel showSortingControls showGroupingControls />
                    </DevExpressGrid>
                </MUIBox>
                <MUIBox sx={{ position: "absolute", top: "12px", right: "12px", width: "40%" }}>
                    {this.renderSearchAndMenuBar()}
                </MUIBox>
            </>
        );
    }


}