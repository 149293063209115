import React from "react";
import MUIGrid from "@mui/material/Grid";
import MUITypography from "@mui/material/Typography";
import MUIBox from "@mui/material/Box";
import MUIStack from "@mui/material/Stack";

export default class PageHeader extends React.Component {
    /**
     * Renders the page header.
     */
    render() {
        return (
            <MUIGrid container alignItems="center" sx={{"marginBottom": 2}}>
                <MUIGrid item xs={8}>
                    <MUITypography noWrap variant="h5" color="textPrimary">{this.props.title}</MUITypography>
                    <MUITypography noWrap variant="body2" color="textSecondary">{this.props.subtitle}</MUITypography>
                </MUIGrid>
                <MUIGrid item xs={4}>
                    <MUIBox display="flex" justifyContent="flex-end">
                        {this.renderButtonGrid()}
                    </MUIBox>
                </MUIGrid>
            </MUIGrid>
        );
    }

    /**
     * Renders an optional grid of buttons.
     */
    renderButtonGrid() {
        return (
            <MUIStack direction="row" spacing={1}>
                {this.props.buttons && this.props.buttons.map((button, i) => (
                    React.cloneElement(button, { key: i })
                ))}
            </MUIStack>
        );
    }
}