import React from "react";
import DialogSearch from "../DialogSearch"
import TileVendor from "../../Tile/TileVendor";
import PropTypes from "prop-types";
import Vendor from "../../../Utility/Crud/Vendor";

export default class DialogSearchVendor extends DialogSearch {

    static contextTypes = {
        vendorsIndexed: PropTypes.object,
    };

    /**
     * Searches for vendors by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered vendors.
     */
    getSearchResults(query) {
        return Vendor.search(Object.values(this.context.vendorsIndexed), query, true);
    }

    /**
     * @param {object} vendor The vendor object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileVendor component for this vendor.
     */
    renderTile(vendor, onClick) {
        const handleClick = () => {
            onClick(vendor);
            this.close();
        }

        return (
            <TileVendor
                key={vendor.id}
                vendor={vendor}
                onClick={handleClick}
            />
        );
    }
}
