import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import "./orderList.css";
import {isGroupTableRow} from "@devexpress/dx-grid-core";
import {
    Grid,
    Table,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar,
    TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import {
    GroupingState,
    IntegratedGrouping,
    PagingState,
    IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
    Plugin,
    Template,
    TemplateConnector,
    TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import {
    Button,
    Container,
    Col,
    Row,
    Spinner,
    toast,
    ToastContainer,
    MDBIcon, CardBody, MDBCard, MDBModal, MDBModalBody, MDBCardHeader, MDBRow, MDBCol, MDBCardBody, MDBBtn,
} from "mdbreact";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "./orderList.css";
import ReactTooltip from "react-tooltip";
import DateTimePicker from "material-ui-pickers/DateTimePicker";
import MomentUtils from "@date-io/moment";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import OrderService from "../Security/OrderService/orderService";
import NoteService from "../Security/NoteService/noteService";
import GlobalFunctions from "../Filters/GlobalFunctions";

const ItemCounter = () => (
    <Plugin name="ItemCounter">
        <Template
            name="tableCell"
            predicate={({tableRow}) => isGroupTableRow(tableRow)}
        >
            {(params) => (
                <TemplateConnector>
                    {({getCollapsedRows}) => {
                        const updatedParams = {
                            ...params,
                            tableRow: {
                                ...params.tableRow,
                                row: {
                                    ...params.tableRow.row,
                                    collapsedRows: getCollapsedRows(params.tableRow.row) || [],
                                },
                            },
                        };
                        return <TemplatePlaceholder params={updatedParams}/>;
                    }}
                </TemplateConnector>
            )}
        </Template>
    </Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{height: "100%"}}/>;

function getVal(row) {
    if (row.collapsedRows.length > 0) {
        return "Count: " + row.collapsedRows.length;
    } else {
        return null;
    }
}

const Content = ({row, column}) => (
    <span>
		<span>
			{column.title} : {row.value}
		</span>
		<span style={{fontWeight: "bold"}}>&nbsp; {getVal(row)}</span>
	</span>
);

export default class ATPView extends React.Component {
    constructor(props) {
        super(props);

        const groupColumns = [
            {columnName: "viewType"},
            {columnName: "salesRep"},
            {columnName: "status"},
        ];

        const data = {
            columns: [
                {
                    title: "Id",
                    name: "id",
                },
                {
                    title: "Type",
                    name: "viewType",
                },
                {
                    title: "Sales Rep",
                    name: "salesRep",
                },
                {
                    title: "Status",
                    name: "status",
                },
                {
                    title: "Reason",
                    name: "reason",
                },
                {
                    title: "Patient Name",
                    name: "patientName",
                },
                {
                    title: "Payor Source",
                    name: "payorSource",
                },
                {
                    title: "Insurance",
                    name: "insurance",
                },
                {
                    title: "ATP Scheduled Date",
                    name: "atpScheduledDate",
                },
                {
                    title: "Scheduled Delivery Date",
                    name: "scheduledDeliveryDate",
                },
                {
                    title: "Notes",
                    name: "lastNoteDate",
                },
            ],
            rows: [],
        };

        let defaultColumnWidths = [
            {columnName: "id", width: 80},
            {columnName: "viewType", width: 120},
            {columnName: "patientName", width: 175},
            {columnName: "salesRep", width: 125},
            {columnName: "status", width: 100},
            {columnName: "reason", width: 125},
            {columnName: "atpScheduledDate", width: 175},
            {columnName: "scheduledDeliveryDate", width: 175},
            {columnName: "lastNoteDate", width: 100},
            {columnName: "payorSource", width: 100},
            {columnName: "insurance", width: 125},
        ];

        this.state = {
            data: data,
            isLoaded: false,
            grouping: [],
            groupingColumns: groupColumns,
            defaultColumnWidths: defaultColumnWidths,
            total: 0,
            atpTotal: 0,
            deliveryTotal: 0,
            fullResults: [],
            currentSchedule: {deliveries: []},
            deliverySchedule: {deliveries: []},
            noteModalOpen: false,
            currentNotes: [],
            currentRow: {},
            currentOrder: {patient: {}},
            scheduleModalOpen: false,
            newNoteText: ""
        };

        this.changeGrouping = (grouping) => {
            let ary = [];

            grouping.forEach((group) => {
                ary.push({columnName: group.columnName});
            });
            this.setState({
                groupingColumns: ary,
            });
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };

    componentDidMount() {
        this.getResults();
    }

    downloadFunction() {
        this.clientCSV(this.state.data, "Orders.csv");
    }

    //This will convert all the data in the grid to a csv file
    clientCSV(stateData, filename) {
        let result,
            ctr,
            keys = [],
            headers = [],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data;

        data = stateData.rows || null;
        if (data == null || !data.length) {
            return null;
        }

        stateData.columns.forEach((col) => {
            keys.push(col.name);
            headers.push(col.title);
        });

        let replace = ["category", "address", "patientName", "phone", "atpScheduledDate", "scheduledDeliveryDate"];

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;
                
                if (replace.indexOf(key) > -1 && item[key] != null) {
                    result += item[key].replace(/,/g, " ");
                } else {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //this actually prompts the download
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    getResults() {
        const { currentUser } = this.context;

        OrderService.getATPViewNative({ ids: currentUser.id })
            .then((res) => {
                this.setState({ fullResults: res });
                this.buildNewResults(res);
            })
            .catch((err) => {
                console.log(err)
                this.setState({isLoaded: true});
            });
    }

    getNotesForOrder(id, row) {
        NoteService.getNotes(id).then(r => {
            this.setState({
                currentRow: row,
                currentNotes: r,
                noteModalOpen: true
            })
        }).catch(e => {
            console.log(e)
        })
    }

    openScheduleModal(row) {
        let t = this;

        OrderService.getOrderActivity(row.id)
            .then(r => {
                console.log(r)
                t.setState({
                    scheduleModalOpen: true,
                    currentOrder: r,
                })
            }).catch(e => {
                console.log(e)
            });
    }

    buildNewResults(res) {
        let ary = [],
            atpSchedule = {deliveries: []},
            atpTotal = 0,
            deliverySchedule = {deliveries: []},
            deliveryTotal = 0,
            dt = this.state.data;


        res.sort((a, b) => {
            return new Date(a.atpScheduledDate) - new Date(b.atpScheduledDate);
        });

        res.forEach((val) => {

            if (val.atpScheduledDate) {
                atpTotal++;
                let orderDate = new Date(val.atpScheduledDate).toLocaleDateString();

                let exists = atpSchedule.deliveries.findIndex((del) => {
                    return del.date === orderDate;
                });

                if (exists > -1) {
                    atpSchedule.deliveries[exists].orders.push(val);
                } else {
                    atpSchedule.deliveries.push({
                        date: orderDate,
                        orders: [val],
                    });
                }
            }
            else if(val.scheduledDeliveryDate && val.viewType === "Patient Delivery"){
                deliveryTotal++;
                let dDate = new Date(val.scheduledDeliveryDate).toLocaleDateString();

                let exists = deliverySchedule.deliveries.findIndex((del) => {
                    return del.date === dDate;
                });

                if (exists > -1) {
                    deliverySchedule.deliveries[exists].orders.push(val);
                } else {
                    deliverySchedule.deliveries.push({
                        date: dDate,
                        orders: [val],
                    });
                }
            }

            ary.push({
                id: val.id,
                viewType: val.viewType,
                salesRep: val.salesRep,
                status: val.status,
                reason: val.reason,
                patientName: val.patientName,
                payorSource: val.payorSource,
                lastNoteDate: new Date(val.lastNoteDate).toLocaleDateString(),
                atpScheduledDate: val.atpScheduledDate ? new Date(val.atpScheduledDate).toLocaleString() : "",
                scheduledDeliveryDate: val.scheduledDeliveryDate ? GlobalFunctions.formatDateTime(val.scheduledDeliveryDate) : "",
                insurance: val.insurance,
                phone: val.phone,
                address: val.address,
                city: val.city,
                state: val.state,
                zip: val.zip
            });
        });

        dt.rows = ary;

        toast.success("Found " + res.length + " Results");

        this.setState({
            data: dt,
            total: res.length,
            currentSchedule: atpSchedule,
            atpTotal:atpTotal,
            deliverySchedule: deliverySchedule,
            deliveryTotal:deliveryTotal,
            isLoaded: true,
        });
    }

    addNewNote(row) {
        //get the current user context that can be passed into the note DTO...BC
        const {currentUser} = this.context;

        let cb = {id: currentUser.id, name: currentUser.username};

        let note = {
            task: false,
            completed: true,
            assignedTo: null,
            createdAt: GlobalFunctions.getUTCMoment(),
            createdBy: cb,
            createdById: cb.id,
            account: {},
            patientName: row.patientName,
        };
        
        let nText = document.getElementById("newActivityNoteText").value;
        note.text = nText;

         return NoteService.createActivityNote(row.id, note)
            .then((res) => {
                if(res.addNote != null){
                    this.setState({noteModalOpen: false});
                }
            })
            .catch((err) => {
                //Maybe send an error to the user?...BC
                console.log(err);
            });
    }

    renderNotesModal() {
        function renderNotes(n, idx) {
            return (
                <MDBCard key={idx} style={{backgroundColor: "#FFF7D1", margin: 8}}>
                    <MDBCardHeader style={{backgroundColor: "#FFF2AB"}}>
                        <MDBRow>
                            <MDBCol size={"5"} style={{fontSize: 14}}>
                                {GlobalFunctions.formatDateTime(n.createdAt)}
                            </MDBCol>

                            <MDBCol size={"5"} style={{fontSize: 14}}>
                                {n.createdBy ? n.createdBy.name : ""}
                            </MDBCol>
                        </MDBRow>
                    </MDBCardHeader>

                    <MDBCardBody>
                        <p style={{whiteSpace: "pre-line"}}>{n.text}</p>
                    </MDBCardBody>
                    <ReactTooltip/>
                </MDBCard>
            )
        }

        return (
            <MDBModal size={"lg"}
                      toggle={() => {
                          this.setState({noteModalOpen: false})
                      }}
                      isOpen={this.state.noteModalOpen}>
                <MDBModalBody>
                    <div style={{height: 400, overflowY: 'auto'}}>
                        {this.state.currentNotes.map((n, idx) => {
                            return renderNotes(n, idx)
                        })}
                    </div>
                    <hr/>

                    <MDBRow>
                        <MDBCol size="12">
							<textarea
                                id={"newActivityNoteText"}
                                style={{
                                    width: "100%",
                                    height: "100px",
                                }}
                            />
                        </MDBCol>

                        <MDBCol size="12">
                            <MDBIcon
                                style={{color: "#7B626E", float: "right", padding: 4}}
                                data-tip={"Add New Note"}
                                icon="check"
                                onClick={() => {
                                    this.addNewNote(this.state.currentRow)
                                }}
                            />
                        </MDBCol>
                    </MDBRow>

                </MDBModalBody>
            </MDBModal>
        )
    }

    setATPDate(d) {
        let co = this.state.currentOrder;
        co.atpScheduledDate = d;

        this.setState({
            currentOrder: co
        })
    }

    saveOrder() {
        let co = this.state.currentOrder,
            d = this.state.data,
            cs =  this.state.currentSchedule,
            rows = d.rows.slice();

        var sched = {
            atpScheduledDate: co.atpScheduledDate,
        };

        OrderService.updateOrderProperties(co.id, sched)
            .then(r => {
                if(r){
                    toast.success("Order Scheduled for ATP")

                    let cr = d.rows.findIndex(v => {
                        return v.id === co.id
                    })

                    if (cr > -1) {
                        let f = d.rows[cr];
                        f.atpScheduledDate = new Date(co.atpScheduledDate).toLocaleString();

                        rows.splice(cr, 1, f)
                        d.rows = rows;


                        let sd = cs.deliveries.slice(),
                            shortDate = new Date(co.atpScheduledDate).toLocaleDateString();

                        let idx = sd.findIndex(delivery => {
                            return delivery.date === shortDate;
                        })

                        if(idx > -1){
                            sd[idx].orders.push(f);

                            sd[idx].orders.sort((a,b) => {
                                return new Date(a.atpScheduledDate) - new Date(b.atpScheduledDate);
                            })
                        }
                        else{
                            sd.push({
                                date:f.atpScheduledDate,
                                orders:[f]
                            })

                            sd.sort((a,b) => {
                                return new Date(a.date) - new Date(b.date);
                            })
                        }

                        cs.deliveries = sd;
                    }

                    this.setState({
                        currentOrder: {patient: {}},
                        scheduleModalOpen: false,
                        data: d,
                        currentSchedule: cs
                    })
                }
                else{
                    toast.warn("An error occurred setting the date.")
                }
            }).catch(e => {
                console.log(e);
            })
    }

    renderScheduleModal() {
        let co = this.state.currentOrder

        function formatPhoneNumber(phoneNumberString) {
            let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
            let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

            if (match) {
                let intlCode = (match[1] ? '+1 ' : '');
                return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
            }
            return phoneNumberString;
        }

        return (
            <MDBModal size={"lg"}
                      toggle={() => {
                          this.setState({scheduleModalOpen: false})
                      }}
                      isOpen={this.state.scheduleModalOpen}>
                <MDBModalBody>
                    <MDBRow>
                        <Col size={8}>
                            <MDBRow>
                                <MDBCol size={12}>
                                    {co.patientName}
                                </MDBCol>
                                <MDBCol size={12}>
                                    {formatPhoneNumber(co.phone)}
                                </MDBCol>
                                <MDBCol size={12}>
                                    {co.address}
                                </MDBCol>
                                <MDBCol size={12}>
                                    {co.city}{", "}{co.state} {" "}{co.zipCode}
                                </MDBCol>
                            </MDBRow>
                        </Col>
                        <Col size={4} style={{paddingTop: 10}}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    format={"MMMM DD, YYYY - hh:mm a"}
                                    size={"small"}
                                    clearable
                                    style={{width: "100%"}}
                                    variant={"outlined"}
                                    label={"ATP Scheduled Date"}
                                    emptyLabel={"Not Specified"}
                                    value={co.atpScheduledDate || null}
                                    onChange={(date) => this.setATPDate(date)}
                                />
                            </MuiPickersUtilsProvider>
                        </Col>
                    </MDBRow>

                    <hr/>

                    <span style={{float: "right"}}>
                        <MDBBtn onClick={() => this.saveOrder()}>
                            Schedule ATP Date
                        </MDBBtn>
                    </span>

                </MDBModalBody>
            </MDBModal>
        )
    }

    renderTable() {
        const Cell = ({row, column, ...props}) => {
            if (column.name === "lastNoteDate") {
                return (
                    <Table.Cell style={{cursor: 'pointer'}} onClick={this.getNotesForOrder.bind(this, row.id, row)}>
                        <MDBIcon size={"lg"}
                                 icon={"sticky-note"}
                                 style={{color: "#b5a040"}}/>
                    </Table.Cell>

                )
            }

            if (column.name === "atpScheduledDate") {

                return (
                    <Table.Cell style={{cursor: 'pointer'}} onClick={this.openScheduleModal.bind(this, row)}>
                        <MDBIcon size={"lg"} icon={"calendar"} style={{color: "#8a3c61", marginRight: 8}}/>
                            {row.atpScheduledDate || ""}
                    </Table.Cell>
                )

            }

            return (
                <Table.Cell
                    {...props}
                    // onClick={(e) => {
                    //     if (e.ctrlKey === true) {
                    //         this.setState({
                    //             targetLink: row.id,
                    //         });
                    //
                    //         setTimeout(() => {
                    //             document.getElementById("orderListId").click();
                    //         }, 5);
                    //     } else {
                    //         this.props.history.push({
                    //             pathname: "/order/" + row.id,
                    //             state: {goto: "followup"},
                    //         });
                    //     }
                    // }}
                    style={{
                        cursor: "pointer",
                    }}
                />
            );
        };

        if (this.state.isLoaded === true) {
            return (
                <Grid
                    style={{maxWidth: "1800px !important"}}
                    rows={this.state.data.rows}
                    rootComponent={Root}
                    columns={this.state.data.columns}
                >
                    <DragDropProvider/>
                    <GroupingState
                        autoExpandAll={false}
                        grouping={this.state.groupingColumns}
                        onGroupingChange={this.changeGrouping}
                    />
                    <IntegratedGrouping/>
                    <PagingState defaultCurrentPage={0} pageSize={25}/>
                    <IntegratedPaging/>
                    <VirtualTable cellComponent={Cell} height={"auto"}/>
                    <TableColumnResizing
                        defaultColumnWidths={this.state.defaultColumnWidths}
                    />
                    <TableHeaderRow/>
                    <PagingPanel/>
                    <TableGroupRow contentComponent={Content} showColumnsWhenGrouped/>
                    <ItemCounter/>
                    <Toolbar/>
                    <GroupingPanel/>
                </Grid>
            );
        } else {
            return <div/>;
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div className={"purchasingGrid ordersListGrid"}
                 style={{height: 0.9 * window.innerHeight, backgroundColor: "white"}}>
                {this.renderTable()}
            </div>
        );
    }

    renderTooltipCards() {
        let type = this.state.tooltipType;

        let classStyle = type === "Patient Evaluation" ? "techTooltipBox" : "techTooltipBoxDelivery";

        return (
            <div id={"techTooltip"} className={"techTooltipBoxOuter"}>
                <div className="wrapper">
                    {this.state.techTooltip ? (
                        this.state.techTooltip.map((c, idx) => {
                            return (
                                <div className={classStyle} key={idx}>
                                    {c}
                                </div>
                            );
                        })
                    ) : (
                        <div />
                    )}
                </div>
            </div>
        );
    }

    printTechTooltip(date, type) {
        const {currentUser} = this.context;

        let mywindow = window.open("", "PRINT", "height=600,width=600");
        mywindow.document.write(
            "<html><head><title>Daily ATP Schedule</title>"
        );
        mywindow.document.write("</head><body >");
        mywindow.document.write("<h1>" + currentUser.firstname + " " + currentUser.lastname + "</h1>");
        mywindow.document.write("<h1>" + type + " Schedule for " + date + "</h1>");
        mywindow.document.write(document.getElementById("techTooltip").innerHTML);
        mywindow.document.write("</body></html>");
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); //  for IE >= 10*/
        mywindow.print();
        mywindow.close();
        return true;
    }

    getTooltipContent(orders, tech, date, type) {

        orders.sort((a, b) => {
            return (
                new Date(a.atpScheduledDate) - new Date(b.atpScheduledDate)
            );
        });

        let ary = [];

        orders.forEach((ord, index) => {
            let address =
                !ord.address && !ord.city && !ord.state && !ord.zip
                    ? "*** No Address Provided ***"
                    : ord.address +
                    "\n\t" +
                    ord.city +
                    ", " +
                    ord.state +
                    " " +
                    ord.zip;
            let phone = ord.phone ? ord.phone : "*** No phone Provided ***";
            ary.push(
                <p
                    key={index}
                    className={"techTooltipBoxInner"}
                    onClick={() => this.printTechTooltip(date)}
                >
                    {"Order ID: " + ord.id} <br />
                    {"Patient Name: " + ord.patientName} <br />
                    {"ATP Scheduled Time: " + GlobalFunctions.formatTime(ord.atpScheduledDate)}{" "}
                    <br />
                    {"Address: " + address} <br />
                    {"Phone: " + phone} <br />
                </p>
            );
        });
        this.setState({
            techTooltip: ary,
            tooltipType: type,
        });
    }

    renderSideSchedule() {
        const {currentUser} = this.context;

        let schedule = this.state.currentSchedule,
            deliverySchedule = this.state.deliverySchedule,
            t = this;

        function renderDateRows(ary, type) {
            return (ary.deliveries.map((r, idx) => {

                    return (
                        <div key={idx}
                             onMouseEnter={() => t.getTooltipContent(r.orders, currentUser.firstname + " " + currentUser.lastname, r.date, type)}
                             onClick={() => t.printTechTooltip(r.date, type)}
                             onMouseLeave={() => t.setState({ techTooltip: "" })}>
                            {new Date(r.date).toLocaleDateString()}
                            <span style={{float:'right'}}>{r.orders.length}</span>
                        </div>
                    )

                })
            )
        }

        return (
            <MDBCard>
                <CardBody style={{overflowY: "scroll"}}>
                    <div>
                        <div
                            style={{
                                paddingRight: 0,
                                width: "100%",
                                paddingTop: "10px",
                            }}
                        >

                            <div style={{maxWidth: "90%"}}>
                                <div
                                    style={{
                                        paddingTop: "6px",
                                        paddingBottom: "6px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <h6 style={{display: "inline"}}>
                                        ATP Evaluations
                                    </h6>
                                    <h6 style={{float: 'right'}}>
                                        {this.state.atpTotal}
                                    </h6>
                                    <hr
                                        style={{borderColor: "#555555", borderWidth: "3px"}}
                                    />
                                    {renderDateRows(schedule, "Patient Evaluation")}

                                    <br/>
                                    <br/>

                                    <h6 style={{display: "inline"}}>
                                        Deliveries
                                    </h6>
                                    <h6 style={{float: 'right'}}>
                                        {this.state.deliveryTotal}
                                    </h6>
                                    <hr
                                        style={{borderColor: "#555555", borderWidth: "3px"}}
                                    />
                                    {renderDateRows(deliverySchedule, "Patient Delivery")}
                                </div>
                            </div>
                        </div>
                        <ReactTooltip/>
                    </div>
                </CardBody>
            </MDBCard>
        );
    }

    render() {
        return (
            <div>
                {this.renderNotesModal()}
                {this.renderScheduleModal()}
                {this.renderTooltipCards()}
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                    position={"top-right"}
                    style={{marginTop: "75px"}}
                />

                <div style={{maxWidth: "98%", marginLeft: "1rem"}}>
                    <Row style={{marginTop: 10}}>
                        <Col size={"3"}>
                            {this.renderSideSchedule()}
                        </Col>
                        <Col size={"9"}>
                            {this.renderTableOrSpinner()}
                        </Col>
                    </Row>

                    <Link
                        id="orderListId"
                        to={{
                            pathname: `/order/${this.state.targetLink}`,
                            state: {goto: "followup"},
                        }}
                        target="_blank"
                        rel="opener"
                        style={{display: "none"}}
                        activeclassname="active"
                    />

                    <div className={"fixed-bottom downloadCsvDiv"}>
                        <Button
                            className={"downloadCsvButton"}
                            floating
                            size="sm"
                            color={"primary"}
                            data-tip={"Download CSV"}
                            onClick={this.clientCSV.bind(
                                this,
                                this.state.data,
                                "Follow-Up.csv"
                            )}
                        >
                            <MDBIcon icon="download" style={{fontSize: "2em"}}/>
                        </Button>

                        <ReactTooltip/>
                    </div>
                </div>
            </div>
        );
    }
}
