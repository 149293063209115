import React from "react";
import ColumnPipeline from "../../Components/Pipeline/ColumnPipeline";
import OrderService from "../Security/OrderService/orderService";
 

class SalesPipelineGraphView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pipelineResults: [],
            stateTotals: {},
        };
    }

    formatPipelineResults(res, cat) {
        let bigAry = [];

        let topPayors = ["Medicare", "Medicaid", "Cash", "Private"];
        let cats = [
            "Standard PWC",
            "Standard MWC",
            "POV",
            "Complex MWC",
            "Complex PWC",
            "Pediatric - Complex MWC",
        ];
        if (cat) {
            cat.map((c) => {
                return cats.push(c.name);
            });
        }

        res = res.filter(x => x.totalPoints > 0);

        res.forEach((order) => {
            let payor = order.payorSource;
            let category = order.category;

            if (
                order.category === "Complex - Group 2" ||
                order.category === "Complex Base Only"
            ) {
                category = "Complex PWC";
            }

            if (topPayors.indexOf(payor) === -1) {
                payor = "Other";
            }

            if (!order.salesLocation) {
                return;
            }

            if (order.reason === "Contract Services") {
                return;
            }

            let v = bigAry.find((vals) => {
                return vals["Sales Location"] === order.salesLocation;
            });

        if (v) {
                v.TotalPoints += order.totalPoints || 0;
                v[order.status] += order.totalPoints || 0;
                v[order.status] = parseFloat(v[order.status].toFixed(2));
                if (order.status === "Setup") {
                    v[payor] += order.totalPoints || 0;
                    v[payor] = parseFloat(v[payor].toFixed(2));
                    v[category] += 1;
                    v[category] = parseFloat(v[category].toFixed(2));
                }
                searchForRep(order, v[order.status + "Data"]);
            } else {
                pushInitialObject(order, payor, category);
            }
        });

        function searchForRep(order, aryToSearch) {
            let rv = aryToSearch && aryToSearch.length > 0 ? aryToSearch.find((repVal) => {
                return repVal.salesRep === order.salesRep;
            }) : null;

            if (rv) {
                rv.points += order.totalPoints || 0;
                rv.points = Number(rv.points.toFixed(2));
            } else {
                if (!aryToSearch)
                    aryToSearch = [];
                aryToSearch.push({
                    salesRep: order.salesRep,
                    points: order.totalPoints || 0,
                });
            }
        }

        function pushInitialObject(order, payor, category) {
            let outsideData = {
                "Sales Location": order.salesLocation,
                TotalPoints: 0,
                New: 0,
                NewData: [],
                "In Process": 0,
                "In ProcessData": [],
                "Ready to Deliver": 0,
                "Ready to DeliverData": [],
                Setup: 0,
                SetupData: [],
                Other: 0,
                OtherData: [],
                payorSource: 0,
                payorSourceData: [],
            };
            topPayors.forEach((ele) => {
                outsideData[ele] = 0;
            });
            cats.forEach((ele) => {
                outsideData[ele] = 0;
                if (ele === "Complex" || ele === "Standard")
                    outsideData[ele + "Data"] = [];
            });

            outsideData.TotalPoints += order.totalPoints || 0;
            outsideData[order.status] += order.totalPoints || 0;
            outsideData[order.status] = parseFloat(
                outsideData[order.status].toFixed(2)
            );
            if (order.status === "Setup") {
                outsideData[payor] += order.totalPoints || 0;
                outsideData[payor] = parseFloat(outsideData[payor].toFixed(2));
                outsideData[category] += 1;
                outsideData[category] = parseFloat(outsideData[category].toFixed(2));
            }

            outsideData[order.status + "Data"].push({
                salesRep: order.salesRep,
                points: order.totalPoints,
            });

            bigAry.push(outsideData);
        }

        bigAry = bigAry.filter((a) => {
            return a.TotalPoints > 0;
        });

        let totals = { newTotal: 0, processTotal: 0, rtdTotal: 0, setupTotal: 0 };

        bigAry.forEach((v) => {
            totals.newTotal += v.New;
            totals.processTotal += v.InProcess || v["In Process"];
            totals.rtdTotal += v["Ready to Deliver"];
            totals.setupTotal += v.Setup;
        });

        for (let p in totals) {
            totals[p] = totals[p].toFixed(2);
        }

        bigAry.sort((a, b) => {
            return a.TotalPoints > b.TotalPoints ? -1 : 1;
        });

        this.setState({
            pipelineResults: bigAry,
            stateTotals: totals,
        });
    }

    sortByRegion(region) {
        this.newSearch(region);
    }

    
    newSearch(region) {
        this.setState({
            isLoaded: false,
        });
        let today = new Date();
        let filters = {
            end: new Date().toLocaleDateString(),
            start: new Date(today.setDate((today.getDate()) - 14)).toLocaleDateString(),
            locType: "Sales",
            status: [0, 1, 2, 3],
        };

        if (region) {
            filters.region = region;
        }

        return OrderService.getPipelineView(!region || region === 0 ? null : region)
            .then((res) => {
                this.formatPipelineResults(res);
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoaded: true });
            });
    }


    renderPipelineGraph() {
        return (
            <div
                style={{
                    backgroundColor: "#FFF",
                    padding: 25,
                    margin: 25,
                    paddingBottom: 50,
                    marginTop: 20,
                }}
            >
                <ColumnPipeline
                    props={{
                        sortByRegion: this.sortByRegion.bind(this),
                        pro: this.state.pipelineResults,
                        totals: this.state.stateTotals,
                    }}
                />
            </div>
        );
    }

    render() {
        return this.renderPipelineGraph()
    }
}

export default SalesPipelineGraphView;
