import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import InsuranceType from "../../Utility/Crud/InsuranceType";
import PropTypes from "prop-types";

export default class TileInsuranceType extends Tile {
    static contextTypes = {
        insuranceTypesIndexed: PropTypes.object,
    };

    /**
      * @returns {string} The InsuranceType name.
      */
    getTitle() {
        return this.props.insuranceType ? InsuranceType.getDisplayName(this.props.insuranceType) : "None";
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.insuranceType ? InsuranceType.getDisplayName(this.props.insuranceType) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.insuranceType ? InsuranceType.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return InsuranceType.getIcon();
    }
}
