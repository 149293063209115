import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	MDBContainer,
	MDBRow,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
	MDBIcon,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import DocumentTypeService from "./documentTypesService";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

export default class documentTypes extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
				},
				{
					label: "Delete",
					field: "delete",
					sort: "asc",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			confirmationModal: false,
			addNewModal: false,
			name: "",
			currIndex: -1,
			errorMessage: "",
			currDoc: {},
			newDoc:{
				name:"",
			},
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveDocType();
	}

	retrieveDocType() {
		let t = this;
		return DocumentTypeService.getAllDocumentTypes()
			.then((res) => {
				let arr = [],
					d = this.state.data;
				res.forEach((doc, index) => {
					arr.push({
						id: doc.id,
						name: doc.name,
						edit: t.renderEditButton(doc, index),
						remove: t.renderRemoveButton(doc),
					});
				});

				d.rows = arr;

				t.setState({
					data: d,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderEditButton(doc, index) {
		return (
			<MDBIcon
				onClick={() => {this.handleEditClick(doc, index);}}
				icon="edit"
				style={{color: "#7ac5ff"}}
			/>
			// <Button
			// 	floating
			// 	size="sm"
			// 	onClick={() => this.handleEditClick(doc, index)}
			// >
			// 	<MDBIcon icon="edit" />
			// </Button>
		);
	}



	renderRemoveButton(id) {
		return (
			<MDBIcon
				onClick={() => this.handleRemoveClick(id)}
				icon="trash"
				style={{color: "#db0404"}}
			/>
			// <Button
			// 	floating
			// 	color={"red"}
			// 	size="sm"
			// 	onClick={() => this.handleRemoveClick(id)}
			// >
			// 	<MDBIcon icon="trash" />
			// </Button>
		);
	}

	handleConfirmationDelete(id){
		DocumentTypeService.deleteDocumentType(id)
			.then((res) => {
				this.retrieveDocType();
				toast.success("Document Type Removed");
				this.handleConfirmationClose();
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Document Type");
			});
	}

	handleRemoveClick(doc) {
		let i = {
			id: doc.id,
			name: doc.name,
		};
		this.setState({
			confirmationModal: !this.state.confirmationModal,
			currDoc: i,
		});
	}

	handleEditClick(doc, index) {
		let a = {
			id: doc.id,
			name: doc.name,
		};
		this.setState({
			currDoc: a,
			editModal: !this.state.editModal,
			currIndex: index,
		});
	}

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};

	confirmationToggle = () => {
		this.setState({
			confirmationModal: !this.state.confirmationModal,
			errorMessage: "",
		})
	}

	handleConfirmationClose= () => {
		let i = {
			id: -1,
			name: "",
		};
		this.setState({
			currDoc: i,
			currIndex: -1,
			errorMessage: "",
			confirmationModal: !this.state.confirmationModal,
		});
	}

	renderConfirmationModal() {
		return (
			<Modal isOpen={this.state.confirmationModal} toggle={this.confirmationToggle}>
				<ModalHeader
					style={{ backgroundColor: "#F93154", color: "white" }}
					toggle={this.confirmationToggle}
				>
					Confirm Delete
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderConfirmationModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Confirm Delete"}
						onClick={() => this.handleConfirmationDelete(this.state.currDoc.id)}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleConfirmationClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderConfirmationModalBody() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<MDBContainer>
					<MDBRow>
						Are you sure you want to delete {this.state.currDoc.name}?
					</MDBRow>
				</MDBContainer>
			</div>
		);
	}

	renderAddNewModal() {
		return (
			<Modal isOpen={this.state.addNewModal} toggle={this.handleAddNewClose}>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.handleAddNewClose}
				>
					Add New Document Type
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderAddNewModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Add New Document Type"}
						onClick={this.addNewDoc}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleAddNewClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderAddNewModalBody() {
		if (this.state.addNewModal) {
			return (
				<div>
					<p style={{ color: "red" }}>{this.state.errorMessage}</p>
					<Input
						outline
						label="Document Type"
						onChange={this.handleAddNewChange.bind(this)}
					/>
				</div>
			);
		}
	}

	renderEditModal() {
		return (
			<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
				<ModalHeader
					style={{ backgroundColor: "white" }}
					toggle={this.editToggle}
				>
					Edit Document Type
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					<div>
						<p style={{ color: "red" }}>{this.state.errorMessage}</p>
						<Input
							label="Name"
							value={this.state.currDoc.name}
							onChange={this.handleEditChange.bind(this)}
						/>
					</div>
				</ModalBody>

				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Update Document Type"}
						onClick={this.handleEditSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Close"}
						onClick={this.handleEditClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>

					<ReactTooltip />
				</ModalFooter>
			</Modal>
		);
	}

	handleAddNewClose = () => {
		this.setState({
			newDoc:{
				name: "",
			},
			addNewModal: !this.state.addNewModal,
			errorMessage: "",
		})
	}

	handleAddNewChange(e) {
		let i = this.state.newDoc;
		i.name = e.target.value;
		this.setState({
				newDoc: i,
			}
		)
	}

	addNewDoc = () => {
		if(this.validateAddNew()){
			let obj = this.state.newDoc;
			return DocumentTypeService.createDocumentType(obj)
				.then((res) => {
					this.setState({
						errorMessage: "",
					});
					toast.success(obj.name + " Document Type has been Added!");
					this.handleAddNewClose();
					this.retrieveDocType();
				})
				.catch((err) => {
					toast.warn("An error occurred adding a new Document Type");
				});
		}
	}

	handleEditSave = () => {
		if (this.validateEdit()) {
			let d = this.state.data;
			let i = this.state.currIndex;
			return DocumentTypeService.updateDocumentType(this.state.currDoc)
				.then((doc) => {
					d.rows[i].name = doc.name;
					this.setState({
						data: d,
						editModal: !this.state.editModal,
					});
					toast.success("Document Type Edited!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateAddNew(){
		let a = this.state.newDoc;
		if (a.name === "") {
			this.setError("Please add a Credit Card Type");
			return false;
		}
		return true;
	}

	validateEdit() {
		let a = this.state.currDoc;
		if (a.name === "") {
			this.setError("Please add a Document Name");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleEditClose = () => {
		this.setState({
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
			currDoc: {},
		});
	};

	handleEditChange(e) {
		let c = this.state.currDoc;
		c.name = e.target.value;
		this.setState({
			currDoc: c,
		});
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable small striped style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	addNewClick = () => {
		this.setState({
			addNewModal: true
		})
	};

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30 }}>
						Document Types
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Document Type"}
							onClick={this.addNewClick}
						>
							<MDBIcon far icon="file-alt" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				<ReactTooltip />
				{this.renderEditModal()}
				{this.renderConfirmationModal()}
				{this.renderAddNewModal()}
			</Container>
		);
	}
}
