import React from "react";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	Col,
	Container,
	DataTable,
	Input,
	Modal,
	ModalFooter,
	MDBIcon,
	Row,
	Spinner,
	toast,
	ToastContainer,
} from "mdbreact";
import PropTypes from "prop-types";
import Select from "react-select";
import generator from "generate-password";
import ReactTooltip from "react-tooltip";
import userService from "../Security/UserService/userService";
import accountService from "../Security/AccountService/accountService";
import globalFunctions from "../Filters/GlobalFunctions";

export default class userList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
					width: 150,
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
					width: 150,
				},
				{
					label: "Username",
					field: "username",
					sort: "asc",
					width: 100,
				},
				{
					label: "E-Mail",
					field: "email",
					sort: "asc",
					width: 200,
				},
				{
					label: "Account",
					field: "account",
					sort: "asc",
					width: 100,
				},
				{
					label: "Password Expiration Date",
					field: "passwordResetDate",
					sort: "asc",
					width: 100,
				},
				{
					label: "Role",
					field: "role",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "button",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		let pw = this.generatePw(12, true, true);

		let pin = this.generatePw(6, false, false);

		this.state = {
			data: data,
			isLoaded: false,
			//addModalOpen: false,
			editModalOpen: false,
			accountsLoaded: true,
			contactsLoaded: true,
			usersLoaded: false,
			selectedUser: {},
			availableAccounts: [],
			availableContacts: [],
			selectedContact: "",
			searchText: "",
			retypeEmail: "",
			nUser: {
				account: {},
				contactId: null,
				username: "",
				password: pw,
				retypePassword: pw,
				pin: pin,
				phone: "",
				firstname: "",
				lastname: "",
				email: "",
				passwordResetDate: new Date(),
				role: "THIRD_PARTY_USER",
			},
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		thirdPartyUsers: PropTypes.array,
		thirdPartyUsersLoaded: PropTypes.bool,
	};

	componentDidMount() {
		this.populateUsers();
	}

	//shouldComponentUpdate(nextProps, nextState, nextContext) {
	//if (
	// 	nextContext.thirdPartyUsersLoaded === true &&
	// 	this.state.usersLoaded === false
	//) {
	// 	this.populateUsers(nextContext.thirdPartyUsers);
	//}
	//return true;
	//}

	generatePw(length, uppercase, numbers) {
		let pin = generator.generate({
			length: length,
			uppercase: uppercase,
			numbers: numbers,
		});

		return pin;
	}

	searchForAccounts(e) {
		e.preventDefault();

		let st = this.state,
			text = st.searchText;

		if (text == null || text === "") {
			return;
		}

		let f = {
			q: text,
		};

		this.setState({
			accountsLoaded: false,
		});

		accountService.getAllAccounts(f)
			.then((res) => {
				this.populateAccounts(res);
			})
			.catch((e) => {
				//do something if rejected
			});

		//do the search for accounts and set value of available accounts then do populateAccounts;
	}

	populateAccounts(results) {
		let ary = [];

		results.map((acc) => {
			return ary.push({
				label: acc.account + " -- " + (acc.salesRep ? acc.salesRep : " "),
				value: acc,
			});
		});

		this.setState({
			accountsLoaded: true,
			availableAccounts: ary,
		});
	}

	handleAccountSelected = (e) => {
		let nUser = this.state.nUser,
			acc = e.value;

		//set the account ref for the new User
		nUser.account = { name: acc.name, id: acc.id };

		this.setState({
			nUser: nUser,
		});

		//need to get the contacts for this account and populate the list below...BC
		this.retrieveContacts(acc.id);
	};

	retrieveContacts(id) {
		this.setState({
			contactsLoaded: false,
		});

		return userService.getContactsByAccount(id)
			.then((contacts) => {
				this.populateContacts(contacts.accountContacts);
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	populateContacts(results) {
		let ary = [];

		results.map((con) => {
			return ary.push({
				label: con.firstname + " " + con.lastname,
				value: con,
			});
		});

		this.setState({
			availableContacts: ary,
			contactsLoaded: true,
		});
	}

	populateContactInformation(contact) {
		let nUser = this.state.nUser,
			fn = contact.firstname ? contact.firstname.substring(0, 1) : "";

		//set the contact id and autogenerate the username;
		nUser.contactId = contact.id;
		nUser.username = fn + contact.lastname + contact.id;

		nUser.firstname = contact.firstname;
		nUser.lastname = contact.lastname;

		this.setState({
			nUser: nUser,
			selectedContact: contact.firstname + " " + contact.lastname,
		});
	}

	handleContactSelected = (e) => {
		let contact = e.value;

		userService.checkThirdPartyUser(contact.id)
			.then((res) => {
				if (res && res.contactProfileExists != null) {
					//a user already exists...BC
					toast.warn(
						"There is already a third party account associated with this contact.  Please choose another"
					);
					return;
				}
				this.populateContactInformation(contact);
			})
			.catch((e) => {});
	};

	handleUserObjectUpdated(prop, value) {
		let nUser = this.state.nUser;
		nUser[prop] = value;

		this.setState({ nUser: nUser });
	}

	changeRole(role) {
		let nUser = this.state.nUser;
		nUser.role = role;
		this.setState({ nUser: nUser });
	}

	handleNameChanged(prop, value) {
		let nUser = this.state.nUser;
		nUser[prop] = value;

		let fn = nUser.firstname ? nUser.firstname.substring(0, 1) : "";

		nUser.username = fn + nUser.lastname + nUser.contactId;

		this.setState({ nUser: nUser });
	}

	handleEditChanged(prop, value) {
		let user = this.state.selectedUser;
		user[prop] = value;

		this.setState({ selectedUser: user });
	}

	populateUsers() {
		userService.findThirdPartyUsers()
			.then((data) => {
				let ary = [],
					dt = this.state.data;
				console.log(data)
				data.forEach((user, index) => {
					ary.push({
						id: user.id,
						name: user.firstname + " " + user.lastname || "no name",
						username: user.username,
						email: user.email || "no email",
						account: user.account ? user.account.name : "no account",
						passwordResetDate: globalFunctions.formatDate(user.passwordResetDate),
						role: user.role === "THIRD_PARTY_USER" ? "Standard User" : user.role,
						//button: this.renderEditButton(user, index),
						button: "",
					});
				});

				dt.rows = ary;

				this.setState({
					data: dt,
					isLoaded: true,
					usersLoaded: true,
				});
			});
	}

	createNewUser() {
		let u = this.state.nUser;

		if (u.account.id == null) {
			toast.warn("You must choose an account.");
			return;
		}

		if (u.contactId == null) {
			toast.warn("You must select a contact.");
			return;
		}

		if (u.firstname === "") {
			toast.warn("You must enter a first name.");
			return;
		}

		if (u.lastname === "") {
			toast.warn("You must enter a last name.");
			return;
		}

		if (u.username === "" || u.username.length < 3) {
			toast.warn(
				"The selected contact must have a first and last name on file in order to create a third party user."
			);
			return;
		}

		if (u.phone == null || u.phone === "") {
			toast.warn("You must provide a phone number.");
			return;
		}

		if (u.password === "" || u.retypePassword === "") {
			toast.warn("You must provide a password.");
			return;
		}

		if (u.password !== u.retypePassword) {
			toast.warn("Passwords do not match");
			return;
		}

		if (u.email === "") {
			toast.warn("You must enter an email");
			return;
		}

		if (u.email !== this.state.retypeEmail) {
			toast.warn("email addresses do not match");
			return;
		}

		userService.createUser(u)
			.then(() => {
				toast.success("Third party user created successfully!");
				this.cancelNewUser();
			})
			.catch((err) => {
				console.log(err);
			});
	}

	updateUser(user) {
		if (user.firstname === "") {
			toast.warn("You must enter a first name.");
			return;
		}

		if (user.lastname === "") {
			toast.warn("You must enter a last name.");
			return;
		}

		if (user.phone == null || user.phone === "") {
			toast.warn("You must provide a phone number.");
			return;
		}

		// lets only require password / email for create, but not edit - CD
		if(user.email && this.state.retypeEmail){
			if (user.email !== this.state.retypeEmail) {
				toast.warn("email addresses do not match");
				return;
			}
		}
		if(user.password && user.retypePassword){
			if (user.password !== user.retypePassword) {
				toast.warn("Passwords do not match");
				return;
			}
		}

		if (user.email === "") {
			toast.warn("You must enter an email");
			return;
		}

		userService.updateUser(user)
			.then(() => {
				toast.success("Third party user updated successfully!");

				this.setState({
					editModalOpen: false,
				});
			})
			.catch((err) => {
				toast.warn(err);
			});
	}

	cancelNewUser() {
		let pw = this.generatePw(12, true, true);

		let pin = this.generatePw(6, false, false);

		let nUser = {
			account: {},
			contactId: null,
			username: "",
			password: pw,
			retypePassword: pw,
			pin: pin,
			email: "",
			phone: "",
			firstname: "",
			lastname: "",
			role: "THIRD_PARTY_USER",
		};

		this.setState({
			nUser: nUser,
			//addModalOpen: false,
			retypeEmail: "",
			selectedContact: "",
			availableContacts: [],
			availableAccounts: [],
		});
	}

	//renderAddUserButton() {
	//	//This will call a modal
	//	return (
	//		<Button
	//			style={{ float: "right" }}
	//			floating
	//			size="sm"
	//			color={"secondary"}
	//			data-tip={"Add Third Party User"}
	//			onClick={() => this.setState({ addModalOpen: true })}
	//		>
	//			<MDBIcon icon="user-plus" style={{ fontSize: "2em" }} />
	//		</Button>
	//	);
	//}

	renderEditButton(user, index) {
		return (
			<MDBIcon
				icon="edit"
				key={index}
				aria-hidden="true"
				color="warning"
				onClick={() => {
					let today = new Date().getTime();

					if (today > user.passwordResetDate) {
						toast.warn("This user's password needs reset!");
					}

					//get a copy of the user and set a new pin;
					let su = JSON.parse(JSON.stringify(user));
					su.pin = this.generatePw(6, false, false);

					this.setState({
						editModalOpen: true,
						selectedUser: su,
					});
				}}
			/>
		);
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<DataTable striped info={false} small data={this.state.data}>
					{" "}
				</DataTable>
			);
		} else {
			return <div></div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container>
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderAccountSelect() {
		if (this.state.accountsLoaded) {
			return (
				<Select
					placeholder={this.state.nUser.account.name || "Select an Account ..."}
					options={this.state.availableAccounts}
					onChange={this.handleAccountSelected.bind(this)}
				/>
			);
		}

		return (
			<div>
				<Spinner small blue />
			</div>
		);
	}

	renderContactSelect() {
		if (this.state.contactsLoaded) {
			return (
				<Select
					placeholder={this.state.selectedContact || "Select a Contact ..."}
					options={this.state.availableContacts}
					onChange={this.handleContactSelected.bind(this)}
				/>
			);
		}

		return (
			<div>
				<Spinner small green />
			</div>
		);
	}

	//renderAddUserModal() {
	//	//Need to be able to select an account and then contacts from that account
	//	return (
	//		<Modal
	//			position={"top"}
	//			className=""
	//			isOpen={this.state.addModalOpen}
	//			toggle={() => {
	//				this.cancelNewUser();
	//			}}
	//		>
	//			<Card>
	//				<CardHeader color={"indigo"}>New Third Party User</CardHeader>
	//				<CardBody>
	//					<FormInline
	//						onSubmit={(e) => {
	//							this.searchForAccounts(e);
	//						}}
	//						style={{ display: "block" }}
	//					>
	//						<Input
	//							label={"Search for an Account"}
	//							style={{ width: "100%" }}
	//							onChange={(e) => {
	//								this.setState({ searchText: e.target.value });
	//							}}
	//						/>
	//					</FormInline>

	//					{this.renderAccountSelect()}

	//					<br />

	//					{this.renderContactSelect()}

	//					<Row>
	//						<Col size={"6"}>
	//							<Input
	//								label={"First Name"}
	//								value={this.state.nUser.firstname}
	//								onChange={(e) => {
	//									this.handleNameChanged("firstname", e.target.value);
	//								}}
	//							/>
	//						</Col>

	//						<Col size={"6"}>
	//							<Input
	//								label={"Last Name"}
	//								value={this.state.nUser.lastname}
	//								onChange={(e) => {
	//									this.handleNameChanged("lastname", e.target.value);
	//								}}
	//							/>
	//						</Col>
	//					</Row>

	//					<Row>
	//						<Col size={"6"}>
	//							<Input
	//								label={"Username"}
	//								disabled={this.state.nUser.role !== "ADMIN"}
	//								value={this.state.nUser.username}
	//								onChange={(e) => {
	//									this.handleUserObjectUpdated("username", e.target.value);
	//								}}
	//							/>
	//						</Col>
	//						<Col size={"6"}>
	//							<Input
	//								label={"Phone"}
	//								value={this.state.nUser.phone}
	//								onChange={(e) => {
	//									this.handleUserObjectUpdated("phone", e.target.value);
	//								}}
	//							/>
	//						</Col>
	//					</Row>

	//					<Row>
	//						<Col size={"6"}>
	//							<Input
	//								label={"Pin"}
	//								disabled={true}
	//								value={this.state.nUser.pin}
	//								onChange={(e) => {
	//									this.handleUserObjectUpdated("pin", e.target.value);
	//								}}
	//							/>
	//						</Col>

	//						{this.state.nUser.role === "ADMIN" ? (
	//							<Col size={"6"}>
	//								<Input
	//									label={"Password"}
	//									value={this.state.nUser.password}
	//									onChange={(e) => {
	//										this.handleUserObjectUpdated("password", e.target.value);
	//										this.handleUserObjectUpdated(
	//											"retypePassword",
	//											e.target.value
	//										);
	//									}}
	//								/>
	//							</Col>
	//						) : (
	//							""
	//						)}
	//					</Row>

	//					<Input
	//						label={"Email"}
	//						value={this.state.nUser.email}
	//						onChange={(e) => {
	//							this.handleUserObjectUpdated("email", e.target.value);
	//						}}
	//					/>

	//					<Input
	//						label={"Retype Email"}
	//						value={this.state.retypeEmail}
	//						onChange={(e) => {
	//							this.setState({ retypeEmail: e.target.value });
	//						}}
	//					/>

	//					<Row
	//						style={{
	//							display: "flex",
	//							justifyContent: "space-between",
	//							paddingLeft: "2rem",
	//							paddingRight: "2rem",
	//						}}
	//					></Row>

	//					<ModalFooter>
	//						<Button
	//							floating
	//							size="sm"
	//							color={"success"}
	//							data-tip={"Create Third Party User"}
	//							onClick={() => this.createNewUser()}
	//						>
	//							<MDBIcon icon="user-check" style={{ fontSize: "2em" }} />
	//						</Button>

	//						<Button
	//							floating
	//							size="sm"
	//							color={"red"}
	//							data-tip={"Cancel"}
	//							onClick={() => this.cancelNewUser()}
	//						>
	//							<MDBIcon icon="times" style={{ fontSize: "2em" }} />
	//						</Button>
	//					</ModalFooter>
	//					<ReactTooltip />
	//				</CardBody>
	//			</Card>
	//		</Modal>
	//	);
	//}

	renderEditUserModal() {
		let user = this.state.selectedUser;
		//Need to be able to select an account and then contacts from that account
		return (
			<Modal
				position={"top"}
				className=""
				isOpen={this.state.editModalOpen}
				toggle={() => {
					this.setState({ editModalOpen: !this.state.editModalOpen });
				}}
			>
				<Card>
					<CardHeader color={"indigo"}>Update User</CardHeader>
					<CardBody>
						<Row>
							<Col size={"6"}>
								<Input
									label={"First Name"}
									value={user.firstname}
									onChange={(e) => {
										this.handleEditChanged("firstname", e.target.value);
									}}
								/>
							</Col>

							<Col size={"6"}>
								<Input
									label={"Last Name"}
									value={user.lastname}
									onChange={(e) => {
										this.handleEditChanged("lastname", e.target.value);
									}}
								/>
							</Col>
						</Row>

						<Row>
							<Col size={"6"}>
								<Input
									label={"Username"}
									disabled={true}
									value={user.username}
								/>
							</Col>
							<Col size={"6"}>
								<Input
									label={"Phone"}
									value={user.phone}
									onChange={(e) => {
										this.handleEditChanged("phone", e.target.value);
									}}
								/>
							</Col>
						</Row>

						<Row>
							<Col size={"6"}>
								<Input
									label={"Password"}
									value={user.password}
									onChange={(e) => {
										this.handleEditChanged("password", e.target.value);
									}}
								/>
							</Col>

							<Col size={"6"}>
								<Input
									label={"Repeat Password"}
									value={user.retypePassword}
									onChange={(e) => {
										this.handleEditChanged("retypePassword", e.target.value);
									}}
								/>
							</Col>
						</Row>

						<Input
							label={"Email"}
							value={user.email}
							onChange={(e) => {
								this.handleUserObjectUpdated("email", e.target.value);
							}}
						/>

						<Input
							label={"Retype Email"}
							value={this.state.retypeEmail}
							onChange={(e) => {
								this.setState({ retypeEmail: e.target.value });
							}}
						/>

						<ModalFooter>
							<Button
								floating
								size="sm"
								color={"success"}
								data-tip={"Update Third Party User"}
								onClick={() => this.updateUser(user)}
							>
								<MDBIcon far icon="save" style={{ fontSize: "2em" }} />
							</Button>
						</ModalFooter>

						<ReactTooltip />
					</CardBody>
				</Card>
			</Modal>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}

		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={5000}
				/>
				{/*{this.renderAddUserModal()}*/}
				{this.renderEditUserModal()}
				<Card>
					<CardHeader
						style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30 }}>
						Third Party Users
						{/*{this.renderAddUserButton()}*/}
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
					<ReactTooltip />
				</Card>
			</Container>
		);
	}
}
