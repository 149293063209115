import React from "react";
import Card from "../../../Card";
import EmptyState from "../../../EmptyState";
import MUIButton from "@mui/material/Button"
import MUIIconButton from "@mui/material/IconButton"
import MUIAddIcon from "@mui/icons-material/Add"
import MUIDeleteIcon from "@mui/icons-material/Delete"
import MUIGrid from "@mui/material/Grid";
import DialogSearchInsuranceType from "../../../Dialog/Search/DialogSearchInsuranceType";
import TileInsuranceType from "../../../Tile/TileInsuranceType";
import PropTypes from "prop-types";

export default class CardAdminQueueFilterOrderActivityInsuranceType extends Card {
    static contextTypes = {
        insuranceTypesIndexed: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state.insuranceTypeDialogOpen = false;
    }

    /**
     * Render the content of the card.
     */
    renderContent() {
        const handleRemove = (insuranceTypeId) => {
            const filter = this.props.queue.filter;
            filter["order_activity.insurance_type_id"].splice(
                filter["order_activity.insurance_type_id"].indexOf(insuranceTypeId),
                1
            );

            this.props.onChange({
                "filter": filter
            });
        }

        if (
            this.props.queue &&
            this.props.queue.filter &&
            this.props.queue.filter["order_activity.insurance_type_id"] &&
            this.props.queue.filter["order_activity.insurance_type_id"].length > 0
        ) {
            return (
                <>
                    <MUIGrid container spacing={2}>
                        {this.props.queue.filter["order_activity.insurance_type_id"].map((insuranceTypeId) => {
                            return (
                                <MUIGrid key={insuranceTypeId} item xs={12} sm={6} md={4}>
                                    <TileInsuranceType
                                        insuranceType={this.context.insuranceTypesIndexed[insuranceTypeId]}
                                        iconButtons={[(<MUIIconButton onClick={() => handleRemove(insuranceTypeId)}><MUIDeleteIcon /></MUIIconButton>)]}
                                    />
                                </MUIGrid>
                            );
                        })}
                        {this.renderDialog()}
                    </MUIGrid>
                </>
            );
        } else {
            return (
                <>
                    <EmptyState
                        line1="Not Configured"
                        line2="Showing all Insurance Types"
                    />
                    {this.renderDialog()}
                </>
            )
        }
    }



    /**
     * Renders a search dialog of Insurance Types
     */
    renderDialog() {
        const handleAdd = (insuranceType) => {
            const filter = (this.props.queue && this.props.queue.filter) || {};
            if (!filter["order_activity.insurance_type_id"]) {
                filter["order_activity.insurance_type_id"] = [];
            }
            filter["order_activity.insurance_type_id"].push(insuranceType.id);
            filter["order_activity.insurance_type_id"] = [...new Set(filter["order_activity.insurance_type_id"])];

            this.props.onChange({
                "filter": filter
            });
        }

        return (
            <DialogSearchInsuranceType
                open={this.state.insuranceTypeDialogOpen}
                onClose={() => this.setState({ insuranceTypeDialogOpen: false })}
                onSelect={handleAdd}
            />
        );
    }

    /**
     * Renders add Insurance Type button
     */
    getActions() {
        const handleAdd = () => {
            this.setState({
                insuranceTypeDialogOpen: true,
            });
        }

        const buttonItems = [
            (<MUIButton
                component="label"
                variant="outlined"
                startIcon={<MUIAddIcon />}
                onClick={handleAdd}>Add Insurance Type</MUIButton>)
        ];

        return buttonItems;
    }

    /**
     * @returns {string} The title of the card.
     */
    getTitle() {
        return "Filter: Order Activity > Primary Insurance > Type";
    }
}