import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
	MDBIcon,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import DeliveryAcknowledgementService from "./deliveryAcknowledgementService";

export default class deliveryAcknowledgements extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Description",
					field: "description",
					sort: "asc",
				},
				{
					label: "Type",
					field: "type",
					sort: "asc",
				},
				{
					label: "Choices",
					field: "choices",
					sort: "asc",
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
				},
				{
					label: "Delete",
					field: "delete",
					sort: "asc",
				},
			],
			rows: [],
		};

		const o = [
			{
				label: "Signature",
				value: "Signature",
			},
			{
				label: "Initials",
				value: "Initials",
			},
			{
				label: "Date",
				value: "Date",
			},
			{
				label: "Select",
				value: "Select",
			},
		];

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			name: "",
			currIndex: -1,
			errorMessage: "",
			currDelivery: {},
			selectOptions: o,
			selected: "",
			currChoice: "",
			choiceList: [],
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveDelivery();
	}

	retrieveDelivery() {
		let t = this;
		return DeliveryAcknowledgementService.getAllDeliveryAcknowledgements()
			.then((res) => {
				let arr = [],
					d = this.state.data;
				res.forEach((delivery, index) => {
					arr.push({
						id: delivery.id,
						name: delivery.name,
						type: delivery.type >= 0 ? this.state.selectOptions[delivery.type].label : 'None',
						choices: delivery.choices || "",
						edit: t.renderEditButton(delivery, index),
						remove: t.renderRemoveButton(delivery.id),
					});
				});

				d.rows = arr;

				t.setState({
					// data: d,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderEditButton(delivery, index) {
		return (
			<MDBIcon
				onClick={() => {this.handleEditClick(delivery, index);}}
				icon="edit"
				style={{color: "#7ac5ff"}}
			/>
		);
	}

	renderRemoveButton(id) {
		return (
			<MDBIcon
				onClick={() => this.handleRemoveClick(id)}
				icon="trash"
				style={{color: "#db0404"}}
			/>
		);
	}

	handleRemoveClick(id) {
		DeliveryAcknowledgementService.deleteDeliveryAcknowledgement(id)
			.then((res) => {
				this.retrieveDelivery();
				toast.success("Delivery Acknowledgment Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Delivery Acknowledgment");
			});
	}

	handleEditClick(delivery, index) {
		let d = JSON.parse(JSON.stringify(delivery));
		this.setState({
			currDelivery: d,
			editModal: !this.state.editModal,
			currIndex: index,
		});
	}

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};

	renderEditModal() {
		return (
			<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
				<ModalHeader
					style={{ backgroundColor: "#90A4AE", color: "white" }}
					toggle={this.editToggle}
				>
					Edit Delivery Acknowledgment
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					<div>
						{this.renderForm()}
						{this.renderSelectChosen()}
					</div>
				</ModalBody>

				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Update Delivery Acknowledgement"}
						onClick={this.handleEditSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Close"}
						onClick={this.handleEditClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>

					<ReactTooltip />
				</ModalFooter>
			</Modal>
		);
	}

	renderForm() {
		let opts = this.state.selectOptions,
			names = opts.map(x => x.label),
			ind = this.state.currDelivery.type,
			plHolder = names[ind];
			
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<label htmlFor="defaultFormContactMessageEx" className="grey-text">
					Description
				</label>
				<textarea
					id="defaultFormContactMessageEx"
					className="form-control"
					rows="3"
					onChange={this.descriptionChange.bind(this)}
					value={this.state.currDelivery.name}
				/>
				<div className="mt-4">
					<Select
						placeholder={plHolder || "Select type"}
						options={this.state.selectOptions}
						onChange={this.handleSelectChange.bind(this)}
					/>
				</div>
			</div>
		);
	}

	renderSelectChosen() {
		if (this.state.currDelivery.type === 3) {
			let cl = this.state.currDelivery.choices;
			return (
				<div>
					<Input
						label={"Choice value"}
						onChange={this.choiceValueChange.bind(this)}
						value={this.state.currChoice}
					/>
					<p className="grey-text">{cl !== null ? "Current Choices:" : ""}</p>
					<p>{cl}</p>
					<Button
						style={{ float: "right" }}
						onClick={() => this.choiceButtonClick("add")}
					>
						{" "}
						Add Choice{" "}
					</Button>
					<Button
						style={{ float: "right" }}
						color={"secondary"}
						onClick={() => this.choiceButtonClick("remove")}
					>
						{" "}
						Remove Choice{" "}
					</Button>
				</div>
			);
		}
	}

	descriptionChange = (e) => {
		let d = this.state.currDelivery;
		d.name = e.target.value;
		this.setState({
			currDelivery: d,
		});
	};

	handleSelectChange(e) {
		let opts = this.state.selectOptions,
			names = opts.map(x => x.label),
			ind = names.indexOf(e.value);
		let d = this.state.currDelivery;
		d.type = ind;
		this.setState({
			currDelivery: d,
		});
	}

	choiceValueChange = (e) => {
		this.setState({
			currChoice: e.target.value,
		});
	};

	choiceButtonClick = (val) => {
		let delivery = JSON.parse(JSON.stringify(this.state.currDelivery));
		let list = delivery.choices;
		if (val === "add") {
			let c = this.state.currChoice;

			if (c.trim() !== "") {
				if (delivery.choices === null) {
					list = c;
				} else if (delivery.choices.length === 0) {
					list += c;
				} else {
					list += " / " + c;
				}
			}
		} else if (val === "remove") {
			list = list.split(" / ");
			list.pop();
			list = list.join(" / ");
		}
		delivery.choices = list;
		this.setState({
			currDelivery: delivery,
			currChoice: "",
		});
	};

	handleEditSave = () => {
		if (this.validateEdit()) {
			let ack = this.state.currDelivery;
				
			if (ack.type !== 3 && ack.choices !== null) {
				delete ack.choices;
			}

			return DeliveryAcknowledgementService.updateDeliveryAcknowledgement(ack)
				.then((delivery) => {
					this.setState({
						editModal: !this.state.editModal,
						errorMessage: "",
					});
					toast.success("Delivery Acknowledgment Edited!");
					this.retrieveDelivery();
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateEdit() {
		let a = this.state.currDelivery;
		if (a.name.trim() === "") {
			this.setError("Please add a Description");
			return false;
		} else if (a.type === "Select" && a.choices === null) {
			this.setError("Please add Choices to use the Select Type");
			return false;
		} else if (a.type === "Select" && a.choices.length === 0) {
			this.setError("Please add Choices to use the Select Type");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleEditClose = () => {
		this.setState({
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
			currDelivery: {},
		});
	};

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable small striped style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	addNewClick = () => {
		return this.props.history.push({
			pathname: "deliveryAcknowledgements/new",
		});
	};

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize: 30 }}>
						Delivery Acknowledgments
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Delivery Acknowledgement"}
							onClick={this.addNewClick}
						>
							<MDBIcon far icon="file-alt" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				<ReactTooltip />
				{this.renderEditModal()}
			</Container>
		);
	}
}
