import React from "react";
import Chip from "../Chip";
import MUIGreyColor from "@mui/material/colors/grey";
import MUIAvatar from "@mui/material/Avatar";
import Department from "../../Utility/Crud/Department";
import PropTypes from "prop-types";
import * as MUIColors from "@mui/material/colors";

export default class ChipDepartment extends Chip {
    static contextTypes = {
        colorsIndexed: PropTypes.object,
    };

    /**
     * Renders a MUI Avatar.
     */
    renderAvatar() {
        const colorObject = this.props.department ? this.context.colorsIndexed[this.props.department.colorId] : null;
        const color = (this.props.department ? MUIColors[colorObject.hue][colorObject.shade] : MUIGreyColor[500]);
        const avatarColor = this.props.isTransparent ? "transparent" : color;
        const iconColor = this.props.isTransparent ? color : "#fff";

        return (
            <MUIAvatar sx={{ bgcolor: avatarColor }}>
                {Department.getIcon({ fontSize: "small", sx: { color: iconColor } })}
            </MUIAvatar>
        );
    }

    /**
     * @return {string} The department name.
     */
    getLabel() {
        return this.props.department ? Department.getDisplayName(this.props.department) : "None";
    }
}
