import Tile from "../Tile";
import MUIGreyColor from "@mui/material/colors/grey";
import IssueStatus from "../../Utility/Crud/IssueStatus";

export default class TileIssueStatus extends Tile {
    /**
      * @returns {string} The IssueStatus name.
      */
    getTitle() {
        return this.props.issueStatus ? IssueStatus.getDisplayName(this.props.issueStatus) : "None";
    }

    /**
     * @returns {string} The string to be used by the avatar abbreviation
     * formatter.
     */
    getAvatarString() {
        return this.props.issueStatus ? IssueStatus.getDisplayName(this.props.issueStatus) : null;
    }

    /**
     * @returns {string} The color of the avatar.
     */
    getAvatarColor() {
        return this.props.issueStatus ? IssueStatus.getColor() : MUIGreyColor[400];
    }

    /**
     * Renders an icon to represent the avatar if no string is provided.
     */
    renderAvatarIcon() {
        return IssueStatus.getIcon();
    }
}
