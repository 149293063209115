import React from "react";
import MUIMenu from '@mui/material/Menu';
import MUIMoreVertIcon from "@mui/icons-material/MoreVert";
import MUIIconButton from "@mui/material/IconButton";
import MUITooltip from "@mui/material/Tooltip";

export default class Menu2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            anchor: null
        };

    }

    /**
     * Renders the menu.
     */
    render() {
        const handleClose = () => {
            this.setState({
                open: false,
            });
        };

        const handleAnchorClick = (e) => {
            this.setState({
                open: !this.state.open,
                anchor: e.target
            });
        };

        // TODO Figure out how to remove the backdrop and still allow clicking
        // outside the menu to close it
        return (
            <>
                <MUITooltip title={this.props.tooltip}>
                    <MUIIconButton onClick={handleAnchorClick}>
                        {this.props.icon ? this.props.icon : (<MUIMoreVertIcon />)}
                    </MUIIconButton>
                </MUITooltip>
                <MUIMenu
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: this.props.anchorOriginHorizontal || "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: this.props.transformOriginHorizontal || "right",
                    }}
                    anchorEl={this.state.anchor}
                    open={this.state.open}
                    onClose={handleClose}
                    onClick={handleClose}
                >
                    {this.props.menuItems.map((menuItem, i) => (
                        React.cloneElement(menuItem, { key: i })
                    ))}
                </MUIMenu>
            </>
        );
    }
}