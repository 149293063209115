import React from "react";
import {
	Container,
	Fa,
	ToastContainer,
	FormInline,
	CardHeader,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalBody,
	ModalHeader,
	Timeline,
	TimelineStep,
	Spinner,
} from "mdbreact";
import "./home.css";
import CardList from "./cardList";
import OrderService from "../Security/OrderService/orderService";
import NoteService from "../Security/NoteService/noteService";
import GlobalFunctions from "../Filters/GlobalFunctions";

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.retrieveOrders();

		this.state = {
			orders: [],
			sortedOrders: [],
			isLoaded: false,
			searchValue: "",
			physician: "Show All",
			physicians: ["Admin"],
			sortType: "Sort",
			statusFilter: "All Statuses",
			displayStatus: "All Statuses",
			timelineModalOpen: false,
			timelineModalOrder: {},
			timelineModalEntries: [],
			notesModalOpen: false,
			notesModalOrder: {},
			notesModalNotes: [],
		};
	}

	formatStatus(order) {
		if (order.status === "ReadyToDeliver") {
			return "Waiting for Delivery";
		} else if (order.status === "InProcess") {
			if (
				order.orderStatusReason &&
				order.orderStatusReason.name === "Verification"
			) {
				return "Intake / Insurance Verification";
			} else {
				return "Insurance Review";
			}
		} else {
			return order.status;
		}
	}

	formatTimelineStatus(timelineEntry) {
		if (timelineEntry.status === "ReadyToDeliver") {
			return "Waiting for Delivery";
		} else if (timelineEntry.status === "InProcess") {
			if (timelineEntry.reason === "Verification") {
				return "Intake / Insurance Verification";
			} else {
				return "Insurance Review";
			}
		} else {
			return timelineEntry.status;
		}
	}

	retrieveOrders() {
		let f = {
			start: new Date().toLocaleDateString(),
			end: new Date().toLocaleDateString(),
		};
		OrderService.getAllOrdersNative(f)
			.then((orders) => {
				this.setState({
					orders: orders,
					sortedOrders: orders,
					isLoaded: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	setSearchValue(event) {
		event.preventDefault();
		this.searchPatients();
	}

	statusSelected(status, displayStatus) {
		this.setState({
			statusFilter: status,
			displayStatus: displayStatus,
		});

		let self = this;
		setTimeout(
			(self) => {
				self.searchPatients();
			},
			10,
			self
		);
	}

	physicianSelected(physician) {
		this.setState({ physician: physician });

		let self = this;
		setTimeout(
			(self) => {
				self.searchPatients();
			},
			10,
			self
		);
	}

	sortType(type) {
		let so = this.state.sortedOrders.slice();

		//Only sort alphabetically by last name
		so.sort((b, a) => {
			let aIdx = a.patientName.lastIndexOf(" "),
				bIdx = b.patientName.lastIndexOf(" "),
				an = a.patientName.slice(aIdx + 1),
				bn = b.patientName.slice(bIdx + 1);

			if (type === "Z - A") {
				if (an.toLowerCase() > bn.toLowerCase()) {
					return 1;
				}
				return -1;
			} else if (type === "A - Z") {
				if (an.toLowerCase() > bn.toLowerCase()) {
					return -1;
				}
				return 1;
			} else if (type === "Recently Updated") {
				if (b.lastNoteDate > a.lastNoteDate) {
					return -1;
				}
				return 1;
			}
			return -1;
		});

		this.setState({ sortedOrders: so, sortType: type });
	}

	searchPatients() {
		let v = this.state.searchValue,
			p = this.state.physician,
			f = this.state.statusFilter,
			d = this.state.displayStatus,
			result = this.state.orders.filter((patient) => {
				let name = patient.patientName.toLowerCase();
				if (this.state.physician === "Show All") {
					return name.indexOf(v.toLowerCase()) > -1;
				} else {
					return name.indexOf(v.toLowerCase()) > -1 && patient.owner.name === p;
				}
			});

		if (f !== "All Statuses") {
			result = result.filter((patient) => {
				//Only show verification orders with this criteria...BC
				if (d === "Intake / Insurance Verification") {
					return (
						this.state.statusFilter === patient.status &&
						patient.orderStatusReason &&
						patient.orderStatusReason.name === "Verification"
					);
				}
				//Show all other in process with this criteria...BC
				else if (d === "Insurance Review") {
					return (
						this.state.statusFilter === patient.status &&
						(!patient.orderStatusReason ||
							patient.orderStatusReason.name !== "Verification")
					);
				}

				//show the orders with matching status regardless of reason...BC
				return this.state.statusFilter === patient.status;
			});
		}

		this.setState({ sortedOrders: result });
	}

	toggleTimelineModalPopup(currentOrder) {
		let newState = !this.state.timelineModalOpen;

		//We don't want to call the endpoint if the modal is being closed...BC
		if (newState === false) {
			this.setState({
				timelineModalOpen: newState,
			});
		} else {
			OrderService.getOrderTimeline(currentOrder).then((records) => {
					this.setState({
						timelineModalOpen: newState,
						timelineModalOrder: currentOrder,
						timelineModalEntries: records.orderTimeline,
					});
				});
		}
	}

	toggleNotesModal(order) {
		if (this.state.notesModalOpen === true) {
			this.setState({ notesModalOpen: false });
			return;
		}

		return NoteService.getTherapistNotes(order.id).then((notes) => {
			this.setState({
				notesModalOpen: true,
				notesModalOrder: order,
				notesModalNotes: notes,
			});
		});
	}

	generateTimelineEntry(order, theme) {
		if (this.state.timelineModalEntries == null) {
			return;
		}
		return this.state.timelineModalEntries.map((att, idx) => {
			let v = true;
			if (idx === 0 || idx % 2 === 0) {
				v = false;
			}
			return (
				<TimelineStep
					color="red darken-4"
					href="#void"
					inverted={v}
					key={idx}
					icon="calendar-check-o"
				>
					<h3>{this.formatTimelineStatus(att)}</h3>
					<h4>{att.reason}</h4>
					<hr />
					<h4>{GlobalFunctions.formatDate(att.updatedOn)}</h4>
				</TimelineStep>
			);
		});
	}

	renderTimelineModal(theme) {
		let order = this.state.timelineModalOrder;
		return (
			<div>
				<Modal
					isOpen={this.state.timelineModalOpen}
					toggle={() => this.toggleTimelineModalPopup(order)}
					fullHeight
					position="left"
				>
					<ModalHeader
						style={{
							backgroundColor: theme.importantCard,
							color: theme.headerText,
						}}
					>
						{this.formatStatus(order)}
						<br />
						Updated: {GlobalFunctions.formatDate(order.lastUpdatedOn || new Date())}
					</ModalHeader>
					<ModalBody style={{ backgroundColor: "transparent" }}>
						{this.renderOrderTimeline(order, theme)}
					</ModalBody>
				</Modal>
			</div>
		);
	}

	renderOrderTimeline(order, theme) {
		return (
			<Timeline className="TimelineStep">
				{this.generateTimelineEntry(order, theme)}
			</Timeline>
		);
	}

	renderNotesModal(theme) {
		let order = this.state.notesModalOrder,
			notes = this.state.notesModalNotes;

		return (
			<div>
				<Modal
					isOpen={this.state.notesModalOpen}
					toggle={() => this.toggleNotesModal(order)}
					fullHeight
					position="right"
				>
					<ModalHeader
						style={{
							backgroundColor: theme.importantCard,
							color: theme.headerText,
						}}
					>
						{order.patientName}
					</ModalHeader>
					<ModalBody style={{ backgroundColor: "transparent" }}>
						{notes.map((notes, idx) => {
							return (
								<CardHeader
									style={{
										backgroundColor: theme.cardHeader,
										color: theme.headerText,
										paddingBottom: "10px",
										marginBottom: "10px",
									}}
									key={idx}
								>
									{notes.text}
									<br />
									<div style={{ textAlign: "right" }}>
										{GlobalFunctions.formatDate(notes.createdAt)}
									</div>
								</CardHeader>
							);
						})}
					</ModalBody>
				</Modal>
			</div>
		);
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderUserDropdown() {
		return (
			<div className="searchDropdownDiv">
				<Dropdown size="lg">
					<DropdownToggle caret>{this.state.physician}</DropdownToggle>
					<DropdownMenu basic>
						{this.state.physicians.map((physician, idx) => {
							return (
								<DropdownItem
									key={idx}
									onClick={this.physicianSelected.bind(this, physician)}
								>
									{physician}
								</DropdownItem>
							);
						})}
						<DropdownItem divider />
						<DropdownItem
							onClick={this.physicianSelected.bind(this, "Show All")}
						>
							Show All
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</div>
		);
	}

	renderSortDropdown() {
		return (
			<div className="searchDropdownDiv">
				<Dropdown size="lg">
					<DropdownToggle caret>{this.state.sortType}</DropdownToggle>
					<DropdownMenu basic>
						<DropdownItem onClick={this.sortType.bind(this, "A - Z")}>
							A - Z
						</DropdownItem>
						<DropdownItem onClick={this.sortType.bind(this, "Z - A")}>
							Z - A
						</DropdownItem>
						<DropdownItem divider />
						<DropdownItem
							onClick={this.sortType.bind(this, "Recently Updated")}
						>
							Recently Updated
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</div>
		);
	}

	renderStatusDropdown() {
		return (
			<div className="searchDropdownDiv">
				<Dropdown size="lg">
					<DropdownToggle caret>{this.state.displayStatus}</DropdownToggle>
					<DropdownMenu basic>
						<DropdownItem
							onClick={this.statusSelected.bind(
								this,
								"All Statuses",
								"All Statuses"
							)}
						>
							All Statuses
						</DropdownItem>
						<DropdownItem divider />
						<DropdownItem
							onClick={this.statusSelected.bind(
								this,
								"InProcess",
								"Intake / Insurance Verification"
							)}
						>
							Intake / Insurance Verification
						</DropdownItem>
						<DropdownItem
							onClick={this.statusSelected.bind(
								this,
								"New",
								"Medical Documents"
							)}
						>
							Medical Documents
						</DropdownItem>
						<DropdownItem
							onClick={this.statusSelected.bind(
								this,
								"InProcess",
								"Insurance Review"
							)}
						>
							Insurance Review
						</DropdownItem>
						<DropdownItem
							onClick={this.statusSelected.bind(
								this,
								"ReadyToDeliver",
								"Waiting For Delivery"
							)}
						>
							Waiting For Delivery
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</div>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}

		return (
			<div>
				{this.renderTimelineModal()}
				{this.renderNotesModal()}
				<div align="center">
					<ToastContainer
						hideProgressBar={false}
						newestOnTop={true}
						autoClose={5000}
					/>
					<Container>
						<div className="patientSearchDiv">
							<FormInline
								className="md-form"
								onSubmit={this.setSearchValue.bind(this)}
							>
								<Fa icon="search" />
								<input
									className="form-control form-control-sm ml-3 w-90"
									type="text"
									value={this.state.searchValue}
									placeholder="Search"
									onChange={(evt) =>
										this.setState({ searchValue: evt.target.value })
									}
									aria-label="Search"
								/>
								{this.renderUserDropdown()}
								{this.renderSortDropdown()}
								{this.renderStatusDropdown()}
							</FormInline>
						</div>
					</Container>

					<CardList
						orders={this.state.orders}
						toggleTimelineFunction={this.toggleTimelineModalPopup.bind(this)}
						toggleNotesFunction={this.toggleNotesModal.bind(this)}
						sortedOrders={this.state.sortedOrders}
					/>
				</div>
			</div>
		);
	}
}

export default Home;