import Crud from "../Crud";
import MUICyanColor from "@mui/material/colors/cyan";

/**
 * Static helper functions related to BaseUnitTypes.
 */
export default class BaseUnitType extends Crud {

    /**
     * @param {object} baseUnitType The baseUnitType object.
     * @returns The display name of the baseUnitType.
     */
    static getDisplayName(baseUnitType) {
        return baseUnitType.name;
    }

    /**
     * Determines if a baseUnitType matches a search query.
     *
     * @param {object} baseUnitType The baseUnitType to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the baseUnitType matches the query.
     */
    static match(baseUnitType, query) {
        return (
            (baseUnitType.name !== null && baseUnitType.name.toLowerCase().includes(query)) ||
            baseUnitType.id === +query
        );
    }

    /**
     * @param {array} baseUnitTypes The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(baseUnitTypes) {
        return baseUnitTypes.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUICyanColor;
    }

    /**
     * @returns {number} The base color shade of this object.
     */
    static getBaseColorShade() {
        return 800;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Accessible";
    }
}