import Crud from "../Crud";
import MUITealColor from "@mui/material/colors/teal";

/**
 * Static helper functions related to Queue Routes.
 */
export default class QueueRoute extends Crud {

    /**
     * @param {object} queueRoute The queueRoute object.
     * @returns The display name of the queueRoute.
     */
    static getDisplayName(queueRoute) {
        return queueRoute.name;
    }

    /**
     * Determines if a queueRoute matches a search query.
     *
     * @param {object} queueRoute The queueRoute to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the queueRoute matches the query.
     */
    static match(queueRoute, query) {
        return (
            (queueRoute.name.toLowerCase().includes(query)) ||
            queueRoute.id === +query
        );
    }

    /**
     * @param {array} queueRoutes The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(queueRoutes) {
        return queueRoutes.sort((a, b) => {
            return (a.name).localeCompare(b.name);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUITealColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "CallSplit";
    }

}