import api from "../Security/api";

class PatientCommunicationService {
	async getAllPatientCommunicationTriggerDefs() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				paged: false,
			};
			api
				.get("/patientCommunicationTriggerDefs", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	// calls proc spInsertPatientCommunication
	async insertPatientCommunication(params) {
		return new Promise((res, reject) => {
			api
				.get("/insertPatientCommunication", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async addNewPatientCommunication(id) {
		return new Promise((res, reject) => {
			api
				.post("/patientCommunications/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createPatientCommunication(params) {
		return new Promise((res, reject) => {
			api
				.post("/patientCommunications", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}

const patientCommunicationService = new PatientCommunicationService();

export default patientCommunicationService;
