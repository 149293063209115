import React from "react";
import PropTypes from "prop-types";
import {
    Container,
    Spinner,
    Col,
    CardHeader,
    Input,
    InputFile,
    Row,
    toast,
    ToastContainer,
    View,
    MDBIcon,
    MDBBtn, MDBCard, MDBCol, MDBRow,
} from "mdbreact";
import Select from "react-select";
import UserService from "../Security/UserService/userService";
import ReactTooltip from "react-tooltip";

export default class TechList extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            isLoaded: true,
            allLocs: [],
            allTechs: [],
            locationsSelect: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            locationsSelected: [],
        };

    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allLocations: PropTypes.array,
        techs: PropTypes.array,
    };

    componentDidMount() {
        this.getLocationsOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {allLocations} = this.context;
        let st = this.state;

        if (st.allLocs.length !== allLocations.length) {
            this.getLocationsOptions();
        }
    }

    getTechOptions(e) {
        const { techs } = this.context;
        let options = [];
        
        if (e === undefined || e.length === 0) {
            options = techs;
        } else {
            console.log(techs)
            techs.map((tech) => {
                let hasLocation = tech.userprofileLocations.find(l => {
                    return l.locationsId === e.value
                })

                if (hasLocation != null) {
                    return options.push(tech);
                }
                return null;
            });
        }

        this.setState({
            allTechs: options,
        });
    }

    getLocationsOptions() {
        const {allLocations} = this.context;

        let select = [];

        allLocations.map((location) => {
            if (location.type !== null && location.type.toString() === "0") {
                return select.push({
                    label: location.name,
                    value: location.id,
                });
            }
            return null;
        });

        this.setState({
            locationsSelect: select,
            allLocs: allLocations,
        });
    }

    handleLocationChange = (e) => {
        this.setState({
            locationsSelected: e,
        });
        this.getTechOptions(e);
    };

    renderProfilePicture(img) {
        if (!img) {
            return (
                <div style={{width: 200, height: 300, border: "2px solid black", textAlign: "center", margin:'auto'}}>
                    Pic Here
                </div>
            )
        }

        return (
            <View>
                <img
                    src={"data:image/jpeg;base64," + img}
                    className="img-thumbnail"
                    alt={"Receipt"}
                    style={{height: 80, width: 100}}
                />
            </View>
        );
    }

    updateProfile(profile) {
        //profile.profilePicture = profile.profilePictureString;
        //delete profile.profilePictureString;

        return UserService.updateUser(profile)
            .then((data) => {
                console.log(data)
                if(data){
				    toast.success("Saved Successfully!");
                }
			})
			.catch((err) => {
				toast.warn("An error occurred while saving.");
			});
    }

    fileInputHandler(user, value) {
        let reader = new FileReader(),
            file = value[0],
            data,
            rt,
            newStr,
            techs = this.state.allTechs,
            selTech = techs.find(x => x.id === user.id);

        reader.onloadend = () => {
            data = reader.result;
            rt = data.indexOf(",");
            if (rt > -1) {
                newStr = data.slice(rt + 1);
            }
            
            user.profilePicture = newStr;
            selTech.profilePicture = newStr;
            console.log(user)
            this.setState({allTechs: techs});
        };

        reader.readAsDataURL(file);
    }

    renderProfilePicInput(user) {
        return (
            <Row>
                <Col md={12}>
                    <InputFile
                        textFieldTitle="only .png or .jpg files are currently supported"
                        getValue={this.fileInputHandler.bind(this, user)}
                    />
                </Col>
            </Row>
        );
    }

    renderGreetingTextInput(user){
        return(
            <Input
                valueDefault={user.greeting || ""}
                type="textarea"
                label="Enter greeting.."
                rows="2"
                cols="50"
                outline
                onChange={(e) => {
                    user.greeting = e.target.value
                }}
            />
        )
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <MDBRow>
                {this.renderTechCards()}
            </MDBRow>
        );
    }

    renderTechCards() {
        let at = this.state.allTechs,
            t = this;

        function renderCard(tech, idx) {
            return (
                <MDBCol style={{padding: 10}} key={tech.id} size={3}>
                    <MDBCard style={{padding: 10}}>

                        {t.renderProfilePicture(tech.profilePicture)}

                        {t.renderProfilePicInput(tech)}

                        {t.renderGreetingTextInput(tech)}

                        <MDBRow>
                            <MDBCol style={{textAlign: "center"}} size={8}>
                                {tech.firstname + " " + tech.lastname}
                            </MDBCol>
                            <MDBCol size={4}>
                                <MDBBtn onClick={() => t.updateProfile(tech)} data-tip={"Save"} size={"sm"} outline rounded>
                                    <MDBIcon icon={"check"}/>
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                    <ReactTooltip/>
                </MDBCol>
            )
        }

        return (
            at.map((tech, idx) => {
                return (renderCard(tech, idx))
            })
        )
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    renderLocationsFilter() {
        const {currentUser} = this.context;
        let role = currentUser.role;

        switch (role) {
            case "SALES":
            case "OFFICE_SUPPORT":
            case "CARE_COORDINATOR":
            case "CALL_CENTER":
            case "TECHNICIAN":
            case "DATA_EXPORT":
            case "VERIFICATION":
            case "FOLLOWUP":
            case "COLLECTIONS":
            case "BILLING":
            case "PURCHASING":
            case "FINANCE":
            default:
                return <div/>;
            case "SYSTEM_ADMIN":
            case "ADMIN":
            case "EXECUTIVE_MANAGEMENT":
            case "OFFICE_MANAGER":
            case "SALES_MANAGER":
                return (
                    <Row>
                        <Col md={4} style={{marginTop: 5}}>
                            <Select
                                placeholder="Select Location"
                                // closeMenuOnSelect={false}
                                options={this.state.locationsSelect}
                                onChange={this.handleLocationChange.bind(this)}
                                value={this.state.locationsSelected}
                            />
                        </Col>
                    </Row>
                );
        }
    }

    render() {
        return (
            <div >
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />

                <CardHeader style={{backgroundColor: "#5581C1"}}>
                    <Row>
                        <Col md={10} style={{color: "#000"}}>
                            {this.renderLocationsFilter()}
                        </Col>
                    </Row>
                </CardHeader>

                <Row style={{marginLeft: "1%", marginRight: "1%", zIndex: 0}}>
                    <Col style={{zIndex: 0, paddingBottom: "10px"}}>{this.renderTableOrSpinner()}</Col>
                </Row>
            </div>
        );
    }
}
