import api from "../api";

class QueueService {

    // Temporary until we implement this in the API
    static getQueueCountsMemo = {};

    parseJSON(queues) {
        if (Array.isArray(queues) === false) {
            let parsedQueues = queues;
            if (parsedQueues.filter) {
                parsedQueues = { ...parsedQueues, ...{ filter: JSON.parse(parsedQueues.filter) } };
            }
            if (parsedQueues.view) {
                parsedQueues = { ...parsedQueues, ...{ view: JSON.parse(parsedQueues.view) } };
            }
            return parsedQueues;
        }
        return queues.map((queue) => {
            let parsedQueue = queue;
            if (parsedQueue.filter) {
                parsedQueue = { ...parsedQueue, ...{ filter: JSON.parse(parsedQueue.filter) } };
            }
            if (parsedQueue.view) {
                parsedQueue = { ...parsedQueue, ...{ view: JSON.parse(parsedQueue.view) } };
            }
            return parsedQueue;
        });
    }

    async getAllQueues() {
        return new Promise((res, reject) => {
            api
                .get("/allQueues")
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getQueueById(id) {
        return new Promise((res, reject) => {
            api
                .get("/queues/" + id)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createQueue(obj) {
        const queue = {
            ...obj,
            ...{
                filter: obj.filter ? JSON.stringify(obj.filter) : null,
                view: obj.view ? JSON.stringify(obj.view) : null
            }
        };
        return new Promise((res, reject) => {
            api
                .post("/queues", queue)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateQueue(obj) {
        const queue = {
            ...obj,
            ...{
                filter: obj.filter ? JSON.stringify(obj.filter) : null,
                view: obj.view ? JSON.stringify(obj.view) : null
            }
        };
        return new Promise((res, reject) => {
            api
                .put("/queues", queue)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteQueue(id) {
        return new Promise((res, rej) => {
            return api.delete('/queues/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            })
        })
    }

    async getQueueRows(id) {
        return new Promise((res, reject) => {
            api.post("/getQueueRows", id)
                .then((response) => {
                    res(response.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async getQueueStatistics(id, filters) {
        return new Promise((res, reject) => {
            api.get(`/queueStatistics/${id}`, filters)
                .then((response) => {
                    res(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // async getQueueCounts(ids) {
    //     return new Promise((res, reject) => {
    //         api
    //             .post("/getQueueCounts", { ids: ids })
    //             .then((response) => {
    //                 res(response.data);
    //             })
    //             .catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // }


    async getQueueCounts(ids) {
        const memoKey = JSON.stringify(ids);

        // Check if the result is already memoized and not expired
        const memoized = QueueService.getQueueCountsMemo[memoKey];
        if (memoized && memoized.expiration > Date.now()) {
            return Promise.resolve(memoized.data, true);
        }

        // Fetch the data if not memoized or if the memo has expired
        return api
            .post("/getQueueCounts", { ids: ids })
            .then((response) => {
                const expiration = Date.now() + (Math.random() * 5 + 5) * 60 * 1000; // 5-10 minutes
                QueueService.getQueueCountsMemo[memoKey] = {
                    data: response.data,
                    expiration: expiration,
                };
                return response.data;
            });
    }

}

const queueService = new QueueService();

export default queueService;
