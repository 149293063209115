import api from "../api";

class BtProductService {
    async getAllBtProducts() {
        return new Promise((res, reject) => {
            api
                .get("/btproducts?page=0&perPage=999999")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getBtProductsByHcpcs(hcpcs) {
        return new Promise((res, reject) => {
            if (!/^[A-z0-9]{5}$/.test(hcpcs.trim())) {
                reject();
            } else {
                api
                    .get("/btproducts/hcpcs/" + hcpcs.trim())
                    .then((data) => {
                        res(data);
                    })
                    .catch(() => {
                        reject();
                    });
            }

        });
    }


    async getBtProductById(id) {
        return new Promise((res, reject) => {
            api
                .get("/btproducts/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createBtProduct(obj) {
        return new Promise((res, reject) => {
            api
                .post("/btproduct", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateBtProduct(obj) {
        return new Promise((res, reject) => {
            api
                .put("/btproduct", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteBtProduct(id) {
        return new Promise((res, rej) => {
            return api.delete('/btproduct/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            })
        })
    }
}

const btProductService = new BtProductService();

export default btProductService;
