import api from "../api";

class GroupService {
	async getAllTechnicians() {
		return new Promise((res, reject) => {
			api
				.get("/findTechnicians")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getAllTechniciansByLocations(locations) {
		let params = {
			locations: locations || [],
		};

		return new Promise((res, reject) => {
			api
				.get("/findTechniciansByLocations", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getAllSalesRepsByLocations(locations) {
		let params = {
			locations: locations || [],
		};

		return new Promise((res, reject) => {
			api
				.get("/findSalesRepsByLocations", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getAllUsersByLocations(locations) {
		let params = {
			locations: locations || [],
		};

		return new Promise((res, reject) => {
			api
				.get("/findUsersByLocations", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async addUsersToLocations(userIds, location) {
		return new Promise((res, reject) => {
			api
				.post("/users/addUsersToLocation/" + location, userIds)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	//Gets all specified user by group depending on which groups they are in
	async findAllUsersFromGroup(params) {
		return new Promise((res, reject) => {
			// let params= {
			//     includeUsers: true,
			//     // techniciansOnly: true
			// };
			api
				.get("/users/groups", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getAllUsersInGroup(id) {
		return new Promise((res, reject) => {
			api
				.get("/users/groups/" + id, { includeUsers: true })
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}

const groupService = new GroupService();

export default groupService;
