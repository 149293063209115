import api from "../api";

class QueueRouteService {
    parseJSON(queueRoutes) {
        if (Array.isArray(queueRoutes) === false) {
            if (queueRoutes.jsonSubTypes) {
                return { ...queueRoutes, ...{ jsonSubTypes: JSON.parse(queueRoutes.jsonSubTypes) } };
            }
            return queueRoutes;
        }
        return queueRoutes.map((queueRoute) => {
            if (queueRoute.jsonSubTypes) {
                return { ...queueRoute, ...{ jsonSubTypes: JSON.parse(queueRoute.jsonSubTypes) } };
            }
            return queueRoute;
        });
    }

    async getAllQueueRoutes() {
        return new Promise((res, reject) => {
            api
                .get("/allQueueRoutes")
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getQueueRoutesById(id) {
        return new Promise((res, reject) => {
            api
                .get("/queueRoutes/" + id)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createQueueRoute(obj) {
        const queueRoute = {
            ...obj,
            ...{
                jsonSubTypes: obj.jsonSubTypes ? JSON.stringify(obj.jsonSubTypes) : null
            }
        };
        return new Promise((res, reject) => {
            api
                .post("/queueRoutes", queueRoute)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateQueueRoute(obj) {
        const queueRoute = {
            ...obj,
            ...{
                jsonSubTypes: obj.jsonSubTypes ? JSON.stringify(obj.jsonSubTypes) : null
            }
        };
        return new Promise((res, reject) => {
            api
                .put("/queueRoutes", queueRoute)
                .then((data) => {
                    res(this.parseJSON(data));
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteQueueRoute(id) {
        return new Promise((res, rej) => {
            return api.delete('/queueRoutes/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            })
        })
    }
}

const queueRouteService = new QueueRouteService();

export default queueRouteService;
