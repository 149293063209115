import api from "../api";

class TeamService {
    async getAllTeams() {
        return new Promise((res, reject) => {
            api
                .get("/allTeams")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getTeamById(id) {
        return new Promise((res, reject) => {
            api
                .get("/teams/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createTeam(obj) {
        return new Promise((res, reject) => {
            api
                .post("/teams", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateTeam(obj) {
        return new Promise((res, reject) => {
            api
                .put("/teams", obj)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteTeam(id) {
        return new Promise((res, rej) => {
            return api.delete('/teams/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            })
        })
    }
}

const teamService = new TeamService();

export default teamService;
