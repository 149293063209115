import React from "react";
import DialogSearch from "../DialogSearch"
import TileIssueStatus from "../../Tile/TileIssueStatus";
import PropTypes from "prop-types";
import IssueStatus from "../../../Utility/Crud/IssueStatus";

export default class DialogSearchIssueStatus extends DialogSearch {

    static contextTypes = {
        issueStatusesIndexed: PropTypes.object,
    };

    /**
     * Searches for dpartments by several name combinations or ID.
     *
     * @param {string} query The search query.
     * @returns A list of filtered issueStatus.
     */
    getSearchResults(query) {
        return IssueStatus.search(Object.values(this.context.issueStatusesIndexed), query, true);
    }

    /**
     * @param {object} issueStatus The issueStatus object.
     * @param {Function} onClick A function to call when clicking the tile.
     * @returns A TileIssueStatus component for this issueStatus.
     */
    renderTile(issueStatus, onClick) {
        const handleClick = () => {
            onClick(issueStatus);
            this.close();
        }

        return (
            <TileIssueStatus
                key={issueStatus.id}
                issueStatus={issueStatus}
                onClick={handleClick}
            />
        );
    }
}
