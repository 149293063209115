import React from "react";
import PropTypes from "prop-types";
import {
    Container,
    Spinner,
    Col,
    CardHeader,
    Button,
    MDBDatePicker,
    Row,
    toast,
    ToastContainer,
    MDBIcon,
    DataTable, MDBSelect,
} from "mdbreact";
import Select from "react-select";
import QuickFilter from "../Filters/quickFilter";
import ReactTooltip from "react-tooltip";
import ReverseQualityService from "./reverseQualityService";

export default class ReverseQualityList extends React.Component {
    constructor(props) {
        super(props);

        const devData = {
            columns: [
                {
                    label: "Order Id",
                    field: "orderId",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Reported By",
                    field: "reportedBy",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Reported On",
                    field: "reportedOn",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "User Reported",
                    field: "userReported",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Reviewed By",
                    field: "reviewedBy",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Reviewed On",
                    field: "reviewedOn",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Reverse Quality Category",
                    field: "reverseQualityCategory",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Reverse Quality Reason",
                    field: "reverseQualityReason",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Notes",
                    field: "notes",
                    sort: "asc",
                    width: 350,
                },
            ],
            rows: [],
        };

        const columnHeaders = [
            "orderId",
            "reportedBy",
            "reportedOn",
            "userReported",
            "reviewedBy",
            "reviewedOn",
            "reverseQualityCategory",
            "reverseQualityReason",
            "notes",
        ];

        this.state = {
            devData: devData,
            columnHeaders: columnHeaders,
            isLoaded: true,
            filters: {},
            addExpenseModal: false,
            denyModalOpen: false,
            errorMessage: [],
            allLocs: [],
            allUsers: [],
            locationsSelect: [
                {
                    disabled: true,
                    text: "Loading...",
                    value: "loading",
                },
            ],
            allUsersSelect: [
                {
                    disabled: true,
                    text: "Loading...",
                    value: "loading",
                },
            ],
            allUsersSelected: [],
            locationsSelected: [],
            showDatePicker: true,
            quickFilter: [],
            quickFilterSelected: null,
            startDate: new Date(),
            endDate: new Date(),
            rawData: [],
            skip: 0,
            rowData: [],
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allLocations: PropTypes.array,
        allUsers: PropTypes.array,
    };

	componentDidMount() {
		this.getLocationsOptions();
		this.getSalesRepsOptions();
	}

    componentDidUpdate() {
        const {currentUser, allLocations} = this.context;
        let st = this.state;
        if (
            st.allLocs.length !== allLocations.length &&
            st.currUser !== currentUser
        ) {
            this.getLocationsOptions();
        }
    }

    getSalesRepsOptions(e) {
        const {allUsers, currentUser} = this.context;
        let options = [];
        if (e === undefined || e.length === 0) {
            if (
                currentUser.role === "ADMIN" ||
                currentUser.role === "SYSTEM_ADMIN" ||
                currentUser.role === "EXECUTIVE_MANAGEMENT" ||
                currentUser.role === "SALES_LIAISON_MANAGER" ||
                currentUser.role === "FINANCE"
            ) {
                allUsers.map((rep) => {
                    return options.push({
                        text: rep.firstname + " " + rep.lastname,
                         value: rep.id,
                     });
                 });
            }
            this.setState({
                allUsersSelect: options,
                allUsers: options,
            });
        } else {
            let salesList = [],
                options = [];
            let locs = [];
            e.forEach((location) => {
                locs.push(location.value);
            });

            allUsers.map((rep) => {
                if (rep.userprofileLocations.filter(x => e.includes(x.locationsId)).length > 0) {
                    salesList.push(rep.id);
                    return options.push({
                        text: rep.firstname + " " + rep.lastname,
                        value: rep.id,
                    });
                }
                return null;
            });
            this.setState({
                allUsersSelect: options,
                allUsersSelected: options,
                //allUsers: options,
            });

            //return GroupService.getAllUsersByLocations(locs)
            //    .then((res) => {
     //               allUsers.forEach((t) => {
     //                   if (salesList.indexOf(t.id) === -1) {
     //                       return options.push({
     //                           text: t.firstname + " " + t.lastname,
     //                           value: t.id,
     //                       });
     //                   }
     //                   salesList.push(t.id);
     //               });

					//this.setState({
					//	allUsersSelect: options,
					//	allUsersSelected: options,
					//});
				//})
				//.catch((err) => {
				//	console.log(err);
				//});
		}
	}

    getLocationsOptions() {
        const {allLocations, currentUser} = this.context;
        let cu = currentUser;
        let select = [],
            locations = [];
        if (
            cu.role === "ADMIN" ||
            cu.role === "SYSTEM_ADMIN" ||
            cu.role === "EXECUTIVE_MANAGEMENT" ||
            cu.role === "SALES_LIAISON_MANAGER" ||
            cu.role === "FINANCE"
        ) {
            locations = allLocations;
        } else {
            locations = cu.userprofileLocations.map(x => x.locations);
        }
        locations.map((location) => {
            // Populates the locations dropdown depending on which locations the user is in...JK
            if (location.type === 0) {
                return select.push({
                    text: location.name,
                    value: location.id,
                });
            }
            return null;
        });
        this.setState({
            locationsSelect: select,
            allLocs: allLocations,
        });
    }

    handleLocationChange = (e) => {
        this.setState({
            locationsSelected: e,
        });
        this.getSalesRepsOptions(e);
    };

    handleSaleRepChange = (e) => {
        this.setState({
            allUsersSelected: e,
        });
    };

    handleQuickFilter = (e) => {
        let a = QuickFilter.getDates(e.value),
            startDate = "",
            endDate = "";

        if (a.length > 0) {
            startDate = a[0];
            endDate = a[1];
        }

        this.setState({
            quickFilter: a,
            quickFilterSelected: e,
            startDate: startDate,
            endDate: endDate,
        });
    };

    reviewRecord(row, idx) {
        const {currentUser} = this.context;

        let dt = this.state.devData,
            rows = dt.rows.slice();

        ReverseQualityService.getLogById(row.id)
            .then(res => {
                res.ReviewedById = currentUser.id;
                res.reviewedOn = new Date().toISOString();

                ReverseQualityService.editReverseQualityLog(res)
                    .then(r => {
                        let f = rows.find(v => {
                            return v.id === row.id
                        })


                        if(f){
                            f.reviewedOn = new Date().toLocaleDateString();
                            f.reviewedBy = currentUser.username;

                            rows.splice(idx,1,f)

                            dt.rows = rows;
                            this.setState({
                                devData: dt
                            })
                        }
                    });
            }).catch(e => {
            console.log(e)
        })
    }

    formatRows(rqLogs) {
        let ary = [],
            dt = this.state.devData;
        console.log(rqLogs)
        let t = this;

        function markReviewed(log, idx) {
            if (!log.reviewedOn) {
                return (
                    <span onClick={() => t.reviewRecord(log, idx)} 
                        className={"gridTextLink"}
					    style={{ color: "blue", cursor: "pointer" }}>
                        Reviewed
                    </span>
                )
            } else {
                return new Date(log.reviewedOn).toLocaleDateString()
            }
        }

        rqLogs.forEach((log, idx) => {
            ary.push({
                id: log.id,
                orderId: log.orderId,
                reportedBy: log.reportedBy,
                reportedOn: new Date(log.reportedOn).toLocaleDateString(),
                userReported: log.userReported,
                reviewedBy: log.reviewedBy,
                reviewedOn: markReviewed(log, idx),
                reverseQualityCategory: log.reverseQualityCategory,
                reverseQualityReason: log.reverseQualityReason,
                notes: log.notes,
            });
        });

        dt.rows = ary;

        this.setState({
            devData: dt,
            isLoaded: true,
        });
    }

    getInternalLocations() {
        const {internalLocations, currentUser} = this.context;

        let ary = [],
            role = currentUser.role;

        ary.push({
            label: "Choose a Location...",
            value: "",
        });

        switch (role) {
            case "SYSTEM_ADMIN":
            case "ADMIN":
            case "EXECUTIVE_MANAGEMENT":
            case "SALES_LIAISON_MANAGER":
            case "FINANCE":
                internalLocations.map((location) => {
                    return ary.push({
                        label: location.name,
                        value: location,
                    });
                });
                break;
            case "SALES_MANAGER":
                if (currentUser.userprofileLocations) {
                    currentUser.userprofileLocations.map((l) => {
                        if (l.locations.type === 0) {
                            return ary.push({
                                label: l.locations.name,
                                value: l.locations,
                            });
                        }
                        return null;
                    });
                }
                break;
            default:
                break;
        }
        return ary;
    }

    selectLocation(location) {
        let nFilters = this.state.filters;
        nFilters.locationId = location.value.id;
        this.setState({filters: nFilters});
    }

    filterOnLocation() {
        let st = this.state;
        if (
            st.startDate == null ||
            st.startDate === "" ||
            st.endDate == null ||
            st.endDate === ""
        ) {
            toast.error("Please check your date filters");
            return;
        }

        this.setState({isLoaded: false});

        this.getReverseQualityLogs();
    }

    getReverseQualityLogs() {
        let f = {
            start: new Date(this.state.startDate).toLocaleDateString(),
            end: new Date(this.state.endDate).toLocaleDateString(),
        };

        if (this.state.allUsersSelected.length > 0) {
            f.users = this.state.allUsersSelected.toString();
        }

        ReverseQualityService.getReverseQualityLogs(f)
            .then((r) => {
                console.log(r)
                this.formatRows(r);
            })
            .catch(err => console.log(err));
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div style={{backgroundColor: "#FFF", padding: 8, marginBottom: "1rem"}} className={"rqGridPadding"}>
                {this.renderDevTable()}
            </div>
        );
    }

    renderDevTable() {
        let filtersData = {
            users: this.state.allUsersSelected,
            locations: this.state.locationsSelected,
            currentPage: this.state.currentPage,
            totalCount: this.state.totalCount,
        };

        //build filters to send over
        if (this.state.showDatePicker) {
            filtersData.startDate = this.state.startDate;
            filtersData.endDate = this.state.endDate;
        } else {
            filtersData.quickFilter = this.state.quickFilter;
            filtersData.quickFilterSelected = this.state.quickFilterSelected;
        }

        return (
            <DataTable
                entriesOptions={[25, 50, 100]}
                entries={25}
                pagesAmount={10}
                data={this.state.devData}
                responsive
                striped
                bordered
                small
            />
        );
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    handleDatePickerChange = (property, value) => {
        this.setState({
            [property]: value,
        });
    };

	renderLocationsFilter() {
		const { currentUser } = this.context;
		let role = currentUser.role;

        if (["SYSTEM_ADMIN", "ADMIN", "EXECUTIVE_MANAGEMENT"].includes(role) || role.indexOf("MANAGER") >= 0) {
            return (
                <Row>
                    <Col md={"6"} style={{ marginTop: 10 }}>

                        <MDBSelect
                            selectAll
                            multiple
                            outline
                            color="info"
                            selected={"Choose Locations"}
                            style={{ maxHeight: '100px', backgroundColor: 'white', margin: 0 }}
                            options={this.state.locationsSelect}
                            getValue={this.handleLocationChange.bind(this)}
                        />

                    </Col>
                    <Col md={"6"} style={{ marginTop: 10 }}>
                        <MDBSelect
                            search
                            selectAll
                            multiple
                            outline
                            color="warning"
                            selected={"Select Users"}
                            style={{ maxHeight: '100px', backgroundColor: 'white', margin: 0 }}
                            options={this.state.allUsersSelect}
                            getValue={this.handleSaleRepChange.bind(this)}
                        />

                    </Col>
                </Row>
            );
        }
        else
            return <div />;
		//switch (role) {
		//	case "SALES":
		//	case "OFFICE_SUPPORT":
		//	case "CARE_COORDINATOR":
		//	case "CALL_CENTER":
		//	case "TECHNICIAN":
		//	case "DATA_EXPORT":
		//	case "VERIFICATION":
		//	case "FOLLOWUP":
		//	case "COLLECTIONS":
		//	case "BILLING":
		//	case "PURCHASING":
		//	case "FINANCE":
		//	default:
		//		return <div />;
  //          case "AREA_MANAGER":
  //          case "SALES_MANAGER":
		//	case "SYSTEM_ADMIN":
  //          case "ADMIN":
  //          case "SALES_LIAISON_MANAGER":
  //          case "EXECUTIVE_MANAGEMENT":
  //          case "OFFICE_MANAGER":
		//		return (
		//			<Row>
		//				<Col md={"6"} style={{ marginTop: 10 }}>

  //                          <MDBSelect
  //                              selectAll
  //                              multiple
  //                              outline
  //                              color="info"
  //                              selected={"Choose Locations"}
  //                              style={{maxHeight: '100px', backgroundColor: 'white', margin: 0}}
  //                              options={this.state.locationsSelect}
  //                              getValue={this.handleLocationChange.bind(this)}
  //                          />

		//				</Col>
		//				<Col md={"6"} style={{ marginTop: 10 }}>
  //                          <MDBSelect
  //                              search
  //                              selectAll
  //                              multiple
  //                              outline
  //                              color="warning"
  //                              selected={"Select Users"}
  //                              style={{maxHeight: '100px', backgroundColor: 'white', margin: 0}}
  //                              options={this.state.allUsersSelect}
  //                              getValue={this.handleSaleRepChange.bind(this)}
  //                          />

		//				</Col>
		//			</Row>
		//		);
		//}
	}

    //call to download data
    clientCSV(stateData) {
        let result = "",
            ctr = 0,
            keys = this.state.columnHeaders,
            columnDelimiter = ",",
            lineDelimiter = "\n";

        if (stateData == null || !stateData.length) {
            return null;
        }

        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        stateData.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (key === "notes" && item[key] != null) {
                    item[key] = item[key].replace(/,/g, " ");
                }

                if (key === "reviewedOn" && typeof item[key] !== "string") {
                    result += "";
                }
                else {
                    result += item[key] === null || item[key] === undefined ? "" : item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, "rqLogs.csv");
    }

    //csv downlaod data here
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

	render() {
		return (
			<div style={{ marginLeft: "4%", marginRight: "4%" }}>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={5000}
				/>
				<CardHeader style={{ backgroundColor: "#5881C1" }}>
					<Row>
						<Col md={"5"} style={{ color: "#000" }}>
							{this.renderLocationsFilter()}
						</Col>
						<Col md={"2"} style={{ marginTop: 10, color: "#000" }}>
							<Select
								placeholder="Quick Filter"
								options={QuickFilter.getOptions()}
								onChange={this.handleQuickFilter.bind(this)}
								value={this.state.quickFilterSelected}
							/>
						</Col>


							<Col md={"2"} style={{ color: "#FFF" }}>
								<div className={"expenseDateOptions"}>
									<MDBDatePicker
										className={"openDatePicker"}
										label={"Start Date"}
										showTodayButton
										autoOk
										allowKeyboardControl
										keyboard={true}
										okLabel={false}
										value={this.state.startDate}
										getValue={this.handleDatePickerChange.bind(
											this,
											"startDate"
										)}
									/>
								</div>
							</Col>
							<Col md={"2"}>
								<div className={"expenseDateOptions"}>
									<MDBDatePicker
										className={"openDatePicker"}
										label={"End Date"}
										showTodayButton
										autoOk
										allowKeyboardControl
										keyboard={true}
										okLabel={false}
										value={this.state.endDate}
										getValue={this.handleDatePickerChange.bind(this, "endDate")}
									/>
								</div>
							</Col>
                            <Col md={"1"}>
                                <Button
                                    floating
                                    size="sm"
                                    color={"indigo"}
                                    data-tip={"Search"}
                                    onClick={() => {
                                        this.filterOnLocation();
                                    }}
                                >
                                    <MDBIcon icon="search-plus" style={{ fontSize: "2em" }} />
                                </Button>
                            </Col>

					</Row>
				</CardHeader>

                {this.renderTableOrSpinner()}

				<div className={"fixed-bottom downloadCsvDiv"}>
					<Button
						className={"downloadCsvButton"}
						floating
						size="sm"
						color={"indigo"}
						data-tip={"Download CSV"}
						onClick={() => this.clientCSV(this.state.devData.rows)}
					>
						<MDBIcon icon="download" style={{ fontSize: "2em" }} />
					</Button>

                    <ReactTooltip/>
                </div>
            </div>
        );
    }
}
