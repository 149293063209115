/**
 * Static validator class.
 */
export default class Validator {

    /**
     * @param {object} object The object to validate.
     * @param {object} rules A set of rules to validate the object against. The
     * keys in this object should match the keys in the "object" parameter.
     * @returns {object} An object containing any validation issues. If there
     * are no issues, an empty object.
     */
    static validate(object, rules) {
        const validationIssues = {};

        for (const key in rules) {
            const rule = rules[key];

            if (
                rule.required === true &&
                Validator.validateRequired(object[key]) === false
            ) {
                validationIssues[key] = "Required"
            } else if (
                rule.type === "integer" &&
                object[key] !== null &&
                object[key] !== undefined &&
                Validator.validateInteger(object[key]) === false
            ) {
                validationIssues[key] = "Must be an integer"
            }
        }

        return validationIssues;
    }

    /**
     * @param {*} value
     * @returns Whether or not the value is set and is not an empty string.
     */
    static validateRequired(value) {
        if (
            value === null ||
            value === undefined ||
            (
                typeof value === "string" &&
                value.trim() === ""
            )
        ) {
            return false;
        }

        return true;
    }

    /**
     * @param {*} value
     * @returns Whether or not the value is an integer.
     */
    static validateInteger(value) {
        return Number.isInteger(+value);
    }
}