import api from "../Security/api";

class DeploymentService {
	async getAllDeployments() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				paged: false,
			};
			api
				.get("/deployments", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createDeployment(cc) {
		return new Promise((res, reject) => {
			api
				.post("/deployments", cc)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}

const deploymentService = new DeploymentService();

export default deploymentService;
